import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createAssignedServiceZips,
  getAssignedServiceZips,
  updateAssignedServiceZips,
  assignVendorIndustryLocations,
  updateVendorIndustryLocations
} from "../services/vendorIndustryServices";

const initialState = {
  isError: false,
  isLoading: false,
  assignedZips: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Users Roles
export const get_assigned_service_zips = createAsyncThunk(
  "get_assigned_service_zips",
  async (data,thunkAPI) => {
    try {
      return await getAssignedServiceZips(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Roles
export const create_assigned_service_zips = createAsyncThunk(
  "create_assigned_service_zips",
  async (data, thunkAPI) => {
    try {
      return await createAssignedServiceZips(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Roles
export const update_assigned_service_zips = createAsyncThunk(
  "update_assigned_service_zips",
  async (data, thunkAPI) => {
    try {
      return await updateAssignedServiceZips(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Update Roles
export const assign_vendor_indutries_location = createAsyncThunk(
  "assign_vendor_indutries_location",
  async (data, thunkAPI) => {
    try {
      return await assignVendorIndustryLocations(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Update Roles
export const update_vendor_indutries_location = createAsyncThunk(
  "update_vendor_indutries_location",
  async (data, thunkAPI) => {
    try {
      return await updateVendorIndustryLocations(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const vendorIndustrySlice = createSlice({
  name: "vendorIndustry",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.assignedZips = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_assigned_service_zips.pending, (state) => {
        state.isLoading = true;
        state.assignedZips = [];
      })
      .addCase(get_assigned_service_zips.fulfilled, (state, action) => {
        state.isLoading = false;
        state.assignedZips = action.payload?.data;
      })
      .addCase(get_assigned_service_zips.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.assignedZips = [];
      })
      .addCase(create_assigned_service_zips.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_assigned_service_zips.fulfilled, (state, action) => {
        state.isLoading = false;
        state.assignedZips.push(action.payload.data);
      })
      .addCase(create_assigned_service_zips.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_assigned_service_zips.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_assigned_service_zips.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.assignedZips.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.assignedZips[result] = {
          ...state.assignedZips[result],
          ...action.payload.data,
        };
      })
      .addCase(update_assigned_service_zips.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(assign_vendor_indutries_location.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assign_vendor_indutries_location.fulfilled, (state, action) => {
        state.isLoading = false;
        // const result = state.assignedZips.findIndex(
        //   ({ _id }) => _id === action?.payload?.data?._id
        // );
        // state.assignedZips[result] = {
        //   ...state.assignedZips[result],
        //   ...action.payload.data,
        // };
      })
      .addCase(assign_vendor_indutries_location.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(update_vendor_indutries_location.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_vendor_indutries_location.fulfilled, (state, action) => {
        state.isLoading = false;
        // const result = state.assignedZips.findIndex(
        //   ({ _id }) => _id === action?.payload?.data?._id
        // );
        // state.assignedZips[result] = {
        //   ...state.assignedZips[result],
        //   ...action.payload.data,
        // };
      })
      .addCase(update_vendor_indutries_location.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { reset } = vendorIndustrySlice.actions;
export default vendorIndustrySlice.reducer;
