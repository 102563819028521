import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Loader } from "../../../components";
import axios from "axios";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../features/authSlice";
import { setStatus, setTimer } from "../../../features/dialerSlice";
import jwtDecode from "jwt-decode";

const CustomModal = ({ onClose, logoutHandler }) => {
    const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const [sessionTimer, setSessionTimer] = useState();
  const checkTokenExpiry = () => {
    const refreshToken = localStorage.getItem("refreshToken");

    if (!refreshToken) {
      dispatch(logoutUser());
      dispatch(setStatus(""));
      dispatch(setTimer(""));
      return
    }
    // Retrieve the token's expiry time from local storage
    const jwt = jwtDecode(refreshToken);
    // Get current time in Unix timestamp (seconds)
    const currentTime = Math.floor(Date.now() / 1000);

    // Calculate the time left before the token expires
    const timeLeft = jwt.exp - currentTime;
    setSessionTimer(timeLeft)
    // Check if the token is going to expire in the next 2 minutes (120 seconds)
  };
  React.useEffect(() => {
    // Check token expiry on component mount
    checkTokenExpiry();
    // Set up an interval to check token expiry every minute
    const interval = setInterval(checkTokenExpiry, 1000); // 60000 milliseconds = 1 minute
    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);
  const handleConfirm = async () => {
    setIsLoader(true);
    try {
      const res = await axios.get(`/api/refresh/renew`, {
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: { refreshToken: localStorage.getItem("refreshToken") },
      });
      if (res.status === 200) {
        localStorage.setItem("refreshToken", res.data.newRefreshToken);
        toast.success(`Session renew successfully`);
      } else {
        toast.error(`Session couldn't be renewed`);
      }
    } catch (error) {
      toast.error(`Session couldn't be renewed`);
    }
    onClose();
    setIsLoader(false);
  };
  return (
    <>
      <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40">
        <div className="bg-white p-8 rounded-lg shadow-lg relative w-[400px]">
          <h2 className="text-lg font-semibold mb-4">Warning</h2>
          <div>
            Your session is about to expire in {sessionTimer} seconds. Please log in again. or renew your
            session.
          </div>
          <div className="flex justify-end !mt-4">
            {isLoader ? (
              <Loader />
            ) : (
              <>
                <Button
                  text="Keep working"
                  onClick={handleConfirm}
                  variant="btn_submit"
                  className="mr-2"
                />

                <Button
                  text="Logout"
                  variant="btn_cancel"
                  onClick={logoutHandler}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomModal;
