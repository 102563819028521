import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Button, DotsLoader } from "../../components";
import { get_job_category } from "../../features/jobCategorySlice";
import { get_card_types_drd } from "../../features/cardTypesSlice";
import { get_months } from "../../features/monthsSlice";
import TagsInput from "../../components/molecules/TagsInput";
import { get_tags_drd } from "../../features/tagsSlice";
import { get_crm_vendors_drd, get_tech, get_users_drd } from "../../features/usersSlice";
import {
  get_jobs_by_id,
  get_lead_tag,
  get_notes,
  get_transactions,
  update_job,
} from "../../features/jobsSlice";
import { toast } from "react-toastify";
import { get_job_status } from "../../features/jobStatusSlice";

import "react-calendar/dist/Calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import { get_payment_gateways_drd } from "../../features/paymentGatewaySlice";
import ImgModal from "./components/ImgModal";
import ImageHistory from "./components/ImageHistory";
import SummarySection from "./components/Summary";
import LocationSection from "./components/Location";
import AssignTechSection from "./components/AssignTech";
import JobHeader from "./components/Header";
import InvoiceDetail from "./components/InvoiceDetail";
import PaymentOption from "./components/PaymentOption";
import CloseJob from "./components/CloseJob";
import errorMessage from "../../util/errorMessage";
import { get_industry_drd } from "../../features/IndustrySlice";
import { userPermissionNew } from "../../util/userPermissionNew";
import CardManagement from "./components/CardManagement";
const CreateJobForm = () => {
  let initialValues = {
    lead_num: "",
    lead_token: "",
    phone: "",
    job_category_id: "",
    service_cost: "",
    scheduled_on: "",

    name: "",
    car_year: "",
    car_model: "",
    car_make: "",
    is_neutral: "",

    email: "",
    alternate_phone: "",

    drop_latitude: 0,
    drop_longitude: 0,
    drop_country: "",
    drop_city: "",
    drop_state: "",
    drop_zip: "",
    complete_drop_address: "",

    pickup_latitude: 0,
    pickup_longitude: 0,
    pickup_country: "",
    pickup_city: "",
    pickup_state: "",
    pickup_zip: "",
    complete_pickup_address: "",

    distance: "",
    special_instruction: "",
    car_service: "",

    is_declined: "",
    decline_comment: "",
    decline_reasons: "",
    decline_id: "",

    card_type_id: "",
    card_month_id: "",
    card_number: "",
    card_year: "",
    card_CVV: "",
    card_holder_name: "",
    card_zip: "",
    card_city: "",
    card_state: "",
    zelle_auth_number: "",
    venmo: "",
    cash_app: "",
    vendor_pay: "",

    tech_user_id: "",
    tech_fee: "",
    driver_notes: "",
    amount_paid_to_tech: "",
    tech_paid_on: "",
    discount: "",
    cc_fee: "",
    tax: "",
    attachments: [],
    tags: "",
    goa_price: "",
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const jobTypes = useSelector((state) => state.jobCategory);
  const cardTypes = useSelector((state) => state.cardTypes);
  const jobStatus = useSelector((state) => state.jobStatus);
  const [isLoader, setIsLoader] = React.useState(false);
  const months = useSelector((state) => state.months);
  const tags = useSelector((state) => state.tags);
  const { techs } = useSelector((state) => state.users);
  const paymentGateway = useSelector((state) => state.paymentGateway);

  const [imgSaveModal, setImgSaveModal] = useState(false);
  const [imgViewModal, setImgViewModal] = useState(false);
  const [singleImg, setSingleImg] = useState();

  const [tech, setTech] = useState();
  const [formikData, setFormikData] = useState(null);
  const jobs = useSelector((state) => state.jobs);
  const isUpdating = location.pathname.startsWith("/jobs/update");
  const { id } = params;

  const getJob = async () => {
    try {
      const res = await dispatch(get_jobs_by_id(id));
      if (res?.payload?.status === 200) {
        const job = res.payload.data;
        setFormikData({
          ...res.payload.data,
          id: id,
          vendor_pay: job?.tech_fee
            ? (job?.tech_fee / (job?.service_cost - job.parts_charge)) * 100
            : tech?.tech_percentage,
          tech_fee: job?.tech_fee
            ? job?.tech_fee
            : tech?.tech_percentage
            ? (
                (tech?.tech_percentage / 100) *
                (job?.service_cost - job.parts_charge)
              )?.toFixed(2)
            : 0,
        });
      } else {
        errorMessage({ payload: res.payload, action: "Job", msg: "loaded" });
      }
    } catch (error) {
      console.error(
        "Error ~ file: AddUpdateForm.jsx:157 ~ getJob ~ error",
        error
      );
      errorMessage({ payload: error, action: "Job", msg: "loaded" });
    }
  };

  if (isUpdating) {
    const job = ((Array.isArray(jobs.record) && jobs.record) || [])?.find(
      (item) => item?._id === id
    );
    if (job) {
      initialValues = {
        ...job,
        job_category_id: job?.job_category_id?._id,
        job_category: job?.job_category_id,
        id: job._id,
        vendor_pay: job?.tech_fee
          ? (
              (job?.tech_fee / (job?.service_cost - job.parts_charge)) *
              100
            )?.toFixed(2)
          : tech?.tech_percentage,
      };
    }
  }

  const formik = useFormik({
    initialValues: formikData || initialValues,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        const res = await dispatch(update_job(values));
        if (res?.payload?.status === 200) {
          toast.success("Job Updated Successfully");
        } else {
          errorMessage({
            payload: res.payload,
            action: "Job",
            msg: "updated",
          });
        }
      } catch (err) {
        errorMessage({
          payload: err,
          action: "Job",
          msg: "updated",
        });
      }
    },
  });

  useEffect(() => {
    // if (
    //   jobTypes?.record?.records?.length === 0 ||
    //   jobTypes?.record?.records === undefined
    // ) {
    // }
    dispatch(get_job_category({ get_new_records_also: 1 }));

    if (
      cardTypes.cardTypesDrd?.length === 0 ||
      cardTypes?.cardTypesDrd === undefined
    ) {
      dispatch(get_card_types_drd());
    }
    if (months.months?.length === 0 || months?.months === undefined) {
      dispatch(get_months());
    }
    if (tags.tagsDrd?.length === 0 || tags?.tagsDrd === undefined) {
      dispatch(get_tags_drd());
    }

    if (
      paymentGateway?.record?.records?.length === 0 ||
      paymentGateway?.record?.records === undefined
    ) {
      dispatch(get_payment_gateways_drd());
    }

    if (
      jobStatus?.record?.records?.length === 0 ||
      jobStatus?.record?.records === undefined
    ) {
      dispatch(get_job_status({ page: 1, size: 1000, active: 1 }));
    }

    dispatch(get_industry_drd());
    dispatch(get_users_drd());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(
      get_tech({
        page: 1,
        size: 100,
        job_id: id,
      })
    );
    dispatch(get_crm_vendors_drd());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getJob();
    dispatch(get_transactions({ id: id, type: "Payment" }));
    dispatch(get_lead_tag(id));
    // eslint-disable-next-line
  }, [id]);
  useEffect(() => {
    if (formik?.values?._id) {
      dispatch(get_notes(formik?.values?.job_number));
    }
    // eslint-disable-next-line
  }, [formik?.values?._id]);
  useEffect(() => {
    let tech = {};
    tech = techs?.records?.find(
      (user) => user._id === formik.values.vendor_id?._id
    );
    setTech(tech);
    // eslint-disable-next-line
  }, [formik.values.vendor_id, techs]);

  const onCancelForm = () => {
    setImgSaveModal(false);
    setImgViewModal(false);
    setSingleImg(null);
  };

  return (
    <form>
      {imgSaveModal || imgViewModal || singleImg ? (
        <ImgModal
          title={"Add image"}
          onCancelModal={onCancelForm}
          formIsValid={formik.isValid}
          isLoading={jobs.isLoading}
          onClick={onCancelForm}
          hideHeader={imgViewModal || singleImg ? true : false}
          imgViewModal={imgViewModal}
          imgSaveModal={imgSaveModal}
          singleImg={singleImg}
          formik={formik}
        />
      ) : null}

      {jobTypes?.loading ||
        cardTypes.loading ||
        (tags.isLoading || jobs.isLoading || isLoader ? <DotsLoader /> : null)}
      <div># Dashboard # Jobs # Job Detail (#{formik.values?.job_number})</div>
      <div className="border rounded mt-2 py-3">
        <JobHeader formik={formik} jobTypes={jobTypes} tech={tech} />

        <TagsInput tags={tags?.tagsDrd} formik={formik} id={id} />
      </div>
      <div className="md:border md:bg-light md:p-2 rounded mb-4 md:mt-4">
        <div className="px-3.7 w-full">
          <div className="flex items-center">
            <h3 className="my-4">Job Detail</h3>
          </div>
        </div>

        <div className="w-full">
          <div className="">
            {/* Summary Section */}

            <SummarySection formik={formik} jobTypes={jobTypes} />

            {/* Location Section */}

            <LocationSection formik={formik} jobTypes={jobTypes} />

            {/* Assign Tech Section */}
            <AssignTechSection
              formik={formik}
              //techs={techs}
              tech={tech}
              jobTypes={jobTypes}
              job_id={id}
            />
            {userPermissionNew("Vendors Card Management") ? (
              <CardManagement formik={formik} job_id={id} />
            ) : null}

            {/* Client History Section */}

            <div className="mt-3 px-2">
              <div className="bg-white border px-3 md:px-4 rounded">
                <div className="md:col-4 pl-2 pt-3">
                  <h5 className="">Client History</h5>
                </div>
                <div className="md:grid grid-cols-2 gap-x-2 mb-3">
                  {/* <div className="mb-2 bg-white">
                    <VerificationHistory />
                  </div> */}
                  <div className="mb-2">
                    <ImageHistory
                      formik={formik}
                      setImgSaveModal={setImgSaveModal}
                      setSingleImg={setSingleImg}
                      setImgViewModal={setImgViewModal}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="px-2 mt-3">
              <div className="pt-3 bg-white border px-4 rounded">
                <InvoiceDetail formik={formik} jobTypes={jobTypes} />
                {/* <PaymentDetail formik={formik} /> */}
                {userPermissionNew("Charge Card") ||
                userPermissionNew("Charge Client Card") ? (
                  <PaymentOption formik={formik} />
                ) : null}

                <CloseJob
                  formik={formik}
                  tech={tech}
                  setIsLoading={setIsLoader}
                  getJob={getJob}
                />
              </div>
            </div>
          </div>
          <div className="w-full p-2 justify-center flex">
            <Button
              text={"Update"}
              onClick={formik.handleSubmit}
              variant="btn_submit"
              className="w-full md:w-auto"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateJobForm;
