import React from "react";
import { useNavigate } from "react-router-dom";
function SuccessMsg({ resData, formik, job_note }) {
  const navigate = useNavigate();

  return (
    <div className="container-fluid mt-2 text-center">
      <div className="inline-block bg-white my-0 mx-auto">
        <div className="w-[100px] h-[100px] bg-[#3dcb31] rounded-full m-auto mt-[-70px] flex justify-center items-center">
          <i className="text-white font-extrabold text-5xl text-center">✓</i>
        </div>
        <label className="text-black font-pop text-3xl mb-2.5 font-medium">
          Thank You!
        </label>
        <p className="text-[#7b828a] font-pop text-base m-0">
          Your Service request has been recived. Your job number is{" "}
          <span
            className="text-[#027bff]"
            onClick={() => {
              navigate(
                `/jobs/update/${
                  job_note
                    ? job_note?.job_id
                    : resData?._id
                    ? resData?._id
                    : formik.values.id
                }`
              );
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            {resData?.job_number}
          </span>
          . Call or text us on this{" "}
          <span className="text-[#027bff]"> {resData?.call_text_on}</span> for
          any query.
        </p>
      </div>
      {job_note?.params && (
        <div className="flex justify-center align-items !mt-3">
          <div className="w-1/4 text-left">
            {Object.entries(job_note?.params)?.map(
              ([key, { label, value }]) => (
                <div key={key} className="flex justify-between">
                  <span className="text-black font-pop text-base font-medium">
                    {label}:{" "}
                  </span>
                  <span>{value}</span>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default SuccessMsg;
