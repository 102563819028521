import api from "./api";

export const getJobStatus = async (data) => {
  return await api.get("/api/jobstatus", { params: data });
};

export const createJobStatus = async (values) => {
  return await api.post("/api/jobstatus/new", values);
};

export const updateJobStatus = async ({ id, name, systemJobStatusId }) => {
  return await api.put(`/api/jobstatus/${id}`, { name, systemJobStatusId });
};

export const deleteJobStatus = async (id) => {
  return await api.delete(`/api/jobstatus/${id}`);
};
