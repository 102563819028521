import React, { useEffect, useState } from "react";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { DotsLoader } from "../../components";
import api from "../../services/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
const UptimeMonitor = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [records, setRecords] = useState([]);
  dayjs.extend(relativeTime);
  dayjs.extend(utc);
  const getScriptList = async () => {
    setIsLoading(true);
    try {
      const res = await api.get("/api/script_status");
      if (res.status === 200 || res.status === 201) {
        setRecords(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    getScriptList();
    // eslint-disable-next-line
  }, []);
  const listing = records
    ?.filter((record) => {
      const matchesStatus =
        selectedStatus === null || record?.isRunning === selectedStatus;
      const matchesSearch = Object.keys(record).some(
        (key) =>
          record[key] &&
          record[key]
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase())
      );

      return matchesStatus && matchesSearch;
    })
    .sort((a, b) => {
      if (a.status === 9 && b.status !== 9) return -1;
      if (a.status !== 9 && b.status === 9) return 1;
      return 0;
    });

  const statusOption = [
    { value: true, label: "Running" },
    { value: false, label: "Stop" },
  ];
  const columnDefs = [
    { headerName: "#", field: "counter", width: 80 },
    { headerName: "Name", field: "scriptName", flex: 1 },
    { headerName: "Description", field: "description", flex: 1 },
    {
      headerName: "Status",
      field: "isRunning",
      width: 100,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: statusOption,
      renderCell: (params) => {
        const statusClasses = params.row.isRunning
          ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300"
          : "bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300";

        return (
          <>
            <span
              className={`text-xs font-medium me-2 px-3.5 py-0.5 rounded-full ${statusClasses}`}
            >
              {params.row.isRunning ? "Running" : "Stop"}
            </span>
          </>
        );
      },
    },
    {
      headerName: "Last Start Time",
      field: "lastStartTime",
      flex: 1,
      filterable: false,
    },
    {
      headerName: "Last End Time",
      field: "lastEndTime",
      flex: 1,
      filterable: false,
    },
    {
      headerName: "Running Duration",
      field: "timeDifference",
      flex: 1,
      filterable: false,
    },
  ];

  const CustomComponent = () => {
    return (
      <div className="flex gap-2 ml-1 mb-2 flex-wrap">
        <button
          onClick={() => setSelectedStatus(null)}
          className={`inline-flex items-center justify-center rounded text-sm font-semibold p-2 whitespace-nowrap w-full md:max-w-[12%] ${
            selectedStatus === null ? "bg-primary-100" : "bg-gray-200"
          } border cursor-pointer`}
        >
          {"All Scripts"} ({records?.length})
        </button>
        <button
          onClick={() => setSelectedStatus(true)}
          className={`inline-flex items-center justify-center rounded text-sm font-semibold p-2 whitespace-nowrap w-full md:max-w-[15%] ${
            selectedStatus === true ? "bg-primary-100" : "bg-gray-200"
          } border cursor-pointer`}
        >
          Running ({records?.filter(script => script?.isRunning).length})
        </button>
        <button
          onClick={() => setSelectedStatus(false)}
          className={`inline-flex items-center justify-center rounded text-sm font-semibold p-2 whitespace-nowrap w-full md:max-w-[15%] ${
            selectedStatus === false ? "bg-primary-100" : "bg-gray-200"
          } border cursor-pointer`}
        >
          Stop ({records?.filter(script => !script?.isRunning).length})
        </button>
      </div>
    );
  };

  const displayTimeDifference = (startTime, endTime) => {
    const start = dayjs.utc(startTime); // Convert start time to UTC
    const end = dayjs.utc(endTime); // Convert end time to UTC

    const diffSeconds = end.diff(start, "second");
    const diffMinutes = end.diff(start, "minute");
    const diffHours = end.diff(start, "hour");

    if (diffSeconds < 0) {
      return "End time is before start time"; // Handle this case as needed
    }

    if (diffHours < 24) {
      // If the difference is less than 24 hours
      if (diffHours >= 1) {
        return diffHours === 1 ? "1 hour" : `${diffHours} hours`;
      } else {
        // If the difference is less than an hour
        if (diffMinutes >= 1) {
          return diffMinutes === 1 ? "1 minute" : `${diffMinutes} minutes`;
        } else {
          // If the difference is less than a minute
          return diffSeconds === 1 ? "1 second" : `${diffSeconds} seconds`;
        }
      }
    } else {
      // If the difference is 24 hours or more
      return `${Math.floor(diffHours / 24)} days ${diffHours % 24} hours`;
    }
  };
  return (
    <>
      {isLoading ? <DotsLoader /> : null}
      <PageHeader heading="Script Status Listing" />

      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={listing?.map((record, index) => {
            let counter = index + 1;
            const {
              scriptName,
              description,
              isRunning,
              lastEndTime,
              lastStartTime,
              note,
            } = record;
            return {
              counter,
              records: { ...record },
              scriptName,
              description,
              isRunning,
              lastEndTime: lastEndTime
                ? dayjs(lastEndTime).format("ddd, MMM D, YYYY h:mm:ss A")
                : null,
              lastStartTime: lastStartTime
                ? dayjs(lastStartTime).format("ddd, MMM D, YYYY h:mm:ss A")
                : null,
              note,
              timeDifference: displayTimeDifference(
                record.lastStartTime,
                record.lastEndTime
              ),
            };
          })}
          pagination="No"
          totalItems={listing?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          CustomComponent={CustomComponent}
          searchable="No"
        />
      </div>
    </>
  );
};

export default UptimeMonitor;
