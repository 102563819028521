import React from 'react'
import { NavLink } from 'react-router-dom';
import {sideMenu} from '../../components/organisms/Sidebar/menu.config'
function Settings() {
    const settings = sideMenu?.find(({to})=>to==='/settings')
  return (
    <div className="mt-2">
        <div className="border-b w-full">
          <span className="!ml-3 text-xl font-semibold">Settings</span>
        </div>
        <div className="flex flex-col lg:flex-wrap lg:flex-row justify-start mx-[1%] mt-4 md:mt-3 gap-x-6">
          {settings.children.map((report, index) => {
            const { to, label, Icon } = report;
            return (
              <NavLink
                key={index}
                to={to}
                className="flex justify-between items-center text-ml-3 !mb-4 !p-2 sm:w-[45%] md:w-[32%] hover:no-underline no-underline text-black shadow-report rounded-[1px] bg-white border-l-[3px] border-primary-100 hover:shadow-hover transition-all"
              >
                {label}
                <Icon/>
              </NavLink>
            );
          })}
        </div>
      </div>
  )
}

export default Settings