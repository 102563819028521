import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import JobsOverview from './JobsOverview';
import Sources from "./Sources";
import TechPerformance from "./TechPerformance";
import AreaPerformance from "./AreaPerformance";
import Dispatcherformance from "./DispatcherPerformance";
import ReportHead from "../Components/Header";
const StatisticsReports = ()=> {
    return (
        <div className="mt-2 p-2">
        <ReportHead route="Reports > Commission Report" heading="Login IP" />

            <Tabs>
                <TabList className="mb-0">
                <Tab>Jobs Overview</Tab>
                <Tab>Sources</Tab>
                <Tab>Tech Performance</Tab>
                <Tab>Area Performance</Tab>
                <Tab>Dispatcher Performance</Tab>
                </TabList>

                <TabPanel>
                    <JobsOverview />
                </TabPanel>
                <TabPanel>
                    <Sources />
                </TabPanel>
                <TabPanel>
                    <TechPerformance />
                </TabPanel>
                <TabPanel>
                    <AreaPerformance />
                </TabPanel>
                <TabPanel>
                    <Dispatcherformance />
                </TabPanel>
            </Tabs>
        </div>

    )
}

export default StatisticsReports;