import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addDetachDomain,
  addDomainRankingKeywords,
  addDomainRunner,
  createBatch,
  deleteBatch,
  deleteDetachDomain,
  deleteDomainRankingKeywords,
  getAlternateDomainPhone,
  getBatch,
  getBatchDetail,
  getBatchForExport,
  getBatchForExport2,
  getBuyingReport,
  getDetachDomain,
  getDomainDrd,
  getDomainNumbers,
  getDomainRankingReport,
  getDomainRunners,
  getDomains,
  getGoogleConsoles,
  getLeadJobCount,
  getNamesiloDomainReport,
  getPendingDomainsPhone,
  getPurchaseDomain,
  getPurchaseDomainZips,
  getTestLinkIndustries,
  importTestDomainDB,
  purchaseDomains,
  setAlternateAreaCode,
  setTestDomains,
  updateDomainNumbers,
  updateDomainRankingKeywords,
  updateDomainRunner,
} from "../services/domainCenter";
const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
  batches: [],
  buyingDomains: [],
  batchDetail: [],
  message: "",
  export_Data: [],
  searchConsole: [],
  purchaseDomains: [],
  domainList: [],
  purchaseDomainsZips: [],
  testingIndustries: [],
  domainRanking: {},
  domainsDrd: [],
  domainRunner: [],
  alternatePhoneArea: [],
  domainNumbers: [],
  pendingPhone: [],
  namesiloDomains: [],
  detachDomain: [],
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Industry Data
export const get_buying_report = createAsyncThunk(
  "get_buying_report",
  async (payload, thunkAPI) => {
    try {
      return await getBuyingReport(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Industry Data
export const get_purchase_domains = createAsyncThunk(
  "get_purchase_domains",
  async (payload, thunkAPI) => {
    try {
      return await getPurchaseDomain(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Alternate Domains Phone
export const get_alternate_domains_phone = createAsyncThunk(
  "get_alternate_domains_phone",
  async (thunkAPI) => {
    try {
      return await getAlternateDomainPhone();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_purchase_domain_zips = createAsyncThunk(
  "get_purchase_domain_zips",
  async (payload, thunkAPI) => {
    try {
      return await getPurchaseDomainZips(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_domain_ranking_report = createAsyncThunk(
  "get_domain_ranking_report",
  async (payload, thunkAPI) => {
    try {
      return await getDomainRankingReport(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_namesilo_domains_report = createAsyncThunk(
  "get_namesilo_domains_report",
  async (payload, thunkAPI) => {
    try {
      return await getNamesiloDomainReport(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_lead_job_count = createAsyncThunk(
  "get_lead_job_count",
  async (payload, thunkAPI) => {
    try {
      return await getLeadJobCount(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_domains_drd = createAsyncThunk(
  "get_domains_drd",
  async (payload, thunkAPI) => {
    try {
      return await getDomainDrd(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const add_domain_ranking_keywords = createAsyncThunk(
  "add_domain_ranking_keywords",
  async (payload, thunkAPI) => {
    try {
      return await addDomainRankingKeywords(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const delete_domain_ranking_keywords = createAsyncThunk(
  "delete_domain_ranking_keywords",
  async (payload, thunkAPI) => {
    try {
      return await deleteDomainRankingKeywords(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const update_domain_ranking_keywords = createAsyncThunk(
  "update_domain_ranking_keywords",
  async (payload, thunkAPI) => {
    try {
      return await updateDomainRankingKeywords(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create Batch
export const create_batch = createAsyncThunk(
  "create_batch",
  async (payload, thunkAPI) => {
    try {
      return await createBatch(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Batchs
export const get_batch = createAsyncThunk(
  "get_batch",
  async (payload, thunkAPI) => {
    try {
      return await getBatch(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Purchase Domains
export const purchase_domains = createAsyncThunk(
  "purchase_domains",
  async (data, thunkAPI) => {
    try {
      return await purchaseDomains(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_domain_list = createAsyncThunk(
  "get_domain_list",
  async (data, thunkAPI) => {
    try {
      return await getDomains(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const import_testing_domain_db_file = createAsyncThunk(
  "import_testing_domain_db_file",
  async ({ file, domain }, thunkAPI) => {
    try {
      return await importTestDomainDB({ file, domain });
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const add_domain_runner = createAsyncThunk(
  "add_domain_runner",
  async (data, thunkAPI) => {
    try {
      return await addDomainRunner(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const update_domain_runner = createAsyncThunk(
  "update_domain_runner",
  async (data, thunkAPI) => {
    try {
      return await updateDomainRunner(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const set_test_domains = createAsyncThunk(
  "set_test_domains",
  async (domain, thunkAPI) => {
    try {
      return await setTestDomains(domain);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Batchs
export const get_batch_detail = createAsyncThunk(
  "get_batch_detail",
  async (data, thunkAPI) => {
    try {
      return await getBatchDetail(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Batchs
export const get_data_for_export = createAsyncThunk(
  "get_data_for_export",
  async (id, thunkAPI) => {
    try {
      return await getBatchForExport(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_data_for_export2 = createAsyncThunk(
  "get_data_for_export2",
  async (id, thunkAPI) => {
    try {
      return await getBatchForExport2(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Delete Batchs
export const delete_batch = createAsyncThunk(
  "delete_batch",
  async (id, thunkAPI) => {
    try {
      return await deleteBatch(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_google_search_console = createAsyncThunk(
  "get_google_search_console",
  async (data, thunkAPI) => {
    try {
      return await getGoogleConsoles(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Batchs
export const get_testing_domains_industries = createAsyncThunk(
  "get_testing_domains_industries",
  async (thunkAPI) => {
    try {
      return await getTestLinkIndustries();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Domain Runners List
export const get_domain_runners = createAsyncThunk(
  "get_domain_runners",
  async (data, thunkAPI) => {
    try {
      return await getDomainRunners(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_domain_numbers = createAsyncThunk(
  "get_domain_numbers",
  async (thunkAPI) => {
    try {
      return await getDomainNumbers();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const update_domain_numbers = createAsyncThunk(
  "update_domain_numbers",
  async (data, thunkAPI) => {
    try {
      return await updateDomainNumbers(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_pending_domains_phone = createAsyncThunk(
  "get_pending_domains_phone",
  async (payload, thunkAPI) => {
    try {
      return await getPendingDomainsPhone(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const set_alternate_area_codes = createAsyncThunk(
  "set_alternate_area_codes",
  async (payload, thunkAPI) => {
    try {
      return await setAlternateAreaCode(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_detach_domain = createAsyncThunk(
  "get_detach_domain",
  async (payload, thunkAPI) => {
    try {
      return await getDetachDomain(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const add_detach_domain = createAsyncThunk(
  "add_detach_domain",
  async (payload, thunkAPI) => {
    try {
      return await addDetachDomain(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const delete_detach_domain = createAsyncThunk(
  "delete_detach_domain",
  async (payload, thunkAPI) => {
    try {
      return await deleteDetachDomain(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const domainCenterSlice = createSlice({
  name: "domainCenter",
  initialState,
  reducers: {
    resetBatchDetail: (state) => {
      state.batchDetail = [];
    },
    resetBuyingDomains: (state) => {
      state.buyingDomains = [];
    },
    resetPurchaseDomain: (state) => {
      state.purchaseDomains = [];
    },
    domainCenterReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.batches = [];
      state.buyingDomains = [];
      state.batchDetail = [];
      state.message = "";
      state.export_Data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_buying_report.pending, (state) => {
        state.isLoading = true;
        state.buyingDomains = [];
      })
      .addCase(get_buying_report.fulfilled, (state, action) => {
        state.isLoading = false;
        state.buyingDomains = action.payload?.data;
        if (action.payload?.data?.length === 0) {
          toast.error("No record found");
        }
      })
      .addCase(get_buying_report.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_batch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_batch.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_batch.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_batch.pending, (state) => {
        state.isLoading = true;
        // state.batches = [];
      })
      .addCase(get_batch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.batches = action.payload?.data;
      })
      .addCase(get_batch.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_batch_detail.pending, (state) => {
        state.isLoading = true;
        state.batchDetail = [];
      })
      .addCase(get_batch_detail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.batchDetail = action.payload?.data;
      })
      .addCase(get_batch_detail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(delete_batch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_batch.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_batch.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(purchase_domains.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(purchase_domains.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(purchase_domains.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_data_for_export.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_data_for_export.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(get_data_for_export.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_data_for_export2.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_data_for_export2.fulfilled, (state, action) => {
        state.isLoading = false;
        state.export_Data = action.payload.data;
      })
      .addCase(get_data_for_export2.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_google_search_console.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_google_search_console.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchConsole = action.payload.data;
      })
      .addCase(get_google_search_console.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_purchase_domains.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_purchase_domains.fulfilled, (state, action) => {
        state.isLoading = false;
        state.purchaseDomains = action.payload.data;
      })
      .addCase(get_purchase_domains.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_purchase_domain_zips.pending, (state) => {
        state.isLoading = true;
        state.purchaseDomainsZips = [];
      })
      .addCase(get_purchase_domain_zips.fulfilled, (state, action) => {
        state.isLoading = false;
        state.purchaseDomainsZips = action.payload.data;
      })
      .addCase(get_purchase_domain_zips.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_testing_domains_industries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_testing_domains_industries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.testingIndustries = action.payload.data?.result;
      })
      .addCase(get_testing_domains_industries.rejected, (state, action) => {
        state.isLoading = false;
        state.testingIndustries = [];
        toast.error("Got an error to get the missed calls acount");
        // toast.error(action.payload?action.payload:"Cloudn't get the data")
      })
      .addCase(get_domain_ranking_report.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_domain_ranking_report.fulfilled, (state, action) => {
        state.isLoading = false;
        state.domainRanking = action.payload.data;
      })
      .addCase(get_domain_ranking_report.rejected, (state, action) => {
        state.isLoading = false;
        state.domainRanking = {};
        toast.error("Got an error to get the domain ranking");
        // toast.error(action.payload?action.payload:"Cloudn't get the data")
      })
      .addCase(add_domain_ranking_keywords.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_domain_ranking_keywords.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(add_domain_ranking_keywords.rejected, (state, action) => {
        state.isLoading = false;
        // toast.error(action.payload?action.payload:"Cloudn't get the data")
      })
      .addCase(get_domains_drd.pending, (state) => {
        state.domainsDrd = [];
        state.isLoading = true;
      })
      .addCase(get_domains_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.domainsDrd = action.payload.data;
      })
      .addCase(get_domains_drd.rejected, (state, action) => {
        state.isLoading = false;
        state.domainsDrd = [];
        // toast.error(action.payload?action.payload:"Cloudn't get the data")
      })
      .addCase(delete_domain_ranking_keywords.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_domain_ranking_keywords.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_domain_ranking_keywords.rejected, (state, action) => {
        state.isLoading = false;
        // toast.error(action.payload?action.payload:"Cloudn't get the data")
      })
      .addCase(update_domain_ranking_keywords.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_domain_ranking_keywords.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_domain_ranking_keywords.rejected, (state, action) => {
        state.isLoading = false;
        // toast.error(action.payload?action.payload:"Cloudn't get the data")
      })
      .addCase(get_domain_list.pending, (state) => {
        state.domainList = [];
        state.isLoading = true;
      })
      .addCase(get_domain_list.fulfilled, (state, action) => {
        state.isLoading = false;
        state.domainList = action.payload.data;
      })
      .addCase(get_domain_list.rejected, (state, action) => {
        state.isLoading = false;
        state.domainList = [];
        // toast.error(action.payload?action.payload:"Cloudn't get the data")
      })
      .addCase(set_test_domains.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(set_test_domains.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(set_test_domains.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(import_testing_domain_db_file.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(import_testing_domain_db_file.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(import_testing_domain_db_file.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(add_domain_runner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_domain_runner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.domainRunner.records.push(action.payload.data);
      })
      .addCase(add_domain_runner.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(update_domain_runner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_domain_runner.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.domainRunner.records.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.domainRunner[result] = {
          ...state.domainRunner[result],
          ...action.payload.data,
        };
      })
      .addCase(update_domain_runner.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_domain_runners.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_domain_runners.fulfilled, (state, action) => {
        state.isLoading = false;
        state.domainRunner = action.payload.data;
      })
      .addCase(get_domain_runners.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_alternate_domains_phone.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_alternate_domains_phone.fulfilled, (state, action) => {
        state.isLoading = false;
        state.alternatePhoneArea = action.payload.data;
      })
      .addCase(get_alternate_domains_phone.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_domain_numbers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_domain_numbers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.domainNumbers = action.payload.data;
      })
      .addCase(get_domain_numbers.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(update_domain_numbers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_domain_numbers.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.domainNumbers.findIndex(
          (domain) => domain.domain === action?.payload?.data?.domain
        );
        if (result > -1) {
          state.domainNumbers[result] = {
            ...state.domainNumbers[result],
            ...action.payload.data[0],
          };
        }
      })
      .addCase(update_domain_numbers.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_pending_domains_phone.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_pending_domains_phone.fulfilled, (state, action) => {
        state.isLoading = false;
        state.pendingPhone = action.payload.data.records;
      })
      .addCase(get_pending_domains_phone.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(set_alternate_area_codes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(set_alternate_area_codes.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(set_alternate_area_codes.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_namesilo_domains_report.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_namesilo_domains_report.fulfilled, (state, action) => {
        state.isLoading = false;
        state.namesiloDomains = action.payload.data;
      })
      .addCase(get_namesilo_domains_report.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_lead_job_count.pending, (state, action) => {
        const payload = action.meta.arg.dids;
        const mappedArray = state.namesiloDomains?.domainList?.map((item) => {
          const phone = `1${item.phone}`;
          // Check if the phone number is in the phoneNumbers array
          if (payload.includes(phone)) {
            return {
              ...item,
              counts: {
                job_count: 0,
                lead_count: 0,
              },
            };
          } else {
            return item; // No match, return the original object unchanged
          }
        });
        state.namesiloDomains = {
          ...state.namesiloDomains,
          domainList: mappedArray,
        };
        state.isLoading = true;
      })
      .addCase(get_lead_job_count.fulfilled, (state, action) => {
        state.isLoading = false;
        const newArray = state.namesiloDomains?.domainList?.map((item1) => {
          const matchingItems2 = action?.payload?.data?.filter(
            (item2) => item2.did === `1${item1.phone}`
          );
          // Check if any matching items were found
          if (matchingItems2.length > 0) {
            const counts = matchingItems2.reduce(
              (acc, item) => {
                acc.job_count += item.job_created ? item.count : 0;
                acc.lead_count += !item.job_created ? item.count : 0;
                return acc;
              },
              { job_count: 0, lead_count: 0 }
            );
            return {
              ...item1,
              counts,
            };
          } else {
            // No matching items found, return the original object unchanged
            return item1;
          }
        });
        state.namesiloDomains = {
          ...state.namesiloDomains,
          domainList: newArray,
        };
      })
      .addCase(get_lead_job_count.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_detach_domain.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_detach_domain.fulfilled, (state, action) => {
        state.isLoading = false;
        state.detachDomain = action.payload.data;
      })
      .addCase(get_detach_domain.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_detach_domain.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_detach_domain.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_detach_domain.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(add_detach_domain.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_detach_domain.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(add_detach_domain.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
export const {
  resetBatchDetail,
  resetBuyingDomains,
  domainCenterReset,
  resetPurchaseDomain,
} = domainCenterSlice.actions;
export default domainCenterSlice.reducer;
