import React, { useEffect, useState } from "react";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";
import api from "../../services/api";
const NationSiteRating = () => {
  const [searchText, setSearchText] = useState("");
  const [record, setRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const connexListing = record?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });

  const TagsCellRenderer = (params) => {
    const tags = params.value || [];
    const renderedTags = Array.isArray(tags)
      ? tags.map((tag, index) => (
          <span
            key={index}
            style={{
              margin: "2px",
              color: "black",
              borderRadius: 2,
              padding: "0.2em 0.6em 0.3em",
              lineHeight: 1,
              whiteSpace: "nowrap",
              display: "inline-block",
            }}
          >
            {`${index + 1}. ${tag}`}
          </span>
        ))
      : null;

    return (
      <div style={{ display: "flex", flexDirection: "column", width: "auto" }}>
        {renderedTags}
      </div>
    );
  };

  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false },
    { headerName: "Id", field: "id", flex: 1 },
    { headerName: "Name", field: "name", flex: 1, minWidth: 100 },
    { headerName: "Credit", field: "credit", flex: 1, minWidth: 100 },
    { headerName: "Currency", field: "currency", flex: 1, minWidth: 80 },
    { headerName: "Debit Limit", field: "debit_limit", flex: 1 },
    { headerName: "Status", field: "status", flex: 1 },
    { headerName: "Last Call", field: "last_call", flex: 1, minWidth: 100 },
    {
      headerName: "IPS",
      field: "ips",
      flex: 1,
      renderCell: TagsCellRenderer,
      filterable: false,
      minWidth: 130,
    },
    {
      headerName: "SIP Users",
      field: "sip_users",
      flex: 1,
      renderCell: TagsCellRenderer,
      filterable: false,
      minWidth: 130,
    },
    {
      headerName: "Cards",
      field: "cards",
      flex: 1,
      renderCell: TagsCellRenderer,
      filterable: false,
      minWidth: 130,
    },
    {
      headerName: "Contact Name",
      field: "contact_name",
      flex: 1,
      renderCell: TagsCellRenderer,
      filterable: false,
      minWidth: 100,
    },
  ];

  const getConnectList = async () => {
    setIsLoading(true);
    try {
      const res = await api.get("/api/connectcx/getAllCustomers");
      if (res.status === 200 || res.status === 201) {
        setRecord(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error || "An error occurred");
      } else {
        toast.error(err?.response?.data || "An error occurred");
      }
    }
  };
  useEffect(() => {
    getConnectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getRowHeight = (params) => {
    const baseRowHeight = 40;
    const contentHeightConfig = {
      sip_users: { heightPerItem: 25 },
      ips: { heightPerItem: 20 },
      cards: { heightPerItem: 20 },
      contact_name: { heightPerItem: 20 },
    };
    let totalHeight = baseRowHeight;
    const data = params?.model;
    Object.keys(contentHeightConfig).forEach((key) => {
      if (data?.[key] && Array.isArray(data[key])) {
        const numItems = data[key].length;
        totalHeight += contentHeightConfig[key].heightPerItem * numItems;
      }
    });
    return totalHeight;
  };

  return (
    <>
      <PageHeader heading="Connecx Listing" />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={connexListing?.map((record, index) => {
            let counter = index + 1;
            const {
              id,
              name,
              credit,
              currency,
              debit_limit,
              status,
              last_call,
              ips,
              sip_users,
              cards,
              contact_name,
            } = record;
            return {
              counter,
              records: { ...record },
              id,
              name,
              credit,
              currency,
              debit_limit,
              status,
              last_call,
              ips,
              sip_users,
              cards,
              contact_name,
            };
          })}
          totalItems={record?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
          gridOptions={getRowHeight}
        />
      </div>
    </>
  );
};

export default NationSiteRating;
