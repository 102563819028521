import React, { useState } from "react";
import { Button, FormInput, Loader } from "../../components";
import api from "../../services/api";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
const ServerRestartModal = ({ onClose }) => {
  const [isLoader, setIsLoader] = useState(false);
  const [errors, setErrors] = React.useState([]);
  const [payload, setPayload] = useState({
    email: "",
    securepass: "",
    token: "",
  });
  // Zx4&bQa9!mDOM
  function validateForm(data) {
    const errors = [];
    if (!data.email) {
      errors.push({ email: "Email is required" });
    }
    if (!data.securepass) {
      errors.push({ securepass: '"securepass" is required' });
    }
    if (data.securepass && data.securepass !== "Zx4&bQa9!mDOM") {
      errors.push({ securepass: "Incorrect password" });
    }
    return errors;
  }
  const get_token = async () => {
    setErrors([]);
    const errors = validateForm(payload);
    if (errors?.length === 0) {
      setIsLoader(true);
      try {
        const res = await api.post(`/api/open/get_verification_token`, {
          email: payload.email,
          securepass: payload.securepass,
        });
        if (res.status === 200 || res.status === 201) {
          toast.success(
            res?.data?.message || "Token has been sent to your email"
          );
        } else {
          toast.error(res?.data?.message || "Token couldn't be sent");
        }
        setIsLoader(false);
      } catch (err) {
        setIsLoader(false);
        toast.error(err?.response?.data?.message || "Token couldn't be sent");
        console.log("🚀 ~ handleConfirm ~ err:", err);
      }
    } else {
      errorMessage({
        payload: errors,
        setErrors: setErrors,
        action: "Industry Category",
        msg: `Token couldn't be sent`,
      });
    }
  };
  const verify_restart_mongo = async () => {
    setErrors([]);
    const errors = validateForm(payload);
    if (!payload.token) {
      errors.push({ token: '"token" is required' });
    }
    if (errors?.length === 0) {
      setIsLoader(true);
      try {
        const res = await api.post(`/api/open/verify_restart_mongo`, {
          email: payload.email,
          securepass: payload.securepass,
          token: payload.token,
        });
        if (res.status === 200 || res.status === 201) {
          toast.success(res?.data?.message || "Mongo has been restarted");
          onClose();
        } else {
          toast.error(res?.data?.message || "Mongo couldn't be restarted");
        }
        setIsLoader(false);
      } catch (err) {
        setIsLoader(false);
        toast.error(
          err?.response?.data?.message || "Mongo couldn't be restarted"
        );
        console.log("🚀 ~ handleConfirm ~ err:", err);
      }
    } else {
      errorMessage({
        payload: errors,
        setErrors: setErrors,
        action: "Industry Category",
        msg: `Mongo couldn't be restarted`,
      });
    }
  };
  const get_mongo_status = async () => {
    setErrors([]);
    if (errors?.length === 0) {
      setIsLoader(true);
      try {
        const res = await api.post(`/api/open/get_status_mongo`, {
          securepass: payload.securepass,
        });
        console.log("👊 ~ constget_mongo_status= ~ res:", res)
        if (res.status === 200 || res.status === 201) {
          toast.success(res?.data?.message || "Mongo has been restarted");
          onClose();
        } else {
          toast.error(res?.data?.message || "Mongo couldn't be restarted");
        }
        setIsLoader(false);
      } catch (err) {
        setIsLoader(false);
        toast.error(
          err?.response?.data?.message || "Mongo couldn't be restarted"
        );
        console.log("🚀 ~ handleConfirm ~ err:", err);
      }
    } else {
      errorMessage({
        payload: errors,
        setErrors: setErrors,
        action: "Industry Category",
        msg: `Mongo couldn't be restarted`,
      });
    }
  };
  return (
    <div className="fixed inset-0 flex items-start justify-center z-40 bg-black/60">
      <div className="bg-white p-8 rounded-lg shadow-lg relative min-w-[500px] max-w-[90%]">
        {/* <h2 className="text-lg font-semibold mb-4">
          Are you sure want to send card information to the vendor?
        </h2> */}

        <div className="relative flex flex-col">
          <div className="flex gap-y-3 flex-col">
            <FormInput
              onChange={(e) =>
                setPayload({ ...payload, securepass: e.target.value })
              }
              value={payload.securepass}
              name="securepass"
              placeholder={"Password"}
              label="Password"
              type={"text"}
              errors={errors}
            />
            <FormInput
              onChange={(e) =>
                setPayload({ ...payload, email: e.target.value })
              }
              value={payload.email}
              name="email"
              placeholder={"email"}
              label="email"
              type={"email"}
              errors={errors}
            />
            <div>
              <FormInput
                onChange={(e) =>
                  setPayload({ ...payload, token: e.target.value })
                }
                value={payload.token}
                name="token"
                placeholder={"Token"}
                label="Token"
                type={"text"}
                errors={errors}
              />
              {payload.email ? (
                <button
                  onClick={get_token}
                  className="text-blue-500 text-right w-full hover:underline text-sm"
                >
                  Get the token through email
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex justify-between !mt-4">
          <div>
            {isLoader ? (
              <Loader />
            ) : (
              <>
                {payload.token ? (
                  <Button
                    text="Restart Mongo"
                    variant="btn_submit"
                    onClick={verify_restart_mongo}
                    className="ml-2"
                  />
                ) : null}
                {payload.securepass ? (
                  <Button
                    text="Mongo Status"
                    variant="btn_cancel"
                    onClick={get_mongo_status}
                    className="ml-2"
                  />
                ) : null}
              </>
            )}
          </div>
          <Button text="Cancel" variant="btn_danger" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default ServerRestartModal;
