import React, { useState } from "react";
import { DotsLoader, Modal } from "../../components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FaInfoCircle } from "react-icons/fa";
import api from "../../services/api";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

const ResetDomainCloudflare = ({ onCancelForm, modalTitle, selectedData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showMore, setShowMore] = useState(true);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const domainIds = selectedData?.map(({ domain }) => domain);
    try {
      const res = await api.post(`/api/domains/reset_domain_cloudflare`, {
        domains: domainIds,
      });
      if (res.status === 200 || res.status === 201) {
        toast.success(res?.data?.message || "Domains reset successfully");
        onCancelForm();
      } else {
        toast.error(
          res?.data?.message || "Domains couldn't be reset successfully"
        );
      }
    } catch (err) {
      console.error(err);
      toast.error(
        err?.response?.data?.message || "Domains couldn't be reset successfully"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {},
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  return (
    <Modal
      title={modalTitle}
      onCancelModal={onCancelForm}
      formIsValid={formik.isValid}
      onSubmit={formik.handleSubmit}
      isLoading={isLoading}
      onClick={onCancelForm}
    >
      {isLoading && <DotsLoader />}
      <div
        className="flex items-center p-4 mb-4 text-lg text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800"
        role="alert"
      >
        <FaInfoCircle />
        <div>
          <span className="font-medium ml-2">
            You have selected ({selectedData?.length}) domains.
          </span>
        </div>
      </div>
      <div className="flex p-0 mt-0">
        <div
          className="flex-1 bg-gray-100 p-2 shadow w-full"
          style={{ maxHeight: "calc(50vh - 2rem)" }}
        >
          <span
            className="ml-2 text-yellow-800 cursor-pointer font-semibold"
            onClick={toggleShowMore}
          >
            {showMore ? (
              <>
                <IoIosArrowDown className="cursor-pointer mt-1 mr-1 mb-2" />
                Hide Domains..
              </>
            ) : (
              <>
                <IoIosArrowForward className="cursor-pointer mt-1 mb-2" />
                Show Domains..
              </>
            )}
          </span>
          {showMore && (
            <div
              className="overflow-y-auto border-t"
              style={{ maxHeight: "calc(40vh - 2rem - 2.5rem)" }}
            >
              {selectedData?.map((item) => (
                <div key={item._id} className="mb-1 text-blue-600">
                  {item.domain}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ResetDomainCloudflare;
