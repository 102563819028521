const SelectInput = ({ name, className, options, onChange, value, onFocus, onBlur }) => {
  return (
    <select name={name} className={className} onChange={onChange} value={value} onClick={onFocus} onBlur={onBlur}>
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SelectInput;
