import React from "react";
import { FaCaretDown, FaSearch } from "react-icons/fa";
import {
  MdDelete,
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
} from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

function FiltersDropdown({
  options,
  searchable,
  onChange,
  areObjectsEqual,
  value,
  onRemove,
  handleDeleteFilters,
}) {
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const [showfilter, setShowfilter] = React.useState(false);
  const filterRef = React.useRef();
  const [searchText, setSearchText] = React.useState("");
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setShowfilter(false);
    }
  };
  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setShowfilter(false);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  return (
    <div className="relative">
      <button
        className="inline-flex items-center justify-between min-w-[275px] text-[#828282] !p-2 h-10 !border !border-[#a9a9a9] !rounded font-semibold text-sm font-pop whitespace-nowrap"
        onClick={() => setShowfilter(!showfilter)}
      >
        <div>
          {`Filters`}
          {value ? (
            <span className="text-black">{`: ${value?.note}`}</span>
          ) : null}
        </div>
        <div className="flex items-center">
          {value ? (
            <RxCross2
            size={18}
              className="ml-2 mr-2"
              onClick={(event) => {
                event.stopPropagation();
                onRemove();
              }}
            />
          ) : null}

          <FaCaretDown />
        </div>
      </button>
      {showfilter && (
        <div
          ref={filterRef}
          className="absolute bg-white w-auto min-w-[275px] shadow z-10 border-b border-x border-[#ddd] overflow-hidden transition ease-in-out delay-150"
        >
          {options?.length ? (
            <ul className="pl-0 mb-0 max-h-[450px] overflow-y-auto ml-auto">
              {searchable ? (
                <div className="w-100">
                  <input
                    className={
                      "form-control-sm border h-100 w-100 pl-5 !rounded-none"
                    }
                    style={{ minHeight: 31 }}
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                  <div className="absolute top-[3px] left-[10px] border-r pr-[10px] flex justify-center items-center h-[25px]">
                    <FaSearch />
                  </div>
                </div>
              ) : null}
              {options?.map((list, index) => {
                return (
                  <li
                    className="flex items-center justify-between border-t border-[#ddd]"
                    key={index}
                  >
                    <button
                      className={`hover:bg-[#e1e1e1] cursor-pointer w-full px-2.5 py-1.5 flex items-center ${
                        areObjectsEqual(list?.data?.filters || list?.data)
                          ? "text-primary-100"
                          : ""
                      }`}
                      onClick={() => onChange(list.data)}
                    >
                      {areObjectsEqual(list?.data?.filters || list?.data) ? (
                        <MdRadioButtonChecked size={20} className="mr-2" />
                      ) : (
                        <MdRadioButtonUnchecked size={20} className="mr-2" />
                      )}
                      <span className="flex-1 text-left truncate ml-2">{list.note}</span>
                    </button>
                    <button
                      className="px-2.5 py-1.5 hover:text-red-500"
                      onClick={() => handleDeleteFilters(list?._id)}
                    >
                     {list?.user_id === user?._id && <MdDelete />}
                    </button>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className="w-full py-3 flex">
              <strong className="text-center w-full">No record found</strong>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default FiltersDropdown;
