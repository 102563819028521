import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import GoogleConsoleForm from "./GoogleConsoleForm";
import userPermission from "../../util/userPermission";
import api from "../../services/api";
import {
  delete_gsc_email_accounts,
  get_gsc_email_accounts,
  server_deployment,
} from "../../features/googleEmailSlice";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { MdRefresh } from "react-icons/md";
import { Button } from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiOutlineRefresh } from "react-icons/hi";
import GitPullModal from "./GitPullModal";
import { DotsLoader } from "../../components";
import GscPullResponseModal from "./GscPullResponseModal";
const GoogleEmailAccounts = () => {
  const dispatch = useDispatch();
  const { isLoading, emailAccounts } = useSelector(
    (state) => state.googleEmails
  );
  const optionsRef = React.useRef();
  const [searchText, setSearchText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [showMenu, setShowMenu] = useState(null);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isPullModal, setIsPullModal] = useState(false);
  const [isGscId, setGscId] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [emailAccountsData, setEmailAccountsData] = useState(emailAccounts);
  const [loadingRows, setLoadingRows] = useState({});
  const [apiResponses, setApiResponses] = useState([]);
  const [isGscPullModal, setIsGscPullModal] = useState(false);
  const [isErrorLoading, setIsErrorLoading] = useState(false);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  useEffect(() => {
    dispatch(get_gsc_email_accounts());
  }, [dispatch]);

  useEffect(() => {
    setEmailAccountsData(emailAccounts);
  }, [emailAccounts]);
  const googleConsoles = emailAccountsData?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const deleteRecordHandler = async (record_id) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_gsc_email_accounts(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Server deleted successfully");
          dispatch(get_gsc_email_accounts());
        } else {
          errorMessage({
            payload: res.payload,
            action: "Server",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          action: "Google Search Console Account",
          msg: "deleted",
        });
      }
    }
  };
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.info("Refresh Token URL copied successfully");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };
  const handleServerDeployment = async ({ type, server_id }) => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    setShowMenu(false);
    setIsLoader(true);
    try {
      const res = await dispatch(server_deployment({ type, server_id }));
      if (res?.payload?.status === 200) {
        toast.success("Process completed successfully");
      } else {
        toast.error(res?.payload || "Process couldn't be completed");
      }
    } catch (err) {
      toast.error(err.payload || "Process couldn't be completed");
      console.log("👊 ~ handleServerDeployment ~ err:", err);
    } finally {
      setIsLoader(false);
    }
  };
  const testMenu = [
    {
      label: "Edit server",
      action: (records) => openFormHandler(records),
      getIsHide: (records) => false,
    },
    {
      label: "Delete server",
      action: (records) => deleteRecordHandler(records?._id),
      getIsHide: (records) => false,
    },
    {
      label: "Copy Refresh Token URL",
      action: (records) => handleCopy(records?.refresh_token_url),
      getIsHide: (records) => false,
    },
    {
      label: "Prepare Server",
      action: (records) =>
        handleServerDeployment({
          server_id: records._id,
          type: "prepare_server",
        }),
      getIsHide: (records) => records?.server_ready,
    },
    {
      label: "Deploy App",
      action: (records) =>
        handleServerDeployment({ server_id: records._id, type: "deploy_app" }),
      getIsHide: (records) => !records?.server_ready,
    },
    {
      label: "Git Pull",
      action: (records) => handlePullModal(records),
      getIsHide: (records) => !records?.server_ready,
    },
  ];

  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = googleConsoles.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };

  const onSingleSelect = ({ checked, data }) => {
    if (isLoading) {
      return toast.error("Please wait until the previous process is complete.");
    }
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          if (!prevSelectedData.some(({ _id }) => _id === data._id)) {
            return [...prevSelectedData, data];
          }
          return prevSelectedData;
        });
      } else {
        setSelectedData((prevSelectedData) => {
          return prevSelectedData.filter(({ _id }) => _id !== data._id);
        });
      }
    } catch (err) {
      console.error("Error in onSingleSelect:", err);
    }
  };

  const isSelected = (data) => {
    return selectedData.some(({ _id }) => _id === data._id);
  };

  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={
            googleConsoles?.length &&
            selectedData?.length === googleConsoles?.length
          }
          disabled={!googleConsoles?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <div className="">
          {isSelected(params.row.records) && isLoading ? (
            <div>test</div>
          ) : (
            <input
              type="checkbox"
              checked={isSelected(params.row.records)}
              onChange={(e) =>
                onSingleSelect({
                  checked: e.target.checked,
                  data: params.row.records,
                })
              }
              className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
            />
          )}
        </div>
      ),
      disableColumnMenu: true,
    },
    { headerName: "#", field: "counter", width: 60, filterable: false },
    { headerName: "Server IP", field: "server_ip", flex: 1, minWidth: 120 },
    { headerName: "App URL", field: "app_url", flex: 1, minWidth: 120 },
    { headerName: "Email", field: "email", flex: 1, minWidth: 120 },

    {
      headerName: "Domain Count",
      field: "domain_count",
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: "Commit Count",
      field: "commit_count",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        const isRowLoading = loadingRows[params.row.records._id];
        return (
          <div className={`p-2`}>
            {!params.row.server_ready ? (
              <span>N/A</span>
            ) : (
              <span
                className="text-blue-600 cursor-pointer hover:underline"
                onClick={() => handleCommitCount(params?.row?.records)}
              >
                {isRowLoading ? (
                  <span className="text-white bg-primary-100 p-1">
                    Loading...
                  </span>
                ) : (
                  <span>
                    {params?.row?.records?.commit_count
                      ? params?.row?.records?.commit_count
                      : "Get Count"}
                  </span>
                )}
              </span>
            )}
          </div>
        );
      },
    },
    {
      headerName: "Domain Limit",
      field: "domain_limit",
      flex: 1,
      minWidth: 120,
    },

    { headerName: "Client ID", field: "client_id", flex: 1, minWidth: 120 },
    {
      headerName: "Client Secret",
      field: "client_secret",
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: "Refresh Token",
      field: "refresh_token",
      flex: 1,
      minWidth: 120,
    },

    // { headerName: "Password", field: "email_password", flex: 1, minWidth: 100 },
    // { headerName: "Server User", field: "server_user", flex: 1, minWidth: 120 },
    // {
    //   headerName: "Server Ready",
    //   field: "server_ready",
    //   flex: 1,
    //   minWidth: 120,
    // },
    // {
    //   headerName: "App Deployed",
    //   field: "app_deployed",
    //   flex: 1,
    //   minWidth: 120,
    // },
    // {
    //   headerName: "Email User",
    //   field: "email_user_name",
    //   flex: 1,
    //   minWidth: 120,
    // },
    // {
    //   headerName: "Proxy User",
    //   field: "proxy_user",
    //   minWidth: 120,
    // },
    // {
    //   headerName: "Mobile Number",
    //   field: "mobile_num",
    //   flex: 1,
    //   minWidth: 120,
    // },
    // {
    //   headerName: "Mobile Carrier",
    //   field: "mobile_carrier",
    //   flex: 1,
    //   minWidth: 120,
    // },

    {
      headerName: "Action",
      field: "client_sec",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <div>
          <BsThreeDotsVertical
            size={18}
            className="cursor-pointer !relative hover:text-primary-100"
            onClick={() => setShowMenu(params.row.records._id)}
          />
          {showMenu === params.row.records?._id ? (
            <div
              ref={optionsRef}
              className="absolute w-auto right-12 min-w-[100px] rounded shadow !mt-1 bg-white z-20 border-x border-y border-[#ddd] -ml-px overflow-hidden transition ease-in-out delay-150 "
            >
              <ul className="!pl-0 !mb-0">
                {testMenu?.map((option, index) => {
                  const hide = option?.getIsHide(params.row.records);
                  return !hide ? (
                    <li
                      key={index}
                      className="cursor-pointer px-3 !py-1.25 border- border-[#ddd] hover:bg-[#e1e1e1]"
                      onClick={() => option.action(params.row.records)}
                    >
                      {option.label}
                    </li>
                  ) : null;
                })}
              </ul>
            </div>
          ) : null}
          {/* <div className="flex flex-row">
                <FaEdit
                  onClick={openFormHandler(params.row.records)}
                  className="my_navIcon"
                  title="Update Server"
                />
                <FaTrashAlt
                  onClick={deleteRecordHandler(params.row.records._id)}
                  className="my_navIcon"
                  title="Delete Server"
                />
                <FaCopy
                  onClick={() =>
                    handleCopy(params?.row?.records?.refresh_token_url)
                  }
                  className="my_navIcon"
                  title="Copy Refresh Token URL"
                />
              </div> */}
        </div>
      ),
    },
  ];
  const handleHideDropdown = (event) => {
    if (showMenu && event.key === "Escape") {
      setShowMenu(null);
    }
  };
  const handleClickOutside = (event) => {
    if (
      showMenu &&
      optionsRef.current &&
      !optionsRef.current.contains(event.target)
    ) {
      setShowMenu(null);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const RefreshButton = () => {
    return (
      <button
        className="bg-primary-100 text-white ml-2 h-[35px] w-[35px] flex justify-center items-center rounded-sm"
        onClick={() => {
          setEmailAccountsData(emailAccounts);
          dispatch(get_gsc_email_accounts());
          setSelectedData([]);
        }}
      >
        <HiOutlineRefresh />
      </button>
    );
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <Button
          variant="text"
          startIcon={<MdRefresh size={18} />}
          onClick={pullGscRecords}
          sx={{
            borderRadius: "6px",
            marginRight: "5px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "26px",
            fontSize: "0.8125rem",
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          Pull Records
        </Button>
        <Button
          variant="text"
          startIcon={<MdRefresh size={18} />}
          onClick={pullCommitCounts}
          sx={{
            borderRadius: "6px",
            marginRight: "5px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "26px",
            fontSize: "0.8125rem",
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          Get Commit Count
        </Button>
      </GridToolbarContainer>
    );
  }
  const handlePullModal = (records) => {
    setGscId(records?._id);
    setIsPullModal(true);
  };
  const updateCommitCount = (data, idToUpdate, commitCount) => {
    if (!data || !data.records || !Array.isArray(data.records)) {
      console.error("Invalid data structure");
      return data;
    }

    const updatedRecords = data.records.map((record) =>
      record._id === idToUpdate
        ? { ...record, commit_count: commitCount || "N/A" }
        : record
    );

    return { ...data, records: updatedRecords };
  };

  const handleCommitCount = async (record) => {
    setLoadingRows((prev) => ({ ...prev, [record?._id]: true }));

    const payload = {
      server_ip: record?.server_ip,
      type: "gsc_commit",
    };

    try {
      const { status, data } = await api.post(
        `/api/gsc/server/runshell_command`,
        payload
      );
      const commitCount = status === 200 || status === 201 ? data?.output : "";

      setEmailAccountsData((prevData) =>
        updateCommitCount(prevData, record?._id, commitCount)
      );
    } catch (err) {
      toast.error(err.response.data.message || "Process couldn't be Started");
      console.error(err);
    } finally {
      setLoadingRows((prev) => ({ ...prev, [record?._id]: false }));
    }
  };

  const cancelPullFormHandler = () => {
    setIsPullModal(false);
  };

  const fetchApiResponse = async (serverIp) => {
    const payload = {
      server_ip: serverIp,
      type: "gsc_pull",
    };

    try {
      const res = await api.post("/api/gsc/server/runshell_command", payload);
      if (res.status === 200 || res.status === 201) {
        return { server_ip: serverIp, ...res.data };
      } else {
        throw new Error(`Unexpected response status: ${res.status}`);
      }
    } catch (error) {
      console.log("Error fetching data for IP:", serverIp, error);
      return {
        server_ip: serverIp,
        error: error.response.data.message || error.message,
      };
    }
  };
  const pullGscRecords = async () => {
    if (!selectedData?.length) {
      toast.error("Please select any IP!");
      return;
    }
    const c = window.confirm(`Are you sure you want to pull records?`);
    if (!c) return;
    setIsErrorLoading(true);
    try {
      const promises = selectedData?.map((item) =>
        fetchApiResponse(item?.server_ip)
      );
      const responses = await Promise.all(promises);
      setApiResponses(responses);
    } catch (error) {
      toast.error(error || "Error fetching data for IP");
      console.log(error);
    } finally {
      setIsErrorLoading(false);
    }
    setIsGscPullModal(true);
    setSelectedData([]);
  };

  const updateAllCommitCounts = async (
    records,
    setEmailAccountsData,
    setLoadingRows
  ) => {
    setLoadingRows(true);
    try {
      for (const record of records) {
        setLoadingRows((prev) => ({ ...prev, [record._id]: true }));

        const payload = {
          server_ip: record.server_ip,
          type: "gsc_commit",
        };
        try {
          const { status, data } = await api.post(
            `/api/gsc/server/runshell_command`,
            payload
          );
          const commitCount =
            status === 200 || status === 201 ? data?.output : "";
          setEmailAccountsData((prevData) =>
            updateCommitCount(prevData, record._id, commitCount)
          );
        } catch (err) {
          toast.error(
            err.response?.data?.message || "Process couldn't be started"
          );
          console.error(err);
        } finally {
          setLoadingRows((prev) => ({ ...prev, [record._id]: false }));
        }
      }
    } finally {
      setLoadingRows(false);
      toast.success("All IPs synced successfully.");
      setSelectedData([]);
    }
  };
  const pullCommitCounts = async () => {
    const c = window.confirm(`Are you sure you want to get commit counts?`);
    if (!c) return;
    updateAllCommitCounts(selectedData, setEmailAccountsData, setLoadingRows);
  };

  return (
    <>
      <PageHeader
        heading="Server List"
        onClick={() => openFormHandler(0)}
        isAllowed={userPermission("Add Allowed IP")}
      />
      {isEditing && (
        <GoogleConsoleForm
          editingRecord={editingRecord}
          modalTitle="Add Server"
          onCancelForm={cancelFormHandler}
        />
      )}
      {isPullModal ? (
        <GitPullModal
          modalTitle="GSC Domains"
          onCancelForm={cancelPullFormHandler}
          selectedData={isGscId}
        />
      ) : null}
      {isGscPullModal ? (
        <GscPullResponseModal
          modalTitle="GSC Pull Response"
          onCancelForm={() => {
            setSelectedData([]);
            setIsGscPullModal(false);
          }}
          selectedData={apiResponses}
        />
      ) : null}
      {isErrorLoading ? <DotsLoader /> : null}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={googleConsoles?.map((record, index) => {
            let counter = index + 1;
            const {
              server_ip,
              app_url,
              email,
              email_password,
              server_user,
              proxy_user,
              mobile_num,
              mobile_carrier,
              email_user_name,
              app_deployed,
              server_ready,
              domain_count,
              domain_limit,
              client_id,
              client_secret,
              refresh_token,
              commit_count,
            } = record;
            return {
              counter,
              records: { ...record },
              server_ip,
              app_url,
              email,
              email_password,
              server_user,
              proxy_user,
              mobile_num,
              mobile_carrier,
              email_user_name,
              app_deployed: app_deployed ? "Yes" : "No",
              server_ready: server_ready ? "Yes" : "No",
              domain_count,
              domain_limit,
              client_id,
              client_secret,
              refresh_token,
              commit_count: commit_count || "",
            };
          })}
          totalItems={emailAccountsData?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading || isLoader}
          CustomComponent={RefreshButton}
          CustomToolbar={CustomToolbar}
        />
      </div>
    </>
  );
};

export default GoogleEmailAccounts;
