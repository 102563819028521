import dayjs from "dayjs";
import moment from "moment";
import React, { useContext } from "react";
import { FaArrowLeft } from "react-icons/fa";
import GlobalContext from "../context/GlobalContext";

function Days({ events }) {
  const times = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23,
  ];
  const {
    daySelected,
    setShowEventModal,
    setSelectedEvent,
    setViewType,
    setDaySelected,
  } = useContext(GlobalContext);
  const format = "DD-MM-YY";
  const jobs = events?.filter((job) => job?.job_created && job.scheduled_on);
  const todayJobs = jobs?.filter(
    (job) =>
      dayjs(job?.scheduled_on)?.format(format) ===
      dayjs(daySelected).format(format)
  );
  const transformedEvents = todayJobs?.reduce((acc, event) => {
    // Extract the hour part from the 'start' timestamp
    const eventHour = new Date(event.scheduled_on).getHours();
    // Add the eventObject to the respective hour in the accumulator
    if (acc[eventHour]) {
      acc[eventHour].push(event);
    } else {
      acc[eventHour] = [event];
    }

    return acc;
  }, {});

  return (
    <div className="flex-1 w-full px-2 border border-gray-200">
      <div
        onClick={() => {
          setDaySelected(dayjs());
          setViewType("month");
        }}
        className="w-2/3 flex flex-row text-lg font-semibold items-center justify-between p-1 mt-1 rounded cursor-pointer"
      >
        <span className="flex flex-row items-center">
          <FaArrowLeft className="mr-2" />
          Month
        </span>

        <span className="text-gray-500 font-sans">
          There are {todayJobs ? todayJobs?.length : 0} jobs scheduled for{" "}
          {dayjs(daySelected)?.format("ddd, MMM D, YYYY")}
        </span>
      </div>

      {times?.map((time) => {
        const formattedTime = moment().set("hours", time).format("h a");
        return (
          <div className="flex flex-row w-full">
            <div className="text-[12px] text-[#212121] text-right p-[6px] block box-border w-[10%]">
              <span>{formattedTime}</span>
            </div>
            <div className="border border-[#e0e0e0] w-full flex flex-col gap-2 h-auto min-h-[40px] p-1">
              {todayJobs &&
                transformedEvents[time] &&
                transformedEvents[time]?.map((event) => (
                  <div
                    className="cursor-pointer !w-auto h-auto flex items-center rounded-sm text-black py-[2px] px-[4px] text-[12px]"
                    onClick={() => {
                      setSelectedEvent(event);
                      setShowEventModal(true);
                    }}
                  >
                    <span className="bg-[#c7d2fe] border border-[#818cf8] px-1">
                      {event.industry_id?.name}
                      {" - "}
                      {event?.job_created ? "Job#" : "Lead#"}{" "}
                      {event?.job_created ? event?.job_number : event?.lead_num}
                      {" | "}
                      {event?.complete_pickup_address || ""}
                      {" | "}
                      {moment(event?.scheduled_on).format("hh:mm a")} -{" "}
                      {moment(
                        event?.scheduled_end || event?.scheduled_on
                      ).format("hh:mm a")}
                    </span>
                    {event?.vendor_id ? (
                      <span className="bg-[#c7d2fe] border border-[#818cf8] ml-1.5 px-1">
                        {event?.vendor_id?.first_name
                          ? event?.vendor_id?.first_name
                          : ""}{" "}
                        {event?.vendor_id?.last_name
                          ? event?.vendor_id?.last_name
                          : ""}
                      </span>
                    ) : null}
                    {event?.job_status_id ? (
                      <span className="bg-[#c7d2fe] border border-[#818cf8] ml-1.5 px-1">
                        {event?.job_status_id?.name}
                      </span>
                    ) : null}
                  </div>
                ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default Days;
