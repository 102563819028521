import React, { useState } from "react";
import PageHeader from "../../components/molecules/PageHeader";
import UserChangePassword from "./ProfilePassword/UserChangePassword";
import CompanyEmail from "./ProfilePassword/CompanyEmail";
import CompanyPassword from "./ProfilePassword/CompanyPassword";

const UserProfile = () => {
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const showRightSection = user?.comapny_email ? true : false;
  return (
    <>
      <PageHeader heading="Change Password" />
      <div className="bg-white my-3 border rounded">
      <div
        className={`flex ${
          showRightSection ? "grid md:grid-cols-2 gap-x-2" : "justify-center"
        } ${showRightSection ? "" : "h-full"} p-4`}
      >
        <UserChangePassword showRightSection={showRightSection} />
        {showRightSection && (
          <div className="w-full">
            <CompanyEmail companyEmail={user?.comapny_email || ""} />
            <CompanyPassword />
          </div>
        )}
      </div>
      </div>
    </>
  );
};

export default UserProfile;
