import React, { useState, useEffect } from "react";
import { MultiSelect } from "primereact/multiselect";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import api from "../../services/api";
import { Loader } from "../../components";
const AssignIndustries = ({ onClose, userDetail }) => {
  const { industryDrd } = useSelector((state) => state.industry);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedIndustries, setSelectedIndustries] = useState([]);

  const getAssignedIndustries = async () => {
    setIsLoader(true);
    try {
      const res = await api.get(
        `/api/industries/user_industries/${userDetail?._id}`
      );
      if (res.status === 200) {
        setSelectedIndustries(res.data);
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      console.log("🚀 ~ getAssignedIndustries ~ err:", err);
    }
  };
  useEffect(() => {
    getAssignedIndustries();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = async () => {
    if (selectedIndustries?.length === 0) {
      return toast.error("Please select the industries");
    }

    setIsLoader(true);
    try {
      const res = await api.post(
        `/api/industries/user_industries/${userDetail?._id}`,
        { industries: selectedIndustries }
      );
      if (res.status === 200) {
        toast.success("Industries assigned successfully");
        onClose();
      } else {
        toast.error("Industries couldn't be assigned");
      }
    } catch (err) {
      console.log("🚀 ~ handleConfirm ~ err:", err);
      toast.error("Industries couldn't be assigned");
      setIsLoader(false);
    }
  };

  return (
    <>
      <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40">
        <div className="bg-white p-8 rounded-lg shadow-lg relative w-[400px]">
          {/* {isLoader?<DotsLoader/>:null} */}
          <h2 className="text-lg font-semibold mb-4">
            Assign Industries to{" "}
            <span className="text-primary-100">{`${
              userDetail?.first_name || ""
            } ${userDetail?.last_name || ""}`}</span>
          </h2>
          <div className=" !px-0.5 !mb-3">
            <label className="block">Select Industries:</label>
            <MultiSelect
              placeholder="Industries"
              options={industryDrd}
              optionLabel="name"
              optionValue="_id"
              filter
              maxSelectedLabels={2}
              value={selectedIndustries}
              onChange={(e) => setSelectedIndustries(e.value)}
              disabled={isLoader}
              className="h-[38px] w-full !rounded-[4px] border !border-[#e5e7eb] hover:border-[#ced4da]"
            />
          </div>
          <div className="flex justify-end !mt-4">
            {isLoader ? (
              <Loader />
            ) : (
              <>
                <button
                  className="px-4 py-2 mr-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  onClick={handleConfirm}
                >
                  OK
                </button>
                <button
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignIndustries;
