import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  create_call_centers,
  update_call_centers,
} from "../../../features/callCentersSlice";
import Button from "../../../components/atoms/Button";
import api from "../../../services/api";
import { FaEye, FaEyeSlash } from "react-icons/fa";
function AddUpdateForm({
  editingRecord,
  onCancelForm,
  modalTitle,
  onSave,
  newRecord,
  userPermission,
}) {
  const { isLoading } = useSelector((state) => state.callCenters);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [restartData, setRestartData] = React.useState({
    selectedType: editingRecord.restart_type ? editingRecord.restart_type : "",
    userToRestart: editingRecord.user_to_restart || "",
    passwordToRestart: editingRecord.pswrd_to_restart || "",
  });
  const [showPass, setShowPass] = React.useState({
    databasePass: false,
    password: false,
    configPass: false,
  });
  const dispatch = useDispatch();
  const initialValues = {
    url: "",
    host: "",
    db_name: "",
    db_password: "",
    port: "",
    username: "",
    password: "",
    default_dialer_phone: "",
    active: true,
    ...(editingRecord && {
      _id: editingRecord._id,
      url: editingRecord.url,
      host: editingRecord.host,
      db_name: editingRecord.db_name,
      active: editingRecord.active,
      db_password: editingRecord.db_password,
      port: editingRecord.port,
      username: editingRecord.username,
      password: editingRecord.password,
      default_dialer_phone: editingRecord?.default_dialer_phone,
    }),
  };
  const handleSubmit = async (values) => {
    const action = editingRecord ? update_call_centers : create_call_centers;
    const message = editingRecord ? "updated" : "created";
    try {
      const res = await dispatch(action(values));
      if (res.payload?.status === 200) {
        toast.success(`Call Center successfully  ${message}`);
        onSave(!newRecord);
        onCancelForm();
      } else {
        if (Array.isArray(res.payload) || typeof res.payload === "object") {
          setErrors(res.payload);
        } else {
          toast.error(
            res.payload ? res.payload : `Call Center couldn't be ${message}`
          );
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload)) {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload ? error.payload : `Call Center couldn't be ${message}`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues,

    validateOnBlur: true,
    // validationSchema: CallCenters,
    onSubmit: handleSubmit,
  });

  const handleSubmitConfig = async () => {
    const errors = [];
    if (
      !restartData.userToRestart.trim() &&
      restartData.selectedType !== "By_Key"
    ) {
      errors.push({ userToRestart: "Config User is required" });
    }

    if (
      !restartData.passwordToRestart.trim() &&
      restartData.selectedType !== "By_Key"
    ) {
      errors.push({ passwordToRestart: "Config Password is required" });
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors(errors);
    }
    try {
      const payload = {
        restart_type: restartData.selectedType,
      };
      if (restartData.selectedType !== "By_Key") {
        payload.user_to_restart = restartData.userToRestart;
        payload.pswrd_to_restart = restartData.passwordToRestart;
      }
      const res = await api.put(
        `/api/call_centers/restart_config/${editingRecord._id}`,
        payload
      );
      if (res.status === 200 || res.status === 201) {
        toast.success(
          res?.data?.message || "Call Center Config added successfully"
        );
        setLoading(false);
        onCancelForm();
      } else {
        toast.error(
          res?.data?.message ||
            "Call Center config couldn't be added successfully"
        );
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      toast.error(
        err?.response?.data?.message ||
          "Call Center config couldn't be added successfully"
      );
      setLoading(false);
    }
  };
  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
        hideButtons={true}
      >
        {isLoading || loading ? <DotsLoader /> : null}
        <div className="pt-0  m-auto ">
          <form className="grid md:grid-cols-3 gap-x-3 mt-5 mx-2.5">
            <div className="mb-3">
              <FormInput
                errors={errors}
                name="url"
                label="URL"
                formik={formik}
              />
            </div>
            <div className="mb-3">
              <FormInput
                errors={errors}
                name="host"
                label="Host"
                formik={formik}
              />
            </div>
            <div className="mb-3">
              <FormInput
                errors={errors}
                name="db_name"
                label="Database Name"
                formik={formik}
              />
            </div>
            <div className="mb-3 col-span-1 relative ">
              <FormInput
                errors={errors}
                name="db_password"
                label="Database Password"
                formik={formik}
                type={showPass.databasePass ? "text" : "password"}
                className="pr-10" // Add padding to the right
              />
              <div
                className="absolute top-1/2 transform -translate-y-1/2 right-3 border-l border-[#dee2e6] rounded flex justify-center items-center h-[25px] px-1.5 cursor-pointer"
                onClick={(e) =>
                  setShowPass((prevState) => ({
                    ...prevState,
                    databasePass: !showPass.databasePass,
                  }))
                }
              >
                {showPass.databasePass ? (
                  <FaEye className="text-black" />
                ) : (
                  <FaEyeSlash className="text-black" />
                )}
              </div>
            </div>
            <div className="mb-3">
              <FormInput
                errors={errors}
                name="username"
                label="User Name"
                formik={formik}
              />
            </div>
            <div className="mb-3 col-span-1 relative">
              <FormInput
                errors={errors}
                name="password"
                label="Password"
                formik={formik}
                type={showPass.password ? "text" : "password"}
                className="pr-10" // Add padding to the right
              />
              <div
                className="absolute top-1/2 transform -translate-y-1/2 right-3 border-l border-[#dee2e6] rounded flex justify-center items-center h-[25px] px-1.5 cursor-pointer"
                onClick={(e) =>
                  setShowPass((prevState) => ({
                    ...prevState,
                    password: !showPass.password,
                  }))
                }
              >
                {showPass.password ? (
                  <FaEye className="text-black" />
                ) : (
                  <FaEyeSlash className="text-black" />
                )}
              </div>
            </div>
            <div className="mb-3 col-span-1">
              <FormInput
                errors={errors}
                name="port"
                label="Port"
                formik={formik}
              />
            </div>
            <div className="mb-3 col-span-1">
              <FormSelectInput
                errors={errors}
                name="active"
                label="Active"
                formik={formik}
                options={[
                  { value: "true", label: "Yes" },
                  { value: "false", label: "No" },
                ]}
                convertor={(value) => value === "true"}
                valueProp="value"
                labelProp="label"
              />
            </div>
            <div className="mb-3 col-span-1">
              <FormInput
                errors={errors}
                name="default_dialer_phone"
                label="Default Dialer Phone"
                formik={formik}
              />
            </div>
          </form>
          <div className="!p-4 text-right">
            <Button
              text="Cancel"
              className="mx-2"
              onClick={onCancelForm}
              variant="btn_cancel"
            />
            <Button
              text={"Submit"}
              className=""
              onClick={formik.handleSubmit}
              isLoading={isLoading}
              variant="btn_submit"
            />
          </div>
        </div>
        {editingRecord &&
        editingRecord._id &&
        userPermission("Restart Call Center") ? (
          <div className="pt-0 m-auto gap-4">
            <form className="mt-0 grid grid-cols-2 gap-4">
              <div className="col-span-3 mb-3">
                <h5 className="text-primary-100 border-b border-primary-100 pb-2">
                  Config Call Center
                </h5>
              </div>
              <FormSelectInput
                name="type"
                label="Type"
                options={[
                  { value: "By_Key", label: "By Key" },
                  { value: "Password", label: "Password" },
                ]}
                valueProp="value"
                labelProp="label"
                value={restartData.selectedType}
                onChange={(e) =>
                  setRestartData((prevState) => ({
                    ...prevState,
                    selectedType: e,
                  }))
                }
              />
              {restartData.selectedType === "Password" && (
                <>
                  <FormInput
                    name="userToRestart"
                    label="User"
                    value={restartData.userToRestart}
                    onChange={(e) =>
                      setRestartData((prevState) => ({
                        ...prevState,
                        userToRestart: e.target.value,
                      }))
                    }
                    errors={errors}
                    className="col-span-1"
                  />
                  <div className="mb-3 col-span-1 relative">
                    <FormInput
                      name="passwordToRestart"
                      label="Password"
                      value={restartData.passwordToRestart}
                      onChange={(e) =>
                        setRestartData((prevState) => ({
                          ...prevState,
                          passwordToRestart: e.target.value,
                        }))
                      }
                      errors={errors}
                      className="col-span-1"
                      type={showPass.configPass ? "text" : "password"}
                    />
                    <div
                      className="absolute top-1/2 transform -translate-y-1/2 right-3 border-l border-[#dee2e6] rounded flex justify-center items-center h-[25px] px-1.5 cursor-pointer"
                      onClick={(e) =>
                        setShowPass((prevState) => ({
                          ...prevState,
                          configPass: !showPass.configPass,
                        }))
                      }
                    >
                      {showPass.configPass ? (
                        <FaEye className="text-black" />
                      ) : (
                        <FaEyeSlash className="text-black" />
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className="col-span-3 mt-0">
                <div className="text-right">
                  <Button
                    text={"Save Config"}
                    className=""
                    onClick={handleSubmitConfig}
                    isLoading={isLoading}
                    variant="btn_submit"
                  />
                </div>
              </div>
            </form>
          </div>
        ) : null}
      </Modal>
    </>
  );
}

export default AddUpdateForm;
