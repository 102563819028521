import React, { useState, useEffect } from "react";
import CustomModal from "../../components/molecules/Modal/customModal";
import api from "../../services/api";
import { DotsLoader } from "../../components";
const GitPullModal = ({ onCancelForm, modalTitle, selectedData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [gscServerDomains, setGscServerDomains] = useState([]);

  const getGscServerDomains = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`/api/gsc/server/domains/${selectedData}`);
      if (res.status === 200 || res.status === 201) {
        setGscServerDomains(res?.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    getGscServerDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <CustomModal
        handleModal={onCancelForm}
        cancelButtonRef={onCancelForm}
        className="max-w-3xl"
        modalType={true}
        title={modalTitle}
        hideButton={true}
      >
        {isLoading ? <DotsLoader /> : null}
        <div className="flex p-0 mt-2">
          <div
            className="flex-1 bg-gray-100 p-4 shadow w-full"
            style={{ maxHeight: "calc(80vh - 2rem)" }}
          >
            <div
              className="overflow-y-auto"
              style={{ maxHeight: "calc(80vh - 2rem - 2.5rem)" }}
            >
              {gscServerDomains && gscServerDomains.length ? (
                <div
                  className="flex-1 bg-gray-100 p-4  ml-4"
                  style={{ maxHeight: "calc(80vh - 2rem)" }}
                >
                  <h2 className="text-lg font-semibold mb-2 sticky top-0 bg-gray-100">
                    GSC Server Domains ({gscServerDomains.length})
                  </h2>
                  <div
                    className="overflow-y-auto"
                    style={{ maxHeight: "calc(70vh - 2rem - 2.5rem)" }}
                  >
                    {gscServerDomains.map((item) => (
                      <div key={item._id} className="mb-1 text-blue-600">
                        {item.domain}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <>
                  {!isLoading && (
                    <div className="mb-1 text-black text-center">
                      No Records
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default GitPullModal;
