import api from "./api";

export const createMerchant = async (data) => {
  return await api.post("/api/merchants", data);
};

export const getMerchants = async (data) => {
  return await api.post("/api/merchants/report", data);
};

export const updateMerchants = async (data) => {
  return await api.put("/api/merchants", data);
};
export const updateMerchantsCx = async (data) => {
  return await api.post("/api/merchants/conexcs_settings", data);
};
export const syncMerchantsCx = async (data) => {
  return await api.post("/api/merchants/conexcs_settingss", data);
};
export const deleteMerchant = async (id) => {
  return await api.delete("/api/merchants", { data: { id } });
};

export const getMerchantsCompanies = async (params) => {
  return await api.get("/api/merchants/companies_by_call_center", { params });
};
