import api from "./api";

export const getTlds = async () => {
    return await api.get("/api/tld");
};

export const createTld = async (name) => {
  return await api.post("/api/tld", { name });
};

export const updateTld = async (id, name) => {
  return await api.put(`/api/tld/${id}`, { name });
};

export const deleteTld = async (id) => {
  return await api.delete(`/api/tld/${id}`);
};
