import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { FaEdit } from "react-icons/fa";
import { MdOutlineRestartAlt } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../../components/molecules/PageHeader";
import {
  get_call_centers,
  callCenterReset,
} from "../../../features/callCentersSlice";
import userPermission from "../../../util/userPermission";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import AddUpdateRestartForm from "./AddUpdateRestartForm";
const CallCenters = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.callCenters);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newRecord, setNewRecord] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isRestartModal, setRestartModal] = useState(false);
  const [isErrorResponse, setErrorResponse] = useState([]);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  useEffect(() => {
    dispatch(get_call_centers());
    return () => {
      dispatch(callCenterReset());
    };
    // eslint-disable-next-line
  }, []);

  const callCenter = record?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const openRestartForm = (record) => {
    setEditingRecord(record);
    setRestartModal(true);
  };
  const columnDefs = [
    { headerName: "#", field: "counter" },
    { headerName: "DataBase Name", field: "db_name", flex: 1 },
    { headerName: "URL", field: "url", flex: 1 },
    { headerName: "Port", field: "port", flex: 1 },
    { headerName: "Default Phone", field: "default_dialer_phone", flex: 1 },
    { headerName: "Status", field: "status", flex: 1 },
    {
      field: "actions",
      renderCell: (params) => (
        <div>
          {userPermission("Update Call Center") && (
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon mt-1"
              title="Update Call Center"
            />
          )}
          {userPermission("Restart Call Center") && (
            <MdOutlineRestartAlt
              onClick={(e) => openRestartForm(params.row.records)}
              className="my_navIcon"
              title="Restart"
              size={20}
            />
          )}
        </div>
      ),
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Call Center"
          onCancelForm={cancelFormHandler}
          onSave={setNewRecord}
          newRecord={newRecord}
          userPermission={userPermission}
        />
      )}
      <PageHeader
        route="Call Center > Call Centers"
        heading="Call Centers Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Call Center")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={callCenter?.map((record, index) => {
            let counter = index + 1;
            let db_name = record.db_name;
            let port = record.port;
            let url = record.url;
            let status = record.active ? "Active" : "InActive";
            let default_dialer_phone = record.default_dialer_phone;
            return {
              counter,
              records: record,
              db_name,
              port,
              url,
              status,
              default_dialer_phone,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          paginationMode="client"
        />
      </div>
      {isRestartModal && (
        <AddUpdateRestartForm
          modalTitle="Restart Call Center"
          onCancelForm={cancelFormHandler}
          editingRecord={editingRecord}
          onClose={() => {
            setRestartModal(false);
          }}
          setErrorResponse={setErrorResponse}
        />
      )}
      {isErrorResponse && isErrorResponse?.length > 0 ? (
        <div className="fixed inset-0 flex items-center justify-center z-50 top-0 left-0 right-0 bottom-0 bg-black/50 px-4">
          <div className="bg-white p-8 rounded-lg shadow-lg min-w-[250px] max-w-[80%]">
            <h1 className="text-center border-b pb-1 font_pop font-semibold text-lg">
              Response for Restart Call Center
            </h1>
            <div className="max-h-[70vh] overflow-y-auto">
              <div className="border-b mb-3">
                {isErrorResponse?.includes("\n")
                  ? isErrorResponse?.split("\n")?.map((txt) => {
                      return <div className="text-lg font-medium">{txt}</div>;
                    })
                  : ""}
              </div>
            </div>
            <div className="mt-2 flex justify-end items-center">
              <button
                className="border rounded bg-red-600 text-white p-2 text-sm"
                onClick={() => {
                  setErrorResponse([]);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CallCenters;
