import { FormControl, InputLabel, Select } from "@mui/material";

const SelectForm = ({
  name,
  label,
  options = [],
  valueProp,
  labelProp,
  disabled,
  onChange,
  value,
}) => {
  return (
    <FormControl
      sx={{ mt: 0, minWidth: 120, borderColor: "#6666" }}
      fullWidth
      variant="outlined"
    >
      <InputLabel htmlFor="grouped-native-select">{label}</InputLabel>
      <Select
        sx={{
          borderColor: "#6666",
          mt: 0,
        }}
        defaultValue=""
        id={name}
        label={label}
        size="small"
        fullWidth
        native
        name={name}
        variant="outlined"
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        <option value="" label=""></option>
        {options.length > 0 &&
          options.map((option) => (
            <option key={option[valueProp]} value={option[valueProp]}>
              {option[labelProp]}
            </option>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectForm;
