import React, { useEffect, useState } from "react";
import { FaTrashAlt, FaEdit, FaExchangeAlt, FaSearch } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import dayjs from "dayjs";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import {
  delete_geo_vendors,
  get_all_geo_vendors,
  update_geo_vendors,
} from "../../features/GeoVendorsSlice";
import { useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import TagsInput from "../../components/atoms/CustomTagsInput";
import PhoneInput from "react-phone-number-input";
import FormRadioGroup from "../../components/molecules/FormRadioGroup";
import { Loader } from "../../components";
import { Dropdown } from "primereact/dropdown";
import MerchantChange from "./MerchantChange";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button } from "@mui/material";
import api from "../../services/api";
import CreateUsernameModal from "./CreateUserName";
const Vendors = () => {
  const { isLoading, allVendors, isAllRecordLoading, updateLoader } =
    useSelector((state) => state.geoVendors);
  const [isLoader, setIsLoader] = useState(false);
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const [snackbar, setSnackbar] = React.useState(null);
  const [editCell, setEditCell] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [editingRecord, setEditingRecord] = useState(null);
  const [idsArray, setIdsArray] = useState([]);
  const [emailIdsArray, setEmailIdsArray] = useState([]);
  const [isMerchantChange, setIsMerchantChange] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [vendorType, setVendorType] = useState("Own");
  const [isUserCreate, setIsUserCreate] = useState(false);

  const [quickFilters, setquickFilters] = useState([
    {
      field: "active",
      operator: "is",
      value: true,
    },
    {
      field: "deleted",
      operator: "is",
      value: false,
    },
  ]);
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "AND",
    rules: [],
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsMerchantChange(true);
  };
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 1000,
    page: 1,
  });
  const sendVerificationEmail = async (mail) => {
    const c = window.confirm(
      "Are you sure want to send the verification email?"
    );
    if (!c) return;
    setIsLoader(true);
    try {
      const res = await api.post(
        "/api/vendors/send-vendor-verification-email",
        {
          email: mail,
        }
      );
      if (res.status === 200) {
        toast.success(
          res.data?.message || "Verification email send successfully"
        );
      } else {
        toast.error(res.data?.message || "Verification email couldn't be send");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error(
        err?.response?.data?.message || "Verification email couldn't be send"
      );
    }
  };
  const RenderRecipentsEditInputCell = (params) => {
    return (
      <div className="w-full !flex !justify-center !items-center">
        {updateLoader ? (
          <div className="w-full flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <TagsInput
            tags={editCell?.recipients ? editCell?.recipients : []}
            setTags={(e) => {
              setEditCell({ ...editCell, recipients: e });
            }}
            placeholder="Add Phone Number"
            phoneInput={true}
            isFloat
          />
        )}
      </div>
    );
  };
  const renderDIDEditInputCell = (params) => {
    return (
      <div className="w-full !flex !justify-center !items-center">
        {updateLoader ? (
          <div className="w-full flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <PhoneInput
            defaultCountry="US"
            className={[
              " appearance-none !borde !border-[#a9a9a9] rounded max-[400px]:w-[100%] min-[400px]:w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none",
              `rounded-l-none`,
            ].join(" ")}
            onChange={(e) => setEditCell({ ...editCell, unique_did: e })}
            value={editCell.unique_did}
            placeholder={"Unique SMS DID"}
            initialValueFormat="national"
          />
        )}
      </div>
    );
  };
  const renderClientMaskingEditInputCell = (params) => {
    return (
      <div className="w-full !flex !justify-center !items-center">
        {updateLoader ? (
          <div className="w-full flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <FormRadioGroup
            name="client_phone_masking"
            isOnChange={(e) =>
              setEditCell({ ...editCell, client_phone_masking: e })
            }
            labelProp="label"
            valueProp="value"
            options={[
              { label: "Yes", value: 1 },
              { label: "No", value: 0 },
            ]}
            isCheck={+editCell?.client_phone_masking}
            isHorizontal
          />
        )}
      </div>
    );
  };
  const exportOption = [
    {
      label: "Current Page",
      value: "current_record",
    },
    {
      label: "All Records",
      value: "all_record",
    },
  ];
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_geo_vendors(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Vendor deleted");
        } else {
          errorMessage({
            payload: res.payload,
            action: "Vendor",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Vendor", msg: "deleted" });
      }
    }
  };

  useEffect(() => {
    setSearchText("");
    // eslint-disable-next-line
  }, []);
  const activeOption = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];
  const deleteOption = [
    { value: true, label: "Deleted" },
    { value: false, label: "Not Deleted" },
  ];
  const TagsCellRenderer = (data) => {
    const values = idsArray?.includes(data?.row?._id)
      ? data?.value
      : data?.value?.slice(0, 1);
    const tags = values?.map((tag, index) => (
      <span
        key={tag.id}
        style={{
          margin: "2px",
          color: "black",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
          // backgroundColor:"#f5f5f5"
        }}
      >
        {index + 1}. {tag?.name}
        {/* {industry?.record?.find(({ id }) => id === tag?.id)?.industry_name} */}
      </span>
    ));

    return (
      <div className="flex flex-col">
        <div className="flex flex-col w-auto">{tags}</div>
        {data?.value?.length > 1 ? (
          !idsArray?.includes(data?.row?._id) ? (
            <div
              className="flex flex-col w-auto ml-3 text-blue-600 cursor-pointer"
              onClick={() => setIdsArray([...idsArray, data?.row?._id])}
            >
              {`See More (${data?.value?.length - 1})`}
            </div>
          ) : (
            <div
              className="flex flex-col w-auto ml-3 text-blue-600 cursor-pointer"
              onClick={() =>
                setIdsArray((preArray) => {
                  if (!preArray || !data?.row?._id) return preArray;
                  return preArray.filter((id) => id !== data.row._id);
                })
              }
            >
              {`See Less`}
            </div>
          )
        ) : null}
      </div>
    );
  };
  const EmailCellRenderer = (data) => {
    const values = emailIdsArray?.includes(data?.row?._id)
      ? data?.value
      : data?.value?.slice(0, 1);
    const tags = values?.map((tag, index) => (
      <span
        key={tag.id}
        style={{
          margin: "2px",
          color: "black",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
          // backgroundColor:"#f5f5f5"
        }}
      >
        {index + 1}. {tag}
        {/* {industry?.record?.find(({ id }) => id === tag?.id)?.industry_name} */}
      </span>
    ));

    return (
      <div className="flex flex-col">
        <div className="flex flex-col w-auto">{tags}</div>
        {data?.value?.length > 1 ? (
          !emailIdsArray?.includes(data?.row?._id) ? (
            <div
              className="flex flex-col w-auto ml-3 text-blue-600 cursor-pointer"
              onClick={() =>
                setEmailIdsArray([...emailIdsArray, data?.row?._id])
              }
            >
              {`See More (${data?.value?.length - 1})`}
            </div>
          ) : (
            <div
              className="flex flex-col w-auto ml-3 text-blue-600 cursor-pointer"
              onClick={() =>
                setEmailIdsArray((preArray) => {
                  if (!preArray || !data?.row?._id) return preArray;
                  return preArray.filter((id) => id !== data.row._id);
                })
              }
            >
              {`See Less`}
            </div>
          )
        ) : null}
      </div>
    );
  };
  const RecipentsCellRenderer = (data) => {
    const items =
      data?.value && !Array.isArray(data?.value)
        ? JSON.parse(data?.value)
        : Array.isArray(data?.value)
        ? data?.value
        : [];
    const tags = items?.map((tag, index) => (
      <span
        key={tag}
        style={{
          margin: "2px",
          color: "black",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
          // backgroundColor:"#f5f5f5"
        }}
      >
        {index + 1}. {tag}
      </span>
    ));

    return <div className="flex flex-col w-auto">{tags}</div>;
  };
  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    const items =
      data?.recipients && !Array.isArray(data?.recipients)
        ? JSON.parse(data?.recipients)
        : Array.isArray(data?.recipients)
        ? data?.recipients
        : [];
    // const items = data?.recipients ? JSON.parse(data?.recipients) : [];
    const numberOfLines = items.length ? items.length : 0;
    const addressHeight = rowHeight + numberOfLines * 20;
    if (
      (data?.industry_ids && data?.industry_ids.length > 1) ||
      (data?.emails && data?.emails.length > 1)
    ) {
      if (!idsArray?.includes(data?._id) && !emailIdsArray?.includes(data?._id))
        return rowHeight + 15;
      const tagHeight = 25; // height of each tag
      const numTags = data.industry_ids.length;
      const emailNum = data.emails.length;
      if (addressHeight > rowHeight + tagHeight * numTags + cellContentHeight) {
        return addressHeight;
      } else if (
        idsArray?.includes(data?._id) &&
        !emailIdsArray?.includes(data?._id)
      ) {
        return rowHeight + tagHeight * numTags + cellContentHeight;
      } else if (
        !idsArray?.includes(data?._id) &&
        emailIdsArray?.includes(data?._id)
      ) {
        return rowHeight + tagHeight * emailNum + cellContentHeight;
      } else if (
        emailNum > numTags &&
        idsArray?.includes(data?._id) &&
        emailIdsArray?.includes(data?._id)
      ) {
        return rowHeight + tagHeight * emailNum + cellContentHeight;
      } else {
        return rowHeight + tagHeight * numTags + cellContentHeight;
      }
    }
    if (addressHeight > rowHeight + cellContentHeight) {
      return addressHeight;
    } else {
      return rowHeight + cellContentHeight;
    }
  };
  const columnDefs = [
    {
      headerName: "#",
      field: "counter",
      flex: 1,
      width: 60,
      filterable: false,
    },
    {
      headerName: "Action",
      field: "actions",
      flex: 1,
      renderCell: (params) => {
        return !params.row.records.deleted ? (
          <div>
            <FaEdit
              onClick={() =>
                navigate(
                  `/settings/vendors/update/${params.row.records?._id}`,
                  {
                    state: params.row.records,
                  }
                )
              }
              className="my_navIcon"
              title="Update Vendor"
            />
            <FaTrashAlt
              onClick={deleteRecordHandler(params.row.records?._id)}
              className="my_navIcon"
              title="Delete Vendor"
            />
            {user?.role === "SUPER ADMINISTRATOR" &&
            !params?.row?.parentName ? (
              <FaExchangeAlt
                onClick={openFormHandler(params.row.records)}
                className="my_navIcon"
              />
            ) : null}
          </div>
        ) : (
          <span className="text-red-600">Deleted</span>
        );
      },
      minWidth: 100,
      filterable: false,
    },
    // {
    //   headerName: "Status",
    //   field: "active",
    //   flex: 1,
    //   minWidth: 100,
    //   filterOperators: customOperatorsYesorNo,
    // },
    {
      field: "active",
      headerName: "Status",
      type: "singleSelect",
      flex: 1,
      minWidth: 100,
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value?.label,
      valueOptions: activeOption,
      renderCell: (params) => params.value?.active,
      valueGetter: (params) => params.row?.active,
      valueFormatter: (params) => params.value?.active,
    },
    
    {
      field: "deleted",
      headerName: "Deleted",
      type: "singleSelect",
      flex: 1,
      minWidth: 100,
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value?.label,
      valueOptions: deleteOption,
      renderCell: (params) => params.value?.active,
      valueGetter: (params) => params.row?.active,
      valueFormatter: (params) => params.value?.active,
    },
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      minWidth: 200,
      filterable: false,
    },
    {
      field: "testing",
      headerName: "Zip Status",
      type: "singleSelect",
      flex: 1,
      minWidth: 100,
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value?.label,
      valueOptions: [
        { value: "null", label: "Valid" },
        { value: "InvalidZIP", label: "Invalid" },
      ],
      renderCell: (params) => params.value?.testing,
      valueGetter: (params) => params.row?.testing,
      valueFormatter: (params) => params.value?.testing,
    },
    { headerName: "First Name", field: "first_name" },
    { headerName: "Last Name", field: "last_name" },

    { headerName: "Phone", field: "phone", flex: 1, minWidth: 200 },
    {
      headerName: "Company Name",
      field: "company_name",
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Parent Vendor",
      field: "parentName",
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Vendor type",
      field: "vendor_type",
      flex: 1,
      minWidth: 150,
      filterOperators: [
        {
          value: "InNet",
          label: "InNet",
          getApplyFilterFn: (filterItem) => {
            return (params) =>
              String(params.value) === String(filterItem.operator);
          },
        },
        {
          value: "OutNet",
          label: "OutNet",
          getApplyFilterFn: (filterItem) => {
            return (params) =>
              String(params.value) === String(filterItem.operator);
          },
        },
      ],
    },
    {
      headerName: "Emails",
      field: "emails",
      flex: 1,
      minWidth: 250,
      renderCell: EmailCellRenderer,
    },
    {
      headerName: "Industries",
      field: "industry_ids",
      renderCell: TagsCellRenderer,
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Unique SMS DID",
      field: "unique_did",
      flex: 1,
      minWidth: 200,
      editable: true,
      renderEditCell: renderDIDEditInputCell,
      // align: "center",
    },
    {
      headerName: "Client Phone Masking",
      field: "client_phone_masking",
      flex: 1,
      minWidth: 200,
      editable: true,
      renderEditCell: renderClientMaskingEditInputCell,
      type: "singleSelect",
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        client_phone_masking: value?.value,
      }),
      valueOptions: [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
      ],
    },
    {
      headerName: "Recipients",
      field: "recipients",
      flex: 1,
      minWidth: 300,
      editable: true,
      renderCell: RecipentsCellRenderer,
      renderEditCell: RenderRecipentsEditInputCell,
      wrap: true,
    },
    { headerName: "State", field: "state", flex: 1, minWidth: 150 },
    { headerName: "City", field: "city", flex: 1, minWidth: 150 },
    // { headerName: "Email", field: "email", flex: 1, minWidth: 200 },
    {
      headerName: "Email",
      field: "email",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div className="flex flex-col">
          <span>{params.row.email}</span>
          {params.row.email &&
            (params.row.email_verified ? (
              <div className="flex gap-x-2">
                <small className="text-green-600">Verified</small>
                {params.row.records?.username ? (
                  <small>{`( ${params.row.records?.username} )`}</small>
                ) : (
                  <small
                    className="text-blue-600 cursor-pointer"
                    onClick={() => {
                      setEditingRecord(params.row.records);
                      setIsUserCreate(true);
                    }}
                  >
                    Create Username
                  </small>
                )}
              </div>
            ) : (
              <small
                className="text-red-600 hover:underline cursor-pointer ml-3"
                onClick={() => sendVerificationEmail(params.row.email)}
              >
                Not verified
              </small>
            ))}
        </div>
      ),
    },

    { headerName: "HQ Zip code", field: "zip", flex: 1, minWidth: 100 },

    { headerName: "Job Count", field: "job_count", flex: 1, minWidth: 100 },
    { headerName: "Tech(%)", field: "tech_percentage", flex: 1, minWidth: 100 },
  ];
  function exportToExcel(type) {
    const data =
      type === "all_record"
        ? allVendors?.records
        : type === "current_record"
        ? allVendors?.records
        : [];
    const rows = [
      [
        "#",
        "Name",
        "Country Code",
        "Phone",
        "Email",
        "Parent Vendor",
        "Client Phone Masking",
        "Unique DID",
        "Recipients",
        "Tech (%)",
        "Address",
        "Street",
        "Zip",
        "Active",
        "Industries",
      ],
    ];

    data.forEach((item, index) => {
      const parent_vendor = allVendors?.records?.find(
        ({ id }) => +id === +item?.parent_id
      );
      const {
        first_name,
        last_name,
        unique_did,
        recipients,
        phone_country_code,
        phone,
        alternate_phone,
        email,
        tech_percentage,
        address,
        street,
        zip,
        active,
        industry_ids,
      } = item;
      const counter = index + 1;
      const parentName = parent_vendor
        ? `${parent_vendor?.first_name} ${
            parent_vendor?.last_name ? parent_vendor?.last_name : ""
          }`
        : "";
      const fullName = `${first_name ? first_name : ""} ${
        last_name ? last_name : ""
      }`;
      const phones = `${phone ? phone : ""}${
        alternate_phone ? ", " + alternate_phone : ""
      }`;
      const isAtive = active ? "Yes" : "No";
      const client_phone_masking = item?.client_phone_masking ? "Yes" : "No";
      const industryString = industry_ids
        .map((industry, idx) => `${idx + 1}. ${industry?.name}`)
        .join("\n");

      rows.push([
        counter,
        fullName,
        phone_country_code,
        phones,
        email,
        parentName,
        client_phone_masking,
        unique_did,
        recipients,
        tech_percentage,
        address,
        street,
        zip,
        isAtive,
        industryString,
      ]);
    });

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    // Add rows to the worksheet
    rows.forEach((row) => {
      worksheet.addRow(row);
    });

    // Apply text wrapping for the industries column
    worksheet.getColumn(15).alignment = { wrapText: true };
    for (let i = 1; i <= 14; i++) {
      // Assuming there are 14 columns to center-align
      worksheet.getColumn(i).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    }
    // Write to buffer
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      saveAs(blob, "Vendors record.xlsx");
    });
  }

  function fieldNameToTitle(fieldName) {
    // Split the field name by underscores
    const words = fieldName.split("_");
    // Capitalize the first letter of each word and join them with spaces
    const titleCaseFieldName = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return titleCaseFieldName;
  }
  // const apiRef = useGridApiContext();
  const processRowUpdate = async (newRow, api) => {
    // Make the HTTP request to save in the backend
    let c;
    if (fieldName === "recipients") {
      c = window.confirm("Make sure all the numbers are in Tag form");
    }
    if (fieldName === "recipients" ? c : !c) {
      try {
        const res = await dispatch(
          update_geo_vendors({
            ...editCell,
          })
        );
        if (res?.payload?.status === 200) {
          toast.success(`${fieldNameToTitle(fieldName)} successfully updated`);
          setSnackbar({
            children: `${fieldNameToTitle(fieldName)} successfully updated`,
            severity: "success",
          });
          setEditCell(null);
        }
        return res.payload.data[0];
      } catch (err) {
        console.error("🚀 ~ file: index.jsx:282 ~ err:", err);
      }
    } else {
      return;
    }
  };
  const onStartEditing = (data) => {
    setEditCell(data);
  };
  const handleProcessRowUpdateError = React.useCallback((error) => {
    if (
      error.message?.includes(
        "The data grid component requires all rows to have a unique `id` property."
      )
    ) {
      return;
    }
    toast.error(`${error.message}`);
  }, []);
  useEffect(() => {
    dispatch(
      get_all_geo_vendors({
        page: 1,
        size: paginationModel.pageSize,
        vendor_type: vendorType,
        filters: {
          groupOp: "AND",
          rules: [
            {
              field: "active",
              op: "eq",
              data: true,
            },
            {
              field: "deleted",
              op: "eq",
              data: false,
            },
          ],
        },
      })
    );
  }, [dispatch, vendorType]);

  const VendorType = () => {
    return (
      <div className="w-[246px] ml-2">
        <Dropdown
          value={vendorType}
          onChange={(e) => {
            setVendorType(e.value);
          }}
          options={[
            { label: "All Vendors", value: "All" },
            { label: "Global Vendors", value: "Global" },
            { label: "Own Vendors", value: "Own" },
          ]}
          optionLabel="label"
          placeholder="Select Vendor Type"
          className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
        />
      </div>
    );
  };
  const handleFilterData = (type) => {
    if (type === "All") {
      dispatch(
        get_all_geo_vendors({
          page: 1,
          size: paginationModel.pageSize,
          vendor_type: vendorType,
          filters: queryOptions,
        })
      );
      setquickFilters([]);
    }
    if (type === "Active") {
      setquickFilters((prev) => {
        const filteredRules = prev.filter(
          ({ field }) => field !== "active" && field !== "deleted"
        );

        return [
          ...filteredRules,
          {
            field: "active",
            operator: "is",
            value: true,
          },
          {
            field: "deleted",
            operator: "is",
            value: false,
          },
        ];
      });
      dispatch(
        get_all_geo_vendors({
          page: 1,
          size: paginationModel.pageSize,
          vendor_type: vendorType,
          filters: {
            ...queryOptions,
            rules: [
              ...queryOptions.rules?.filter(
                ({ field }) => field !== "active" && field !== "deleted"
              ),
              {
                field: "active",
                op: "eq",
                data: true,
              },
              {
                field: "deleted",
                op: "eq",
                data: false,
              },
            ],
          },
        })
      );
    }
    if (type === "Inactive") {
      setquickFilters((prev) => {
        const filteredRules = prev.filter(
          ({ field }) => field !== "active" && field !== "deleted"
        );
        return [
          ...filteredRules,
          {
            field: "active",
            operator: "is",
            value: false,
          },
          {
            field: "deleted",
            operator: "is",
            value: false,
          },
        ];
      });
      dispatch(
        get_all_geo_vendors({
          page: 1,
          size: paginationModel.pageSize,
          vendor_type: vendorType,
          filters: {
            ...queryOptions,
            rules: [
              ...queryOptions.rules?.filter(
                ({ field }) => field !== "active" && field !== "deleted"
              ),
              {
                field: "active",
                op: "eq",
                data: false,
              },
              {
                field: "deleted",
                op: "eq",
                data: false,
              },
            ],
          },
        })
      );
    }
    if (type === "Deleted") {
      setquickFilters((prev) => {
        const filteredRules = prev.filter(
          ({ field }) => field !== "active" && field !== "deleted"
        );
        return [
          ...filteredRules,
          {
            field: "deleted",
            operator: "is",
            value: true,
          },
        ];
      });
      dispatch(
        get_all_geo_vendors({
          page: 1,
          size: paginationModel.pageSize,
          vendor_type: vendorType,
          filters: {
            ...queryOptions,
            rules: [
              ...queryOptions.rules?.filter(
                ({ field }) => field !== "active" && field !== "deleted"
              ),
              {
                field: "deleted",
                op: "eq",
                data: true,
              },
            ],
          },
        })
      );
    }
  };

  const LineOne = () => {
    return (
      <div className="grid grid-cols-4 gap-2 ml-3">
        <button
          className={`flex flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${
          !quickFilters?.length ||
          quickFilters?.every(
            ({ field }) => field !== "active" && field !== "deleted"
          )
            ? "border-l-2 border-primary-100"
            : ""
        }
        `}
          onClick={() => handleFilterData("All")}
        >
          <span className="text-sm font-pop">All Vendors</span>
          {/* <span className="text-base font-medium font-pop">
              {`${
                sumsAndCounts?.allInvoiceCount
              } ($${sumsAndCounts?.allInvoiceAmountSum?.toLocaleString()})`}
            </span> */}
        </button>
        <button
          className={`flex flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${
          quickFilters?.length &&
          quickFilters?.find(({ field, value }) => field === "active" && value)
            ? "border-l-2 border-primary-100"
            : ""
        }
        `}
          onClick={() => handleFilterData("Active")}
        >
          <span className="text-sm font-pop">Active Vendors</span>
        </button>
        <button
          className={`flex flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${
          quickFilters?.length &&
          quickFilters?.find(({ field, value }) => field === "active" && !value)
            ? "border-l-2 border-primary-100"
            : ""
        }
        `}
          onClick={() => handleFilterData("Inactive")}
        >
          <span className="text-sm font-pop">Inactive Vendors</span>
        </button>
        <button
          className={`flex flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${
          quickFilters?.length &&
          quickFilters?.find(({ field, value }) => field === "deleted" && value)
            ? "border-l-2 border-primary-100"
            : ""
        }
        `}
          onClick={() => handleFilterData("Deleted")}
        >
          <span className="text-sm font-pop">Deleted Vendors</span>
        </button>
      </div>
    );
  };
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    dispatch(
      get_all_geo_vendors({
        page: 1,
        size: paginationModel.pageSize,
        filters: queryOptions,
        vendor_type: vendorType,
      })
    );
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="!text-[#042a42]">
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <Button
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42]"
        >
          Apply filter
        </Button>
      </GridToolbarContainer>
    );
  }
  const onFilterChange = React.useCallback(
    (filterModel) => {
      let ruless = [];
      // eslint-disable-next-line array-callback-return
      [...filterModel.items, ...quickFilters]?.map((rule) => {
        ruless = [
          ...ruless,
          {
            field: `${rule.field}`,
            op:
              rule.operator === "contains"
                ? "cn"
                : rule.operator === "equals"
                ? "eq"
                : rule.operator === "is"
                ? "eq"
                : rule.operator === "="
                ? "eq"
                : rule.operator === "!="
                ? "not"
                : rule.operator === ">"
                ? "gt"
                : rule.operator === ">="
                ? "gte"
                : rule.operator === "<"
                ? "lt"
                : rule.operator === "<="
                ? "lte"
                : rule.operator === "onOrBefore"
                ? "lte"
                : rule.operator === "before"
                ? "lt"
                : rule.operator === "onOrAfter"
                ? "gte"
                : rule.operator === "after"
                ? "gt"
                : rule.operator,
            data:
              rule.field === "latest_position"
                ? +rule.value
                : rule.value === "null"
                ? null
                : rule.value
                ? rule.value
                : rule.value === 0
                ? rule.value
                : rule.value === false
                ? rule.value
                : null,
          },
        ];
      });
      setQueryOptions({
        groupOp: filterModel.logicOperator.toUpperCase(),
        rules: ruless,
      });
      if (filterModel?.items?.length === 0) {
        dispatch(
          get_all_geo_vendors({
            page: 1,
            size: paginationModel.pageSize,
            vendor_type: vendorType,
            filters: {
              groupOp: filterModel.logicOperator.toUpperCase(),
              rules: ruless,
            },
          })
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [quickFilters]
  );

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_all_geo_vendors({
        filters: queryOptions,
        page: +params.page + 1,
        size: params.pageSize,
        vendor_type: vendorType,
      })
    );
  };
  return (
    <>
      {/* {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle={editingRecord ? "Update Vendors" : "Add Vendors"}
          onCancelForm={cancelFormHandler}
        />
      )} */}
      {isMerchantChange && (
        <MerchantChange
          modalTitle={`Change merchant for ${
            editingRecord?.first_name +
            " " +
            (editingRecord?.last_name ? editingRecord?.last_name : "")
          }`}
          onCancelForm={() => setIsMerchantChange(false)}
          editingRecord={editingRecord}
        />
      )}
      {isUserCreate && (
        <CreateUsernameModal
          modalTitle={`Create Username for vendor ${
            editingRecord?.first_name +
            " " +
            (editingRecord?.last_name ? editingRecord?.last_name : "")
          }`}
          onCancelForm={() => setIsUserCreate(false)}
          editingRecord={editingRecord}
        />
      )}

      <PageHeader
        route="Setting > Users"
        heading="Vendor Listing"
        onClick={() => navigate(`/settings/vendors/add`)}
        isAllowed={userPermission("Add User")}
        // CustomComponent={VendorType}
      />

      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          exportOption={exportOption}
          items={allVendors.records?.map((data, index) => {
            let counter = index + 1;
            let _id = data._id;
            let name =
              data?.first_name + " " + (data?.last_name ? data?.last_name : "");
            let createdAt = data.created_at
              ? dayjs(data?.created_at).format("ddd, MMM D, YYYY h:mm A")
              : null;
            let {
              parent_id,
              first_name,
              last_name,
              unique_did,
              recipients,
              phone,
              alternate_phone,
              emails,
              username,
              tech_percentage,
              address,
              street,
              zip,
              address_latitude,
              address_longitude,
              active,
              job_count,
              city,
              state,
              industry_ids,
              client_phone_masking,
              company_name,
              vendor_type,
              email_verified,
              email,
              testing,
            } = data;
            return {
              _id,
              id: _id,
              first_name,
              last_name,
              email_verified,
              parentName: allVendors?.records?.find(
                ({ id }) => id === parent_id
              )?.first_name,
              counter,
              unique_did,
              recipients,
              phone: `${phone ? phone : ""}${
                alternate_phone ? ", " + alternate_phone : ""
              }`,
              tech_percentage,
              address,
              street,
              zip,
              address_latitude,
              address_longitude,
              records: { ...data },
              name,
              emails,
              email,
              username,
              createdAt,
              active: active ? "Active" : "InActive",
              job_count:
                job_count >= 0 ? job_count : job_count >= 0 ? job_count : "-",
              city,
              state,
              industry_ids,
              client_phone_masking: client_phone_masking ? 1 : 0,
              company_name:
                company_name && company_name !== "null" ? company_name : "",
              vendor_type,
              testing: testing ? testing : "Valid",
            };
          })}
          totalItems={allVendors?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading || isAllRecordLoading || isLoader}
          searchCounter={`${searchText?.length > 0 ? "Yes" : ""}`}
          handleExport={exportToExcel}
          searchable="No"
          exportButton={exportToExcel}
          gridOptions={getRowHeight}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          LineOne2={LineOne}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          paginationModel={paginationModel}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          onCellEditStart={(params) => {
            setFieldName(params?.field);
            onStartEditing({
              ...params.row?.records,
              recipients: params?.row?.records?.recipients
                ? JSON.parse(params?.row?.records?.recipients)
                : [],
            });
          }}
          // initialState={{
          //   filter: {
          //     filterModel: {
          //       items: [...quickFilters],
          //       logicOperator: "and",
          //       quickFilterValues: [],
          //       quickFilterLogicOperator: "and",
          //     },
          //   },
          // }}
          columnVisibilityModel={{
            first_name: false,
            last_name: false,
            deleted: false,
            // testing: false,
          }}
        />
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </div>
    </>
  );
};

export default Vendors;
