import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_testing_domains_industries } from "../../../features/domainCenterSlice";
import PageHeader from "../../../components/molecules/PageHeader";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";
import { useState } from "react";
import axios from "axios";
function TestingLinks() {
  const { isLoading, testingIndustries } = useSelector(
    (state) => state.domainCenter
  );
  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = React.useState("");
  React.useEffect(() => {
    dispatch(get_testing_domains_industries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reDeploy = async (data) => {
    try {
      setIsLoader(true);
      const res = await axios.post(
        `https://backend.amplifytest1.top/api/setup/get_pull_on_server`,
        data,
        {
          headers: {
            Authorization: "Bearer AKIA3WTNB4VOJYIFVP7F",
          },
        }
      );
      if (res.status === 200) {
        toast.success(res?.data?.message);
        dispatch(get_testing_domains_industries());
      } else {
        toast.error(res?.data?.message);
      }
      setIsLoader(false);
    } catch (error) {
      console.error("🚀 ~ file: index.jsx:27 ~ reReploy ~ error:", error);
      toast.error(error?.data?.message);
      setIsLoader(false);
    }
  };
  const deploy = async (data) => {
    try {
      setIsLoader(true);
      const res = await axios.post(
        `https://backend.amplifytest1.top/api/setup/setup_industry_test_link`,
        data,
        {
          headers: {
            Authorization: "Bearer AKIA3WTNB4VOJYIFVP7F",
          },
        }
      );
      if (res.status === 200) {
        toast.success(res?.data?.message);
        dispatch(get_testing_domains_industries());
      } else {
        toast.error(res?.data?.message);
      }
      setIsLoader(false);
    } catch (error) {
      console.error("🚀 ~ file: index.jsx:27 ~ reReploy ~ error:", error);
      toast.error(error?.data?.message);
      setIsLoader(false);
    }
  };
  async function redeployAll() {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    setIsLoader(true);
    const selectedDataCopy = testingIndustries?.filter(
      ({ domain }) => !!domain
    );
    for (const item of selectedDataCopy) {
      try {
        const response = await reDeploy({
          app_folder_name: item.app_folder_name,
          port: item.PORT,
          industry_id: item.industry_id,
          industry_name: item.industry_name,
        });

        if (response.status === 200) {
          toast.success(
            `${response?.data?.message} for ${item.domain} & ${item.app_folder_name}`
          );
        } else {
          toast.error(
            `${response.data?.message} for ${item.domain} & ${item.app_folder_name}`
          );
        }
      } catch (error) {
        console.error(
          "🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:",
          error
        );
        toast.error(
          `${error?.data?.message} for ${item.domain} & ${item.app_folder_name}`
        );
      }
    }
    setIsLoader(false);
  }
  async function deployAll() {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    setIsLoader(true);
    const selectedDataCopy = testingIndustries?.filter(({ domain }) => !domain);
    for (const item of selectedDataCopy) {
      try {
        const response = await deploy({
          app_folder_name: item.app_folder_name,
          port: item.PORT,
          industry_id: item.industry_id,
          industry_name: item.industry_name,
          template_id: item?.template_id,
          template_name: item?.template_name,
          github_full_name: item?.github_full_name,
        });

        if (response.status === 200) {
          toast.success(
            `${response?.data?.message} for ${item.domain} & ${item.app_folder_name}`
          );
        } else {
          toast.error(
            `${response.data?.message} for ${item.domain} & ${item.app_folder_name}`
          );
        }
      } catch (error) {
        console.error(
          "🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:",
          error
        );
        toast.error(
          `${error?.data?.message} for ${item.domain} & ${item.app_folder_name}`
        );
      }
    }
    setIsLoader(false);
  }
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Template", field: "template_name", flex: 1 },
    { headerName: "Industry", field: "industry_name", flex: 1 },
    {
      headerName: "Domain",
      field: "domain",
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.domain ? (
            <a
              className="h-6 flex items-center text-black cursor-pointer hover:underline no-underline"
              target="_blank"
              rel="noreferrer"
              href={`https://${params.row.domain}`}
            >{`${params.row.domain}`}</a>
          ) : null}
        </>
      ),
    },
    { headerName: "Folder Name", field: "app_folder_name", flex: 1 },
    { headerName: "Port", field: "PORT", flex: 1 },
    {
      headerName: "Action",
      field: "action",
      renderCell: (params) => (
        <div
          className="text-blue-600 cursor-pointer hover:underline"
          onClick={() => {
            params?.row?.domain
              ? reDeploy({
                  app_folder_name: params.row.app_folder_name,
                  port: params.row.PORT,
                  industry_id: params.row.industry_id,
                  industry_name: params.row.industry_name,
                })
              : deploy({
                  industry_id: params.row.industry_id,
                  industry_name: params.row.industry_name,
                  template_id: params.row.template_id,
                  template_name: params.row.template_name,
                  github_full_name: params.row.github_full_name,
                });
          }}
        >
          {params.row?.domain ? "Redeploy" : "Make live"}
        </div>
      ),
    },
  ];
  const roles = testingIndustries?.filter((role) =>
    role?.industry_name?.toLowerCase().includes(searchText.toLowerCase())
  );
  return (
    <div>
      <PageHeader
        route="Setting > User Roles"
        heading="Testing Links"
        onClick={deployAll}
        buttonTitle="Deploy All"
        // isAllowed={true}
        additionalBtnTitle="Redeploy All"
        extraButton
        additionalBtnAllow
        extraButtonClick={redeployAll}
        addIcon="No"
        editIcon="No"
        
      />

      <div className="bg-white my-2 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={roles?.map((record, index) => {
            let counter = index + 1;
            const {
              domain,
              industry_name,
              app_folder_name,
              PORT,
              industry_id,
              template_id,
              template_name,
              github_full_name,
            } = record;
            return {
              counter,
              domain,
              app_folder_name,
              PORT,
              industry_id,
              records: record,
              industry_name,
              template_id,
              template_name,
              github_full_name,
            };
          })}
          totalItems={testingIndustries?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading || isLoader}
          searchable="No"
        />
      </div>
    </div>
  );
}

export default TestingLinks;
