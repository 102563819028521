import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import SetAlternateModal from "./Modal";
import { toast } from "react-toastify";
import api from "../../../services/api";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import DotsLoader from "../../../components/atoms/DotsLoader";
import { get_pending_domains_phone } from "../../../features/domainCenterSlice";
import PageHeader from "../../../components/molecules/PageHeader";
function PendingDomainsPhone() {
  const { isLoading, pendingPhone } = useSelector(
    (state) => state.domainCenter
  );
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [alternateModal, setAlternateModal] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  useEffect(() => {
    dispatch(get_pending_domains_phone());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    {
      field: "domain",
      headerName: "Domain",
      renderCell: (params) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.row.domain}`}
            className="text-[#000] no-underline hover:text-blue-600 hover:underline"
          >
            {params?.row.domain}
          </a>
        );
      },
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Industry Name",
      field: "industry_name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "areaCodes",
      headerName: "Area Codes",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "city",
      headerName: "City",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "state",
      headerName: "State",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "population",
      headerName: "Population",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
  ];
  const data = pendingPhone?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  function exportToExcel() {
    const data = pendingPhone;
    const rows = [
      [
        "Sr.",
        "Domains",
        "Industry Name",
        "Area Codes",
        "City",
        "State",
        "Population",
      ], // Add headings
    ];

    data.forEach((item, index) => {
      const { areaCodes, domain, industry_name, city, state, population } =
        item;
      rows.push([
        index + 1,
        domain,
        industry_name,
        areaCodes,
        city,
        state,
        population,
      ]); // Add a row without domains
    });

    const worksheet = XLSX.utils.aoa_to_sheet(rows);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const fileName = "pending_domains_phone.xlsx";

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    if (typeof window.navigator.msSaveBlob !== "undefined") {
      // For IE browser
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    }
  }
  function exportToExcelUnique() {
    const uniqueAreaCodes = new Set();

    // Loop through each object in the array
    pendingPhone.forEach((item) => {
      const codes = item.areaCodes.split(","); // Split area codes if there are multiple
      codes.forEach((code) => uniqueAreaCodes.add(code.trim())); // Add each code to the set
    });
    const uniqueAreaCodesArray = [...uniqueAreaCodes];
    const data = uniqueAreaCodesArray;
    const rows = [
      ["Sr.", "Area Codes"], // Add headings
    ];

    data.forEach((item, index) => {
      rows.push([index + 1, item]); // Add a row without domains
    });

    const worksheet = XLSX.utils.aoa_to_sheet(rows);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const fileName = "pending_domains_phone_by_area_code.xlsx";
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    if (typeof window.navigator.msSaveBlob !== "undefined") {
      // For IE browser
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    }
  }
  const exportOption = [
    {
      label: "Complete Report",
      value: "csv",
      action: exportToExcel,
    },
    {
      label: "Unique Area Codes",
      value: "csv",
      action: exportToExcelUnique,
    },
  ];

  const handleUpdatePhone = async () => {
    const c = window.confirm(`Are you sure want to update purchased phone?`);
    if (!c) {
      return;
    }
    setIsLoader(true);
    try {
      const res = await api.put("/api/domains/update_purchased_phone");
      if (res.status === 200) {
        toast.success("Purchased phones updated successfully");
      } else {
        toast.error("Purchased phones couldn't be updated");
      }
      setIsLoader(false);
    } catch (err) {
      toast.error("Purchased phones couldn't be updated");
      console.log("🚀 ~ file: Modal.jsx:79 ~ handleSubmit ~ err:", err);
      setIsLoader(false);
    }
  };
  return (
    <>
      {isLoading || isLoader ? <DotsLoader text="Please Wait" /> : null}
      {alternateModal ? (
        <SetAlternateModal
          pendingPhone={pendingPhone}
          onCloseModal={() => setAlternateModal(false)}
        />
      ) : null}

      <PageHeader
        heading="Pending Domains Phone"
        onClick={() => setAlternateModal(!alternateModal)}
        isAllowed={true}
        buttonTitle={`Set Alternate Codes`}
        extraButton={true}
        extraButtonClick={handleUpdatePhone}
        additionalBtnTitle={"Update Purchased Phone"}
        additionalBtnAllow={true}
        addIcon="No"
        editIcon="No"
      />
      <div className="bg-white !my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={data?.map((item, index) => {
            let counter = index + 1;
            const {
              areaCodes,
              domain,
              industry_name,
              city,
              state,
              population,
            } = item;
            return {
              records: { ...item, id: index + 1 },
              counter,
              industry_name,
              areaCodes,
              domain,
              city,
              state,
              population,
            };
          })}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          pagination="No"
          totalItems={
            pendingPhone?.totalItems
              ? pendingPhone?.totalItems
              : pendingPhone?.length
          }
          exportOption={exportOption}
        />
      </div>
    </>
  );
}

export default PendingDomainsPhone;
