import api from "./api";

export const getPurchaseRegistrar = async () => {
    return await api.get("/api/domain_registrar");
};
export const createPurchaseRegistrar = async (data) => {
  return await api.post("/api/domain_registrar", data);
};

export const updatePurchaseRegistrar = async (data) => {
  return await api.put(`/api/domain_registrar/${data?.id}`, data );
};

export const deletePurchaseRegistrar = async (id) => {
  return await api.delete(`/api/domain_registrar/${id}`);
};
