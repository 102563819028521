import React from "react";
import { FaCalendarCheck, FaPhone } from "react-icons/fa";
import SelectInput from "../../components/atoms/SelectInput";
import { Bar, Pie } from "react-chartjs-2";
import Picker from "../../reports/Components/Picker";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import dayjs from "dayjs";
import { get_geo_vendors_drd } from "../../features/GeoVendorsSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { FiMaximize } from "react-icons/fi";
import { toast } from "react-toastify";
import { get_vendor_calls_by_date } from "../../features/dashboardSlice";
import { Loader } from "../../components";
import StatsLoader from "../../components/organisms/Statsloader";
import GraphLoader from "../../components/organisms/GraphLoader";
import PieChartLoader from "../../components/organisms/PieChartLoader";
function ByVendor({
  barOptions,
  pieOptions,
  days,
  selectedSearchType,
  searchTypes,
  setSelectedSearchType,
  setGraphData,
  setIsGraphModal,
}) {
  const dispatch = useDispatch();
  const { isLoading, geoVendorDrd } = useSelector((state) => state.geoVendors);
  const { isVendorLoading, vendorJobsOverAll } = useSelector(
    (state) => state.dashboard
  );
  const localDate = new Date();

  // Get the time zone offset in minutes
  const timeZoneOffsetMinutes = localDate.getTimezoneOffset();

  // Convert the offset to hours and minutes
  const offsetHours = Math.floor(Math.abs(timeZoneOffsetMinutes) / 60);
  const offsetMinutes = Math.abs(timeZoneOffsetMinutes) % 60;

  // Determine the sign of the offset
  const offsetSign = timeZoneOffsetMinutes > 0 ? "-" : "+";

  // Format the offset in the desired format
  // eslint-disable-next-line no-unused-vars
  const offsetString = `${offsetSign}${offsetHours
    .toString()
    .padStart(2, "0")}:${offsetMinutes.toString().padStart(2, "0")}`;

  // const fromDate = dayjs().startOf("month").toDate();
  const fromDate =
    new Date(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      0,
      0,
      0,
      0
    )
      .toISOString()
      .slice(0, 23) + "Z";
  const toDate = new Date().toISOString().slice(0, 23) + "Z";
  const getTimezoneString = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  };
  const [vendorFilter, setVendorFilter] = React.useState({
    start_date: fromDate,
    end_date: toDate,
    day: localDate.getDay() + 1,
    vendor_id: "all",
    timezone: getTimezoneString(),
  });
  const [searchText, setSearchText] = React.useState("");
  const [vendorRecord, setVendorRecord] = React.useState({
    byDays: {
      labels: [],
      datasets: [
        {
          label: "Send Jobs",
          data: [], // Values for Total Calls
          backgroundColor: "rgba(4, 42, 66,1)",
          borderColor: "rgba(4, 42, 66, 1)",
          borderWidth: 2,
          pointRadius: 5,
          fill: false,
        },
        {
          label: "Closed",
          data: [], // Values for Answered Calls
          backgroundColor: "#16a34a",
          borderColor: "#16a34a",
          borderWidth: 2,
          pointRadius: 5,
          fill: false,
        },
        {
          label: "Estimated",
          data: [], // Values for Answered Calls
          backgroundColor: "#ffb700",
          borderColor: "#ffb700",
          borderWidth: 1,
          pointRadius: 5,
          fill: false,
        },
        {
          label: "Canceled",
          data: [], // Values for Answered Calls
          backgroundColor: "#dc2626",
          borderColor: "#dc2626",
          borderWidth: 2,
          pointRadius: 5,
          fill: false,
        },
      ],
    },
    byDates: {
      labels: [],
      datasets: [
        {
          label: "Send Jobs",
          data: [], // Values for Total Calls
          backgroundColor: "rgba(4, 42, 66,1)",
          borderColor: "rgba(4, 42, 66, 1)",
          borderWidth: 2,
          pointRadius: 5,
          fill: false,
        },
        {
          label: "Closed",
          data: [], // Values for Answered Calls
          backgroundColor: "#16a34a",
          borderColor: "#16a34a",
          borderWidth: 2,
          pointRadius: 5,
          fill: false,
        },
        {
          label: "Estimated",
          data: [], // Values for Answered Calls
          backgroundColor: "#ffb700",
          borderColor: "#ffb700",
          borderWidth: 1,
          pointRadius: 5,
          fill: false,
        },
        {
          label: "Canceled",
          data: [], // Values for Answered Calls
          backgroundColor: "#dc2626",
          borderColor: "#dc2626",
          borderWidth: 2,
          pointRadius: 5,
          fill: false,
        },
      ],
    },
    overAll: {
      labels: ["Send Jobs", "Closed", "Estimated", "Canceled"],
      datasets: [
        {
          data: [],
          backgroundColor: [
            "rgba(5, 42, 66,1)",
            "#16a34a",
            "rgba(255, 183, 0,1)",
            "#dc2626",
          ],
          borderColor: [
            "rgba(5, 42, 66,1)",
            "#16a34a",
            "rgba(255, 183, 0,1)",
            "#dc2626",
          ],
          borderWidth: 1,
        },
      ],
    },
  });
  const getDayIndex = (day) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return days.indexOf(day);
  };
  const getDatesForDay = (start, end, day) => {
    const dates = [];
    const days = [];
    let currentDate = new Date(start);
    const endDate = new Date(end);
    while (currentDate <= endDate) {
      if (currentDate.getDay() === getDayIndex(day)) {
        days.push(new Date(currentDate));
      }
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return { days, dates };
  };
  const handleGetDatesClick = (selectedDay) => {
    const startDate = dayjs(vendorFilter?.start_date).format("YYYY-MM-DD");
    const endDate = dayjs(vendorFilter?.end_date).format("YYYY-MM-DD");
    if (startDate && endDate) {
      const { days, dates } = getDatesForDay(
        vendorFilter?.start_date,
        vendorFilter?.end_date,
        selectedDay
      );
      const dayLabels = days?.map(
        (date) => `${date?.getMonth() + 1}-${date?.getDate()}`
      );
      const dateLabels = dates?.map(
        (date) => `${date?.getMonth() + 1}-${date?.getDate()}`
      );
      setVendorRecord({
        ...vendorRecord,
        byDays: {
          ...vendorRecord?.byDays,
          labels: dayLabels,
        },
        byDates: {
          ...vendorRecord?.byDates,
          labels: dateLabels,
        },
      });
      // setResultDates(objects);
      // getDashboardData();
    } else {
      // Handle error, both start date and end date must be selected
    }
  };

  React.useEffect(() => {
    dispatch(get_geo_vendors_drd());
    // eslint-disable-next-line
  }, []);
  const agentOptions = geoVendorDrd?.map((user) => ({
    value: user?.crm_id,
    label: `${user?.first_name || ""} ${user?.last_name || ""}`,
  }));
  const agentOption = agentOptions?.filter((user) =>
    user?.label?.toLowerCase()?.includes(searchText?.toLowerCase())
  );
  const getDashboardAgentData = async () => {
    let payload = { ...vendorFilter };
    if (vendorFilter?.vendor_id === "all") {
      payload = { ...payload, vendor_id: "" };
    }
    const res = await dispatch(get_vendor_calls_by_date({ ...payload }));
    if (res?.payload?.status === 200) {
      const data = res?.payload.data;
      if (
        !data?.summary?.assignedJobs &&
        !data?.summary?.cancelledJobs &&
        !data?.summary?.closedJobs &&
        !data?.summary?.estimatedJobs
      ) {
        // toast.info("No Record found by vendor");
      }
      const newData = [
        data?.summary?.assignedJobs,
        data?.summary?.closedJobs,
        data?.summary?.estimatedJobs,
        data?.summary?.cancelledJobs,
      ];

      setVendorRecord((prevState) => ({
        ...prevState,
        overAll: {
          ...prevState.overAll,
          datasets: [
            {
              ...prevState.overAll.datasets[0],
              data: newData,
            },
          ],
        },
      }));
    }
    if (res?.payload?.status === 200) {
      const data = res?.payload?.data;
      const sendJobsByDay = [];
      const closedJobsByDay = [];
      const estimatedJobsByDay = [];
      const canceledJobsByDay = [];

      const sendJobsByDate = [];
      const closedJobsByDate = [];
      const estimatedJobsByDate = [];
      const canceledJobsByDate = [];

      vendorRecord.byDates.labels.forEach((label) => {
        const sendByDate = data?.sent_records?.find((item) => {
          // eslint-disable-next-line no-unused-vars
          const [year, month, day] = item._id.split("-");
          const formattedDay = day.startsWith("0") ? day.substring(1) : day;
          const formattedDate = `${month}-${formattedDay}`;
          return formattedDate === label;
        });
        const cencelByDate = data?.cancel_records?.find((item) => {
          // eslint-disable-next-line no-unused-vars
          const [year, month, day] = item._id.split("-");
          const formattedDay = day.startsWith("0") ? day.substring(1) : day;
          const formattedDate = `${month}-${formattedDay}`;
          return formattedDate === label;
        });
        const estimateByDate = data?.estimated_records?.find((item) => {
          // eslint-disable-next-line no-unused-vars
          const [year, month, day] = item._id.split("-");
          const formattedDay = day.startsWith("0") ? day.substring(1) : day;
          const formattedDate = `${month}-${formattedDay}`;
          return formattedDate === label;
        });
        const closeByDate = data?.closed_records?.find((item) => {
          // eslint-disable-next-line no-unused-vars
          const [year, month, day] = item._id.split("-");
          const formattedDay = day.startsWith("0") ? day.substring(1) : day;
          const formattedDate = `${month}-${formattedDay}`;
          return formattedDate === label;
        });
        if (sendByDate) {
          sendJobsByDate.push(sendByDate.count);
        } else {
          sendJobsByDate.push(0);
        }
        if (cencelByDate) {
          canceledJobsByDate.push(cencelByDate.count);
        } else {
          canceledJobsByDate.push(0);
        }
        if (estimateByDate) {
          estimatedJobsByDate.push(estimateByDate.count);
        } else {
          estimatedJobsByDate.push(0);
        }
        if (closeByDate) {
          closedJobsByDate.push(closeByDate.count);
        } else {
          closedJobsByDate.push(0);
        }
      });
      vendorRecord.byDays.labels.forEach((label) => {
        const sendDataByDay = data?.sent_records?.find((item) => {
          // eslint-disable-next-line no-unused-vars
          const [year, month, day] = item._id.split("-");
          const formattedDay = day.startsWith("0") ? day.substring(1) : day;
          const formattedDate = `${month}-${formattedDay}`;
          return formattedDate === label;
        });
        const cencelByDay = data?.cancel_records?.find((item) => {
          // eslint-disable-next-line no-unused-vars
          const [year, month, day] = item._id.split("-");
          const formattedDay = day.startsWith("0") ? day.substring(1) : day;
          const formattedDate = `${month}-${formattedDay}`;
          return formattedDate === label;
        });
        const estimateByDay = data?.estimated_records?.find((item) => {
          // eslint-disable-next-line no-unused-vars
          const [year, month, day] = item._id.split("-");
          const formattedDay = day.startsWith("0") ? day.substring(1) : day;
          const formattedDate = `${month}-${formattedDay}`;
          return formattedDate === label;
        });
        const closeByDay = data?.closed_records?.find((item) => {
          // eslint-disable-next-line no-unused-vars
          const [year, month, day] = item._id.split("-");
          const formattedDay = day.startsWith("0") ? day.substring(1) : day;
          const formattedDate = `${month}-${formattedDay}`;
          return formattedDate === label;
        });
        if (sendDataByDay) {
          sendJobsByDay.push(sendDataByDay.count);
        } else {
          sendJobsByDay.push(0);
        }
        if (cencelByDay) {
          canceledJobsByDay.push(cencelByDay.count);
        } else {
          canceledJobsByDay.push(0);
        }
        if (estimateByDay) {
          estimatedJobsByDay.push(estimateByDay.count);
        } else {
          estimatedJobsByDay.push(0);
        }
        if (closeByDay) {
          closedJobsByDay.push(closeByDay.count);
        } else {
          closedJobsByDay.push(0);
        }
      });
      const updatedDatasets = vendorRecord.byDates.datasets.map((item) => {
        if (item.label === "Send Jobs") {
          return { ...item, data: sendJobsByDate };
        } else if (item.label === "Canceled") {
          return { ...item, data: canceledJobsByDate };
        } else if (item.label === "Estimated") {
          return { ...item, data: estimatedJobsByDate };
        } else if (item.label === "Closed") {
          return { ...item, data: closedJobsByDate };
        }
        return item;
      });
      const updatedDatasetsByDay = vendorRecord.byDays.datasets.map((item) => {
        if (item.label === "Send Jobs") {
          return { ...item, data: sendJobsByDay };
        } else if (item.label === "Canceled") {
          return { ...item, data: canceledJobsByDay };
        } else if (item.label === "Estimated") {
          return { ...item, data: estimatedJobsByDay };
        } else if (item.label === "Closed") {
          return { ...item, data: closedJobsByDay };
        }
        return item;
      });
      setVendorRecord((prevState) => ({
        ...prevState,
        byDates: {
          ...prevState.byDates,
          datasets: updatedDatasets,
        },
        byDays: {
          ...prevState.byDays,
          datasets: updatedDatasetsByDay,
        },
      }));
    }
  };
  React.useEffect(() => {
    if (
      vendorRecord?.byDays?.labels?.length > 0 ||
      vendorRecord?.byDates?.labels?.length > 0
    ) {
      getDashboardAgentData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorRecord?.byDays?.labels, vendorRecord?.byDates?.labels]);
  React.useEffect(() => {
    handleGetDatesClick(
      days?.find(({ value }) => value === +vendorFilter.day)?.label
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorFilter]);
  return (
    <div className="border p-2 mt-2">
      <div className="flex flex-col md:flex-row justify-between">
        <div className="flex flex-col max-w-2/3 ">
          <h2>By Vendors</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 mt-3 gap-3 md:gap-0 mb-3 md:mb-0">
            {isVendorLoading ? (
              <StatsLoader />
            ) : (
              <div className="flex flex-row w-full items-center p-1">
                <div className="rounded-full border flex justify-center items-center h-[40px] w-[40px] bg-[#042A42]/20">
                  <FaPhone color="#042A42" />
                </div>
                <div className="flex flex-col ml-2 w-auto">
                  <small className="font-pop text-lg font-bold">
                    {vendorJobsOverAll?.assignedJobs}
                  </small>
                  <small className="font-pop">Send Jobs</small>
                </div>
              </div>
            )}
            {isVendorLoading ? (
              <StatsLoader />
            ) : (
              <div className="flex flex-row w-full items-center p-1">
                <div className="rounded-full border border-[#16a34a] flex justify-center items-center h-[40px] w-[40px] bg-[#16a34a]/20">
                  <IoMdCheckmarkCircle color="#16a34a" size={28} />
                </div>
                <div className="flex flex-col ml-2 w-auto">
                  <small className="font-pop text-lg font-bold">
                    {vendorJobsOverAll?.closedJobs}{" "}
                    {vendorJobsOverAll?.assignedJobs &&
                    vendorJobsOverAll?.closedJobs
                      ? `(${(
                          (+vendorJobsOverAll?.closedJobs /
                            +vendorJobsOverAll?.assignedJobs) *
                          100
                        )?.toFixed(2)})%`
                      : "(0%)"}
                  </small>
                  <small className="font-pop">Closed</small>
                </div>
              </div>
            )}
            {isVendorLoading ? (
              <StatsLoader />
            ) : (
              <div className="flex flex-row w-full items-center p-1">
                <div className="rounded-full border border-[#ffb700] flex justify-center items-center h-[40px] w-[40px] bg-[#ffb700]/20">
                  <FaCalendarCheck color="#ffb700" size={22} />
                </div>
                <div className="flex flex-col ml-2 w-auto">
                  <small className="font-pop text-lg font-bold">
                    {vendorJobsOverAll?.estimatedJobs}{" "}
                    {vendorJobsOverAll?.assignedJobs &&
                    vendorJobsOverAll?.estimatedJobs
                      ? `(${(
                          (+vendorJobsOverAll?.estimatedJobs /
                            +vendorJobsOverAll?.assignedJobs) *
                          100
                        )?.toFixed(2)})%`
                      : "(0%)"}
                  </small>
                  <small className="font-pop">Estimated</small>
                </div>
              </div>
            )}
            {isVendorLoading ? (
              <StatsLoader />
            ) : (
              <div className="flex flex-row w-full items-center p-1">
                <div className="rounded-full border border-[#dc2626] flex justify-center items-center h-[40px] w-[40px] bg-[#dc2626]/20">
                  <TiCancel color="#dc2626" size={30} />
                </div>
                <div className="flex flex-col ml-2 w-auto">
                  <small className="font-pop text-lg font-bold">
                    {vendorJobsOverAll?.cancelledJobs}{" "}
                    {vendorJobsOverAll?.assignedJobs &&
                    vendorJobsOverAll?.cancelledJobs
                      ? `(${(
                          (+vendorJobsOverAll?.cancelledJobs /
                            +vendorJobsOverAll?.assignedJobs) *
                          100
                        )?.toFixed(2)})%`
                      : "(0%)"}
                  </small>
                  <small className="font-pop">Cancel</small>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex max-w-1/4">
          <Picker
            filterData={vendorFilter}
            setFiltersData={setVendorFilter}
            dropdown_label="Vendors"
            dropdown_menu={
              agentOption
                ? [{ value: "all", label: "All" }, ...agentOption]
                : [{ value: "all", label: "All" }]
            }
            valueKey="vendor_id"
            value
            fromKey={"start_date"}
            toKey={"end_date"}
            rangeValue={"Today"}
            setSearchText={setSearchText}
            searchText={searchText}
            searchable
            isLoader={isLoading}
          />
        </div>
      </div>
      <div className="grid md:grid-cols-3 mt-3 gap-x-1">
        <div className="flex flex-col border shadow-md ">
          <div className="flex flex-row justify-between p-2">
            <div className="space-x-2 flex flex-row">
              {searchTypes.map((type, index) => (
                <div key={index} className="flex items-center">
                  <input
                    type="checkbox"
                    id={`search-type-${index}-agent`}
                    name="searchType"
                    value={type}
                    checked={selectedSearchType === type}
                    onChange={() => setSelectedSearchType(type)}
                  />
                  <label htmlFor={`search-type-${index}`} className="ml-1">
                    {type}
                  </label>
                </div>
              ))}
            </div>
            <FiMaximize
              className="cursor-pointer"
              onClick={() => {
                setGraphData({
                  title: `Calls Compare View By ${selectedSearchType}`,
                  graphOptions: barOptions,
                  data: {
                    ...vendorRecord,
                    labels: vendorRecord?.byDays.labels,
                    datasets: vendorRecord?.byDays.datasets,
                  },
                  graphType: "Bar",
                  isVendors: true,
                });
                setIsGraphModal(true);
              }}
            />
          </div>
          <div className="flex justify-between items-center p-2">
            <span className="text-lg font-semibold">
              Jobs Compare View By Days
            </span>
            {selectedSearchType !== "Years" ? (
              <SelectInput
                className={
                  "h-[31px] bg-white border border-gray-300 float-right rounded-[0.2rem]"
                }
                onChange={(e) => {
                  const day = days?.find(
                    ({ value }) => value === +e.target.value
                  )?.label;
                  handleGetDatesClick(day);
                  setVendorFilter({
                    ...vendorFilter,
                    day: e.target.value,
                  });
                }}
                options={selectedSearchType === "Days" ? days : []}
                value={vendorFilter.day}
              />
            ) : null}
          </div>
          {isVendorLoading ? (
            <GraphLoader />
          ) : (
            <Bar
              data={{
                labels: vendorRecord?.byDays.labels,
                datasets: vendorRecord?.byDays.datasets,
              }}
              options={barOptions}
            />
          )}
        </div>
        <div className="flex flex-col border shadow-md ">
          <div className="flex justify-between items-center p-2">
            <span className="text-lg font-semibold">
              Jobs Compare View By Date
            </span>
            <FiMaximize
              className="cursor-pointer"
              onClick={() => {
                setGraphData({
                  title: `Calls Compare View By Date`,
                  graphOptions: barOptions,
                  data: {
                    labels: vendorRecord?.byDates.labels,
                    datasets: vendorRecord?.byDates.datasets,
                  },
                  graphType: "Bar",
                  isVendors: true,
                });
                setIsGraphModal(true);
              }}
            />
          </div>
          {isVendorLoading ? (
            <GraphLoader />
          ) : (
            <Bar
              data={{
                labels: vendorRecord?.byDates.labels,
                datasets: vendorRecord?.byDates.datasets,
              }}
              options={barOptions}
            />
          )}
        </div>
        <div className="flex flex-col border shadow-md ">
          <div className="flex justify-between items-center p-2">
            <span className="text-lg font-semibold">Jobs</span>
          </div>
          <div className="h-60 flex items-center justify-center pb-3">
            {isVendorLoading ? (
              <PieChartLoader />
            ) : (
              <Pie data={vendorRecord?.overAll} options={pieOptions} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ByVendor;
