import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React from "react";
import { FormInput } from "../../../../components";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
function LocationSection(props) {
  const [scheduled_on, setScheduled_on] = React.useState(false);
  const [scheduled_end, setScheduled_end] = React.useState(false);

  const scheduledOn = React.useRef();
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setScheduled_on(false);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  const { formik, jobTypes, errors } = props;
  return (
    <div className="px-2 !mt-4">
      <div className="bg-white border px-2 md:px-4 rounded">
        <div className="pl-2 pt-3 !mb-2">
          <h5 className="">Client Location</h5>
        </div>
        <div className="md:grid grid-cols-2">
          <div className="px-2 py-0 col-span-2 mb-3">
            <FormInput
              errors={errors}
              name="complete_pickup_address"
              label="Pickup Location"
              formik={formik}
              type="text"
            />
          </div>

          {jobTypes?.record?.records?.find(
            (type) => type?._id === formik?.values?.job_category_id
          )?.drop_location_required && (
            <>
              <div className="px-2 py-0 col-span-2 mb-3">
                <FormInput
                  errors={errors}
                  name="complete_drop_address"
                  label="Drop Location"
                  formik={formik}
                  type="text"
                />
              </div>
              <div className="px-2 py-0 col-span-1 mb-3">
                <FormInput
                  errors={errors}
                  name="distance"
                  label="Distance (in miles)"
                  formik={formik}
                  type="number"
                  readOnly={true}
                />
              </div>
            </>
          )}
          <div className="col-span-2">
            <div className="md:grid grid-cols-2">
              <div className="px-2 py-0 col-span-1 mb-3">
                {!scheduled_on && (
                  <FormInput
                    errors={errors}
                    label="Schdule On Date"
                    formik={formik}
                    type="text"
                    readOnly
                    value={
                      formik.values.scheduled_on
                        ? dayjs(formik.values.scheduled_on).format(
                            "ddd, MMM D, YYYY"
                          )
                        : null
                    }
                    onClick={() => setScheduled_on(!scheduled_on)}
                  />
                )}

                {scheduled_on && (
                  <div className="flex flex-col justify-center w-full">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        ref={scheduledOn}
                        open={true}
                        label="Schdule On Date"
                        value={formik?.values?.scheduled_on}
                        onChange={(val) => {
                          formik?.setFieldValue("scheduled_on", val.toString());
                        }}
                        onClose={() => setScheduled_on(false)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            sx={{
                              "& label.Mui-focused": {
                                color: "#000",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",

                                "& fieldset": {
                                  borderColor: "#a9a9a9",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#a9a9a9",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#a9a9a9",
                                },
                              },

                              "&  .MuiFormHelperText-root.Mui-error": {
                                backgroundColor: "transparent ",
                                margin: 0,
                                paddingLeft: 2,
                              },
                              mt: 0,
                            }}
                            size="small"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                )}
              </div>
              <div className="px-2 py-0 col-span-1 mb-3">
                <FormInput
                  errors={errors}
                  value={
                    formik.values.scheduled_on
                      ? dayjs(formik.values.scheduled_on).format("h:mm A")
                      : null
                  }
                  label="Schdule On Time"
                  formik={formik}
                  type="text"
                  readOnly
                />
              </div>
            </div>
            {formik?.values?.industry_id?.name !== "Towing" &&
              formik.values.scheduled_end && (
                <div className="md:grid grid-cols-2">
                  <div className="px-2 py-0 col-span-1 mb-3">
                    {!scheduled_end && (
                      <FormInput
                        errors={errors}
                        label="Schdule End Date"
                        formik={formik}
                        type="text"
                        readOnly
                        value={
                          formik.values.scheduled_end
                            ? dayjs(formik.values.scheduled_end).format(
                                "ddd, MMM D, YYYY"
                              )
                            : null
                        }
                        onClick={() => setScheduled_end(!scheduled_end)}
                      />
                    )}

                    {scheduled_end && (
                      <div className="flex flex-col justify-center w-full">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker
                            ref={scheduledOn}
                            open={true}
                            label="Schdule End Date"
                            value={formik?.values?.scheduled_end}
                            onChange={(val) => {
                              formik?.setFieldValue(
                                "scheduled_end",
                                val.toString()
                              );
                            }}
                            onClose={() => setScheduled_end(false)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                sx={{
                                  "& label.Mui-focused": {
                                    color: "#000",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    backgroundColor: "#fff",

                                    "& fieldset": {
                                      borderColor: "#a9a9a9",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#a9a9a9",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#a9a9a9",
                                    },
                                  },

                                  "&  .MuiFormHelperText-root.Mui-error": {
                                    backgroundColor: "transparent ",
                                    margin: 0,
                                    paddingLeft: 2,
                                  },
                                  mt: 0,
                                }}
                                size="small"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    )}
                  </div>
                  <div className="px-2 py-0 col-span-1 mb-3">
                    <FormInput
                      errors={errors}
                      value={dayjs(formik.values.scheduled_end).format(
                        "h:mm A"
                      )}
                      label="Schedule End Time"
                      formik={formik}
                      type="text"
                      readOnly
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationSection;
