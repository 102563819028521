import { TextField } from "@mui/material";
const InputForm = ({
  name,
  label,
  type,
  disabled = false,
  onClick,
  placeholder,
  value,
  readOnly,
  required,
  onChange
}) => {
  return (
    <div className="flex flex-col">
      <TextField
        id="standard-password-input"
        label={label ? label : placeholder}
        variant="outlined"
        sx={{
          "& label.Mui-focused": {
            color: "#000",
          },
          "& .MuiOutlinedInput-root": {
            backgroundColor:'#fff',

            "& fieldset": {
              borderColor: "#a9a9a9",
            },
            "&:hover fieldset": {
              borderColor: "#a9a9a9",

            },
            "&.Mui-focused fieldset": {
              borderColor: "#a9a9a9",
            },
          },

          "&  .MuiFormHelperText-root.Mui-error": {
            backgroundColor: "transparent ",
            margin: 0,
            paddingLeft: 2,
          },
          mt: 0,
        }}
        margin="dense"
        size="small"
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        disabled={disabled}
        onClick={onClick}
        readOnly={readOnly}
        required={required}
      />
    </div>
  );
};

export default InputForm;
