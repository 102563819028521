import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { createTld, deleteTld, getTlds, updateTld } from "../services/tldService";
const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
  record: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Industry Data
export const get_tld = createAsyncThunk(
  "get_tld",
  async (thunkAPI) => {
    try {
      return await getTlds();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Industry
export const create_tld = createAsyncThunk(
  "create_tld",
  async (data, thunkAPI) => {
    try {
      return await createTld(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Industry
export const update_tld = createAsyncThunk(
  "update_tld",
  async (data, thunkAPI) => {
    try {
      return await updateTld(data?.id, data?.name);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Industry
export const delete_tld = createAsyncThunk(
  "delete_tld",
  async (id, thunkAPI) => {
    try {
      return await deleteTld(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const TldsSlice = createSlice({
  name: "industries",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_tld.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_tld.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data
      })
      .addCase(get_tld.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(create_tld.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_tld.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.push(action.payload.data);
      })
      .addCase(create_tld.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_tld.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_tld.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.findIndex(
          ({ id }) => id === action?.payload?.data?.id
        );
        state.record[result] = {
          ...state.record[result],
          ...action.payload.data,
        };
      })
      .addCase(update_tld.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_tld.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_tld.fulfilled, (state, action) => {
        const result = state.record.filter(
          ({ id }) => id !== +action?.payload?.data
        );
        state.record = result;
        state.isLoading = false;
      })
      .addCase(delete_tld.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
export const { reset } = TldsSlice.actions;
export default TldsSlice.reducer;
