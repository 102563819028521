import React from "react";
import { FaChevronDown } from "react-icons/fa";
import { Button } from "../../../components";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { update_general_setting } from "../../../features/generalSettingSlice";
import InputForm from "./InputForms";
import errorMessage from "../../../util/errorMessage";
function GoogleAPIKey({ settings }) {
  let initialValues = {
    setting_type: "googleAPIKey",
    key: "",
  };

  React.useEffect(() => {
    setGoogleAPIKey(settings[0]?.googleAPIKey);
  }, [settings]);

  const dispatch = useDispatch();

  const [googleAPIKey, setGoogleAPIKey] = React.useState(initialValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setGoogleAPIKey({
      ...googleAPIKey,
      [name]: value,
    });
  };
  const handleSubmit = async () => {
    try {
      const res = await dispatch(
        update_general_setting(
          googleAPIKey?.setting_type === "googleAPIKey"
            ? googleAPIKey
            : { ...googleAPIKey, setting_type: "googleAPIKey" }
        )
      );
      if (res?.payload?.status === 200) {
        toast.success("Additional Info successfully updated");
      } else {
        errorMessage({payload:res.payload, action:"Google API Key", msg:'updated'})
      }
    } catch (error) {
      console.error(
        "Error ~ file: AddUpdateForm.jsx ~ line 59 ~ onSubmit: ~ error",
        error
      );
      errorMessage({payload:error, action:"Google API Key", msg:'updated'})
    }
  };

  return (
    <details className="border mt-3 mb-3 ">
      <summary className="flex flex-row items-center text-normal px-2 font-semibold font-pop justify-between border-b hover:cursor-pointer  bg-gray-100 hover:bg-gray-300 py-2 px-1">
        <span>Google API Key</span>
        <FaChevronDown iconClass="fas fa-times" />
      </summary>
      <div className="flex flex-col p-2">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <div className="grid grid-cols-12 gap-x-2 mt-2">
                <div className="col-span-12  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="key"
                      placeholder="key"
                      onChange={(e) => handleInputChange(e)}
                      value={googleAPIKey?.key}
                      type="text"
                    />
                  </div>
                </div>

                <div className="col-span-4">
                  <Button
                    text="Save"
                    variant="btn_submit"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </details>
  );
}

export default GoogleAPIKey;
