import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import {
  delete_template,
  emailTemplateReset,
  get_template,
} from "../../features/emailTemplateSlice";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { get_industry_drd } from "../../features/IndustrySlice";

const EmailTemplate = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.emailTemplate);
  const { industryDrd } = useSelector((state) => state.industry);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isShowDeail, setIsShowDetail] = useState(null);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    const totalItems=params?.id===isShowDeail?data.industries.length:3
    if (data?.industries && data?.industries.length > 1) {
      const tagHeight = 25; // height of each tag
      return rowHeight + tagHeight * totalItems + cellContentHeight;
    }else{
      return rowHeight
    }
  };
  const TagsCellRenderer = (data) => {
     const totalItems=data?.id===isShowDeail?data?.values?.length:2
    const tags = data?.value?.slice(0,totalItems)?.map((tag,index) => (
      <span
        key={tag}
        style={{
          margin: "2px",
          color: "black",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
        }}
      >
        {tag?.name?`${index+1}. ${tag?.name}`:`${index+1}- ${industryDrd?.find(({_id})=>_id===tag)?.name}`}
      </span>
    ));

    return (
      <div style={{ display: "flex", flexDirection:'column', width: "auto" }}>
        {tags}
        {
          data?.value?.length>2?
          <>
          {
            data?.id!==isShowDeail?
            <span 
          style={{padding: "0.2em 0.6em 0.3em",}}
          className="text-blue-600 hover:underline cursor-pointer relative"
          onClick={() => setIsShowDetail(data?.id)}
          >
            Show More ({data?.value?.length})
            </span>
            :<span 
            style={{padding: "0.2em 0.6em 0.3em",}}
            className="text-blue-600 hover:underline cursor-pointer relative"
            onClick={() => setIsShowDetail(null)}
            >
              Show less
              </span>
          }
          
            </>
            :null
        }
      </div>
    );
  };
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_template(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Email Template deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "Email Template",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          action: "Email Template",
          msg: "deleted",
        });
      }
    }
  };

  useEffect(() => {
    dispatch(get_template({ page: 1, size: paginationModel.pageSize }));
    dispatch(get_industry_drd())
    return () => {
      dispatch(emailTemplateReset());
    };
    // eslint-disable-next-line
  }, []);
  const templates = record?.records?.filter(
    (template) =>
      template?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      template?.slug?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 70 },
    { headerName: "Name", field: "name", flex: 1, minWidth: 150 },
    {
      headerName: "Industries",
      field: "industries",
      renderCell: TagsCellRenderer,
      flex: 1,
      minWidth: 200,
    },
    { headerName: "Slug", field: "slug" },
    { headerName: "Default", field: "is_default" },
    { headerName: "Send BBC", field: "send_bcc" },
    { headerName: "Send CC", field: "send_cc" },
    { headerName: "Status", field: "status" },
    {
      field: "actions",
      renderCell: (params) =>
        params.row.records.active ? (
          <div>
            {userPermission("Update Email Template") && (
              <FaEdit
                onClick={openFormHandler(params.row.records)}
                className="my_navIcon"
                title="Update Email Template"
              />
            )}
            {userPermission("Delete Email Template") && (
              <FaTrashAlt
                onClick={deleteRecordHandler(params.row.records._id)}
                className="my_navIcon"
                title="Delete Email Template"
              />
            )}
          </div>
        ) : null,
    },
  ];

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_template({
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Email template"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Email Templates"
        heading="Email Templates Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Email Template")}
      />
      <div className="container-fluid bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={templates?.map((record, index) => {
            let counter = index + 1;
            let name = record.name;
            let industries = record.industries;
            let slug = record.slug;
            let is_default = record.is_default ? "Yes" : "No";
            let send_bcc = record.send_bcc ? "Yes" : "No";
            let send_cc = record.send_cc ? "Yes" : "No";
            let status = record.active ? "Active" : "InActive";
            return {
              records: record,
              counter,
              name,
              slug,
              is_default,
              send_bcc,
              send_cc,
              status,
              industries
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          gridOptions={getRowHeight}
        />
      </div>
    </>
  );
};

export default EmailTemplate;
