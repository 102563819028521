import React from "react";
import { Backdrop, Modal } from "../../components";

function AddZipForm({ editZipRecord, onCancelForm, zip_list }) {
  const selectedZipIndex = zip_list?.filter(
    (list) => list?.location === editZipRecord?.location
  );
  return (
    <>
      <Backdrop onClick={onCancelForm} />
      <Modal title={editZipRecord?.title} onCancelModal={onCancelForm} onClick={onCancelForm}>
        <form>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-sm"
            rows={8}
            value={selectedZipIndex[0].zip.toString()}
            readOnly
          />
        </form>
      </Modal>
    </>
  );
}

export default AddZipForm;
