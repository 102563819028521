import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createJobCategory,
  deleteJobCategory,
  getCMSJobCategory,
  getJobCategory,
  updateJobCategory,
} from "../services/jobCategoryService";
import { getPhoneNo } from "../services/leads";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  cmsRecord: [],
  message: "",
  phone:''
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Job Category Data
export const get_job_category = createAsyncThunk(
  "get_job_category",
  async (data, thunkAPI) => {
    try {
      return await getJobCategory(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get CMS Job Category Data
export const get_cms_job_category = createAsyncThunk(
  "get_cms_job_category",
  async (data, thunkAPI) => {
    try {
      return await getCMSJobCategory(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get phone Data
export const get_phone = createAsyncThunk(
  "get_phone",
  async (id, thunkAPI) => {
    try {
      return await getPhoneNo(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Job Category
export const create_job_category = createAsyncThunk(
  "create_job_category",
  async (data, thunkAPI) => {
    try {
      return await createJobCategory(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Job Category
export const update_job_category = createAsyncThunk(
  "update_job_category",
  async (data, thunkAPI) => {
    try {
      return await updateJobCategory(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Job Category
export const delete_job_category = createAsyncThunk(
  "delete_job_category",
  async (id, thunkAPI) => {
    try {
      return await deleteJobCategory(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const jobCategorySlice = createSlice({
  name: "jobCategory",
  initialState,
  reducers: {
    jobCategoryReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.cmsRecord= [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_job_category.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_job_category.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_job_category.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(get_cms_job_category.pending, (state) => {
        state.isLoading = true;
        state.cmsRecord = [];
      })
      .addCase(get_cms_job_category.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cmsRecord = action.payload?.data;
      })
      .addCase(get_cms_job_category.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.cmsRecord = [];
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(create_job_category.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_job_category.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cmsRecord.push(action.payload.data[0]);
      })
      .addCase(create_job_category.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(update_job_category.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_job_category.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.cmsRecord.findIndex(
          ({ id }) => id === action?.payload?.data[0]?.id
        );
        state.cmsRecord[result] = {
          ...state.cmsRecord[result],
          ...action.payload.data[0],
        };
      })
      .addCase(update_job_category.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_job_category.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_job_category.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cmsRecord = state.cmsRecord.filter(
          ({ id }) => id !== +action?.payload?.data
        );
      })
      .addCase(delete_job_category.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_phone.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_phone.fulfilled, (state, action) => {
        state.isLoading = false;
        state.phone=action.payload.data.phone;
      })
      .addCase(get_phone.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.payload?action.payload:"Unknown Error")
      })
  },
});

export const { jobCategoryReset } = jobCategorySlice.actions;
export default jobCategorySlice.reducer;
