import React, { useCallback, useRef, useState } from "react";
import FormInput from "../Common/FormInput";
import PhoneFormInput from "../Common/PhoneFormInput";
import { useDropzone } from "react-dropzone";
import { FaTrash } from "react-icons/fa";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { DotsLoader } from "../../../components";

function CustomerInformation({
  formik,
  errors,
  handleSendImageForm,
  handleSaveImageForm,
}) {
  const [isLoader, setIsLoader] = useState(false);
  const checkAttachments = () => {
    const attachments = formik.values.attachments;
    if (!Array.isArray(attachments) || attachments.length === 0) {
      return { hasAttachments: false, hasFiles: false, hasUrlsOnly: false };
    }
    const hasFiles = attachments.some(
      (item) =>
        item instanceof File ||
        (typeof item === "string" &&
          (item.startsWith("data:image") || item.includes("data:image")))
    );
    const hasUrlsOnly =
      !hasFiles && attachments.every((item) => typeof item === "string");

    return { hasAttachments: true, hasFiles, hasUrlsOnly };
  };

  const inputFile = useRef(null);
  const onFileChange = (files) => {
    let img = [];
    Array.from(files).forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileDataUrl = reader.result;
        if (checkAttachments().hasAttachments) {
          const imageExists = formik.values.attachments.some(
            (existingImage) => existingImage === fileDataUrl
          );
          if (!imageExists) {
            img = img.concat(reader.result);
          }
        } else {
          img = img.concat(reader.result);
        }
        formik.setFieldValue(
          "attachments",
          checkAttachments().hasAttachments
            ? [...formik.values.attachments, ...img]
            : img
        );
      };
      reader.readAsDataURL(file);
    });
    inputFile.current.value = null;
  };
  const onDrop = useCallback((acceptedFiles) => {
    onFileChange(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });
  const removeImage = (index) => {
    if (typeof index === "string" && index.includes("merchants")) {
      const c = window.confirm("Are you sure want to delete this image?");
      if (!c) return;
      return deleteImage(index);
    }
    const c = window.confirm("Are you sure want to remove this image?");
    if (!c) return;
    const updatedAttachments = formik.values.attachments.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("attachments", updatedAttachments);
  };
  const deleteImage = async (path) => {
    setIsLoader(true);
    const file_name = path?.split("/").pop();
    try {
      const res = await api.post("api/jobs/delete_imgs", {
        id: formik.values._id,
        file_name,
      });
      if (res?.status === 200) {
        toast.success("Images deleted Successfully");
        const updatedAttachments = formik.values.attachments.filter(
          (attachment) => !attachment.includes(file_name)
        );
        formik.setFieldValue("attachments", updatedAttachments);
      } else {
        toast.error(res.data?.message || res?.data || "Image deletion failed");
      }
    } catch (err) {
      console.log("👊 ~ deleteImage ~ err:", err);
      toast.error(
        err.response.data?.message ||
          err?.response?.data ||
          "Image deletion failed"
      );
    }
    setIsLoader(false);
  };
  const paymentTypeOptions = [
    { label: "C.O.D", value: "COD" },
    { label: "Insurance", value: "Insurance" },
  ];
  return (
    <>
      <div className="bg-white border  px-2 md:px-4 ">
        {isLoader ? <DotsLoader /> : null}
        <div className="pl-2 pt-3 !mb-2">
          <div className="font-semibold text-lg">Customer Information:</div>
        </div>
        <div className="flex flex-col lg:flex-row gap-4">
          <div className="w-full lg:w-4/5 bg-neutral-50">
            <div className="flex flex-col p-2">
              <h6 className="font-semibold text-xs text-gray-500 w-[100px] border-t border-l border-r border-gray-300 rounded-tl rounded-tr border-b-0 ml-2 px-1 py-0">
                Payment Type
              </h6>
              <div className="flex flex-col lg:flex-row items-start lg:items-center space-y-2 lg:space-y-0 lg:space-x-4 mb-4">
                <div className="flex items-center space-x-4 border rounded-md border-gray-300 px-2 py-2">
                  {paymentTypeOptions?.map(({ value, label }) => {
                    return (
                      <div
                        className={`md:self-center flex flex-row md:items-center`}
                        key={value}
                      >
                        <input
                          type="radio"
                          id={`payment_type-${value}`}
                          name="payment_type"
                          value={value}
                          className="mr-2 cursor-pointer"
                          checked={formik?.values?.payment_type === value}
                          onChange={(e) =>
                            formik?.setFieldValue(
                              "payment_type",
                              e.target.value
                            )
                          }
                        />
                        <label className="flex items-center">{label}</label>
                      </div>
                    );
                  })}

                  {/* <div
                    className={`md:self-center flex flex-row md:items-center`}
                  >
                    <input
                      type="radio"
                      id="Insurance"
                      name="payment_type"
                      value="Insurance"
                      className="mr-2 cursor-pointer"
                      checked={formik?.values?.payment_type === "Insurance"}
                      onChange={(e) =>
                        formik?.setFieldValue("payment_type", e.target.value)
                      }
                    />
                    <label className="flex items-center">Insurance</label>
                  </div> */}
                </div>
                <FormInput
                  name="po_insurance"
                  type="text"
                  placeholder="PO Insurance"
                  formik={formik}
                />
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/5">
            <label
              className="font-semibold text-xs text-gray-500 mb-1"
              htmlFor={"lead_num"}
            >
              {"Lead Number"}
            </label>
            <input
              type="text"
              id="lead_num"
              className="border border-gray-300 rounded-md p-2 w-full"
              placeholder="1255 4454 2452"
              disabled
              value={formik?.values?.lead_num || ""}
            />
          </div>
        </div>

        <div className="border-t-2 mb-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4 mb-4 mt-2">
            <FormInput
              name="name"
              type="text"
              label="Full Name"
              formik={formik}
            />
            {/* <FormInput name="last_name" type="text" label={"Last Name"} /> */}
            <FormInput
              name="email"
              type="text"
              label={"Enter Email"}
              formik={formik}
            />
            <PhoneFormInput
              name="phone"
              formik={formik}
              placeholder="Phone No."
              label="Phone Number"
              onChange={(e) => formik.setFieldValue("phone", e)}
              value={formik.values.phone}
              errors={errors}
            />
            <PhoneFormInput
              name="alternate_phone"
              label="Alternative Number"
              placeholder="Alternate Phone No."
              formik={formik}
              onChange={(e) => formik.setFieldValue("alternate_phone", e)}
              value={formik?.values?.alternate_phone}
              errors={errors}
            />
          </div>
        </div>
      </div>

      <div className="bg-white border px-2 py-2 md:px-4 mt-3">
        <div className="grid grid-cols-2 gap-4 my-2">
          <div className="flex flex-col h-full">
            <div className="font-semibold text-sm text-gray-500 mb-1">
              Additional Info/Instructions:
            </div>
            <textarea
              id="special_instruction"
              className="border rounded-md border-gray-300 p-2 h-full resize-none"
              placeholder="Type problem here"
              name="special_instruction"
              onChange={(e) =>
                formik?.setFieldValue("special_instruction", e.target.value)
              }
              value={formik?.values?.special_instruction}
            />
          </div>
          <div className="flex flex-col h-full">
            <div className="font-semibold text-sm text-gray-500 mb-1">
              Request for photo: (Only if needed)
            </div>
            <div className="border rounded-md h-[215px] border-gray-300 p-2 flex items-start justify-start h-full flex-col">
              {checkAttachments()?.hasAttachments ? (
                <div className="w-full overflow-x-auto flex">
                  {formik?.values?.attachments?.map((img, index) => {
                    return (
                      <div className="relative w-56 flex-shrink-0" key={index}>
                        <img
                          src={
                            img.includes("merchants")
                              ? `${process.env.REACT_APP_BACKEND_URL}/${img}`
                              : img
                          }
                          alt="Car"
                          className="w-56 h-40 shadow m-1 rounded"
                        />
                        <button
                          type="button"
                          onClick={() =>
                            removeImage(img.includes("merchants") ? img : index)
                          }
                          className="absolute !p-1 bg-white top-0 right-0 w-7 h-7 rounded-bl-xl flex justify-center items-center hover:text-red-500"
                        >
                          <FaTrash />
                        </button>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="!my-3 flex justify-center w-full">
                  <div
                    className={`w-full flex justify-center border-2 border-gray-300 w-60 h-30 border-dashed rounded-md !p-2 hover:border-gray-400 focus:outline-none ${
                      isDragActive ? "bg-gray-100" : "bg-white"
                    }`}
                    {...getRootProps()}
                  >
                    <label
                      className={`flex justify-center w-ful ${"h-[80px]"} px-4 transition appearance-none cursor-pointer`}
                    >
                      <span className="flex items-center space-x-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-gray-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          />
                        </svg>
                        <span className="font-medium text-gray-600">
                          Drop files to Attach, or{" "}
                          <span
                            className="text-blue-600 underline"
                            onClick={open}
                          >
                            browse
                          </span>
                        </span>
                      </span>
                      <input {...getInputProps()} />
                    </label>
                  </div>
                </div>
              )}

              <input
                style={{ display: "none" }}
                ref={inputFile}
                multiple
                onChange={(e) => onFileChange(e.target.files)}
                type="file"
              />
              <div className="flex justify-between w-full mt-1">
                <div className="gap-x-2 flex">
                  <button
                    className="text-[#828282] font-semibold hover:underline text-sm shadow p-0.5"
                    onClick={handleSendImageForm}
                  >
                    Send Link
                  </button>
                  {checkAttachments().hasAttachments ? (
                    <button
                      className="text-[#828282] font-semibold hover:underline text-sm shadow p-0.5"
                      onClick={() => inputFile.current.click()}
                    >
                      Browse
                    </button>
                  ) : null}
                </div>
                {checkAttachments().hasFiles ? (
                  <button
                    className="text-[#828282] font-semibold hover:underline text-sm shadow p-0.5"
                    onClick={handleSaveImageForm}
                  >
                    Save
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div dangerouslySetInnerHTML={{ __html: decodedText }} /> */}
    </>
  );
}

export default CustomerInformation;
