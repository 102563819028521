import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addNationSiteZips,
  createNationSite,
  createNationSiteVendors,
  createRating,
  createServiceHours,
  getNationSiteList,
  getNationSiteVendorsList,
  getNationSiteZips,
  getRating,
  getServiceHours,
  updateNationSite,
  updateNationSiteVendors,
  updateRating,
  updateServiceHours,
  deleteRating,
  deleteVendor,
  deleteServiceHours,
} from "../services/nationSiteService";

const initialState = {
  isError: false,
  isLoading: false,
  nationSites: [],
  nationVendors: [],
  nationZips: [],
  serviceHours: [],
  nationSiteRating:[],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Nation Sites Data
export const get_nation_site = createAsyncThunk(
  "get_nation_site",
  async (thunkAPI) => {
    try {
      return await getNationSiteList();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Nation Sites
export const create_nation_site = createAsyncThunk(
  "create_nation_site",
  async (data, thunkAPI) => {
    try {
      return await createNationSite(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Nation Sites
export const update_nation_site = createAsyncThunk(
  "update_nation_site",
  async (data, thunkAPI) => {
    try {
      return await updateNationSite(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_nation_site_zips = createAsyncThunk(
  "get_nation_site_zips",
  async (id, thunkAPI) => {
    try {
      return await getNationSiteZips(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const add_nation_site_zips = createAsyncThunk(
  "add_nation_site_zips",
  async (data, thunkAPI) => {
    try {
      return await addNationSiteZips(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Nation Site Vendors Data
export const get_nation_site_vendors = createAsyncThunk(
  "get_nation_site_vendors",
  async (thunkAPI) => {
    try {
      return await getNationSiteVendorsList();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Nation Site Vendors
export const create_nation_site_vendors = createAsyncThunk(
  "create_nation_site_vendors",
  async (data, thunkAPI) => {
    try {
      return await createNationSiteVendors(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Nation Site Vendors
export const update_nation_site_vendors = createAsyncThunk(
  "update_nation_site_vendors",
  async (data, thunkAPI) => {
    try {
      return await updateNationSiteVendors(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);


// Service Hours
export const get_service_hours = createAsyncThunk(
  "get_service_hours",
  async (thunkAPI) => {
    try {
      return await getServiceHours();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const create_service_hours = createAsyncThunk(
  "create_service_hours",
  async (data, thunkAPI) => {
    try {
      return await createServiceHours(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const update_service_hours = createAsyncThunk(
  "update_service_hours",
  async (data, thunkAPI) => {
    try {
      return await updateServiceHours(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Nation Site rating
export const get_rating = createAsyncThunk(
  "get_rating",
  async (thunkAPI) => {
    try {
      return await getRating();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const create_rating = createAsyncThunk(
  "create_rating",
  async (data, thunkAPI) => {
    try {
      return await createRating(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const update_rating = createAsyncThunk(
  "update_rating",
  async (data, thunkAPI) => {
    try {
      return await updateRating(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Delete Rating
export const delete_rating = createAsyncThunk(
  "delete_rating",
  async (id, thunkAPI) => {
    try {
      return await deleteRating(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Vendor
export const delete_vendor = createAsyncThunk(
  "delete_vendor",
  async (id, thunkAPI) => {
    try {
      return await deleteVendor(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Delete Service Hours
export const delete_service_hours = createAsyncThunk(
  "delete_service_hours",
  async (id, thunkAPI) => {
    try {
      return await deleteServiceHours(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const nationSitesSlice = createSlice({
  name: "nationSites",
  initialState,
  reducers: {
    nationSiteReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.nationSites = [];
      state.message = "";
    },
    nationSiteZipsReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.nationZips = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_nation_site.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_nation_site.fulfilled, (state, action) => {
        state.isLoading = false;
        state.nationSites = action.payload?.data;
        // state.nationSites = action.payload?.data?.map((item) => ({
        //   ...item,
        //   dids: [],
        //   tollfree: [],
        // }));
      })
      .addCase(get_nation_site.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.nationSites = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_nation_site.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_nation_site.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_nation_site.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_nation_site.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_nation_site.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_nation_site.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_nation_site_vendors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_nation_site_vendors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.nationVendors = action.payload?.data;
      })
      .addCase(get_nation_site_vendors.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.nationVendors = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_nation_site_vendors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_nation_site_vendors.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_nation_site_vendors.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_nation_site_vendors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_nation_site_vendors.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_nation_site_vendors.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(get_nation_site_zips.pending, (state) => {
        state.isLoading = true;
        state.isLoader = true;
        state.nationZips = [];
      })
      .addCase(get_nation_site_zips.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.nationZips = action.payload?.data;
      })
      .addCase(get_nation_site_zips.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.nationZips = [];
      })
      .addCase(add_nation_site_zips.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_nation_site_zips.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action?.payload?.data?.message);
      })
      .addCase(add_nation_site_zips.rejected, (state, action) => {
        state.isLoading = false;
      })
      
      .addCase(get_service_hours.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_service_hours.fulfilled, (state, action) => {
        state.isLoading = false;
        state.serviceHours = action.payload?.data;
      })
      .addCase(get_service_hours.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.serviceHours = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_service_hours.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_service_hours.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_service_hours.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_service_hours.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_service_hours.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_service_hours.rejected, (state, action) => {
        state.isLoading = false;
      })
      
      
      
      .addCase(get_rating.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_rating.fulfilled, (state, action) => {
        state.isLoading = false;
        state.nationSiteRating = action.payload?.data;
      })
      .addCase(get_rating.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.nationSiteRating = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_rating.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_rating.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_rating.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_rating.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_rating.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_rating.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_rating.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_rating.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_rating.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(delete_vendor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_vendor.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_vendor.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(delete_service_hours.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_service_hours.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_service_hours.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      });
  },
});

export const { nationSiteReset, nationSiteZipsReset } =
  nationSitesSlice.actions;
export default nationSitesSlice.reducer;
