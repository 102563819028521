import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "../../../components";
import {
  create_service_hours,
  update_service_hours,
  get_service_hours,
} from "../../../features/nationSitesSlice";

const AddHours = ({ onCloseModal, editingRecord }) => {
  const dispatch = useDispatch();
  const initialValues = {
    hours: [
      { day: "mon", opens: "", closes: "" },
      { day: "tue", opens: "", closes: "" },
      { day: "wed", opens: "", closes: "" },
      { day: "thu", opens: "", closes: "" },
      { day: "fri", opens: "", closes: "" },
      { day: "sat", opens: "", closes: "" },
      { day: "sun", opens: "", closes: "" },
    ],
    ...(editingRecord && {
      id: editingRecord._id,
      hours: editingRecord.hours,
    }),
  };
  const [payload, setPayload] = useState(initialValues);
  const inputRefs = useRef([]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 27) {
        onCloseModal();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onCloseModal]);

  const handleChange = (event, index, key) => {
    const value = event.target.value.replace(/[^\d]/g, "").slice(0, 4);
    const hour = value.length >= 1 ? parseInt(value.substring(0, 2)) : 0;
    const minute = value.length >= 4 ? parseInt(value.substring(2, 4)) : 0;

    if (hour >= 0 && hour <= 24 && minute >= 0 && minute <= 59) {
      const time = value.replace(/^(\d{2})/, "$1:");
      setPayload((prevHours) => ({
        ...prevHours,
        hours: prevHours?.hours.map((h, i) =>
          i === index ? { ...h, [key]: time } : h
        ),
      }));

      const inputIndex = inputRefs.current.indexOf(event.target);
      if (inputIndex < inputRefs.current.length - 1 && value.length === 4) {
        inputRefs.current[inputIndex + 1].focus();
      }
    } else {
      toast.error(`Enter a valid format 'HH:MM'`);
    }
  };

  const onSubmit = async () => {
    try {
      const res = editingRecord
        ? await dispatch(update_service_hours(payload))
        : await dispatch(create_service_hours(payload));

      const successMessage = editingRecord ? "Hours Updated" : "Hours Added";
      const errorMessage = editingRecord
        ? "Hours couldn't be updated"
        : "Hours couldn't be added";

      if (res.payload.status === 200 || res.payload.status === 201) {
        toast.success(successMessage);
        dispatch(get_service_hours());
        onCloseModal();
      } else {
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
      <div className="container-fluid mt-2 text-center">
        <div className="inline-block bg-white w-3/5 my-0 mx-auto p-4 rounded max-h-[88vh]">
          <header className="flex items-center justify-center shadow-lg bg-primary-100 text-white h-[50px] mb-3 text-xl">
            {`${editingRecord ? "Update" : "Add"} Service Hours`}
          </header>
          <div className="h-auto mb-2">
            {payload?.hours.map((hour, index) => (
              <div
                key={hour.day}
                className="flex flex-row items-center justify-center w-full"
              >
                <label className="text-lg font-medium p-2 w-3/12 capitalize">
                  {hour.day}
                </label>
                <input
                  className="bg-white border border-slate-300 rounded-md p-2 w-1/4 shadow-sm"
                  type="text"
                  placeholder="hh:mm"
                  value={hour.opens}
                  onChange={(event) => handleChange(event, index, "opens")}
                  ref={(el) => (inputRefs.current[index * 2] = el)}
                />
                <input
                  className="bg-white border border-slate-300 rounded-md p-2 w-1/4 ml-2 shadow-sm"
                  type="text"
                  placeholder="hh:mm"
                  value={hour.closes}
                  onChange={(event) => handleChange(event, index, "closes")}
                  ref={(el) => (inputRefs.current[index * 2 + 1] = el)}
                />
              </div>
            ))}
          </div>
          <Button
            text="Cancel"
            variant="btn_cancel"
            className="ml-3 mt-2"
            onClick={onCloseModal}
          />
          <Button
            text="Submit"
            variant="btn_submit"
            className="ml-3 mt-2"
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default AddHours;
