import React, { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import AddUpdateForm from "./AddUpdateForm";
import { useSelector, useDispatch } from "react-redux";
import { delete_tag, get_tags } from "../../features/tagsSlice";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";

const Tag = () => {
  const { isLoading, record } = useSelector((state) => state.tags);
  const dispatch = useDispatch();

  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_tag(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Tag deleted");
          cancelFormHandler();
        } else {
          errorMessage({ payload: res.payload, action: "Tag", msg: "deleted" });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Tag", msg: "deleted" });
      }
    }
  };

  useEffect(() => {
    dispatch(get_tags({ page: 1, size: paginationModel.pageSize }));
    // eslint-disable-next-line
  }, []);
  const tags = record?.records?.filter((record) =>
    record?.name?.includes(searchText)
  );
  const columnDefs = [
    { headerName: "#", field: "counter" },
    {
      headerName: "NAME",
      field: "name",
      // flex: 1,
      minWidth: 300,
      renderCell: (params) => (
        <div
          style={{
            backgroundColor: params?.row?.color,
            flex:1,
            height:40,
            alignContent:'center',
            justifyContent:'center'
          }}
          className={`py-px px-0.5 bg-[${params?.row?.color}] rounded-sm text-white min-w-[72px] text-center flex items-center`}
        >
          {params?.row?.name}
        </div>
      ),
    },
    { headerName: "COLOR", field: "color", flex: 1, minWidth: 200 },
    { headerName: "STATUS", field: "status", flex: 1, minWidth: 200 },
    {
      field: "actions",
      renderCell: (params) =>
        params.row.records.active ? (
          <div>
            {userPermission("Update Tag") && (
              <FaEdit
                onClick={openFormHandler(params.row.records)}
                className="my_navIcon"
                title="Update Tag"
              />
            )}
            {userPermission("Delete Tag") && (
              <FaTrashAlt
                onClick={deleteRecordHandler(params.row.records._id)}
                className="my_navIcon"
                title="Delete Tag"
              />
            )}
          </div>
        ) : null,
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_tags({
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Tag"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Tags"
        heading="Tags Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Tag")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={tags?.map((record, index) => {
            let counter = index + 1;
            let name = record.name;
            let color = record.color;
            let status = record.active ? "Active" : "InActive";
            return {
              counter,
              name,
              color,
              status,
              records: record,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default Tag;
