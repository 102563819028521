import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PageHeader from "../../../components/molecules/PageHeader";
import errorMessage from "../../../util/errorMessage";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import {
  callCenterReset,
  delete_affiliate,
  get_affiliate,
} from "../../../features/callCentersSlice";
import { getFormattedDate } from "../../../util/common";
const Affiliate = () => {
  const dispatch = useDispatch();
  const { isLoading, affiliate } = useSelector((state) => state.callCenters);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_affiliate(record_id));
        if (res.payload?.status === 200) {
          toast.success("Affiliate deleted");
          // dispatch(get_affiliate());
        } else {
          errorMessage({
            payload: res.payload,
            action: "Affiliate",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          action: "Affiliate",
          msg: "deleted",
        });
      }
    }
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  useEffect(() => {
    dispatch(get_affiliate());
    return () => {
      dispatch(callCenterReset());
    };
    // eslint-disable-next-line
  }, []);
  const filteredNumbers = Array.isArray(affiliate)
    ? affiliate?.filter((e) => {
        return Object.keys(e)?.some(
          (key) =>
            e[key] &&
            e[key]
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchText?.toLowerCase())
        );
      })
    : [];
  const columnDefs = [
    { headerName: "#", field: "counter" },
    { headerName: "Name", field: "name", flex: 1 },
    { headerName: "Created At", field: "createdAt", flex: 1 },
    {
      field: "actions",
      renderCell: (params) => (
        <div>
          <FaEdit
            onClick={openFormHandler(params?.row?.records)}
            className="my_navIcon"
            title="Update Affiliate"
          />
          <FaTrashAlt
            onClick={deleteRecordHandler(params?.row?.records?._id)}
            className="my_navIcon"
            title="Delete Affiliate"
          />
        </div>
      ),
    },
  ];
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Affiliates"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        heading="Affiliates Listing"
        onClick={openFormHandler(0)}
        isAllowed={true}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={filteredNumbers?.map((record, index) => {
            let counter = index + 1;
            const { name, createdAt } = record;
            return {
              counter,
              records: { ...record },
              name,
              createdAt: getFormattedDate(createdAt),
            };
          })}
          totalItems={affiliate?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          pagination="No"
        />
      </div>
    </>
  );
};

export default Affiliate;
