import React from "react";
import { NavLink } from "react-router-dom";
import menu from "./menu.json";
import { HiOutlineCalculator } from "react-icons/hi";
import { CgCloseR } from "react-icons/cg";
import { TbUserOff } from "react-icons/tb";
import userPermission from "../../util/userPermission";
function CallCenterMenu() {
  const getIcon = (service) => {
    let Icon = "";
    switch (service) {
      case "Inbound Groups":
        Icon = TbUserOff;
        break;

      case "Campaigns":
        Icon = HiOutlineCalculator;
        break;

      case "DIDs":
        Icon = CgCloseR;
        break;

      case "Call Center":
        Icon = CgCloseR;
        break;

      case "Agent Performance":
        Icon = CgCloseR;
        break;

      case "Call Summary":
        Icon = CgCloseR;
        break;
      case "Call Logs":
        Icon = CgCloseR;
        break;

      default:
        Icon = CgCloseR;
        break;
    }
    return <Icon size={30} />;
  };

  return (
    <>
      <div className="mt-2 md:mt-3">
        <div className="border-b w-full">
          <span className="!ml-3 text-xl font-semibold">Forms</span>
        </div>
        <div className="flex flex-col lg:flex-wrap lg:flex-row justify-between mx-[1%] mt-4 md:mt-3">
          {menu.data.map((report, index) => {
            const { path, title, task } = report;
            return (
              <>
                {userPermission(task) && (
                  <NavLink
                    key={index}
                    to={path}
                    className="flex justify-between items-center text-ml-3 !mb-5 !p-2 w-full lg:w-[32%] hover:no-underline no-underline text-black shadow-report rounded-[1px] bg-white border-l-[3px] border-primary-100 hover:shadow-hover transition-all"
                  >
                    {title}
                    {getIcon(title)}
                  </NavLink>
                )}
              </>
            );
          })}
        </div>
      </div>
      <div className="mt-2 md:mt-3">
        <div className="border-b w-full">
          <span className="!ml-3 text-xl font-semibold">Reports</span>
        </div>
        <div className="flex flex-col lg:flex-wrap lg:flex-row justify-start mx-[1%] mt-4 md:mt-3 gap-x-6">
          {menu.repots.map((report, index) => {
            const { path, title, task } = report;
            return (
              <>
                {userPermission(task) && (
                  <NavLink
                    key={index}
                    to={path}
                    className="flex justify-between items-center text-ml-3 !mb-5 !p-2 w-full lg:w-[32%] hover:no-underline no-underline text-black shadow-report rounded-[1px] bg-white border-l-[3px] border-primary-100 hover:shadow-hover transition-all"
                  >
                    {title}
                    {getIcon(title)}
                  </NavLink>
                )}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default CallCenterMenu;
