import Select from "react-select";

const FormMultiSelectInput = ({
  name,
  label,
  formik,
  userOptions,
  multiSelectHandler,
  customStyle,
  defaultValue,
  errors,
}) => {
  const style = {
    menu: (provided) => ({
      ...provided,
      width: "auto",
      minWidth: "200px",
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#ffe7c7",
    }),
    menuList: (provided) => ({
      ...provided,
      width: "auto",
    }),
  };
  return (
    <div className="relative">
      {label && (
        <label
          htmlFor={name}
          className={[
            `small`,
            formik.errors[name] && formik.touched[name]
              ? "text-[rgb(211,_47,_47)]"
              : "text-black",
            label
              ? "text-[rgba(0,_0,_0,_1)] absolute left-1 top-0 font-normal text-base truncate max-w-[calc(133%-24px)] -translate-y-[13px] bg-white scale-75 px-1 z-10"
              : "",
          ].join(" ")}
        >
          {label}
        </label>
      )}
      <div>
        <Select
          styles={customStyle ? customStyle : style}
          isMulti
          closeMenuOnSelect={false}
          id={name}
          name={name}
          options={userOptions}
          className={[
            formik.errors[name] && formik.touched[name]
              ? "border-[rgb(211,_47,_47)]"
              : "",
          ].join(" ")}
          classNamePrefix="form-select"
          onChange={(e) => {
            multiSelectHandler(e);
          }}
          onBlur={formik.handleBlur}
          defaultValue={defaultValue?.map((ele) => ({
            label: ele?.first_name ? ele?.first_name : ele?.name,
            value: ele?._id,
          }))}
          menuPosition="fixed"
        />
      </div>
      {formik.errors[name] && formik.touched[name] && (
        <small className="ml-3 form-text text-muted !text-[rgb(211,_47,_47)]">
          {formik.errors[name]}
        </small>
      )}
      {errors !== undefined &&
        errors?.length > 0 &&
        errors?.map((error, index) => {
          return (
            <>
              {error[name] && (
                <small
                  className="ml-3 form-text text-muted !text-[rgb(211,_47,_47)]"
                  key={index}
                >
                  {error[name].replaceAll(`"${name}"`, label)}
                </small>
              )}
            </>
          );
        })}
    </div>
  );
};

export default FormMultiSelectInput;
