import React, { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import AddUpdateProvider from "./AddUpdateVendors";
import userPermission from "../../util/userPermission";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import {
  get_geo_states,
  get_geo_vendors,
  delete_geo_vendors,
} from "../../features/GeoVendorsSlice";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import ChildVendors from "./ChildVendors";
import { HiLockClosed } from "react-icons/hi";
const GeoVendors = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.geoVendors);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isChildVendors, setIsChildVendors] = useState(false);
  const [vendor_id, setVendor_id] = useState(false);
  const [searchText, setSearchText] = useState("");

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setIsChildVendors(false);
    setVendor_id(null);
  };
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_geo_vendors(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Vendor deleted");
          dispatch(get_geo_vendors());
        } else {
          errorMessage({
            payload: res.payload,
            action: "Vendor",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Vendor", msg: "deleted" });
      }
    }
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const childVendors = (id) => () => {
    setVendor_id(id);
    setIsChildVendors(true);
  };

  useEffect(() => {
    dispatch(get_geo_vendors());
    dispatch(get_geo_states());
    // eslint-disable-next-line
  }, []);
  const vendors = record?.records?.filter((vendor) =>
    vendor?.name?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Vendor Name", field: "name", flex: 1 },
    { headerName: "Phone", field: "phone", flex: 1 },
    {
      headerName: "Tech Count",
      field: "tech_count",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <span
          className="text-blue-600 cursor-pointer hover:underline"
          onClick={childVendors(params.row.records?.id)}
        >
          {params.row.tech_count}
        </span>
      ),
    },
    // { headerName: "Tech Count", field: "tech_count", flex: 1 },
    { headerName: "Search Log Count", field: "search_log_count", flex: 1 },
    { headerName: "State", field: "state_id", flex: 1 },
    { headerName: "City", field: "city_id", flex: 1 },
    { headerName: "Zip Code", field: "zip_id", flex: 1 },
    { headerName: "Description", field: "description", flex: 1 },
    {
      field: "actions",
      renderCell: (params) => (
        <div>
          <FaEdit
            onClick={openFormHandler(params.row.records)}
            className="my_navIcon"
            title="Update Vendor"
          />
          {params.row.records?.tech_count === 0 &&
          params.row.records?.search_log_count === 0 ? (
            <FaTrashAlt
              onClick={deleteRecordHandler(params.row.records.id)}
              className="my_navIcon"
              title="Delete Vendor"
            />
          ) : (
            <HiLockClosed className="my_navIcon" size={18} />
          )}
        </div>
      ),
      width: 120,
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  return (
    <>
      {isEditing && (
        <AddUpdateProvider
          editingRecord={editingRecord}
          modalTitle="Add Vendors"
          onCancelForm={cancelFormHandler}
        />
      )}

      <PageHeader
        route="Setting > Vendors"
        heading="Vendors Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Geo Location")}
      />
      {isChildVendors && (
        <ChildVendors
          modalTitle="Child Vendors"
          onCancelForm={cancelFormHandler}
          id={vendor_id}
        />
      )}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={vendors?.map((record, index) => {
            let counter = index + 1;
            let name = record?.name;
            let phone = record?.phone;
            let state_id = record?.state_id?.name;
            let city_id = record.city_id?.name;
            let description = record?.description;
            let zip_id = record?.zip_id?.zip;
            let search_log_count = record?.search_log_count;
            let tech_count = record?.tech_count;
            return {
              counter,
              records: { ...record, _id: counter },
              name,
              phone,
              state_id,
              city_id,
              description,
              zip_id,
              search_log_count,
              tech_count,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          paginationMode={"client"}
        />
      </div>
    </>
  );
};

export default GeoVendors;
