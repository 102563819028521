import React, { useState } from "react";
import {
  DotsLoader,
  Modal,
  FormSelectInput,
  FormInput,
} from "../../components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import api from "../../services/api";
const AddCardModal = ({
  onCancelForm,
  modalTitle,
  budgetId,
  ownerId,
  editingRecord,
  getVirtaulCardList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    cardName: "",
    amount: "",
    cardType: "onetime",
    ...(editingRecord && {
      amount: editingRecord?.currentPeriod?.limit,
      cardName: editingRecord.name,
      cardType: editingRecord.recurring ? "recurring" : "onetime",
    }),
  };

  const validationSchema = Yup.object({
    cardName: Yup.string().required("Card Name is required"),
    amount: Yup.number()
      .required("Amount is required")
      .typeError("Amount must be a number")
      .positive("Amount must be a positive number")
      .test(
        "is-decimal",
        "Amount must be a valid decimal number",
        (value) => value === undefined || !isNaN(value)
      ),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const payload = {
        ...(editingRecord && { cardId: editingRecord.id }),
        cardName: values?.cardName,
        ownerId,
        budgetId,
        amount: values?.amount,
        ...(editingRecord && {
          recurring: values?.cardType === "recurring" ? true : false,
        }),
      };

      setIsLoading(true);

      try {
        const url = editingRecord
          ? "/api/bill/update_virtual_card"
          : "/api/bill/create_virtual_card";

        const method = editingRecord ? "put" : "post";

        const res = await api[method](url, payload);

        if (res.status === 200 || res.status === 201) {
          const action = editingRecord ? "updated" : "added";
          toast.success(`Card successfully ${action}`);
          onCancelForm();
          getVirtaulCardList();
        }
      } catch (err) {
        console.error(err);
        const errorMessage =
          err?.response?.data?.error ||
          err?.response?.data?.message ||
          "An error occurred";
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    },
  });
  return (
    <Modal
      isUpdate={!!editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      formIsValid={formik.isValid && formik.dirty}
      onSubmit={formik.handleSubmit}
      isLoading={isLoading}
      onClick={onCancelForm}
    >
      {isLoading && <DotsLoader />}
      <div className="!mb-3">
        <FormInput name="cardName" label="Card Name" formik={formik} />
      </div>
      <div className="!mb-3">
        <FormInput
          name="amount"
          type="number"
          label="Amount ($)"
          formik={formik}
        />
      </div>
      <div className="mb-3">
        <FormSelectInput
          name="cardType"
          label="Card Type"
          formik={formik}
          disabled={!editingRecord?.id}
          onChange={(e) => {
            formik?.setFieldValue("cardType", e);
          }}
          options={[
            { label: "One Time", value: "onetime" },
            { label: "Recurring", value: "recurring" },
          ]}
          labelProp="label"
          valueProp="value"
          nullOption="No"
        />
      </div>
    </Modal>
  );
};

export default AddCardModal;
