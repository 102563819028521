import React, { useEffect } from "react";
import { DotsLoader, FormInput, Loader, Modal } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  create_allowed_ip,
  update_allowed_ip,
} from "../../features/allowedipSlice";
import { useFormik } from "formik";
import { get_users_drd } from "../../features/usersSlice";
import FormMultiSelectInput from "../../components/molecules/FormMultiSelectInput";
import { toast } from "react-toastify";
const AllowedIPForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.allowedIP);
  const { usersDrd, isLoader } = useSelector((state) => state.users);
  const [userOptions, setUserOptions] = React.useState([]);
  const [errors, setErrors] = React.useState([]);

  const initialValues = {
    ip_address: "",
    user_ids: "",
    ...(editingRecord && {
      id: editingRecord._id,
      ip_address: editingRecord.ip_address,
      user_ids:
        editingRecord.user_ids?.map((val) => val?.user_id?._id)?.toString() ||
        "",
    }),
  };
  const handleSubmit = async (values) => {
    const action = editingRecord ? update_allowed_ip : create_allowed_ip;
    const message = editingRecord ? "updated" : "created";
    try {
      const res = await dispatch(action(values));
      if (res.payload?.status === 200) {
        toast.success(`Allowed IP ${message}`);
        onCancelForm();
      } else {
        if (Array.isArray(res.payload)) {
          setErrors(res.payload);
        } else {
          toast.error(
            res.payload ? res.payload : `Allowed IP couldn't be ${message}`
          );
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload)) {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload ? error.payload : `Allowed IP couldn't be ${message}`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (usersDrd?.length === 0) {
      dispatch(get_users_drd());
    }

    if (usersDrd?.length > 0) {
      const arr =
        usersDrd?.map(({ _id, first_name }) => ({
          label: first_name,
          value: _id,
        })) || [];
      setUserOptions(arr);
    }
    // eslint-disable-next-line
  }, []);
  const multiSelectHandler = (e) => {
    const user_ids = e?.map((val) => val?.value) || [];
    formik.setFieldValue("user_ids", user_ids.toString());
  };

  const style = {
    menu: (provided) => ({
      ...provided,
      width: "auto",
      minWidth: "200px",
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#ffe7c7",
    }),
    menuList: (provided) => ({
      ...provided,
      width: "auto",
    }),
  };
  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        {isLoader && <Loader />}
        <form className="mt-4">
          <div className="!mb-3">
            <FormInput
              errors={errors}
              name="ip_address"
              label="Allowed IP"
              formik={formik}
            />
          </div>
          <FormMultiSelectInput
            errors={errors}
            customStyle={style}
            name="user_ids"
            label="Users"
            defaultValue={editingRecord ? editingRecord?.user_ids : null}
            formik={formik}
            userOptions={userOptions}
            multiSelectHandler={multiSelectHandler}
          />
        </form>
      </Modal>
    </>
  );
};

export default AllowedIPForm;
