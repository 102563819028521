import PhoneInput from "react-phone-number-input";

const PhoneFormInput = ({
  name,
  label,
  formik,
  placeholder,
  value,
  errors,
  onChange,
}) => {
  return (
    <div className="flex flex-col">
      {label ? (
        <label
          className="font-semibold text-xs text-gray-500 mb-1"
          htmlFor={name}
        >
          {label}
        </label>
      ) : null}

      <PhoneInput
        defaultCountry="US"
        className={[
          "shadow appearance-none border rounded max-[400px]:w-[100%] min-[400px]:w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
          `rounded-l-none`,
          formik.errors[name] && formik.touched[name] ? "invalid" : "",
        ].join(" ")}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        initialValueFormat="national"
      />
      {formik?.errors[name] && formik?.touched[name] && (
        <small
          style={{
            fontSize: 12,
            marginTop: -5,
            paddingLeft: 16,
            color: "#D32F2F",
          }}
        >
          {formik.errors[name]}
        </small>
      )}
      {errors !== undefined &&
        errors?.length > 0 &&
        errors?.map((error, index) => {
          return (
            <>
              {error[name] && (
                <small
                  style={{
                    fontSize: 12,
                    marginTop: -5,
                    paddingLeft: 16,
                    color: "#D32F2F",
                  }}
                  key={index}
                >
                  {error[name]
                    .replaceAll(`"${name}"`, label)
                    .replaceAll(`${name}`, label)}
                </small>
              )}
            </>
          );
        })}
      {errors !== undefined && typeof errors === "object" && (
        <>
          {errors[name] && (
            <small
              style={{
                fontSize: 12,
                marginTop: 0,
                paddingLeft: 16,
                color: "#D32F2F",
                background: "transparent",
              }}
            >
              {errors[name][0]}
            </small>
          )}
        </>
      )}
    </div>
  );
};

export default PhoneFormInput;
