import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import { Button, Loader } from "../../components";
const SendEmailModal = ({ onClose, invoiceDetail }) => {
  console.log("👊 ~ invoiceDetail:", invoiceDetail);
  const [isLoader, setIsLoader] = useState(false);
  const [message, setMessage] = useState("");
  const handleConfirm = async (id) => {
    setIsLoader(true);
    try {
      const res = await api.post("/api/jobs/send_invoice", {
        message,
        invoice_id: invoiceDetail?._id,
      });
      if (res.status === 200) {
        toast.success("Invoice send successfully");
        onClose();
      } else {
        toast.error(res?.data?.message || "Invoice couldn't be send");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error(err?.response?.data?.message || "Invoice couldn't be send");
      console.log("🚀 ~ sendInvoice ~ err:", err);
    }
  };
  return (
    <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40 bg-black/60">
      <div className="bg-white p-8 rounded-lg shadow-lg relative min-w-[500px] max-w-[90%]">
        <h2 className="text-lg font-semibold mb-4">
          Would you like to send the PDF Invoice to
        </h2>
        <div className="grid grid-cols-1 font-pop font-medium gap-x-2">
          <div className="flex justify-betwee p-1">
            <span className="col-span-3">Vendor:</span>
            <span className="text-left ml-3">{`${
              invoiceDetail?.vendor_id?.first_name || ""
            } ${invoiceDetail?.vendor_id?.last_name || ""}`}</span>
          </div>
          <div className="flex justify-betwee p-1">
            <span className="col-span-3">Email:</span>
            <span className="text-left ml-3">
              {invoiceDetail?.vendor_id?.email}
            </span>
          </div>
          {!invoiceDetail?.vendor_id?.email ? (
            <small className="text-red-600 -mt-2 mb-3 pl-1">
              Email is not exist for this vendor, Please add it from Vendors.
            </small>
          ) : null}
        </div>
        <div className="relative flex flex-row">
          <input
            className="bg-white border border-gray-300 text-sm rounded w-full p-2.5 "
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            name="message"
            placeholder={"Message"}
          />
        </div>
        <div className="flex justify-end !mt-4">
          {isLoader ? (
            <Loader />
          ) : (
            <>
              <Button text="Cancel" variant="btn_cancel" onClick={onClose} />
              <Button
                text="Send Email"
                variant="btn_submit"
                onClick={handleConfirm}
                className="ml-2"
                disabled={!invoiceDetail?.vendor_id?.email}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SendEmailModal;
