import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import api from "../../../services/api";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import Loader from "../../atoms/Loader";
const CustomAutocomplete = ({
  placeholder,
  value,
  onSelect,
  country_id,
  errors,
  name,
  label,
}) => {
  const [record, setRecord] = React.useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [searchedValue, setSearchedValue] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const listRef = React.useRef(null);
  const getSERPReport = async (e) => {
    setIsVisible(true);
    setIsLoading(true);
    try {
      const res = await api.get(`api/location_db/city_zips/${country_id}/${e}`);
      if (res.status === 200) {
        setRecord(res.data);
      }
    } catch (err) {
      console.log("🚀 ~ getSERPReport ~ err:", err);
    } finally {
      setIsLoading(false);
    }
  };
  const debounceFn = debounce(getSERPReport, 1000);
  const onChangeField = (e) => {
    debounceFn(e.target.value);
  };
  return (
    <div className="relative" ref={listRef}>
      <input
        placeholder={value || placeholder}
        onChange={(e) => {
          if (!country_id) {
            return toast.error("Please select country");
          }
          setSearchedValue(e.target.value);
          if (e.target.value?.length > 2) {
            onChangeField(e);
          }
        }}
        className={`placeholder:${
          value ? "!text-[#000]" : "!text-[#a9a9a9]"
        } h-[40px] block bg-white w-full border !border-[#a9a9a9] rounded-md !py-1.5 px-2 shadow-sm focus:outline-none focus:ring-1 sm:text-sm`}
        value={searchedValue || value || ""}
      />
      {errors !== undefined &&
        errors?.length > 0 &&
        errors?.map((error, index) => {
          return (
            <>
              {error[name] && (
                <small
                  style={{
                    fontSize: 12,
                    marginTop: -5,
                    paddingLeft: 16,
                    color: "#D32F2F",
                  }}
                  key={index}
                >
                  {error[name]
                    .replaceAll(`"${name}"`, label)
                    .replaceAll(`${name}`, label)}
                </small>
              )}
            </>
          );
        })}
      {errors !== undefined && typeof errors === "object" && (
        <>
          {errors[name] && (
            <small
              style={{
                fontSize: 12,
                marginTop: 0,
                paddingLeft: 16,
                color: "#D32F2F",
                background: "transparent",
              }}
            >
              {errors[name][0]}
            </small>
          )}
        </>
      )}
      {isVisible ? (
        <div className="absolute z-10 left-0 mt-2 w-full max-h-48 overflow-y-auto bg-white rounded-md shadow-lg">
          {!isLoading && !record.length ? (
            <div className="py-3 w-full text-center">
              <strong>No record found</strong>
            </div>
          ) : !isLoading ? (
            <ul className="px-1 rounded-md border border-gray-200 min-h-48">
              {record.map((item) => (
                <li
                  onMouseDown={(e) => e.preventDefault()}
                  key={item._id}
                  onClick={() => {
                    onSelect(item);
                    setIsVisible(false);
                    setSearchedValue("");
                  }}
                  className="cursor-pointer"
                >
                  {`${item.zip}`}
                </li>
              ))}
            </ul>
          ) : (
            <div className="py-3">
              <Loader />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default CustomAutocomplete;
