import React from "react";

import {
  Backdrop,
  Button,
  DotsLoader,
  FormInput,
  FormSelectInput,
  FormTextArea,
} from "../../components";

import { useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import {
  create_sms_template,
  update_sms_template,
} from "../../features/smsTemplateSlice";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import { MultiSelect } from "primereact/multiselect";

const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.smsTemplate);
  const [errors, setErrors] = React.useState([]);
  const { industryDrd } = useSelector((state) => state.industry);
  let initialValues = {
    title: "",
    slug: "",
    structure: "",
    industries: "",
    is_default: false,
  };

  if (editingRecord) {
    const { _id, title, slug, structure, is_default, industries } =
      editingRecord;
    initialValues = {
      id: _id,
      title,
      slug,
      structure,
      is_default,
      industries: industries?.map(({ _id }) => _id),
    };
  }

  const formik = useFormik({
    initialValues,

    validateOnBlur: true,

    // validationSchema: SMSTemplateSchema,

    onSubmit: async (values) => {
      if (!editingRecord) {
        try {
          const res = await dispatch(
            create_sms_template({ ...values })
          );
          if (res?.payload?.status === 200) {
            toast.success("SMS Template created");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "SMS Template",
              msg: "created",
            });
          }
        } catch (error) {
          errorMessage({
            payload: error,
            action: "SMS Template",
            msg: "created",
            setErrors: setErrors,
          });
        }
      } else {
        try {
          const res = await dispatch(
            update_sms_template({ ...values,})
          );
          if (res?.payload?.status === 200) {
            toast.success("SMS Template updated");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "SMS Template",
              msg: "created",
            });
          }
        } catch (error) {
          errorMessage({
            payload: error,
            action: "SMS Template",
            msg: "updated",
            setErrors: setErrors,
          });
        }
      }
    },
  });
  return (
    <div className="min-h-screen bg-white w-full z-[100] fixed top-0 left-0 overflow-y-auto">
      <div className="overflow-y-auto h-screen">
        <div className="flex items-center justify-between w-full text-client-50 py-2.5 !px-5 shadow-client">
          <h4>
            {!!editingRecord
              ? modalTitle.replaceAll("Add", "Edit")
              : modalTitle}
          </h4>
          <Button
            text="X"
            className="mr-2"
            onClick={onCancelForm}
            variant="btn_danger"
          />
        </div>
        <Backdrop onClick={onCancelForm} />
        {isLoading && <DotsLoader />}
        <form className="!p-5 grid md:grid-cols-[repeat(2,_1fr)] gap-5">
          <div className="!py-5 px-10 bg-white rounded-xl shadow-client">
            <div>
              <div className="grid md:grid-cols-2 gap-x-5">
                <div className="!mb-3">
                  <FormInput
                    errors={errors}
                    name="title"
                    label="SMS Title"
                    formik={formik}
                  />
                </div>
                <div className="!mb-3">
                  <FormInput
                    errors={errors}
                    name="slug"
                    label="Slug"
                    formik={formik}
                  />
                </div>
                <div className="!mb-3">
                  <FormSelectInput
                    errors={errors}
                    name="is_default"
                    label="Default"
                    formik={formik}
                    options={[
                      { value: false, label: "No" },
                      { value: true, label: "Yes" },
                    ]}
                    valueProp="value"
                    labelProp="label"
                  />
                </div>
                <MultiSelect
                placeholder="Select Industries"
                options={industryDrd}
                optionLabel="name"
                optionValue="_id"
                filter
                maxSelectedLabels={2}
                value={formik.values.industries}
                onChange={(e) => formik.setFieldValue("industries", e.value)}
                className="h-[38px] mb-2 w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              />
              </div>
              <div>
                <h4>Placeholders</h4>
                <div className="text-xs h-32 overflow-y-auto bg-white border border-[rgb(215,_215,_215)] p-2.5 rounded">
                  _AGENT_EMAIL_ = Agent SMS
                  <br />
                  _AGENT_FAX_ = Agent Fax
                  <br />
                  _AGENT_NAME_ = Agent Name
                  <br />
                  _AGENT_PHONE_ = Agent Phone
                  <br />
                  _AGENT_EXT_ = Agent Extension
                  <br />
                  __APPLICATION_LINK__ = Application Link
                  <br />
                  _COMPANY_ADDRESS_ = Company Address
                  <br />
                  _COMPANY_NAME_ = Company Name
                  <br />
                  _COMPANY_URL_ = Company Website URL
                  <br />
                  __CUSTOMER_DETAIL_URL__ = Customer Detail URL
                  <br />
                  __CUSTOMER_DETAIL_URL_1__ = Apply Now
                  <br />
                  __CUSTOMER_DETAIL_URL_2__ = Learn More
                  <br />
                  __DATE__ = Current Date
                  <br />
                  __EMAIL__ = Customer SMS Address
                  <br />
                  __FORM_LINK__ = Authorize Form Link
                  <br />
                  __LOGO__ = Logo Image
                  <br />
                  __NAME__ = Customer Name
                  <br />
                  __USER_NAME__ = User Name
                  <br />
                  _OFFICE_NUMBER_ = Office Contact Number
                  <br />
                  __PHONE__ = Customer Phone Number
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div className="!py-5 px-10 bg-white rounded-xl shadow-client mb-12">
            <FormTextArea name="structure" formik={formik} label="Structure" />
          </div>
        </form>
        <div className="w-full absolute flex justify-center bottom-0 !p-5 bg-white border-t">
          <Button
            text="Cancel"
            className="mr-2"
            onClick={onCancelForm}
            variant="btn_cancel"
          />
          <Button
            text={"Submit"}
            className="mr-2"
            onClick={formik.handleSubmit}
            variant="btn_submit"
          />
        </div>
      </div>
    </div>
  );
};

export default AddUpdateForm;
