import React from "react";
import {
  DotsLoader,
  FormInput,
  Modal,
} from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  create_rating,
  get_rating,
  update_rating,
} from "../../../features/nationSitesSlice";
const AddUpdateNationSiteVendorsModal = ({
  editingRecord,
  onCancelForm,
  modalTitle,
}) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.nation);
  const [errors, setErrors] = React.useState([]);

  const initialValues = {
    rating: "",
    ...(editingRecord && {
      id: editingRecord._id,
      rating: editingRecord.rating,
    }),
  };
  const handleSubmit = async (values) => {
    const action = editingRecord ? update_rating : create_rating;
    const message = editingRecord ? "updated" : "created";
    try {
      const res = await dispatch(action(values));
      if (res.payload?.status === 200 || res.payload?.status === 201) {
        toast.success(
          res.payload?.data?.message || `Rating ${message} successfully`
        );
        dispatch(get_rating());
        onCancelForm();
      } else {
        if (Array.isArray(res.payload)) {
          setErrors(res.payload);
        } else {
          toast.error(
            res.payload?.data?.message
              ? res.payload?.data?.message
              : `Rating couldn't be ${message}`
          );
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload)) {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload ? error.payload : `Rating couldn't be ${message}`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  return (
    <Modal
      isUpdate={!!editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      formIsValid={formik.isValid}
      onSubmit={formik.handleSubmit}
      isLoading={isLoading}
      onClick={onCancelForm}
    >
      {isLoading ? <DotsLoader /> : null}
      <form className="mt-4">
        <div className="!mb-3">
          <FormInput
            errors={errors}
            name="rating"
            label="Rating"
            formik={formik}
          />
        </div>
      </form>
    </Modal>
  );
};

export default AddUpdateNationSiteVendorsModal;
