import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { createRegistrar, deleteRegistrar, getRegistrar, updateRegistrar } from "../services/registrarService";
const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
  record: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Industry Data
export const get_registrar = createAsyncThunk(
  "get_registrar",
  async (thunkAPI) => {
    try {
      return await getRegistrar();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Industry
export const create_registrar = createAsyncThunk(
  "create_registrar",
  async (data, thunkAPI) => {
    try {
      return await createRegistrar(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Industry
export const update_registrar = createAsyncThunk(
  "update_registrar",
  async (data, thunkAPI) => {
    try {
      return await updateRegistrar(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Industry
export const delete_registrar = createAsyncThunk(
  "delete_registrar",
  async (id, thunkAPI) => {
    try {
      return await deleteRegistrar(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const registrarSlice = createSlice({
  name: "industries",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_registrar.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_registrar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data
      })
      .addCase(get_registrar.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(create_registrar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_registrar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.push(action.payload.data);
      })
      .addCase(create_registrar.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_registrar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_registrar.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record[result] = {
          ...state.record[result],
          ...action.payload.data,
        };
      })
      .addCase(update_registrar.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_registrar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_registrar.fulfilled, (state, action) => {
        const result = state.record.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record[result].active = false;
        state.isLoading = false;
      })
      .addCase(delete_registrar.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
export const { reset } = registrarSlice.actions;
export default registrarSlice.reducer;
