import api from "./api";

export const getRegistrar = async () => {
    return await api.get("/api/registrar");
};
export const createRegistrar = async (data) => {
  return await api.post("/api/registrar", data);
};

export const updateRegistrar = async (data) => {
  return await api.put(`/api/registrar/${data?.id}`, data );
};

export const deleteRegistrar = async (id) => {
  return await api.delete(`/api/registrar/${id}`);
};
