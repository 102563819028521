import api from "./api";

export const getTasks = async () => {
  return await api.get("/api/administration/task");
};
export const createTask = async (data) => {
  return await api.post("/api/administration/task", data);
};
export const updateTask = async (data) => {
  return await api.put(`/api/administration/task/${data?._id}`, data);
};
export const updateUserTask = async (data) => {
  return await api.put(`/api/administration/user_tasks/${data?.id}`, data);
};
export const updateRoleTask = async (data) => {
  return await api.put(`/api/administration/role_tasks/${data?.id}`, data);
};
export const getTaskByUser = async (id) => {
  return await api.get(`/api/administration/user_tasks/${id}`);
};
export const getTaskByRole = async (id) => {
  return await api.get(`/api/administration/role_tasks/${id}`);
};