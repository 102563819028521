import React, { useEffect, useState } from "react";
import AddUpdateNationSitesModal from "./AddUpdateRating";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../../components/molecules/PageHeader";
import userPermission from "../../../util/userPermission";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import {
  get_rating,
  nationSiteReset,
  delete_rating,
} from "../../../features/nationSitesSlice";
import { toast } from "react-toastify";
import errorMessage from "../../../util/errorMessage";
const NationSiteRating = () => {
  const dispatch = useDispatch();
  const { isLoading, nationSiteRating } = useSelector((state) => state?.nation);
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchText, setSearchText] = useState("");
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  useEffect(() => {
    dispatch(get_rating());
    return () => {
      dispatch(nationSiteReset());
    };
    // eslint-disable-next-line
  }, []);
  const sites = nationSiteRating?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_rating(record_id));
        if (res?.payload?.status === 200) {
          dispatch(get_rating());
          toast.success("Rating deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "Rating",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Rating", msg: "deleted" });
      }
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false },
    { headerName: "Rating", field: "rating", flex: 1 },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex flex-row">
          {userPermission("Update Rating") ? (
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update Rating"
            />
          ) : null}
          <FaTrashAlt
            onClick={deleteRecordHandler(params.row.records._id)}
            className="my_navIcon"
            title="Delete Rating"
          />
        </div>
      ),
      width: 100,
      filterable: false,
    },
  ];
  return (
    <>
      {isEditing && (
        <AddUpdateNationSitesModal
          editingRecord={editingRecord}
          modalTitle="Add Raing"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        heading="Rating Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Rating Site")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={sites?.map((record, index) => {
            let counter = index + 1;
            const { rating } = record;
            return {
              counter,
              records: { ...record },

              rating,
            };
          })}
          totalItems={nationSiteRating?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default NationSiteRating;
