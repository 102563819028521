import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAOODataReport,
  getClosingReport,
  getCommissionReport,
  getDIDDrd,
  getInboundReport,
  getLastTouch,
  getLoginLogs,
  getAOODataFilters,
  getAPIsReport,
  getInboundForwardingReport,
  getWebQueryReport,
  deleteWebQuery,
  getAffiliateReport
} from "../services/reportsService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  lastTouch: [],
  commissionReport: [],
  inboundReport: [],
  dids: [],
  closingnReport: [],
  aooDataReport: [],
  aooDataFilters: [],
  apiReport: [],
  inboundForwardingReport: [],
  webQueryReport: [],
  affiliateReport:[]
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Login Logs
export const get_login_logs = createAsyncThunk(
  "get_login_logs",
  async (data, thunkAPI) => {
    try {
      return await getLoginLogs(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_last_touch = createAsyncThunk(
  "get_last_touch",
  async (data, thunkAPI) => {
    try {
      return await getLastTouch(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_api_report = createAsyncThunk(
  "get_api_report",
  async (data, thunkAPI) => {
    try {
      return await getAPIsReport(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_commission_report = createAsyncThunk(
  "get_commission_report",
  async (data, thunkAPI) => {
    try {
      return await getCommissionReport(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_closing_report = createAsyncThunk(
  "get_closing_report",
  async (data, thunkAPI) => {
    try {
      return await getClosingReport(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_inbound_report = createAsyncThunk(
  "get_inbound_report",
  async (data, thunkAPI) => {
    try {
      return await getInboundReport(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_inbound_forwarding_report = createAsyncThunk(
  "get_inbound_forwarding_report",
  async (data, thunkAPI) => {
    try {
      return await getInboundForwardingReport(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_affiliate_report = createAsyncThunk(
  "get_affiliate_report",
  async (data, thunkAPI) => {
    try {
      return await getAffiliateReport(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_did_drd = createAsyncThunk(
  "get_did_drd",
  async (data, thunkAPI) => {
    try {
      return await getDIDDrd();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_aoo_data_report = createAsyncThunk(
  "get_aoo_data_report",
  async (data, thunkAPI) => {
    try {
      return await getAOODataReport(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_aoo_filters = createAsyncThunk(
  "get_aoo_filters",
  async (data, thunkAPI) => {
    try {
      return await getAOODataFilters(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_web_query = createAsyncThunk(
  "get_web_query",
  async (data, thunkAPI) => {
    try {
      return await getWebQueryReport(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const delete_web_query = createAsyncThunk(
  "delete_web_query",
  async (id, thunkAPI) => {
    try {
      return await deleteWebQuery(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const reportSlice = createSlice({
  name: "getReports",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
    resetAOOReport: (state) => {
      state.aooDataReport = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_login_logs.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_login_logs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_login_logs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
      })
      .addCase(get_last_touch.pending, (state) => {
        state.isLoading = true;
        // state.lastTouch = [];
      })
      .addCase(get_last_touch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.lastTouch = action.payload?.data;
      })
      .addCase(get_last_touch.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.lastTouch = [];
      })
      .addCase(get_commission_report.pending, (state) => {
        state.isLoading = true;
        // state.lastTouch = [];
      })
      .addCase(get_commission_report.fulfilled, (state, action) => {
        state.isLoading = false;
        state.commissionReport = action.payload?.data;
      })
      .addCase(get_commission_report.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.commissionReport = [];
      })
      .addCase(get_inbound_report.pending, (state) => {
        state.isLoading = true;
        // state.lastTouch = [];
      })
      .addCase(get_inbound_report.fulfilled, (state, action) => {
        state.isLoading = false;
        state.inboundReport = action.payload?.data;
      })
      .addCase(get_inbound_report.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.inboundReport = [];
      })
      .addCase(get_did_drd.pending, (state) => {
        state.isLoading = true;
        // state.lastTouch = [];
      })
      .addCase(get_did_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dids = action.payload?.data;
      })
      .addCase(get_did_drd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.dids = [];
      })
      .addCase(get_closing_report.pending, (state) => {
        state.isLoading = true;
        // state.lastTouch = [];
      })
      .addCase(get_closing_report.fulfilled, (state, action) => {
        state.isLoading = false;
        state.closingnReport = action.payload?.data;
      })
      .addCase(get_closing_report.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.closingnReport = [];
      })
      .addCase(get_aoo_data_report.pending, (state) => {
        state.isLoading = true;
        // state.lastTouch = [];
      })
      .addCase(get_aoo_data_report.fulfilled, (state, action) => {
        state.isLoading = false;
        state.aooDataReport = action.payload?.data;
      })
      .addCase(get_aoo_data_report.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.aooDataReport = [];
      })
      .addCase(get_aoo_filters.pending, (state) => {
        // state.isLoading = true;
        // state.lastTouch = [];
      })
      .addCase(get_aoo_filters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.aooDataFilters = action.payload?.data;
      })
      .addCase(get_aoo_filters.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.aooFilters = [];
      })
      .addCase(get_api_report.pending, (state) => {
        state.isLoading = true;
        state.apiReport = [];
      })
      .addCase(get_api_report.fulfilled, (state, action) => {
        state.isLoading = false;
        state.apiReport = action.payload?.data;
      })
      .addCase(get_api_report.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.apiReport = [];
      })
      .addCase(get_inbound_forwarding_report.pending, (state) => {
        state.isLoading = true;
        state.inboundForwardingReport = [];
      })
      .addCase(get_inbound_forwarding_report.fulfilled, (state, action) => {
        state.isLoading = false;
        state.inboundForwardingReport = action.payload?.data;
      })
      .addCase(get_inbound_forwarding_report.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.inboundForwardingReport = [];
      })
      .addCase(get_web_query.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_web_query.fulfilled, (state, action) => {
        state.isLoading = false;
        state.webQueryReport = action.payload?.data;
      })
      .addCase(get_web_query.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.webQueryReport = [];
      })

      .addCase(delete_web_query.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_web_query.fulfilled, (state, action) => {
        state.isLoading = false;
        state.webQueryReport = action.payload?.data;
      })
      .addCase(delete_web_query.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(get_affiliate_report.pending, (state) => {
        state.isLoading = true;
        state.affiliateReport = [];
      })
      .addCase(get_affiliate_report.fulfilled, (state, action) => {
        console.log("👊 ~ .addCase ~ action:", action)
        state.isLoading = false;
        state.affiliateReport = action.payload?.data;
      })
      .addCase(get_affiliate_report.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.affiliateReport = [];
      });
  }
});

export const { reset, resetAOOReport } = reportSlice.actions;
export default reportSlice.reducer;
