import React from "react";
import { Bar } from "react-chartjs-2";
import { FaCalendarCheck, FaPhone } from "react-icons/fa";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import styles from "./style.module.css";
function GraphModal({
  data,
  graphType,
  graphOptions,
  title,
  onClose,
  industry,
  isVendors,
}) {
  const sumCalls =
    !isVendors &&
    data?.datasets
      ?.find((item) => item.label === "Calls")
      ?.data.reduce((acc, curr) => acc + curr, 0);
  const sumBookings =
    !isVendors &&
    data?.datasets
      ?.find((item) => item.label === "Bookings")
      ?.data.reduce((acc, curr) => acc + curr, 0);

  const sumSendJobs =
    isVendors &&
    data?.datasets
      ?.find((item) => item.label === "Send Jobs")
      ?.data.reduce((acc, curr) => acc + curr, 0);
  const sumClosed =
    isVendors &&
    data?.datasets
      ?.find((item) => item.label === "Closed")
      ?.data.reduce((acc, curr) => acc + curr, 0);
  const sumCanceled =
    isVendors &&
    data?.datasets
      ?.find((item) => item.label === "Canceled")
      ?.data.reduce((acc, curr) => acc + curr, 0);
  const sumEstimated =
    isVendors &&
    data?.datasets
      ?.find((item) => item.label === "Estimated")
      ?.data.reduce((acc, curr) => acc + curr, 0);

  return (
    <>
      <div className="fixed w-[90%] md:w-[1024px] z-[101] left-[50%] translate-x-[-50%]">
        <div
          className="absolute -right-3.7 -top-3.7 w-[34px] h-[34px] bg-white rounded-full flex justify-center items-center cursor-pointer drop-shadow"
          onClick={onClose}
        >
          X
        </div>
        <div className="max-w-[1024px] h-auto p-2 bg-[#E6EAEA] rounded flex justify-center items-center text-xl font-bold">
          <div className="bg-white my-3 border rounded w-full">
            <div className="flex flex-col p-2">
              <h2>Selected Industry: {industry}</h2>
              {isVendors ? (
                <div className="grid grid-cols-2 md:grid-cols-4 mt-3">
                  <div className="flex flex-row w-full items-center p-1">
                    <div className="rounded-full border flex justify-center items-center h-[40px] w-[40px] bg-[#042A42]/20">
                      <FaPhone color="#042A42" />
                    </div>
                    <div className="flex flex-col ml-2 w-auto">
                      <small className="font-pop text-lg font-bold">
                        {sumSendJobs}
                      </small>
                      <small className="font-pop">Send Jobs</small>
                    </div>
                  </div>
                  <div className="flex flex-row w-full items-center p-1">
                    <div className="rounded-full border border-[#16a34a] flex justify-center items-center h-[40px] w-[40px] bg-[#16a34a]/20">
                      <IoMdCheckmarkCircle color="#16a34a" size={28} />
                    </div>
                    <div className="flex flex-col ml-2 w-auto">
                      <small className="font-pop text-lg font-bold">
                        {sumClosed}{" "}
                        {sumSendJobs && sumClosed
                          ? `(${((+sumClosed / +sumSendJobs) * 100)?.toFixed(
                              2
                            )})%`
                          : "(0%)"}
                      </small>
                      <small className="font-pop">Closed</small>
                    </div>
                  </div>
                  <div className="flex flex-row w-full items-center p-1">
                    <div className="rounded-full border border-[#ffb700] flex justify-center items-center h-[40px] w-[40px] bg-[#ffb700]/20">
                      <FaCalendarCheck color="#ffb700" size={22} />
                    </div>
                    <div className="flex flex-col ml-2 w-auto">
                      <small className="font-pop text-lg font-bold">
                        {sumEstimated}{" "}
                        {sumSendJobs && sumEstimated
                          ? `(${((+sumEstimated / +sumSendJobs) * 100)?.toFixed(
                              2
                            )})%`
                          : "(0%)"}
                      </small>
                      <small className="font-pop">Estimated</small>
                    </div>
                  </div>
                  <div className="flex flex-row w-full items-center p-1">
                    <div className="rounded-full border border-[#dc2626] flex justify-center items-center h-[40px] w-[40px] bg-[#dc2626]/20">
                      <TiCancel color="#dc2626" size={30} />
                    </div>
                    <div className="flex flex-col ml-2 w-auto">
                      <small className="font-pop text-lg font-bold">
                        {sumCanceled}{" "}
                        {sumSendJobs && sumCanceled
                          ? `(${((+sumCanceled / +sumSendJobs) * 100)?.toFixed(
                              2
                            )})%`
                          : "(0%)"}
                      </small>
                      <small className="font-pop">Cancel</small>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-2 md:grid-cols-3 mt-1.5">
                  <div className="flex flex-row w-full items-center p-1">
                    <div className="rounded-full border flex justify-center items-center h-[40px] w-[40px] bg-[#042A42]/20">
                      <FaPhone color="#042A42" />
                    </div>
                    <div className="flex flex-col ml-2 w-auto">
                      <small className="font-pop text-lg font-bold">
                        {sumCalls}
                      </small>
                      <small className="font-pop">Total Calls</small>
                    </div>
                  </div>
                  <div className="flex flex-row w-full items-center p-1">
                    <div className="rounded-full border border-[#FFB700] flex justify-center items-center h-[40px] w-[40px] bg-[#FFB700]/20">
                      <FaPhone color="#FFB700" />
                    </div>
                    <div className="flex flex-col ml-2 w-auto">
                      <small className="font-pop text-lg font-bold">
                        {sumCalls && sumBookings
                          ? `${sumBookings} (${(
                              (sumBookings / sumCalls) *
                              100
                            )?.toFixed(2)}%)`
                          : "0 (0%)"}
                      </small>
                      <small className="font-pop">Booking</small>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col border shadow-md ">
              <div className="flex justify-between items-center px-2">
                <span className="text-lg font-semibold">{title}</span>
              </div>

              <Bar data={data} options={graphOptions} />
            </div>
          </div>
        </div>
      </div>

      <div className={[styles.backdrop].join(" ")} onClick={onClose}></div>
    </>
  );
}

export default GraphModal;
