const FormInput = ({
  name,
  label,
  formik,
  type,
  disabled = false,
  onClick,
  placeholder,
  value,
  readOnly,
  required,
  errors,
  className,
  onChange,
  onKeyUp,
  onFocus,
  id,
  CustomButton = null,
  prefix = null,
}) => {
  return (
    <div className="flex flex-col">
      {label ? (
        CustomButton ? (
          <div className="flex justify-between">
            <label
              className="font-semibold text-xs text-gray-500 mb-1"
              htmlFor={name}
            >
              {label}
            </label>
            <CustomButton prefix={prefix} />
          </div>
        ) : (
          <label
            className="font-semibold text-xs text-gray-500 mb-1"
            htmlFor={name}
          >
            {label}
          </label>
        )
      ) : null}

      <input
        id={id || name}
        name={name}
        type={type}
        placeholder={placeholder || ""}
        value={
          formik
            ? formik?.values[name] || formik?.values[name] === 0
              ? formik?.values[name]
              : value
              ? value
              : ""
            : ""
        }
        onChange={onChange ? onChange : formik?.handleChange}
        onBlur={formik?.handleBlur}
        disabled={disabled}
        onClick={onClick}
        required={required}
        error={formik?.errors[name] && formik?.touched[name] ? true : false}
        className={
          className
            ? className
            : "border rounded-md border-gray-300 px-2 py-1.5"
        }
        InputProps={{
          readOnly: readOnly,
        }}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
      />
      {formik?.errors[name] && formik?.touched[name] && (
        <small
          style={{
            fontSize: 12,
            marginTop: -5,
            paddingLeft: 16,
            color: "#D32F2F",
          }}
        >
          {formik.errors[name]}
        </small>
      )}
      {errors !== undefined &&
        errors?.length > 0 &&
        errors?.map((error, index) => {
          return (
            <>
              {error[name] && (
                <small
                  style={{
                    fontSize: 12,
                    marginTop: -5,
                    paddingLeft: 16,
                    color: "#D32F2F",
                  }}
                  key={index}
                >
                  {error[name]
                    .replaceAll(`"${name}"`, label)
                    .replaceAll(`${name}`, label)}
                </small>
              )}
            </>
          );
        })}
      {errors !== undefined && typeof errors === "object" && (
        <>
          {errors[name] && (
            <small
              style={{
                fontSize: 12,
                marginTop: 0,
                paddingLeft: 16,
                color: "#D32F2F",
                background: "transparent",
              }}
            >
              {errors[name][0]}
            </small>
          )}
        </>
      )}
    </div>
  );
};

export default FormInput;
