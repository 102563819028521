import api from "./api";

export const getGeoIndustry = async () => {
    return await api.get("/api/vendors/domain_prime_time/industry_list");
};

export const createGeoIndustry = async (name) => {
  return await api.post("https://api.cmsdeployer.com/api/domain-prime-time/add_industry", { name });
};

export const updateGeoIndustry = async (id, name) => {
  return await api.put(`https://api.cmsdeployer.com/api/domain-prime-time/update_industry/${id}`, { name });
};

export const deleteGeoIndustry = async (id) => {
  return await api.delete(`https://api.cmsdeployer.com/api/domain-prime-time/delete_industry/${id}`,);
};

export const getGeoIndustryDrd = async () => {
  return await api.get("/api/industries/drd");
};
export const getIndustryServiceArea = async (data) => {
  return await api.post("https://api.cmsdeployer.com/api/geo_locations/industry_service_area_zip_file",  data );
};