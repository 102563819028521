import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get_tasks } from "../../features/tasksSlice";
import PageHeader from "../../components/molecules/PageHeader";
import TaskForm from "./AddUpdateForm";
import { FaEdit } from "react-icons/fa";
import userPermission from "../../util/userPermission";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";

const Tasks = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.tasks);
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchText, setSearchText] = useState("");

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  // pending for future implementation
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  useEffect(() => {
    dispatch(get_tasks());
    // eslint-disable-next-line
  }, []);
  const tasks = record?.filter((task) =>
    task?.name?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Name", field: "name", flex: 1 },
    { headerName: "Description", field: "description", flex: 1 },
    {
      field: "actions",
      renderCell: (params) => (
        <div>
          {userPermission("Administration") && (
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update Task"
            />
          )}
        </div>
      ),
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  return (
    <>
      {isEditing && (
        <TaskForm
          editingRecord={editingRecord}
          modalTitle="Add Task"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > User Tasks"
        heading="User Task Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Administration")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={tasks?.map((record, index) => {
            let counter = index + 1;
            let name = record.name;
            let description = record.description;
            return {
              counter,
              name,
              description,
              records: record,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          paginationMode={"client"}
        />
      </div>
    </>
  );
};

export default Tasks;
