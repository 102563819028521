import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import { Button, FormInput, Loader } from "../../components";
const QuotingModal = ({ onClose, jobDetail, reloadReport }) => {
  const [isLoader, setIsLoader] = useState(false);
  const [amount, setAmount] = useState(
    jobDetail?.virtual_cards_info?.requested_amount || 0
  );
  const handleConfirm = async () => {
    if (+amount > +jobDetail?.virtual_cards_info?.requested_amount)
      return toast.error(
        "Sanctioned amount should be equal or less than from requested amount"
      );
    setIsLoader(true);
    try {
      const res = await api.post(
        `/api/jobs/virtual_card_request_approved/${jobDetail?._id}`,
        {
          card_id: jobDetail?.virtual_cards_info?._id,
          sanctioned_amount: amount,
        }
      );
      if (res.status === 200) {
        toast.success("Card request accepted successfully");
        reloadReport();
        onClose();
      } else {
        toast.error(
          res?.data?.message ||
            res?.data?.error ||
            "Card request couldn't be accepted"
        );
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error(
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          "Card request couldn't be accepted"
      );
      console.log("🚀 ~ handleConfirm ~ err:", err);
    }
  };
  return (
    <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40 bg-black/60">
      <div className="bg-white p-8 rounded-lg shadow-lg relative min-w-[500px] max-w-[80%]">
        <h2 className="text-lg font-semibold mb-4">Quoting Request</h2>
        <div className="relative flex flex-col">
          <FormInput
            value={jobDetail?.virtual_cards_info?.requested_amount}
            name="requested_amount"
            placeholder={"Requested Amount"}
            label="Requested Amount"
            className={"my-3"}
            type={"number"}
            readOnly={true}
          />
          <FormInput
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            name="sanctioned_amount"
            placeholder={"Sanctioned Amount"}
            label="Sanctioned Amount"
            className={"my-3"}
            type={"number"}
          />
          <FormInput
            onChange={(e) => setAmount(e.target.value)}
            value={+amount + 1}
            name="final_amount"
            placeholder={"Final Amount"}
            label="Final Amount"
            className={"my-3"}
            type={"number"}
            disabled={true}
          />
        </div>
        <div className="flex justify- !mt-4">
          {isLoader ? (
            <Loader />
          ) : (
            <div className="flex justify-between w-full">
              <Button text="Close" variant="btn_cancel" onClick={onClose} />
              <div className="flex gap-x-2">
                {/* <Button
                  text="Reject"
                  variant="btn_danger"
                  onClick={() => handleConfirm("Rejected")}
                /> */}
                <Button
                  text="Accept"
                  variant="btn_submit"
                  onClick={handleConfirm}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuotingModal;
