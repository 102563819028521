import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";

const EmailModal = ({ onClose, emailData, emailFilter, setFilter }) => {
  const [selectedEmail, setSelectedEmail] = useState(null);
  console.log("👊 ~ EmailModal ~ selectedEmail:", selectedEmail);
  const handleMailSelect = (e) => {
    setSelectedEmail(e);
    const filter = { groupOp: "", rules: [] };
    filter.rules.push({ field: "email", op: "eq", data: e });
    emailFilter(filter);
    onClose()
  };
  return (
    <>
      <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40">
        <div className="bg-white p-8 rounded-lg shadow-lg relative w-[400px]">
          <h2 className="text-lg font-semibold mb-4">
            Emails have multiple records
          </h2>
          {/* <p className="mb-4">Are you sure you want to restart the application?</p> */}

          <div className=" !px-0.5 !mb-3">
            <label className="block">Select Email:</label>
            <Dropdown
              value={selectedEmail}
              onChange={(e) => handleMailSelect(e.value)}
              placeholder="Select an Email"
              options={emailData}
              optionValue="email"
              optionLabel={(option) => `${option?.email} (${option?.count})`}
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              filter
            />
          </div>
          <div className="flex justify-end !mt-4">
            <button
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailModal;
