import api from "./api";

export const getDomainsIndexReport = async (data) => {
  return await api.get(
    "https://domainchecker.ecommcube.com/api/domain_index/list_domains",
    { params: data }
  );
};
export const startCheckingIndexReport = async (domains) => {
  return await api.post(
    `https://domainchecker.ecommcube.com/api/domain_index/check_index`,
    domains
  );
};
