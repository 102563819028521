import React, { useRef, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, DotsLoader } from "../../../../components";
import { add_images } from "../../../../features/jobsSlice";
import errorMessage from "../../../../util/errorMessage";
import styles from "./style.module.css";
import api from "../../../../services/api";
import userPermission from "../../../../util/userPermission";
import { userPermissionNew } from "../../../../util/userPermissionNew";
function useVisible(onCancelModal) {
  const modalRef = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      onCancelModal();
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onCancelModal();
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { modalRef };
}

const ImgModal = (props) => {
  const {
    title,
    onCancelModal,
    isLoading,
    onClick,
    hideButtons,
    hideHeader,
    imgViewModal,
    imgSaveModal,
    singleImg,
    formik,
  } = props;
  const { modalRef } = useVisible(onClick);
  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useDispatch();
  const onAddImages = async () => {
    try {
      const res = await dispatch(
        add_images({ img: formik.values.attachments, id: formik.values._id })
      );
      if (res?.payload?.status === 200) {
        toast.success("Images Added Successfully");
        formik.setFieldValue("attachments", res.payload.data.attachments);
        onCancelModal();
      } else {
        errorMessage({ payload: res.payload, action: "Images", msg: "added" });
      }
    } catch (err) {
      errorMessage({ payload: err, action: "Images", msg: "added" });
    }
  };
  const deleteImage = async () => {
    const c = window.confirm("Are you sure want to delete this image?");
    if (!c) return;
    setIsLoader(true);
    const file_name = singleImg.split("/").pop();
    try {
      const res = await api.post("api/jobs/delete_imgs", {
        id: formik.values._id,
        file_name,
      });
      if (res?.status === 200) {
        toast.success("Images deleted Successfully");
        formik.setFieldValue("attachments", res.data.attachments);
        onCancelModal();
      } else {
        toast.error(res.data?.message || res?.data || "Image deletion failed");
      }
    } catch (err) {
      console.log("👊 ~ deleteImage ~ err:", err);
      toast.error(
        err.response.data?.message ||
          err?.response?.data ||
          "Image deletion failed"
      );
    }
    setIsLoader(false);
  };
  return (
    <>
      {isLoader || isLoading ? <DotsLoader /> : null}
      <div className="fixed w-[90%] md:w-[1024px] z-[101] left-[50%] translate-x-[-50%]">
        <div className="absolute top-0 right-0 p-2 z-20 -mt-6 -mr-6">
          <button
            onClick={onCancelModal}
            className="bg-white rounded-full text-gray-700 hover:text-red-500 p-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div
          className="w-auto md:max-w-[1024px] md:min-w-[730px] max-h-[calc(100vh-130px)] overflow-y-auto top-8 left-1/2 z-[200] bg-[rgba(255,_255,_255,_0.25)] shadow-[0_4px_31px_rgba(0,_0,_0,_0.25)] p-3.5 rounded-2xl"
          ref={modalRef}
        >
          <div className="bg-white pt-10 rounded-2xl max-w-[994px] h-full">
            {!hideHeader && (
              <header className="bg-primary-100 h-[60px] w-11/12 mx-auto flex justify-center items-center rounded-[200px] shadow-[0_4px_31px_0px_#00000040]">
                <h1 className="text-2xl text-white text-center">{title}</h1>
              </header>
            )}

            <div className="p-4 pb-0">
              {imgViewModal && (
                <Carousel
                  nextLabel={null}
                  prevLabel={null}
                  indicators={false}
                  indicatorLabels={[formik?.values?.attachments?.length]}
                >
                  {formik?.values?.attachments?.map((img, index) => {
                    return (
                      <Carousel.Item key={index}>
                        <img
                          className="block w-full"
                          src={
                            img.includes("merchants")
                              ? `${process.env.REACT_APP_BACKEND_URL}/${img}`
                              : img
                          }
                          alt="img hostory"
                          style={{
                            width: "100%",
                            height: "100%",
                            maxHeight: 400,
                            maxWidth: 640,
                            objectFit: "contain",
                            alignSelf: "center",
                            margin: "0 auto",
                          }}
                        />
                        <Carousel.Caption></Carousel.Caption>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              )}

              {imgSaveModal &&
                formik?.values?.attachments?.map((img, index) => {
                  return (
                    <div
                      className="flex text-center"
                      style={{ flexDirection: "column" }}
                      key={index}
                    >
                      <img
                        src={
                          img.includes("merchants")
                            ? `${process.env.REACT_APP_BACKEND_URL}/${img}`
                            : img
                        }
                        alt="img"
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: 400,
                          maxWidth: 640,
                          objectFit: "contain",
                          alignSelf: "center",
                          marginBottom: 20,
                        }}
                      />
                    </div>
                  );
                })}

              {singleImg && (
                <div
                  className="flex text-center"
                  style={{ flexDirection: "column" }}
                >
                  <img
                    src={singleImg}
                    alt="img"
                    style={{
                      width: "100%",
                      height: "100%",
                      maxHeight: 400,
                      maxWidth: 640,
                      objectFit: "contain",
                      alignSelf: "center",
                      marginBottom: 20,
                    }}
                  />
                </div>
              )}
            </div>
            {!hideButtons && (
              <div className="p-4 text-center">
                <Button
                  text="Cancel"
                  className="mr-2"
                  onClick={onCancelModal}
                  variant="btn_cancel"
                />
                {(singleImg && userPermissionNew("Delete Job Images")) ||
                imgSaveModal ||
                (imgViewModal &&
                userPermissionNew("Delete All Job Images")) ? (
                  <Button
                    text={
                      imgSaveModal
                        ? "Submit"
                        : imgViewModal
                        ? "Delete All"
                        : singleImg
                        ? "Delete"
                        : ""
                    }
                    className="mr-2"
                    onClick={
                      imgSaveModal
                        ? onAddImages
                        : imgViewModal
                        ? null
                        : singleImg
                        ? deleteImage
                        : null
                    }
                    variant="btn_submit"
                    isLoading={isLoading || isLoader}
                  />
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className={[styles.backdrop].join(" ")}
        onClick={onCancelModal}
      ></div>
    </>
  );
};
export default ImgModal;
