import React from "react";
import { DotsLoader, FormInput, Modal } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { create_gsc_server, get_gsc_server, update_gsc_server } from "../../features/serverSlice";
const GoogleConsoleForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.servers);
  const [errors, setErrors] = React.useState([]);

  const initialValues = {
    server_ip: "",
    ...(editingRecord && {
      id: editingRecord?.id,
      server_ip: editingRecord?.server_ip,
    }),
  };
  const handleSubmit = async (values) => {
    const action = editingRecord
      ? update_gsc_server
      : create_gsc_server;
    const message = editingRecord ? "updated" : "added";
    try {
      const res = await dispatch(action(values));
      if (res.payload?.status === 200 || res.payload?.status === 201) {
        toast.success(`Server ${message} successfully`);
        dispatch(get_gsc_server());
        onCancelForm();
      } else {
        if (Array.isArray(res.payload)) {
          setErrors(res.payload);
        } else {
          toast.error(
            res.payload
              ? res.payload
              : `Server couldn't be ${message} successfully`
          );
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload)) {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload
            ? error.payload
            : `Server couldn't be ${message} successfully`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}

        <form className="mt-4">
          <div className="!mb-3">
            <FormInput
              errors={errors}
              name="server_ip"
              label="Server IP"
              formik={formik}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default GoogleConsoleForm;
