import React, { useState, useEffect, useReducer, useMemo } from "react";
import GlobalContext from "./GlobalContext";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

function savedEventsReducer(state, { type, payload }) {
  switch (type) {
    case "push":
      return [...state, payload];
    case "update":
      return state.map((evt) => (evt.id === payload.id ? payload : evt));
    case "delete":
      return state.filter((evt) => evt.id !== payload.id);
    default:
      throw new Error();
  }
}
const customLabels = [
  {
    label: "Jobs",
    color: "indigo",
    checked: true,
  },
  // {
  //   label: "Leads",
  //   color: "purple",
  //   checked: true,
  // },
];
function initEvents() {
  const storageEvents = localStorage.getItem("savedEvents");
  const parsedEvents = storageEvents ? JSON.parse(storageEvents) : [];
  return parsedEvents;
}

export default function ContextWrapper(props) {
  const { record } = useSelector((state) => state.jobs);

  const [monthIndex, setMonthIndex] = useState(dayjs().month());
  const [smallCalendarMonth, setSmallCalendarMonth] = useState(null);
  const [daySelected, setDaySelected] = useState(dayjs());
  const [showEventModal, setShowEventModal] = useState(false);
  const [showJobModal, setShowJobModal] = useState(false);
  const [viewType, setViewType] = useState("day");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedMoreJobs, setSelectedMoreJobs] = useState([]);
  const [filters, setFilters] = useState({
    industry_id: "",
    job_status_id: "",
  });
  const [labels, setLabels] = useState(customLabels);
  const [savedEvents, dispatchCalEvent] = useReducer(
    savedEventsReducer,
    [],
    initEvents
  );

  // const filteredEvents = useMemo(() => {
  //   return record?.records?.filter((evt) =>
  //     labels
  //       .filter((lbl) => lbl.checked)
  //       .map((lbl) => lbl.label)
  //       .includes(evt.job_created)
  //   );
  // }, [record?.records, labels]);

  const filteredEvents = useMemo(() => {
    const checkedJobs =
      labels.find((lbl) => lbl.label === "Jobs")?.checked ?? false;
    const checkedLeads =
      labels.find((lbl) => lbl.label === "Leads")?.checked ?? false;

    return record?.records?.filter((evt) => {
      const jobCreated = evt.job_created;
      const jobCreatedTrue = jobCreated === true || jobCreated === "true";

      if (checkedJobs && !checkedLeads) {
        return jobCreatedTrue;
      } else if (!checkedJobs && checkedLeads) {
        return !jobCreatedTrue;
      } else if (!checkedJobs && !checkedLeads) {
        return false;
      } else {
        return true;
      }
    });
  }, [record?.records, labels]);

  useEffect(() => {
    localStorage.setItem("savedEvents", JSON.stringify(savedEvents));
  }, [savedEvents]);

  // useEffect(() => {
  //   setLabels((prevLabels) => {
  //     return [...new Set(savedEvents.map((evt) => evt.label))].map(
  //       (label) => {
  //         const currentLabel = prevLabels.find(
  //           (lbl) => lbl.label === label
  //         );
  //         return {
  //           label,
  //           checked: currentLabel ? currentLabel.checked : true,
  //         };
  //       }
  //     );
  //   });
  // }, [savedEvents]);

  useEffect(() => {
    if (smallCalendarMonth !== null) {
      setMonthIndex(smallCalendarMonth);
    }
  }, [smallCalendarMonth]);

  useEffect(() => {
    if (!showEventModal) {
      setSelectedEvent(null);
    }
  }, [showEventModal]);

  function updateLabel(label) {
    setLabels(labels.map((lbl) => (lbl.label === label.label ? label : lbl)));
  }

  return (
    <GlobalContext.Provider
      value={{
        monthIndex,
        setMonthIndex,
        smallCalendarMonth,
        setSmallCalendarMonth,
        daySelected,
        setDaySelected,
        showEventModal,
        setShowEventModal,
        dispatchCalEvent,
        selectedEvent,
        setSelectedEvent,
        savedEvents,
        setLabels,
        labels,
        updateLabel,
        filteredEvents,
        showJobModal,
        setShowJobModal,
        selectedMoreJobs,
        setSelectedMoreJobs,
        filters,
        setFilters,
        setViewType,
        viewType
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
}
