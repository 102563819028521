import React from "react";
import { car_icn, gear_icn, calendar_icn, modal_icn } from "../../../../images";
import CustomSelect from "../../Components/Selectors";
function CarInfo({ formik, setIsDisable }) {
  let currentYear = new Date().getFullYear();
  const [yearsOption, setYearsOption] = React.useState();
  React.useEffect(() => {
    let years = [];
    for (var i = 1970; i <= currentYear; i++) {
      years = [...years, { label: i, value: i }];
      setYearsOption(years);
    }
    if (formik?.values?.job_category_id) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <form>
      <CustomSelect
        icon={calendar_icn}
        label="Please select the year of your car."
        placeholder="Year"
        isSelect={true}
        options={yearsOption}
        formik={formik}
        name="car_year"
      />
      <CustomSelect
        icon={car_icn}
        label="What is the make of your car?"
        placeholder="Make"
        isInput={true}
        formik={formik}
        name="car_make"
        value={formik?.values?.car_make}
      />
      <CustomSelect
        name="car_model"
        icon={modal_icn}
        label="What is the model of your car?"
        placeholder="Model"
        isInput={true}
        formik={formik}
        value={formik?.values?.car_model}
      />
      <CustomSelect
        name="is_neutral"
        head="Neutral"
        label="Does your car go into neutral?"
        labelProp="label"
        valueProp="value"
        options={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ]}
        // eslint-disable-next-line
        convertor={(value) => value == "true"}
        formik={formik}
        type="text"
        icon={gear_icn}
        isHorizontal
        isRadio={true}
        isBorder
      />
      <CustomSelect
        name="special_instruction"
        icon={modal_icn}
        label="Please tell us what is wrong with your car?"
        placeholder="Special Instructions"
        formik={formik}
        isInput
        value={formik?.values?.special_instruction}
      />
    </form>
  );
}

export default CarInfo;
