import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../../components";
import {
  create_server,
  get_runner_apps,
  update_server,
} from "../../../features/serverSlice";
import { getRunnerDRD } from "../../../services/domainRunnerService";
function AddUpdateUsers({ editingRecord, onCancelForm, modalTitle }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.servers);
  const [errors, setErrors] = React.useState([]);
  const [runners, setRunners] = useState([]);

  useEffect(() => {
    const fetchRunners = async () => {
      try {
        const runnerData = await getRunnerDRD();
        setRunners(runnerData.data);
      } catch (error) {
        toast.error("Failed to fetch runners");
      }
    };

    fetchRunners();
  }, []);

  const initialValues = {
    name: "Runner_",
    type: "Cron",
    domain_runner_id: "",
    app_folder_name: "cms-domain-backend-cron",
    pm2_process_name: "cms-domain-backend-cron",
    api_url: "https://runner5.ecommcube.com/api/setup_front_backend/deploye_backend_and_cron",
    ...(editingRecord && {
      id: editingRecord?._id,
      name: editingRecord?.name,
      type: editingRecord?.type,
      domain_runner_id: editingRecord?.domain_runner_id._id,
      app_folder_name: editingRecord?.app_folder_name,
      pm2_process_name: editingRecord?.pm2_process_name,
      api_url: editingRecord?.api_url,
    }),
  };
  const handleSubmit = async (values) => {
    const action = editingRecord ? update_server : create_server;
    const message = editingRecord ? "updated" : "added";
    try {
      const res = await dispatch(action(values));
      if (res.payload?.status === 200 || res.payload?.status === 201) {
        toast.success(`Server ${message} successfully`);
        dispatch(get_runner_apps());
        onCancelForm();
      } else {
        if (Array.isArray(res.payload)) {
          setErrors(res.payload);
        } else {
          toast.error(
            res.payload
              ? res.payload
              : `Server couldn't be ${message} successfully`
          );
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload)) {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload
            ? error.payload
            : `Server couldn't be ${message} successfully`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  const typeOptions = [
    { value: "Backend", label: "Backend" },
    { value: "Frontend", label: "Frontend" },
    { value: "Cron", label: "Cron" },
  ];

  const runnerOptions = runners.map((runner) => ({
    value: runner._id,
    label: runner.runner_name,
  }));
  return (
    <Modal
      isUpdate={editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={formik.handleSubmit}
      onClick={onCancelForm}
    >
      {isLoading && <DotsLoader />}
      <div className="container-fluid max-w-[900px] border rounded bg-white py-3 w-full flex flex-row justify-between">
        <div className="w-full !mr-2 grid grid-cols-2 gap-x-2">
          <div className="col-span-1">
            <FormInput
              name="name"
              label="Name"
              errors={errors}
              formik={formik}
            />
          </div>
          <div className="col-span-1">
            <FormSelectInput
              label="Runner"
              name="domain_runner_id"
              options={runnerOptions}
              valueProp="value"
              labelProp="label"
              errors={errors}
              formik={formik}
            />
          </div>
          <div className="col-span-1 !mt-3">
            <FormInput
              className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
              label="App Folder Name"
              name="app_folder_name"
              errors={errors}
              formik={formik}
            />
          </div>
          <div className="col-span-1 !mt-3">
            <FormInput
              label="pm2 Process Name"
              name="pm2_process_name"
              errors={errors}
              formik={formik}
            />
          </div>
          <div className="col-span-1 !mt-3">
            <FormSelectInput
              errors={errors}
              name="type"
              label="Type"
              formik={formik}
              options={typeOptions}
              valueProp="value"
              labelProp="label"
            />
          </div>
          <div className="col-span-2 !mt-3">
            <FormInput
              className="form-control-sm border  w-full h-[40px] focus:shadow-none focus:border-0"
              label="API URL"
              name="api_url"
              errors={errors}
              formik={formik}
            />
          </div>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
}

export default AddUpdateUsers;
