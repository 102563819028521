import React, { useCallback, useState } from "react";
import { DotsLoader, FormInput, Modal } from "../../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import {
  create_audio_files,
  get_audio_files,
} from "../../../features/callCentersSlice";
function UploadAudioFile({ editingRecord, onCancelForm, modalTitle }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.callCenters);
  const [errors, setErrors] = useState([]);
  const [audioFile, setAudioFile] = useState(null);
  let initialValues = {
    file_name: "",
  };
  const onDrop = useCallback((acceptedFiles) => {
    let files = acceptedFiles[0];
    setAudioFile(files);
    formik.setFieldValue("file_name", files?.name);
    // handleFile(files);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
    accept: "audio/*",
  });

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("audiofile", audioFile);
    formData.append("file_name", formik.values.file_name);
    try {
      const res = await dispatch(create_audio_files(formData));
      if (res?.payload.status === 200) {
        toast.success(res?.payload?.data?.message || "File Successfully saved");
        dispatch(get_audio_files());
        onCancelForm();
      } else toast.error(res?.payload || "File couldn't be be saved");
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error);
      if (Array.isArray(error.payload) || typeof error.payload === "object") {
        setErrors(error.payload);
      } else {
        toast.error("File couldn't be be saveds");
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  return (
    <Modal
      isUpdate={!!editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      formIsValid={formik.isValid}
      onSubmit={formik.handleSubmit}
      isLoading={isLoading}
      onClick={onCancelForm}
    >
      {isLoading ? <DotsLoader /> : null}
      <div className="grid md:grid-cols-1 gap-x-5 mt-3 mx-2.5">
        <div className="text-base font-medium">
          <span className="font-semibold text-lg font_pop text-red-600">
            Note:{" "}
          </span>{" "}
          We STRONGLY recommend uploading only 16bit Mono 8k PCM WAV audio
          files(.wav)
        </div>
        <div className="!my-3">
          <div
            className={`w-full flex justify-center border-2 border-gray-300 border-dashed rounded-md !p-2 hover:border-gray-400 focus:outline-none ${
              isDragActive ? "bg-gray-100" : "bg-white"
            }`}
            {...getRootProps()}
          >
            <label
              className={`flex justify-center w-full ${"h-[80px]"} px-4 transition appearance-none cursor-pointer`}
            >
              {audioFile ? (
                <span className="flex items-center space-x-2">
                  <div className="flex flex-col text-center">
                    <span className="font-medium text-gray-600">
                      File Name: {audioFile?.name}
                    </span>
                    <span className="text-gray-600" onClick={open}>
                      Size:{" "}
                      {audioFile?.size > 1000 && audioFile.size < 1000000
                        ? (audioFile.size / 1000).toFixed(2) + " KB"
                        : (audioFile.size / 1000000).toFixed(2) + " MB"}
                    </span>
                  </div>
                </span>
              ) : (
                <span className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <span className="font-medium text-gray-600">
                    Drop files to Attach, or{" "}
                    <span className="text-blue-600 underline" onClick={open}>
                      browse
                    </span>
                  </span>
                </span>
              )}

              <input {...getInputProps()} />
            </label>
          </div>
        </div>
        <div className="w-full">
          <FormInput
            errors={errors}
            name="file_name"
            label="File Name"
            formik={formik}
          />
        </div>
        <a
          className="text-center items-center cursor-pointer hover:underline no-underline mt-3"
          target="_blank"
          rel="noreferrer"
          href={`https://g711.org/`}
        >
          Audio converter for asterisk IVR Prompts
        </a>
      </div>
    </Modal>
  );
}

export default UploadAudioFile;
