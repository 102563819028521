import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getBatchList } from "../services/batchListService";
const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
  record: [],
  batchListDrd: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get batch list data
export const get_batchlist = createAsyncThunk(
  "get_batchlist",
  async (token, thunkAPI) => {
    try {
      return await getBatchList(token);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const BatchListSlice = createSlice({
  name: "batchlist",
  initialState,
  reducers: {
    batchListReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.batchListDrd = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_batchlist.pending, (state) => {
        state.isLoading = true;
        state.batchListDrd = [];
      })
      .addCase(get_batchlist.fulfilled, (state, action) => {
        state.isLoading = false;
        const filteredData = action.payload.data?.records?.map(
          ({ _id, batch_name }) => ({ _id, batch_name })
        );
        state.batchListDrd = filteredData;
      })
      .addCase(get_batchlist.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      });
  },
});
export const { batchListReset } = BatchListSlice.actions;
export default BatchListSlice.reducer;
