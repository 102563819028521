import React, { useState, useEffect } from "react";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import { DotsLoader } from "../../components";
import { address_icn } from "../../images";
import { useParams } from "react-router-dom";
import FormInput from "./Input";
import api from "../../services/api";
import { toast } from "react-toastify";
const libraries = ["places", "drawing", "geometry"];
function LocationPermissionInstructions({ isOpen, onClose }) {
  if (!isOpen) return null; // Only render if open

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 max-w-md mx-auto relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
        >
          &times;
        </button>
        <h2 className="text-lg font-bold mb-4 text-center">
          Location Access Needed
        </h2>
        <p className="text-sm text-gray-600 mb-4">
          Location access was denied. To enable it, please follow these
          instructions based on your browser:
        </p>
        <ul className="list-disc list-inside text-gray-700 text-sm space-y-2">
          <li>
            <strong>Chrome:</strong> Go to{" "}
            <em>
              Settings &gt; Privacy and Security &gt; Site Settings &gt;
              Location
            </em>
          </li>
          <li>
            <strong>Firefox:</strong> Go to{" "}
            <em>
              Preferences &gt; Privacy & Security &gt; Permissions &gt; Location
            </em>
          </li>
          <li>
            <strong>Safari:</strong> Go to{" "}
            <em>Preferences &gt; Websites &gt; Location</em>
          </li>
        </ul>
        <button
          onClick={onClose}
          className="mt-4 w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-200"
        >
          Close
        </button>
      </div>
    </div>
  );
}
function PickupLocationForm() {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_API,
    libraries,
  });
  const [coords, setCoords] = useState({
    lat: 10.99835602,
    lng: 77.01502627,
    accuracy: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [accuracy, setAccuracy] = useState(null);
  const { token } = useParams();

  const [location, setLocation] = useState({
    token,
    pickup_latitude: "",
    pickup_longitude: "",
    pickup_country: null,
    pickup_city: null,
    pickup_state: null,
    pickup_zip: null,
    complete_pickup_address: null,
  });
  useEffect(() => {
    getLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extractAddress = (place) => {
    if (!Array.isArray(place?.address_components)) return;

    const updatedLocation = {
      ...location,
      complete_pickup_address: place?.formatted_address,
    };

    place.address_components.forEach((component) => {
      const types = component.types;
      if (types.includes("postal_code"))
        updatedLocation.pickup_zip = component.long_name;
      if (types.includes("locality"))
        updatedLocation.pickup_city = component.long_name;
      if (types.includes("administrative_area_level_1"))
        updatedLocation.pickup_state = component.short_name;
      if (types.includes("country"))
        updatedLocation.pickup_country = component.long_name;
    });

    setLocation(updatedLocation);
  };
  const getLocation = () => {
    setIsLoading(true);

    // Check if the permissions API is supported
    if (navigator.permissions) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          // If permission is denied, prompt user again on subsequent clicks
          if (permissionStatus.state === "denied") {
            // alert(
            //   "Location access was denied. Please enable it in your browser settings or try again."
            // );
            setIsLoading(false);
            setModalOpen(true);
          } else {
            // Request geolocation if permission is prompt or granted
            requestGeolocation();
          }

          // Listen for changes to the permission status
          permissionStatus.onchange = () => {
            if (permissionStatus.state === "granted") {
              requestGeolocation();
            }
          };
        })
        .catch((error) => {
          console.error("Permission API error:", error);
          setIsLoading(false);
        });
    } else {
      // Fallback if the Permissions API is not supported
      requestGeolocation();
    }
  };

  // Helper function to request geolocation
  const requestGeolocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude, accuracy } = position.coords;
        setCoords({ lat: latitude, lng: longitude, accuracy });
        setAccuracy(accuracy);

        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=true&key=${process.env.REACT_APP_MAP_API}`
        )
          .then((res) => res.json())
          .then((json) => {
            setIsLoading(false);
            extractAddress(json.results[0]);
            setLocation((prev) => ({
              ...prev,
              pickup_latitude: latitude,
              pickup_longitude: longitude,
            }));
          })
          .catch((error) => {
            console.error("Geocode API Error:", error);
            setIsLoading(false);
          });
      },
      (error) => {
        console.error("Geolocation Error:", error.message);
        setIsLoading(false);

        if (error.code === error.PERMISSION_DENIED) {
          alert(
            "Location permission is required to access your current location.."
          );
        } else {
          alert("Unable to retrieve your location. Please try again.");
        }
      }
    );
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLocation((prev) => ({ ...prev, [name]: value }));
  };
  const getErrorMessage = (error) => {
    return (
      error?.response?.data?.error ||
      (error?.response?.data?.debugInfo && [
        error?.response?.data?.debugInfo,
      ]) ||
      error?.response?.data?.message ||
      error?.response?.data ||
      error.toString()
    );
  };
  const handleSave = async () => {
    setIsLoading(true);
    try {
      const url = `/api/open/get_client_job_location`;
      const response = await api.post(url, location);
      if (response.status === 200 || response.status === 201) {
        toast.success("Location sent successfully!");
      } else {
        toast.error(
          response?.data?.error ||
            response?.data?.message ||
            "Location couldn't be sent."
        );
      }
      console.log("Location sent successfully!", response.data);
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message || "Location couldn't be sent.");
      console.error("Error uploading images:", error);
    }
    setIsLoading(false);
  };
  return (
    <div>
      <LocationPermissionInstructions
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <div className="bg-[#242f57] text-white py-3.7">
        <h2 className="text-lg text-center font-semibold font-pop">
          24/7 Emergency Services
        </h2>
      </div>
      {isLoading && <DotsLoader />}

      <div className="container-fluid mt-4">
        <div className="bg-white p-3.7 rounded-xl shadow-[0_4px_20px_rgba(59,_89,_153,_0.15)] mb-5">
          <h3 className="text-lg border-b-2 border-neutral-100 pb-2 font-pop font-medium">
            Pickup Address
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 !mt-3">
            <div className="mt-">
              <div className="flex justify-end items-center mb-2">
                <button
                  type="button"
                  className="text-base font-pop font-medium text-[#007bff] cursor-pointer underline"
                  onClick={getLocation}
                >
                  Click here to get your current location
                </button>
              </div>

              <FormInput
                icon={address_icn}
                value={location.complete_pickup_address}
                placeholder="Pickup Address"
                name="complete_pickup_address"
                label={
                  accuracy ? `Accurate to ${accuracy.toFixed(2)} meters` : ""
                }
                onChange={(e) => handleOnChange(e)}
              />

              <div className="grid lg:grid-cols-3 gap-x-2">
                <FormInput
                  placeholder="Zip"
                  name="pickup_zip"
                  value={location.pickup_zip}
                  onChange={(e) => handleOnChange(e)}
                />
                <FormInput
                  placeholder="State"
                  name="pickup_state"
                  value={location.pickup_state}
                  onChange={(e) => handleOnChange(e)}
                />
                <FormInput
                  placeholder="City"
                  name="pickup_city"
                  value={location.pickup_city}
                  onChange={(e) => handleOnChange(e)}
                />
              </div>

              <div className="flex gap-x-2 justify-end">
                {/* <button
                  className="py-1.5 px-3 text-white bg-client-100"
                  onClick={getLocation}
                >
                  <BiRefresh size={24} />
                </button> */}
                <button
                  className="py-1.5 px-3 text-white bg-client-100"
                  type="button"
                  onClick={handleSave}
                >
                  Send Your Location
                </button>
              </div>
            </div>
            <div>
              {isLoaded && (
                <GoogleMap
                  center={coords}
                  zoom={15}
                  mapContainerStyle={{
                    width: "100%",
                    height: "65vh",
                    borderRadius: 10,
                    border: "2px solid #f5f5f5",
                  }}
                  options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                  }}
                >
                  <Marker position={coords} />
                </GoogleMap>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PickupLocationForm;
