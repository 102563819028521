import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createCardType,
  getCardTypes,
  getCardTypesDrd,
  updateCardType,
} from "../services/cardTypeService";

const initialState = {
  isError: false,
  isLoading: false,
  cardTypes: [],
  message: "",
  cardTypesDrd:[]
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Card Type
export const get_card_types = createAsyncThunk(
  "get_card_types",
  async (token, thunkAPI) => {
    try {
      return await getCardTypes(token);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Card Type
export const get_card_types_drd = createAsyncThunk(
  "get_card_types_drd",
  async (token, thunkAPI) => {
    try {
      return await getCardTypesDrd();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create New Card Type
export const create_card_type = createAsyncThunk(
  "create_card_type",
  async (data, thunkAPI) => {
    try {
      return await createCardType(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Card Type
export const update_card_type = createAsyncThunk(
  "update_card_type",
  async (data, thunkAPI) => {
    try {
      return await updateCardType(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const cardTypesSlice = createSlice({
  name: "getCardTypes",
  initialState,
  reducers: {
    cardTypeReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.cardTypes = [];
      state.message = "";
      state.cardTypesDrd=[]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_card_types.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_card_types.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cardTypes = action.payload?.data
      })
      .addCase(get_card_types.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.cardTypes = [];
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(create_card_type.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_card_type.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cardTypes.push(action.payload.data);
      })
      .addCase(create_card_type.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_card_type.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_card_type.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.cardTypes.records.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.cardTypes[result] = {
          ...state.cardTypes[result],
          ...action.payload.data,
        };
      })
      .addCase(update_card_type.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_card_types_drd.pending, (state) => {
        state.isLoading = true;
        state.cardTypesDrd = [];
      })
      .addCase(get_card_types_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cardTypesDrd = action.payload?.data
      })
      .addCase(get_card_types_drd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload?action.payload:"Unknown Error")
      });
  },
});

export const { cardTypeReset } = cardTypesSlice.actions;
export default cardTypesSlice.reducer;
