import { Dropdown } from "primereact/dropdown";
import React from "react";
import { Button } from "../../components";

function CopyZipsModal({
  onCloseModal,
  groupedOptions,
  handleChangeIndustry,
  assigedZips,
  setSearchedLocation,
}) {
  const [industry_id, setIndustry_id] = React.useState();

  const handleCopy = () => {
    const newArray = assigedZips.map((item) => ([{
      data: {
        states: [{ name: item.state }],
        cities: item.cities.map(({ name }) => ({ name })),
        zips: item.cities.flatMap(({ zips }) => zips.map((zip) => ({ zip }))),
      },
      type: "state",
      name: item.state,
      payload: {
        locations: item.state,
        type: "state",
      },
    }]));
    setSearchedLocation(newArray);
    handleChangeIndustry(industry_id);
    onCloseModal();
  };
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
      <div className="container-fluid mt-2 text-center">
        <div className="inline-block bg-white w-full my-0 mx-auto p-5 bg-red-600 rounded max-w-[40%]">
          <div className="mb-3">
            <Dropdown
              value={industry_id}
              onChange={(e) => setIndustry_id(e.value)}
              options={groupedOptions}
              optionLabel="label"
              optionValue="value"
              optionGroupLabel="label"
              optionGroupChildren="items"
              className="w-full md:w-14rem"
              placeholder="Select an industry"
            />
          </div>

          <Button
            text="Close"
            variant="btn_cancel"
            className="ml-3"
            onClick={onCloseModal}
          />
          <Button
            text="Copy Zips"
            variant="btn_submit"
            className="ml-3"
            onClick={handleCopy}
          />
        </div>
      </div>
    </div>
  );
}

export default CopyZipsModal;
