import React from "react";
import { Button } from "../../components";

function IndustriesModal({
  formik,
  vendorDetail,
  onCloseModal,
  allIndustries,
}) {
  const onIndustrySelect = (id) => {
    if (formik.values.industries?.some((ids) => ids === id)) {
      const arr = formik.values.industries;
      formik.setFieldValue(
        "industries",
        arr?.filter((indutry) => indutry !== id)
      );
      formik.setS((prevArray) => prevArray?.filter((item) => item !== id));
    } else {
      formik.setFieldValue("industries", [...formik.values.industries, id]);
    }
  };
  const industries_without = vendorDetail?.industries?.filter(
    (item) =>
      !vendorDetail?.industries_has_locations?.includes(item.id) &&
      !formik.values.industries?.includes(item.id)
  );
  const with_locations = vendorDetail?.industries?.filter(
    (item) =>
      vendorDetail?.industries_has_locations?.includes(item.id) &&
      !formik.values.industries?.includes(item.id)
  );
  const optionsRef = React.useRef();
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      onCloseModal();
    }
  };
  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      onCloseModal();
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
      <div className="container-fluid mt-2 text-center" ref={optionsRef}>
        <div className="inline-block bg-white w-full my-0 mx-auto p-5 bg-red-600 rounded max-w-[80%]">
          <div className="grid grid-cols-1 gap-y-2 max-h-[70vh] overflow-y-auto mb-3">
            <div className="p-3 bg-white rounded border !mt-2">
            <h5 className="bg-[#f0f0f0] text-[#343434] p-1 w-full border border-[#d8d8d8] flex flex-row justify-between items-center">
                Industries without Locations
                <span className="bg-[#343434] w-5 h-5 flex justify-center items-center rounded-full">
              <small className="text-white text-sm">{industries_without?.length?industries_without?.length:0}</small>
              </span>
              </h5>
              <div className="flex flex-wrap">
                {industries_without?.map((category) => (
                  <div key={category.id}>
                    <div
                      className={`flex items-center !py-1.5 !px-2.5`}
                      onClick={(e) => onIndustrySelect(category?.id)}
                    >
                      <input
                        type="checkbox"
                        checked={formik.values.industries?.some(
                          (id) => id === category.id
                        )}
                        // onChange={(e) => onIndustrySelect(category?.id)}
                        className={`form-checkbox h-5 w-5 text-primary-100 rounded focus:ring-0 cursor-pointer mr-2`}
                        value={category.id}
                      />
                      <label
                        className={`text-base text-[#495057] cursor-pointer`}
                      >
                        {
                          allIndustries?.find(({ id }) => id === category.id)
                            ?.industry_name
                        }
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="p-3 bg-white rounded border !mt-2">
            <h5 className="bg-[#f0f0f0] text-[#343434] p-1 w-full border border-[#d8d8d8] flex flex-row justify-between items-center">
                Industries with Locations
                <span className="bg-[#343434] w-5 h-5 flex justify-center items-center rounded-full">
              <small className="text-white text-sm">{with_locations?.length?with_locations?.length:0}</small>
              </span>
              </h5>
              <div className="flex flex-wrap">
                {with_locations?.map((category) => (
                  <div key={category.id}>
                    <div
                      className={`flex items-center !py-1.5 !px-2.5 `}
                      onClick={(e) => onIndustrySelect(category?.id)}
                    >
                      <input
                        type="checkbox"
                        checked={formik.values.industries?.some(
                          (id) => id === category.id
                        )}
                        // onChange={(e) => onIndustrySelect(category?.id)}
                        className={`form-checkbox h-5 w-5 text-primary-100 rounded focus:ring-0 cursor-pointer mr-2`}
                        value={category.id}
                      />
                      <label
                        className={`text-base text-[#495057] cursor-pointer`}
                      >
                        {
                          allIndustries?.find(({ id }) => id === category.id)
                            ?.industry_name
                        }
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Button
            text="Close"
            variant="btn_cancel"
            className="ml-3"
            onClick={onCloseModal}
          />
        </div>
      </div>
    </div>
  );
}

export default IndustriesModal;
