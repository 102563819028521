import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DotsLoader, Button } from "../../../../components";
import MUIDataTable from "../../../../components/molecules/DataTable/muigrid";
import PageHeader from "../../../../components/molecules/PageHeader";
import AddUpdateForm from "./AddUpdateForm";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import { FaTrashAlt } from "react-icons/fa";
import { FaPhoneSlash, FaDownload, FaFileExport } from "react-icons/fa6";
import { HiOutlineRefresh } from "react-icons/hi";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { FaSearch } from "react-icons/fa";
import { Button as MUIButton } from "@mui/material";
import DownloadExcelForm from "./DownloadExcelForm";
import ExportDidToCallcenter from "./ExportDidToCallcenter";
import { get_cc_cards, get_cc_providers } from "../../../../features/didSlice";
import { get_industry_drd } from "../../../../features/IndustrySlice";
import { get_dialer_users } from "../../../../features/usersSlice";
import { useSelector, useDispatch } from "react-redux";
import { get_inbound_group } from "../../../../features/inboundGroupSlice";
import Swal from "sweetalert2";
import { get_affiliate } from "../../../../features/callCentersSlice";
function PurchaseDids() {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [callManu, setCallMenu] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isLoader, setIsLoading] = React.useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [didList, setDidList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDownloadExcel, setDownloadExcel] = React.useState(false);
  const [isSyncToInteqliquent, setSyncToInteqliquent] = React.useState(false);
  const { isLoading, record } = useSelector((state) => state.dids);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const cancelFormHandler = () => {
    setIsEditing(false);
    setDownloadExcel(false);
    setSyncToInteqliquent(false);
  };

  const get_call_menus = async () => {
    try {
      const res = await api.get("/api/cc/dids/options/call_menus");
      if (res.status === 200) {
        setCallMenu(res.data);
      }
    } catch (err) {
      console.log("🚀 ~ constget_call_menus=async ~ err:", err);
    }
  };
  const getDidList = async (filters) => {
    if (!filters?.filters?.rules?.length) {
      delete filters.filters;
    }
    const payload = {
      ...filters,
    };
    setIsLoading(true);
    try {
      const res = await api.post("/api/cc/dids/purchase_did_list", payload);
      if (res.status === 200) {
        setDidList(res.data?.data);
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    dispatch(get_affiliate());
    dispatch(get_industry_drd());
    dispatch(get_cc_cards());
    dispatch(get_cc_providers());
    dispatch(get_dialer_users());
    dispatch(get_inbound_group());
    get_call_menus();
    getDidList({ page: 1, size: paginationModel.pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openFormHandler = (record) => () => {
    setIsEditing(true);
  };

  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = didList?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };

  const onSingleSelect = ({ checked, data }) => {
    if (isLoading) {
      return toast.error("Please wait until the previous process is complete.");
    }
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          if (!prevSelectedData.some(({ _id }) => _id === data._id)) {
            return [...prevSelectedData, data];
          }
          return prevSelectedData;
        });
      } else {
        setSelectedData((prevSelectedData) => {
          // Remove item from the selected list
          return prevSelectedData.filter(({ _id }) => _id !== data._id);
        });
      }
    } catch (err) {
      console.error("Error in onSingleSelect:", err);
    }
  };

  const isSelected = (data) => {
    return selectedData.some(({ _id }) => _id === data._id);
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={didList?.length && selectedData?.length === didList?.length}
          disabled={!didList?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={isSelected(params.row.records)}
          onChange={(e) =>
            onSingleSelect({
              checked: e.target.checked,
              data: params.row.records,
            })
          }
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
      disableColumnMenu: true,
    },
    { headerName: "#", field: "counter", width: 10 },
    {
      headerName: "DID",
      field: "did",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Service Provider Status",
      field: "status",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <span className="text-sm font-medium me-2 px-2.5 py-0.5 rounded bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300">
          {params.row.status}
        </span>
      ),
    },
    {
      headerName: "Call Center Status",
      field: "callCenter_status",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <div className="flex flex-col">
          <span>{params.row.callCenter_status || "N/A"}</span>
        </div>
      ),
    },
    {
      headerName: "Purchased By",
      column: "purchased_by",
      field: "purchased_by.first_name",
      filterable: false,
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <div className="flex flex-col">
          <span>
            {params.row.purchased_by?.first_name}{" "}
            {params.row.purchased_by?.last_name}
          </span>
        </div>
      ),
    },
    {
      headerName: "Description",
      field: "description",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Created At",
      field: "created_at",
      flex: 1,
      minWidth: 120,
      filterable: false,
    },
  ];

  const onFormSubmit = () => {
    setIsEditing(false);
    getDidList({ page: 1, size: paginationModel.pageSize });
  };

  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      getDidList({
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "onOrBefore"
              ? "lte"
              : rule.operator === "before"
              ? "lt"
              : rule.operator === "onOrAfter"
              ? "gte"
              : rule.operator === "after"
              ? "gt"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getDidList({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={unregisteredDid}
          startIcon={<FaPhoneSlash className="h-5 w-5" />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Unregister DID from service provider
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={downloadExcel}
          startIcon={<FaDownload className="h-4 w-4" />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Export
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={syncToInteliquent}
          startIcon={<FaFileExport className="h-4 w-4" />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Export DID to CC
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={handleBulkDelete}
          startIcon={<FaTrashAlt className="h-4 w-4" />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
          title="Delete DIDs from Call Center"
        >
          Delete Selected
        </MUIButton>
      </GridToolbarContainer>
    );
  }

  const unregisteredDid = async () => {
    if (!selectedData?.length) {
      toast.error("Please select at least one DID.");
      return;
    }
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    setLoading(true);
    const payload = {
      dids: selectedData?.map((item) => item.did),
    };
    try {
      const res = await api.post(
        "/api/cc/dids/unregister_selected_dids",
        payload
      );
      if (res.status === 200) {
        toast.success("DID successfully unregistered.");
        await getDidList({
          filters: queryOptions,
          page: paginationModel?.page,
          size: paginationModel.pageSize,
        });
      } else {
        toast.error("DID could not be unregistered!");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("DID could not be unregistered!");
      console.log(err);
    }
  };
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getDidList({
      filters: queryOptions,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };

  const downloadExcel = async () => {
    if (selectedData?.length) {
      setDownloadExcel(true);
    } else {
      toast.error("Please select at least one DID.");
    }
  };

  const syncToInteliquent = async () => {
    if (selectedData?.length) {
      setSyncToInteqliquent(true);
    } else {
      toast.error("Please select at least one DID.");
    }
  };

  const exportFormSubmit = () => {
    setSyncToInteqliquent(false);
    getDidList({ page: 1, size: paginationModel.pageSize });
  };

  const handleBulkDelete = async () => {
    if (!selectedData?.length) {
      toast.error("Please select at least one DID.");
      return;
    }

    Swal.fire({
      title: "Are you sure to perform this action?",
      text: "DIDs will be deleted from the Call Center only.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        popup: "w-90 p-4 text-sm",
        title: "text-lg font-bold",
        content: "text-sm font-semibold",
        confirmButton: "text-sm py-2 px-4",
        cancelButton: "text-sm py-2 px-4",
      },
    }).then(async (result) => {
      if (!result.isConfirmed) return;
      setIsLoading(true);
      try {
        const updatedArray = selectedData.map((data) => ({
          did: "1" + data.did,
        }));

        let searchCondition = [];
        searchCondition.push(
          `did_pattern IN (${updatedArray.map((d) => `"${d.did}"`).join(",")})`
        );

        const searchStr =
          searchCondition.length > 0 ? searchCondition.join(` AND `) : "";

        const res = await api.post(`/api/cc/dids/get_dids`, {
          page: paginationModel?.page,
          size: paginationModel?.pageSize,
          sqlwhere: searchStr,
        });
        if (res?.status === 200 || res?.status === 201) {
          const [did_data, didPattern] = res.data?.data?.reduce(
            (acc, record) => {
              acc[0].push({ did_id: record?.did_id });
              acc[1].push(record?.did_pattern.slice(-10)); 
              return acc;
            },
            [[], []]
          );
         
          if (!did_data.length || !didPattern.length) {
            toast.error("No data available for deletion.");
            return;  
          }

          try {
            const { data } = await api.post("/api/cc/dids/bulk_delete", {
              did_data: did_data,
              did_patterns: didPattern,
            });

            if (data?.status) {
              setSelectedData([]);
              toast.success(data?.message);
              getDidList({
                filters: queryOptions,
                page: 1,
                size: paginationModel.pageSize,
              });
            } else {
              toast.error("Data couldn't be deleted");
            }
          } catch (err) {
            toast.error("An error occurred during deletion");
            console.error(err);
          }
        } else {
          toast.error("Failed to retrieve DIDs for deletion");
        }
       } catch (err) {
        toast.error("An error occurred while fetching DIDs");
        console.error(err);
      } finally {
        setIsLoading(false);
      } 
    });
  };

  return (
    <>
      {isLoader || loading ? <DotsLoader /> : null}
      <PageHeader
        heading="Purchase DIDs"
        isAllowed={true}
        onClick={openFormHandler(0)}
        extraButton={true}
        extraButtonClick={() => {
          getDidList({
            filters: queryOptions,
            page: 1,
            size: paginationModel.pageSize,
          });
          setSelectedData([]);
        }}
        additionalBtnAllow={true}
        addIcon="Yes"
        Icon={HiOutlineRefresh}
        iconClassName=" w-5 h-5"
        
      />

      {isEditing && (
        <AddUpdateForm
          modalTitle="Purchase DIDs"
          onCancelForm={cancelFormHandler}
          onFormSubmit={onFormSubmit}
        />
      )}

      {isDownloadExcel ? (
        <DownloadExcelForm
          modalTitle="Export Excel"
          onCancelForm={cancelFormHandler}
          selectedData={selectedData}
        />
      ) : null}
      {isSyncToInteqliquent ? (
        <ExportDidToCallcenter
          modalTitle="Export DID To Call Center"
          onCancelForm={cancelFormHandler}
          selectedData={selectedData}
          callManu={callManu}
          exportFormSubmit={exportFormSubmit}
        />
      ) : null}
      <div className="bg-white !my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={didList?.map((item, index) => {
            let counter = index + 1;
            let created_at = dayjs(item?.createdAt).format(
              "ddd, MMM D, YYYY h:mm A"
            );
            const {
              did,
              status,
              purchased_by,
              description,
              callCenter_status,
            } = item;
            return {
              records: { ...item },
              counter,
              did,
              status,
              description,
              purchased_by,
              created_at,
              callCenter_status,
            };
          })}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          searchable="No"
          totalItems={didList?.length}
          isLoading={isLoader}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
        />
      </div>
    </>
  );
}

export default PurchaseDids;
