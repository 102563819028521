import { IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import { FaSave } from "react-icons/fa";

function EditCellInput({ preValue, params, handleSaveTags }) {
  const [newAPILink, setNewAPIlink] = useState("");
  return (
    <div className="flex items-center">
      <TextField
        onChange={(e) => setNewAPIlink(e.target.value)}
        value={newAPILink || preValue}
        sx={{ maxWidth: "200px", width: "100%" }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSaveTags({ ...params, newAPILink });
          }
        }}
      />
      <IconButton
        onClick={() => handleSaveTags({ ...params, newAPILink })}
        color="primary"
        sx={{ marginLeft: 1 }}
      >
        <FaSave size={16} />
      </IconButton>
    </div>
  );
}

export default EditCellInput;
