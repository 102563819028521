import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { create_service_cost_data } from "../../features/quotePricingSlice";
import { location_data } from "./config";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";

function AddServiceCostData({
  editServiceData,
  onCancelForm,
  modalTitle,
  serviceCostId,
}) {
  const jobTypes = useSelector((state) => state.jobCategory);
  const serviceCost = useSelector((state) => state.quotePricing);
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState([]);

  let initialValues = {
    service_cost_id: serviceCostId,
    location_data: {
      locations_price: [],
    },
  };
  const formik = useFormik({
    initialValues,

    validateOnBlur: true,

    validationSchema: Yup.object().shape({
      service_cost_id: Yup.string().trim(),
      location_data: Yup.object(),
    }),

    onSubmit: async (values) => {
      if (!serviceCost?.isLoading) {
        try {
          const res = await dispatch(create_service_cost_data(values));
          if (res?.payload?.status === 200) {
            toast.success("Service Cost data be created");
            formik.setFieldValue("location_data.locations_price", "");
          } else {
            if (Array.isArray(res.payload)) {
              setErrors(res.payload);
            } else {
              toast.error(
                res.payload ? res.payload : `Call Center couldn't be created`
              );
            }
          }
        } catch (error) {
          if (Array.isArray(error.payload)) {
            setErrors(error.payload);
          } else {
            toast.error(
              error.payload ? error.payload : `Call Center couldn't be created`
            );
          }
        }
      } else {
        toast.error("Please wait until previous action be completed");
      }
    },
  });
  return (
    <>
      <Modal
        isUpdate={!!editServiceData}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={jobTypes?.isLoading}
        onClick={onCancelForm}
      >
        {jobTypes?.isLoading || serviceCost?.isLoading ? <DotsLoader /> : null}

        <form className="grid md:grid-cols-2 gap-x-5 mt-5">
          <div className="mb-2">
            <FormSelectInput errors={errors}
              name="unit"
              label="Unit"
              formik={formik}
              options={[
                {
                  value: "FIXED",
                  label: "Fixed",
                },
                {
                  value: "PER_UNIT",
                  label: "Per Unit",
                },
                {
                  value: "UNIT",
                  label: "Unit",
                },
              ]}
              labelProp="label"
              valueProp="value"
              onChange={(val) =>
                formik?.setFieldValue("location_data", {
                  ...formik?.values.location_data,
                  unit: val,
                })
              }
               
            />
          </div>
          <div className="mb-2">
            <FormInput errors={errors}
              name="location_data.title"
              label="Title"
              formik={formik}
              value={formik?.values?.location_data?.title}
               
            />
          </div>

          {location_data?.map((item, key) => {
            return (
              <div className="form-group w-full" key={key}>
                <div className="flex flex-col mb-2">
                  <TextField
                    id="standard-password-input"
                    label={item?.label}
                    variant="outlined"
                    sx={{
                      "& label.Mui-focused": {
                        color: "#000",
                      },
                      "&:hover fieldset": {
                        borderColor: "yellow",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#f3804e",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#a9a9a9",
                          background: "white",
                        },
                        "&:hover fieldset": {
                          borderColor: "#a9a9a9",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#a9a9a9",
                        },
                      },
                      "&  .MuiFormHelperText-root.Mui-error": {
                        backgroundColor: "transparent ",
                        margin: 0,
                        paddingLeft: 2,
                      },
                      mt: 0,
                    }}
                    margin="dense"
                    size="small"
                    value={
                      (formik?.values?.location_data?.locations_price &&
                        formik?.values?.location_data?.locations_price[key]
                          ?.cost) ||
                      ""
                    }
                    onChange={(v) =>
                      formik.setFieldValue(
                        `location_data.locations_price.${key}`,
                        { location: item?.location, cost: v.target.value }
                      )
                    }
                    className={[
                      formik.errors[item?.location] &&
                      formik.touched[item?.location]
                        ? "invalid"
                        : "",
                    ].join(" ")}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors[item?.location] &&
                      formik.touched[item?.location]
                        ? true
                        : false
                    }
                  />
                  {formik.errors[item?.location] &&
                    formik.touched[item?.location] && (
                      <small
                        className="form-text"
                        style={{
                          fontSize: 12,
                          marginTop: -5,
                          paddingLeft: 16,
                          color: "#D32F2F",
                        }}
                      >
                        {formik.errors[item?.location]}
                      </small>
                    )}
                  {errors?.length > 0 &&
                    errors?.map((error, index) => {
                      return (
                        <>
                          {error[item?.location] && (
                            // <div key={index} className="text-[#f00] text-sm mb-3.7 mt-1.5">{error[name].replaceAll(name,placeholder)}</div>

                            <small
                              style={{
                                fontSize: 12,
                                marginTop: -5,
                                paddingLeft: 16,
                                color: "#D32F2F",
                              }}
                              key={index}
                            >
                              {error[item?.location].replaceAll(
                                `"${item?.location}"`,
                                item.label
                              )}
                            </small>
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </form>
      </Modal>
    </>
  );
}

export default AddServiceCostData;
