import React, { memo, useMemo, useState } from "react";

import { DotsLoader } from "../../components";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { useDispatch, useSelector } from "react-redux";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { FaSearch } from "react-icons/fa";
import { Button as MUIButton, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import api from "../../services/api";
import PageHeader from "../../components/molecules/PageHeader";
import { get_crm_vendors_drd, get_users_drd } from "../../features/usersSlice";
import Picker from "../Components/Picker";
import { toast } from "react-toastify";
import LeadJobListModal from "./LeadJobList";
function VendorJobsReport() {
  const { CRMVendorsDrd } = useSelector((state) => state.users);
  const [leads, setLeads] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [record, setRecord] = useState([]);
  const dispatch = useDispatch();
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [dateRange, setDateRange] = React.useState({
    start_date: dayjs()
      .subtract(31, "day")
      .set("date", dayjs().date())
      .toDate(),
    end_date: new Date(),
  });
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const getSERPReport = async (filter) => {
    setRecord([]);
    setIsLoading(true);
    if (!filter?.filters?.rules?.length) {
      delete filter.filters;
    }
    delete filter?.pageSize;
    try {
      const res = await api.post("/api/reports/vendor_job_report");
      if (res.status === 200) {
        // const dataArray = Object?.entries(
        //   res.data?.data?.vendors ? res.data?.data?.vendors : {}
        // ).map(([key, value]) => ({
        //   id: key, // Assuming you want to keep the original keys as IDs
        //   vendor: {
        //     ...CRMVendorsDrd?.find(({ _id }) => _id === key),
        //   },
        //   ...value,
        // }));

        const dataArray = res.data?.data?.vendors?.map((item) => {
          // Extract vendor details
          const vendorDetails = res.data?.data?.vendors_details?.find(
            ({ _id }) => _id === item.vendor_id
          ) || { _id: item.vendor_id, first_name: "Vendor Not Found" };

          // Create a new object with the vendor details
          const newItem = {
            vendor_id: { ...vendorDetails },
          };
          let totalJobCount = 0;
          // Iterate over the keys in the item object
          Object.keys(item).forEach((key) => {
            if (key !== "vendor_id") {
              const job = item[key];
              newItem[job.job_status] = {
                id: key,
                job_status: job.job_status,
                job_count: job.job_count,
              };
              totalJobCount += job.job_count;
            }
          });
          // Add the Total Jobs entry
          newItem["Total Jobs"] = {
            id: "total_jobs", // You can assign any id here
            job_status: "Total Jobs",
            job_count: totalJobCount,
          };
          return newItem;
        });
        const sortedDataArray = [...(dataArray || [])]?.sort((a, b) =>
          a?.vendor_id?.first_name.localeCompare(b?.vendor_id?.first_name)
        );
        setRecord({
          data: sortedDataArray,
          totalItems: res?.data?.totalItems,
        });
      } else {
        toast.error(res?.data?.message || "Data couldn't be loaded");
      }
      setIsLoading(false);
    } catch (err) {
      toast.error(err?.response?.data?.message || "Data couldn't be loaded");
      setIsLoading(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  React.useEffect(() => {
    getSERPReport({
      ...paginationModel,
      ...sortingModel,
      ...dateRange,
      size: paginationModel.pageSize,
      filters: queryOptions,
    });

    dispatch(get_users_drd());
    dispatch(get_crm_vendors_drd());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const batchList = (Array.isArray(record?.data) ? record?.data : [])?.filter(
    (e) => {
      return Object.keys(e)?.some(
        (key) =>
          e[key] &&
          e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
      );
    }
  );

  function getUniqueStatues(statuses) {
    if (memo.hasOwnProperty("statusArray") && memo.data === statuses) {
      return memo.data;
    }
    const uniqueKeysSet = new Set();
    record?.data?.forEach((obj) => {
      Object?.keys(obj ? obj : {}).forEach((key) => {
        if (
          key !== "vendor_id" &&
          key !== "vendor" &&
          key !== "id" &&
          key !== "Total Jobs"
        ) {
          uniqueKeysSet.add(key);
        }
      });
    });
    const uniqueStatusArray = Array.from(uniqueKeysSet);
    // Store results in memo object
    memo.data = statuses;
    memo.statusArray = [...uniqueStatusArray];

    return memo.statusArray;
  }
  const uniqueStatusArray = useMemo(
    () => getUniqueStatues(Array.isArray(record?.data) ? record?.data : []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [record?.data]
  );

  const columnDefs = [
    {
      headerName: "#",
      field: "counter",
      width: 60,
      filterable: false,
      sortable: false,
    },
    {
      field: "vendor_name",
      headerName: "Vendor Name",
      type: "singleSelect",
      flex: 1,
      minWidth: 280,
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) =>
        `${value.first_name || ""} ${value.last_name || ""}`,
      valueOptions: CRMVendorsDrd,
      renderCell: (params) => params.row?.vendor_name,
      valueGetter: (params) => params.row?.vendor_id,
      valueFormatter: (params) => params.value?.vendor_id,
      sortable: false,
    },
    {
      headerName: "Total Jobs",
      field: "total_jobs",
      filterable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() => {
              const filters = [];
              filters.push({
                field: "vendor_id",
                op: "eq",
                data: params?.row?.records?.vendor_id?._id,
              });
              // setLeads({ groupOP: "AND", rules: filters });
              setLeads({
                filter: { groupOP: "AND", rules: filters },
                titles: {
                  vendor: `${
                    params?.row?.records?.vendor_id?.first_name || ""
                  } ${params?.row?.records?.vendor_id?.last_name || ""}`,
                  status: "All Jobs",
                },
              });
            }}
          >
            {params.row.records["Total Jobs"]
              ? params.row.records["Total Jobs"]?.job_count
              : "-"}
          </span>
        );
      },
      renderHeader: () => <span className="testVertical">Total Jobs</span>,
      disableColumnMenu: true,
      width: 60,
    },
    ...uniqueStatusArray?.map((item) => ({
      field: `${item}`,
      renderHeader: (params) => (
        <Tooltip title={<span>{item}</span>}>
          <span className="testVertical">{item}</span>
        </Tooltip>
      ),
      renderCell: ({ row, field }) => {
        return (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() => {
              const filters = [];
              filters.push({
                field: "vendor_id",
                op: "eq",
                data: row?.records?.vendor_id?._id,
              });
              if (row.records[field]?.id !== "Total Jobs") {
                filters.push({
                  field: "job_status_id",
                  op: "eq",
                  data: row.records[field]?.id,
                });
              }
              setLeads({
                filter: { groupOP: "AND", rules: filters },
                titles: {
                  vendor: `${row?.records?.vendor_id?.first_name || ""} ${
                    row?.records?.vendor_id?.last_name || ""
                  }`,
                  status: field,
                },
              });
            }}
          >
            {row.records[item] ? row.records[item]?.job_count : "-"}
          </span>
        );
      },
      filterable: false,
      width: 50,
      sortable: false,
      valueGetter: (params) => params.row.records[item]?.jobs?.length, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2,
    })),
  ];

  // const handlePageSizeChange = (newPageSize) => {
  //   setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  // };
  // const handlePageChange = (params) => {
  //   setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  //   getSERPReport({
  //     ...sortingModel,
  //     ...dateRange,
  //     filters: queryOptions,
  //     page: +params.page + 1,
  //     size: params.pageSize,
  //   });
  // };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      getSERPReport({
        ...sortingModel,
        // ...dateRange,
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "onOrBefore"
              ? "lte"
              : rule.operator === "before"
              ? "lt"
              : rule.operator === "onOrAfter"
              ? "gte"
              : rule.operator === "after"
              ? "gt"
              : rule.operator,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getSERPReport({
      ...sortingModel,
      // ...dateRange,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        {/* <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        /> */}
        {/* <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton> */}
      </GridToolbarContainer>
    );
  }
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    getSERPReport({
      ...dateRange,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      sort_field: params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
  };
  const Count = () => {
    return (
      <div className="text-base font-normal font-mont text-heading">
        {`Displaying 1-${batchList?.length} of ${
          (Array.isArray(record?.data) ? record?.data : [])?.length
        } result`}
      </div>
    );
  };
  return (
    <>
      <PageHeader route="Setting > Industries" heading="Job Progress Report" />
      {leads ? (
        <LeadJobListModal onCancelForm={() => setLeads(null)} data={leads} />
      ) : null}
      {isLoading ? <DotsLoader /> : null}
      {/* <div className="flex justify-end w-full">
        <div className="w-auto">
          <Picker
            filterData={dateRange}
            setFiltersData={setDateRange}
            rangeValue="Last 31 days"
            dropdown_label="Report by"
            valueKey={"report_by"}
            isDropdown={"No"}
            fromKey={"start_date"}
            toKey={"end_date"}
            seachButtonOnClick={() =>
              getSERPReport({
                ...dateRange,
                ...sortingModel,
                filters: queryOptions,
                page: 1,
                perPage: paginationModel.pageSize,
              })
            }
            seachButton={true}
          />
        </div>
      </div> */}

      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={batchList?.map((record, index) => {
            const counter = index + 1;
            const { vendor_id } = record;
            const { first_name: v_name = "", last_name: v_last = "" } =
              vendor_id;
            return {
              records: { ...record },
              counter,
              // jobs,
              vendor_id: `${v_name} ${v_last}`,
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          sortingMode="server"
          pagination="No"
          onSortModelChange={handleSortModelChange}
          initialState={{ pinnedColumns: { left: ["counter", "vendor_name"] } }}
          columnHeaderHeight={250}
          hideFooter={true}
          CustomComponent2={Count}
          showCount="No"
          height="67vh"
        />
        {/* <div className="overflow-x-auto">
          <table className="min-w-full border border-gray-200">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-3 font-medium text-sm h-52 sticky left-0 z-10 bg-gray-100">#</th>
                <th className="p-3 font-medium text-sm h-52 sticky left-12 z-10 bg-gray-100">
                  Vendor Name
                </th>
                <th className="p-3 font-medium text-sm h-52 -rotate-180 testVertical border">
                  Total Jobs
                </th>
                {uniqueStatusArray?.map((item) => {
                  return (
                    <th className="p-3 font-medium text-sm h-52 -rotate-180 testVertical border">
                      {item}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {batchList?.map((record, index) => {
                const counter = index + 1;
                const { vendor, jobs, summary } = record;
                const { first_name: v_name = "", last_name: v_last = "" } =
                  vendor;
                return (
                  <tr className="text-sm text-black font-mon">
                    <td className="px-3 h-9 text-sm text-black sticky left-0 z-10 bg-white" key={index}>
                      {counter}
                    </td>
                    <td
                      className="px-3 h-9 text-sm text-black sticky left-12 z-10 bg-white border-r"
                      key={index}
                    >
                      {`${v_name} ${v_last}`}
                    </td>
                    <td className="px-3 h-9 text-sm text-black" key={index}>
                      {jobs?.length}
                    </td>
                    {uniqueStatusArray.map((column) => (
                      <td key={column} className="p-3 text-center text-black">
                        {summary[column] ? summary[column] : "-"}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div> */}
      </div>
    </>
  );
}

export default VendorJobsReport;
