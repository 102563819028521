import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createTask, getTaskByRole, getTaskByUser, getTasks, updateRoleTask, updateTask, updateUserTask } from "../services/tasksService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  tasks:[]
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Users Tasks
export const get_tasks = createAsyncThunk("get_tasks", async (thunkAPI) => {
  try {
    return await getTasks();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Create New Tasks
export const create_tasks = createAsyncThunk(
  "create_tasks",
  async (data, thunkAPI) => {
    try {
      return await createTask(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Roles
export const update_role_tasks = createAsyncThunk(
  "update_role_tasks",
  async (data, thunkAPI) => {
    try {
      return await updateRoleTask(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Update Task
export const update_tasks = createAsyncThunk(
  "update_tasks",
  async (data, thunkAPI) => {
    try {
      return await updateTask(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Task
export const get_task_by_user = createAsyncThunk(
  "get_task_by_user",
  async (id, thunkAPI) => {
    try {
      return await getTaskByUser(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Update Task
export const get_task_by_role = createAsyncThunk(
  "get_task_by_role",
  async (id, thunkAPI) => {
    try {
      return await getTaskByRole(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Update Roles
export const update_user_tasks = createAsyncThunk(
  "update_user_tasks",
  async (data, thunkAPI) => {
    try {
      return await updateUserTask(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const tasksSlice = createSlice({
  name: "userTasks",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_tasks.pending, (state) => {
        state.isLoading = true;
        state.record = [];
      })
      .addCase(get_tasks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_tasks.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
      })
      .addCase(create_tasks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_tasks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.push(action.payload.data);
      })
      .addCase(create_tasks.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_tasks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_tasks.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record[result] = {
          ...state.record[result],
          ...action.payload.data,
        };
      })
      .addCase(update_tasks.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(update_role_tasks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_role_tasks.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_role_tasks.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(update_user_tasks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_user_tasks.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_user_tasks.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_task_by_user.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_task_by_user.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_task_by_user.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_task_by_role.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_task_by_role.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_task_by_role.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { reset } = tasksSlice.actions;
export default tasksSlice.reducer;
