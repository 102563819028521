import dayjs from "dayjs";
import React from "react";
// import { toast } from "react-toastify";
import { Button } from "../../components";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";

const LeadJobListModal = ({
  onCancelForm,
  jobs,
  selectedData,
  setSelectedData,
  setIsModal,
  setJobList,
}) => {
  const modalRef = React.useRef(null);
  const [searchText, setSearchText] = React.useState("");
  // const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      onCancelForm();
    }
  };
  const onSelectAll = (checked, vendorId) => {
    if (checked) {
      const updatedArray = jobs
        ?.filter(({ vendor_invoice_id }) => !vendor_invoice_id)
        ?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (job) => {
    if (selectedData?.length > 0) {
      if (selectedData?.filter(({ _id }) => _id === job?._id).length > 0) {
        return true;
      }
    }
    return false;
  };
  const onSingleSelect = ({ event, checked, data }) => {
    try {
      if (checked) {
        if (selectedData?.length) {
          const firstVendorId = selectedData[0].vendor_id._id;
          if (firstVendorId !== data.vendor_id._id)
            return toast.error("Vendor are not the same as pre-selected jobs.");
        }
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            (job) => job?._id !== data?._id
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:116 ~ onSingleSelect ~ err:", err);
    }
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2 disabled:cursor-not-allowed`}
          checked={
            jobs?.length &&
            selectedData?.length &&
            selectedData?.length ===
              jobs?.filter(({ vendor_invoice_id }) => !vendor_invoice_id)
                ?.length
          }
          disabled={
            !jobs?.length ||
            !jobs?.filter(({ vendor_invoice_id }) => !vendor_invoice_id)?.length
          }
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) =>
        params.row.records.vendor_id &&
        !params.row.records.vendor_invoice_id ? (
          <input
            type="checkbox"
            checked={isSelected(params.row.records)}
            onChange={(e) =>
              onSingleSelect({
                checked: e.target.checked,
                data: params.row.records,
              })
            }
            className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          />
        ) : null,
      disableColumnMenu: true,
    },
    {
      field: "job_number",
      header: "Job Numbers",
      align: "left",
      minWidth: 150,
      renderCell: (params) => (
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`/jobs/update/${params.row._id}`}
            className={`text-[#1976D2] hover:underline`}
          >
            {params.row.job_number}
          </a>
          {params?.row.records?.vendor_invoice_id ? (
            <small className="text-red-600 ml-2 font-medium">
              Invoice Created
            </small>
          ) : null}
        </div>
      ),
    },
    {
      field: "industry_id",
      headerName: "Industry",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Service Cost",
      field: "service_cost",
      flex: 1,
      minWidth: 100,
      align: "center",
      type: "number",
      renderCell: (params) => (
        <span>{params.row.service_cost?.toFixed(2)}</span>
      ),
    },
    {
      field: "parts_charge",
      headerName: "Parts Charge",
      flex: 1,
      minWidth: 150,
      type: "number",
    },
    {
      field: "net_amount",
      headerName: "Net Amount",
      renderCell: (params) => (
        <span>
          {(params.row.service_cost - params.row.parts_charge)?.toFixed(2)}
        </span>
      ),
      flex: 1,
      minWidth: 150,
      type: "number",
    },
    {
      headerName: "Tech %",
      field: "tech_percentage",
      flex: 1,
      minWidth: 100,
      align: "center",
      renderCell: (params) => {
        return (
          <span>
            {params.row.service_cost === 0 && params.row.tech_fee === 0
              ? 0?.toFixed(2)
              : (
                  (params.row.tech_fee /
                    (params.row.service_cost - params.row.parts_charge)) *
                  100
                )?.toFixed(2)}
          </span>
        );
      },
    },
    {
      headerName: "Tech Share",
      field: "tech_fee",
      flex: 1,
      minWidth: 100,
      align: "center",
      renderCell: (params) => {
        return params.rowNode.type === "group" ? (
          <span className="font-bold">
            {params.row.vendor_profit?.toFixed(2)}
          </span>
        ) : (
          <span>{params.row.tech_fee?.toFixed(2)}</span>
        );
      },
    },
    {
      headerName: "Company Share",
      field: "company_profit",
      flex: 1,
      minWidth: 130,
      type: "number",
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <span className="font-bold">{params.row.vendor_owe?.toFixed(2)}</span>
        ) : (
          <span>
            {(
              Math.round(
                (params.row.service_cost - params.row.parts_charge) * 100 -
                  params.row.tech_fee * 100
              ) / 100
            )?.toFixed(2)}
          </span>
        ),
    },
    {
      headerName: "Invoice Created",
      field: "vendor_invoice_id",
      flex: 1,
      type: "singleSelect",
      minWidth: 120,
      align: "center",
      disableColumnMenu: true,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        vendor_invoice_id: value?.value,
      }),
      filterOperators: [
        {
          label: "Yes",
          value: "!=",
          getApplyFilterFn: (filterItem) => {
            // Assuming vendor_invoice_id is stored as 1 for "Yes" and 0 for "No"
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
        {
          label: "No",
          value: "=",
          getApplyFilterFn: (filterItem) => {
            // Assuming vendor_invoice_id is stored as 1 for "Yes" and 0 for "No"
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
      ],
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Created At",
      field: "createdAt",
      flex: 1,
      minWidth: 120,
      sortable: true,
      valueGetter: (params) => new Date(params.row.createdAt),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
    },
    { headerName: "Status", field: "job_status_id" },
    {
      headerName: "Scheduled On",
      field: "scheduled_on",
      sortable: true,
      valueGetter: (params) => new Date(params.row.scheduled_on),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
    },
    {
      headerName: "Closed",
      field: "is_closed",
      renderCell: (params) =>
        params.rowNode.type === "group" ? null : <span>{params.value}</span>,
      disableColumnMenu: true,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        is_closed: value?.value,
      }),
      filterOperators: [
        {
          label: "Yes",
          value: true,
          getApplyFilterFn: (filterItem) => {
            // Assuming vendor_invoice_id is stored as 1 for "Yes" and 0 for "No"
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
        {
          label: "No",
          value: false,
          getApplyFilterFn: (filterItem) => {
            // Assuming vendor_invoice_id is stored as 1 for "Yes" and 0 for "No"
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
      ],
    },
    {
      headerName: "Closed At",
      field: "closed_date",
      flex: 1,
      minWidth: 120,
      sortable: false,
      valueGetter: (params) => new Date(params.row.closed_date),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
    },
    // { headerName: "Industry", field: "industry_id", flex: 1, minWidth: 100 },
    {
      headerName: "Client Type",
      field: "payment_type",
      flex: 1,
      minWidth: 120,
    },
  ];
  // const handleClickOutside = (event) => {
  //   if (modalRef.current && !modalRef.current.contains(event.target)) {
  //     onCancelForm();
  //   }
  // };
  document.addEventListener("keydown", handleHideDropdown, true);
  // document.addEventListener("click", handleClickOutside, true);
  const searchedRecord = jobs?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const sortedData = [...(searchedRecord || [])]?.sort((a, b) => {
    // Parsing dates for comparison
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);

    // Comparing parsed dates
    return dateB - dateA;
  });

  return (
    <>
      <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
        <div className="container-fluid mt-2  text-center">
          <div
            className="inline-block bg-white w-3/4 my-0 mx-auto p-4 bg-red-600 rounded  max-h-[87vh]"
            ref={modalRef}
          >
            <header className="flex items-center justify-center shadow-lg bg-primary-100 text-white h-[50px] mb-3 text-xl">
              <h1 className="text-2xl text-white m-4 font-medium">
                Job Listing for{" "}
                {`(${jobs[0]?.vendor_id?.first_name || ""} ${
                  jobs[0]?.vendor_id?.last_name || ""
                })`}
              </h1>
            </header>
            <div className="flex flex-row justify-between">
              <input
                placeholder="Search"
                onChange={(e) => setSearchText(e.target.value)}
                className="!w-1/3 mb-2 placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md !py-2 px-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                value={searchText}
              />
              <span className="text-xl">
                {searchedRecord?.length} records found
              </span>
            </div>

            <div className="h-[50vh] mb-2">
              <MUIDataTable
                items={sortedData?.map((item, index) => {
                  const {
                    _id,
                    job_number,
                    createdBy,
                    createdAt,
                    complete_pickup_address,
                    job_status_id,
                    industry_id,
                    is_closed,
                    tech_fee = 0,
                    goa_price = 0,
                    refund_amount = 0,
                    company_profit = 0,
                    service_cost = 0,
                    vendor_invoice_id,
                    scheduled_on,
                    closed_date,
                    payment_type,
                    vendor_id,
                    parts_charge,
                  } = item;
                  const tech_percentage  = vendor_id?.tech_percentage||0;
                  return {
                    _id,
                    // counter: index + 1,
                    records: { ...item, _id: _id },
                    job_number,
                    parts_charge,
                    createdBy: createdBy?.username,
                    job_status_id: job_status_id?.name,
                    createdAt: dayjs(createdAt).format(
                      "ddd, MMM D, YYYY h:mm A"
                    ),
                    industry_id: industry_id?.name,
                    complete_pickup_address,
                    company_profit: company_profit
                      ? company_profit?.toFixed(2)
                      : 0,
                    is_closed: is_closed ? "Yes" : "No",
                    payment_type,
                    refund_amount: refund_amount ? refund_amount : 0,
                    tech_fee: tech_fee ? tech_fee : 0,
                    service_cost: service_cost ? service_cost : 0,
                    goa_price: goa_price ? goa_price : 0,
                    closed_date: closed_date
                      ? dayjs(closed_date).format("ddd, MMM D, YYYY h:mm A")
                      : "",
                    scheduled_on: scheduled_on
                      ? dayjs(scheduled_on).format("ddd, MMM D, YYYY h:mm A")
                      : "",
                    vendor_invoice_id: vendor_invoice_id ? "Yes" : "No",
                    tech_percentage,
                  };
                })}
                columnDefs={columnDefs}
                pagination={"No"}
                searchable="No"
                showCount="No"
                toolbar="No"
                height="46vh"
              />
            </div>
            <div className="w-full flex justify-end items-center">
              <Button
                text="Cancel"
                variant="btn_cancel"
                className="ml-3"
                onClick={onCancelForm}
              />
              {selectedData?.length ? (
                <Button
                  text="Create Invoice"
                  variant="btn_submit"
                  className="ml-3"
                  onClick={() => {
                    if (selectedData?.length) {
                      setIsModal(true);
                      setJobList([]);
                    } else {
                      toast.error("No any job selected");
                    }
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadJobListModal;
