import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export default async function exportVendorBalanceReport(data, headers, fileName) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Vendor Report");
  // Add headers (main headers)
  worksheet.addRow(headers);
  // Add data rows with child headers
  data.forEach((item) => {
    const { jobs, ...parentData } = item;
    // Add an empty row for separation
    const emptyRow = worksheet.addRow([]);
    const rowIndex = emptyRow.number;

    // Merge cells in the empty row
    worksheet.mergeCells(`A${rowIndex}:I${rowIndex}`); // Adjust "L" if you have more columns

    // Style the merged cell
    const mergedCell = worksheet.getCell(`A${rowIndex}`);
    // mergedCell.value = `Job List`;
    mergedCell.font = { bold: true, size: 14 };
    mergedCell.alignment = { vertical: "middle", horizontal: "center" };
    // Add parent row
    const parentRow = worksheet.addRow(Object.values(parentData));
    headers.forEach((header, index) => {
      const cell = worksheet.getRow(1).getCell(index + 1)
      cell.font = { bold: true }
      cell.alignment = { vertical: "middle", horizontal: index < 2 ? "left" : "right" };
  })
    // Apply styles to the parent row
    parentRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: "000000" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "f5f5f5" },
      };
      cell.alignment = { vertical: "middle", horizontal: colNumber < 3 ? "left" : "right" };
    });
    // Add product (child) rows
    jobs.forEach((product) => {
      const productData = [
        "",
        product.job_number,
        product.service_cost,
        product.pending_invoiced,
        product.total_invoiced,
        product.parts_charge,
        product.tech_percent,
        product.tech_fee,
        product.company_profit,
      ];
      const productRow = worksheet.addRow(productData);

      // Apply alignment for job rows
      productRow.eachCell((cell, colNumber) => {
        cell.alignment = { vertical: "middle", horizontal: colNumber < 3 ? "left" : "right" };
      });
    });
  });

  // Write to buffer
  const buffer = await workbook.xlsx.writeBuffer();

  // Save the file
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, `${fileName}.xlsx`);
}
