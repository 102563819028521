import React, { useEffect } from "react";
import { Button } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { get_api_report } from "../../features/reportsSlice";
import ReportHead from "../Components/Header";
import Select from "react-select";
import { get_users, get_users_drd } from "../../features/usersSlice";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import dayjs from "dayjs";
import { get_merchants } from "../../features/merchantSlice";

const APIsReport = () => {
  const dispatch = useDispatch();
  const { usersDrd } = useSelector((state) => state.users);
  const { record } = useSelector((state) => state.merchant);
  const { isLoading, apiReport } = useSelector((state) => state.reports);
  const [searchText, setSearchText] = React.useState("");
  const initialFilters = {
    user_id: "",
    merchant_id: "",
  };
  const [filters, setFilters] = React.useState(initialFilters);
  useEffect(() => {
    dispatch(get_merchants());
    dispatch(get_users_drd());
    dispatch(get_api_report(filters));
    // eslint-disable-next-line
  }, []);

  const columnDefs = [
    { headerName: "#", field: "counter" },
    { headerName: "Merchant", field: "merchant_id", flex: 1 },
    { headerName: "UserName", field: "username", flex: 1 },
    { headerName: "Request Path", field: "request_path", flex: 1 },
    { headerName: "In Time", field: "in_time", flex: 1 },
    { headerName: "Out Time", field: "out_time", flex: 1 },
    { headerName: "Response Time", field: "response_time", flex: 1 },
  ];
  useEffect(() => {
    dispatch(get_users());
    // eslint-disable-next-line
  }, []);
  const style = {
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#ffe7c7",
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused || state.hover ? "#a9a9a9" : null,
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 1000,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_api_report({
        ...filters,
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  const apiReportFilter = apiReport?.result?.filter((e) => {
    const { user_id, lead_id, createdAt } = e;
    const searchTextLowerCase = searchText?.toLowerCase();

    const containsSearchText = Object?.values(user_id ? user_id : {})
      ?.concat(Object?.values(lead_id ? lead_id : {}))
      ?.some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase()?.includes(searchTextLowerCase);
        }
        return false;
      });

    return containsSearchText || createdAt?.includes(searchText);
  });
  return (
    <>
      <div className="container-fluid">
        <ReportHead route="Reports > Last Touch Report" heading="Login IP" />
        <div className="w-full">
          <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
            <div className="md:col-span-3 !px-0.5 !mb-3">
              <Select
                styles={style}
                placeholder="Users"
                options={usersDrd}
                getOptionLabel={(options) => options?.first_name}
                getOptionValue={(options) => options?._id}
                onChange={(e) => setFilters({ ...filters, user_id: e._id })}
                className="w-full"
                value={
                  filters.user_id
                    ? {
                        _id: filters.user_id,
                        first_name: usersDrd?.find(
                          ({ _id }) => _id === filters.user_id
                        )?.first_name,
                      }
                    : null
                }
              />
            </div>
            <div className="md:col-span-3 !px-0.5 !mb-3">
              <Select
                styles={style}
                placeholder="Select Merchant"
                options={record?.data}
                getOptionLabel={(options) => options.name}
                getOptionValue={(options) => options._id}
                onChange={(e) => setFilters({ ...filters, merchant_id: e._id })}
                className="w-full"
                value={
                  filters.merchant_id
                    ? {
                        _id: filters.merchant_id,
                        name: record?.data?.find(
                          ({ _id }) => _id === filters.merchant_id
                        )?.name,
                      }
                    : null
                }
              />
            </div>

            <div className="md:col-span-1 !px-0.5 !mb-3">
              <Button
                text="Search"
                variant="btn_submit"
                onClick={() => dispatch(get_api_report(filters))}
              />
            </div>
            <div className="md:col-span-1 !px-0.5 !mb-3">
              <Button
                text="Reset"
                variant="btn_cancel"
                onClick={() => {
                  setFilters(initialFilters);
                  dispatch(get_api_report(initialFilters));
                }}
              />
            </div>
          </div>
        </div>

        <div className="bg-white my-3 border rounded">
          <MUIDataTable
            columnDefs={columnDefs}
            items={apiReportFilter?.map((item, index) => {
              let counter = index + 1;
              let records = item;
              let record_id = item.id;
              let merchant_id = record?.records?.find(
                ({ _id }) => _id === item?.merchant_id
              )?.name;
              let in_time = dayjs(item?.in_time).format(
                "ddd, MMM D, YYYY h:mm:ss A"
              );
              const out_time = dayjs(item?.out_time).format(
                "ddd, MMM D, YYYY h:mm:ss A"
              );
              const { request_path, username, response_time } = item;
              return {
                counter,
                records,
                record_id,
                in_time,
                username,
                merchant_id,
                out_time,
                request_path,
                response_time,
              };
            })}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            totalItems={apiReport.totalItems}
            searchText={searchText}
            setSearchText={setSearchText}
            paginationModel={paginationModel}
            isLoading={isLoading}
          />
        </div>
        <div className="d-none"></div>
      </div>
    </>
  );
};

export default APIsReport;
