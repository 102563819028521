import React from "react";
import "./style.css";

const ToggleSwitch = ({ label, onChange, checked, unique_by, defaultChecked }) => {
  return (
    <div className="containe !justify-start">
      {label}{" "}
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="checkbox"
          name={label}
          id={unique_by}
          onChange={onChange}
          checked={checked}
          defaultChecked={defaultChecked}
        />
        <label className="label" htmlFor={unique_by}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
