import React from "react";
import CustomModal from "../../components/molecules/Modal/customModal";

function IndustryShowModal({ onCancelForm, industryData, industryDrd }) {
  return (
    <CustomModal
      multiple
      handleModal={onCancelForm}
      cancelButtonRef={onCancelForm}
      className="max-w-5xl"
      modalType={true}
      title={"Industry Details"}
      hideButton
    >
      <div class="-m-1.5 overflow-auto">
        <div class="p-1.5 min-w-full inline-block align-middle max-h-96 overflow-y-auto">
          <div class="overflow-hidden">
            <div class="table border-collapse table-auto w-full divide-y divide-gray-200 dark:divide-neutral-700">
              <div class="table-header-group bg-gray-100">
                <div class="table-row">
                  <div class="table-cell px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                    Industry
                  </div>
                  <div class="table-cell px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                    Port
                  </div>
                  <div class="table-cell px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                    Default Domain
                  </div>
                  <div class="table-cell px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                    Domain Count
                  </div>
                </div>
              </div>
              {industryData?.industry_detail?.length ? (
                industryData.industry_detail.map(
                  ({ industry_id, name, port, default_domain, domain_count }) => {
                    const industry_name =
                      name ||
                      industryDrd.find((option) => option._id === industry_id)
                        ?.name;

                    return (
                      <div
                        className="table-row-group divide-y divide-gray-200 bg-white dark:divide-neutral-700 dark:bg-neutral-800"
                        key={industry_id}
                      >
                        <div className="table-row">
                          <div className="table-cell px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200">
                            {industry_name}
                          </div>
                          <div className="table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                            {port}
                          </div>
                          <div className="table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`https://${default_domain}`}
                              className="text-black no-underline hover:underline cursor-poi"
                            >
                              {default_domain}
                            </a>
                          </div>
                          <div className="table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                            {domain_count}
                          </div>
                        </div>
                      </div>
                    );
                  }
                )
              ) : (
                <div className="table-row-group divide-y divide-gray-200 bg-white dark:divide-neutral-700 dark:bg-neutral-800">
                  <div className="table-row">
                    <div className="table-cell px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200"></div>
                    <div className="table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200 text-center font-semibold">
                      No Records.
                    </div>
                    <div className="table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
}

export default IndustryShowModal;
