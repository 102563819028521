import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddUpdateDomainRunners from "./AddUpdateDomainRunners";
import { FaEdit, FaInfoCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import api from "../../services/api";
import { get_industry_drd } from "../../features/IndustrySlice";
import { get_domain_runners } from "../../features/domainCenterSlice";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import PageHeader from "../../components/molecules/PageHeader";
import { DotsLoader } from "../../components";
import IndustryShowModal from "./IndustryShowModal";
import { FaSync } from "react-icons/fa";
import TokenModal from "./TokenModal";
function DomainRunner() {
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchInput, setSearchInput] = React.useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setIsloading] = useState(false);
  const [idsArray, setIdsArray] = useState([]);
  const [industryData, showIndustryData] = useState(null);
  const dispatch = useDispatch();
  const { industryDrd } = useSelector((state) => state.industry);
  const [isTokenModal, setTokenModel] = React.useState({
    status: false,
    data: {},
  });
  const { isLoading, domainRunner } = useSelector(
    (state) => state.domainCenter
  );
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  useEffect(() => {
    dispatch(get_industry_drd());
    dispatch(
      get_domain_runners({
        size: paginationModel.pageSize,
        page: 1,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setTokenModel((prevState) => ({
      ...prevState,
      status: false,
      data: {},
    }));
  };

  const formSubmit = () => {
    dispatch(
      get_domain_runners({
        size: paginationModel.pageSize,
        page: 1,
      })
    );
    setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const setup_runners = async (id) => {
    const c = window.confirm(`Are you sure want to setup the runner?`);
    if (!c) return;
    setIsloading(true);
    try {
      const res = await api.post(
        `/api/domain_runners/runner_setup_process/${id}`
      );
      if (res.status === 200) {
        toast.success("Runner setup successfully");
      } else {
        toast.error(`Runner couldn't be setup`);
      }
      setIsloading(false);
    } catch (err) {
      console.log(
        "🚀 ~ file: index.jsx:42 ~ constsetup_runners=async ~ err:",
        err
      );
      setIsloading(false);
      toast.error(`Runner couldn't be setup`);
    }
  };

  const handleIndustry = (params) => {
    showIndustryData(params);
  };
  const TagsCellRenderer = (data) => {
    const values = idsArray?.includes(data?.row?._id)
      ? data?.value
      : data?.value?.slice(0, 1);
    const tags = values?.map((tag, index) => (
      <span
        key={tag.id}
        style={{
          margin: "2px",
          color: "black",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
          // backgroundColor:"#f5f5f5"
        }}
      >
        {index + 1}. {industryDrd.find((option) => option._id === tag)?.name} (
        <span
          className="text-blue-700 cursor-pointer"
          onClick={() => handleOpenTab(tag)}
        >
          {
            data?.row?.industry_detail.find((item) => item.industry_id === tag)
              ?.domain_count
          }
        </span>
        )
      </span>
    ));

    const handleOpenTab = (domain) => {
      const url = new URL("/domain_center/domains", window.location.origin);
      url.searchParams.append("industry_id", domain);
      const tabName = `domainCenterTab`;
      let existingTab = window.open("", tabName);
      if (!existingTab || existingTab.closed) {
        existingTab = window.open(url, tabName);
      } else {
        existingTab.location.href = url;
        existingTab.focus();
      }
    };
    return (
      <div className="flex flex-col">
        <div className="flex flex-col w-auto">{tags}</div>
        {data?.value?.length > 1 ? (
          !idsArray?.includes(data?.row?._id) ? (
            <div
              className="flex flex-col w-auto ml-3 text-blue-600 cursor-pointer"
              onClick={() => setIdsArray([...idsArray, data?.row?._id])}
            >
              {`See More (${data?.value?.length - 1})`}
            </div>
          ) : (
            <div
              className="flex flex-col w-auto ml-3 text-blue-600 cursor-pointer"
              onClick={() =>
                setIdsArray((preArray) => {
                  if (!preArray || !data?.row?._id) return preArray;
                  return preArray.filter((id) => id !== data.row._id);
                })
              }
            >
              {`See Less`}
            </div>
          )
        ) : null}
      </div>
    );
  };
  const columnDefs = [
    // Define your columns based on the structure of your domain runners
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "IP", field: "ip", flex: 1 },
    { headerName: "Runner Name", field: "runner_name", flex: 1 },
    {
      headerName: "LB Server File",
      field: "lb_server_file",
      flex: 1,
      renderCell: (params) => (
        <div className="flex flex-row items-center pl-1">
          {params?.row?.lb_server_file}
          {params?.row?.lb_server_ip ? (
            <FaSync
              onClick={() => setDomainOnLbsServer(params)}
              title="Set Domain on LBS Server"
              size={15}
              className="ml-2 cursor-pointer"
            />
          ) : null}
        </div>
      ),
    },
    { headerName: "LB Server IP", field: "lb_server_ip", flex: 1 },
    { headerName: "Total Domain Count", field: "total_domain_count", flex: 1 },
    {
      field: "industry_detail",
      column: "industry_detail",
      headerName: "Industry",
      type: "singleSelect",
      flex: 1,
      minWidth: 220,
      filterable: false,
      renderCell: TagsCellRenderer,
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) => value.name,
      valueOptions: industryDrd,
      valueGetter: (params) => {
        if (params.row?.industry_detail) {
          return params.row.industry_detail.map((detail) => detail.industry_id);
        }
        return [];
      },
      valueFormatter: (params) => {
        if (!params.value || params.value.length === 0) {
          return "";
        }
        const industryNames = params.value
          .map((id) => {
            const industry = industryDrd.find((ind) => ind._id === id);
            return industry ? industry.name : null;
          })
          .filter((name) => name);
        return industryNames.join(", ");
      },
    },
    {
      field: "actions",
      filterable: false,
      renderCell: (params) => (
        <div className="flex items-center w-full">
          <FaInfoCircle
            onClick={(e) => handleIndustry(params.row.records)}
            className="my_navIcon cursor-pointer"
            size={20}
          />
          <FaEdit
            onClick={openFormHandler(params.row.records)}
            className="my_navIcon cursor-pointer"
            size={20}
          />
        </div>
      ),
      width: 100,
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 10,
      },
    },
  ];
  const data = domainRunner?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_domain_runners({
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    const items = data?.industry_detail;
    // const items = data?.recipients ? JSON.parse(data?.recipients) : [];
    const numberOfLines = items?.length ? items?.length : 0;
    const addressHeight = rowHeight + numberOfLines * 20;
    if (data?.industry_detail && data?.industry_detail.length > 1) {
      if (!idsArray?.includes(data?._id)) return rowHeight + 15;
      const tagHeight = 25; // height of each tag
      const numTags = data?.industry_detail.length;
      if (addressHeight > rowHeight + tagHeight * numTags + cellContentHeight) {
        return addressHeight;
      } else if (idsArray?.includes(data?._id)) {
        return rowHeight + tagHeight * numTags + cellContentHeight;
      } else {
        return rowHeight + tagHeight * numTags + cellContentHeight;
      }
    }
    if (addressHeight > rowHeight + cellContentHeight) {
      return addressHeight;
    } else {
      return rowHeight + cellContentHeight;
    }
  };

  const setDomainOnLbsServer = async (params) => {
    setIsloading(true);
    try {
      const res = await api.get(
        `/api/domain_runners/get_lbfile_update_token/${params?.row?.records?._id}`
      );

      if (res.status === 200 || res.status === 201) {
        toast.success(res?.data?.message);
        setTokenModel((prevState) => ({
          ...prevState,
          status: true,
          data: params?.row?.records,
        }));
      } else {
        toast.error(res?.data?.message || "Something went wrong!");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || "Something went wrong!");
      console.error(err);
    } finally {
      setIsloading(false);
    }
  };
  const tokenSubmit = () => {
    setTokenModel((prevState) => ({
      ...prevState,
      status: false,
      data: {},
    }));
    dispatch(
      get_domain_runners({
        size: paginationModel.pageSize,
        page: 1,
      })
    );
  };
  return (
    <>
      {isEditing && (
        <AddUpdateDomainRunners
          editingRecord={editingRecord}
          modalTitle="Add Domain Runner"
          onCancelForm={cancelFormHandler}
          formSubmit={formSubmit}
        />
      )}

      {isTokenModal?.status ? (
        <TokenModal
          modalTitle={"Token to generate LB server file"}
          onCancelForm={cancelFormHandler}
          selectedData={isTokenModal?.data}
          tokenSubmit={tokenSubmit}
        />
      ) : null}
      {isLoading || loading ? <DotsLoader text="Please Wait" /> : null}
      <PageHeader
        heading="Domain Runners"
        onClick={openFormHandler(0)}
        isAllowed={true}
      />
      {industryData ? (
        <IndustryShowModal
          onCancelForm={() => showIndustryData(null)}
          industryData={industryData}
          industryDrd={industryDrd}
        />
      ) : null}
      {!isLoading && (
        <div className="bg-white mt-3 border rounded">
          <MUIDataTable
            columnDefs={columnDefs}
            items={
              data
                ? data.map((runner, index) => ({
                    ...runner,
                    records: { ...runner },
                    counter: offset + index + 1,
                    // Additional formatting if needed
                  }))
                : []
            }
            isLoading={isLoading}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            pagination="Yes"
            totalItems={domainRunner?.totalItems}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            gridOptions={getRowHeight}
          />
        </div>
      )}
    </>
  );
}

export default DomainRunner;
