import React, { Fragment, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../atoms/Button";
import { IoCloseCircle } from "react-icons/io5";
function useVisible(onCancelModal, multiple) {
  const modalRef = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape" && !multiple) {
      onCancelModal();
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target) && !multiple) {
      onCancelModal();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCancelModal]);

  return { modalRef };
}
export default function Modal({
  title,
  open,
  cancelButtonRef,
  handleModal,
  isLoading,
  children,
  handleModalSubmit,
  className,
  modalType,
  modalFormStep,
  HeaderButton,
  hideCloseIcon,
  onSubmit,
  hideButton,
  multiple,
}) {
  const { modalRef } = useVisible(handleModal,multiple);
  return (
    <Transition.Root show={!open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={handleModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-400 dark:bg-gray-600/70 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-900 text-left shadow-xl transition-all my-8 w-full ${className}`}
                ref={modalRef}
              >
                <button
                  type="button"
                  className="absolute top-1 right-0 p-2 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-100"
                  onClick={handleModal}
                  aria-label="Close"
                >
                  <IoCloseCircle className="w-8 h-8" />
                </button>
                <form onSubmit={handleModalSubmit} className="w-full p-7">
                  {title && (
                    <div className="w-full  items-center gap-5 justify-between mb-4">
                      <header className="bg-primary-100 h-[60px] flex items-center justify-center shadow-lg">
                        <h1 className=" text-2xl text-white m-4 font-medium">
                          {title}
                        </h1>
                      </header>
                      <div className="flex items-center justify-end w-full md:w-auto">
                        {modalFormStep === 1 && (
                          <>{HeaderButton && <HeaderButton />}</>
                        )}
                      </div>
                    </div>
                  )}
                  {children}
                  {(modalType && !hideButton )&& (
                    <>
                      {modalType === "delete" ? (
                        <div className="flex items-cente justify-end mt-4 space-x-2 text-white">
                          <button
                            type="button"
                            className="btnPrimary "
                            onClick={handleModal}
                          >
                            Cancel
                          </button>

                          {isLoading ? (
                            <div className="bg-blue/10 flex items-center justify-center rounded-md py-2 w-48">
                              <img
                                src="/img/loading.gif"
                                className="w-7"
                                alt=""
                              />
                            </div>
                          ) : (
                            <button
                              type="submit"
                              className="btnPrimary bg-red-600"
                            >
                              Delete
                            </button>
                          )}
                        </div>
                      ) : (
                        <div className="flex items-cente justify-end mt-4 space-x-2 text-white">
                          <Button
                            text="Cancel"
                            className=""
                            onClick={handleModal}
                            variant="btn_cancel"
                          />
                          {isLoading ? (
                            <div className="bg-blue/10 flex items-center justify-center rounded-md py-2 w-48">
                              <img
                                src="/img/loading.gif"
                                className="w-7"
                                alt=""
                              />
                            </div>
                          ) : (
                            <Button
                              text={"Submit"}
                              className="mx-2"
                              onClick={onSubmit}
                              isLoading={isLoading}
                              variant="btn_submit"
                            />
                          )}
                        </div>
                      )}
                    </>
                  )}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
