import React, { useState } from "react";
import api from "../../services/api";
// import { InputField } from "../../components";
// import { XMarkIcon } from "@heroicons/react/24/outline";
// import { PlusIcon } from "@heroicons/react/24/solid";
import { RxCross2 } from "react-icons/rx";
import { FaPlus } from "react-icons/fa";
import TextInput from "../../components/atoms/TextInput";
import { Button } from "../../components";

export default function AddUpdatePermission({
  permission,
  setPermission,
  subPermissions,
  setSubPermissions,
  getPermissions,
  permission_id,
  setIsLoading,
  onClose,
}) {
  const [subPermission, setSubPermission] = useState("");
  const [isSubPermission, setIsSubPermission] = useState("");
  const [subPermissionValue, setSubPermissionValue] = useState("");
  const addNewPermission = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await api
      .post(`/api/permissions`, {
        permission_id,
        name: permission,
        sub_permissions: subPermissions,
      })
      .then((res) => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
    getPermissions();
    setPermission("");
    setSubPermissions([]);
    onClose();
  };

  const addSubPermission = (e) => {
    e.preventDefault();
    setSubPermissions((prevState) => [
      ...prevState,
      { name: subPermission, sub_permissions: null },
    ]);
    setSubPermission("");
  };
  const addSubPermissions = (e) => {
    e.preventDefault();
    setSubPermissions((prevState) => {
      const updatedState = prevState.map((permission) => {
        if (permission.name === isSubPermission) {
          // If the name matches, add another object in sub_permissions
          return {
            ...permission,
            sub_permissions: [
              ...(permission.sub_permissions || []), // Ensure sub_permissions is an array
              { name: subPermissionValue, sub_permissions: null },
            ],
          };
        }
        return permission;
      });
      return updatedState;
    });
    setIsSubPermission(null);
    setSubPermissionValue("");
  };
  const removeSubPermission = (index) => {
    setSubPermissions((prevState) => [
      ...prevState.slice(0, index),
      ...prevState.slice(index + 1),
    ]);
  };
  const handleRemoveSubPermission = (permissionName, subPermissionToRemove) => {
    setSubPermissions((prevState) => {
      return prevState.map((permission) => {
        if (permission.name === permissionName) {
          // If the permission name matches, filter out the sub-permission to remove
          const updatedSubPermissions = (
            permission.sub_permissions || []
          ).filter(
            (subPermission) => subPermission.name !== subPermissionToRemove
          );
          return {
            ...permission,
            sub_permissions: updatedSubPermissions,
          };
        }
        return permission;
      });
    });
  };
  return (
    <div>
      <div className="flex flex-col md:flex-row items-center justify-between mb-3">
        <h3 className="text-lg lg:text-xl font-bold">Add New Permsission</h3>
        <div className="flex items-center justify-end">
          <button
            onClick={onClose}
            className="mx-2 py-1.5 !px-3 align-middle !bg-menu border-menu !text-white font-semibold rounded-none"
          >
            Cancel
          </button>
          <Button
            onClick={addNewPermission}
            variant={"btn_submit"}
            text=" Save & Update"
          />
        </div>
      </div>
      <div className="p-7 rounded-lg bg-white border">
        <TextInput
          name="permissionName"
          label="Permission Name"
          placeholder="Enter Permission Name"
          value={permission}
          onChange={(e) => setPermission(e.target.value)}
          className={"inputField"}
        />
        <div className="flex items-center mt-3 gap-3">
          <div className="w-full">
            <TextInput
              name="subPermission"
              label="Sub Permission"
              placeholder="Sub permission"
              className="inputField"
              value={subPermission}
              onChange={(e) => setSubPermission(e.target.value)}
            />
          </div>
          <button
            disabled={!subPermission}
            className="w-7 h-7 cursor-pointer rounded-full bg-neutral-300 flex items-center justify-center text-xs"
            onClick={addSubPermission}
          >
            <FaPlus />
          </button>
        </div>

        <div className="flex flex-col gap-1 mt-3">
          {subPermissions.map((item, index) => (
            <div className="flex flex-col">
              <div
                key={index}
                className="flex items-center justify-between border-b py-1"
              >
                <p>{item.name}</p>
                <div className="flex gap-3">
                  <button
                    // disabled={subPermission}
                    className="w-7 h-7 cursor-pointer rounded-full bg-neutral-300 flex items-center justify-center text-xs"
                    onClick={() => setIsSubPermission(item.name)}
                  >
                    <FaPlus />
                  </button>
                  <button
                    className="w-7 h-7 border border-red-400 rounded-full text-red-400 cursor-pointer flex items-center justify-center text-xs"
                    onClick={() => removeSubPermission(index)}
                  >
                    <RxCross2 />
                  </button>
                </div>
              </div>
              {isSubPermission === item.name ? (
                <div className="flex items-end gap-3">
                  <TextInput
                    name="subPermissionValue"
                    label={`Sub Permission inside ${item.name}`}
                    placeholder="Sub permission"
                    className="mt-5 flex-1 border inputField"
                    value={subPermissionValue}
                    onChange={(e) => setSubPermissionValue(e.target.value)}
                  />
                  <button
                    disabled={!subPermissionValue}
                    className="border-0 text-base border-0"
                    onClick={addSubPermissions}
                  >
                    Add
                  </button>
                </div>
              ) : null}

              {(item?.sub_permissions || [])?.map((subItem, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between border-b ml-4"
                >
                  <p>{subItem.name}</p>
                  <div className="flex gap-3">
                    {/* <button
                      className="flex text-xs gap-0.5 cursor-pointer"
                      onClick={() => setIsSubPermission(item.name)}
                    >
                      <PlusIcon className="w-4 text-primary" />
                      Add
                    </button> */}
                    <RxCross2
                      className="w-4 text-red-400 cursor-pointer"
                      onClick={() =>
                        handleRemoveSubPermission(item?.name, subItem?.name)
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
