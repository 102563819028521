import React from "react";
import { logo_towingwiz, menu } from "../../../images";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../atoms/Button";
import { FaSearch, FaTimes } from "react-icons/fa";
import Select from "react-select";
import { useState } from "react";
import { header_search } from "../../../features/jobsSlice";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../atoms/Loader";
import { toast } from "react-toastify";
import { get_dispo_drd, get_pause_drd } from "../../../features/campaignSlice";
import DialerHeader from "./DialerHeader";
import { logo_logical } from "../../../images";
const Header = (props) => {
  const navigate = useNavigate();
  const initialFilter = {
    searchBy: "",
    searchValue: "",
  };
  const dispatch = useDispatch();
  const [searchJobs, setSearchJobs] = useState(initialFilter);
  const [menuToggle, setMenuToggle] = useState(false);
  const { status, campaign, ext } = useSelector((state) => state.dialer);
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const { loader } = useSelector((state) => state.jobs);
  const style = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      minWidth: "180px",
      maxHeight: "31px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "31px",
      boxShadow: state.isFocused ? "none" : "none",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "31px",
    }),
  };
  const [isActive, setActive] = useState(false);

  const ToggleClass = () => {
    setActive(!isActive);
  };
  const searchJob = async () => {
    try {
      const res = await dispatch(header_search(searchJobs));
      if (res.payload?.status === 200) {
        if (res.payload?.data?.length === 1) {
          navigate(`/jobs/update/${res.payload?.data[0]?._id}`);
        }
        if (res.payload?.data?.length > 1) {
          navigate(
            `/jobs?searchBy=${searchJobs.searchBy}&searchValue=${searchJobs.searchValue}`
          );
        }
        if (res.payload?.data?.length === 0) {
          toast.info("No record found");
        }
      }
    } catch (err) {}
  };

  React.useEffect(() => {
    if (campaign) {
      dispatch(get_dispo_drd(campaign));
      dispatch(get_pause_drd(campaign));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);
  const menuToggler = () => {
    setMenuToggle(!menuToggle);
    return props.toggleNavbar(!props.show_nav);
  };
  const hostname = window.location.hostname === "towingwiz.com";
  return (
    <>
      <header
        className={`flex flex-col w-full  bg-[#f7f7f7] top-0  border-b border-[#d6dadc] fixed z-[99] justify-between transition-all ease-in-out delay-150 
      ${
        !menuToggle
          ? "md:w-[calc(100%-235px)] ml-0 md:!ml-[235px]"
          : "ml-[235px] md:w-100% md:ml-0"
      }`}
      >
        <div className="flex flex-row w-full justify-between items-center px-2 md:px-0 flex-wrap md:flex-nowrap">
          <div className="flex flex-row items-center w-ful gap-x-2 px-3">
            {/* <div className="sm:block md:block cursor-pointer">
              <img
                src={menu}
                alt="burgur"
                className="brightness-0"
                onClick={() => props.toggleNavbar(!props.show_mobilenav)}
              />
            </div>*/}
            <div className="sm:block md:block cursor-pointer">
              <img
                src={menu}
                alt="burgur"
                className="brightness-0"
                onClick={menuToggler}
              />
            </div>
            <h3 className="hidden md:block font-medium text-[#5f5f5f] text-[19px] mb2 w-auto mb-0">
              {`Welcome ${user?.first_name} `}
            </h3>
            {ext ? (
              <span className="font-medium text-[#5f5f5f] text-[19px]">
                Ext: {ext}
              </span>
            ) : null}
            {status ? (
              <div className="ml-3">
                <DialerHeader />
              </div>
            ) : null}
          </div>

          {/* Logo Mobile */}
          <div className="inline-block md:hidden p-2 my-2 -translate-x-[20%]">
            <Link to="/">
              <img
                src={hostname ? logo_towingwiz : logo_logical}
                alt="Callrings CRM"
                className="w-[80px]"
              />
            </Link>
          </div>
          <button
            className="bg-[#231F20] sm:block md:hidden cursor-pointer p-1 text-center text-white leading-[16px]"
            onClick={ToggleClass}
          >
            {isActive ? <FaTimes size={16} /> : <FaSearch size={16} />}
          </button>

          {!user?.vendor_type && (
            <div
              className={`${
                isActive ? "flex flex-col gap-3" : "hidden"
              } md:flex md:flex-row !bg-[#f7f7f7] item-center basis-full md:basis-auto justify-end p-2.5`}
            >
              <Select
                options={[
                  { value: "job_number", label: "Job ID" },
                  // { value: "card_last_4_digits", label: "Last Digit of CC" },
                  { value: "name", label: "Customer Name" },
                  { value: "phone", label: "Phone" },
                  { value: "po_insurance", label: "PO Number" },
                ]}
                placeholder="Search By"
                className="w-full md:w-1/2 !mr-2"
                onChange={(e) =>
                  setSearchJobs({ ...searchJobs, searchBy: e.value })
                }
              />
              <div className="flex flex-row border-1 border-gray-300 rounded-[0.2rem] md:border-none md:p-0">
                <input
                  style={style}
                  type="text"
                  className="flex-1 md:w-1/2 md:!mr-2 max-h-[38px] bg-[#fff] border text-gray-900 text-sm md:bg-white border-gray-300 md:rounded-[0.2rem] p-2.5"
                  placeholder="Search"
                  value={props.searchText}
                  onChange={(e) => {
                    setSearchJobs({
                      ...searchJobs,
                      searchValue: e.target.value,
                    });
                  }}
                />
                <Button
                  text={
                    loader.headerSearch ? (
                      <Loader size={5} width={20} height={20} color="#fff" />
                    ) : (
                      <FaSearch size={20} />
                    )
                  }
                  variant="btn_submit"
                  className="max-h-[38px] !bg-[#042A42]"
                  onClick={searchJob}
                />
              </div>
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
