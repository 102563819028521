import React from "react";
import { DotsLoader, FormInput, Modal } from "../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import {
  create_system_job_status,
  update_system_job_status,
} from "../../features/systemJobStatusSlice";

const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.systemJobStatus);
  const [errors, setErrors] = React.useState([]);

  let initialValues = {
    name: "",
  };

  if (editingRecord) {
    const { _id, name } = editingRecord;
    initialValues = {
      id: _id,
      name,
    };
  }

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    // validationSchema: JobStatusSchema,
    onSubmit: async (values) => {
      if (!isLoading) {
        if (!editingRecord) {
          try {
            const res = await dispatch(create_system_job_status(values));
            if (res?.payload?.status === 200 || res?.payload?.status === 201) {
              toast.success("Job Status created");
              onCancelForm();
            } else {
              errorMessage({
                payload: res.payload,
                setErrors: setErrors,
                action: "Job Status",
                msg: "created",
              });
            }
          } catch (error) {
            toast.error("Job Status couldn't be created");
          }
        } else {
          try {
            const res = await dispatch(update_system_job_status(values));
            if (res?.payload?.status === 200) {
              toast.success("Job Status updated");
              onCancelForm();
            } else {
              errorMessage({
                payload: res.payload,
                setErrors: setErrors,
                action: "Job Status",
                msg: "updated",
              });
            }
          } catch (error) {
            toast.error("Job Status couldn't be updated");
          }
        }
      } else {
        toast.error("Please wait until previous action be completed");
      }
    },
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="mt-4">
          <FormInput errors={errors} name="name" label="Name" formik={formik} />
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;
