import React from "react";
import { Chart } from "react-google-charts";

export const chart1data = [
    ["Year", "Jobs", "Canceled"],
    ["2014", 1000, 400],
    ["2015", 1170, 460],
    ["2016", 660, 1120],
    ["2017", 1030, 540],
  ];
  
  export const chart1options = {
    chart: {
      title: "Jobs Overview",
      subtitle: "",
      legend: "top"
    },
  };
  export const chart2data = [
    ["Year", "Sales", "Profit"],
    ["2014", 1000, 400],
    ["2015", 1170, 460],
    ["2016", 660, 1120],
    ["2017", 1030, 540],
  ];
  
  export const chart2options = {
    chart: {
      title: "Company Performance",
      subtitle: "Sales, Profit: 2014-2017",
      legend: "top"
    },
  };
  

const JobsOverview = ()=> {
    return (
        <div className="w-full bg-white flex justify-between p-2">
            <div className="w-9/12">
                <div className="filter flex justify-end mt-2">
                    <button className="py-1 px-2 bg-slate-200 hover:bg-slate-400 hover:text-white">Day</button>
                    <button className="py-1 px-2 bg-slate-200 hover:bg-slate-400 hover:text-white">Week</button>
                    <button className="py-1 px-2 bg-slate-200 hover:bg-slate-400 hover:text-white">Month</button>
                </div>
                <Chart
                chartType="Bar"
                width="100%"
                height="400px"
                data={chart1data}
                options={chart1options}
                />
                <div className="my-1"></div>
                <Chart
                chartType="Bar"
                width="100%"
                height="400px"
                data={chart2data}
                options={chart2options}
                />

            </div>
            <div className="w-2/12">
                <ul>
                    <li className="flex gap-2 items-center border-solid border-b pb-1 mb-1">
                        <div className="text-4xl font-bold">125</div>
                        <div className="leading-4">Jobs<br />Done</div>
                    </li>
                    <li className="flex gap-2 items-center border-solid border-b pb-1 mb-1">
                        <div className="text-4xl font-bold">1</div>
                        <div className="leading-4">Jobs<br />Submitted</div>
                    </li>
                    <li className="flex gap-2 items-center border-solid border-b pb-1 mb-1">
                        <div className="text-4xl font-bold">0</div>
                        <div className="leading-4">Jobs<br />In Progress</div>
                    </li>
                    <li className="flex gap-2 items-center border-solid border-b pb-1 mb-1">
                        <div className="text-4xl font-bold">566</div>
                        <div className="leading-4">Jobs<br />Canceled</div>
                    </li>
                    <li className="flex gap-2 items-center border-solid border-b pb-1 mb-1">
                        <div className="text-4xl font-bold">98,7465</div>
                        <div className="leading-4">Total<br />Sales</div>
                    </li>
                    <li className="flex gap-2 items-center pb-1 mb-1">
                        <div className="text-4xl font-bold">59,8254</div>
                        <div className="leading-4">Total<br />Profit</div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default JobsOverview;