import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { FaEdit, FaTrashAlt, FaTrashRestoreAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import {
  declineReasonReset,
  delete_decline_reasons,
  get_decline_reasons,
  restore_decline_reasons,
} from "../../features/declineReasonSlice";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
const DeclineReason = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.declineReason);
  const [searchText, setSearchText] = useState("");

  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const deleteRecordHandler = async (record_id) => {
    const c = window.confirm("Are you sure to delete this record?");
    if (c) {
      try {
        const res = await dispatch(delete_decline_reasons(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Decline Reason deleted");
          cancelFormHandler();
          await dispatch(
            get_decline_reasons({
              data: { page: 1, size: paginationModel.pageSize },
            })
          );
        } else {
          errorMessage({
            payload: res.payload,
            action: "Decline Reason",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          action: "Decline Reason",
          msg: "deleted",
        });
      }
    }
  };

  const restoreRecordHandler = async (record_id) => {
    const c = window.confirm("Are you sure to restore this record?");
    if (c) {
      try {
        const res = await dispatch(restore_decline_reasons(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Decline Reason restored");
          cancelFormHandler();
          await dispatch(
            get_decline_reasons({
              data: { page: 1, size: paginationModel.pageSize },
            })
          );
        } else {
          errorMessage({
            payload: res.payload,
            action: "Decline Reason",
            msg: "restored",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          action: "Decline Reason",
          msg: "restored",
        });
      }
    }
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  useEffect(() => {
    dispatch(
      get_decline_reasons({ data: { page: 1, size: paginationModel.pageSize } })
    );
    return () => {
      dispatch(declineReasonReset());
    };
    // eslint-disable-next-line
  }, []);
  const reasons = record?.records?.filter(
    (reason) =>
      reason?.reason?.toLowerCase().includes(searchText.toLowerCase()) ||
      reason?.type?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Reason", field: "reason", flex: 1 },
    { headerName: "Description", field: "description", flex: 1 },
    { headerName: "Deleted", field: "deleted", flex: 1 },
    {
      field: "actions",
      filterable: false,
      renderCell: (params) => (
        <div>
          {userPermission("Update Decline Reason") && (
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update Decline Reason"
            />
          )}
          {userPermission("Delete Decline Reason") && (
            <>
              <FaTrashAlt
                onClick={() => deleteRecordHandler(params.row.records._id)}
                className="my_navIcon"
                title="Delete Decline"
              />
              {params.row.deleted &&
              params.row.deleted === "Yes" ? (
                <FaTrashRestoreAlt
                  onClick={() => restoreRecordHandler(params.row.records._id)}
                  className="my_navIcon"
                  title="Resote Decline"
                />
              ) : null}
            </>
          )}
        </div>
      ),
    },
  ];

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_decline_reasons({
        data: {
          page: +params.page + 1,
          size: params.pageSize,
        },
      })
    );
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Decline Reasons"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Decline Reason"
        heading="Decline Reasons Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Decline Reason")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={reasons?.map((record, index) => {
            let counter = index + 1;
            let reason = record.reason;
            let description = record.description;
            return {
              records: record,
              counter,
              reason,
              description,
              deleted: record.deleted ? "Yes" : "No",
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default DeclineReason;
