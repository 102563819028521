import React, { useState, useRef, useMemo, useEffect } from "react";
import JoditEditor from "jodit-react";

const HtmlEditor = ({ placeholder, inputValue, onChange }) => {
  const editor = useRef(null);
  const [content, setContent] = useState(inputValue);

  useEffect(() => {
    setContent(inputValue);
  }, [inputValue]);

  // Configuration for JoditEditor
  const config = useMemo(
    () => ({
      readonly: false,
      minHeight: "400px",
      placeholder: placeholder || "Start typing...",
      theme: "default",
      cleanHTML: false,
      //disablePlugins:['image','video'],
      /* uploader: {
				url: 'https://your-image-upload-api.com/upload', // Replace with your backend endpoint for file upload
				format: 'json', 
				method: 'POST', 
				withCredentials: false,
				headers: {}, 
				pathVariableName: 'file', 
				defaultHandlerSuccess: function (data) {
					// Process the successful upload here and return the URL of the uploaded image
					return data.baseurl + data.files[0].filename;
				},
			}, */
    }),
    [placeholder]
  );

  const handleEditorChange = (newContent) => {
    setContent(newContent);
    if (onChange) {
      onChange(newContent);
    }
  };

  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      tabIndex={1}
      onBlur={(newContent) => handleEditorChange(newContent)} // Update content on blur
      onChange={(newContent) => handleEditorChange(newContent)} // Update content on change
    />
  );
};

export default HtmlEditor;
