import React, { useEffect, useState } from "react";
import Steppers from "../../Components/Stepper";
import CarInfo from "./CarInfo";
import Locations from "./Locations";
import Quote from "./Quote";
import Services from "./Services";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { DotsLoader, FormInput } from "../../../../components";
import { error } from "../../../../images";
import CustomSelect from "../../Components/Selectors";
import Select from "react-select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TextField } from "@mui/material";
import SuccessMsg from "./SuccessMsg";
import { agent_create_job } from "../../../../features/agentlessForm";
import BasicInfo from "./BasicInfo";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  agent_get_new_qoute,
  create_lead_initial,
  get_leads_by_id,
  single_form_create_job,
  update_vicidial_id,
} from "../../../../features/leadsSlice";
import Countdown from "react-countdown";
import { get_job_category } from "../../../../features/jobCategorySlice";
import { toast } from "react-toastify";
import {
  isJobComplete,
  resetLeadDid,
  setDialerPhone,
} from "../../../../features/dialerSlice";
import "react-phone-number-input/style.css";
import { get_card_types_drd } from "../../../../features/cardTypesSlice";
import { ClientFormSchema } from "../../../../schemas/LeadSchema";
import _ from "lodash";
import { get_jobs_data_by_phone } from "../../../../features/jobsSlice";
import { get_industry_drd } from "../../../../features/IndustrySlice";
import FormRadioGroup from "../../../../components/molecules/FormRadioGroup";
import userPermission from "../../../../util/userPermission";
import errorMessage from "../../../../util/errorMessage";
import SingleForm from "../SingleForm/SingleForm";
import SingleFormLocation from "../SingleForm/SingleLocation";
import SingleFormSchedule from "../SingleForm/SingleFormSchedule";
import JobListing from "./JobListing";
import { sendIframeMsg } from "../../../../util/iFrame";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getLeadsById } from "../../../../services/leads";
import { generateQuotingPriceRequest } from "../../../../services/agentlessFormService";
import LeadRejectForm from "./LeadRejectForm";
function ClientForm() {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("q");
  const {
    phone_number,
    callType,
    dialer_lead_id,
    vicidial_id,
    did_pattern,
    did_description,
    conversation_id,
  } = useSelector((state) => state.dialer);
  const jobs = useSelector((state) => state.jobs);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState([]);
  const [isRejectFormModal, setRejectFormModal] = React.useState(false);
  const params = useParams();
  const leads = useSelector((state) => state.leads);
  const title = [
    "1. Basic Information",
    "2. Choose Your Service",
    "3. Car Information",
    "4. Pickup & Drop Locations",
    "5. Get Quote",
  ];

  let initialValues = {
    industry_id: "",
    lead_num: "",
    lead_token: token,
    phone: "",
    job_category_id: "",
    service_cost: null,
    scheduled_on: "",

    name: "",
    car_year: "",
    car_model: "",
    car_make: "",
    is_neutral: false,

    email: "",
    alternate_phone: "",

    drop_latitude: 0,
    drop_longitude: 0,
    drop_country: "",
    drop_city: "",
    drop_state: "",
    drop_zip: "",
    complete_drop_address: "",

    pickup_latitude: 0,
    pickup_longitude: 0,
    pickup_country: "US",
    pickup_city: "",
    pickup_state: "",
    pickup_zip: "",
    complete_pickup_address: "",

    distance: "",
    special_instruction: "",
    car_service: "",

    is_declined: false,
    decline_comment: "",
    decline_reasons: "",
    decline_id: "",
    payment_type: "COD",
    card_type_id: "",
    card_month_id: "",
    card_number: "",
    card_year: "",
    card_CVV: "",
    card_holder_name: "",
    card_zip: "",
    card_city: "",
    card_state: "",
    zelle_auth_number: "",
    venmo: "",
    cash_app: "",
    vendor_pay: "",
    tags: "",
    goa_price: "",
    comments: "N/A",
    scheduled_end: "",
  };
  const jobTypes = useSelector((state) => state.jobCategory);
  const cardTypes = useSelector((state) => state.cardTypes);
  const industry = useSelector((state) => state.industry);
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const { quote } = useSelector((state) => state.leads);
  const [service, setService] = React.useState(null);
  const [currentStep, setCurrentStep] = React.useState(1);
  const [isDisable, setIsDisable] = React.useState(false);
  const [isLeadCreate, setIsLeadCreate] = React.useState(false);
  const [resData, setResData] = React.useState();
  const [isSchedule, setIsSchedule] = React.useState("false");
  const [isMultipleJobs, setIsMultipleJobs] = React.useState(false);
  const [isTimer, setIsTimer] = React.useState(false);
  const [indusry_id, setIndustry_id] = React.useState("Towing");
  const isUpdating = location.pathname.startsWith("/leads/update");
  const [vicidialId, setVicidialId] = React.useState(null);
  const { id } = params;
  const menualData = location.state;
  const [isLoader, setIsLoader] = useState(false);
  const getLead = async () => {
    try {
      const res = await dispatch(get_leads_by_id(id));
      if (res.payload?.status === 200) {
        formik.setValues({
          ...res.payload.data,
          id: id,
          name: menualData?.name ? menualData?.name : res?.payload?.data?.name,
          email: menualData?.email
            ? menualData?.email
            : res?.payload?.data?.email,
          alternate_phone: menualData?.alternate_phone
            ? menualData?.alternate_phone
            : res?.payload?.data?.alternate_phone,
        });
      }
    } catch (error) {
      console.error(
        "Error ~ file: AddUpdateForm.jsx:157 ~ getJob ~ error",
        error
      );
      errorMessage({ payload: error, action: "Lead", msg: "loaded" });
    }
  };
  if (isUpdating) {
    const lead = _.find(leads.record, { _id: id });
    if (lead) {
      initialValues = {
        ...lead,
        id: lead._id,
      };
    }
  }
  const receiveMessage = (event) => {
    if (event?.data?.action === "vendor_id_confirm") {
      setVicidialId(event?.data?.vicidial_id);
    }
  };
  window.addEventListener("message", receiveMessage);
  React.useEffect(() => {
    const createAutoLead = async () => {
      if (phone_number) {
        formik.setFieldValue("phone", phone_number);
        formik.setFieldValue("call_type", callType);
        formik.setFieldValue("dialer_lead_id", dialer_lead_id);
        formik.setFieldValue("vicidial_id", vicidial_id);
        formik.setFieldValue("did_pattern", did_pattern);
        formik.setFieldValue("did_description", did_description);
        formik.setFieldValue("conversation_id", conversation_id);
        dispatch(isJobComplete(false));
        setIsLeadCreate(false);
        const jobs = await dispatch(
          get_jobs_data_by_phone({ phone_num: phone_number })
        );
        if (
          jobs?.payload?.data?.totalItems > 0 &&
          jobs?.payload?.data?.records?.length > 0
        ) {
          setIsMultipleJobs(true);
          setIsLeadCreate(false);
        } else {
          await createLeadInitial({
            phone_number,
            callType,
            dialer_lead_id,
            vicidial_id,
            did_pattern,
            did_description,
            conversation_id,
          });
        }
        dispatch(
          setDialerPhone({
            phone: "",
            callType: "",
            dialer_lead_id: "",
            vicidial_id: "",
            did_description: "",
            did_pattern: "",
            conversation_id: "",
          })
        );
      }
    };

    createAutoLead();
    // eslint-disable-next-line
  }, [phone_number]);

  const createMenualLead = async () => {
    try {
      let phone = phone_number ? phone_number : formik?.values?.phone;
      const res = await dispatch(
        create_lead_initial({
          industry_id: formik.values.industry_id
            ? formik.values.industry_id
            : "6366126737c4d7c72204c6c0",
          // : industry?.industryDrd?.find(({ name }) => name === "Towing")?._id,
          phone:
            phone?.length === 10 && !phone?.includes("+")
              ? `+1${phone}`
              : phone?.length === 11 &&
                !phone?.includes("+") &&
                phone?.startsWith("1")
              ? `+${phone}`
              : phone,
          call_payload: null,
          dialer_lead_id: "",
          vicidial_id: "",
          call_type: "Manual",
          payment_type: formik.values.payment_type,
        })
      );
      if (res?.payload?.status === 200) {
        formik?.setFieldValue("lead_num", res?.payload?.data?.lead_num);
        formik?.setFieldValue("id", res?.payload?.data?._id);
        setCurrentStep(2);
        navigate(`/leads/update/${res?.payload?.data?._id}`, {
          state: {
            name: formik.values.name,
            email: formik.values.email,
            alternate_phone: formik.values.alternate_phone,
            menualVicidialId: vicidialId,
          },
        });
      } else {
        errorMessage({
          payload: res.payload?.error,
          setErrors: setErrors,
          action: "SMS",
          msg: "send",
        });
      }
    } catch (error) {
      console.error("🚀 ~ file: index.jsx:475 ~ handleSMS ~ error:", error);
      errorMessage({
        payload: error,
        setErrors: setErrors,
        action: "SMS",
        msg: "send",
      });
    }
  };
  // Create Initial Lead
  const createLeadInitial = async ({
    callType,
    phone_number,
    dialer_lead_id,
    did_pattern,
    did_description,
    conversation_id,
  }) => {
    let callPayload;
    try {
      if (
        callType
          ? callType === "Incoming"
          : formik.values.call_type === "Incoming"
      ) {
        if (!did_pattern) {
          callPayload = null;
        } else {
          callPayload = did_pattern
            ? [{ did: did_pattern, did_group_id: did_description }]
            : null;
        }
      }

      let phone = phone_number ? phone_number : formik?.values?.phone;
      const res = await dispatch(
        create_lead_initial({
          industry_id: formik.values.industry_id
            ? formik.values.industry_id
            : "6366126737c4d7c72204c6c0",
          // : industry?.industryDrd?.find(({ name }) => name === "Towing")?._id,
          phone:
            phone?.length === 10 && !phone?.includes("+")
              ? `+1${phone}`
              : phone?.length === 11 &&
                !phone?.includes("+") &&
                phone?.startsWith("1")
              ? `+${phone}`
              : phone,
          call_payload: callPayload ? callPayload : null,
          dialer_lead_id: dialer_lead_id ? dialer_lead_id : "",
          call_type: callType,
          conversation_id: conversation_id ? conversation_id : null,
          payment_type: formik.values.payment_type,
        })
      );
      dispatch(resetLeadDid());
      if (res?.payload?.status === 200) {
        formik?.setFieldValue("lead_num", res?.payload?.data?.lead_num);
        formik?.setFieldValue("id", res?.payload?.data?._id);
        formik?.setFieldValue("lead_token", res?.payload?.data?.lead_token);
        sendIframeMsg({
          action: "send_vendor_lead_code",
          user: user?.dialer_data?.dialer_user,
          pass: user?.dialer_data?.dialer_pass,
          vendor_lead_code: res?.payload?.data?.lead_num,
        });
        setCurrentStep(1);
        setIsMultipleJobs(false);
        navigate(`/leads/update/${res?.payload?.data?._id}`);
      } else {
        errorMessage({
          payload: res.payload?.error,
          setErrors: setErrors,
          action: "SMS",
          msg: "send",
        });
      }
    } catch (error) {
      console.error("🚀 ~ file: index.jsx:475 ~ handleSMS ~ error:", error);
      errorMessage({
        payload: error,
        setErrors: setErrors,
        action: "SMS",
        msg: "send",
      });
    }
  };
  // Get Quote
  const getQuote = async () => {
    try {
      const res = await dispatch(
        agent_get_new_qoute({
          ...formik?.values,
          industry_id: formik.values.industry_id
            ? formik.values.industry_id
            : "6366126737c4d7c72204c6c0",
          // : industry?.industryDrd?.find(({ name }) => name === "Towing")?._id,
        })
      );
      if (res?.payload?.status === 200) {
        formik.setFieldValue("service_cost", res?.payload?.data?.service_cost);
        setIsDisable(false);
        setCurrentStep(5);
      } else {
        setIsDisable(true);
        errorMessage({
          payload: res.payload,
          action: "Quote",
          msg: "generated",
        });
      }
    } catch (err) {
      console.error("🚀 ~ file: index.jsx:333 ~ getQuote ~ err:", err);
      setIsDisable(true);
      errorMessage({ payload: err, action: "Quote", msg: "generated" });
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnBlur: true,
    // validationSchema: null,
    // JobSchema,
    validationSchema: ClientFormSchema,
    onSubmit: async (values) => {
      const quoting_status = formik.values?.quoting_request_status;
      if (quoting_status === "Pending")
        return toast.error(
          "You quoting request is pending, please wait for approval."
        );
      if (quoting_status === "Rejected")
        return toast.error("You quoting request has been rejected");
      if (!formik.values.service_cost)
        return toast.error("Job couldn't be create without service cost");

      const payload = {
        service_cost: formik.values?.quoting_request_price
          ? formik.values?.quoting_request_price
          : formik.values.service_cost
          ? formik.values.service_cost
          : leads.quote?.service_cost,
        scheduled_on: formik.values.scheduled_on,
        payment_type: formik?.values.payment_type,
        id: formik.values._id,
      };
      try {
        const res = await dispatch(agent_create_job(payload));
        if (res.payload?.status === 200) {
          // await dispatch(set_job_status({ job_status_id: jobStatus?.record?.records?.find(({name})=>name==="Submitted")?._id, id: formik.values.id }));
          setResData(res?.payload?.data);
          setIsLeadCreate(true);
          dispatch(isJobComplete(true));
        } else {
          errorMessage({
            payload: res.payload,
            setErrors: setErrors,
            action: "Lead",
            msg: "created",
          });
        }
      } catch (err) {
        errorMessage({
          payload: error,
          setErrors: setErrors,
          action: "Lead",
          msg: "created",
        });
      }
    },
  });
  useEffect(() => {
    const getLeadDetail = async () => {
      try {
        const res = await getLeadsById(id);
        if (res?.status === 200) {
          formik.setValues({
            ...res.data,
            id: id,
            name: menualData?.name ? menualData?.name : res?.data?.name,
            email: menualData?.email ? menualData?.email : res?.data?.email,
            service_cost:
              res.data?.quoting_request_price || res.data?.service_cost,
            alternate_phone: menualData?.alternate_phone
              ? menualData?.alternate_phone
              : res?.data?.alternate_phone,
          });
        }
      } catch (error) {
        console.error(
          "Error ~ file: AddUpdateForm.jsx:157 ~ getJob ~ error",
          error
        );
        errorMessage({
          payload: error?.response.message,
          action: "Lead",
          msg: "loaded",
        });
      }
    };
    const intervalId = setInterval(() => {
      if (formik.values.quoting_request_status === "Pending") {
        getLeadDetail();
      } else {
        clearInterval(intervalId);
      }
    }, 10000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.quoting_request_status]);
  React.useEffect(() => {
    async function fetchData() {
      const lead_id = formik.values.id ? formik.values.id : id;
      if (lead_id && vicidialId) {
        try {
          const res = await dispatch(
            update_vicidial_id({ id: lead_id, vicidial_id: vicidialId })
          );
          if (res.payload?.status === 200) {
            formik.setValues({
              ...res.payload.data,
              id: id,
              name: menualData?.name
                ? menualData?.name
                : res?.payload?.data?.name,
              email: menualData?.email
                ? menualData?.email
                : res?.payload?.data?.email,
              alternate_phone: menualData?.alternate_phone
                ? menualData?.alternate_phone
                : res?.payload?.data?.alternate_phone,
            });
          }
          setVicidialId(null);
        } catch (err) {
          console.error(
            "🚀 ~ file: index.jsx:243 ~ React.useEffect ~ err:",
            err
          );
        }
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, [vicidialId, formik.values.id, id]);
  const sections = [
    { title: "Basic Information" },
    { title: "Choose Services" },
    { title: "Car Info" },
    { title: "Pickup & Drop Location" },
    { title: "Get Quote" },
  ];

  const props = {
    service: service,
    setService: setService,
    currentStep: currentStep,
    setCurrentStep: setCurrentStep,
    formik: formik,
    setIsDisable: setIsDisable,
    record: jobTypes?.record?.records,
    sections: indusry_id === "Towing" ? sections : [],
  };
  React.useEffect(() => {
    if (cardTypes.cardTypesDrd?.length === 0) {
      dispatch(get_card_types_drd());
    }
    // if (industry?.industryDrd?.length === 0) {
    dispatch(get_industry_drd());
    // }
    if (jobTypes?.record?.records && jobTypes?.record?.records?.length === 0) {
      dispatch(get_job_category());
    }
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    if (
      (isUpdating && leads?.record?.length === 0) ||
      leads?.record === undefined ||
      id
    ) {
      getLead();
    }
    // eslint-disable-next-line
  }, [id]);
  // Create Job
  const handleCreateJob = async () => {
    const errors = validateForm(formik.values);
    if (errors?.length === 0) {
      try {
        let leadId = formik.values.id;
        if (!isUpdating) {
          const initialLead = await dispatch(
            create_lead_initial({
              industry_id: formik.values.industry_id
                ? formik.values.industry_id
                : "6366126737c4d7c72204c6c0",
              // : industry?.industryDrd?.find(({ name }) => name === "Towing")
              //     ?._id,
              phone:
                formik?.values?.phone?.length === 10 &&
                !formik?.values?.phone?.includes("+")
                  ? `+1${formik?.values?.phone}`
                  : formik?.values?.phone?.length === 11 &&
                    !formik?.values?.phone?.includes("+") &&
                    formik?.values?.phone?.startsWith("1")
                  ? `+${formik?.values?.phone}`
                  : formik?.values?.phone,
              call_payload: null,
              call_type: "Manual",
              vicidial_id: "",
            })
          );
          if (initialLead?.payload?.status === 200) {
            leadId = initialLead?.payload?.data?._id;
          }
        }
        const res = await dispatch(
          single_form_create_job({
            id: leadId,
            phone: formik?.values.phone,
            name: formik?.values.name,
            email: formik?.values.email ? formik?.values.email : "",
            alternate_phone: formik?.values.alternate_phone
              ? formik?.values.alternate_phone
              : "",
            scheduled_on: formik?.values.scheduled_on,
            industry_id: formik.values.industry_id
              ? formik.values.industry_id
              : industry?.industryDrd?.find(({ name }) => name === indusry_id)
                  ?._id,
            complete_pickup_address: formik?.values.complete_pickup_address,
            pickup_country: formik?.values.pickup_country,
            pickup_city: formik?.values.pickup_city,
            pickup_state: formik?.values.pickup_state,
            pickup_zip: formik?.values.pickup_zip,
            pickup_latitude: formik?.values.pickup_latitude?.toString(),
            pickup_longitude: formik?.values.pickup_longitude?.toString(),
            comments: formik?.values.comments,
            scheduled_end: formik?.values.scheduled_end,
            payment_type: formik?.values.payment_type,
          })
        );
        if (res?.payload?.status === 201) {
          setResData(res?.payload?.data);
          setIsLeadCreate(true);
          dispatch(isJobComplete(true));
          // toast.success("Job create successfully");
          // navigate(`/jobs/update/${res?.payload?.data?.job?._id}`);
        } else {
          if (
            res.payload?.error?.length > 0 &&
            res.payload?.error[0]?.industry_id
          ) {
            toast.error("Please Select the industry again");
          }
          errorMessage({
            payload: res.payload?.error,
            setErrors: setErrors,
            action: "Job",
            msg: "created",
          });
        }
      } catch (error) {
        console.error(
          "🚀 ~ file: index.jsx:421 ~ handleCreateJob ~ error:",
          error
        );
        errorMessage({
          payload: error,
          setErrors: setErrors,
          action: "Job",
          msg: "created",
        });
      }
    } else {
      errorMessage({
        payload: errors,
        setErrors: setErrors,
        action: "Job",
        msg: "created",
      });
    }
  };
  const handleSMS = async () => {
    if (!isTimer) {
      toast.info("Please contact administrator.");
      // try {
      //   const res = await dispatch(send_sms(formik.values.id));
      //   if (res?.payload?.status === 200) {
      //     toast.success("SMS send successfully");
      //   } else {
      //     errorMessage({
      //       payload: res.payload?.error,
      //       setErrors: setErrors,
      //       action: "SMS",
      //       msg: "send",
      //     });
      //   }
      // } catch (error) {
      //   console.error("🚀 ~ file: index.jsx:475 ~ handleSMS ~ error:", error);
      //   errorMessage({
      //     payload: error,
      //     setErrors: setErrors,
      //     action: "SMS",
      //     msg: "send",
      //   });
      // }
    } else {
      toast.error("Please wait for 30 second.");
    }
  };
  const renderer = ({ seconds, completed }) => {
    if (completed) {
      return setIsTimer(false);
    } else {
      return <span>Wait for {seconds}</span>;
    }
  };
  const isSelected = (val) => {
    if (indusry_id) {
      if (indusry_id === val.name) {
        return true;
      }
    }
    return false;
  };
  function validateForm(data) {
    const errors = [];
    if (!data.phone) {
      errors.push({ phone: "Phone is not allowed to be empty" });
    }
    if (!data.name) {
      errors.push({ name: '"name" is not allowed to be empty' });
    }
    if (!data.complete_pickup_address) {
      errors.push({ job_address: '"job_address" required' });
    }
    if (!data.pickup_city) {
      errors.push({ pickup_city: '"pickup_city" required' });
    }
    if (!data.pickup_state) {
      errors.push({ pickup_state: '"pickup_state" required' });
    }
    // if (!data.pickup_latitude) {
    //   errors.push({ pickup_latitude: '"pickup_latitude" required' });
    // }
    // if (!data.pickup_longitude) {
    //   errors.push({ pickup_longitude: '"pickup_longitude" required' });
    // }
    return errors;
  }
  const generate_quoting_price_request = async () => {
    setIsLoader(true);
    try {
      const res = await generateQuotingPriceRequest(formik?.values?._id);
      if (res.status === 200 || res.status === 201) {
        formik.setValues({
          ...formik.values,
          quoting_request_status: res.data?.quoting_request_status,
        });
        toast.success(res.data.message || "Quoting request sent successfully");
      } else {
        toast.error(res.data.message || "Quoting request couldn't be send");
      }
    } catch (err) {
      toast.error(
        err?.response.data.message || "Quoting request couldn't be send"
      );
      console.log("👊 ~ constgenerate_quoting_price_request=async ~ err:", err);
    }
    setIsLoader(false);
  };

  const openRejectFormHandler = () => {
    const c = window.confirm(`Are you sure you want to decline this job?`);
    if (c) {
      setRejectFormModal(true);
    }
  };
  const cancelFormHandler = () => {
    setRejectFormModal(false);
  };
  const navigateToLead = () => {
    const c = window.confirm(`Are you sure you want to decline this job?`);
    if (c) {
      navigate("/leads");
    }
  };

  return (
    <div style={{ height: "auto" }} className="mt-5 mt-md-0">
      {isRejectFormModal && (
        <LeadRejectForm
          modalTitle="Decline Lead"
          onCancelForm={cancelFormHandler}
          leadId={formik?.values?._id}
        />
      )}
      {isMultipleJobs && (
        <JobListing
          onCancelModal={() => setIsMultipleJobs(false)}
          callType={formik.values.call_type}
          createLeadInitial={() =>
            createLeadInitial({
              phone_number: formik.values.phone,
              callType: formik.values.call_type,
              dialer_lead_id: formik.values.dialer_lead_id,
              did_pattern: formik.values.did_pattern,
              did_description: formik.values.did_description,
            })
          }
        />
      )}
      <div className="block md:hidden w-full md:w-2/4 relative z-[9]">
        <label className="font-bold mb-1">Select Industry</label>
        <Select
          options={industry?.industryDrd}
          //defaultValue={selectedIndustry}
          getOptionLabel={(options) => options?.name}
          getOptionValue={(options) => options?._id}
          className="w-full"
          placeholder="Select"
          //onChange={(e) => setSelectedIndustry(e)}
        />
      </div>
      <div className="hidden md:flex flex-wrap justify-center mt-3">
        {industry?.industryDrd?.map((data) => {
          const { _id, name } = data;
          return (
            <li
              className={`col_1_5 mb-2 list-none active`}
              key={_id}
              onClick={() => {
                setCurrentStep(1);
                setIndustry_id(name);
                formik.setFieldValue("industry_id", _id);
              }}
            >
              <div
                className={`flex flex-col items-center w-full p-1.25 rounded border border-[#e3e3e3] cursor-pointer ${
                  isSelected(data)
                    ? "bg-client-100 text-white"
                    : "bg-white text-black"
                }`}
              >
                <span className="text-xs font-pop">{name}</span>
              </div>
            </li>
          );
        })}
      </div>
      {indusry_id === "Towing" ? (
        // (isAgentless && token) || !isAgentless ? (
        <div>
          {indusry_id === "Towing" && <Steppers props={props} />}

          <div className="container-fluid mt-4">
            <div
              className={[
                "bg-white p-3.7 rounded-xl shadow-[0_4px_20px_rgba(59,_89,_153,_0.15)] mb-",
                isLeadCreate ? "mt-24" : "",
              ].join(" ")}
            >
              {indusry_id === "Towing" && !isLeadCreate && (
                <div className="flex flex-col md:flex-row ">
                  <div className="px-2 py-0 relative w-full md:w-1/5 mb-2 md:mb-0">
                    <FormRadioGroup
                      errors={errors}
                      name="payment_type"
                      label="Payment Type"
                      labelProp="label"
                      valueProp="value"
                      options={[
                        { label: "C.O.D", value: "COD" },
                        { label: "Insurance", value: "Insurance" },
                      ]}
                      formik={formik}
                      type="text"
                      isHorizontal
                      isBorder
                      isFloat
                    />
                  </div>
                  <div className="flex">
                    <div className="px-2 py-0 mb-3">
                      <FormInput
                        errors={errors}
                        name="po_insurance"
                        label="PO Insurance"
                        formik={formik}
                        type="text"
                      />
                    </div>
                    <div className="px-2 py-0 mb-3">
                      <FormInput
                        errors={errors}
                        name="distance"
                        label="Distance"
                        formik={formik}
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              {!isLeadCreate && (
                <h3 className="mb-3 text-lg border-b-2 border-neutral-100 pb-2 font-pop font-medium">
                  {title[currentStep - 1]}
                </h3>
              )}
              {industry.isLoading ||
              leads.isLoading ||
              jobs.isLoading ||
              isLoader ? (
                <DotsLoader />
              ) : null}
              {currentStep === 2 ? (
                <Services props={props} errors={errors} />
              ) : currentStep === 3 ? (
                <CarInfo
                  formik={formik}
                  setIsDisable={setIsDisable}
                  errors={errors}
                />
              ) : // eslint-disable-next-line no-mixed-operators
              currentStep === 4 || currentStep === 2 ? (
                <Locations
                  formik={formik}
                  setIsDisable={setIsDisable}
                  record={jobTypes?.record}
                  errors={errors}
                  currentStep={currentStep}
                />
              ) : currentStep === 1 ? (
                <BasicInfo
                  formik={formik}
                  setIsDisable={setIsDisable}
                  errors={errors}
                />
              ) : currentStep === 5 && isLeadCreate ? (
                <SuccessMsg
                  resData={resData}
                  formik={formik}
                  job_note={resData?.job_note}
                />
              ) : (
                <Quote
                  formik={formik}
                  record={jobTypes?.record}
                  isUpdating={isUpdating}
                  errors={errors}
                />
              )}
            </div>

            {currentStep === 5 &&
              quote &&
              formik?.values?.phone &&
              !isLeadCreate && (
                <div>
                  <CustomSelect
                    name="schedule_on"
                    labelProp="label"
                    valueProp="value"
                    options={[
                      { label: "I want driver to come now.", value: "false" },
                      {
                        label: "I want to schedule a driver for later.",
                        value: "true",
                      },
                    ]}
                    isCheck={isSchedule}
                    isOnChange={(ele) => setIsSchedule(ele)}
                    type="text"
                    isRadio={true}
                  />
                  {isSchedule === "true" && (
                    <div className="mb-2 flex justify-center items-center col-md-6">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          value={formik?.values?.scheduled_on}
                          onChange={(val) => {
                            formik?.setFieldValue(
                              "scheduled_on",
                              val.toString()
                            );
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                  )}
                </div>
              )}

            {!isLeadCreate ? (
              <div
                className={`flex flex-row w-full items-center h-[40px] mb-2 mt-4 ${
                  currentStep === 1 ? "justify-end" : "justify-between"
                }`}
              >
                {currentStep > 1 && (
                  <button
                    className="py-1.5 px-3 align-middle next rounded-0 text-black bg-[#e8e9e8] border border-[#e8e9e8] float-left"
                    onClick={() => setCurrentStep(currentStep - 1)}
                  >
                    Previous
                  </button>
                )}
                <div>
                  {currentStep < 5 && !isDisable && (
                    <button
                      className="py-1.5 px-3 align-middle next rounded-0 text-white bg-client-100 border-client-100 float-right hover:bg-[#007bff]"
                      onClick={() => {
                        if (currentStep === 4) {
                          getQuote();
                        } else {
                          setCurrentStep(currentStep + 1);
                        }
                      }}
                      disabled={isDisable}
                    >
                      Next
                    </button>
                  )}
                  {currentStep === 1 && isDisable && (
                    <button
                      className="py-1.5 px-3 align-middle next rounded-0 text-white bg-client-100 border-client-100 float-right hover:bg-[#007bff]"
                      onClick={() =>
                        isUpdating
                          ? setCurrentStep(currentStep + 1)
                          : createMenualLead()
                      }
                    >
                      Next
                    </button>
                  )}
                  {currentStep === 5 && !isDisable && (
                    <div className="flex flex-row gap-x-2">
                      {/* <button
                        type="button"
                        className="py-1.5 px-3 align-middle next rounded-0 text-white bg-client-100 border-client-100 float-right hover:bg-[#007bff]"
                        onClick={formik.handleSubmit}
                      >
                        Accept Payment, Pay later
                      </button> */}
                      {formik.values?.quoting_request_status &&
                      (formik.values?.quoting_request_status === "Quoted" ||
                        formik.values?.quoting_request_status ===
                          "Rejected") ? (
                        <button
                          type="button"
                          className="py-1.5 px-3 align-middle next rounded-0 text-white bg-client-100 border-client-100 float-right hover:bg-[#007bff]"
                          onClick={generate_quoting_price_request}
                        >
                          Re-Submit Quote
                        </button>
                      ) : null}
                      <button
                        type="button"
                        className="py-1.5 px-3 align-middle next rounded-0 text-white bg-red-400 float-right"
                        onClick={() => openRejectFormHandler()}
                      >
                        Decline Job
                      </button>
                      <button
                        type="submit"
                        className="py-1.5 px-3 align-middle next rounded-0 text-white bg-client-100 border-client-100 float-right hover:bg-[#007bff]"
                        onClick={formik.handleSubmit}
                      >
                        Accept Payment, Pay later
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="container-fluid mt-4">
          <div
            className={[
              "bg-white p-3.7 rounded-xl shadow-[0_4px_20px_rgba(59,_89,_153,_0.15)] mb-",
              isLeadCreate ? "mt-24" : "",
            ].join(" ")}
          >
            {isLeadCreate ? (
              <SuccessMsg
                resData={resData?.job}
                job_note={resData?.job_note}
                formik={formik}
              />
            ) : (
              <>
                <div className="flex flex-col md:flex-row ">
                  <div className="px-2 py-0 relative w-full md:w-1/5 mb-2 md:mb-0">
                    <FormRadioGroup
                      errors={errors}
                      name="payment_type"
                      label="Payment Type"
                      labelProp="label"
                      valueProp="value"
                      options={[
                        { label: "C.O.D", value: "COD" },
                        { label: "Insurance", value: "Insurance" },
                      ]}
                      formik={formik}
                      type="text"
                      isHorizontal
                      isBorder
                      isFloat
                    />
                  </div>
                  <div className="flex">
                    <div className="px-2 py-0 mb-3">
                      <FormInput
                        errors={errors}
                        name="po_insurance"
                        label="PO Insurance"
                        formik={formik}
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                {leads.isLoading && <DotsLoader />}
                <SingleForm
                  formik={formik}
                  setIsDisable={setIsDisable}
                  errors={errors}
                />
                <SingleFormLocation formik={formik} errors={errors} />
                <SingleFormSchedule formik={formik} errors={errors} />
              </>
            )}
          </div>
          <div className="flex justify-end space-x-2 mt-2">
            <button
              type="button"
              className="py-1.5 px-3 rounded-0 text-white bg-red-400"
              onClick={() => navigateToLead()}
            >
              Decline Job
            </button>
            {!isLeadCreate && (
              <button
                className="py-1.5 px-3 rounded-0 text-white bg-client-100 border-client-100 hover:bg-[#007bff]"
                onClick={handleCreateJob}
              >
                Create Job
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ClientForm;
