import dayjs from "dayjs";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, DotsLoader, FormInput } from "../../../../components";
import FormRadioGroup from "../../../../components/molecules/FormRadioGroup";
import { close_job } from "../../../../features/jobsSlice";
import errorMessage from "../../../../util/errorMessage";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import api from "../../../../services/api";
function CloseJob(props) {
  const { formik, tech, setIsLoading, getJob } = props;
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(
    new Date()?.toISOString()
  );
  const onCloseJob = async () => {
    try {
      const res = await dispatch(
        close_job({
          id: formik.values._id,
          performed_status: formik.values.performed_status,
          paid_via: formik.values.paid_via,
          goa_tech_fee: formik.values.goa_tech_fee,
          tech_fee: formik.values.tech_fee,
          refund: formik.values.refund,
          confirmed_cx: formik.values.confirmed_cx,
          confirmed_driver: formik.values.confirmed_driver,
          closed_date: formik.values.closed_date
            ? formik.values.closed_date
            : selectedDate,
        })
      );
      if (res?.payload?.status === 200) {
        getJob();
        toast.success("Job closed Successfully");
      } else {
        errorMessage({
          payload: res.payload,
          setErrors: setErrors,
          action: "Technician",
          msg: "assigned",
        });
      }
    } catch (error) {
      errorMessage({
        payload: error,
        setErrors: setErrors,
        action: "Technician",
        msg: "assigned",
      });
    }
  };
  const onUndoClosedJob = async () => {
    setIsLoading(true);
    try {
      const res = await api.post(
        `/api/jobs/${formik?.values?._id}/undo_close_job`
      );
      if (res?.status === 200) {
        toast.success("Job reopened successfully.");
        getJob();
      } else {
        errorMessage({
          payload: res.data?.message,
          setErrors: setErrors,
          action: "Job",
          msg: "reopened",
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      errorMessage({
        payload: error?.response?.data?.message,
        setErrors: setErrors,
        action: "Job",
        msg: "reopened",
      });
    }
  };
  React.useEffect(() => {
    if (!formik.values.is_closed) {
      formik.setFieldValue(
        "vendor_pay",
        formik?.values?.vendor_id?.tech_percentage
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tech]);
  React.useEffect(() => {
    if (
      formik.values.performed_status === "Performed" &&
      formik.values.paid_via === "Vendor Pay" &&
      !formik.values.is_closed
      // && !formik.values.tech_fee
    ) {
      formik.setFieldValue(
        "tech_fee",
        (
          ((formik.values.vendor_pay ? formik.values.vendor_pay : 0) / 100) *
          (formik.values.service_cost-formik.values.parts_charge)
        )?.toFixed(2)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.vendor_pay,
    formik.values.performed_status,
    formik.values.paid_via,
  ]);
  React.useEffect(() => {
    if (
      formik.values.performed_status === "Not Performed" &&
      formik.values.paid_via === "Vendor Pay" &&
      formik?.values?.goa_tech_fee === "No"
    ) {
      formik.setFieldValue("refund", 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.goa_tech_fee,
    formik.values.performed_status,
    formik.values.paid_via,
  ]);

  return (
    <div className="col-12 md:border mb-3 px-0 md:px-3">
      <div className="md:col-2 p-2">
        <h5 className="">Close Job</h5>
      </div>
      <div className={["mt-2"].join(" ")}>
        <div className="md:grid grid-cols-3 gap-x-1 gap-y-2">
          <div className="md:px-2 py-0 !mb-3 relative items-center">
            <FormRadioGroup
              errors={errors ? errors : props.errors}
              name="performed_status"
              label="Select"
              labelProp="label"
              valueProp="value"
              options={[
                { label: "Pending", value: "Pending" },
                { label: "Performed", value: "Performed" },
                {
                  label: " Not Performed",
                  value: "Not Performed",
                },
              ]}
              formik={formik}
              type="text"
              isHorizontal
              isBorder
              isFloat
            />
          </div>
          {formik?.values?.performed_status === "Performed" && (
            <div className="md:px-2 py-0 !mb-3 relative items-center">
              <FormRadioGroup
                errors={errors ? errors : props.errors}
                name="paid_via"
                label="Payment"
                labelProp="label"
                valueProp="value"
                options={[
                  { label: "Inhouse CC", value: "CC" },
                  { label: "Tech Paid", value: "Vendor Pay" },
                  {
                    label: "Others",
                    value: "Others",
                  },
                ]}
                formik={formik}
                type="text"
                isHorizontal
                isBorder
                isFloat
              />
            </div>
          )}
          {formik?.values?.performed_status === "Not Performed" ? (
            <div className="md:px-2 py-0 !mb-3 relative items-center">
              <FormRadioGroup
                errors={errors ? errors : props.errors}
                name="paid_via"
                label="GOA Payment"
                labelProp="label"
                valueProp="value"
                options={[
                  { label: "Inhouse CC", value: "CC" },
                  { label: "Tech Paid", value: "Vendor Pay" },
                ]}
                formik={formik}
                type="text"
                isHorizontal
                isBorder
                isFloat
              />
            </div>
          ) : null}

          {formik?.values?.performed_status === "Not Performed" ? (
            <div className="md:px-2 py-0 !mb-3 relative items-center">
              <FormRadioGroup
                errors={errors ? errors : props.errors}
                name="goa_tech_fee"
                label="GOA Tech Fee"
                labelProp="label"
                valueProp="value"
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
                formik={formik}
                type="text"
                isHorizontal
                isBorder
                isFloat
              />
            </div>
          ) : null}
        </div>
        <div
          className={`md:grid ${
            (formik.values.performed_status === "Performed" &&
              formik.values.paid_via === "Vendor Pay") ||
            (formik.values.goa_tech_fee === "No" &&
              formik?.values?.performed_status === "Not Performed" &&
              formik?.values?.paid_via === "CC")
              ? "grid-cols-6"
              : formik.values.performed_status === "Not Performed" &&
                formik.values.goa_tech_fee === "Yes"
              ? "grid-cols-5"
              : "grid-cols-3"
          } gap-x-1 gap-y-2`}
        >
          {formik.values.paid_via === "Vendor Pay" &&
            formik?.values?.performed_status === "Performed" && (
              <div className="md:px-2 py-0 !mb-3">
                <FormInput
                  errors={errors ? errors : props.errors}
                  formik={formik}
                  name="vendor_pay"
                  label="Tech Pay(%)"
                  type="text"
                  onKeyUp={(e) => {
                    formik.setFieldValue("vendor_pay", e.target.value);
                    formik.setFieldValue(
                      "tech_fee",
                      (
                        ((formik.values.vendor_pay
                          ? formik.values.vendor_pay
                          : 0) /
                          100) *
                        (+formik.values.service_cost-formik.values.parts_charge)
                      )?.toFixed(2)
                    );
                  }}
                />
              </div>
            )}

          <div className="md:px-2 py-0 !mb-3">
            <FormInput
              errors={errors ? errors : props.errors}
              formik={formik}
              name="service_cost"
              label="Service Cost"
              type="text"
              disabled={true}
            />
          </div>
          <div className="md:px-2 py-0 !mb-3">
            <FormInput
              errors={errors ? errors : props.errors}
              formik={formik}
              name="parts_charge"
              label="Parts Charges"
              type="text"
              disabled={true}
            />
          </div>
          <div className="md:px-2 py-0 !mb-3">
            <FormInput
              errors={errors ? errors : props.errors}
              value={formik.values.service_cost - formik.values.parts_charge }
              name="net_amount"
              label="Net Amount"
              type="text"
              disabled={true}
            />
          </div>
          {(formik.values.goa_tech_fee === "Yes" &&
            formik?.values?.performed_status === "Not Performed") ||
          (formik.values.goa_tech_fee === "No" &&
            formik?.values?.performed_status === "Not Performed" &&
            formik?.values?.paid_via === "CC") ? (
            <div className="md:px-2 py-0 !mb-3">
              <FormInput
                errors={errors ? errors : props.errors}
                formik={formik}
                name="refund"
                label="Refund"
                type="text"
                onKeyUp={(e) => formik.setFieldValue("refund", e.target.value)}
              />
            </div>
          ) : null}
          <div className="md:px-2 py-0 !mb-3">
            <FormInput
              errors={errors ? errors : props.errors}
              formik={formik}
              name="tech_fee"
              label="Tech Fee ($)"
              type="text"
            />
          </div>
          {formik.values.performed_status === "Not Performed" &&
          formik.values.goa_tech_fee === "Yes" ? (
            <div className="md:px-2 py-0 !mb-3">
              <FormInput
                errors={errors ? errors : props.errors}
                formik={formik}
                name="goa_price"
                label="GOA Price ($)"
                type="text"
              />
            </div>
          ) : null}
          <div className="md:px-2 py-0 !mb-3">
            <FormInput
              errors={errors ? errors : props.errors}
              name="profit"
              label="Profit ($)"
              type="text"
              disabled={true}
              value={(
                (formik.values.service_cost !== ""
                  ? (+formik.values.service_cost -formik.values.parts_charge)
                  : 0) -
                (formik.values.tech_fee !== ""
                  ? +formik.values.tech_fee
                  : 0.0) -
                (formik.values.refund ? +formik.values.refund : 0.0) -
                (formik.values.goa_price ? +formik.values.goa_price : 0.0)
              )?.toFixed(2)}
            />
          </div>
        </div>
        {formik.values.performed_status === "Performed" ||
        (formik.values.performed_status === "Not Performed" &&
          formik.values.goa_tech_fee === "Yes") ? (
          <div className="md:grid grid-cols-3 gap-x-1 gap-y-2">
            {formik.values.paid_via !== "Vendor Pay" && (
              <div className="md:px-2 py-0 !mb-3 relative items-center">
                <FormRadioGroup
                  errors={errors ? errors : props.errors}
                  name="confirmed_cx"
                  label="Confirmed CX"
                  labelProp="label"
                  valueProp="value"
                  options={[
                    { label: "Call", value: "Call" },
                    { label: "SMS", value: "SMS" },
                    { label: "No Answer", value: "No Answer" },
                  ]}
                  formik={formik}
                  type="text"
                  isHorizontal
                  isBorder
                  isFloat
                />
              </div>
            )}
            <div className="md:px-2 py-0 !mb-3 relative items-center">
              <FormRadioGroup
                errors={errors ? errors : props.errors}
                name="confirmed_driver"
                label="Confirmed Driver"
                labelProp="label"
                valueProp="value"
                options={[
                  { label: "Call", value: "Call" },
                  { label: "SMS", value: "SMS" },
                  { label: "No Answer", value: "No Answer" },
                ]}
                formik={formik}
                type="text"
                isHorizontal
                isBorder
                isFloat
              />
            </div>
          </div>
        ) : null}
        <div className="grid md:grid-cols-3 mb-2">
          {formik.values.is_closed ? (
            <div className="md:px-2 py-0 ">
              <FormInput
                errors={errors ? errors : props.errors}
                name="closed_date"
                label="Closed Job Stamp Date"
                type="text"
                readOnly={true}
                value={
                  formik.values.closed_date
                    ? dayjs(formik.values.closed_date).format(
                        "ddd, MMM D, YYYY h:mm A"
                      )
                    : ""
                }
              />
            </div>
          ) : (
            <div className="flex flex-col justify-center w-full md:px-2">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  value={selectedDate || new Date()}
                  onChange={(val) => {
                    setSelectedDate(val.toISOString());
                    formik?.setFieldValue("closed_date", val.toISOString());
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size={"small"}
                      label="Closing Date"
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          )}
        </div>
      </div>
      <div className="flex w-full justify-end">
        {formik.values.is_closed ? (
          <Button
            text={"Reopen job"}
            variant="btn_submit"
            className={"mb-2 md:mr-2 w-full md:w-auto"}
            onClick={onUndoClosedJob}
          />
        ) : (
          <Button
            text={"Complete"}
            variant="btn_submit"
            className={"mb-2 md:mr-2 w-full md:w-auto"}
            onClick={onCloseJob}
          />
        )}
      </div>
    </div>
  );
}

export default CloseJob;
