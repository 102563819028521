import React from 'react'
import Breadcrumbs from '../../../components/molecules/Breadcrumbs'
function ReportHead({className}) {
  return (
    <div className="px-3.7 !mt-5 sm:!mt-5 md:!mt-0">
    <div className={`mb-3 ${className}`}>
      <Breadcrumbs/>
    </div>
  </div>
  )
}

export default ReportHead