import React, { useEffect } from "react";
import { FormInput, FormSelectInput } from "../../../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FaInfoCircle } from "react-icons/fa";
import CustomModal from "../../../../components/molecules/Modal/customModal";
import { get_industry_drd } from "../../../../features/IndustrySlice";
import { useSelector, useDispatch } from "react-redux";
import exportReport from "../../../Domains/exportDomainReport";
const DownloadExcelForm = ({ onCancelForm, modalTitle, selectedData }) => {
  const dispatch = useDispatch();
  const { industryDrd } = useSelector((state) => state.industry);
  const validationSchema = Yup.object({
    site_name: Yup.string().required("Site Name is required"),
    industry_id: Yup.string().required("Industry is required"),
  });
  const formik = useFormik({
    initialValues: {
      site_name: "",
      industry_id: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const Header = ["DID", "Site Name", "Industry Id", "Industry Name"];
      const items = selectedData.map((data) => {
        const addNAIfEmpty = (value) =>
          value === undefined || value === null || value === "" ? "N/A" : value;
        const add1IfDidNotEmpty = (dids) =>
          dids === undefined || dids === null || dids === "" ? "" : dids;

        return {
          dids: add1IfDidNotEmpty(data?.did),
          site_name: addNAIfEmpty(values?.site_name),
          industry_id: addNAIfEmpty(values?.industry_id),
          industry_name: addNAIfEmpty(
            industryDrd.find((ind) => ind._id === values?.industry_id)?.name
          ),
        };
      });

      exportReport(items, Header, "DIDs Report", "DIDs Report");
    },
  });

  useEffect(() => {
    dispatch(get_industry_drd());
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <CustomModal
        handleModal={onCancelForm}
        cancelButtonRef={onCancelForm}
        className="max-w-5xl"
        modalType={false}
        title={modalTitle}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
      >
        <div
          class="flex items-center p-4 mb-4 text-lg text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800"
          role="alert"
        >
          <FaInfoCircle />
          <div>
            <span class="font-medium ml-2">
              You have selected {selectedData?.map((item) => item.dids)?.length}{" "}
              DIDs to Export
            </span>
          </div>
        </div>

        <form>
          <div className="grid md:grid-cols-2 gap-4 w-full">
            <FormInput name="site_name" label="Site Name" formik={formik} />

            <FormSelectInput
              name="industry_id"
              label="Industry"
              formik={formik}
              options={industryDrd}
              labelProp="name"
              valueProp="_id"
            />
          </div>

          <div className="flex p-0 mt-2">
            <div
              className={`flex-1 bg-gray-100 p-4 shadow w-full}`}
              style={{ maxHeight: "calc(60vh - 2rem)" }}
            >
              <h2 className="text-lg font-semibold mb-2 sticky top-0 bg-gray-100">
                Selected DIDs
              </h2>
              <div
                className="overflow-y-auto"
                style={{ maxHeight: "calc(40vh - 2rem - 2.5rem)" }}
              >
                {selectedData?.map((item, index) => (
                  <div key={index} className="mb-1 text-blue-600">
                    {item?.did}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-3 flex justify-end">
            <button
              type="button"
              onClick={formik.handleSubmit}
              className="bg-primary-100 text-white text-sm rounded px-4 py-2 font-semibold"
            >
              Download
            </button>
            <button
              type="button"
              onClick={onCancelForm}
              className="bg-black text-white text-sm rounded px-4 py-2 font-semibold ml-2"
            >
              Cancel
            </button>
          </div>
        </form>
      </CustomModal>
    </>
  );
};

export default DownloadExcelForm;
