import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { get_alternate_domains_phone } from "../../../features/domainCenterSlice";
import PageHeader from "../../../components/molecules/PageHeader";
import AddUpdateForm from "./AddUpdateForm";
function AlternateDomainPhone() {
  const { isLoading, alternatePhoneArea } = useSelector(
    (state) => state.domainCenter
  );
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const cancelFormHandler = () => {
    setIsEditing(false);
    setEditingRecord(null);
  };
  useEffect(() => {
    dispatch(get_alternate_domains_phone());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    {
      headerName: "Alternate Code",
      field: "alternate_code",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "areaCode",
      headerName: "Area Codes",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Active",
      field: "isActive",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "deleted_on",
      headerName: "Delete On",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Created At",
      field: "createdAt",
      flex: 1,
      minWidth: 100,
    },
  ];
  const data = alternatePhoneArea?.records;

  return (
    <>
      <PageHeader
        heading="Alternate areacodes"
        isAllowed={true}
        onClick={openFormHandler(0)}
      />
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Alternate Areacode"
          onCancelForm={cancelFormHandler}
        />
      )}
      <div className="bg-white !my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={data?.map((item, index) => {
            let counter = index + 1;
            const {
              areaCode,
              alternate_code,
              deleted_on,
              createdAt,
              isActive,
            } = item;
            return {
              records: { ...item, id: index + 1 },
              counter,
              areaCode,
              alternate_code,
              deleted_on: deleted_on
                ? dayjs(deleted_on).format("ddd, MMM D, YYYY h:mm A")
                : null,
              createdAt: createdAt
                ? dayjs(createdAt).format("ddd, MMM D, YYYY h:mm A")
                : null,
              isActive: isActive ? "Yes" : "No",
            };
          })}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          pagination="No"
          searchable="No"
          totalItems={
            alternatePhoneArea?.totalItems
              ? alternatePhoneArea?.totalItems
              : alternatePhoneArea?.length
          }
          isLoading={isLoading}
        />
      </div>
    </>
  );
}

export default AlternateDomainPhone;
