import React from "react";
import ContentLoader from "react-content-loader";

const GraphLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        width={400}
        height={200}
        viewBox="0 0 400 200"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{ width: "100%", height: "auto" }}
      >
        {/* <rect x="0" y="260" rx="0" ry="0" width="400" height="10" /> */}

        <rect x="13" y="0" rx="3" ry="3" width="35" height="260" />
        <rect x="53" y="80" rx="3" ry="3" width="35" height="180" />

        <rect x="113" y="160" rx="3" ry="3" width="35" height="100" />
        <rect x="153" y="60" rx="3" ry="3" width="35" height="200" />
        
        <rect x="213" y="100" rx="3" ry="3" width="35" height="100" />
        <rect x="253" y="146" rx="3" ry="3" width="35" height="300" />

        <rect x="313" y="140" rx="3" ry="3" width="35" height="100" />
        <rect x="353" y="116" rx="3" ry="3" width="35" height="300" />
      </ContentLoader>
    </>
  );
};

export default GraphLoader;
