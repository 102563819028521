import { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PageHeader from "../../../components/molecules/PageHeader";
import AddCampaignsForm from "./AddCampaignsForm";
import {
  delete_campaigns,
  get_campaigns,
  campaignReset,
  updateActiveAgents,
} from "../../../features/campaignSlice";
import { get_inbound_group } from "../../../features/inboundGroupSlice";
import userPermission from "../../../util/userPermission";
import errorMessage from "../../../util/errorMessage";
import React from "react";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import AddPauseCode from "./PauseCode";
import api from "../../../services/api";
import { DotsLoader } from "../../../components";
const Campaigns = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.campaigns);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [newRecord, setNewRecord] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setIsAdding(false);
  };

  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_campaigns(record_id));
        if (res.payload?.status === 200) {
          toast.success("Campaigns deleted");
          setNewRecord(!newRecord);
        } else {
          errorMessage({
            payload: res.payload,
            action: "Campaigns",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Campaigns", msg: "deleted" });
      }
    }
  };

  const openAddFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsAdding(true);
  };
  const openEditFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  useEffect(() => {
    dispatch(get_campaigns());
    dispatch(get_inbound_group());
    return () => {
      dispatch(campaignReset());
    };
    // eslint-disable-next-line
  }, [newRecord]);
  const campaignFilter = record?.filter(
    (campaign) =>
      campaign?.campaign_name
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      campaign?.campaign_id?.toLowerCase().includes(searchText.toLowerCase()) ||
      campaign?.dial_method?.toLowerCase().includes(searchText.toLowerCase())
  );
  const handleLogoutAgents = async (campaign) => {
    const c = window.confirm(
      `Are you sure to logout ${campaign?.active_agent} agent(s)?`
    );
    if (!c) {
      return;
    }
    setIsLoader(true);
    try {
      const res = await api.get(
        `/api/cc/compaigns/force_logout_active_agents/${campaign?.campaign_id}`
      );
      if (res.status === 200 || res.status === 201) {
        dispatch(get_campaigns());
        toast.success("All agents logged out successfully.");
      } else {
        toast.error("Failed to log out agents.");
      }
    } catch (err) {
      console.log("👊 ~ handleLogoutAgents ~ err:", err);
      toast.error("Failed to log out agents.");
    }
    setIsLoader(false);
  };
  const columnDefs = [
    { headerName: "#", field: "counter" },
    { headerName: "Campaign ID", field: "campaign_id", flex: 1 },
    { headerName: "Campaign Name", field: "campaign_name", flex: 1 },
    { headerName: "Dial Method", field: "dial_method", flex: 1 },
    { headerName: "Status", field: "status", flex: 1 },
    {
      headerName: "Active Agents",
      field: "active_agent",
      flex: 1,
      renderCell: (params) =>
        params.row.active_agent ? (
          <button
            className="text-blue-500 hover:underline"
            onClick={() => handleLogoutAgents(params.row)}
          >
            Logout <strong>{params.row.active_agent}</strong> agent(s)
          </button>
        ) : (
          <span>
            <strong>0</strong> active agent
          </span>
        ),
    },
    {
      field: "actions",
      renderCell: (params) =>
        params.row.records.active === "Y" ? (
          <div>
            {userPermission("Update Compaign") && (
              <FaEdit
                onClick={openEditFormHandler(params.row.records)}
                className="my_navIcon"
                title="Update Compaign"
              />
            )}
            {userPermission("Remove Compaign") && (
              <FaTrashAlt
                onClick={deleteRecordHandler(params.row.records.campaign_id)}
                className="my_navIcon"
                title="Delete Campaign"
              />
            )}
          </div>
        ) : null,
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  const fetchActiveAgent = async (campaignId) => {
    try {
      const response = await api.get(
        `/api/cc/compaigns/total_active_agents/${campaignId}`
      ); // API call with campaign_id
      return response.data; // Assuming the API returns active_agent in the response
    } catch (error) {
      console.error(
        `Failed to fetch active agent for campaign ${campaignId}:`,
        error
      );
      return null; // Return null or handle error appropriately
    }
  };
  const updateCampaignData = async () => {
    const updatedCampaigns = await Promise.all(
      record.map(async (campaign) => {
        const activeAgent = await fetchActiveAgent(campaign.campaign_id); // Fetch active agent
        return {
          ...campaign,
          active_agent: activeAgent, // Add active_agent key
        };
      })
    );

    // Dispatch the updated campaigns to Redux store
    dispatch(updateActiveAgents(updatedCampaigns));
  };
  useEffect(() => {
    if (
      record.length > 0 &&
      record?.some((item) => !("active_agent" in item))
    ) {
      updateCampaignData(); // Fetch and update campaign data when component mounts
    }
  }, [record]);
  return (
    <>
      {isLoader ? <DotsLoader /> : null}
      {isAdding && (
        <AddCampaignsForm
          editingRecord={editingRecord}
          modalTitle="Add Campaigns"
          onCancelForm={cancelFormHandler}
          onSave={setNewRecord}
          newRecord={newRecord}
        />
      )}
      {isEditing && (
        <AddPauseCode
          editingRecord={editingRecord}
          modalTitle="Add Campaigns"
          onCancelForm={cancelFormHandler}
          onSave={setNewRecord}
          newRecord={newRecord}
        />
      )}
      <PageHeader
        route="Call Center > Campaigns"
        heading="Campaigns Listing"
        onClick={openAddFormHandler(0)}
        isAllowed={userPermission("Add Compaign")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={campaignFilter?.map((record, index) => {
            let counter = index + 1;
            let status = record.active === "Y" ? "Active" : "InActive";
            const { active_agent, campaign_name, campaign_id, dial_method } =
              record;
            return {
              counter,
              records: { ...record, _id: campaign_id },
              campaign_id,
              campaign_name,
              dial_method,
              status,
              active_agent,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          paginationMode="client"
        />
      </div>
    </>
  );
};

export default Campaigns;
