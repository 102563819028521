import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, DotsLoader } from "../../../components";
import PageHeader from "../../../components/molecules/PageHeader";
import {
  get_batch_detail,
  get_data_for_export,
  purchase_domains,
} from "../../../features/domainCenterSlice";
import { get_tld } from "../../../features/TldSlice";
import userPermission from "../../../util/userPermission";
import DomainLogViewModal from "./ViewDomainLogs";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { FiChevronDown } from "react-icons/fi";
import { Dropdown } from "primereact/dropdown";
import { get_purcahse_registrar_account } from "../../../features/registrarAccountsSlice";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton, Tooltip } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { get_purcahse_registrar } from "../../../features/purchaseRegistrarSlice";
const BatchViewModal = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const [reloadPage, setReloadPage] = React.useState({
    isReload: "No",
    interval: -1,
  });
  const { isLoading, batchDetail } = useSelector((state) => state.domainCenter);
  const registrar = useSelector((state) => state?.purchaseRegistrar?.record);
  const { record } = useSelector((state) => state?.registrarAccounts);
  const [domainLogs, setDomainLogs] = useState();
  const [searchText, setSearchText] = React.useState("");
  const [isDomainLogs, setIsDomainLogs] = useState(false);
  const [secondsLeft, setSecondsLeft] = React.useState(null);

  React.useEffect(() => {
    dispatch(get_tld());
    dispatch(
      get_batch_detail({
        batch_id: id,
        filters: queryOptions,
        ...sortingModel,
        page: paginationModel?.page,
        size: paginationModel?.pageSize,
      })
    );
    dispatch(get_purcahse_registrar());
    // eslint-disable-next-line
  }, []);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const [loader, setLoader] = useState(false);
  const initialFilters = {
    domains: [],
    purchase_note: "",
    domain_registrar_id: null,
  };
  const [filterData, setfilterData] = useState(initialFilters);
  const handleViewDomainLogs = (data) => {
    setDomainLogs(data);
    setIsDomainLogs(true);
  };
  const [selected, setSelected] = React.useState([]);
  const selectAll = (checked) => {
    if (checked) {
      console.log("condition true");
      // eslint-disable-next-line
      const domains = batchDetail?.locations
        ?.filter(
          ({ availability_status, purchase_status, domain }) =>
            !!domain &&
            availability_status === "Available" &&
            purchase_status === "Pending"
        )
        ?.map((val) => val?.domain);
      setSelected(domains);
    } else {
      setSelected([]);
    }
  };

  const onSingleselect = (domain) => {
    if (selected?.length > 0) {
      if (selected.filter((id) => id === domain).length > 0) {
        const arr = selected.filter((id) => id !== domain);
        setSelected(arr);
      } else {
        setSelected([...selected, domain]);
      }
    } else {
      setSelected([...selected, domain]);
    }
  };
  const isChecked = (domain) => {
    if (selected?.length > 0) {
      if (selected?.filter((id) => id === domain).length > 0) {
        return true;
      }
    }
    return false;
  };
  const handlePurchase = async () => {
    if (filterData?.purchase_note) {
      setLoader(true);
      try {
        const res = await dispatch(
          purchase_domains({ ...filterData, domains: selected })
        );
        if (res?.payload?.status === 200) {
          setfilterData(initialFilters);
          setSelected([]);
          toast.success("Domain purchasing start successfully");
        }
        setLoader(false);
      } catch (err) {
        toast.success("Domain purchasing couldn't be started");
        setLoader(false);
        console.error("🚀 ~ file: index.jsx:146 ~ handlePurchase ~ err:", err);
      }
    } else {
      toast.error("Note is required");
    }
  };
  const hasDesiredStatus =
    batchDetail?.batch?.domain_generation_status &&
    batchDetail?.batch?.domain_generation_status === "Pending";
  React.useEffect(() => {
    const timerID = setInterval(() => {
      if (hasDesiredStatus && reloadPage?.isReload !== "No") {
        if (secondsLeft && secondsLeft > 0) {
          setSecondsLeft((prevSeconds) => prevSeconds - 1);
        } else {
          if (secondsLeft === 0) {
            setSecondsLeft(reloadPage.interval);
            dispatch(
              get_batch_detail({
                batch_id: id,
                filters: queryOptions,
                ...sortingModel,
                page: paginationModel?.page,
                size: paginationModel?.pageSize,
              })
            );
          }
        }
      } else {
        clearInterval(timerID);
      }
    }, 1000);
    return () => {
      clearInterval(timerID);
    };
    // eslint-disable-next-line
  }, [batchDetail, secondsLeft]);
  const columnDefs = [
    {
      headerName: "#",
      field: "counter",
      width: 80,
      cellClassName: "multiline-cell",
    },
    {
      headerName: "State",
      field: "state",
      flex: 1,
      minWidth: 120,
      cellClassName: "multiline-cell",
    },

    { headerName: "City", field: "city", minWidth: 150, flex: 1 },
    {
      headerName: "Generated Domains",
      field: "domain",
      // sortable:false,
      renderCell: (params) => {
        return params?.row?.domain ? (
          <div className="flex flex-row items-center">
            <input
              type={"checkbox"}
              onChange={() => onSingleselect(params?.row?.domain)}
              checked={isChecked(params?.row?.domain)}
              disabled={
                (params?.row?.availability_status === "Available" &&
                  params?.row?.purchase_status !== "Pending") ||
                (params?.row?.availability_status !== "Available" &&
                  params?.row?.purchase_status === "Pending")
              }
              className={`form-checkbox h-5 w-5 text-primary-100 rounded-sm focus:ring-0 mr-2 cursor-pointer  disabled:cursor-not-allowed disabled:text-gray-500 disabled:bg-gray-200`}
            />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://${params?.row?.domain}`}
              key={id}
              className={`${
                params?.row?.domain
                  ? `badge me-1 pe justify-between items-center !mr-1 my-0.5 font-pop hover:underline no-underline ${
                      params?.row?.availability_status?.toLowerCase() ===
                      "pending"
                        ? "bg-secondary !text-white"
                        : params?.row?.availability_status === "in_queue"
                        ? "!bg-primary-75 !text-white"
                        : params?.row?.availability_status === "purchased"
                        ? "bg-success !text-white"
                        : params?.row?.availability_status === "unavailable"
                        ? "bg-danger !text-white"
                        : params?.row?.availability_status === "hasError"
                        ? "bg-danger !text-white"
                        : params?.row?.availability_status === "Available"
                        ? "bg-transparent !text-black"
                        : "Unknown"
                    }`
                  : ""
              }`}
              style={{
                margin: "2px",
                color: "black",
                borderRadius: 2,
                padding: "0.2em 0.6em 0.3em",
                fontWeight: 400,
                fontSize: "110%",
                lineHeight: 1,
                whiteSpace: "nowrap",
                display: "inline-block",
              }}
            >
              {params?.row?.domain ? `${params?.row?.domain}` : null}
            </a>
            <span className="text-xs">{`(${params?.row?.availability_status})`}</span>
          </div>
        ) : (
          <div className="flex flex-row items-center">
            <input
              type={"checkbox"}
              disabled={true}
              className={`form-checkbox h-5 w-5 text-primary-100 rounded-sm focus:ring-0 mr-2 cursor-pointer  disabled:cursor-not-allowed disabled:text-gray-500 disabled:bg-gray-200`}
            />
            <span className="ml-2 text-red-500">
              Not generated
              <span className="text-xs !ml-1">{`(${params?.row?.availability_status})`}</span>
            </span>
          </div>
        );
      },
      flex: 1,
      minWidth: 400,
      renderHeader: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type={"checkbox"}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              selectAll(e.target.checked);
            }}
            checked={
              batchDetail?.locations?.length &&
              selected?.length ===
                batchDetail?.locations?.filter(
                  ({ availability_status, purchase_status, domain }) =>
                    !!domain &&
                    availability_status === "Available" &&
                    purchase_status === "Pending"
                )?.length
            }
            className={`form-checkbox h-5 w-5 text-primary-100 rounded-sm focus:ring-0 cursor-pointer mr-2 cursor-pointer`}
          />
          <span className="font-[500]">{params.colDef.headerName}</span>
        </div>
      ),
    },

    {
      headerName: "Purchase Status",
      field: "purchase_status",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Domain Logic",
      field: "domain_logic",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "History",
      field: "history",
      flex: 1,
      filterable: false,
      align: "center",
      headerAlign: "center",
      minWidth: 150,
      renderCell: (params) =>
        params?.row?.history?.length > 0 ? (
          <div className="flex justify-center items-center w-full">
            <Tooltip
              title={
                <div className="text-sm">
                  {params?.row?.history?.map((item, index) => (
                    <div className="flex flex-row">
                      <span key={index}>{`${index + 1}.`}</span>
                      <div className="flex flex-col ml-1">
                        <span key={index}>{`Domain: ${item.domain}`}</span>
                        <span
                          key={index}
                        >{`Logic: ${item?.domain_logic}`}</span>
                        <span key={index}>
                          {`Status: ${item?.availability_status}`}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              }
            >
              <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
                {params?.row?.history?.length}
              </span>
            </Tooltip>
          </div>
        ) : null,
    },
    {
      headerName: "Latest Message",
      field: "latest_message",
      minWidth: 150,
      flex: 1,
    },
    
  ];
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_batch_detail({
        batch_id: id,
        filters: queryOptions,
        ...sortingModel,
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };

  const filteredRows = batchDetail?.locations?.filter((row) => {
    const state = row.state?.toLowerCase();
    const city = row.city?.toLowerCase();
    const domains = row.domain?.toLowerCase();
    return (
      (state && state?.includes(searchText?.toLowerCase())) ||
      (city && city?.includes(searchText?.toLowerCase())) ||
      (domains &&
        domains?.some((domain) => domain?.includes(searchText?.toLowerCase())))
    );
  });

  const exportData = async () => {
    try {
      const res = await dispatch(get_data_for_export(id));
      if (res?.payload?.status === 200) {
        const data = res.payload.data;
        const zipCodeCounts = {};

        data.forEach((item) => {
          const zipCode = item.zip;
          if (zipCodeCounts.hasOwnProperty(zipCode)) {
            zipCodeCounts[zipCode]++;
          } else {
            zipCodeCounts[zipCode] = 1;
          }
        });
        const filteredCounts = {};

        for (const zipCode in zipCodeCounts) {
          if (zipCodeCounts[zipCode] > 2) {
            filteredCounts[zipCode] = zipCodeCounts[zipCode];
          }
        }
        const currentDate = new Date();

        const rows = [
          [
            "Export Date:",
            dayjs(currentDate).format("ddd, MMM D, YYYY h:mm A"),
          ],
          ["Domains", "City", "State", "Zip", "Zip Population", "Area Codes"], // Add headings
        ];

        data.forEach((item) => {
          const { domain, city, state, zip, zip_population, area_code } = item;

          if (domain.length === 0) {
            rows.push([zip, zip_population, city, state, "", ""]); // Add a row without domains
          } else {
            const domains = domain.map((domain) => domain.domain).join(", ");

            const areaCodes = area_code.split(",");

            const row = [domains, city, state, zip, zip_population];
            areaCodes.forEach((code) => {
              row.push(code.trim());
            });

            rows.push(row);
          }
        });

        const worksheet = XLSX.utils.aoa_to_sheet(rows);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        const fileName = "data.xlsx";

        const blob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });

        if (typeof window.navigator.msSaveBlob !== "undefined") {
          // For IE browser
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          // For other browsers
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName;
          link.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
          }, 100);
        }
      }
    } catch (err) {
      console.error("🚀 ~ file: ViewBatch.jsx:322 ~ exportData ~ err:", err);
    }
  };

  const option = [
    { label: "Don't Reload", value: "No" },
    { label: "10 Sec", value: "10" },
    { label: "20 Sec", value: "20" },
    { label: "30 Sec", value: "30" },
    { label: "Custom", value: "custom" },
  ];
  const [showExportOptions, setShowExportOptions] = React.useState(false);
  const optionsRef = React.useRef();
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setShowExportOptions(false);
    }
  };
  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setShowExportOptions(false);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const LineOne = () => {
    return (
      <div className="w-[20% justify-center items-center md:justify-end gap-2 flex md:!mt-0 md:col-span-2 pr-1">
        <div className="grid grid-cols-2 gap-2 ml-">
          {[
            ...(batchDetail?.summary?.domainAvailabilityCounts ?? []),
            ...(batchDetail?.summary?.domainPurchaseStatusCounts ?? []),
          ]?.map((item) => {
            const { status, count } = item;
            return (
              <button
                className={`flex flex-co justify-between min-w-[150px] w-full items-center text-ml-3 !p-1 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
    ${status === status ? "border-l-2 border-primary-100" : ""}
    `}
                onClick={() => console.log(status)}
              >
                <span className="text-sm font-pop">
                  {status === "ReadyToPurchase" ? "In Queue" : status}
                </span>
                <span className="text-base font-medium font-pop">
                  {`${count}`}
                </span>
              </button>
            );
          })}
        </div>
        {reloadPage?.isReload === "custom" ? (
          <input
            type="text"
            className="h-[31px] bg-white border border-gray-300 text-gray-900 text-sm rounded-[0.2rem] w-[119px] p-2.5 "
            placeholder="Seconds"
            value={reloadPage?.interval}
            onChange={(e) => {
              setReloadPage({ ...reloadPage, interval: e.target.value });
            }}
            onFocus={() => setSecondsLeft("")}
            onBlur={(e) => {
              setSecondsLeft(e.target.value);
            }}
            autoFocus={reloadPage.isReload === "custom" && !secondsLeft}
          />
        ) : null}
        <div className="relative pr-1">
          <button
            onClick={() => setShowExportOptions(!showExportOptions)}
            text="Export"
            className="relative float-right border min-w-[125px] w-full h-[31px] text-sm flex justify-between items-center px-0.5 rounded-[0.2rem] text-black font-regular"
          >
            <span>
              {
                option?.find(({ value }) => value === reloadPage.isReload)
                  ?.label
              }
            </span>
            <span>
              <FiChevronDown />
            </span>
          </button>
          {showExportOptions && (
            <div className="absolute top-full rounded-t-[0.2rem] !mt1.25 bg-white z-10 border-x border-b border-[#ddd] -ml-px overflow-hidden transition ease-in-out delay-150">
              <ul ref={optionsRef} className="!pl-0 !mb-0">
                {option?.map((option, index) => {
                  return (
                    <li
                      key={index}
                      className="cursor-pointer px-2.5 !py-1.25 border-t border-[#ddd] hover:bg-[#e1e1e1]"
                      onClick={(e) => {
                        setReloadPage({
                          isReload: option?.value,
                          interval:
                            option?.value === "custom"
                              ? ""
                              : option?.value === "No"
                              ? -1
                              : +option?.value,
                        });
                        setSecondsLeft(
                          option?.value === "custom"
                            ? ""
                            : option?.value === "No"
                            ? -1
                            : +option?.value
                        );
                        setShowExportOptions(!showExportOptions);
                      }}
                    >
                      {option.label}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  };

  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      dispatch(
        get_batch_detail({
          ...sortingModel,
          batch_id: id,
          page: 1,
          size: paginationModel.pageSize,
        })
      );
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : // : rule.operator === "isEmpty"
                // ? "eq"
                rule.operator,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    dispatch(
      get_batch_detail({
        ...sortingModel,
        batch_id: id,
        filters: queryOptions,
        page: 1,
        size: paginationModel.pageSize,
      })
    );
  };

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        {selected?.length ? (
          <span className="text-sm font-semibold bg-[#fcebc5] py-1 px-1.5">
            {`${selected?.length} domains are selected `}
            <span
              className="text-blue-600 hover:underline cursor-pointer"
              onClick={() => setSelected([])}
            >
              Clear
            </span>
          </span>
        ) : null}
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
      </GridToolbarContainer>
    );
  }
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    dispatch(
      get_batch_detail({
        batch_id: id,
        filters: queryOptions,
        page: 1,
        size: paginationModel.pageSize,
        sort_field: params[0]?.field?.includes("_in")
          ? params[0]?.field.replaceAll("_in", "")
          : params[0]?.field,
        sort_by: params[0]?.sort || "default",
      })
    );
  };
  const Summary = () => {
    return (
      <>
        {[
          batchDetail?.summary?.domainAvailabilityCounts ?? [],
          ...(batchDetail?.summary?.domainPurchaseStatusCounts ?? []),
        ]?.map((item) => {
          const { status, count } = item;
          return (
            <button
              className={`flex mt-2 flex-co justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
    ${status === status ? "border-l-2 border-primary-100" : ""}
    `}
              onClick={() => console.log(status)}
            >
              <span className="text-sm font-pop">{status}</span>
              <span className="text-base font-medium font-pop">
                {`${count}`}
              </span>
            </button>
          );
        })}
      </>
    );
  };
  return (
    <div>
      <PageHeader
        route="Setting > Vendors"
        heading="View Batch Detail"
        isAllowed={userPermission("null")}
      />
      {loader && <DotsLoader />}
      {selected?.length > 0 && (
        <div className="grid grid-cols-3 gap-x-2 items-center mb-2">
          <div className="w-full">
            <label className="inputLabel" htmlFor="industries">
              {"Purchase Note"}
            </label>
            <input
              className="form-control"
              placeholder="Note"
              onChange={(e) =>
                setfilterData({
                  ...filterData,
                  purchase_note: e.target.value,
                })
              }
              value={filterData?.purchase_note}
            />
          </div>
          <div className="">
            <label className="inputLabel" htmlFor="industries">
              {"Domain Registrar"}
            </label>
            <Dropdown
              value={filterData.domain_registrar_id}
              onChange={(e) =>
                setfilterData({
                  ...filterData,
                  domain_registrar_id: e.value,
                })
              }
              options={registrar}
              optionLabel={(option) =>
                `${option.registrar_company} - ${option?.username}`
              }
              optionValue="_id"
              placeholder="Select Purchase Registrar"
              className="h-[38px] min-w-[255pxz w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              showClear
            />
          </div>
          <div className=" mt-4">
            <Button
              text={"Purchase"}
              className="py-1.5 px-3 align-middle mr-2 bg-primary-100 text-white"
              onClick={handlePurchase}
            />
            <Button
              text="Clear"
              className="py-1.5 px-3 align-middle bg-menu text-white"
              onClick={() => {
                // dispatch(resetBuyingDomains())
                setfilterData({
                  ...filterData,
                  domain_registrar_id: null,
                  purchase_note: "",
                });
              }}
            />
          </div>
        </div>
      )}
      <div
      // className="z-10 w-auto w-full md:max-w-[1024px] md:min-w-[730px] max-h-[calc(100vh-130px)] overflow-y-auto bg-neutral-100 p-3.5 border-[10px] border-white"
      // ref={modalRef}
      >
        {isDomainLogs && (
          <DomainLogViewModal
            data={domainLogs}
            onCancelForm={() => setIsDomainLogs(false)}
            title="View Domain Logs"
          />
        )}
        <div className="pt-0 px-3.5 m-auto">
          <div className="md:p-4">
            <div className="grid grid-cols-2 gap-x-2 items-center mb-2">
              <div className="span-col-1 w-full">
                <small>TLDs</small>
                <div className="w-full form-control h-auto d-inline-flex flex-wrap min-h-[38px] mr-2">
                  {batchDetail?.batch?.tlds?.map((val, index) => {
                    return (
                      <div
                        className="badge me-1 pe-1 justify-content-between badge me-1 pe-1 justify-content-between bg-secondary !text-white !mr-2 my-0.5 h-[26.39px]"
                        key={index}
                      >
                        {val}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="span-col-1 w-full">
                <small>Note</small>
                <div className="w-full form-control h-auto d-inline-flex flex-wrap min-h-[44.38px] mr-2">
                  {batchDetail?.batch?.note}
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full mt-2">
              <small>Keywords</small>
              <div className="w-full form-control h-auto d-inline-flex flex-wrap min-h-[38px] mr-2">
                {batchDetail?.batch?.keywords?.map((val, index) => {
                  return (
                    <div
                      className="badge me-1 pe-1 justify-content-between badge me-1 pe-1 justify-content-between bg-secondary !text-white !mr-2 my-0.5 h-[26.39px]"
                      key={index}
                    >
                      {val}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="bg-white mt-3 border rounded mh-70-vh overflow-y-auto">
              <MUIDataTable
                columnDefs={columnDefs}
                items={filteredRows?.map((record, index) => {
                  const {
                    state,
                    city,
                    domain_logs,
                    domain,
                    availability_status,
                    domain_logic,
                    history,
                    purchase_status,
                    latest_message
                  } = record;
                  return {
                    counter: index + 1,
                    records: { ...record, _id: index },
                    state,
                    city,
                    domain_logs,
                    domain: domain,
                    availability_status,
                    domain_logic,
                    history,
                    latest_message,
                    purchase_status:
                      purchase_status === "ReadyToPurchase"
                        ? "In Purchased Queue"
                        : purchase_status,
                  };
                })}
                onPaginationModelChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                paginationMode="client"
                totalItems={batchDetail?.locations?.length}
                searchText={searchText}
                setSearchText={setSearchText}
                paginationModel={paginationModel}
                isLoading={isLoading}
                // gridOptions={getRowHeight}
                additionalMsg={`
                ${
                  hasDesiredStatus && reloadPage?.isReload !== "No"
                    ? `| Reload time ${secondsLeft}`
                    : ""
                }`}
                customSize={batchDetail?.locations?.length}
                additionalStatus={true}
                additionalStatusValue={{
                  inqueue: batchDetail?.in_purchase_queue,
                  purchased: batchDetail?.purchased,
                  error: batchDetail?.purchase_error,
                  selectedRecords: selected?.length,
                }}
                LineOne={LineOne}
                // LineOne2={Summary}
                exportButton={exportData}
                onFilterModelChange={onFilterChange}
                CustomToolbar={CustomToolbar}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                // autoHeight={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BatchViewModal;
