import React, { useState } from "react";
import { Button, FormInput, DotsLoader } from "../../../components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import errorMessage from "../../../util/errorMessage";
import api from "../../../services/api";
const CompanyPassword = () => {
  const [errors, setErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState({
    newPassword: false,
  });

  const initialValues = {
    new_password: "",
  };

  const PasswordSchema = Yup.object().shape({
    new_password: Yup.string()
      .required("New Password is required")
      .min(5, "New Password must be at least 5 characters"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: PasswordSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const payload = {
          new_password: values?.new_password,
        };
        const res = await api.put(
          "/api/users/change_user_company_password",
          payload
        );
        if (res.status === 200 || res.status === 201) {
          toast.success("Password changed successfully!");
        } else {
          errorMessage({
            payload: res.payload,
            setErrors: setErrors,
            action: "Password",
            msg: "changed",
          });
        }
      } catch (err) {
        toast.error(
          err?.response?.data?.message ||
            "An error occurred while changing the password"
        );
      } finally {
        setLoading(false);
      }
    },
  });

  const toggleVisibility = (field) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };
  return (
    <>
      <h5 class="text-client-50 pb-2.5 w-full border-b border-client-50">
        Change Password (Company)
      </h5>
      <div className="border mt-3 mb-3">
        {loading && <DotsLoader />}
        <div className="flex flex-col p-2">
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div className="md:grid grid-cols-1 gap-y-2 mt-1">
                  <div className="relative">
                    <FormInput
                      id="new_password_input"
                      name="new_password"
                      placeholder="New Password"
                      type={
                        passwordVisibility.newPassword ? "text" : "password"
                      }
                      label="New Password"
                      formik={formik}
                      errors={errors}
                    />
                    <div
                      className="absolute top-[8px] !right-2 border-l border-[#dee2e6] rounded flex justify-center items-center h-[25px] !px-1.5 cursor-pointer"
                      onClick={() => toggleVisibility("newPassword")}
                    >
                      {passwordVisibility.newPassword ? (
                        <FaEye className="text-black" />
                      ) : (
                        <FaEyeSlash className="text-black" />
                      )}
                    </div>
                  </div>
                  <div>
                    <Button text="Save" variant="btn_submit" type="submit" />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CompanyPassword;
