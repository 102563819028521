import React, { useState, useEffect, useRef } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Polygon,
} from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { Button } from "../../components";
function JobsOnMap({ onCancelModal, records, selectedState }) {
  const { states } = useSelector((state) => state.geoVendors);
  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const { isLoading } = useSelector((state) => state.leads);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_API,
    libraries: ["places", "drawing", "geometry"],
  });
  const stateLat = states?.filter(({ name }) => name === records?.state);
  const [coords, setCoords] = useState(
    records?.state
      ? {
          lat: +stateLat[0]?.new_lat,
          lng: +stateLat[0]?.new_lng,
        }
      : {
          lat: 10.99835602,
          lng: 77.01502627,
        }
  );
  useEffect(() => {
    setCoords(
      records?.state
        ? {
            lat: +stateLat[0]?.latitude,
            lng: +stateLat[0]?.longitude,
          }
        : {
            lat: 35.699599,
            lng: -116.9426161,
          }
    );
    // eslint-disable-next-line
  }, [records?.length]);
  const [map, setMap] = useState(null);
  const mapRef = useRef();
  const onLoadMap = (map) => {
    mapRef.current = map;
    setMap(map);
  };
  const fitConvexHullToBounds = () => {
    if (map && polygonCoordinates.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      polygonCoordinates.forEach((coord) => {
        bounds.extend(coord);
      });
      map.fitBounds(bounds);
    }
  };

  useEffect(() => {
    fitConvexHullToBounds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygonCoordinates]);
  useEffect(() => {
    if (records) {
      const allCoordinates = [];

      // Iterate through cities and their zip_latlng arrays
      records?.cities?.forEach((city) => {
        city.zip_latlng.forEach((zipInfo) => {
          allCoordinates.push({
            lat: +zipInfo.lat,
            lng: +zipInfo.lng,
          });
        });
      });
      const convexHull = calculateConvexHull(allCoordinates);

      setPolygonCoordinates(convexHull);
    } else {
      setPolygonCoordinates([]);
    }
  }, [records]);
  function calculateConvexHull(points) {
    // Ensure there are at least 3 points to form a convex hull
    if (points.length < 3) {
      return points;
    }

    // Function to find the orientation of three points (p, q, r)
    function orientation(p, q, r) {
      const val =
        (q.lat - p.lat) * (r.lng - q.lng) - (q.lng - p.lng) * (r.lat - q.lat);
      if (val === 0) return 0; // Collinear
      return val > 0 ? 1 : 2; // Clockwise or Counterclockwise
    }
    // Sort points lexicographically by coordinates
    points.sort((a, b) => {
      if (a.lat === b.lat) return a.lng - b.lng;
      return a.lat - b.lat;
    });
    // Initialize two empty arrays for upper and lower hulls
    const upperHull = [];
    const lowerHull = [];
    // Build the upper hull
    for (let i = 0; i < points.length; i++) {
      while (
        upperHull.length >= 2 &&
        orientation(
          upperHull[upperHull.length - 2],
          upperHull[upperHull.length - 1],
          points[i]
        ) !== 2
      ) {
        upperHull.pop();
      }
      upperHull.push(points[i]);
    }

    // Build the lower hull
    for (let i = points.length - 1; i >= 0; i--) {
      while (
        lowerHull.length >= 2 &&
        orientation(
          lowerHull[lowerHull.length - 2],
          lowerHull[lowerHull.length - 1],
          points[i]
        ) !== 2
      ) {
        lowerHull.pop();
      }
      lowerHull.push(points[i]);
    }

    // Concatenate the upper and lower hulls to form the convex hull
    return upperHull.concat(lowerHull.slice(1, -1));
  }
  const center = {
    lat: 28.074742, // Replace with your desired map center latitude
    lng: -80.632606, // Replace with your desired map center longitude
  };
  const [visibleMarkers, setVisibleMarkers] = useState([]);
  useEffect(() => {
    if (mapRef.current) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: center,
        zoom: 10,
      });

      // Add event listener to update visible markers when the map is moved or zoomed
      map.addListener("bounds_changed", () => {
        const bounds = map.getBounds();

        // Filter the zip codes based on whether they are within the current bounds
        const markersWithinBounds = records.cities.flatMap((city) =>
          city.zip_latlng.filter((zipInfo) =>
            bounds.contains(
              new window.google.maps.LatLng(
                parseFloat(zipInfo.lat),
                parseFloat(zipInfo.lng)
              )
            )
          )
        );

        setVisibleMarkers(markersWithinBounds);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const subTotalofZips = (datat) => {
    const totalStateZips = datat.reduce(
      (acc, cityData) => acc + cityData.zips.length,
      0
    );
    return totalStateZips;
  };
  return (
    <div className="w-full h-screen z-[100] fixed top-0 left-0 opacity-100 bg-black/[0.75] flex items-center justify-center">
      <div className="relative w-full max-w-[1124px] md:min-w-[730px]">
        <div className="absolute top-0 right-0 p-2 z-20 -mt-6 -mr-6">
          <button
            onClick={onCancelModal}
            className="bg-white rounded-full text-gray-700 hover:text-red-500 p-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="z-10 w-full md:max-w-[1224px] md:min-w-[730px] max-h-[calc(100vh-130px)] overflow-y-auto bg-neutral-100 p-3.5 border-[10px] border-white">
          <div className="pt-0 max-w-[1194px] h-full px-3.5 m-auto">
            <header className="bg-primary-100 h-[60px] flex items-center justify-center shadow-lg">
              <h1 className="text-2xl text-white m-4 font-medium">
                {records?.length} Jobs
              </h1>
            </header>
            <div className="md:p-4">
              <div className="">
                {!isLoading && isLoaded && (
                  <GoogleMap
                    center={coords}
                    zoom={8}
                    mapContainerStyle={{ width: "100%", height: "60vh" }}
                    options={{
                      zoomControl: true,
                      fullscreenControl: false,
                      streetViewControl: false,
                      mapTypeControl: false,
                    }}
                    // onClick={() => setActiveMarker(null)}
                    onLoad={onLoadMap}
                    clickableIcons={false}
                  >
                    <Marker
                      icon={{
                        path: "M0,0",
                      }}
                      position={coords}
                    ></Marker>

                    {visibleMarkers?.map((zipInfo) => {
                      return (
                        <>
                          <div
                            key={zipInfo.zip}
                            className=" bg-primary-10 bg-blue-500 text-white py-1 px-1.5 rounded-full cursor-pointer z-100 relative !mr-2"
                            style={{
                              position: "absolute",
                              top: `${parseFloat(zipInfo.lat)}px`,
                              left: `${parseFloat(zipInfo.lng)}px`,
                            }}
                          >
                            {zipInfo.zip}
                          </div>
                          <Marker
                            position={{
                              lat: parseFloat(zipInfo.lat),
                              lng: parseFloat(zipInfo.lng),
                            }}
                          ></Marker>
                        </>
                      );
                    })}
                    {polygonCoordinates.length > 0 && (
                      <Polygon
                        path={polygonCoordinates}
                        options={{
                          fillColor: "rgba(0, 0, 255, 0.2)",
                          strokeColor: "blue",
                          strokeWeight: 2,
                        }}
                        editable
                      />
                    )}
                  </GoogleMap>
                )}
              </div>

              <div className="!p-4 flex flex-row justify-between text-normal font-semibold font-pop">
                <span className="text-xl font-bolder">
                  {`State: ${
                    records?.state ? records?.state : ""
                  } | Cities Qty: ${
                    records?.cities?.length ? records?.cities?.length : 0
                  } | ZipCodes Qty: ${
                    records?.cities?.length
                      ? subTotalofZips(records?.cities)
                      : 0
                  }`}
                </span>
                <Button
                  text="Cancel"
                  className="mx-2"
                  onClick={onCancelModal}
                  variant="btn_cancel"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobsOnMap;
// {records?.map((job, index) => {
//   return (
//     <>
//       {job?.pickup_latitude && job?.pickup_longitude && (
//         <Marker
//           position={{
//             lat: +job?.pickup_latitude,
//             lng: +job?.pickup_longitude,
//           }}
//           onClick={() => handleActiveMarker(job?.lead_num)}
//           icon={{
//             scaledSize: new window.google.maps.Size(24, 37),
//           }}
//           key={index}
//         >
//           {
//             <OverlayView
//             position={{
//               lat: +job?.pickup_latitude,
//               lng: +job?.pickup_longitude,
//             }}
//             mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
//           >
//             <div
//               className=" bg-primary-10 bg-blue-500 text-white py-1 px-1.5 rounded-full cursor-pointer z-100 relative !mr-2"
//               onClick={() =>
//                 handleActiveMarker(job?.lead_num)
//               }
//             >
//               Job Count at this lat lng
//             </div>
//           </OverlayView>
//           }
//           {activeMarker === job.lead_num ? (
//             <InfoWindow
//               onCloseClick={() => setActiveMarker(null)}
//               position={{
//                 lat: +job?.pickup_latitude,
//                 lng: +job?.pickup_longitude,
//               }}
//             >
//               <div
//                 className={styles.info_window}
//                 ref={optionsRef}
//               >
//                 <ul className="pl-2 ">
//                   <li>
//                     <FaBusinessTime
//                       className="mr-2"
//                       size={20}
//                     />
//                     Job #{" "}
//                     {job?.job_number
//                       ? job?.job_number
//                       : job?.lead_num}
//                   </li>
//                   <li>
//                     <SlUser className="mr-2" size={20} />
//                     {job?.name}
//                   </li>
//                   <li>
//                     <FiPhone className="mr-2" size={20} />
//                     {job?.phone}
//                   </li>
//                   <li>
//                     <CiCalendarDate
//                       className="mr-2"
//                       size={20}
//                     />
//                     {job?.scheduled_on
//                       ? dayjs(job?.scheduled_on).format(
//                           "ddd, MMM D, YYYY h:mm A"
//                         )
//                       : "No scheduled date selected"}
//                   </li>
//                   <li>
//                     <BiMap className="mr-2" size={20} />
//                     {job?.complete_pickup_address}
//                   </li>
//                   <li>
//                     <SlWrench className="mr-2" size={20} />
//                     {job?.job_category_id?.name}
//                   </li>
//                 </ul>
//               </div>
//             </InfoWindow>
//           ) : null}
//         </Marker>
//       )}
//     </>
//   );
// })}
