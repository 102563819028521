import api from "./api";

export const getGscEmailAccounts = async () => {
  return await api.get(`/api/gsc/server`);
};
export const createGscEmailAccounts = async (data) => {
  let payload = { ...data };
  delete payload.app_url;
  return await api.post("/api/gsc/server", payload);
};
export const updateGscEmailAccounts = async (data) => {
  let payload = { ...data };
  delete payload.id;
  delete payload.app_url;
  return await api.put(`/api/gsc/server/${data.id}`, payload);
};
export const serverDeployment = async ({ server_id, type }) => {
  return await api.post(`/api/gsc/server/deploy/${server_id}`, {
    type,
  });
};
export const deleteGscEmailAccounts = async (id) => {
  return await api.delete(`/api/gsc/server/${id}`);
};
