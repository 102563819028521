import React, { useEffect, useState } from "react";
import CardTypeForm from "./CardTypeForm";
import { FaEdit } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { cardTypeReset, get_card_types } from "../../features/cardTypesSlice";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";

const CardType = () => {
  const dispatch = useDispatch();
  const { isLoading, cardTypes } = useSelector((state) => state.cardTypes);
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  useEffect(() => {
    dispatch(
      get_card_types({ data: { page: 1, size: paginationModel.pageSize } })
    );
    return () => {
      dispatch(cardTypeReset());
    };
    // eslint-disable-next-line
  }, []);
  const types = cardTypes.records?.filter((type) =>
    type?.name?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter" },
    { headerName: "NAME", field: "name", flex: 1, minWidth: 200 },
    { headerName: "STATUS", field: "status", flex: 1, minWidth: 200 },
    {
      field: "actions",
      renderCell: (params) =>
        params.row.records.active ? (
          <div>
            {userPermission("Card Types") && (
              <FaEdit
                onClick={openFormHandler(params.row.records)}
                className="my_navIcon"
                title="Update Card Type"
              />
            )}
          </div>
        ) : null,
    },
  ];

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_card_types({
        data: {
          page: +params.page + 1,
          size: params.pageSize,
        },
      })
    );
  };
  return (
    <>
      {isEditing && (
        <CardTypeForm
          editingRecord={editingRecord}
          modalTitle="Add Card Types"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Card Type"
        heading="Card Type Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Card Types")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={types?.map((record, index) => {
            let counter = index + 1;
            let record_id = record._id;
            let records = record;
            let name = record.name;
            let status = record.active ? "Active" : "InActive";
            return {
              counter,
              record_id,
              records,
              name,
              status,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={cardTypes?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default CardType;
