import React from 'react'

const FormInput = ({
    icon,
    name,
    placeholder,
    value,
    isDisable,
    label,
    inputType,
    onChange
  }) => (
    <div className="!mb-3 col-12 px-0 form-group">
      <div className="flex flex-row">
        {icon && (
          <div className="bg-client-100 flex w-[60px] justify-center items-center rounded-l h-10">
            <span
              className="w-6 h-6 bg-center bg-no-repeat bg-contain"
              style={{ backgroundImage: `url(${icon})` }}
            />
          </div>
        )}
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline rounded-l-none"
          name={name}
          placeholder={placeholder}
          value={value}
          disabled={isDisable}
          onChange={onChange}
          type={inputType || "text"}
        />
      </div>
      <small className="font-pop">{label}</small>
    </div>
  );

export default FormInput