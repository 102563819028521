import React, { useState } from "react";
import { GoSearch } from "react-icons/go";
import { HiOutlineChevronDown } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
const CustomDropDown = ({
  options,
  placeholder,
  filter,
  value,
  optionLabel,
  optionValue,
  onChange,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const filteredData = options
    ?.filter((val) => val.includes(searchValue))
    ?.slice(0, 500)
    ?.map((did) => ({
      label: did,
      value: did,
    }))
    .filter(Boolean);
  const dropdownRef = React.useRef(null);

  const isSelected = (id) => {
    if (value) {
      if (value === id) {
        return true;
      }
    }
    return false;
  };
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setShowOptions(false);
    }
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => {
          // setSearchValue("");
          setShowOptions(!showOptions);
        }}
        className="block w-full !border !border-[#a9a9a9] bg-white rounded py-1.5 px-2 flex items-center justify-between h-[40px]"
      >
        <span
          className={`flex items-center text-base  justify-between w-full 
          ${value ? "text-[#495057]" : "text-[#6c757d]"}
          `}
        >
          <span className="w-full truncate text-left">
            {value ? options?.find((item) => item === value) : placeholder}
          </span>
          <div className="flex items-center justify-end w-auto">
            {value ? (
              <RxCross2
                className=" cursor-pointer"
                size={20}
                onClick={(event) => {
                  event.stopPropagation();
                  onChange(null);
                }}
              />
            ) : null}
            <HiOutlineChevronDown className=" cursor-pointer" size={20} />
          </div>
        </span>
      </button>
      {showOptions && (
        <div className="absolute mt1 m bg-white border-gray-300 w-auto min-w-[330px] left-0 transform -translate-x-/2 rounded shadow-dropdown z-10">
          <div className="flex flex-row items-center justify-between !py-3 !px-5 bg-[#f8f9fa] h-16">
            {filter ? (
              <div className="relative w-full px-3.7">
                <input
                  className={
                    "appearance-none border rounded w-full !py-2 !pl-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-sm border w-full !pl-15 h-[38px]"
                  }
                  style={{ minHeight: 31 }}
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
                <div className="absolute top-0 right-2 flex items-center h-full pr-3">
                  {searchValue?.length > 0 ? (
                    <RxCross2
                      className="text-[#6c757d] cursor-pointer mr-1"
                      size={20}
                      onClick={(event) => {
                        event.stopPropagation();
                        setSearchValue("");
                      }}
                    />
                  ) : null}
                  <GoSearch className="text-[#6c757d] cursor-pointer" />
                </div>
              </div>
            ) : null}
            <div
              className="flex items-center justify-center rounded-full hover:bg-[#eaecef] !p-1 cursor-pointer"
              onClick={() => setShowOptions(!showOptions)}
            >
              <RxCross2 className="text-[#6c757d]" size={20} />
            </div>
          </div>
          <div className="!py-2 max-h-[250px] h-auto overflow-y-auto">
            {filteredData?.length > 0 ? (
              filteredData?.map((option, index) => (
                <div
                  key={index}
                  className={`${
                    isSelected(option[optionValue])
                      ? "text-[#4338CA] bg-[#EEF2FF]"
                      : ""
                  }`}
                  onClick={() => {
                    onChange(option[optionValue]);
                    setShowOptions(false);
                  }}
                >
                  <div
                    className={`flex items-center !py-2 !px-5 cursor-pointer 
                  ${isSelected(option[optionValue]) ? "" : "hover:bg-[#eaecef]"}
                  `}
                  >
                    <label
                      className={`text-base font-medium cursor-pointer ${
                        isSelected(option[optionValue])
                          ? "text-[#4338CA]"
                          : "text-[#495057]"
                      }`}
                    >
                      {option[optionLabel]}
                    </label>
                  </div>
                </div>
              ))
            ) : (
              <div className="font-semibold font-pop text-noral flex justify-center items-center">
                No record found
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDropDown;
