import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Button,
  DotsLoader,
  FormInput,
  FormSelectInput,
} from "../../../components";
import PageHeader from "../../../components/molecules/PageHeader";
import { useFormik } from "formik";
import AddMenuOptionForm from "./AddMenuOption";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useLocation, useParams } from "react-router-dom";
import api from "../../../services/api";
import { useDispatch } from "react-redux";
import { get_inbound_group } from "../../../features/inboundGroupSlice";
import {
  get_audio_files,
  update_call_menu,
} from "../../../features/callCentersSlice";
import { useSelector } from "react-redux";
function UpdateCallMenu() {
  const { isLoading, audioFiles } = useSelector((state) => state.callCenters);
  const location = useLocation();
  const data = location.state;
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const dispatch = useDispatch();
  let initialValues = {
    menu_id: "",
    menu_name: "",
    menu_prompt: "",
    menu_timeout_prompt: "",
    menu_timeout: "",
    menu_invalid_prompt: "",
    menu_repeat: "",
  };
  if (data) {
    const {
      menu_id,
      menu_name,
      menu_prompt,
      menu_timeout_prompt,
      menu_timeout,
      menu_invalid_prompt,
      menu_repeat,
    } = data;
    initialValues = {
      menu_id,
      menu_name: menu_name ? menu_name : "",
      menu_prompt: menu_prompt ? menu_prompt : "",
      menu_timeout_prompt: menu_timeout_prompt ? menu_timeout_prompt : "",
      menu_timeout: menu_timeout ? menu_timeout : "",
      menu_invalid_prompt: menu_invalid_prompt ? menu_invalid_prompt : "",
      menu_repeat: menu_repeat ? menu_repeat : "",
    };
  }
  const [errors, setErrors] = useState([]);
  const { record } = useSelector((state) => state.inbound);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [editingRecord, setEditingRecord] = useState(null);
  const [callManu, setCallMenu] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [formikData, setFormikData] = React.useState({});
  const params = useParams();
  const { id } = params;
  const routeOption = [
    { value: "NONE", label: "NONE" },
    { value: "CALLMENU", label: "Call Menu" },
    { value: "INGROUP", label: "Ring Group" },
    { value: "DID", label: "DID" },
    { value: "HANGUP", label: "HANGUP" },
    { value: "EXTENSION", label: "External Number" },
    // { value: "AGI", label: "AGI" },
  ];
  const getCallMenuDetail = async () => {
    try {
      const res = await api.get(`/api/cc/dids/options/call_menu/${id}`);
      if (res.status === 200) {
        setFormikData(res.data?.call_menu[0]);
        const callMenuOptions = res.data?.call_menu_options?.map((item) =>
          item?.option_route === "EXTENSION"
            ? {
                ...item,
                option_route_value: item?.option_route_value?.replace(
                  user?.conexcs_settings?.dial_out_prefix,
                  ""
                ),
              }
            : item
        );
        setSelectedOptions(callMenuOptions);
      }
    } catch (err) {
      console.log("🚀 ~ getCallMenuDetail ~ err:", err);
    }
  };
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const handleSubmit = async (values) => {
    const callMenuOptions = selectedOptions?.map((item) =>
      item?.option_route === "EXTENSION"
        ? {
            ...item,
            option_route_value: `${
              user?.conexcs_settings?.dial_out_prefix || ""
            }${item?.option_route_value}`,
          }
        : item
    );
    try {
      const res = await dispatch(
        update_call_menu({ ...values, menu_options: callMenuOptions })
      );
      if (res?.payload.status === 200) {
        toast.success("Call menu updated successfully");
      } else {
        toast.error(`Call menu couldn't be updated`);
      }
    } catch (error) {
      if (Array.isArray(error.payload) || typeof error.payload === "object") {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload ? error.payload : `Call menu couldn't be updated`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues: { ...formikData, ...initialValues } || initialValues,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  const get_call_menus = async () => {
    try {
      const res = await api.get("/api/cc/dids/options/call_menus");
      if (res.status === 200) {
        setCallMenu(res.data);
      }
    } catch (err) {
      console.log("🚀 ~ constget_call_menus=async ~ err:", err);
    }
  };
  useEffect(() => {
    get_call_menus();
    dispatch(get_inbound_group());
    dispatch(get_audio_files());
    getCallMenuDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleOptionDelete = (value) => {
    setSelectedOptions(
      selectedOptions?.filter((obj) => obj.option_value !== value)
    );
  };
  return (
    <div>
      {isLoading ? <DotsLoader /> : null}
      <PageHeader
        route="Call Center > Inbound Groups"
        heading="Call Menu Update"
        // onClick={openFormHandler(0)}
        // isAllowed={userPermission("Add Inbound")}
      />
      {isEditing && (
        <AddMenuOptionForm
          editingRecord={editingRecord}
          modalTitle="Add Menu Option"
          onCancelForm={cancelFormHandler}
          setSelectedOptions={setSelectedOptions}
          selectedOptions={selectedOptions}
          callManu={callManu}
        />
      )}
      <div className="bg-white my-3 border rounded">
        <div className="grid md:grid-cols-3 gap-3 mt-3 mx-2.5 w-fu">
          <div className="">
            <FormInput
              errors={errors}
              name="menu_name"
              label="Menu Name"
              formik={formik}
            />
          </div>
          <div className="">
            <FormSelectInput
              errors={errors}
              name="menu_prompt"
              label="Menu Prompt"
              formik={formik}
              options={audioFiles}
              valueProp="audio_filename"
              labelProp="real_file_name"
            />
          </div>
          <div className="">
            <FormSelectInput
              errors={errors}
              name="menu_timeout_prompt"
              label="Menu Timeout Prompt"
              formik={formik}
              options={audioFiles}
              valueProp="audio_filename"
              labelProp="real_file_name"
            />
          </div>
          <div className="">
            <FormInput
              errors={errors}
              name="menu_timeout"
              label="Menu Timeout"
              formik={formik}
            />
          </div>
          <div className="">
            <FormSelectInput
              errors={errors}
              name="menu_invalid_prompt"
              label="Menu Invalid Prompt"
              formik={formik}
              options={audioFiles}
              valueProp="audio_filename"
              labelProp="real_file_name"
            />
          </div>
          <div className="">
            <FormInput
              errors={errors}
              name="menu_repeat"
              label="Menu Repeat"
              formik={formik}
            />
          </div>
        </div>
        <div className="!p-4 !pr-0 text-right">
          <Button
            text="Add Menu Options"
            className="mx-2"
            onClick={openFormHandler(0)}
            variant="btn_submit"
          />
        </div>
        <div className="grid grid-cols-2 gap-3 p-2 max-h-[52vh]">
          {selectedOptions?.map((item, index) => {
            const { option_value, option_route, option_route_value } = item;
            return (
              <div
                className="flex flex-row justify-between items-center shadow-sm p-2"
                key={index}
              >
                <span className="font-bold text-lg text-menu">{`${option_value} - ${
                  routeOption?.find((item) => item?.value === option_route)
                    ?.label
                }${option_route_value ? ":" : ""} ${
                  option_route === "CALLMENU"
                    ? callManu?.find(
                        ({ menu_id }) => menu_id === option_route_value
                      )?.menu_name
                    : option_route === "INGROUP"
                    ? record?.find(
                        ({ group_id }) => group_id === option_route_value
                      )?.group_name
                    : option_route_value
                    ? option_route_value
                    : ""
                }`}</span>
                <div>
                  <FaEdit
                    onClick={openFormHandler(item)}
                    className="my_navIcon"
                    title="Update Call Center"
                  />
                  <FaTrash
                    className="my_navIcon"
                    title="Update Call Center"
                    onClick={() => handleOptionDelete(item?.option_value)}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="w-full flex justify-center my-2">
          <Button
            text="Update"
            onClick={formik.handleSubmit}
            variant="btn_submit"
          />
        </div>
      </div>
    </div>
  );
}

export default UpdateCallMenu;
