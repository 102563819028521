import React, { useEffect, useState } from "react";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";
import api from "../../services/api";
import { userPermissionNew } from "../../util/userPermissionNew";
import ToggleSwitch from "../../components/molecules/ToggleButton";
import { FaTrash, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getFullName } from "../../util/common";
import { get_crm_vendors_drd } from "../../features/usersSlice";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button } from "@mui/material";
import { Loader } from "../../components";
const DivvyVirtualCard = () => {
  const dispatch = useDispatch();
  const [record, setRecord] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isBalanceLoading, setIsBalanceLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 1,
  });
  const { CRMVendorsDrd } = useSelector((state) => state.users);
  const getBudgetUsers = async (payload) => {
    setRecord({});
    setIsLoading(true);
    try {
      const res = await api.post(`/api/bill/all_card_of_towingwiz`, payload);
      if (res.status === 200 || res.status === 201) {
        setRecord(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error || "An error occurred");
      } else {
        toast.error(err?.response?.data || "An error occurred");
      }
    }
  };
  useEffect(() => {
    dispatch(get_crm_vendors_drd());
    getBudgetUsers({
      page: paginationModel?.page,
      size: paginationModel?.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listing = record?.data?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const handleFreezeUnfreeze = async (item, action) => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    setIsLoading(true);
    const msg =
      action === "freeze"
        ? "frozen"
        : action === "unfreeze"
        ? "unfrozen"
        : "deleted";
    try {
      const res = await api.post(`/api/jobs/freeze_unfreeze_virtual_card`, {
        lead_id: item?.job_id,
        card_id: item?.card_id,
        type: action,
      });
      if (res.status === 200) {
        toast.success(`Card ${msg} successfully`);
        getBudgetUsers({
          page: paginationModel?.page,
          size: paginationModel?.pageSize,
        });
      } else {
        toast.error(res?.data?.message || `Card couldn't be ${msg}`);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.message || `Card couldn't be ${msg}`);
      console.log("🚀 ~ handleConfirm ~ err:", err);
    }
  };
  const getBalanceAmount = async (item) => {
    setIsLoading(true);
    try {
      const res = await api.post(
        `/api/jobs/get_virtual_card_balance/${item?.id}`
      );

      if (res.status === 200) {
        const cardBalance =
          +res.data?.currentPeriod?.limit - +res.data?.currentPeriod?.spent;
        toast.success(`Card balance: $${cardBalance}`);
        const currentCardsInfo = record?.data || [];
        const updatedCardsInfo = currentCardsInfo.map((card) => {
          if (card?.virtual_cards_info?.card_info?.id === res.data.id) {
            return {
              ...card,
              virtual_cards_info: {
                ...card.virtual_cards_info,
                balance: cardBalance,
              },
            };
          }
          return card;
        });
        setRecord((prevRecord) => ({
          ...prevRecord,
          data: updatedCardsInfo,
        }));
      } else {
        toast.error(res?.data?.message || "Card request couldn't be rejected");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(
        err?.response?.data?.message || "Card request couldn't be rejected"
      );
      console.log("🚀 ~ handleConfirm ~ err:", err);
    }
  };
  const getBalanceAmounts = async (items) => {
    setIsBalanceLoading(true);

    // Helper function to fetch balance with retry
    const fetchBalance = async (item, maxRetries = 2) => {
      for (let attempt = 1; attempt <= maxRetries; attempt++) {
        try {
          const response = await api.post(
            `/api/jobs/get_virtual_card_balance/${item?.virtual_cards_info?.card_info?.id}`
          );
          if (response.status === 200 && response?.data !== -1) {
            const cardBalance =
              +response.data.currentPeriod.limit -
              +response.data.currentPeriod.spent;
            return { balance: cardBalance, id: response.data.id };
          }
        } catch (error) {
          if (attempt === maxRetries || error?.response?.data === -1) {
            return {
              error: true,
              id: item?.virtual_cards_info?.card_info?.id,
              errors: error,
            };
          }
        }
      }
      return {
        error: true,
        id: item?.virtual_cards_info?.card_info?.id,
        maxRetries,
        item,
      };
    };

    try {
      // Filter items to fetch
      const itemsToFetch = items.filter(
        (item) =>
          item?.virtual_cards_info?.card_info &&
          item?.virtual_cards_info?.card_info?.status !== "DELETED"
      );

      // Make requests in parallel
      const balanceResults = await Promise.all(
        itemsToFetch.map((item) => fetchBalance(item))
      );

      let updatedCardsInfo = [...(record?.data || [])];

      // Process results and retry if needed
      for (const result of balanceResults) {
        if (result.error) {
          // toast.error(`Failed to get balance for card ${result.id}`);
        } else {
          // Update card balance in `updatedCardsInfo`
          updatedCardsInfo = updatedCardsInfo.map((card) => {
            if (card?.virtual_cards_info?.card_info?.id === result.id) {
              return {
                ...card,
                virtual_cards_info: {
                  ...card.virtual_cards_info,
                  balance: result.balance,
                },
              };
            }
            return card;
          });
        }
      }

      setRecord((prevRecord) => ({
        ...prevRecord,
        data: updatedCardsInfo,
      }));
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Failed to fetch card balances"
      );
      console.error("Error in fetching balances:", err);
    } finally {
      setIsBalanceLoading(false);
    }
  };

  useEffect(() => {
    if (record?.data && record?.data?.length) getBalanceAmounts(record?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record?.data?.length]);

  const handleConfirm = async (item, action) => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    setIsLoading(true);
    const msg =
      action === "freeze"
        ? "frozen"
        : action === "unfreeze"
        ? "unfrozen"
        : "deleted";
    try {
      const res = await api.post(
        `/api/jobs/${action}_virtual_card/${item?.job_id}/${item?.card_id}`
      );
      if (res.status === 200) {
        toast.success(`Card ${msg} successfully`);
        getBudgetUsers({
          page: paginationModel?.page,
          size: paginationModel?.pageSize,
        });
      } else {
        toast.error(res?.data?.message || `Card couldn't be ${msg}`);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.message || `Card couldn't be ${msg}`);
      console.log("🚀 ~ handleConfirm ~ err:", err);
    }
  };
  const getRowHeight = (params) => {
    const rowHeight = 30; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    if (data?.sent_to && data?.sent_to.length > 0) {
      const tagHeight = 20; // height of each tag
      const numTags = data.sent_to.length;
      return rowHeight + tagHeight * numTags + cellContentHeight;
    }
  };
  const getVendorName = (id) => {
    return CRMVendorsDrd?.find(({ _id }) => _id === id) || null;
  };
  const onSingleSelect = ({ checked, card_id }) => {
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, card_id];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            (id) => id !== card_id
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onSelectAll = (checked) => {
    if (checked) {
      setSelectedData(
        listing?.map((item) => item?.virtual_cards_info?.card_info?.id)
      );
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (card_id) => {
    if (selectedData?.length > 0) {
      if (selectedData?.filter((id) => id === card_id).length > 0) {
        return true;
      }
    }
    return false;
  };
  const openNewTab = ({ id }) => {
    if (isButtonDisabled) {
      toast.info("Please wait for a second to open this job");
      return;
    }
    setIsButtonDisabled(true);
    window.open(`/jobs/update/preview/${id}`, "_blank");
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 1000);
  };
  const InQueue = () => {
    return (
      <div className="flex flex-row items-center ">
        <strong className="text-red-500">Total Cards in Delete Queue:</strong>
        <strong className="ml-1">{record?.totalCardInQueueCount}</strong>
      </div>
    );
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: () => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={listing?.length && selectedData?.length === listing?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => {
        return (
          <input
            type="checkbox"
            checked={isSelected(params.row?.card_info?.id)}
            onChange={(e) => {
              onSingleSelect({
                checked: e.target.checked,
                card_id: params.row?.card_info?.id,
              });
            }}
            className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          />
        );
      },
      disableColumnMenu: true,
    },
    {
      headerName: "#",
      field: "counter",
      sortable: false,
      filterable: false,
      width: 60,
    },
    {
      field: "job_number",
      headerName: "Job #",
      renderCell: (params) => (
        <span
          onClick={() =>
            openNewTab({ id: params.row.job_id, jobNo: params.row.job_number })
          }
          className="text-blue-600 cursor-pointer hover:underline"
        >
          {params.row.job_number}
        </span>
      ),
      width: 60,
    },
    {
      headerName: "Cardholder Name",
      field: "name",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Vendor Name",
      field: "vendor_name",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        // const vendors = getUniqueVendors(params.row?.sent_to);
        const vendors = params.row?.sent_to;
        return vendors?.length ? (
          <div className="flex flex-col">
            {vendors?.map((vendor, index) => {
              return (
                <span key={index} className="font-bol">
                  {getFullName(getVendorName(vendor?.vendor_id))}
                </span>
              );
            })}
          </div>
        ) : (
          <span className="font-bol">
            {getFullName(getVendorName(params?.row?.vendor_id))}
          </span>
        );
      },
    },
    { headerName: "Last 4 digits", field: "lastFour", flex: 1, minWidth: 80 },
    {
      headerName: "Requested Amount",
      field: "requested_amount",
      flex: 1,
      minWidth: 80,
      type: "number",
    },
    {
      headerName: "Sanctioned Amount",
      field: "sanction_amount",
      flex: 1,
      minWidth: 80,
      type: "number",
    },
    {
      headerName: "Final Amount",
      field: "sanctioned_amount",
      flex: 1,
      minWidth: 80,
      type: "number",
    },
    userPermissionNew("Show Vendor Balance")
      ? {
          headerName: "Balance",
          field: "balance",
          flex: 1,
          minWidth: 100,
          align: "right",
          type: "number",
          renderCell: (params) => {
            return isBalanceLoading ? (
              <Loader size={5} width={20} height={20} />
            ) : params?.row?.records?.virtual_cards_info?.balance ||
              params?.row?.records?.virtual_cards_info?.balance === 0 ? (
              <span className="teext-right">
                {params?.row?.records?.virtual_cards_info?.balance}
              </span>
            ) : params.row.card_info?.status === "DELETED" ? (
              <span>0</span>
            ) : params?.row?.card_info ? (
              <button
                className="text-blue-500 hover:underline"
                type="button"
                onClick={() => getBalanceAmount(params?.row?.card_info)}
              >
                Show Balance
              </button>
            ) : (
              <span>-</span>
            );
          },
        }
      : null,
    { headerName: "Status", field: "status", flex: 1, minWidth: 100 },
    userPermissionNew("Freeze/Unfreeze Card")
      ? {
          headerName: "Freeze/Unfreeze",
          field: "freeze",
          flex: 1,
          headerAlign: "center",
          minWidth: 130,
          renderCell: (params) => {
            return params.row.requested_status === "APPROVED" &&
              params.row.card_info?.status !== "DELETED" ? (
              <ToggleSwitch
                checked={params.row?.card_info?.status === "FROZEN"}
                unique_by={params?.row?.records?._id}
                onChange={() => {
                  if (params.row?.card_info?.status === "FROZEN") {
                    handleFreezeUnfreeze(
                      {
                        job_id: params?.row?.job_id,
                        card_id: params?.row?.card_id,
                      },
                      "unfreeze"
                    );
                  } else {
                    handleFreezeUnfreeze(
                      {
                        job_id: params?.row?.job_id,
                        card_id: params?.row?.card_id,
                      },
                      "freeze"
                    );
                  }
                }}
              />
            ) : null;
          },
        }
      : null,
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex flex-row">
          {userPermissionNew("Delete Vendor Card") &&
          params.row.requested_status === "APPROVED" &&
          params.row.records.card_info?.status !== "DELETED" ? (
            <FaTrashAlt
              onClick={() =>
                handleConfirm(
                  {
                    job_id: params?.row?.job_id,
                    card_id: params?.row?.card_id,
                  },
                  "delete"
                )
              }
              className="my_navIcon"
              title="Delete Card"
            />
          ) : null}
        </div>
      ),
      width: 100,
      filterable: false,
    },
  ].filter(Boolean);

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getBudgetUsers({
      page: params.page + 1,
      size: params.pageSize,
    });
  };
  const handleBulkDelete = async () => {
    const c = window.confirm(
      `Are you sure want to delete the selected records?`
    );
    if (c) {
      try {
        const res = await api.post("/api/bill/delete_virtual_card_in_bulk", {
          card_ids: selectedData,
        });
        if (res?.status === 200) {
          toast.success(res?.data || "Records deleted successfully");
          setSelectedData([]);
          getBudgetUsers({
            page: paginationModel?.page,
            size: paginationModel?.pageSize,
          });
        } else {
          toast.error(res?.data?.message || "Records couldn't deleted");
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || "Records couldn't deleted");
        console.error("🚀 ~ file: index.jsx:172 ~ handleDelete ~ err:", err);
      }
    }
  };
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className="py-2">
        {selectedData?.length ? (
          <span className="text-sm font-semibold bg-[#fcebc5] py-1 px-1.5">
            {`${selectedData?.length === record?.data?.length ? "All " : ""}${
              selectedData?.length
            } items are selected `}
            <span
              className="text-blue-600 hover:underline cursor-pointer"
              onClick={() => setSelectedData([])}
            >
              {"Clear Selection?"}
            </span>
          </span>
        ) : null}

        <GridToolbarColumnsButton className="!text-[#042a42] btnSecondary" />
        <GridToolbarDensitySelector className="!text-[#042a42] btnSecondary" />
        <GridToolbarFilterButton
          // ref={setFilterButtonEl}
          className="!text-[#042a42] btnSecondary"
        />
        {selectedData?.length ? (
          <Button
            variant="text"
            onClick={handleBulkDelete}
            startIcon={<FaTrash size={16} />}
            sx={{ fontSize: "0.8125rem" }}
            className="!text-[#042a42] btnSecondary"
          >
            Delete Selected
          </Button>
        ) : null}
      </GridToolbarContainer>
    );
  };
  return (
    <>
      <PageHeader heading="Cards Listing" />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={listing?.map((record, index) => {
            let counter = index + 1;
            const { _id, virtual_cards_info, vendor_id, job_number } = record;
            const {
              card_info,
              requested_amount,
              requested_status,
              sanctioned_amount,
              sent_to,
            } = virtual_cards_info || {};
            const { name = "", lastFour = "", status = null } = card_info || {};
            return {
              counter,
              records: { ...record },
              name,
              job_id: _id,
              card_info,
              requested_amount,
              limit: card_info
                ? card_info?.currentPeriod?.limit
                : requested_amount,
              status: card_info ? status : requested_status,
              sanction_amount: sanctioned_amount ? +sanctioned_amount - 1 : 0,
              sanctioned_amount,
              lastFour,
              requested_status,
              card_id: virtual_cards_info?._id,
              vendor_id,
              sent_to,
              job_number,
            };
          })}
          totalItems={record?.pagination?.totalCards}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          showCount="No"
          height="68vh"
          hidePageSelector="Yes"
          hideTopPageSelector="Yes"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          gridOptions={getRowHeight}
          CustomToolbar={selectedData?.length ? CustomToolbar : null}
          CustomComponent2={record?.totalCardInQueueCount ? InQueue : null}
        />
      </div>
    </>
  );
};

export default DivvyVirtualCard;
