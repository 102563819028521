import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../components";
import { useFormik } from "formik";
import {
  create_payment_gateway,
  update_payment_gateway,
} from "../../features/paymentGatewaySlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import FormRadioGroup from "../../components/molecules/FormRadioGroup";
const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  let initialValues = {
    provider_id: "",
    title: "",
    api_key: "",
    api_password: "",
    api_token: "",
    max_amount: "",
    priority: "",
    has_auth_feature: false,
  };
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.paymentGateway);
  const { record } = useSelector((state) => state.provider);
  const [errors, setErrors] = React.useState([]);
  if (editingRecord) {
    const {
      _id,
      provider_id,
      title,
      api_key,
      api_password,
      api_token,
      max_amount,
      priority,
      active,
      has_auth_feature,
      environment,
    } = editingRecord;
    initialValues = {
      id: _id,
      provider_id: provider_id._id,
      title,
      api_key,
      api_password,
      api_token,
      max_amount,
      priority,
      active,
      has_auth_feature,
      environment,
    };
  }

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (!isLoading) {
        if (!editingRecord) {
          try {
            const res = await dispatch(create_payment_gateway(values));
            if (res?.payload?.status === 200) {
              toast.success("Payment Gateway created");
              onCancelForm();
            } else {
              errorMessage({
                payload: res.payload,
                setErrors: setErrors,
                action: "Payment Gateway",
                msg: "created",
              });
            }
          } catch (error) {
            errorMessage({
              payload: error,
              setErrors: setErrors,
              action: "Payment Gateway",
              msg: "created",
            });
          }
        } else {
          try {
            const res = await dispatch(update_payment_gateway(values));
            if (res?.payload?.status === 200) {
              toast.success("Payment Gateway updated");
              onCancelForm();
            } else {
              errorMessage({
                payload: res.payload,
                setErrors: setErrors,
                action: "Payment Gateway",
                msg: "update",
              });
            }
          } catch (error) {
            errorMessage({
              payload: error,
              setErrors: setErrors,
              action: "Payment Gateway",
              msg: "update",
            });
          }
        }
      } else {
        toast.error("Please wait until previous action be completed");
      }
    },
  });
  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="w-full mt-4 grid md:grid-cols-2 gap-x-5">
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="provider_id"
              label="Provider"
              formik={formik}
              options={record}
              valueProp="_id"
              labelProp="name"
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="title"
              label="Title"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="api_key"
              label="API Key"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="api_password"
              label="API Password"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="api_token"
              label="API Token"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="max_amount"
              label="Max Amount"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="priority"
              label="Priority"
              formik={formik}
            />
          </div>
          {!!editingRecord && (
            <div className="mb-3">
              <FormSelectInput
                errors={errors}
                name="active"
                label="Active"
                formik={formik}
                options={[
                  { value: "true", label: "Yes" },
                  { value: "false", label: "No" },
                ]}
                convertor={(value) => value === "true"}
                valueProp="value"
                labelProp="label"
                disabled={!editingRecord}
              />
            </div>
          )}
          <div className="mb-3 col-span-1">
            <FormSelectInput
              errors={errors}
              name="has_auth_feature"
              label="Auth Feature"
              formik={formik}
              options={[
                { value: "true", label: "Yes" },
                { value: "false", label: "No" },
              ]}
              convertor={(value) => value === "true"}
              valueProp="value"
              labelProp="label"
              isFloat
            />
          </div>
          <div className="mb-2 relative">
            <FormRadioGroup
              errors={errors}
              name="environment"
              label="Environment"
              labelProp="label"
              valueProp="value"
              options={[
                { label: "Sandbox", value: "Sandbox" },
                { label: "Production", value: "Production" },
              ]}
              formik={formik}
              type="text"
              isHorizontal
              isBorder
              isFloat
              customStyle={"bg-white"}
            />
            </div>
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;
