import { Dropdown } from "primereact/dropdown";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DotsLoader } from "../../components";
import { get_gsc_email_accounts } from "../../features/googleEmailSlice";
import api from "../../services/api";

function AddGSCEmailsModal({ onClose, selectedData, get_domain }) {
  const dispatch = useDispatch();
  const { isLoading, emailAccounts } = useSelector(
    (state) => state.googleEmails
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(get_gsc_email_accounts());
    // eslint-disable-next-line
  }, []);
  const handleSubmit = async () => {
    if (!selectedEmail) return toast.error("Please select an email");
    setLoading(true);
    const domainIds = selectedData?.map(({ _id }) => _id);
    try {
      const res = await api.post(`/api/domains/attach_GSC_server`, {
        domains: domainIds,
        GSC_server_id: selectedEmail,
      });
      if (res.status === 200 || res.status === 201) {
        toast.success(res?.data?.message || "Domains added successfully");
        setLoading(false);
        get_domain();
        onClose();
      } else {
        toast.error(
          res?.data?.message || "Domains couldn't be added successfully"
        );
      }
      setLoading(false);
    } catch (err) {
      console.log("🚀 ~ handleSubmit ~ err:", err);
      toast.error(
        err?.response?.data?.message || "Domains couldn't be added successfully"
      );
      setLoading(false);
    }
  };
  const [selectedEmail, setSelectedEmail] = useState("");
  return (
    <>
      <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-50 bg-black/60">
        {isLoading || loading ? <DotsLoader /> : null}
        <div className="bg-white p-8 rounded-lg shadow-lg relative w-[400px]">
          <h2 className="text-lg font-semibold mb-4">
            Add domains to a Google Search Console Email
          </h2>
          <label className="block">Please select a Email:</label>
          <Dropdown
            placeholder="Select an email"
            options={emailAccounts?.records?.filter(
              ({ app_deployed }) => app_deployed
            )}
            optionLabel={({ email, domain_count, domain_limit }) =>
              `${email} (${domain_count}/${domain_limit})`
            }
            optionValue="_id"
            value={selectedEmail}
            onChange={(e) => setSelectedEmail(e.value)}
            className="w-full"
          />

          <div className="flex justify-end !mt-4">
            <button
              className="px-4 py-2 mr-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={handleSubmit}
            >
              OK
            </button>
            <button
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddGSCEmailsModal;
