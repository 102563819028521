import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { create_provider, update_provider } from "../../features/ProviderSlice";
import errorMessage from "../../util/errorMessage";

const AddUpdateProvider = ({ editingRecord, onCancelForm, modalTitle }) => {
  let initialValues = {
    name: "",
    key_code: "",
    url: "",
    active: "",
    sandbox_url: "",
  };
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.provider);
  const [errors, setErrors] = React.useState([]);

  if (editingRecord) {
    const { _id, name, key_code, url, sandbox_url, active } = editingRecord;
    initialValues = { id: _id, name, key_code, url, sandbox_url, active };
  }

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (!isLoading) {
        if (!editingRecord) {
          const { active, ...payload } = values;
          try {
            const res = await dispatch(create_provider(payload));
            if (res?.payload?.status === 200) {
              toast.success("Providers created");
              onCancelForm();
            } else {
              errorMessage({
                payload: res.payload,
                setErrors: setErrors,
                action: "Providers Gateway",
                msg: "created",
              });
            }
          } catch (error) {
            errorMessage({
              payload: error,
              setErrors: setErrors,
              action: "Providers Gateway",
              msg: "created",
            });
          }
        } else {
          try {
            const res = await dispatch(update_provider(values));
            if (res?.payload?.status === 200) {
              toast.success("Providers updated");
              onCancelForm();
            } else {
              errorMessage({
                payload: res.payload,
                setErrors: setErrors,
                action: "Providers Gateway",
                msg: "updated",
              });
            }
          } catch (error) {
            errorMessage({
              payload: error,
              setErrors: setErrors,
              action: "Providers Gateway",
              msg: "updated",
            });
          }
        }
      } else {
        toast.error("Please wait until previous action be completed");
      }
    },
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="w-full grid mt-4 md:grid-cols-2 gap-x-5">
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="name"
              label="Name"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="key_code"
              label="Key Code"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormInput errors={errors} name="url" label="URL" formik={formik} />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="sandbox_url"
              label="Sandbox URL"
              formik={formik}
            />
          </div>
          {!!editingRecord && (
            <div className="mb-3">
              <FormSelectInput
                errors={errors}
                name="active"
                label="Active"
                formik={formik}
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
                valueProp="value"
                labelProp="label"
              />
            </div>
          )}
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateProvider;
