import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { deleteConversation, getConversation, replyToConversation } from "../services/conversationServices";

const initialState = {
  isError: false,
  isLoading: false,
  conversation: [],
  message: "",
  replyLoader:false,
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get AllowedIP Data
export const get_site_conversation = createAsyncThunk(
  "get_site_conversation",
  async (data, thunkAPI) => {
    try {
      return await getConversation(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get AllowedIP Data
export const delete_site_conversation = createAsyncThunk(
  "delete_site_conversation",
  async (data,thunkAPI) => {
    try {
      return await deleteConversation(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get AllowedIP Data
export const reply_to_conversation = createAsyncThunk(
  "reply_to_conversation",
  async (data,thunkAPI) => {
    try {
      return await replyToConversation(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const siteConversation = createSlice({
    name: "siteConversation",
    initialState,
    reducers: {
      siteConversationReset: (state) => {
        state.isError = false;
        state.isLoading = false;
        state.conversation = [];
        state.message = "";
      },
    },
  extraReducers: (builder) => {
    builder
      .addCase(get_site_conversation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_site_conversation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.conversation = action.payload?.data;
      })
      .addCase(get_site_conversation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.conversation = [];
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(delete_site_conversation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_site_conversation.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_site_conversation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(reply_to_conversation.pending, (state) => {
        state.replyLoader = true;
      })
      .addCase(reply_to_conversation.fulfilled, (state, action) => {
        state.replyLoader = false;
        toast.success(action.payload?action.payload:"Reply successfully")
      })
      .addCase(reply_to_conversation.rejected, (state, action) => {
        state.replyLoader = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload?action.payload:"Unknown Error")
      })
  },
});

export const { siteConversationReset } = siteConversation.actions;
export default siteConversation.reducer;
