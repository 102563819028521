import React from "react";
import { FaPlus } from "react-icons/fa";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import ReportHead from "../Components/Header";

export const chartData1 = [
  ["Task", "Hours per Day"],
  ["Work", 11],
  ["Eat", 2],
  ["Commute", 2],
  ["Watch TV", 2],
  ["Sleep", 7],
];
export const chartData2 = [
  ["Task", "Hours per Day"],
  ["Work", 11],
  ["Eat", 2],
  ["Commute", 2],
  ["Watch TV", 2],
  ["Sleep", 7],
];

export const options1 = {
  title: "",
  legend: "bottom",
};
export const options2 = {
  title: "",
  legend: "bottom",
};

const Balances = () => {
  const columnDefs = [
    { headerName: "Tech", field: "tech", flex: 1 },
    { headerName: "Jobs", field: "all_jobs", flex: 1 },
    { headerName: "Description", field: "done_jobs", flex: 1 },
    { headerName: "Actions", field: "open_jobs", flex: 1 },
    { headerName: "Total Balance", field: "canceled_jobs", flex: 1 },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  return (
    <div className="w-full bg-white p-2">
      <ReportHead route="Reports > Commission Report" heading="Login IP" />

      <div className="w-full mt-2 flex flex-col md:flex-row gap-2 justify-between items-center">
        <div className="">
          <div className="filter">
            <button className="py-1 px-2 bg-slate-200 hover:bg-slate-400 hover:text-white">
              Technicians
            </button>
            <button className="py-1 px-2 bg-slate-200 hover:bg-slate-400 hover:text-white">
              Companies
            </button>
          </div>
          <div className="w-[300px] mt-2">
            <select className="bg-transparent p-2 boder-solid border w-full border-slate-300">
              <option>Assign Technician</option>
              <option>John Doe</option>
            </select>
          </div>
          <div className="mt-2 ">
            <button
              type="button"
              className="py-1.5 !px-3 align-middle bg-primary-100 border-primary-100 text-white flex items-center rounded-0"
            >
              <FaPlus className="mr-2" />
              Add Adjustment
            </button>
          </div>
        </div>
        <div className="">
          <div className="min-w-[300px] p-2 border border-solid border-slate-300">
            <div className="text-sm">This Month</div>
            <select className="bg-transparent font-bold">
              <option>Mar 01,2023 - Mar 30, 2023</option>
              <option>Custom</option>
              <option>Today</option>
              <option>This Week</option>
            </select>
          </div>
          <div className="mt-2 border-solid border border-slate-800 bg-slate-200 p-3 text-center">
            <span className="flex flex-col gap hover:no-underline">
              <span>03/29/23 Total Balance for all techs:</span>
              <span className="text-red-500">-383020.69</span>
            </span>
          </div>
        </div>
      </div>
      <div className="w-full mt-2">
        <MUIDataTable
          columnDefs={columnDefs}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={0}
          //   searchText={searchText}
          //   setSearchText={setSearchText}
          paginationModel={paginationModel}
          //   isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default Balances;
