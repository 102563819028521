import api from "./api";

export const getDeclineReason = async ({token,data}) => {
  if (token) {
    return await api.get(`/api/frontend/decline_reasons`, {
      headers: {
        frontend_token: token,
      },
    });
  } else {
    return await api.get(`/api/decline_reasons`,{params:data});
  }
};
export const getDeclineReasonDrd = async () => {
    return await api.get(`/api/decline_reasons/drd`);
};
export const createDeclineReason = async (data) => {
  return await api.post("/api/decline_reasons", data);
};
export const updateDeclineReason = async (data) => {
  return await api.put("/api/decline_reasons", data);
};
export const deleteDeclineReason = async (id) => {
  return await api.delete("/api/decline_reasons", { data: { id } });
};
export const restoreDeclineReason = async (id) => {
  return await api.get(`/api/decline_reasons/${id}/restore`);
};

