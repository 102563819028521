import { Circle, Polygon, Rectangle } from "@react-google-maps/api";

const RenderShapes = ({
  shapes,
  onLoadShape,
  onClickShape,
  onEditShape,
  activeShapeIndex,
}) => {
  const shapeOptions = {
    fillOpacity: 0.3,
    fillColor: "#8aaae5",
    strokeColor: "#8aaae5",
    strokeWeight: 2,
    draggable: true,
    editable: true,
  };
  const activeShapeOptions = {
    fillOpacity: 0.3,
    fillColor: "#5b6eb6",
    strokeColor: "#5b6eb6",
    strokeWeight: 2,
    draggable: true,
    editable: true,
  };
  return (
    <>
      {shapes.map((shape, index) => {
        const shapeKey = `shape_${index}`;
        return (
          <>
            {shape.type === "circle" && (
              <Circle
                center={shape.center}
                radius={shape.radius}
                options={
                  activeShapeIndex.current === index
                    ? activeShapeOptions
                    : shapeOptions
                }
                key={shapeKey}
                onLoad={(event) => onLoadShape(event, index)}
                onMouseDown={() => onClickShape(index)}
                onMouseUp={() => onEditShape(index)}
                onDragEnd={() => onEditShape(index)}
                onRadiusChanged={() => onEditShape(index)}
              />
            )}
            {shape.type === "rectangle" && (
              <Rectangle
                bounds={shape.bounds}
                key={shapeKey}
                options={
                  activeShapeIndex.current === index
                    ? activeShapeOptions
                    : shapeOptions
                }
                onLoad={(event) => onLoadShape(event, index)}
                onMouseDown={() => onClickShape(index)}
                onMouseUp={() => onEditShape(index)}
                onBoundsChanged={() => onEditShape(index)}
                onDragEnd={() => onEditShape(index)}
              />
            )}
            {shape.type === "polygon" && (
              <Polygon
                paths={shape.paths}
                key={shapeKey}
                options={
                  activeShapeIndex.current === index
                    ? activeShapeOptions
                    : shapeOptions
                }
                onLoad={(event) => onLoadShape(event, index)}
                onMouseDown={() => onClickShape(index)}
                onMouseUp={() => onEditShape(index)}
                onDragEnd={() => onEditShape(index)}
              />
            )}
          </>
        );
      })}
    </>
  );
};
export default RenderShapes;
