import api from "./api";

export const getSystemJobStatus = async () => {
  return await api.get("/api/jobstatus/system_job_status");
};

export const createSystemJobStatus = async (values) => {
  return await api.post("/api/jobstatus/system_job_status", values);
};

export const updateSystemJobStatus = async ({ id, name }) => {
  return await api.put(`/api/jobstatus/system_job_status/${id}`, { name });
};
