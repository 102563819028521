import React from "react";
import CustomModal from "../../components/molecules/Modal/customModal";
const GscPullResponseModal = ({ onCancelForm, modalTitle, selectedData }) => {
  return (
    <>
      <CustomModal
        handleModal={onCancelForm}
        cancelButtonRef={onCancelForm}
        className="max-w-6xl"
        modalType={true}
        title={modalTitle}
        hideButton={true}
      >
        <div className="flex p-0 mt-2">
          <div
            className="flex-1 bg-gray-100 p-4 shadow w-full"
            style={{ maxHeight: "calc(80vh - 2rem)" }}
          >
            <div
              className="overflow-y-auto"
              style={{ maxHeight: "calc(80vh - 2rem - 2.5rem)" }}
            >
              {selectedData && selectedData.length ? (
                <div
                  className="overflow-y-auto"
                  style={{
                    maxHeight: "calc(80vh - 2rem - 2.5rem)",
                    minHeight: "calc(60vh - 2rem - 2.5rem)",
                  }}
                >
                  {selectedData?.map((item) => (
                    <>
                      <h2 className="text-2xl font-semibold mb-2 bg-gray-100">
                        Server IP : {item?.server_ip}
                      </h2>
                      <div
                        className={`mb-1 ${
                          item.output ? "text-blue-600" : "text-red-500"
                        }`}
                      >
                        {item.output ? item.output : item.error}
                      </div>
                    </>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default GscPullResponseModal;
