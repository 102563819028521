import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { FaEdit, FaEye, FaEyeSlash, FaTrashAlt, FaPlus } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { delete_user, get_users } from "../../features/usersSlice";
import { get_countries } from "../../features/countriesSlice";
import { get_timezone } from "../../features/timezoneSlice";
import { get_roles } from "../../features/rolesSlice";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import dayjs from "dayjs";
import userPermission from "../../util/userPermission";
import SelectTaskForm from "./SelectTaskForm";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { get_merchants } from "../../features/merchantSlice";
import { get_inbound_group_drd } from "../../features/inboundGroupSlice";
import AssignIndustries from "./AssignIndustries";
import { get_industry_drd } from "../../features/IndustrySlice";
import { MdAssignmentAdd } from "react-icons/md";
import { useLocation } from "react-router-dom";
import api from "../../services/api";
import { Button } from "@mui/material";
const User = () => {
  const initialBulkPayload = {
    barge: false,
    monitor: false,
    whisper: false,
    closer_campaigns: null,
  };
  const location = useLocation();
  const filterData = location.state;
  const optionsRef = React.useRef();
  const inbounds = useSelector((state) => state.inbound);
  const { timezones } = useSelector((state) => state.timezone);
  const { countries } = useSelector((state) => state.countries);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isIndustries, setIsIndustries] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [showMenu, setShowMenu] = useState(null);
  const [payload, setPayload] = useState(initialBulkPayload);
  const [roleManager, setRoleManager] = useState(false);
  const roles = useSelector((state) => state.roles.record);
  const [records, setRecords] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const dispatch = useDispatch();
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setRoleManager(false);
    setIsIndustries(false);
  };
  const handleHideDropdown = (event) => {
    if (showMenu && event.key === "Escape") {
      setShowMenu(null);
    }
  };
  const handleClickOutside = (event) => {
    if (
      showMenu &&
      optionsRef.current &&
      !optionsRef.current.contains(event.target)
    ) {
      setShowMenu(null);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_user(record_id));
        if (res?.payload?.status === 200) {
          toast.success("User deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "User",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "User", msg: "deleted" });
      }
    }
  };
  const get_timezones = async () => {
    if (timezones?.length === 0) {
      try {
        const res = await dispatch(get_timezone());
        if (res?.payload?.status === 200) {
          if (countries?.length === 0) {
            dispatch(get_countries());
            // dispatch(get_states());
          }
        }
      } catch (error) {
        console.error("Timezones couldn't be loaded");
      }
    }
  };
  const getUserById = async (record_id) => {
    try {
      setIsLoader(true);
      const res = await api.get(`/api/users/${record_id}`);
      if (res.status === 200) {
        setIsLoader(false);
        return res.data;
      } else {
        setIsLoader(false);
        return null;
      }
    } catch (err) {
      console.log("👊 ~ getUserById ~ err:", err);
    }
    setIsLoader(false);
  };
  const openFormHandler = (record) => async () => {
    let data = record;
    if (data) {
      data = await getUserById(data._id);
    }
    setEditingRecord(data);
    setIsEditing(true);
  };

  const openIndustriesHandler = (record) => () => {
    setEditingRecord(record);
    setIsIndustries(true);
  };

  const getUserList = async (filter) => {
    setIsLoader(true);
    let payload = { ...filter };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      const res = await api.post("api/users/report", payload);
      if (res?.status === 200 || res?.status === 201) {
        setRecords(res.data);
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      console.log(err);
      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error || "An error occurred");
      } else {
        toast.error(err?.response?.data || "An error occurred");
      }
    }
  };
  useEffect(() => {
    getUserList();
    dispatch(
      get_users({
        ...filterData,
        page: 1,
        size: paginationModel.pageSize,
      })
    );
    dispatch(get_roles());
    dispatch(get_merchants());
    get_timezones();
    dispatch(get_industry_drd());
    dispatch(get_inbound_group_drd());
    // eslint-disable-next-line
  }, []);
  const sendVerificationEmail = async (mail) => {
    const c = window.confirm(
      "Are you sure want to send the verification email?"
    );
    if (!c) return;
    setIsLoader(true);
    try {
      const res = await api.post("/api/users/send-verification-email", {
        email: mail,
      });
      if (res.status === 200) {
        toast.success(
          res.data?.message || "Verification email send successfully"
        );
      } else {
        toast.error(res.data?.message || "Verification email couldn't be send");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error(
        err?.response?.data?.message || "Verification email couldn't be send"
      );
    }
  };
  const filterUsers = records?.data?.filter((user) => {
    const searchTextLower = searchText.toLowerCase().trim();
    const userFirstNameLower = user?.first_name?.toLowerCase() || "";
    const userLastNameLower = user?.last_name?.toLowerCase() || "";
    const userEmailLower = user?.email?.toLowerCase() || "";
    const userNameLower = user?.username?.toLowerCase() || "";
    const userRoleNameLower = user?.role_id?.name?.toLowerCase() || "";
    const userPrimaryMerchantNameLower =
      user?.primary_merchant_id?.name?.toLowerCase() || "";
    const userFullNameLower = `${userFirstNameLower} ${userLastNameLower}`;
    const lastLoginIp = user?.last_login_ip?.toLowerCase() || "";
    return (
      userFullNameLower.includes(searchTextLower) ||
      userEmailLower.includes(searchTextLower) ||
      userNameLower.includes(searchTextLower) ||
      userRoleNameLower.includes(searchTextLower) ||
      lastLoginIp.includes(searchTextLower) ||
      userPrimaryMerchantNameLower.includes(searchTextLower)
    );
  });
  const testMenu = [
    { label: "Edit user", action: (e) => console.log("Edit user", e) },
    { label: "Delete User", action: () => console.log("test2") },
    { label: "Assign Industries", action: () => console.log("test3") },
  ];
  const onSingleSelect = ({ checked, data }) => {
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            (did) => did?._id !== data?._id
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onSelectAll = (checked) => {
    if (checked) {
      setSelectedData([...filterUsers]);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (user) => {
    if (selectedData?.length > 0) {
      if (selectedData?.filter(({ _id }) => _id === user._id).length > 0) {
        return true;
      }
    }
    return false;
  };
  const dialerRoles = [
    { label: "Agent", value: 1 },
    { label: "Admin", value: 8 },
  ];
  const statusOption = [
    { value: true, label: "Active" },
    { value: false, label: "InActive" },
  ];
  const dialerOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: () => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={selectedData?.length === filterUsers?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={isSelected(params.row.records)}
          onChange={(e) => {
            onSingleSelect({
              checked: e.target.checked,
              data: params.row.records,
            });
          }}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
      disableColumnMenu: true,
    },
    { headerName: "#", field: "counter", width: 10 },
    { headerName: "Name", field: "name", filterable: false },
    {
      headerName: "Email",
      field: "email",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div className="flex flex-col">
          <span>{params.row.email}</span>
          {params.row.email_verified ? (
            <small className="text-green-600">Verified</small>
          ) : (
            <small
              className="text-red-600 hover:underline cursor-pointer ml3"
              onClick={() => sendVerificationEmail(params.row.email)}
            >
              Not verified
            </small>
          )}
        </div>
      ),
    },
    { headerName: "User Name", field: "username" },
    {
      field: "pswrd",
      headerName: "Password",
      width: 130,
      renderCell: (params) => {
        return (
          <div className="">
            <span className="!mr-2">
              {showPassword === params.row.records?._id
                ? params.row.records.pswrd
                : "*******"}
            </span>
            {showPassword === params.row.records._id ? (
              <FaEye
                className="my_navIcon cursor-pointer"
                onClick={() => setShowPassword("")}
              />
            ) : (
              <FaEyeSlash
                className="my_navIcon cursor-pointer"
                onClick={() => setShowPassword(params.row.records._id)}
              />
            )}
          </div>
        );
      },
    },
    {
      headerName: "Role",
      field: "role_id",
      width: 100,
      type: "singleSelect",
      getOptionValue: (option) => option?._id,
      getOptionLabel: (option) => option.name,
      valueOptions: roles,
      renderCell: (params) => {
        const list = roles.find((list) => list.name === params?.row?.role);
        return list ? list.name : "N/A";
      },
    },
    {
      headerName: "Merchant",
      field: "primaryMerchant",
      width: 100,
      filterable: false,
    },
    {
      headerName: "Dialer User",
      field: "is_dialer_user",
      width: 100,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: dialerOptions,
      renderCell: (params) => {
        const item = dialerOptions.find(
          (item) => item.label === params?.row?.is_dialer_user
        );
        return item ? item.label : "N/A";
      },
    },
    {
      headerName: "Dialer Info",
      field: "dialer_user",
      filterable: false,
      width: 100,
      renderCell: (params) => {
        return params?.row?.is_dialer_user === "Yes" ||
          params?.row?.dialer_user[0]?.dialer_user ? (
          <div className="flex flex-col">
            <span>User: {params.row.dialer_user[0]?.dialer_user || ""}</span>
            <span>Phone: {params.row.dialer_user[0]?.dialer_phone || ""}</span>
            <span>
              Role:{" "}
              {dialerRoles?.find(
                ({ value }) => value === params.row.dialer_user[0]?.dialer_role
              )?.label || ""}
            </span>
          </div>
        ) : (
          "N/A"
        );
      },
    },
    {
      headerName: "Company Email",
      field: "company_email",
      width: 100,
      renderCell: (params) => {
        if (params.row.company_email) {
          return params.row.company_email;
        }
        return "N/A";
      },
    },
    {
      headerName: "Created At",
      field: "createdAt",
      minWidth: 120,
      type: "date",
      valueGetter: (params) => new Date(params.row.createdAt),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      renderCell: (params) => {
        const rawDate = params.row.createdAt;
        if (rawDate) {
          return rawDate;
        }
        return "";
      },
    },
    {
      headerName: "IP Filtering",
      field: "ip_filtering",
      width: 100,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: dialerOptions,
      renderCell: (params) => {
        const item = dialerOptions.find(
          (item) => item.label === params?.row?.ipFiltering
        );
        return item ? item.label : "N/A";
      },
    },
    {
      headerName: "Status",
      field: "active",
      width: 80,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: statusOption,
      renderCell: (params) => {
        const status = statusOption.find(
          (status) => status.value === params?.row?.active
        );
        return status ? status.label : "N/A";
      },
    },
    {
      headerName: "Action",
      field: "actions",
      align: "center",
      filterable: false,
      renderCell: (params) => (
        <div>
          {userPermission("Update User") && (
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update User"
            />
          )}
          {userPermission("Delete User") && (
            <FaTrashAlt
              onClick={deleteRecordHandler(params.row.record_id)}
              className="my_navIcon"
              title="Delete User"
            />
          )}
          {params.row.status ? (
            <MdAssignmentAdd
              onClick={openIndustriesHandler(params.row.records)}
              className="my_navIcon"
              title="Assign Industries to this user"
              size={18}
            />
          ) : null}
          {/* <BsThreeDotsVertical
            size={18}
            className="cursor-pointer !relative hover:text-primary-100"
            onClick={() => setShowMenu(params.row.records._id)}
          /> */}
          {showMenu === params.row.record_id ? (
            <div
              ref={optionsRef}
              className="absolute w-auto right-12 min-w-[100px] rounded shadow !mt-1 bg-white z-20 border-x border-y border-[#ddd] -ml-px overflow-hidden transition ease-in-out delay-150"
            >
              <ul className="!pl-0 !mb-0">
                {testMenu?.map((option, index) => {
                  return (
                    <li
                      key={index}
                      className="cursor-pointer px-3 !py-1.25 border- border-[#ddd] hover:bg-[#e1e1e1]"
                      onClick={() => option.action(params.row.records)}
                    >
                      {option.label}
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>
      ),

      width: 100,
    },
  ];

  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    let payload = {
      page: 1,
      size: paginationModel.pageSize,
    };
    if (queryOptions.groupOp && queryOptions.rules.length > 0) {
      payload.filters = queryOptions;
    }
    getUserList({ ...payload });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      getUserList({
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getUserList({
      filters: queryOptions,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };

  const bulkOptions = [
    {
      placeholder: "Select Inbounds",
      name: "closer_campaigns",
      label: "Select Inbounds",
      options: inbounds?.inboundDrd,
      valueProp: "group_id",
      labelProp: "group_name",
      isMulti: true,
      onChange: (e) => setPayload({ ...payload, closer_campaigns: e.value }),
      value: payload?.closer_campaigns,
    },
    {
      id: "barge",
      placeholder: "Barge",
      name: "barge",
      isCheckbox: true,
      onChange: () => setPayload({ ...payload, barge: !payload?.barge }),
      value: payload?.barge,
    },
    {
      id: "monitor",
      placeholder: "Monitor",
      name: "monitor",
      isCheckbox: true,
      onChange: () => setPayload({ ...payload, monitor: !payload?.monitor }),
      value: payload?.monitor,
    },
    {
      id: "whisper",
      placeholder: "Whisper",
      name: "whisper",
      isCheckbox: true,
      onChange: () => setPayload({ ...payload, whisper: !payload?.whisper }),
      value: payload?.whisper,
    },
  ];
  const update_bulk_user = async () => {
    const values = selectedData?.map(({ _id }) => ({
      user_id: _id,
      ...payload,
    }));
    try {
      const res = await api.post("/api/users/bulk_update", values);
      if (res.status === 200) {
        toast.success("Users updated successfully");
        setSelectedData([]);
        setPayload(initialBulkPayload);
      } else {
        toast.error(res.data?.message || "Users couldn't be updated");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || "Users couldn't be updated");
      console.log("👊 ~ constupdate_bulk_user=async ~ err:", err);
    }
  };
  const LineOne = () => {
    return (
      <div className="grid grid-cols-4 gap-2 ml-3">
        <Button
          variant="text"
          startIcon={<FaPlus size={18} />}
          onClick={addCompanyEmail}
          disabled={!selectedData.length}
          sx={{
            borderRadius: "6px",
            marginRight: "5px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "26px",
            fontSize: "0.8125rem",
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          Add Company Email
        </Button>{" "}
      </div>
    );
  };
  const addCompanyEmail = async () => {
    if (!selectedData?.length) {
      toast.error("Please select any User!");
      return;
    }
    const confirmed = window.confirm(
      "Are you sure you want to perform this action?"
    );
    if (!confirmed) return;
    setIsLoader(true);
    try {
      const payload = {
        user_ids: selectedData.map((item) => item._id),
      };
      const res = await api.post(
        "/api/users/create_user_merchant_email",
        payload
      );
      if (res.status === 200 || res.status === 201) {
        toast.success(
          res?.data?.message || "Company email added successfully."
        );
      } else {
        toast.error(res.data.message || "Failed to add company email.");
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.message ||
          "An error occurred while adding the company email."
      );
    } finally {
      setIsLoader(false);
      setSelectedData([]);
    }
  };
  const getRowHeight = (params) => {
    const data = params?.model;
    if (data?.is_dialer_user === "Yes" || data?.dialer_user?.dialer_user) {
      return 80;
    }
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle={editingRecord ? "Update User" : "Add User"}
          onCancelForm={cancelFormHandler}
        />
      )}
      {isIndustries ? (
        <AssignIndustries
          userDetail={editingRecord}
          onClose={cancelFormHandler}
        />
      ) : null}
      <PageHeader
        route="Setting > Users"
        heading="Users Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add User")}
      />
      {roleManager && (
        <SelectTaskForm
          editingRecord={editingRecord}
          modalTitle="Manage Role Permissions"
          onCancelForm={cancelFormHandler}
          users={records?.data}
        />
      )}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={filterUsers?.map((record, index) => {
            const {
              email_verified,
              merchants,
              company_email,
              is_dialer_user,
              dialer_user = {},
            } = record;
            const user = JSON.parse(localStorage.getItem("user"));
            let records = record;
            let counter = offset + index + 1;
            let record_id = record._id;
            let name =
              record?.first_name +
              " " +
              (record?.last_name ? record?.last_name : "");
            let email = record.email;
            let username = record?.username;
            let role = roles?.find(
              ({ _id }) =>
                _id ===
                merchants.find(
                  ({ merchant_id }) => merchant_id === user?.current_merchant_id
                )?.role_id
            )?.name;
            let role_level = record?.role_id?.level;
            let primaryMerchant = record?.primary_merchant_id?.name;
            let createdAt = record.createdAt
              ? dayjs(record?.createdAt).format("ddd, MMM D, YYYY h:mm A")
              : null;
            let ipFiltering = record.ip_filtering ? "Yes" : "No";
            let active = record.active;
            let pswrd = record.pswrd;
            return {
              counter,
              name,
              email,
              username,
              role,
              primaryMerchant,
              createdAt,
              ipFiltering,
              active,
              records,
              record_id,
              pswrd,
              role_level,
              email_verified,
              company_email,
              is_dialer_user: is_dialer_user ? "Yes" : "No",
              dialer_user,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={records.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          onFilterModelChange={onFilterChange}
          isLoading={isLoader}
          density="standard"
          toolbarProps={{
            isBulkUpdate: true,
            setSelectedData: setSelectedData,
            selectedItem: selectedData?.length,
            fieldsArray: bulkOptions,
            isSelectAll: selectedData?.length === records?.data?.length,
            handleBulkSave: update_bulk_user,
            applyFilter: handleNewFilter,
            isHideColumnButton: true,
            isHideDensityButton: true,
            onCancel: () => {
              setSelectedData([]);
              setPayload(initialBulkPayload);
            },
          }}
          LineOne2={LineOne}
          gridOptions={getRowHeight}
        />
      </div>
    </>
  );
};

export default User;
