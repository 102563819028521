import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export default async function exportDomainReport(data, headers, fileName,workSheetName) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(workSheetName);

  // Add headers (main headers)
  const headerRow = worksheet.addRow(headers);
  headerRow.height = 30;
  // Style the header row
  headerRow.eachCell((cell) => {
    cell.font = { bold: true };
    cell.alignment = { vertical: "middle", horizontal: "center" };
  });

  // Add data rows for parent data only
  data.forEach((item) => {
    const { ...parentData } = item;

    const parentRow = worksheet.addRow(Object.values(parentData));

    // Apply styles to the parent row
    parentRow.eachCell((cell, colNumber) => {
      cell.alignment = {
        vertical: "middle",
        // horizontal: colNumber < 3 ? "left" : "right",
      };
    });
  });

  // Write to buffer
  const buffer = await workbook.xlsx.writeBuffer();

  // Save the file
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, `${fileName}.xlsx`);
}
