import dayjs from "dayjs";
import React, { useContext } from "react";
import { BiMap } from "react-icons/bi";
import { CiCalendarDate } from "react-icons/ci";
import { FaIndustry } from "react-icons/fa";
import {AiOutlineCloseCircle} from "react-icons/ai";
import { FiPhone } from "react-icons/fi";
import { SlUser, SlWrench } from "react-icons/sl";
import GlobalContext from "../context/GlobalContext";
import { Button } from "../../../components";


export default function EventModal() {
  const { setShowEventModal, selectedEvent } =
    useContext(GlobalContext);
    const optionsRef = React.useRef();
  const handleHideDropdown = (event) => {
        if (event.key === "Escape" ) {
          setShowEventModal(false);
        }
  };
  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setShowEventModal(false);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  return (
    <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center z-[1000]">
      <div className="bg-white rounded-lg shadow-2xl md:w-1/4" ref={optionsRef}>
        <header className={`${selectedEvent?.job_created ? "bg-indigo-200" : "bg-purple-200"} px-4 py-2 flex justify-between items-center`}>
          <span className="material-icons-outlined text-black">
          {selectedEvent?.job_created ? "Job #" : "Lead #"}{" "}
                {selectedEvent?.job_number
                  ? selectedEvent?.job_number
                  : selectedEvent?.lead_num}
          </span>
          <div>
            <button onClick={() => setShowEventModal(false)}>
              <span className="material-icons-outlined text-menu">
                <AiOutlineCloseCircle size={25}/>
              </span>
            </button>
          </div>
        </header>
        <div className="p-3">
          <div
            className={"min-w-[350px]"}
            // ref={optionsRef}
          >
            <ul className="pl-2 ml-0">
              
              <li className="mb-1.25 pt-1.25 pb-2.5 border-b border-[#f0f0f0] flex items-center">
                <SlUser className="mr-2" size={20} />
                {selectedEvent?.name}
              </li>
              <li className="mb-1.25 pt-1.25 pb-2.5 border-b border-[#f0f0f0] flex items-center">
                <FiPhone className="mr-2" size={20} />
                {selectedEvent?.phone}
              </li>
              <li className="mb-1.25 pt-1.25 pb-2.5 border-b border-[#f0f0f0] flex items-center">
                <CiCalendarDate className="mr-2" size={20} />
                {selectedEvent?.scheduled_on
                  ? dayjs(selectedEvent?.scheduled_on).format(
                      "ddd, MMM D, YYYY h:mm A"
                    )
                  : "No scheduled date selected"}
              </li>
              <li className="mb-1.25 pt-1.25 pb-2.5 border-b border-[#f0f0f0] flex items-center">
                <BiMap className="mr-2 min-w-[20px] min-h-[20px]" size={20}  />
                {selectedEvent?.complete_pickup_address}
              </li>
              <li className="mb-1.25 pt-1.25 pb-2.5 border-b border-[#f0f0f0] flex items-center">
                <FaIndustry className="mr-2" size={20} />
                {selectedEvent?.industry_id?.name}
              </li>
              {
                selectedEvent?.job_category_id?.name?
                <li className="mb-1.25 pt-1.25 pb-2.5 border-b border-[#f0f0f0] flex items-center">
                <SlWrench className="mr-2" size={20} />
                {selectedEvent?.job_category_id?.name}
              </li>:null
              }
              
            </ul>
          </div>
        </div>
        <footer className="flex justify-end border-t !p-3 !mt-5">

          <Button
            text="Close"
            onClick={() => setShowEventModal(false)}
            variant="btn_cancel"
            // className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
          />
        </footer>
      </div>
    </div>
  );
}
