import api from "./api";

export const getAllowedIpList = async (data) => {
  return await api.get(`/api/allowed_ip`,{params:data});
};
export const createAllowedIp = async (data) => {
  return await api.post("/api/allowed_ip", data);
};
export const updateAllowedIp = async (data) => {
  return await api.put("/api/allowed_ip", data);
};
export const deleteAllowedIp = async (id) => {
  return await api.delete("/api/allowed_ip", { data: { id } });
};
