import React from "react";
import { DotsLoader, FormInput, Modal } from "../../components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import TagsInput from "../../components/atoms/CustomTagsInput";
import api from "../../services/api";
import FormRadioGroup from "../../components/molecules/FormRadioGroup";
function AddRestrictedWords({
  editingRecord,
  onCancelForm,
  modalTitle,
  reloadList,
  dataType,
}) {
  let initialValues = {
    words: [],
  };
  if (editingRecord) {
    const { _id, name } = editingRecord;
    initialValues = {
      id: _id,
      word: name,
    };
  }
  const [isLoading, setIsLoading] = React.useState(false);
  const [type, setType] = React.useState(dataType);
  const handleSubmit = async (values) => {
    const url = editingRecord
      ? `/api/restrict_word_email/update/${values?.id}`
      : "/api/restrict_word_email/add";
    const msg = editingRecord ? "updated" : "added";
    const method = editingRecord ? "put" : "post";
    setIsLoading(true);
    try {
      const res = await api[method](url, {
        id: values?.id,
        [type]: values[`${editingRecord ? "word" : "words"}`],
      });
      if (res.status === 200) {
        toast.success(
          res.data.error || `Restricted ${type} ${msg} successfully`
        );
        reloadList();
        onCancelForm();
      } else {
        toast.error(res.data.error || `Restricted ${type} couldn't be ${msg}`);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(
        err?.response?.data?.error || `Restricted ${type} couldn't be ${msg}`
      );
      console.log("👊 ~ handleSubmit ~ err:", err);
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  return (
    <Modal
      isUpdate={!!editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      formIsValid={formik.isValid}
      onSubmit={formik.handleSubmit}
      isLoading={isLoading}
      onClick={onCancelForm}
    >
      {isLoading ? <DotsLoader /> : null}

      {editingRecord ? (
        <div>
          <div className="col-span-4 mb-3">
            <FormRadioGroup
              name="type"
              isOnChange={(e) => setType(e)}
              labelProp="label"
              valueProp="value"
              options={[
                { label: "Word", value: "word" },
                { label: "Email", value: "email" },
              ]}
              isCheck={type}
              isHorizontal
              isBorder
              className={"bg-white"}
              isFloat
              label="Type"
            />
          </div>
          <FormInput name="word" label={type} formik={formik} />
        </div>
      ) : (
        <div className="grid md:grid-cols-4 gap-x-5 mt-5 mx-2.5">
          <div className="col-span-4 mb-3">
            <FormRadioGroup
              name="type"
              isOnChange={(e) => setType(e)}
              labelProp="label"
              valueProp="value"
              options={[
                { label: "Words", value: "words" },
                { label: "Emails", value: "emails" },
              ]}
              isCheck={type}
              isHorizontal
              isBorder
              className={"bg-white"}
              isFloat
              label="Type"
            />
          </div>
          <div className="col-span-4">
            <TagsInput
              tags={formik.values.words}
              setTags={(e) => formik.setFieldValue("words", e)}
              placeholder={`Add Restricted ${type}`}
              label={`Add New ${type}`}
              isFloat
            />
          </div>
        </div>
      )}
    </Modal>
  );
}

export default AddRestrictedWords;
