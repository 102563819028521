import React from "react";
import DotsLoader from "../../atoms/DotsLoader";
import { useDispatch, useSelector } from "react-redux";
import Draggable from "react-draggable";
import { useLocation } from "react-router-dom";
import { get_recordings, resetRecordings } from "../../../features/dialerSlice";
import { get_jobs_by_id } from "../../../features/jobsSlice";
import { FaSearch } from "react-icons/fa";
import dayjs from "dayjs";
import errorMessage from "../../../util/errorMessage";
import { get_users_drd } from "../../../features/usersSlice";
export function Recordings({ modalTitle, setIsRecordings }) {
  const { usersDrd } = useSelector((state) => state.users);
  const dialer = useSelector((state) => state.dialer);
  const [jobDetail, setJobDetail] = React.useState();
  const location = useLocation();
  const [filterText, setFilterText] = React.useState("");
  const dispatch = useDispatch();
  const [recordType, setRecordType] = React.useState("Dialer");
  const isUpdating = location.pathname.startsWith("/jobs/update");
  const id =
    location.pathname.split("/")[3] === "preview"
      ? location.pathname.split("/")[4]
      : location.pathname.split("/")[3];
  const getJob = async () => {
    if (isUpdating) {
      try {
        const res = await dispatch(get_jobs_by_id(id));
        if (res.payload?.status === 200) {
          dispatch(
            get_recordings({ lead_id: res.payload.data.lead_num, type: "Job" })
          );
          setJobDetail(res.payload.data);
        } else {
          errorMessage({ payload: res.payload, action: "Job", msg: "loaded" });
        }
      } catch (error) {
        console.error(
          "Error ~ file: AddUpdateForm.jsx:157 ~ getJob ~ error",
          error
        );
      }
    } else {
      setJobDetail("");
    }
  };
  React.useEffect(() => {
    if (isUpdating) {
      getJob();
    } else if (jobDetail?.lead_num) {
      setIsRecordings(false);
    }
    // eslint-disable-next-line
  }, [location.pathname]);
  const recordings = dialer?.recordings?.recordings?.filter(
    (record) =>
      record?.call_type?.toLowerCase().includes(filterText.toLowerCase()) ||
      record?.user?.toLowerCase().includes(filterText.toLowerCase()) ||
      record?.crm_username?.toLowerCase().includes(filterText.toLowerCase()) ||
      dayjs(record?.call_date)
        .format("ddd, MMM D, YYYY h:mm A")
        .toLowerCase()
        .includes(filterText.toLowerCase())
  );
  const DialerRecording = () => {
    return (
      <>
        {dialer?.recordings?.recordings?.length > 0 ? (
          <div className="p-4 flex flex-col overflow-y-auto h-[450px]">
            {recordings?.map((recording, index) => {
              return (
                <>
                  {recording ? (
                    <div className="flex justify-between items-center flex-row">
                      <div
                        id="_timeline1"
                        className="pl-[30px] pb-[30px] relative pr-[20px] min-h-[70px] _msgItem _recItem border-l border-[#ddd]"
                      >
                        <span className="bg-white w-[35px] h-[35px] absolute text-center left-[-18px] top-[0px] font-[1.5rem]">
                          <i className="fa fa-microphone" />
                        </span>
                        <div className="flex ga-4 flex-col">
                          <div className="font-bold mr-[5px] relative">
                            {recording?.call_type} | {recording?.phone_number}
                          </div>
                          <div className="postTime">
                            {dayjs(recording?.call_date).format(
                              "ddd, MMM D, YYYY h:mm A"
                            )}
                          </div>
                          <div>
                            {recording?.crm_username
                              ? `${recording.crm_username} |`
                              : ""}{" "}
                            {recording?.user} | {recording?.campaign_id}
                          </div>
                        </div>
                        <div className="mt-[20px]">
                          <audio
                            id="MyPlayer"
                            controls
                            key={index}
                            className="h-11.5 w-[250px]"
                          >
                            <source
                              src={recording?.location}
                              type="audio/mpeg"
                            />
                          </audio>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              );
            })}
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center">
            {jobDetail?.lead_num && (
              <div className="col-11 text-center mt-5" style={{ height: 500 }}>
                No Data Found
              </div>
            )}
            {jobDetail?.call_type === "Manual" && (
              <div className="col-11 text-center mt-5" style={{ height: 500 }}>
                This job is created manually without a call.
              </div>
            )}
          </div>
        )}
      </>
    );
  };
  const MaskingRecording = () => {
    return (
      <>
        {dialer?.recordings?.masked_recordings?.length > 0 ? (
          <div className="p-4 flex flex-col overflow-y-auto h-[450px]">
            {(dialer?.recordings?.masked_recordings || [])?.map(
              (data, index) => {
                return (
                  <div
                    className="flex justify-between items-center flex-row"
                    key={index}
                  >
                    <div
                      id="_timeline1"
                      className="pl-[30px] pb-[30px] relative pr-[20px] min-h-[70px] _msgItem _recItem border-l border-[#ddd]"
                    >
                      <span className="bg-white w-[35px] h-[35px] absolute text-center left-[-18px] top-[0px] font-[1.5rem]">
                        <i className="fa fa-microphone" />
                      </span>
                      <div className="flex ga-4 flex-col">
                        {/* <div className="postTime">
                            {dayjs(recording?.recording_time).format(
                              "ddd, MMM D, YYYY h:mm A"
                            )}
                          </div> */}
                      </div>
                      <div className="mt-[20px]">
                        <audio
                          id="MyPlayer"
                          controls
                          key={index}
                          className="h-11.5 w-[250px]"
                        >
                          <source src={data} type="audio/mpeg" />
                        </audio>
                      </div>
                    </div>
                  </div>
                );
                // <div className="border-b-2 border-[#3b0062] pb-2">
                //   {data?.map((recording, index) => {
                //     return (
                //       <>
                //         {recording ? (
                //           <div className="flex justify-between items-center flex-row">
                //             <div
                //               id="_timeline1"
                //               className="pl-[30px] pb-[30px] relative pr-[20px] min-h-[70px] _msgItem _recItem border-l border-[#ddd]"
                //             >
                //               <span className="bg-white w-[35px] h-[35px] absolute text-center left-[-18px] top-[0px] font-[1.5rem]">
                //                 <i className="fa fa-microphone" />
                //               </span>
                //               <div className="flex ga-4 flex-col">
                //                 <div className="postTime">
                //                   {dayjs(recording?.recording_time).format(
                //                     "ddd, MMM D, YYYY h:mm A"
                //                   )}
                //                 </div>
                //               </div>
                //               <div className="mt-[20px]">
                //                 <audio
                //                   id="MyPlayer"
                //                   controls
                //                   key={index}
                //                   className="h-11.5 w-[250px]"
                //                 >
                //                   <source
                //                     src={recording?.recording_link}
                //                     type="audio/mpeg"
                //                   />
                //                 </audio>
                //               </div>
                //             </div>
                //           </div>
                //         ) : null}
                //       </>
                //     );
                //   })}
                // </div>
              }
            )}
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center">
            {jobDetail?.lead_num && (
              <div className="col-11 text-center mt-5" style={{ height: 500 }}>
                No Data Found
              </div>
            )}
            {jobDetail?.call_type === "Manual" && (
              <div className="col-11 text-center mt-5" style={{ height: 500 }}>
                This job is created manually without a call.
              </div>
            )}
          </div>
        )}
      </>
    );
  };
  return (
    <Draggable
      defaultPosition={{ x: 0, y: 0 }}
      axis="x"
      style={{ pointerEvents: "none" }}
      cancel="button, input, i, audio, span"
    >
      <div
        className="justify-between items-center w-full relative"
        style={{ display: "flex", cursor: "move", zIndex: 10 }}
      >
        <div className="fixed overflow-y-auto bottom-3.5 right-1.5 bg-white rounded-t-md z-10 shadow-3xl w-[350px] min-h-[600px]">
          <header className="border-b-2 border-[#3b0062] flex justify-between items-center">
            {dialer?.recordings?.length > 0 && (
              <i
                className="fa fa-arrow-left text-lg text-[#3b0062] !m-4 cursor-pointer"
                onClick={() => {
                  setJobDetail("");
                  dispatch(resetRecordings());
                  if (usersDrd?.length === 0) {
                    dispatch(get_users_drd());
                  }
                }}
              />
            )}

            <h1 className="text-lg text-[#3b0062] !m-4 font-medium">
              {modalTitle}{" "}
              {jobDetail?.job_number ? `(Job# ${jobDetail?.job_number})` : ""}
            </h1>
            <i
              className="fa fa-minus text-2xl text-[#3b0062] !m-4 cursor-pointer"
              onClick={() => {
                setIsRecordings(false);
              }}
            ></i>
          </header>
          <div className="flex flex-row justify-center w-full space-x-2 mt-2 px-2">
            <span
              className={`w-1/2 text-center cursor-pointer ${
                recordType === "Dialer"
                  ? "border-b-2 border-primary-100 text-primary-100"
                  : ""
              }`}
              onClick={() => setRecordType("Dialer")}
            >
              Dialer
            </span>
            <span
              className={`w-1/2 text-center cursor-pointer ${
                recordType === "Masking"
                  ? "border-b-2 border-primary-100 text-primary-100"
                  : ""
              }`}
              onClick={() => setRecordType("Masking")}
            >
              Masking
            </span>
          </div>
          {dialer?.recordings?.recordings?.length > 0 &&
          recordType === "Dialer" ? (
            <div className="relative w-full px-3.7 !my-2">
              <input
                className={
                  "shadow appearance-none border rounded w-full !py-2 !pr-3 pl-12 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-sm border w-full !pl-15 h-[31px]"
                }
                style={{ minHeight: 31 }}
                placeholder="Search"
                value={filterText}
                onChange={(e) => {
                  setFilterText(e.target.value);
                }}
              />
              <div className="absolute top-[3px] left-6 border-r border-[#dee2e6] pr-2.5 flex justify-center items-center h-[25px]">
                <FaSearch />
              </div>
            </div>
          ) : null}

          {dialer.isLoading ? (
            <DotsLoader />
          ) : recordType === "Dialer" ? (
            <DialerRecording />
          ) : (
            <MaskingRecording />
          )}
        </div>
      </div>
    </Draggable>
  );
}

export default Recordings;
