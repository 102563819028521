import React,{useState} from "react";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";

export const chartData1 = [
    ["Task", "Hours per Day"],
    ["Work", 11],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7],
  ];
  export const chartData2 = [
    ["Task", "Hours per Day"],
    ["Work", 11],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7],
  ];
  
  export const options1 = {
    title: "",
    legend: "bottom"
  };
  export const options2 = {
    title: "",
    legend: "bottom"
  };

const AreaPerformance = ()=> {
    const [isMetroActive, setMetroActive] = useState(true);
    const [isCityActive, setCityActive] = useState(false);
    const [isZipActive, setZipActive] = useState(false);
    const columnDefs = [
        { headerName: "Service Area", field: "service",flex:1 },
        { headerName: "All Jobs", field: "all_jobs",flex:1 },
        { headerName: "Done Jobs", field: "done_jobs",flex:1 },
        { headerName: "Open Jobs", field: "open_jobs",flex:1 },
        { headerName: "Canceled Jobs", field: "canceled_jobs",flex:1 },
        { headerName: "Canceled %", field: "canceled",flex:1 },
        { headerName: "Gross Amount", field: "gross_amunt",flex:1 },
        { headerName: "Profit", field: "profit",flex:1 },
        { headerName: "Average Sale", field: "avg_sale",flex:1 },
        { headerName: "Average Profit", field: "avg_profit",flex:1 },
      ];
      const cityColumnDefs = [
        { headerName: "City", field: "city",flex:1 },
        { headerName: "Service Area", field: "service",flex:1 },
        { headerName: "All Jobs", field: "all_jobs",flex:1 },
        { headerName: "Done Jobs", field: "done_jobs",flex:1 },
        { headerName: "Open Jobs", field: "open_jobs" ,flex:1},
        { headerName: "Canceled Jobs", field: "canceled_jobs",flex:1 },
        { headerName: "Canceled %", field: "canceled",flex:1 },
        { headerName: "Gross Amount", field: "gross_amunt",flex:1 },
        { headerName: "Profit", field: "profit",flex:1 },
        { headerName: "Average Sale", field: "avg_sale",flex:1 },
        { headerName: "Average Profit", field: "avg_profit",flex:1 },
      ];
      const zipColumnDefs = [
        { headerName: "Zip", field: "zip",flex:1 },
        { headerName: "City", field: "city",flex:1 },
        { headerName: "Service Area", field: "service",flex:1 },
        { headerName: "All Jobs", field: "all_jobs",flex:1 },
        { headerName: "Done Jobs", field: "done_jobs",flex:1 },
        { headerName: "Open Jobs", field: "open_jobs",flex:1 },
        { headerName: "Canceled Jobs", field: "canceled_jobs",flex:1 },
        { headerName: "Canceled %", field: "canceled",flex:1 },
        { headerName: "Gross Amount", field: "gross_amunt",flex:1 },
        { headerName: "Profit", field: "profit",flex:1 },
        { headerName: "Average Sale", field: "avg_sale",flex:1 },
        { headerName: "Average Profit", field: "avg_profit",flex:1 },
      ];
      
      const metroHandler = ()=>{
        setMetroActive(true);
        setCityActive(false);
        setZipActive(false);
      }
      const cityHandler = ()=>{
        setMetroActive(false);
        setCityActive(true);
        setZipActive(false);
      }
      const zipHandler = ()=>{
        setMetroActive(false);
        setCityActive(false);
        setZipActive(true);
      }
      const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 100,
        page: 1,
      });
      const handlePageSizeChange = (newPageSize) => {
        setPaginationModel({ ...paginationModel, pageSize: newPageSize });
      };
      const handlePageChange = (params) => {
        setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
      };
    return (
        <div className="w-full bg-white p-2">
            <div className="w-full mt-2">
            <div className="filter flex ">
                    <button className={`py-1 px-2 bg-slate-200 hover:bg-slate-400 hover:text-white ${isMetroActive && 'bg-slate-400 text-white'}`} onClick={metroHandler}>Metro</button>
                    <button className={`py-1 px-2 bg-slate-200 hover:bg-slate-400 hover:text-white ${isCityActive && 'bg-slate-400 text-white'}`} onClick={cityHandler}>City</button>
                    <button className={`py-1 px-2 bg-slate-200 hover:bg-slate-400 hover:text-white ${isZipActive && 'bg-slate-400 text-white'}`} onClick={zipHandler}>Zip</button>
                </div>
            </div>
            <div className="w-full mt-2">
            {isMetroActive && 
            <div className="bg-white my-3 border rounded">
          <MUIDataTable
            columnDefs={columnDefs}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            totalItems={0}
            //   searchText={searchText}
            //   setSearchText={setSearchText}
            paginationModel={paginationModel}
            //   isLoading={isLoading}
          />
      </div>}
            {isCityActive && <div className="bg-white my-3 border rounded">
          <MUIDataTable
            columnDefs={cityColumnDefs}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            totalItems={0}
            //   searchText={searchText}
            //   setSearchText={setSearchText}
            paginationModel={paginationModel}
            //   isLoading={isLoading}
          />
      </div> }
            {isZipActive && <div className="bg-white my-3 border rounded">
          <MUIDataTable
            columnDefs={zipColumnDefs}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            totalItems={0}
            //   searchText={searchText}
            //   setSearchText={setSearchText}
            paginationModel={paginationModel}
            //   isLoading={isLoading}
          />
      </div> }
            
            </div>
        </div>
    )
}

export default AreaPerformance;