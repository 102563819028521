import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  add_domain_runner,
  update_domain_runner,
} from "../../features/domainCenterSlice";
import { FormInput } from "../../components";
import { useFormik } from "formik";
import errorMessage from "../../util/errorMessage";
import { MultiSelect } from "primereact/multiselect";
import CustomModal from "../../components/molecules/Modal/customModal";
import IndustryFormData from "./IndustryFormData";

function AddUpdateDomainRunner({ editingRecord, onCancelForm, modalTitle, formSubmit }) {
  const multiselectRef = useRef(null);
  const dispatch = useDispatch();
  const { industryDrd } = useSelector((state) => state.industry);
  const [errors, setErrors] = useState([]);
  const [isUpdateIndustryArr, setIsUpdateIndustryArr] = useState(false);

  const initialValues = {
    ip: "",
    industry_id: [],
    runner_name: "",
    lb_server_file: "",
    lb_server_ip: "",
    ...(editingRecord && {
      id: editingRecord._id,
      ip: editingRecord.ip,
      industry_id: editingRecord?.industry_detail
        ? editingRecord.industry_detail.map((industry) => industry.industry_id)
        : [],
      industry_detail: editingRecord?.industry_detail
        ? editingRecord.industry_detail.map((industry) => ({
            industry_id: industry.industry_id,
            port: industry.port || "",
            default_domain: industry.default_domain || "",
          }))
        : [],
      runner_name: editingRecord.runner_name,
      lb_server_file: editingRecord.lb_server_file,
      lb_server_ip: editingRecord.lb_server_ip,
    }),
  };

  const handleSubmit = async (values) => {
    const payload = {
      ...(editingRecord && { id: editingRecord._id }),
      ip: values?.ip,
      runner_name: values?.runner_name,
      lb_server_file: values?.lb_server_file,
      lb_server_ip: values?.lb_server_ip,
      industry_detail: values?.industry_detail,
    };
    const action = editingRecord ? update_domain_runner : add_domain_runner;
    const message = editingRecord ? "updated" : "created";
    try {
      const res = await dispatch(action(payload));
      if (res.payload.status === 200 || res.payload.status === 201) {
        toast.success(`Domain Runner ${message} Successfully`);
        formSubmit();
      } else {
        toast.error(
          res.payload ? res.payload : `Domain Runner couldn't be ${message}`
        );
      }
    } catch (error) {
      errorMessage({
        payload: error,
        setErrors: setErrors,
        action: "Domain Runner",
        msg: message,
      });
      console.error("~ onSubmit ~ error", error);
    }
  };

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  const handleSaveIndustryData = (data) => {
    formik.setFieldValue("industry_detail", data);
  };

  const handleUpdateClick = () => {
    if (multiselectRef.current) {
      multiselectRef.current.hide();
    }
    setIsUpdateIndustryArr(true);
  };

  return (
    <CustomModal
      multiple
      isUpdate={editingRecord}
      handleModal={onCancelForm}
      cancelButtonRef={onCancelForm}
      className="max-w-3xl h-[380px]"
      modalType={true}
      title={editingRecord ? modalTitle.replaceAll("Add", "Edit") : modalTitle}
      formIsValid={formik.isValid}
      onSubmit={formik.handleSubmit}
    >
      {isUpdateIndustryArr ? (
        <IndustryFormData
          onCancelForm={() => setIsUpdateIndustryArr(false)}
          onSave={handleSaveIndustryData}
          industryDrd={industryDrd}
          initialIndustryDetails={formik.values.industry_detail}
          editingRecord={editingRecord}
        />
      ) : null}
      <div className="w-full grid grid-cols-2 gap-2">
        <FormInput errors={errors} name="ip" label="IP" formik={formik} />

        <MultiSelect
          ref={multiselectRef}
          placeholder="Select Industries"
          options={industryDrd}
          optionLabel="name"
          optionValue="_id"
          filter
          maxSelectedLabels={2}
          value={
            formik?.values?.industry_detail?.map((item) => item.industry_id) ||
            []
          }
          onChange={(e) => {
            const selectedIds = e.value;
            const updatedIndustryDetail = [];

            const currentIndustryDetail = formik.values.industry_detail || [];

            selectedIds.forEach((id) => {
              const industry = industryDrd.find((option) => option._id === id);
              if (industry) {
                const existingIndustry = currentIndustryDetail.find(
                  (item) => item.industry_id === industry._id
                );

                if (!existingIndustry) {
                  updatedIndustryDetail.push({
                    industry_id: industry._id,
                    port: industry.port,
                    default_domain: industry.default_domain,
                  });
                }
              }
            });

            const filteredIndustryDetail = currentIndustryDetail.filter(
              (item) => selectedIds.includes(item.industry_id)
            );

            formik.setFieldValue("industry_detail", [
              ...filteredIndustryDetail,
              ...updatedIndustryDetail,
            ]);
            formik.setFieldValue("industry_id", selectedIds);
          }}
          className="h-[35px] mb-2 w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
          panelClassName="max-h-50 overflow-y-auto"
        />

        {formik.values.industry_detail?.length > 0 && (
          <div className="col-span-2 py-1 leading-3 flex justify-center flex-col">
            <button
              type="button"
              className="text-xs hover:underline text-blue-500 text-left w-[200px]"
              onClick={handleUpdateClick}
            >
              Click here to update port and domain
            </button>
          </div>
        )}

        <FormInput
          errors={errors}
          name="runner_name"
          label="Runner Name"
          formik={formik}
          readOnly={!!editingRecord}
        />
        <FormInput
          errors={errors}
          name="lb_server_file"
          label="LB server file"
          formik={formik}
          readOnly={!!editingRecord}
        />
        <FormInput
          errors={errors}
          name="lb_server_ip"
          label="LB server IP"
          formik={formik}
        />
      </div>
    </CustomModal>
  );
}

export default AddUpdateDomainRunner;
