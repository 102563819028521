import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get_login_logs } from "../../features/reportsSlice";
import { useReactToPrint } from "react-to-print";
import ReportHead from "../Components/Header";
import Picker from "../Components/Picker";
import Select from "react-select";
import { get_users } from "../../features/usersSlice";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";

const SaleReport = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);
  const componentRef = React.useRef(null);
  const currentDate = new Date();
  const initialFilters = {
    from_date:
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        0,
        0,
        0,
        0
      )
        .toISOString()
        .slice(0, 23) + "Z",
    to_date: new Date().toISOString().slice(0, 23) + "Z",
      }
  const [filters, setFilters] = React.useState(initialFilters);
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(get_login_logs());
    // eslint-disable-next-line
  }, []);
  const columnDefs = [
    { headerName: "#", field: "counter" },
    { headerName: "Created At", field: "login_at",flex:1 },
    { headerName: "Job Created", field: "OS",flex:1 },
    { headerName: "Booked", field: "s",flex:1 },
    { headerName: "Completed", field: "OSs",flex:1 },
    { headerName: "Cancelled", field: "broswer",flex:1 },
  ];
  const exportOption = [
    // {
    //   label: "Export PDF",
    //   value: "pdf",
    // },
    {
      label: "Export CSV",
      value: "csv",
    },
  ];

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Login Report",
  });
  useEffect(() => {
    dispatch(get_users());
    // eslint-disable-next-line
  }, []);
  const multiSelectHandler = (e) => {
    let arr = [];
    // eslint-disable-next-line
    e?.map((val) => {
      arr = [...arr, val?.value];
    });
    // formik.setFieldValue("user_ids", arr.toString());
  };
  const style = {
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#ffe7c7",
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused || state.hover ? "#a9a9a9" : null,
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  return (
    <>
      <div className="container-fluid">
        <ReportHead route="Reports > Sale Report" heading="Login IP" />

        <div className="row">
          <div className="col-md-8 mb-2">
            <Select
              styles={style}
              placeholder="Search Technician"
              isMulti
              options={users.records}
              getOptionLabel={(options) => options?.first_name}
              getOptionValue={(options) => options?._id}
              onChange={(e) => {
                multiSelectHandler(e);
              }}
              className="w-full"
            />
          </div>

          <div className="col-md-4 !px-0.5 !mb-3">
          <Picker filterData={filters} setFiltersData={setFilters} />
          </div>
        </div>
      </div>
      <div className="bg-white my-3 border rounded">
          <MUIDataTable
            exportOption={exportOption}
            handlePrint={handlePrint}
            columnDefs={columnDefs}
            // items={record?.map((record, index) => {
            //   let counter = index + 1;
            //   let firstName =
            //     record?.user_id?.first_name +
            //     " " +
            //     (record?.user_id?.last_name ? record?.user_id?.last_name : "") +
            //     " (" +
            //     record?.user_id?.username +
            //     ")";
            //   let broswer = record?.browser;
            //   let OS = record?.os;
            //   let user_ip = record?.login_ip;
            //   let login_at = dayjs(record?.createdAt).format(
            //     "ddd, MMM D, YYYY h:mm A"
            //   );
            //   return {
            //     counter,
            //     firstName,
            //     user_ip,
            //     broswer,
            //     OS,
            //     login_at,
            //   };
            // })}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            totalItems={0}
            //   searchText={searchText}
            //   setSearchText={setSearchText}
            paginationModel={paginationModel}
            //   isLoading={isLoading}
          />
      </div>
      <div className="d-none"></div>
    </>
  );
};

export default SaleReport;
