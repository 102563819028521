import React from "react";
import { useLocation, Link } from "react-router-dom";
function Breadcrumbs({breadcrumb}) {
  let location = useLocation();
  const pathname= breadcrumb?breadcrumb:location.pathname
  const paths = pathname?.split("/").filter(Boolean);
  const breadcrumbs = paths?.map((path, i) => {
    let route = `/${paths.slice(0, i + 1).join("/")}`;
    return (
      <span key={i}>
        <Link
          to={i < paths.length - 1 ? route : ""}
          className={`text-base font-medium font-mont text-heading capitalize ${
            i < paths.length - 1
              ? "text-md font-semibold"
              : "hover:no-underline !text-gray-500 cursor-not-allowed"
          }`}
        >
          {path.replaceAll("_", " ")}
        </Link>
        {i < paths.length - 1 && <span> {">"} </span>}
      </span>
    );
  });
  return <div>{breadcrumbs}</div>;
}
export default Breadcrumbs;
