import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddUpdateRegistrarAccounts from "./AddUpdateRegistrarAccounts";
import { FaEdit, FaSearch } from "react-icons/fa";
import { get_registrar_account } from "../../../features/registrarAccountsSlice";
import { DotsLoader } from "../../../components";
import PageHeader from "../../../components/molecules/PageHeader";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import dayjs from "dayjs";
import AddCloudflareEmailModal from "./AddCloudflareEmailModal";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton } from "@mui/material";
function RegistrarAccounts() {
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchInput, setSearchInput] = React.useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [isCloudflareEmail, setCloudflareEmail] = useState(false);
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const dispatch = useDispatch();

  const { isLoading, registrarAccount } = useSelector(
    (state) => state.registrarAccounts
  );
  const togglePasswordVisibility = (id) => {
    if (showPassword === id) {
      setShowPassword(null);
    } else {
      setShowPassword(id);
    }
  };
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_registrar_account({
        ...sortingModel,
        filters: queryOptions,
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      dispatch(
        get_registrar_account({
          ...sortingModel,
          page: 1,
          size: paginationModel.pageSize,
        })
      );
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "onOrBefore"
              ? "lte"
              : rule.operator === "before"
              ? "lt"
              : rule.operator === "onOrAfter"
              ? "gte"
              : rule.operator === "after"
              ? "gt"
              : rule.operator,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    dispatch(
      get_registrar_account({
        ...sortingModel,
        filters: queryOptions,
        page: 1,
        size: paginationModel.pageSize,
      })
    );
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
      </GridToolbarContainer>
    );
  }
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    dispatch(
      get_registrar_account({
        filters: queryOptions,
        page: 1,
        size: paginationModel.pageSize,
        sort_field: params[0]?.field,
        sort_by: params[0]?.sort || "default",
      })
    );
  };
  useEffect(() => {
    dispatch(get_registrar_account());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const columnDefs = [
    { headerName: "#", field: "counter", width: 60, filterable: false },
    { headerName: "Email", field: "auth_email", flex: 1, minWidth: 200 },
    {
      headerName: "Domain Counter",
      field: "domains_count",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Email Password",
      field: "email_password",
      flex: 1,
      minWidth: 150,
      renderCell: (params) =>
        params.row.email_password ? (
          <div className="flex flex-row">
            {showPassword === params.row._id ? (
              <span>{params.value}</span>
            ) : (
              <span>********</span>
            )}
            <button
              className="ml-2 text-blue-500 underline cursor-pointer focus:outline-none"
              onClick={() => togglePasswordVisibility(params.row._id)}
            >
              {showPassword === params.row._id ? "Hide" : "Show"}
            </button>
          </div>
        ) : null,
    },
    {
      headerName: "Cloudflare Password",
      field: "cloudflare_password",
      flex: 1,
      minWidth: 150,
      renderCell: (params) =>
        params.row.cloudflare_password ? (
          <div className="flex flex-row">
            {showPassword === params.row._id ? (
              <span>{params.value}</span>
            ) : (
              <span>********</span>
            )}
            <button
              className="ml-2 text-blue-500 underline cursor-pointer focus:outline-none"
              onClick={() => togglePasswordVisibility(params.row._id)}
            >
              {showPassword === params.row._id ? "Hide" : "Show"}
            </button>
          </div>
        ) : null,
    },
    { headerName: "Phone", field: "email_phone", flex: 1, minWidth: 150 },
    {
      headerName: "Recovery Email",
      field: "reconvery_email",
      flex: 1,
      minWidth: 150,
    },
    { headerName: "Auth Key", field: "auth_key", flex: 1, minWidth: 150 },
    { headerName: "Available", field: "is_available", flex: 1, minWidth: 100 },
    {
      headerName: "Last Updated",
      field: "last_updated",
      flex: 1,
      minWidth: 200,
    },
    { headerName: "Account Id", field: "account_id", flex: 1, minWidth: 150 },
    { headerName: "Token", field: "token", flex: 1, minWidth: 125 },
    { headerName: "Blocked", field: "is_blocked", flex: 1, minWidth: 100 },
    {
      headerName: "Blocked Reason",
      field: "blocked_reason",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Note",
      field: "note",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex items-center w-full">
          <FaEdit
            onClick={openFormHandler(params.row.records)}
            className="my_navIcon cursor-pointer"
            size={20}
          />
        </div>
      ),
      width: 100,
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 10,
      },
    },
  ];
  const data = registrarAccount?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });

  const handleCloudflareEmail = () => {
    setCloudflareEmail(true);
  };
  return (
    <>
      {isEditing && (
        <AddUpdateRegistrarAccounts
          editingRecord={editingRecord}
          modalTitle="Add Cloudflare Account"
          onCancelForm={cancelFormHandler}
        />
      )}
      {isCloudflareEmail && (
        <AddCloudflareEmailModal
          modalTitle="Add Email For Cloudflare Account"
          onCancelForm={() => {
            setCloudflareEmail(false);
          }}
          onSubmit={() => {
            setCloudflareEmail(false);
            dispatch(get_registrar_account());
          }}
        />
      )}
      {isLoading ? <DotsLoader text="Please Wait" /> : null}
      <PageHeader
        heading="Cloudflare Accounts"
        onClick={openFormHandler(0)}
        isAllowed={true}
        additionalBtnTitle="Add Email"
        extraButton
        additionalBtnAllow
        editIcon="No"
        extraButtonClick={handleCloudflareEmail}
      />
      {!isLoading && (
        <div className="bg-white mt-3 border rounded">
          <MUIDataTable
            columnDefs={columnDefs}
            items={data?.map((record, index) => {
              let counter = index + 1;
              const {
                domains_count,
                _id,
                auth_key,
                auth_email,
                account_id,
                token,
                is_blocked,
                blocked_reason,
                email_password,
                cloudflare_password,
                email_phone,
                reconvery_email,
                note,
                last_updated,
                is_available,
              } = record;
              return {
                counter,
                records: record,
                domains_count,
                _id,
                auth_key,
                auth_email,
                is_available:
                  is_available === 1 || is_available === "1" ? "Yes" : "No",
                last_updated: last_updated
                  ? dayjs(record.last_updated).format("ddd, MMM D, YYYY h:mm A")
                  : null,
                account_id,
                token,
                is_blocked: is_blocked ? "Yes" : "No",
                blocked_reason,
                email_password,
                cloudflare_password,
                email_phone,
                reconvery_email,
                note,
              };
            })}
            isLoading={isLoading}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            paginationModel={paginationModel}
            totalItems={registrarAccount?.totalItems}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onFilterModelChange={onFilterChange}
            CustomToolbar={CustomToolbar}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            // pagination="No"
          />
        </div>
      )}
    </>
  );
}

export default RegistrarAccounts;
