import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createProvider, getProviders, updateProvider } from "../services/providerService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Payment Gateway
export const get_providers = createAsyncThunk(
  "get_providers",
  async (thunkAPI) => {
    try {
      return await getProviders();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Payment Gateway
export const create_provider = createAsyncThunk(
  "create_providers",
  async (data, thunkAPI) => {
    try {
      return await createProvider(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Payment Gateway
export const update_provider = createAsyncThunk(
  "update_providers",
  async (data, thunkAPI) => {
    try {
      return await updateProvider(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const providerSlice = createSlice({
  name: "providers",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_providers.pending, (state) => {
        state.isLoading = true;
        state.record = [];
      })
      .addCase(get_providers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_providers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
      })
      .addCase(create_provider.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_provider.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.push(action.payload.data);
      })
      .addCase(create_provider.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_provider.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_provider.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record[result] = {
          ...state.record[result],
          ...action.payload.data,
        };
      })
      .addCase(update_provider.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { reset } = providerSlice.actions;
export default providerSlice.reducer;
