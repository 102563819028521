import React, { useEffect, useState } from "react";
import AllowedIPForm from "./AllowedIPForm";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import {
  get_allowed_ip,
  delete_allowed_ip,
  allowedIPReset,
} from "../../features/allowedipSlice";
import { get_users_drd } from "../../features/usersSlice";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
const AllowedIp = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state?.allowedIP);
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_allowed_ip(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Allowed IP deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "Allowed IP",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Allowed IP", msg: "deleted" });
      }
    }
  };
  useEffect(() => {
    dispatch(get_allowed_ip({ page: 1, size: paginationModel.pageSize }));
    dispatch(get_users_drd());
    return () => {
      dispatch(allowedIPReset());
    };
    // eslint-disable-next-line
  }, []);
  const filterIP = record?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    if (data?.username && data.username?.length > 0) {
      const tagHeight = 20; // height of each tag
      const numTags = data?.username.length;
      return rowHeight + tagHeight * numTags + cellContentHeight;
    }
  };
  const TagsCellRenderer = (data) => {
    const tags = data?.value?.map((tag, index) => {
      return <span key={index}>{`${index + 1}. ${tag}`}</span>;
    });
    return <div className="flex flex-col w-auto">{tags}</div>;
  };
  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false },
    { headerName: "IP Address", field: "ip_address", flex: 1 },
    {
      headerName: "Allowed Users",
      field: "name",
      flex: 1,
      renderCell: TagsCellRenderer,
    },
    {
      headerName: "Username",
      field: "username",
      flex: 1,
      renderCell: TagsCellRenderer,
    },
    { headerName: "Status", field: "status", flex: 1 },
    {
      field: "actions",
      renderCell: (params) =>
        params.row.records.active ? (
          <div className="flex flex-row">
            {userPermission("Update Allowed IP") && (
              <FaEdit
                onClick={openFormHandler(params.row.records)}
                className="my_navIcon"
                title="Update AllowedIP"
              />
            )}
            {userPermission("Delete Allowed IP") && (
              <FaTrashAlt
                onClick={deleteRecordHandler(params.row.record_id)}
                className="my_navIcon"
                title="Delete AllowedIP"
              />
            )}
          </div>
        ) : null,
      width: 100,
      filterable: false,
    },
  ];
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_allowed_ip({
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  return (
    <>
      {isEditing && (
        <AllowedIPForm
          editingRecord={editingRecord}
          modalTitle="Add Allowed IP"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        heading="Allowed IP Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Allowed IP")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={filterIP?.map((record, index) => {
            let counter = index + 1;
            let records = record;
            let record_id = record._id;
            let ip_address = record?.ip_address;
            let name = record?.user_ids?.map((val) => {
              return val?.last_name
                ? val?.first_name + " " + val?.last_name
                : val?.first_name;
            });
            let username = (record?.user_ids || [])?.map(
              (val) => val?.username
            );
            let status = record?.active ? "Active" : "InActive";
            return {
              counter,
              records,
              record_id,
              ip_address,
              name,
              username,
              status,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          gridOptions={getRowHeight}
        />
      </div>
    </>
  );
};

export default AllowedIp;
