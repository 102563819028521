import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import { Button, FormInput, Loader } from "../../components";
import labels from "../Lead/Components/leads_labels.json"
const QuotingModal = ({ onClose, invoiceDetail, reloadReport }) => {
  const [isLoader, setIsLoader] = useState(false);
  const [amount, setAmount] = useState(invoiceDetail?.service_cost || 0);
  const handleConfirm = async (status) => {
    setIsLoader(true);
    try {
      const res = await api.post(
        `/api/leads/${invoiceDetail?._id}/update_quoting_price`,
        {
          status: status,
          price: amount,
        }
      );
      if (res.status === 200) {
        toast.success("Invoice created successfully");
        reloadReport();
        onClose();
      } else {
        toast.error(res?.data?.message || "Invoice couldn't be created");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error(
        err?.response?.data?.message || "Invoice couldn't be created"
      );
      console.log("🚀 ~ handleConfirm ~ err:", err);
    }
  };
  const formatValue = (val) => {
    if (typeof val !== "boolean" && !val) return null;
    if (typeof val === "boolean") {
      return val ? "Yes" : "No";
    }
    if (val && !val.includes("_")) return val;
    return val
      ?.split("_")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ");
  };
  const renderItem = (label, value) => (
    <div className="flex gap-x-1">
      <label className="text-client-50 font-medium text-sm">{label}</label>{" "}
      <span className="font-medium text-sm">{formatValue(value)}</span>
    </div>
  );
  const renderItemHeader = (label) => (
    <div className="col-span-1 text-client-100 pb-1.25 pl-0 border-b border-client-50 ml-2.5 mb-1.25 font-bold">
      {label}
    </div>
  );
  return (
    <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40 bg-black/60">
      <div className="bg-white p-8 rounded-lg shadow-lg relative min-w-[500px] max-w-[90%]">
        <h2 className="text-lg font-semibold mb-4">Quoting Request</h2>
        <div className="relative flex flex-col">
          <div className="grid grid-cols-2 gapx-2">
            {renderItem("Industry:", invoiceDetail?.industry_id?.name)}
            {invoiceDetail?.job_category_id
              ? renderItem(
                  "Job Category:",
                  invoiceDetail?.job_category_id?.name
                )
              : null}
          </div>
          <FormInput
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            name="amount"
            placeholder={"Service Cost"}
            label="Service Cost"
            className={"my-3"}
            type={"number"}
          />
        </div>
        <div className="pt-0 w-auto m-auto ">
          <div className={`md:p-2 overflow-y-auto max-h-[50vh]`}>
            <div className="!p-3 bg-white rounded shadow-client">
              {/* Basic  Information */}
              <div>
                {renderItemHeader("Basic Information")}
                <div className="ml-3 grid grid-cols-2 gap-x-4">
                  {renderItem("Name:", invoiceDetail?.name)}
                  {renderItem("Phone:", invoiceDetail?.phone)}
                  {renderItem("Alternate No:", invoiceDetail?.alternate_phone)}
                  {renderItem("Email:", invoiceDetail?.email)}
                </div>
              </div>
              {/* Pickup & Drop Location */}
              <div>
                {renderItemHeader("Pickup & Drop Location")}
                <div className="ml-3 grid grid-cols-1">
                  {renderItem(
                    "Pickup Address:",
                    invoiceDetail?.complete_pickup_address
                  )}

                  {invoiceDetail?.complete_drop_address
                    ? renderItem(
                        "Drop Address:",
                        invoiceDetail?.complete_drop_address
                      )
                    : null}
                  {invoiceDetail?.complete_drop_2_address
                    ? renderItem(
                        "2nd Drop off Address:",
                        invoiceDetail?.complete_drop_2_address
                      )
                    : null}
                  {invoiceDetail?.distance
                    ? renderItem("Distance:", invoiceDetail?.distance)
                    : null}
                </div>
              </div>
            </div>

            <div className="!p-3 bg-white rounded shadow-client mt-2">
              <div>
                {renderItemHeader("Service & Car Info")}
                {invoiceDetail?.extra_fields ? (
                  <div className="ml-3 grid grid-cols-3 gap-x-4 gap-y-1">
                    {Object.keys(invoiceDetail?.extra_fields).map((key) => {
                      const label = labels[key] || key;
                      return renderItem(
                        label,
                        invoiceDetail?.extra_fields[key]
                      );
                    })}
                  </div>
                ) : (
                  <div className="ml-3 grid grid-cols-4 gap-x-4 gap-y-1">
                    {renderItem("Year:", invoiceDetail?.car_year)}
                    {renderItem("Make:", invoiceDetail?.car_make)}
                    {renderItem("Modal:", invoiceDetail?.car_model)}
                    {renderItem(
                      "Is Neutral?",
                      invoiceDetail?.is_neutral ? "Yes" : "No"
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify- !mt-4">
          {isLoader ? (
            <Loader />
          ) : (
            <div className="flex justify-between w-full">
              <Button text="Close" variant="btn_cancel" onClick={onClose} />
              <div className="flex gap-x-2">
                <Button
                  text="Reject"
                  variant="btn_danger"
                  onClick={() => handleConfirm("Rejected")}
                />
                <Button
                  text="Accept"
                  variant="btn_submit"
                  onClick={() => handleConfirm("Quoted")}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuotingModal;
