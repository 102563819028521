import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, FormInput } from "../../../../components";
import {
  update_cost,
  update_invoice_details,
} from "../../../../features/jobsSlice";
import errorMessage from "../../../../util/errorMessage";
function InvoiceDetail(props) {
  const { formik, jobTypes } = props;
  const [isPercent, setIsPercent] = useState(true);
  const [isCostChange, setIsCostChange] = useState(false);
  const [isPartCharges, setIsPartCharges] = useState(false);
  const [isTechFee, setIsTechFee] = useState(false);
  const [isTax, setIsTax] = useState(false);
  const [isETA, setIsETA] = useState(false);
  const [isGOAPrice, setIsGOAPrice] = useState(false);

  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsCostChange(false);
      setIsTechFee(false);
      setIsTax(false);
      setIsGOAPrice(false);
      setIsETA(false);
      setIsPartCharges(false);
    }
  };
  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsCostChange(false);
      setIsTechFee(false);
      setIsTax(false);
      setIsGOAPrice(false);
      setIsETA(false);
      setIsPartCharges(false);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const updateCost = async () => {
    try {
      const res = await dispatch(
        update_cost({
          service_cost: formik.values.service_cost,
          id: formik.values.id,
        })
      );
      if (res?.payload?.status === 200) {
        toast.success("Service Cost update Successfully");
        setIsCostChange(false);
      } else {
        errorMessage({
          payload: res.payload,
          action: "Service Cost",
          msg: "Updated",
        });
      }
    } catch (err) {
      errorMessage({ payload: err, action: "Service Cost", msg: "updated" });
    }
  };
  const updateInvoice = async ({ field, value, goa_type }) => {
    try {
      const res = await dispatch(
        update_invoice_details({
          data: {
            update_field: field,
            update_value: value,
            goa_type: goa_type,
          },
          id: formik.values.id,
        })
      );
      if (res?.payload?.status === 200) {
        toast.success("Invoice detail update Successfully");
        setIsCostChange(false);
        setIsTax(false);
        setIsTechFee(false);
        setIsPartCharges(false);
      } else {
        errorMessage({
          payload: res.payload,
          action: "Invoice detail",
          msg: "Updated",
        });
      }
    } catch (err) {
      errorMessage({ payload: err, action: "Invoice detail", msg: "updated" });
    }
  };
  return (
    <div className="">
      <h5 className="!mb-2">Invoice Detail</h5>
      <div className="invoice_table grid-view overflow-scrol">
        <div className="!contents w-full table-responsive">
          <table className="items table table-striped table-bordered w-full relative">
            <thead>
              <tr>
                <th className="border">Item Description</th>
                <th className="border">Service Cost ($)</th>
                <th className="border">Parts Charges ($)</th>
                <th className="border">Net Amount ($)</th>
                <th className="border">Tech Share ($)</th>
                <th className="border">Company Share ($)</th>
                {/* <th className="border">
                  GOA Price{" "}
                  <span
                    className="ml-1"
                    onClick={() => setIsPercent(!isPercent)}
                    style={{ cursor: "pointer", color: "blue" }}
                  >
                    {isPercent ? "%" : "$"}
                  </span>
                </th> */}
                {/* <th className="border">ETA (Minutes)</th> */}

                {/* <th className="border">Tax ($)</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>
                    {
                      jobTypes?.record?.records?.find(
                        (type) => type?._id === formik?.values?.job_category_id
                      )?.name
                    }
                  </strong>
                </td>

                <td>
                  <span
                    style={{ cursor: "pointer", color: "blue" }}
                    onClick={() => setIsCostChange(true)}
                  >
                    {formik.values.service_cost
                      ? formik.values.service_cost
                      : 0}
                  </span>

                  {isCostChange && (
                    <div
                      className="absolute"
                      style={{
                        zIndex: 111,
                        marginLeft: -22,
                        position: "absolute",
                      }}
                      ref={inputRef}
                    >
                      <div
                        className="relative w-full h-full bg-white py-2.5 px-5 rounded-lg mt-2.5 shadow-md"
                        style={{ maxWidth: 200 }}
                      >
                        <div className="">
                          <FormInput
                            formik={formik}
                            name="service_cost"
                            type="text"
                          />
                          <div className="flex justify-end w-full">
                            <Button
                              text="Save"
                              className="btn mt-1"
                              color="#fff"
                              onClick={updateCost}
                              variant="btn_submit"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </td>

                <td>
                  <span
                    style={{ cursor: "pointer", color: "blue" }}
                    onClick={() => setIsPartCharges(true)}
                  >
                    {formik.values.parts_charge
                      ? formik.values.parts_charge
                      : 0}
                  </span>

                  {isPartCharges && (
                    <div
                      className="absolute"
                      style={{
                        zIndex: 111,
                        marginLeft: -22,
                        position: "absolute",
                      }}
                      ref={inputRef}
                    >
                      <div
                        className="relative w-full h-full bg-white py-2.5 px-5 rounded-lg mt-2.5 shadow-md"
                        style={{ maxWidth: 200 }}
                      >
                        <div className="">
                          <FormInput
                            formik={formik}
                            name="parts_charge"
                            type="text"
                          />
                          <div className="flex justify-end w-full">
                            <Button
                              text="Save"
                              className="btn mt-1"
                              color="#fff"
                              onClick={() =>
                                updateInvoice({
                                  field: "parts_charge",
                                  value: formik.values.parts_charge,
                                })
                              }
                              variant="btn_submit"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </td>
                <td>
                  {formik.values.service_cost - formik.values.parts_charge}
                </td>
                <td id="cTechFeeT">
                  <span
                    style={{ cursor: "pointer", color: "blue" }}
                    onClick={() => setIsTechFee(true)}
                  >
                    {formik.values.tech_fee ? formik.values.tech_fee : 0}
                  </span>
                  {isTechFee && (
                    <div
                      className="absolute"
                      style={{
                        zIndex: 111,
                        marginLeft: -22,
                        position: "absolute",
                      }}
                      ref={inputRef}
                    >
                      <div
                        className="relative w-full h-full bg-white py-2.5 px-5 rounded-lg mt-2.5 shadow-md"
                        style={{ maxWidth: 200 }}
                      >
                        <div className="">
                          <FormInput
                            formik={formik}
                            name="tech_fee"
                            type="text"
                          />
                          <div className="flex justify-end w-full">
                            <Button
                              text="Save"
                              className="btn mt-1"
                              // backgroundColor="#f46424"
                              color="#fff"
                              onClick={() =>
                                updateInvoice({
                                  field: "tech_fee",
                                  value: formik.values.tech_fee,
                                })
                              }
                              variant="btn_submit"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </td>

                <td className="cAmtClass">
                  {(
                    +formik.values.service_cost -
                    formik.values.parts_charge -
                    formik.values.tech_fee
                  )?.toFixed(2)}
                </td>

                {/* <td className="cAmtClass">
                  <span
                    style={{ cursor: "pointer", color: "blue" }}
                    onClick={() => setIsGOAPrice(true)}
                  >
                    {formik.values.goa_price ? formik.values.goa_price : 0}
                  </span>
                  {isGOAPrice && (
                    <div
                      className="absolute"
                      style={{
                        zIndex: 111,
                        marginLeft: -22,
                        position: "absolute",
                      }}
                      ref={inputRef}
                    >
                      <div
                        className="relative w-full h-full bg-white py-2.5 px-5 rounded-lg mt-2.5 shadow-md"
                        style={{ maxWidth: 200 }}
                      >
                        <div className="">
                          <FormInput
                            formik={formik}
                            name="goa_price"
                            type="text"
                          />
                          <div className="flex justify-end w-full">
                            <Button
                              text="Save"
                              className="btn mt-1"
                              color="#fff"
                              onClick={() =>
                                updateInvoice({
                                  field: "goa_price",
                                  value: formik.values.goa_price,
                                  goa_type: isPercent ? "percentage" : "amount",
                                })
                              }
                              variant="btn_submit"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </td> */}

                {/* <td id="cTax">
                  <span
                    style={{ cursor: "pointer", color: "blue" }}
                    onClick={() => setIsETA(true)}
                  >
                    {formik?.values?.eta ? formik?.values?.eta : 0}
                  </span>
                  {isETA && (
                    <div
                      className="absolute"
                      style={{
                        zIndex: 111,
                        marginLeft: -22,
                        position: "absolute",
                      }}
                      ref={inputRef}
                    >
                      <div
                        className="relative w-full h-full bg-white py-2.5 px-5 rounded-lg mt-2.5 shadow-md"
                        style={{ maxWidth: 200 }}
                      >
                        <div className="">
                          <FormInput formik={formik} name="eta" type="text" />
                          <div className="flex justify-end w-full">
                            <Button
                              text="Save"
                              className="btn mt-1"
                              // backgroundColor="#f46424"
                              color="#fff"
                              onClick={() =>
                                updateInvoice({
                                  field: "eta",
                                  value: formik.values.eta,
                                })
                              }
                              variant="btn_submit"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </td> */}

                {/* <td id="cTax">
                  <span
                    style={{ cursor: "pointer", color: "blue" }}
                    onClick={() => setIsTax(true)}
                  >
                    {formik.values.tax ? formik.values.tax : 0}
                  </span>
                  {isTax && (
                    <div
                      className="absolute"
                      style={{
                        zIndex: 111,
                        marginLeft: -22,
                        position: "absolute",
                      }}
                      ref={inputRef}
                    >
                      <div
                        className="relative w-full h-full bg-white py-2.5 px-5 rounded-lg mt-2.5 shadow-md"
                        style={{ maxWidth: 200 }}
                      >
                        <div className="">
                          <FormInput formik={formik} name="tax" type="text" />
                          <div className="flex justify-end w-full">
                            <Button
                              text="Save"
                              className="btn mt-1"
                              // backgroundColor="#f46424"
                              color="#fff"
                              onClick={() =>
                                updateInvoice({
                                  field: "tax",
                                  value: formik.values.tax,
                                })
                              }
                              variant="btn_submit"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </td> */}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default InvoiceDetail;
