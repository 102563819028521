import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createPurchaseRegistrar,
  deletePurchaseRegistrar,
  getPurchaseRegistrar,
  updatePurchaseRegistrar,
} from "../services/purcahseRegistrarService";
const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
  record: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Industry Data
export const get_purcahse_registrar = createAsyncThunk(
  "get_purcahse_registrar",
  async (thunkAPI) => {
    try {
      return await getPurchaseRegistrar();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Industry
export const create_purcahse_registrar = createAsyncThunk(
  "create_purcahse_registrar",
  async (data, thunkAPI) => {
    try {
      return await createPurchaseRegistrar(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Industry
export const update_purcahse_registrar = createAsyncThunk(
  "update_purcahse_registrar",
  async (data, thunkAPI) => {
    try {
      return await updatePurchaseRegistrar(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Industry
export const delete_purcahse_registrar = createAsyncThunk(
  "delete_purcahse_registrar",
  async (id, thunkAPI) => {
    try {
      return await deletePurchaseRegistrar(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const purchaseRegistrar = createSlice({
  name: "purcahseRegistrar",
  initialState,
  reducers: {
    purchaseRegistrarReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_purcahse_registrar.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_purcahse_registrar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_purcahse_registrar.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_purcahse_registrar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_purcahse_registrar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.push(action.payload.data);
      })
      .addCase(create_purcahse_registrar.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_purcahse_registrar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_purcahse_registrar.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.findIndex(
          ({ id }) => id === action?.payload?.data?.id
        );
        state.record[result] = {
          ...state.record[result],
          ...action.payload.data,
        };
      })
      .addCase(update_purcahse_registrar.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_purcahse_registrar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_purcahse_registrar.fulfilled, (state, action) => {
        const result = state.record.filter(
          ({ _id }) => _id !== action?.payload?.data?.data
        );
        state.record = result;
        state.isLoading = false;
      })
      .addCase(delete_purcahse_registrar.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
export const { purchaseRegistrarReset } = purchaseRegistrar.actions;
export default purchaseRegistrar.reducer;
