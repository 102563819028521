import React, { useState } from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  create_call_menu,
  get_call_menu,
} from "../../../features/callCentersSlice";
function AddCallMenuForm({ editingRecord, onCancelForm, modalTitle }) {
  const dispatch = useDispatch();
  const { isLoading, audioFiles } = useSelector((state) => state.callCenters);
  const [errors, setErrors] = useState([]);
  let initialValues = {
    menu_name: "",
    menu_prompt: "",
    menu_timeout_prompt: "",
    menu_timeout: "",
    menu_invalid_prompt: "",
    menu_repeat: "",
  };
  const handleSubmit = async (values) => {
    try {
      const res = await dispatch(create_call_menu(values));
      if (res?.payload.status === 200) {
        toast.success("Call Menu Successfully created");
        dispatch(get_call_menu());
        onCancelForm();
      } else if (res?.data?.failed?.length) {
        toast.error(
          res.payload ? res.payload : `Call Menu couldn't be created`
        );
      }
    } catch (error) {
      if (Array.isArray(error.payload) || typeof error.payload === "object") {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload ? error.payload : `Call Menu couldn't be created`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  return (
    <Modal
      isUpdate={!!editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      formIsValid={formik.isValid}
      onSubmit={formik.handleSubmit}
      isLoading={isLoading}
      onClick={onCancelForm}
      modalClass={"!min-w-[950px]"}
    >
      {isLoading ? <DotsLoader /> : null}
      <div className="grid md:grid-cols-3 gap-3 mt-3 mx-2.5 w-full">
        <div className="">
          <FormInput
            errors={errors}
            name="menu_name"
            label="Menu Name"
            formik={formik}
          />
        </div>
        <div className="">
          <FormSelectInput
            errors={errors}
            name="menu_prompt"
            label="Menu Prompt"
            formik={formik}
            options={audioFiles}
            valueProp="audio_filename"
            labelProp="real_file_name"
          />
        </div>
        <div className="">
          <FormSelectInput
            errors={errors}
            name="menu_timeout_prompt"
            label="Menu Timeout Prompt"
            formik={formik}
            options={audioFiles}
            valueProp="audio_filename"
            labelProp="real_file_name"
          />
        </div>
        <div className="">
          <FormInput
            errors={errors}
            name="menu_timeout"
            label="Menu Timeout"
            formik={formik}
          />
        </div>
        <div className="">
          <FormSelectInput
            errors={errors}
            name="menu_invalid_prompt"
            label="Menu Invalid Prompt"
            formik={formik}
            options={audioFiles}
            valueProp="audio_filename"
            labelProp="real_file_name"
          />
        </div>
        <div className="">
          <FormInput
            errors={errors}
            name="menu_repeat"
            label="Menu Repeat"
            formik={formik}
          />
        </div>
      </div>
    </Modal>
  );
}

export default AddCallMenuForm;
