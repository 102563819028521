import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateWords";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import api from "../../services/api";
import { FaEdit, FaTrashAlt, FaSearch } from "react-icons/fa";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton } from "@mui/material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
const RestrictedWords = () => {
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [restrictedWords, setRestrictedWords] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const getWords = async (filter) => {
    if (!filter?.filters?.rules?.length) {
      delete filter.filters;
    }
    delete filter?.pageSize;
    setIsLoading(true);
    try {
      const res = await api.post(`/api/restrict_word_email/list`, filter);
      if (res.status === 200) {
        setRestrictedWords(res?.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getWords ~ err:", err);
    }
  };
  useEffect(() => {
    getWords({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
      filters: queryOptions,
      type: activeTabIndex === 0 ? "words" : "email",
    });
    // eslint-disable-next-line
  }, [activeTabIndex]);
  const words = restrictedWords?.records?.filter((word) =>
    word?.name?.toLowerCase().includes(searchText.toLowerCase())
  );
  const reloadList = () => {
    getWords({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
      filters: queryOptions,
      type: activeTabIndex === 0 ? "words" : "email",
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      getWords({
        ...sortingModel,
        page: 1,
        size: paginationModel.pageSize,
        type: activeTabIndex === 0 ? "words" : "email",
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : // : rule.operator === "isEmpty"
                // ? "eq"
                rule.operator,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getWords({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      type: activeTabIndex === 0 ? "words" : "email",
    });
  };
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getWords({
      filters: queryOptions,
      ...sortingModel,
      page: +params.page + 1,
      size: params.pageSize,
      type: activeTabIndex === 0 ? "words" : "email",
    });
  };
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      setIsLoading(true);
      try {
        const res = await api.post(
          `/api/restrict_word_email/remove/${record_id}`,
          { email: activeTabIndex === 0 ? null : "email" }
        );
        if (res?.status === 200) {
          toast.success(res.data.message || "Word deleted successfully");
          await reloadList();
        } else {
          errorMessage({
            payload: res.data.message,
            action: `${activeTabIndex === 0 ? "Word" : "Email"}`,
            msg: "deleted",
          });
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        errorMessage({
          payload: error?.response.data.message,
          action: "Word",
          msg: "deleted",
        });
      }
    }
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
      </GridToolbarContainer>
    );
  }
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    getWords({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
      type: activeTabIndex === 0 ? "words" : "email",
    });
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100, filterable: false },
    { headerName: "Word", field: "name", flex: 1 },
    {
      field: "actions",
      filterable: false,
      renderCell: (params) => (
        <div>
          {userPermission("Restricted Words") && (
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update Restricted Words"
            />
          )}
          {userPermission("Restricted Words") && (
            <FaTrashAlt
              onClick={deleteRecordHandler(params.row.records._id)}
              className="my_navIcon"
              title="Delete Restricted Words"
            />
          )}
        </div>
      ),
    },
  ];
  const handleSelect = (index) => {
    console.log("👊 ~ handleSelect ~ index:", index);
    setActiveTabIndex(index);
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add restricted words"
          onCancelForm={cancelFormHandler}
          reloadList={reloadList}
          dataType={`${
            editingRecord && activeTabIndex === 0
              ? "word"
              : editingRecord && activeTabIndex === 1
              ? "email"
              : !editingRecord && activeTabIndex === 0
              ? "words"
              : !editingRecord && activeTabIndex === 1
              ? "emails"
              : null
          }`}
        />
      )}
      <PageHeader
        route="Setting > Industries"
        heading="Restricted Words Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Domain Center")}
      />
      <Tabs selectedIndex={activeTabIndex} onSelect={handleSelect}>
        <TabList>
          <Tab selectedClassName={"!bg-primary-100 !text-white"}>Restricted Words</Tab>
          <Tab selectedClassName={"!bg-primary-100 !text-white"}>Restricted Emails</Tab>
        </TabList>

        <TabPanel>
          <div className="bg-white my-3 border rounded">
            <MUIDataTable
              columnDefs={columnDefs}
              items={words?.map((record, index) => {
                const { name } = record;
                let counter = index + 1;
                return {
                  records: { ...record },
                  counter,
                  name: `'${name}'`,
                };
              })}
              totalItems={restrictedWords?.totalItems}
              searchText={searchText}
              setSearchText={setSearchText}
              isLoading={isLoading}
              paginationModel={paginationModel}
              onPaginationModelChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              onFilterModelChange={onFilterChange}
              CustomToolbar={CustomToolbar}
              density="standard"
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="bg-white my-3 border rounded">
            <MUIDataTable
              columnDefs={columnDefs}
              items={words?.map((record, index) => {
                const { name } = record;
                let counter = index + 1;
                return {
                  records: { ...record },
                  counter,
                  name: `'${name}'`,
                };
              })}
              totalItems={restrictedWords?.totalItems}
              searchText={searchText}
              setSearchText={setSearchText}
              isLoading={isLoading}
              paginationModel={paginationModel}
              onPaginationModelChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              onFilterModelChange={onFilterChange}
              CustomToolbar={CustomToolbar}
              density="standard"
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
            />
          </div>
        </TabPanel>
      </Tabs>
    </>
  );
};

export default RestrictedWords;
