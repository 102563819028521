import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  generateReport,
  getDomainsReport,
  regenerateReport,
} from "../services/domainActiveReportServices";

const initialState = {
  isError: false,
  isLoading: false,
  domainReport: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Users Roles
export const get_domain_report = createAsyncThunk(
  "get_domain_report",
  async (domains, thunkAPI) => {
    try {
      return await getDomainsReport(domains);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Roles
export const generate_domain_report = createAsyncThunk(
  "generate_domain_report",
  async (data, thunkAPI) => {
    try {
      return await generateReport(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Roles
export const regenerate_domain_report = createAsyncThunk(
  "regenerate_domain_report",
  async (domains, thunkAPI) => {
    try {
      return await regenerateReport(domains);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const domainReportSlice = createSlice({
  name: "domains_active_report",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.domainReport = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_domain_report.pending, (state) => {
        state.isLoading = true;
        state.domainReport = [];
      })
      .addCase(get_domain_report.fulfilled, (state, action) => {
        state.isLoading = false;
        state.domainReport = action.payload?.data;
      })
      .addCase(get_domain_report.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.domainReport = [];
      })
      .addCase(generate_domain_report.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(generate_domain_report.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Testing started for all domains");
      })
      .addCase(generate_domain_report.rejected, (state) => {
        state.isLoading = false;
        toast.error("Testing couldn't be started");
      })
      .addCase(regenerate_domain_report.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(regenerate_domain_report.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Testing started for selected domains");
      })
      .addCase(regenerate_domain_report.rejected, (state) => {
        state.isLoading = false;
        toast.error("Testing couldn't be started");
      });
  },
});

export const { reset } = domainReportSlice.actions;
export default domainReportSlice.reducer;
