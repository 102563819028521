import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/molecules/Modal";
import { toast } from "react-toastify";
import DotsLoader from "../../components/atoms/DotsLoader";
import TagsInput from "../../components/atoms/CustomTagsInput";
import { add_nation_site_zips } from "../../features/nationSitesSlice";
function AddUpdateZips({ editingRecord, onCancelForm, modalTitle, getData }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.servers);
  const { record } = useSelector((state) => state?.industry);
  const sortedIndustries = [...(record?.records || [])]?.sort((a, b) =>
    a?.name.localeCompare(b?.name)
  );
  //   const [zips, setZips] = useState([]);
  let initialData = {
    industry_id: "",
    zips: [],
    isDeleted: 0,
  };

  if (editingRecord) {
    // eslint-disable-next-line
    const { industry_id, zips } = editingRecord;
    initialData = {
      industry_id,
      zips,
      isDeleted: 1,
    };
  }
  const [data, setData] = useState(initialData);

  const onSubmit = async () => {
    if (!data?.industry_id) return toast.error("Industry is required.");
    let c;
    if (data?.isDeleted && !data?.zips?.length) {
      c = window.confirm("Are you sure want to delete all the zip codes?");
    }
    if(data?.isDeleted && !data?.zips?.length && !c) return;
    // if (!data?.zips?.length) return toast.error("Please add at least 1 zip.");
    try {
      const res = await dispatch(add_nation_site_zips(data));
      if (res.payload.status === 200) {
        dispatch(getData(data?.industry_id));
        onCancelForm();
      } else {
        toast.error(res?.payload || "Zips couldn't be updated");
      }
    } catch (error) {
      toast.error(
        error?.response?.message || error?.payload || "Zips couldn't be updated"
      );
      console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
    }
  };
  return (
    <Modal
      isUpdate={editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={onSubmit}
      onClick={onCancelForm}
    >
      {isLoading && <DotsLoader />}
      <div className="container-fluid max-w-[900px] border rounded bg-white py-3 w-full flex flex-row justify-between">
        <div className="w-full !mr-2 grid grid-cols-2 gap-x-2">
          <div className="col-span-2 !mt-3 flex justify-between">
            <select
              value={data.industry_id}
              onChange={(e) =>
                setData({ ...data, industry_id: e.target.value })
              }
              className="border p-1 rounded h-[35px] ml-2"
            >
              <option value="">Select Industry</option>
              {sortedIndustries.map((option) => (
                <option
                  key={option.cms_id}
                  value={option.cms_id}
                  className="flex justify-between"
                >
                  {option?.name}
                </option>
              ))}
            </select>
            <span className="font_pop text-lg font-semibold">
              Total Zips: {data?.zips?.length}
            </span>
          </div>
          <div className="col-span-2 !mt-3">
            <TagsInput
              tags={data?.zips}
              setTags={(tag) => setData({ ...data, zips: tag })}
              placeholder="Add Zip"
            />
          </div>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
}

export default AddUpdateZips;
