import React, { useCallback } from "react";
import { DotsLoader, Modal } from "../../components";
import { toast } from "react-toastify";
import TagsInput from "../../components/atoms/CustomTagsInput";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import api from "../../services/api";
const AddDidForm = ({
  national_site_id,
  onCancelForm,
  modalTitle,
  get_dids,
}) => {
  const [dids, setDids] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    let files = acceptedFiles[0];
    handleFile(files);
  }, []);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });
  const handleFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const coulmnName = "DIDs";
      if (file.name.endsWith(".xlsx")) {
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headerRow = excelData[0];
        const didColumnIndex = headerRow.findIndex(
          (column) => column.toLowerCase() === coulmnName.toLowerCase()
        );
        if (didColumnIndex > -1) {
          const zips = excelData?.slice(1)?.map((item) => item[didColumnIndex]);
          setDids((prevData) => [...prevData, ...zips]);
        } else {
          const zips = excelData?.slice(1)?.map((item) => item[0]);
          setDids((prevData) => [...prevData, ...zips]);
        }
      }
    };
    reader.readAsArrayBuffer(file);
  };
  const generateRandomZipCodes = () => {
    const dids = [];
    for (let i = 0; i < 5; i++) {
      const randomZip = String(
        Math.floor(1000000000 + Math.random() * 9000000000)
      );
      dids.push({ dids: randomZip });
    }
    return dids;
  };
  const exportToExcel = () => {
    const zipCodes = generateRandomZipCodes();
    const ws = XLSX.utils.json_to_sheet(zipCodes);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "DIDs");
    XLSX.writeFile(wb, "dids.xlsx");
    return;
  };
  const handleSubmit = async () => {
    if (!dids?.length) return toast.error("Please add the DIDs");
    setIsLoading(true);
    try {
      const res = await api.post(`/api/nation/did/${national_site_id}`, dids);
      if (res?.status === 200 || res?.status === 201) {
        toast.success(res?.data?.message || `DIDs added successfully`);
        get_dids();
        onCancelForm();
      } else {
        toast.error(
          res?.data?.message ? res?.data?.message : `DIDs couldn't be added`
        );
      }
      setIsLoading(false);
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error);
      setIsLoading(false);
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : `DIDs couldn't be added`
      );
    }
  };
  return (
    <Modal
      isUpdate={false}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      onClick={() => {}}
    >
      {isLoading && <DotsLoader />}
      <div className="w-full !mr-2 grid grid-cols-1 gap-x-2">
        <div className="text-base font-medium">
          <span className="font-semibold text-lg font_pop text-red-600">
            Note:{" "}
          </span>{" "}
          <span className="font-semibold text-lg font_pop text-violet-600">
            ".xlsx"
          </span>{" "}
          files can be import here which must has a column header named
          <span className="font-semibold text-lg font_pop text-violet-600">
            {" "}
            "DIDs".
          </span>
          <span
            className="font-normal text-normal font_pop text-blue-600 underline cursor-pointer ml-3"
            onClick={exportToExcel}
          >
            Sample
          </span>
        </div>
        <div className="!my-3">
          <div
            className={`w-full flex justify-center border-2 border-gray-300 border-dashed rounded-md !p-2 hover:border-gray-400 focus:outline-none ${
              isDragActive ? "bg-gray-100" : "bg-white"
            }`}
            {...getRootProps()}
          >
            <label
              className={`flex justify-center w-full ${"h-[80px]"} px-4 transition appearance-none cursor-pointer`}
            >
              <span className="flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-gray-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  />
                </svg>
                <span className="font-medium text-gray-600">
                  Drop files to Attach, or{" "}
                  <span className="text-blue-600 underline" onClick={open}>
                    browse
                  </span>
                </span>
              </span>
              <input {...getInputProps()} />
            </label>
          </div>
        </div>
        {dids?.length ? (
          <div className="flex justify-between">
            <span className="text-base font-semibold font-pp">
              Total DIDs: {dids?.length}
            </span>
            <span
              className="text-blue-600 cursor-pointer hover:underline"
              onClick={() => setDids([])}
            >
              Clear
            </span>
          </div>
        ) : null}
        <div className="">
          <TagsInput tags={dids} setTags={setDids} placeholder="Add DID" />
        </div>
      </div>
    </Modal>
  );
};

export default AddDidForm;
