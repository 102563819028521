import React from "react";
import { Button, FormInput, DotsLoader } from "../../../components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import errorMessage from "../../../util/errorMessage";
import api from "../../../services/api";

const UserCompanyEmail = ({ companyEmail, editingRecord }) => {
  const [errors, setErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const initialValues = {
    new_email: "",
  };

  const CompanyEmail = Yup.object().shape({
    new_email: Yup.string()
      .required("Company Email Alias is required")
      .matches(
        /^[a-zA-Z0-9._-]+$/,
        "Email alias can only contain alphanumeric characters (letters and numbers), hyphens, underscores, and dots"
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: CompanyEmail,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const payload = {
          user_id: editingRecord?._id,
          new_email: values?.new_email,
        };
        const res = await api.put(
          "/api/users/admin_change_user_company_email",
          payload
        );
        if (res.status === 200 || res.status === 201) {
          toast.success("Company Email changed successfully!");
        } else {
          errorMessage({
            payload: res.payload,
            setErrors: setErrors,
            action: "Company Email",
            msg: "changed",
          });
        }
      } catch (err) {
        toast.error(
          err?.response?.data?.message ||
            "An error occurred while changing the email"
        );
      } finally {
        setLoading(false);
      }
    },
  });

  const handleButtonClick = () => {
    formik.handleSubmit();
  };

  function getDomainWithAtFromEmail(email) {
    const atIndex = email.indexOf("@");
    return atIndex !== -1 ? email.substring(atIndex) : "Invalid email address";
  }

  return (
    <div className="!py-5 bg-white col-span-2">
      {loading && <DotsLoader />}
      <h5 className="!mb-3 text-client-50 pb-2.5 w-full border-b border-client-50">
        Change Company Email ({companyEmail})
      </h5>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="grid md:grid-cols-2 gap-x-2 !mt-5">
          <div className="relative">
            <FormInput
              name="new_email"
              placeholder="Enter Email Alias"
              type="text"
              label="Enter Email Alias"
              formik={formik}
              errors={errors}
            />
            <span
              className="absolute right-1 top-1 rounded bg-primary-100 py-1 px-2.5 border border-transparent text-center text-sm text-white transition-all shadow-sm hover:shadow focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              type="button"
            >
              {getDomainWithAtFromEmail(companyEmail || "" )}
            </span>
          </div>
          <div>
            <Button
              type="button"
              text="Save"
              variant="btn_submit"
              className="mt-0"
              onClick={handleButtonClick}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserCompanyEmail;
