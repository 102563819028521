import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get_login_logs } from "../../features/reportsSlice";
import ReportHead from "../Components/Header";
import Picker from "../Components/Picker";
import Select from "react-select";
import { get_users } from "../../features/usersSlice";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";

const TransactionReport = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(get_login_logs());
    // eslint-disable-next-line
  }, []);
  const currentDate = new Date();
  const initialFilters = {
    from_date:
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        0,
        0,
        0,
        0
      )
        .toISOString()
        .slice(0, 23) + "Z",
    to_date: new Date().toISOString().slice(0, 23) + "Z",
      }
  const [filters, setFilters] = React.useState(initialFilters);
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Job #", field: "firstName" },
    {
      headerName: "Created",
      field: "user_ip",

      cellRendererFramework: (params) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://whatismyipaddress.com/ip/${params?.data?.user_ip}`}
        >
          {params?.data?.user_ip}
        </a>
      ),
    },
    { headerName: "Status", field: "broswer" },
    { headerName: "Tags", field: "OS" },
    { headerName: "Closed", field: "login_at" },
    { headerName: "Job Type", field: "broswer" },
    { headerName: "Client Type", field: "OS" },
    { headerName: "Closed", field: "login_at" },
    { headerName: "Job Type", field: "broswer" },
    { headerName: "Client Type", field: "OS" },
    { headerName: "Address", field: "login_at" },

    { headerName: "Total", field: "broswer" },
    { headerName: "Cash", field: "OS" },
    { headerName: "Credit", field: "login_at" },
    { headerName: "Tech", field: "broswer" },
    { headerName: "Tech fee", field: "OS" },
    { headerName: "Tech Share", field: "login_at" },
    { headerName: "Tech Profit", field: "broswer" },
    { headerName: "Company Profit", field: "OS" },
  ];
  useEffect(() => {
    dispatch(get_users());
    // eslint-disable-next-line
  }, []);
  const multiSelectHandler = (e) => {
    let arr = [];
    // eslint-disable-next-line
    e?.map((val) => {
      arr = [...arr, val?.value];
    });
    // formik.setFieldValue("user_ids", arr.toString());
  };
  const style = {
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#ffe7c7",
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused || state.hover ? "#a9a9a9" : null,
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  return (
    <>
      <div className="container-fluid">
        <ReportHead route="Reports > Transaction Report" heading="Login IP" />

        <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
          <div className="md:col-span-8 pl-0">
            
              <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
                <div className="md:col-span-3 !px-0.5 !mb-3">
                  <Select
                    styles={style}
                    placeholder="Type"
                    isMulti
                    options={users}
                    getOptionLabel={(options) => options?.first_name}
                    getOptionValue={(options) => options?._id}
                    onChange={(e) => {
                      multiSelectHandler(e);
                    }}
                    className="w-full"
                  />
                </div>
                <div className="md:col-span-3 !px-0.5 !mb-3">
                <input className="form-control" placeholder="Job ID"/>

                </div>
                <div className="md:col-span-3 !px-0.5 !mb-3">
                <input className="form-control" placeholder="Transaction ID"/>

                </div>
                <div className="md:col-span-3 !px-0.5 !mb-3">
                  <Select
                    styles={style}
                    placeholder="Job Type"
                    isMulti
                    options={users}
                    getOptionLabel={(options) => options?.first_name}
                    getOptionValue={(options) => options?._id}
                    onChange={(e) => {
                      multiSelectHandler(e);
                    }}
                    className="w-full"
                  />
                </div>
              </div>
            
          </div>

          <div className="md:col-span-4 !px-0.5 !mb-3">
            <Picker filterData={filters} setFiltersData={setFilters} />
          </div>
        </div>
      
      
        <div className="bg-white my-3 border rounded">
          <MUIDataTable
            columnDefs={columnDefs}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            totalItems={0}
            //   searchText={searchText}
            //   setSearchText={setSearchText}
            paginationModel={paginationModel}
            //   isLoading={isLoading}
          />
      </div>
      </div>
    </>
  );
};

export default TransactionReport;
