import React, { useState, useEffect, useRef } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  InfoWindow,
  Autocomplete,
  DrawingManager,
} from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import styles from "./style.module.css";
import dayjs from "dayjs";
import { marker_pin } from "../../images";
import DateRangeComp from "../../components/molecules/DateRangePicker";
import { FaSearch, FaBusinessTime, FaTrashAlt } from "react-icons/fa";
import { SlUser, SlWrench } from "react-icons/sl";
import { FiPhone } from "react-icons/fi";
import { CiCalendarDate } from "react-icons/ci";
import { BiMap } from "react-icons/bi";
import DotsLoader from "../../components/atoms/DotsLoader";
import { Button } from "../../components";
import Select from "react-select";
import { get_industry_drd } from "../../features/IndustrySlice";
import { get_job_status } from "../../features/jobStatusSlice";
import RenderShapes from "./RenderShapes";
import { BsCircle } from "react-icons/bs";
import { TbLassoPolygon } from "react-icons/tb";
import { RxHand } from "react-icons/rx";
import { get_jobs_data } from "../../features/jobsSlice";
const CustomDrawingControl = ({
  drawingManager,
  isDraw,
  setIsDraw,
  onChangeAddress,
  onLoadPickup,
  drawShapes,
  calculatePointsInShape,
  onDeleteDrawing,
  activeShapeIndex,
  onRemoveBoundaries,
}) => {
  const handleDrawingMode = (drawingMode) => {
    drawingManager.setDrawingMode(drawingMode);
  };
  const stopDrawingMode = () => {
    drawingManager.setDrawingMode(null); // Set drawing mode to null to stop drawing
  };
  const autocompleteStyle = {
    boxSizing: "border-box",
    border: "1px solid transparent",
    width: "300px",
    height: "38px",
    padding: "0 12px",
    borderRadius: "3px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
    fontSize: "14px",
    outline: "none",
    textOverflow: "ellipses",
    // position: 'absolute',
    left: "8%",
    top: "10px",
    // marginLeft: '-120px',
  };
  return (
    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 flex  bg-black/40 w-full p-2 flex justify-between">
      <div className="flex flex-row space-x-2">
        <Autocomplete
          onPlaceChanged={onChangeAddress}
          onLoad={(autocomplete) => onLoadPickup(autocomplete)}
        >
          <input
            // className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoFocus
            style={autocompleteStyle}
          />
        </Autocomplete>

        {isDraw ? (
          <>
            <button
              className={`bg-[#fff] borde border-gray- !px-2 py-1 rounded hover:bg-client-50 hover:text-white ${
                drawingManager?.drawingMode === "circle"
                  ? "bg-client-50 text-white"
                  : ""
              }`}
              onClick={() => {
                handleDrawingMode(
                  window.google.maps.drawing.OverlayType.CIRCLE
                );
              }}
            >
              <BsCircle size={25} />
            </button>
            <button
              className={`bg-[#fff] borde border-gray- !px-2 py-1 rounded hover:bg-client-50 hover:text-white ${
                drawingManager?.drawingMode ===
                window.google.maps.drawing.OverlayType.POLYGON
                  ? "bg-client-50 text-white"
                  : ""
              }`}
              onClick={() => {
                handleDrawingMode(
                  window.google.maps.drawing.OverlayType.POLYGON
                );
              }}
            >
              <TbLassoPolygon size={25} />
            </button>
            <button
              className={`bg-[#fff] borde border-gray- !px-2 py-1 rounded hover:bg-client-50 hover:text-white ${
                !drawingManager?.drawingMode ? "bg-client-50 text-white" : ""
              }`}
              onClick={stopDrawingMode}
            >
              <RxHand size={22} />
            </button>
          </>
        ) : null}
        {activeShapeIndex?.current !== null &&
        activeShapeIndex?.current !== undefined ? (
          <button
            className={`bg-[#fff] borde border-gray- !px-2 py-1 rounded hover:bg-client-50 hover:text-white`}
            onClick={onDeleteDrawing}
          >
            <FaTrashAlt size={22} title="Remove Boundary"/>
          </button>
        ) : null}
      </div>
      <div className="top-0 flex space-x-2">
        {isDraw ? (
          <>
            <button
              onClick={() => setIsDraw(false)}
              className="text-white borde border-gray-400 px-3 py-1 rounde hover:bg-client-50 z-10"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                calculatePointsInShape(drawShapes);
                setIsDraw(false);
              }}
              className="text-white borde border-gray-400 px-3 py-1 rounde hover:bg-client-50"
            >
              Apply
            </button>
          </>
        ) : (
          <>
            {drawShapes?.length > 0 ? (
              <button
                onClick={onRemoveBoundaries}
                className="bg-white border border-gray-400 px-3 py-1 rounded hover:bg-client-50"
              >
                Remove Boundaries
              </button>
            ) : (
              <button
                onClick={() => setIsDraw(true)}
                className="bg-white border border-gray-400 px-3 py-1 rounded hover:bg-client-50"
              >
                Draw
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};
// Calculate the distance between two points using the Haversine formula
const calculateDistance = (lat1, lng1, lat2, lng2) => {
  const degToRad = (deg) => deg * (Math.PI / 180);
  const R = 6371; // Earth's radius in kilometers

  const dLat = degToRad(lat2 - lat1);
  const dLng = degToRad(lng2 - lng1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degToRad(lat1)) *
      Math.cos(degToRad(lat2)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
};
// Function to check if a point is within a circle
const isPointInCircle = (point, center, radius) => {
  const distance = calculateDistance(
    +point?.pickup_latitude,
    +point?.pickup_longitude,
    center.lat,
    center.lng
  );
  return distance <= radius;
};

// Function to check if a point is within a polygon
const isPointInPolygon = (point, paths) => {
  const pin = { lat: +point?.pickup_latitude, lng: +point?.pickup_longitude };
  const googlePolygon = new window.google.maps.Polygon({ paths });

  return window.google.maps.geometry.poly.containsLocation(pin, googlePolygon);
};
// Function to check if a point is within a rectangle
const isPointInRectangle = (point, bounds) => {
  const { pickup_latitude, pickup_longitude } = point;
  // const { north, south, east, west } = bounds;
  const swLatLng = bounds.getSouthWest();
  const neLatLng = bounds.getNorthEast();
  const south = swLatLng.lat();
  const west = swLatLng.lng();
  const north = neLatLng.lat();
  const east = neLatLng.lng();
  return (
    +pickup_latitude >= south &&
    +pickup_latitude <= north &&
    +pickup_longitude >= west &&
    +pickup_longitude <= east
  );
};
// Function to check if any lat long exists within the drawn radius/shape
const checkPointsWithinRadius = (pointsArray, shape) => {
  const { type, radius, paths, bounds } = shape;
  const radiusInKG = radius / 1000;
  const pointsWithinRadius = pointsArray.filter((point) => {
    if (type === "circle") {
      return isPointInCircle(point, paths, radiusInKG);
    } else if (type === "polygon") {
      return isPointInPolygon(point, paths);
    } else if (type === "rectangle") {
      return isPointInRectangle(point, bounds);
    } else {
      // Add support for other shape types if needed
      return false;
    }
  });
  return pointsWithinRadius;
};

function JobsOnMap() {
  const { isLoading, record } = useSelector((state) => state.jobs);
  const { industryDrd } = useSelector((state) => state.industry);
  const jobStatus = useSelector((state) => state.jobStatus);
  const [selectedShape, setSelectedShape] = useState(null);
  const [filterText, setFilterText] = React.useState("");
  const [filterbtn, setFilterBtn] = React.useState("Day");
  const [dataMode, setDataMode] = React.useState("All");
  const [isDraw, setIsDraw] = useState(false);
  const [pointsInShape, setJobsInShape] = useState([]);
  const [drawShapes, setDrawShapes] = useState([]);
  const drawingManagerRef = useRef(null);
  const [autocomplete, setAutocomplete] = useState();
  const [map, setMap] = useState(null);
  const [dateRange, setDateRange] = React.useState({
    to_date: null,
    from_date: null,
  });
  const [filters, setFilters] = React.useState({
    industry_id: "",
    job_status_id: "",
  });
  const onDay = () => {
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);
    dispatch(
      get_jobs_data({
        from_date: startDate,
        to_date: dayjs().toDate(),
        get_all_records: 1,
      })
    );
    setFilterBtn("Day");
  };

  const week = () => {
    const today = new Date();
    const lastWeekEnd = dayjs().subtract(7, "day").toDate();
    lastWeekEnd.setHours(23, 59, 59, 59);
    dispatch(
      get_jobs_data({
        from_date: lastWeekEnd,
        to_date: today,
        get_all_records: 1,
      })
    );
    setFilterBtn("Week");
  };
  const month = () => {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const endOfLastMonth = new Date(
      lastMonth.getFullYear(),
      lastMonth.getMonth() + 1
    );
    endOfLastMonth.setHours(23, 59, 59, 59);
    dispatch(
      get_jobs_data({
        to_date: today,
        from_date: endOfLastMonth,
        get_all_records: 1,
      })
    );
    setFilterBtn("Month");
  };

  const dispatch = useDispatch();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_API,
    libraries: ["places", "drawing", "geometry"],
  });
  const [zoom, setZoom] = useState(4);

  useEffect(() => {
    dispatch(get_industry_drd());
    dispatch(get_job_status({ page: 1, size: 1000, active: 1 }));
    onDay();
    // eslint-disable-next-line
  }, []);

  const [activeMarker, setActiveMarker] = useState(null);
  const [activeJob, setActiveJob] = useState(null);
  const [onMap, setOnMap] = useState(true);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };
  let records;
  switch (dataMode) {
    case "Jobs":
      records = record?.records?.filter(
        ({ job_created }) => job_created === true
      );
      break;
    case "Leads":
      records = record?.records?.filter(
        ({ job_created }) => job_created === false
      );
      break;
    case "All":
      records = record?.records;
      break;
    default:
      records = record?.records;
      break;
  }
  const [coords, setCoords] = useState(
    record?.records?.length > 0
      ? {
          lat: +records[0]?.pickup_latitude,
          lng: +records[0]?.pickup_longitude,
        }
      : {
          lat: 39.5803919,
          lng: -105.1002922,
        }
  );
  useEffect(() => {
    setCoords(
      record?.records?.length > 0
        ? {
            lat: +records[0]?.pickup_latitude,
            lng: +records[0]?.pickup_longitude,
          }
        : {
            lat: 35.699599,
            lng: -116.9426161,
          }
    );
    // eslint-disable-next-line
  }, [record?.records?.length]);
  const lead = records?.filter(
    (record) =>
      record?.job_category_id?.name
        ?.toLowerCase()
        .includes(filterText.toLowerCase()) ||
      record?.complete_pickup_address
        ?.toLowerCase()
        .includes(filterText.toLowerCase()) ||
      record?.phone?.toLowerCase().includes(filterText.toLowerCase())
  );
  const filterButtons = [
    {
      id: 1,
      title: "Day",
      onClick: onDay,
    },
    {
      id: 2,
      title: "Week",
      onClick: week,
    },
    {
      id: 3,
      title: "Month",
      onClick: month,
    },
  ];
  const shapeRefs = useRef([]);
  const mapRef = useRef();
  const activeShapeIndex = useRef();
  const optionsRef = React.useRef();
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setActiveMarker(null);
    }
  };

  const fitConvexHullToBounds = () => {
    if (map && Array.isArray(record?.records)) {
      const bounds = new window.google.maps.LatLngBounds();

      record?.records?.forEach((data) => {
        if (data.pickup_latitude && data.pickup_longitude) {
          const pickupCoord = new window.google.maps.LatLng(
            +data.pickup_latitude,
            +data.pickup_longitude
          );

          bounds.extend(pickupCoord);
        }
      });
      if (!bounds.isEmpty()) {
        map.fitBounds(bounds);
      }
    }
  };
  // useEffect(() => {
  //   if (latLngArray.length > 0 && mapContainer.current) {
  //     const bounds = new window.google.maps.LatLngBounds();

  //     latLngArray.forEach((latLng) => {
  //       bounds.extend(new window.google.maps.LatLng(latLng[0], latLng[1]));
  //     });

  //     mapContainer.current.fitBounds(bounds);
  //   }
  // }, [latLngArray]);
  useEffect(() => {
    if (record?.records?.length > 0) {
      fitConvexHullToBounds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record?.records?.length]);
  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setActiveMarker(null);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);

  const handleDrawingComplete = (shapes) => {
    drawingManagerRef.current.setDrawingMode(null);
    const shapeProps = shapes?.overlay;
    if (shapeProps) {
      const shapeType = shapes.type;
      const shapeData = {};

      if (shapeType === "circle") {
        shapeData.center = shapeProps.center;
        shapeData.radius = shapeProps.radius;
        shapeData.paths = {
          lat: shapeProps?.center?.lat(),
          lng: shapeProps?.center?.lng(),
        };
      } else if (shapeType === "rectangle") {
        shapeData.bounds = shapeProps.bounds;
      } else if (shapeType === "polygon") {
        const paths = shapeProps.getPath();
        if (paths) {
          shapeData.paths = paths.getArray().map((latLng) => ({
            lat: latLng.lat(),
            lng: latLng.lng(),
          }));
        } else {
          console.error("Polygon paths are undefined.");
        }
      }
      shapes.overlay?.setMap(null);
      setDrawShapes([...drawShapes, { type: shapeType, ...shapeData }]);
    }
  };
  // Function to calculate points within the shape
  const calculatePointsInShape = (data) => {
    let record = [];
    for (const shape in data) {
      if (data[shape]?.type && data[shape]) {
        const pointsWithinShape = checkPointsWithinRadius(records, data[shape]);
        record = [...record, ...pointsWithinShape];
      }
    }
    setJobsInShape(record);
  };
  const onLoadPickup = (autocomplete) => {
    setAutocomplete(autocomplete);
  };
  const onChangeAddress = () => {
    const { geometry } = autocomplete.getPlace();
    const map = mapRef.current;

    if (!geometry || !map) {
      return;
    }
    // Calculate the distance from the center to a corner of the viewport
    const center = geometry.location;
    const bounds = geometry.viewport;
    const corner = new window.google.maps.LatLng(
      bounds.getNorthEast().lat(),
      bounds.getSouthWest().lng()
    );
    const paths = {
      lat: center?.lat(),
      lng: center?.lng(),
    };
    const approximateRadius =
      window.google.maps.geometry.spherical.computeDistanceBetween(
        center,
        corner
      );
    // setDrawShapes([...drawShapes,
    //   {
    //     type: "circle",
    //     center: center,
    //     radius: approximateRadius,
    //     paths: paths,
    //   },
    // ]);
    setDrawShapes((prevDrawShapes) => [
      ...prevDrawShapes,
      {
        type: "circle",
        center: center,
        radius: approximateRadius,
        paths: paths,
      },
    ]);
    calculatePointsInShape([
      ...drawShapes,

      {
        type: "circle",
        center: center,
        radius: approximateRadius,
        paths: paths,
      },
    ]);
    setZoom(8);
    // const bounds = new window.google.maps.LatLngBounds();
    if (geometry.viewport) {
      bounds.union(geometry.viewport);
    } else {
      bounds.extend(geometry.location);
    }
    mapRef.current.fitBounds(bounds);
    // You can now use the approximateRadius value as needed
  };
  const data = isDraw ? [] : drawShapes.length > 0 ? pointsInShape : records;
  const onLoadShape = (polygon, index) => {
    shapeRefs.current[index] = polygon;
  };

  const onClickShape = (index) => {
    activeShapeIndex.current = index;
    setSelectedShape(index);
  };
  const onRemoveBoundaries = (index) => {
    shapeRefs.current = [];
    setDrawShapes([]);
  };
  const onDeleteDrawing = () => {
    const filtered = drawShapes.filter(
      (polygon, index) => index !== activeShapeIndex.current
    );
    // Remove the corresponding reference from shapeRefs
    delete shapeRefs.current[activeShapeIndex.current];
    setDrawShapes(filtered);
    calculatePointsInShape(filtered);
    setSelectedShape(null);
    activeShapeIndex.current = null;
  };
  const onEditShape = (index) => {
    const polygonRef = shapeRefs.current[index];
    if (polygonRef) {
      if (drawShapes[index]?.type === "circle") {
        const allPolygons = [...drawShapes];
        allPolygons[index].center = polygonRef.center;
        allPolygons[index].radius = polygonRef.radius;
        allPolygons[index].paths = {
          lat: polygonRef?.center?.lat(),
          lng: polygonRef?.center?.lng(),
        };
        setDrawShapes(allPolygons);
        calculatePointsInShape(allPolygons);
      } else if (drawShapes[index]?.type === "rectangle") {
        const allPolygons = [...drawShapes];
        allPolygons[index].bounds = polygonRef.bounds;
        setDrawShapes(allPolygons);
        calculatePointsInShape(allPolygons);
      } else if (drawShapes[index]?.type === "polygon") {
        const paths = polygonRef.getPath();
        if (paths) {
          const coordinates = paths
            .getArray()
            .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

          const allPolygons = [...drawShapes];
          allPolygons[index].paths = coordinates;
          setDrawShapes(allPolygons);
          calculatePointsInShape(allPolygons);
        } else {
          console.error("Polygon paths are undefined.");
        }
      }
    }
  };
  const onLoadMap = (map) => {
    mapRef.current = map;
    setMap(map);
  };

  return (
    <>
      <div className="row bg-white">
        {isLoading && <DotsLoader />}
        <div className={["col-12 bg-[#f7f7f7] p-2.5 border-b"].join(" ")}>
          <div className="flex flex-wrap">
            <div className="col-3">
              <div className="px-2">
                <span className="text-[13px] leading-8">
                  {filterButtons?.map((menu) => {
                    return (
                      <span
                        className={[
                          styles.button,
                          filterbtn === menu.title
                            ? "map-filter-active"
                            : "map-filter",
                        ].join(" ")}
                        data-view="day"
                        onClick={menu.onClick}
                        key={menu.id}
                      >
                        {menu.title}
                      </span>
                    );
                  })}
                </span>
              </div>
            </div>
            <div className="flex flex-row col-8 items-center">
              <Select
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    industry_id: e?._id,
                  });
                }}
                menuPosition="fixed"
                name={"industry_id"}
                options={industryDrd}
                getOptionLabel={(options) => options.name}
                getOptionValue={(options) => options._id}
                placeholder="Select Industry"
                className="w-full"
                value={
                  filters.industry_id
                    ? {
                        name: industryDrd?.find(
                          ({ _id }) => _id === filters.industry_id
                        )?.name,
                        _id: filters.industry_id,
                      }
                    : null
                }
              />
              <Select
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    job_status_id: e?._id,
                  });
                }}
                menuPosition="fixed"
                name={"job_status_id"}
                options={jobStatus?.record?.records}
                getOptionLabel={(options) => options.name}
                getOptionValue={(options) => options._id}
                placeholder="Select Status"
                className="w-full mx-2"
                value={
                  filters.job_status_id
                    ? {
                        name: jobStatus?.record?.records?.find(
                          ({ _id }) => _id === filters.job_status_id
                        )?.name,
                        _id: filters.job_status_id,
                      }
                    : null
                }
              />
              <DateRangeComp
                inputClassName="bg-[#e9ecef] text-sm rounded py-1 px-2 border border-[#ced4da]"
                pickerClassName="-translate-x-[60%]"
                dateRangePicker
                onChange={(e) => {
                  const start = new Date(e.selection.startDate.toDateString());
                  start.setHours(0);
                  start.setMinutes(0);
                  start.setSeconds(0);
                  const end = new Date(e.selection.endDate.toDateString());
                  end.setHours(23);
                  end.setMinutes(59);
                  end.setSeconds(59);
                  setDateRange({ from_date: start, to_date: end });
                }}
              />
              <Button
                text="Search"
                variant="btn_submit"
                className="!ml-2"
                onClick={() =>
                  dispatch(
                    get_jobs_data({
                      ...dateRange,
                      ...filters,
                      get_all_records: 1,
                    })
                  )
                }
              />
              <Button
                text="Clear"
                variant="btn_cancel"
                className="!ml-2"
                onClick={() => {
                  week();
                  setFilters({
                    industry_id: "",
                    job_status_id: "",
                  });
                  dispatch(
                    get_jobs_data({
                      ...dateRange,
                      ...filters,
                      get_all_records: 1,
                    })
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="md:grid grid-cols-12 bg-white gap-2">
        <div className="col-span-3 p-2 mb-4 ">
          <div className="flex flex-row justify-between">
            <span className="text-normal font-semibold">
              Found {lead?.length ? lead?.length : 0} records
            </span>
            <div className="flex flex-row items-center border-y border-1 p-[6px] !pr-2">
              <ul className="flex flex-row items-center w-ful pl-0">
                <li
                  className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                    dataMode === "All" ? "map-filter-active" : ""
                  }`}
                  onClick={() => setDataMode("All")}
                >
                  All
                </li>
                <li
                  className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                    dataMode === "Leads" ? "map-filter-active" : ""
                  }`}
                  onClick={() => setDataMode("Leads")}
                >
                  Leads
                </li>
                <li
                  className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                    dataMode === "Jobs" ? "map-filter-active" : ""
                  }`}
                  onClick={() => setDataMode("Jobs")}
                >
                  Jobs
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 pl-0 pr-0 mb-2">
            <input
              className={
                "shadow appearance-none border rounded w-full py-2 pr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-sm border h-[31px] w-full !pl-11"
              }
              style={{ minHeight: 31 }}
              placeholder="Search"
              value={filterText}
              onChange={(e) => {
                setFilterText(e.target.value);
              }}
            />
            <div className={styles.search_icon}>
              <FaSearch />
            </div>
          </div>
          <div className="cursor-pointer">
            <input
              type="checkbox"
              id="showpass"
              name="showpass"
              checked={onMap}
              onChange={() => {
                setOnMap(!onMap);
                setActiveJob(null);
                setZoom(4);
              }}
              className="cursor-pointer"
            />
            <label htmlFor="showpass" className="ml-2 cursor-pointer">
              Show all on map
            </label>
          </div>
          <div className="h-[67vh] overflow-y-auto">
            {lead?.map((job, index) => {
              return (
                <div
                  key={index}
                  className={styles.card}
                  onClick={() => {
                    setActiveJob(job?.lead_num);
                    setCoords({
                      lat: +job?.pickup_latitude,
                      lng: +job?.pickup_longitude,
                    });
                    setZoom(14);
                  }}
                >
                  <h5>
                    {job?.industry_id?.name} -{" "}
                    {job?.job_created ? "Job #" : "Lead #"}{" "}
                    {job?.job_created ? job?.job_number : job?.lead_num}
                  </h5>
                  <p className="mid-margin-bottom">
                    {job.complete_pickup_address}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="col-span-9">
          {isLoaded && (
            <GoogleMap
              center={coords}
              zoom={zoom ? zoom : 5}
              mapContainerStyle={{ width: "100%", height: "76vh" }}
              options={{
                zoomControl: true,
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
              }}
              onClick={() => setActiveMarker(null)}
              onLoad={onLoadMap}
            >
              <CustomDrawingControl
                drawingManager={drawingManagerRef.current}
                onLoadPickup={onLoadPickup}
                onChangeAddress={onChangeAddress}
                setIsDraw={setIsDraw}
                isDraw={isDraw}
                drawShapes={drawShapes}
                records={records}
                calculatePointsInShape={calculatePointsInShape}
                onDeleteDrawing={onDeleteDrawing}
                selectedShape={selectedShape}
                activeShapeIndex={activeShapeIndex}
                onRemoveBoundaries={onRemoveBoundaries}
              />
              <>
                <DrawingManager
                  onOverlayComplete={handleDrawingComplete}
                  onLoad={(dm) => {
                    drawingManagerRef.current = dm;
                  }}
                  options={{
                    fillOpacity: 0.3,
                    fillColor: "#8aaae5",
                    strokeColor: "#8aaae5",
                    strokeWeight: 2,
                    editable: true,
                  }}
                />
              </>
              <RenderShapes
                activeShapeIndex={activeShapeIndex}
                shapes={drawShapes}
                onLoadShape={onLoadShape}
                onClickShape={onClickShape}
                onEditShape={onEditShape}
              />
              {data?.map((job, index) => {
                if (!onMap && job?.lead_num !== activeJob) {
                  return null; // don't render markers for other jobs if activeJob is set
                }
                return (
                  <>
                    {job?.pickup_latitude && job?.pickup_longitude && (
                      <Marker
                        position={{
                          lat: +job?.pickup_latitude,
                          lng: +job?.pickup_longitude,
                        }}
                        onClick={() => handleActiveMarker(job?.lead_num)}
                        icon={{
                          url: marker_pin,
                          // eslint-disable-next-line
                          scaledSize: new google.maps.Size(24, 37),
                        }}
                        key={index}
                      >
                        {activeMarker === job.lead_num ? (
                          <InfoWindow
                            onCloseClick={() => setActiveMarker(null)}
                          >
                            <div
                              className={styles.info_window}
                              ref={optionsRef}
                            >
                              <ul className="pl-2 ">
                                <li>
                                  <FaBusinessTime className="mr-2" size={20} />
                                  Job #{" "}
                                  {job?.job_number
                                    ? job?.job_number
                                    : job?.lead_num}
                                </li>
                                <li>
                                  <SlUser className="mr-2" size={20} />
                                  {job?.name}
                                </li>
                                <li>
                                  <FiPhone className="mr-2" size={20} />
                                  {job?.phone}
                                </li>
                                <li>
                                  <CiCalendarDate className="mr-2" size={20} />
                                  {job?.scheduled_on
                                    ? dayjs(job?.scheduled_on).format(
                                        "ddd, MMM D, YYYY h:mm A"
                                      )
                                    : "No scheduled date selected"}
                                </li>
                                <li>
                                  <BiMap className="mr-2" size={20} />
                                  {job?.complete_pickup_address}
                                </li>
                                <li>
                                  <SlWrench className="mr-2" size={20} />
                                  {job?.job_category_id?.name}
                                </li>
                              </ul>
                            </div>
                          </InfoWindow>
                        ) : null}
                      </Marker>
                    )}
                  </>
                );
              })}
            </GoogleMap>
          )}
        </div>
      </div>
    </>
  );
}

export default JobsOnMap;
