import React, { useState, useEffect } from "react";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import CustomSelect from "../../Components/Selectors";
import { address_icn } from "../../../../images";
import { BiRefresh } from "react-icons/bi";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
function LocationForm() {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_API,
    libraries: ["places", "drawing", "geometry"],
  });
  const [coords, setCoords] = useState({
    lat: 10.99835602,
    lng: 77.01502627,
    accuracy: null,
  });
  const defaultProps = {
    zoom: 15,
  };

  let initialValues = {
    complete_pickup_address: "",
    physical_address: "",
    physical_address2: "",
    zip: "",
    city: "",
    state: "",
    latitude: null,
    longitude: null,
    complete_drop_address: "",
    home_address: "",
    owner_address2: "",
    owner_zip: "",
    owner_city: "",
    owner_state: "",
    owner_latitude: "",
    owner_longitude: "",
    distance: "",
  };

  const formik = useFormik({
    initialValues,

    validateOnBlur: true,

    validationSchema: Yup.object().shape({
      complete_pickup_address: Yup.string().required("Location is required"),
      physical_address: Yup.string(),
      physical_address2: Yup.string(),
      zip: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      latitude: Yup.number(),
      longitude: Yup.number(),
    }),
    onSubmit: async (values) => {
      console.log("values", values);
    },
  });

  const extractAddress = (place) => {
    if (!Array.isArray(place?.address_components)) {
      return null;
    }

    place.address_components.forEach((component) => {
      const types = component.types;
      const value = component;

      if (types.includes("route")) {
        formik.setFieldValue("physical_address", value?.long_name);
      }

      if (types.includes("neighborhood")) {
        formik.setFieldValue("physical_address2", value?.long_name);
      }

      if (types.includes("postal_code")) {
        formik.setFieldValue("zip", value?.long_name);
      }

      if (types.includes("locality")) {
        formik.setFieldValue("city", value?.long_name);
      }
      if (types.includes("administrative_area_level_1")) {
        formik.setFieldValue("state", value?.short_name);
      }

      if (types.includes("country")) {
        formik.setFieldValue("country", value?.long_name);
      }
    });
  };
  const getLocation = () => {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "granted") {
        navigator.geolocation.getCurrentPosition((position) => {
          formik.setFieldValue("latitude", position?.coords?.latitude);
          formik.setFieldValue("longitude", position?.coords?.longitude);
          setCoords({
            lat: position?.coords.latitude,
            lng: position?.coords.longitude,
            accuracy: position?.coords?.accuracy,
          });
          fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position?.coords?.latitude},${position?.coords?.longitude}&sensor=true&key=AIzaSyDBI6nnS1TLlVUlhevLc7g891wiMioZNiU`
          )
            .then((res) => res.json())
            .then((json) => {
              extractAddress(json?.results[0]);
              formik.setFieldValue(
                "complete_pickup_address",
                json.results[0].formatted_address
              );
            });
        });
      } else return toast.error("Unable to send Location.");
    });
  };
  useEffect(() => {
    getLocation();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="bg-[#242f57] text-white py-3.7">
        <h2 className="text-lg text-center font-semibold font-pop">
          24/7 Emergency Services
        </h2>
      </div>

      <div className="container-fluid mt-4">
        <div className="bg-white p-3.7 rounded-xl shadow-[0_4px_20px_rgba(59,_89,_153,_0.15)] mb-5">
          <h3 className="text-lg border-b-2 border-neutral-100 pb-2 font-pop font-medium">
            Location
          </h3>
          <div className="flex flex-wrap -mr-[15px] -ml-[15px] w-full justify-center flex mr-0 ml-0">
            <div className="col-md-6">
              {isLoaded && (
                <GoogleMap
                  center={coords}
                  zoom={defaultProps.zoom}
                  mapContainerStyle={{
                    width: "100%",
                    height: "65vh",
                    borderRadius: 10,
                    border: "2px #f5f5f5 solid",
                  }}
                  options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                  }}
                >
                  <Marker position={coords} />
                </GoogleMap>
              )}
            </div>
            <div className="col-md-6">
              <div className="w-full justify-center flex mr-0 ml-0 items-center">
                <CustomSelect
                  name="location"
                  label={`Accurate to ${coords?.accuracy?.toFixed(2)} meters`}
                  isDisable
                  isInput
                  icon={address_icn}
                  head={"Location"}
                  value={formik?.values?.complete_pickup_address}
                  formik={formik}
                />
              </div>
            </div>
            <div className="w-full">
              <button
                className="py-1.5 px-3 align-middle next ml-auto flex self-center justify-self-center text-white float-right bg-client-100 border-client-100"
                onClick={formik.handleSubmit}
                disabled={coords?.accuracy <= 20 ? false : true}
              >
                Send Your Location
              </button>
              <button
                className="py-1.5 px-3 align-middle next ml-auto mr-2 flex self-center justify-self-center text-white float-right bg-client-100 border-client-100"
                onClick={getLocation}
              >
                <BiRefresh size={24} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationForm;
