import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  create_gsc_email_accounts,
  get_gsc_email_accounts,
  update_gsc_email_accounts,
} from "../../features/googleEmailSlice";
import errorMessage from "../../util/errorMessage";
import { filterPayload } from "../../util/filterPayload";
const GoogleConsoleForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.googleEmails);
  const [errors, setErrors] = React.useState([]);

  const initialValues = {
    server_ip: "",
    email: "",
    email_password: "",
    server_user: "",
    server_password: "",
    proxy_user: "",
    proxy_password: "",
    mobile_num: "",
    mobile_carrier: "",
    email_user_name: "",
    DOB: new Date(),
    domain_limit: "",
    client_id: "",
    client_secret: "",
    refresh_token: "",
    app_url: "",
    ...(editingRecord && {
      id: editingRecord?._id,
      server_ip: editingRecord?.server_ip,
      email: editingRecord?.email,
      email_password: editingRecord?.email_password,
      server_user: editingRecord?.server_user,
      server_password: editingRecord?.server_password,
      proxy_user: editingRecord?.proxy_user,
      proxy_password: editingRecord?.proxy_password,
      mobile_num: editingRecord?.mobile_num,
      mobile_carrier: editingRecord?.mobile_carrier,
      email_user_name: editingRecord?.email_user_name,
      DOB: editingRecord?.DOB,
      domain_limit: editingRecord?.domain_limit,
      client_id: editingRecord?.client_id,
      client_secret: editingRecord?.client_secret,
      refresh_token: editingRecord?.refresh_token,
      app_url: editingRecord?.app_url,
    }),
  };
  function validateForm(data) {
    const errors = [];
    if (!data.server_ip) {
      errors.push({
        server_ip: "server_ip is not allowed to be empty",
      });
    }
    return errors;
  }
  const handleSubmit = async (values) => {
    const action = editingRecord
      ? update_gsc_email_accounts
      : create_gsc_email_accounts;
    const message = editingRecord ? "updated" : "added";
    const errors = validateForm(values);
    if (errors?.length) {
      return errorMessage({
        payload: errors,
        setErrors: setErrors,
        action: "Server",
        msg: message,
      });
    }
    let payload=filterPayload(values)
    delete payload.app_url;
    // if (editingRecord) {
    //   // delete values.client_secret;
    //   // delete values.email;
    //   // delete values.gsc_server_id;
    //   // delete values.password;
    // }
    try {
      console.log("🧑‍💻 ~ file: GoogleConsoleForm.jsx:93 ~ handleSubmit ~ payload:", payload)
      const res = await dispatch(action(payload));
      if (res.payload?.status === 200 || res.payload?.status === 201) {
        toast.success(`Server ${message} successfully`);
        dispatch(get_gsc_email_accounts());
        onCancelForm();
      } else {
        if (Array.isArray(res.payload)) {
          setErrors(res.payload);
        } else {
          toast.error(
            res.payload
              ? res.payload
              : `Server couldn't be ${message} successfully`
          );
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload)) {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload
            ? error.payload
            : `Server couldn't be ${message} successfully`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}

        <form className="mt-4 grid grid-cols-3 gap-3">
          <div className="col-span-3">
            <h5 className="text-primary-100 border-b border-primary-100 pb-2">
              Server Info
            </h5>
          </div>
          <FormInput
            errors={errors}
            name="server_ip"
            label="Server IP"
            formik={formik}
            required
          />
          <FormInput
            errors={errors}
            name="app_url"
            label="App URL"
            formik={formik}
            disabled={true}
          />
          <FormInput
            errors={errors}
            name="server_user"
            label="Server User"
            formik={formik}
          />
          <FormInput
            errors={errors}
            name="server_password"
            label="Server Password"
            formik={formik}
          />
          <FormInput
            errors={errors}
            name="proxy_user"
            label="Proxy User"
            formik={formik}
          />

          <FormInput
            errors={errors}
            name="proxy_password"
            label="Proxy Password"
            formik={formik}
          />
          <div className="col-span-3">
            <h5 className="text-primary-100 border-b border-primary-100 pb-2">
              Email Info
            </h5>
          </div>
          <FormInput
            errors={errors}
            name="email"
            label="Email"
            formik={formik}
          />
          <FormInput
            errors={errors}
            name="email_password"
            label="Email Password"
            formik={formik}
          />

          <FormInput
            errors={errors}
            name="mobile_num"
            label="Mobile Number"
            formik={formik}
          />
          <FormInput
            errors={errors}
            name="mobile_carrier"
            label="Mobile Carrier"
            formik={formik}
          />
          <FormInput
            errors={errors}
            name="email_user_name"
            label="Email User Name"
            formik={formik}
          />
          <FormInput
            errors={errors}
            name="DOB"
            label="DOB"
            formik={formik}
            type={"date"}
          />
          <div className="col-span-3">
            <h5 className="text-primary-100 border-b border-primary-100 pb-2">
              Additional Info
            </h5>
          </div>
          <FormInput
            errors={errors}
            name="domain_limit"
            label="Domain Limit"
            formik={formik}
          />
          <FormInput
            errors={errors}
            name="client_id"
            label="Client ID"
            formik={formik}
          />
          <FormInput
            errors={errors}
            name="client_secret"
            label="Client Secret"
            formik={formik}
          />
          <FormInput
            errors={errors}
            name="refresh_token"
            label="Refresh Token"
            formik={formik}
          />
        </form>
      </Modal>
    </>
  );
};

export default GoogleConsoleForm;
