import * as Yup from "yup";

export const ClientFormSchema = Yup.object().shape({
  lead_token: Yup.string().nullable(),
  lead_num: Yup.string(),
  name: Yup.string().nullable(),
  phone: Yup.string().nullable(),
  job_category_id: Yup.string().required("Service is required"),
  service_cost: Yup.number().nullable(),
  scheduled_on: Yup.string().nullable(),

  car_year: Yup.number().nullable(),
  car_make: Yup.string().nullable(),
  car_model: Yup.string().nullable(),
  car_service: Yup.object().nullable(),
  is_neutral: Yup.boolean(),

  email: Yup.string().nullable(),
  alternate_phone: Yup.string().nullable(),

  drop_latitude: Yup.number(),
  drop_longitude: Yup.number(),
  drop_country: Yup.string().nullable(),
  drop_city: Yup.string().nullable(),
  drop_state: Yup.string().nullable(),
  drop_zip: Yup.string().nullable(),
  complete_drop_address: Yup.string().nullable(),

  pickup_latitude: Yup.number().nullable(),
  pickup_longitude: Yup.number().nullable(),
  pickup_country: Yup.string().nullable(),
  pickup_city: Yup.string().nullable(),
  pickup_state: Yup.string().nullable(),
  pickup_zip: Yup.string().nullable(),
  complete_pickup_address: Yup.string().nullable(),
  distance: Yup.number().nullable(),
  special_instruction: Yup.string().nullable(),

  decline_reasons: Yup.string()
    
    .nullable(),
});
