import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { DotsLoader } from "../../../components";
const AddUpdateRestartForm = ({ editingRecord, onClose, setErrorResponse }) => {
  const [restartType, setRestartType] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    const isConfirmed = window.confirm("Are you sure you want to restart the server?");
    if (!isConfirmed) return;
    setLoading(true);
    try {
      const res = await api.post(
        `/api/call_centers/restart/${editingRecord._id}`,
        {
          restart_type: restartType,
        }
      );
      if (res.status === 200 || res.status === 201) {
        setErrorResponse(res?.data);
        //toast.success(res?.data?.message || "Call Center restarted");
        setLoading(false);
        onClose();
      } else {
        toast.error(res?.data?.message || "Call Center couldn't restart!");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
      setLoading(false);
    }
  };
  return (
    <div className="fixed inset-0 flex items-center ml-[220px] justify-center z-50 bg-black/60">
      {loading ? <DotsLoader /> : null}
      <div className="bg-white p-8 rounded-lg shadow-lg relative w-[400px]">
        <h2 className="text-lg font-semibold mb-4">Restart Call Center</h2>
        <label className="block">Select :</label>
        <Dropdown
          placeholder="Select Restart Type"
          options={[
            { _id: "Normal", label: "Normal ViciDial Restart" },
            { _id: "Force", label: "Force ViciDial Restart" },
            { _id: "Reboot", label: "Reboot Server" },
          ]}
          optionValue="_id"
          value={restartType}
          onChange={(e) => setRestartType(e.value)}
          className="w-full"
        />

        <div className="flex justify-end !mt-4">
          <button
            className="px-4 py-2 mr-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddUpdateRestartForm;
