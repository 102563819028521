import React from "react";
import {
  MdOutlineRadioButtonChecked,
  MdOutlineRadioButtonUnchecked,
} from "react-icons/md";
import { useSelector } from "react-redux";
const TypeOfServiceSection = ({ formik }) => {
  const { record } = useSelector((state) => state.jobCategory);
  const serviceTypes = [
    { id: "6367d170db946a8a83105c6d", name: "Towing Service" },
    { id: 2, name: "Non-vehicle Tow" },
    { id: "6367d170db946a8a83105c6f", name: "Tire Change" },
    { id: "6367d170db946a8a83105c74", name: "Battery Replacement" },
    { id: 14, name: "Auto-Transport" },
    { id: "6367d170db946a8a83105c73", name: "Tire Replacement" },
    { id: 16, name: "Two-way Tow" },
    { id: "6367d170db946a8a83105c71", name: "Jump Start" },
    { id: 18, name: "Tire Inflation" },
    { id: "6367d170db946a8a83105c70", name: "Lockout" },
    { id: 10, name: "Storage" },
    { id: 21, name: "Load/Unload" },
    { id: 31, name: "Winchout" },
    { id: 41, name: "Locksmith" },
    { id: "6367d170db946a8a83105c6e", name: "Gas Delivery" },
    { id: 61, name: "Mobile Mechanic" },
    { id: 51, name: "Special Request" },
  ];
  const isSelected = (id) => {
    if (formik.values.service_name === id) return true;
    return false;
  };
  return (
    <div className="bg-white border px-2 md:px-4 mt-3">
      <div className="pl-2 pt-3 !mb-2">
        <div className="font-semibold text-lg">Type Of Service:</div>
      </div>

      <div className="flex flex-col lg:flex-row gap-4">
        <div className="w-full bg-neutral-50">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-7 gap-2 mb-4 mt-2 font-semibold">
            {record?.records?.map(({ _id, name }) => {
              return (
                <button
                  key={_id}
                  className={`text-xs flex items-center border border-gray-300 p-1 hover:bg-client-100 hover:text-white transition-colors duration-200 h-[25px] cursor-pointer gap-x-1 ${
                    isSelected(name)
                      ? "bg-client-100 text-white"
                      : "bg-gray-200 text-gray-500"
                  }`}
                  onClick={() => {
                    formik.setFieldValue("job_category_id", _id);
                    formik.setFieldValue("service_name", name);
                  }}
                >
                  {isSelected(name) ? (
                    <MdOutlineRadioButtonChecked size={18} />
                  ) : (
                    <MdOutlineRadioButtonUnchecked size={18} />
                  )}
                  {name}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TypeOfServiceSection;
