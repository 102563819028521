import React from "react";
import VehicleInfoSection from "../Common/Sections/VehicleInfoSection";
const MobileMechanic = ({ formik, serviceName }) => {
  return (
    <VehicleInfoSection
      formik={formik}
      fieldsToShow={["car_year", "car_make", "car_model", "car_color"]}
      serviceName={serviceName}
    />
  );
};

export default MobileMechanic;
