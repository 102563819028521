import React, { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import PageHeader from "../../../components/molecules/PageHeader";
import { get_audio_files, get_call_menu } from "../../../features/callCentersSlice";
import userPermission from "../../../util/userPermission";
import AddCallMenuForm from "./AddCallMenuForm";
import api from "../../../services/api";
import { DotsLoader } from "../../../components";
import { toast } from "react-toastify";
function CallMenu() {
  const { isLoading, callMenu } = useSelector((state) => state.callCenters);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState(false);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const handleDelete = async (id) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (!c) return;
    setLoader(true);
    try {
      const res = await api.delete(
        `/api/cc/dids/options/call_menu/${id}`
      );
      if (res.status === 200) {
        toast.success(res?.data?.message || "Call Menu deleted successfully");
        dispatch(get_call_menu());
      } else {
        toast.error(res?.data?.message || "Call Menu couldn't be deleted");
      }
      setLoader(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Call Menu couldn't be deleted"
      );
      setLoader(false);
      console.log("🚀 ~ handleDelete ~ err:", err);
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter" },
    { headerName: "Menu ID", field: "menu_id", flex: 1 },
    { headerName: "Menu Name", field: "menu_name", flex: 1 },
    {
      field: "actions",
      renderCell: (params) => (
        <div>
            <FaEdit
              onClick={()=>navigate(`${params.row.menu_id}`, {
                state: params.row.records,
              })}
              className="my_navIcon"
              title="Update Call Center"
            />
            <FaTrash
              onClick={()=>handleDelete(params.row.menu_id)}
              className="my_navIcon"
              title="Update Call Center"
            />
        </div>
      ),
    },
  ];
  useEffect(() => {
    dispatch(get_call_menu());
    dispatch(get_audio_files())
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isEditing && (
        <AddCallMenuForm
          editingRecord={editingRecord}
          modalTitle="Add Call Menu"
          onCancelForm={cancelFormHandler}
        />
      )}
      {loader ? <DotsLoader /> : null}
      <PageHeader
        route="Call Center > Inbound Groups"
        heading="Call Menu Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Inbound")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={callMenu?.map((record, index) => {
            let counter = index + 1;
            const { menu_name, menu_id } = record;
            return {
              counter,
              records: { ...record, _id: menu_id },
              menu_name,
              menu_id,
            };
          })}
          pagination="No"
          totalItems={callMenu?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          paginationMode="client"
        />
      </div>
    </>
  );
}

export default CallMenu;
