import React, { useEffect } from "react";
import { Button } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { get_last_touch } from "../../features/reportsSlice";
import { useReactToPrint } from "react-to-print";
import ReportHead from "../Components/Header";
import Picker from "../Components/Picker";
import Select from "react-select";
import { get_users, get_users_drd } from "../../features/usersSlice";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { get_roles } from "../../features/rolesSlice";
import dayjs from "dayjs";

const LastTouchReport = () => {
  const dispatch = useDispatch();
  const { usersDrd } = useSelector((state) => state.users);
  const { record } = useSelector((state) => state.roles);
  const componentRef = React.useRef(null);
  const { isLoading, lastTouch } = useSelector((state) => state.reports);
  const [searchText, setSearchText] = React.useState("");
  const currentDate = new Date();
  const initialFilters = {
    from_date:
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),0,0,0,0
      ).toISOString().slice(0, 23) + "Z",
    to_date: new Date().toISOString().slice(0, 23) + "Z",
    job_number: "",
    role_id: "",
    user_id: "",
  };
  const [filters, setFilters] = React.useState(initialFilters);
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(get_roles());
    dispatch(get_users_drd());
    dispatch(get_last_touch(filters));
    // eslint-disable-next-line
  }, []);
  const columnDefs = [
    { headerName: "#", field: "counter" },
    { headerName: "Date", field: "createdAt", flex: 1 },
    { headerName: "User Name", field: "name", flex: 1 },
    { headerName: "User ID", field: "username", flex: 1 },
    {
      field: "Record Opened",
      renderCell: (params) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/jobs/update/${params.row.lead_id}`}
          className="text-blue-700 hover:no-underline"
        >
          {params.row.job_number}
        </a>
      ),
      width: 150,
    },
    // { headerName: "Record Opened", field: "job_number", flex: 1 },
    { headerName: "Customer Name", field: "customer", flex: 1 },
  ];
  const exportOption = [
    // {
    //   label: "Export PDF",
    //   value: "pdf",
    // },
    {
      label: "Export CSV",
      value: "csv",
    },
  ];

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Login Report",
  });
  useEffect(() => {
    dispatch(get_users());
    // eslint-disable-next-line
  }, []);
  const style = {
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#ffe7c7",
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused || state.hover ? "#a9a9a9" : null,
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_last_touch({
        ...filters,
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  // const lastTouchFilter = lastTouch?.records?.filter((e) => {
  //   return Object.keys(e)?.some(
  //     (key) =>
  //       e[key] &&
  //       // searchInput &&
  //       e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
  //   );
  // });
  const lastTouchFilter = lastTouch?.records?.filter((e) => {
    const { user_id, lead_id, createdAt } = e;
    const searchTextLowerCase = searchText?.toLowerCase();
  
    const containsSearchText = Object?.values(user_id?user_id:{})
      ?.concat(Object?.values(lead_id?lead_id:{}))
      ?.some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase()?.includes(searchTextLowerCase);
        }
        return false;
      });
  
    return containsSearchText || createdAt?.includes(searchText);
  });
  return (
    <>
      <div className="container-fluid">
        <ReportHead route="Reports > Last Touch Report" heading="Login IP" />

        <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
          <div className="md:col-span-9">
            <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
              <div className="md:col-span-3 !px-0.5 !mb-3">
                <input className="form-control" placeholder="Job ID" />
              </div>
              <div className="md:col-span-3 !px-0.5 !mb-3">
                <Select
                  styles={style}
                  placeholder="Select Role"
                  // isMulti
                  options={record}
                  getOptionLabel={(options) => options.name}
                  getOptionValue={(options) => options._id}
                  onChange={(e) => setFilters({ ...filters, role_id: e._id })}
                  className="w-full"
                  value={
                    filters.role_id
                      ? {
                          _id: filters.role_id,
                          name: record?.find(
                            ({ _id }) => _id === filters.role_id
                          )?.name,
                        }
                      : null
                  }
                />
              </div>
              <div className="md:col-span-3 !px-0.5 !mb-3">
                <Select
                  styles={style}
                  placeholder="Users"
                  // isMulti
                  options={usersDrd}
                  getOptionLabel={(options) => options?.first_name}
                  getOptionValue={(options) => options._id}
                  onChange={(e) => setFilters({ ...filters, user_id: e._id })}
                  className="w-full"
                  value={
                    filters.user_id
                      ? {
                          _id: filters.user_id,
                          first_name: usersDrd?.find(
                            ({ _id }) => _id === filters.user_id
                          )?.first_name,
                        }
                      : null
                  }
                />
              </div>
              <div className="md:col-span-1 !px-0.5 !mb-3">
                <Button
                  text="Search"
                  variant="btn_submit"
                  onClick={() => dispatch(get_last_touch(filters))}
                />
              </div>
              <div className="md:col-span-1 !px-0.5 !mb-3">
                <Button
                  text="Reset"
                  variant="btn_cancel"
                  onClick={() => {
                    setFilters(initialFilters);
                    dispatch(get_last_touch(initialFilters));
                  }}
                />
              </div>
            </div>
          </div>

          <div className="md:col-span-3 !px-0.5 !mb-3">
            <Picker
              filterData={filters}
              setFiltersData={setFilters}
            />
          </div>
        </div>

        <div className="bg-white my-3 border rounded">
          <MUIDataTable
            exportOption={exportOption}
            handlePrint={handlePrint}
            columnDefs={columnDefs}
            items={lastTouchFilter?.map((record, index) => {
              let counter = index + 1;
              let records = record;
              let record_id = record._id;
              let name =
                record?.user_id?.first_name +
                " " +
                (record?.user_id?.last_name ? record?.user_id?.last_name : "");
              let username = record?.user_id?.username;
              let job_number = record?.lead_id?.job_number;
              let customer = record?.lead_id?.name;
              let createdAt = dayjs(record?.createdAt).format(
                "ddd, MMM D, YYYY h:mm A"
              );
              let lead_id = record?.lead_id?._id;
              return {
                counter,
                records,
                record_id,
                createdAt,
                name,
                username,
                job_number,
                customer,
                lead_id,
              };
            })}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            totalItems={lastTouch.totalItems}
            searchText={searchText}
            setSearchText={setSearchText}
            paginationModel={paginationModel}
            isLoading={isLoading}
          />
        </div>
        <div className="d-none"></div>
      </div>
    </>
  );
};

export default LastTouchReport;
