import React, { useState, useEffect } from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  FormTextArea,
} from "../../components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import api from "../../services/api";
import * as Yup from "yup";
import { FaInfoCircle } from "react-icons/fa";
import CustomModal from "../../components/molecules/Modal/customModal";
import { IoCloseOutline } from "react-icons/io5";
const AddSeoBatches = ({ onCancelForm, modalTitle, selectedData }) => {
  const [errors, setErrors] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [batchesData, setBatchesData] = useState([]);
  const [editSeoBatchData, setEditSeoBatchData] = React.useState(null);
  const [newSelectedData, setNewSelectData] = React.useState(selectedData);
  const getBatchesList = async () => {
    setIsLoading(true);
    try {
      const res = await api.get("/api/seo_batches/drd");
      if (res.status === 200 || res.status === 201) {
        setBatchesData(res?.data?.records);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const validationSchema = Yup.object({
    batch_name: Yup.string().required("Batch Name is required"),
  });
  const formik = useFormik({
    initialValues: {
      batch_name: "",
      description: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const uniqueDomainIds = [
        ...new Set([
          ...(newSelectedData?.map((item) => item._id) || []),
          ...(editSeoBatchData?.domain_ids?.map((item) => item._id) || []),
        ]),
      ];
      if (!uniqueDomainIds?.length) {
        toast.error("Please select a domain.");
        return;
      }
      const payload = {
        batch_name: values?.batch_name,
        domain_ids: uniqueDomainIds,
        description: values?.description,
      };
      if (!editSeoBatchData) {
        try {
          const res = await api.post("/api/seo_batches", payload);
          if (res.status === 200 || res.status === 201) {
            toast.success("Batch created successfully.");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Batch",
              msg: "created",
            });
          }
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
          if (err?.response?.data?.error) {
            errorMessage({
              payload: err?.response?.data?.error,
              setErrors: setErrors,
              action: "Batch",
              msg: "created",
            });
          } else {
            toast.error(err?.response?.data);
            return;
          }
          console.log(err);
        }
      } else {
        try {
          const res = await api.put(
            `/api/seo_batches/${editSeoBatchData?._id}`,
            payload
          );
          if (res.status === 200 || res.status === 201) {
            toast.success("Batch updated successfully.");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Batch",
              msg: "updated",
            });
          }
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
          if (err?.response?.data?.error) {
            errorMessage({
              payload: err?.response?.data?.error,
              setErrors: setErrors,
              action: "Batch",
              msg: "updated",
            });
          } else {
            toast.error(err?.response?.data);
            return;
          }
          console.log(err);
        }
      }
    },
  });
  useEffect(() => {
    if (formik.values.batch_id) {
      const selectedBatch = batchesData?.find(
        (item) => item._id === formik.values.batch_id
      );
      setEditSeoBatchData(selectedBatch);
    } else {
      setEditSeoBatchData(null);
    }
  }, [formik.values.batch_id, batchesData]);

  useEffect(() => {
    getBatchesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editSeoBatchData) {
      formik.setValues({
        batch_name: editSeoBatchData.batch_name || "",
        description: editSeoBatchData.description || "",
        batch_id: editSeoBatchData._id || "",
      });
    } else {
      formik.setValues({
        batch_name: "",
        description: "",
        batch_id: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSeoBatchData]);

  const handleRemoveNewDomain = (index) => {
    const updatedData = newSelectedData.filter((_, i) => i !== index);
    setNewSelectData(updatedData);
  };

  const handleSelectedBatchDomain = (index) => {
    setEditSeoBatchData((prevData) => ({
      ...prevData,
      domain_ids: prevData.domain_ids.filter((_, i) => i !== index),
    }));
  };
  return (
    <>
      <CustomModal
        handleModal={onCancelForm}
        cancelButtonRef={onCancelForm}
        className="max-w-5xl"
        modalType={true}
        title={modalTitle}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
      >
        {isLoading ? <DotsLoader /> : null}
        {formik?.values?.batch_id ? (
          <div
            class="flex items-center p-4 mb-4 text-lg text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800"
            role="alert"
          >
            <FaInfoCircle />
            <div>
              <span class="font-medium ml-2">
                The selected batch has {editSeoBatchData?.domain_ids?.length}{" "}
                domains, and you are adding{" "}
                {newSelectedData?.map((item) => item._id)?.length} more..
              </span>
            </div>
          </div>
        ) : null}
        <form>
          <div className="grid md:grid-cols-2 gap-4 w-full">
            <FormInput
              errors={errors}
              name="batch_name"
              label="Batch Name"
              formik={formik}
            />

            <FormSelectInput
              errors={errors}
              name="batch_id"
              label="Batch List"
              formik={formik}
              options={batchesData}
              labelProp="batch_name"
              valueProp="_id"
            />
          </div>

          <div className="grid md:grid-cols-1 gap-4 w-full mt-2">
            <FormTextArea
              label="Description"
              rows={3}
              name="description"
              formik={formik}
              type="text"
              errors={errors}
            />
          </div>

          <div className="flex p-0 mt-2">
            <div
              className={`flex-1 bg-gray-100 p-4 shadow ${
                !editSeoBatchData ? "w-full" : ""
              }`}
              style={{ maxHeight: "calc(60vh - 2rem)" }}
            >
              <h2 className="text-lg font-semibold mb-2 sticky top-0 bg-gray-100">
                New Domains
              </h2>
              <div
                className="overflow-y-auto"
                style={{ maxHeight: "calc(40vh - 2rem - 2.5rem)" }}
              >
                {newSelectedData
                  ?.map((item) => item.domain)
                  ?.map((item, index) => (
                    <div key={index} className="mb-1 text-blue-600">
                      {item}
                      <button
                        type="button"
                        onClick={() => handleRemoveNewDomain(index)}
                        className="ml-2 p-1 rounded-full hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 text-black"
                      >
                        <IoCloseOutline size={18} />
                      </button>
                    </div>
                  ))}
              </div>
            </div>
            {editSeoBatchData && (
              <div
                className="flex-1 bg-gray-100 p-4 shadow ml-4"
                style={{ maxHeight: "calc(60vh - 2rem)" }}
              >
                <h2 className="text-lg font-semibold mb-2 sticky top-0 bg-gray-100">
                  Selected Batch Domains
                </h2>
                <div
                  className="overflow-y-auto"
                  style={{ maxHeight: "calc(40vh - 2rem - 2.5rem)" }}
                >
                  {editSeoBatchData?.domain_ids?.map((item, index) => (
                    <div key={item._id} className="mb-1 text-blue-600">
                      {item.domain}
                      <button
                        type="button"
                        onClick={() => handleSelectedBatchDomain(index)}
                        className="ml-2 p-1 rounded-full hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 text-black"
                      >
                        <IoCloseOutline size={18} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </form>
      </CustomModal>
    </>
  );
};

export default AddSeoBatches;
