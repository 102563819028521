import React, { useEffect, useState } from "react";
import PurchaseRegistrarModal from "./AddUpdateForm";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import errorMessage from "../../../util/errorMessage";
import { get_users_drd } from "../../../features/usersSlice";
import userPermission from "../../../util/userPermission";
import PageHeader from "../../../components/molecules/PageHeader";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import {
  delete_purcahse_registrar,
  get_purcahse_registrar,
  purchaseRegistrarReset,
} from "../../../features/purchaseRegistrarSlice";
import dayjs from "dayjs";

const PurchaseRegistrar = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector(
    (state) => state?.purchaseRegistrar
  );
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchText, setSearchText] = useState("");
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_purcahse_registrar(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Domain registrar deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "Domain registrar",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          action: "Domain registrar",
          msg: "deleted",
        });
      }
    }
  };
  useEffect(() => {
    dispatch(get_purcahse_registrar());
    dispatch(get_users_drd());
    return () => {
      dispatch(purchaseRegistrarReset());
    };
    // eslint-disable-next-line
  }, []);
  const filterIP = record?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false },
    {
      headerName: "User Name",
      field: "username",
      flex: 1,
    },
    {
      headerName: "Created At",
      field: "createdAt",
      flex: 1,
    },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex flex-row">
          <FaEdit
            onClick={openFormHandler(params.row.records)}
            className="my_navIcon"
            title="Update Purchase Registrar"
          />
          <FaTrashAlt
            onClick={deleteRecordHandler(params.row.records?._id)}
            className="my_navIcon"
            title="Delete Purchase Registrar"
          />
        </div>
      ),
      width: 100,
      filterable: false,
    },
  ];
  return (
    <>
      {isEditing && (
        <PurchaseRegistrarModal
          editingRecord={editingRecord}
          modalTitle="Add Domain registrar"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Domain registrar"
        heading="Domain Registrar Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Allowed IP")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={filterIP?.map((record, index) => {
            let counter = index + 1;
            const { username, registrar_company, createdAt } = record;
            return {
              counter,
              records: { ...record },
              username,
              registrar_company,
              createdAt: dayjs(createdAt).format("ddd, MMM D, YYYY h:mm A"),
            };
          })}
          totalItems={record?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          pagination="No"
        />
      </div>
    </>
  );
};

export default PurchaseRegistrar;
