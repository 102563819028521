import React, { useState, useEffect } from "react";
import {
  DotsLoader,
  Modal,
  FormSelectInput,
  FormTextArea,
} from "../../../../components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { get_decline_reasons_drd } from "../../../../features/declineReasonSlice";
import * as Yup from "yup";
import api from "../../../../services/api";
import errorMessage from "../../../../util/errorMessage";
import { useNavigate } from "react-router-dom";

const LeadRejectForm = ({ onCancelForm, modalTitle, leadId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const { isLoading, declineReasonDrd } = useSelector(
    (state) => state.declineReason
  );
  const [isLoader, setLoader] = useState(false);

  const RejectLead = Yup.object({
    decline_reason_id: Yup.string().required("Reason is required"),
  });

  const formik = useFormik({
    initialValues: {
      lead_id: leadId || "",
      decline_reason_id: "",
      decline_comment: "",
    },
    validateOnBlur: true,
    validationSchema: RejectLead,
    onSubmit: async (values) => {
      try {
        const payload = {
          lead_id: leadId,
          decline_reason_id: values?.decline_reason_id,
          decline_comment: values?.decline_comment,
        };
        const res = await api.post("/api/leads/decline", payload);
        if (res.status === 200 || res.status === 201) {
          toast.success("Lead Declined successfully.");
          onCancelForm();
          navigate("/leads");
        } else {
          errorMessage({
            payload: res.payload,
            setErrors: setErrors,
            action: "Lead",
            msg: "Declined",
          });
        }
        setLoader(false);
      } catch (err) {
        setLoader(false);
        if (err?.response?.data?.error) {
          errorMessage({
            payload: err?.response?.data?.error,
            setErrors: setErrors,
            action: "Lead",
            msg: "Declined",
          });
        } else {
          toast.error(err?.response?.data);
          return;
        }
        console.log(err);
      }
    },
  });

  useEffect(() => {
    dispatch(get_decline_reasons_drd());
  }, [dispatch]);

  useEffect(() => {
    if (declineReasonDrd.length > 0 && !formik.values.decline_reason_id) {
      formik.setFieldValue("decline_reason_id", declineReasonDrd[0]._id);
    }
  }, [declineReasonDrd, formik]);

  return (
    <>
      <Modal
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading || isLoader}
        onClick={onCancelForm}
      >
        {(isLoading || isLoader) && <DotsLoader />}
        <form className="mt-4">
          <div className="mb-3 mt-4">
            <FormSelectInput
              errors={errors}
              name="decline_reason_id"
              label="Reason"
              formik={formik}
              options={declineReasonDrd}
              valueProp="_id"
              labelProp="reason"
              nullOption="No"
            />
          </div>
          <div className="mb-3 mt-4">
            <FormTextArea
              label="Description"
              rows={5}
              name="decline_comment"
              formik={formik}
              type="text"
              errors={errors}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default LeadRejectForm;
