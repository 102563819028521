export const location_data = [
  {
    location: "OTHER",
    cost: "",
    label: "All States",
  },
  {
    location: "NY_NJ",
    cost: "",
    label: "New York",
  },
  {
    location: "LA_ZIP",
    cost: "",
    label: "Los Angeles",
  },
];
