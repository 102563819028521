import React, { useState } from "react";
import { Button, Loader } from "../../../../components";
import {
  MdOutlineMarkEmailRead,
  MdOutlineNotificationsNone,
} from "react-icons/md";
import { FaSms } from "react-icons/fa";
const CustomModal = ({ onClose, notifyTechHandler }) => {
  const [isLoader] = useState(false);
  return (
    <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40 bg-black/60">
      <div className="bg-white p-8 rounded-lg shadow-lg relative min-w-[500px] max-w-[90%]">
        <h2 className="text-lg font-semibold mb-4">
          Do you want to notify the vendor about this job?
        </h2>
        <div className="flex justify-end !mt-4">
          {isLoader ? (
            <Loader />
          ) : (
            <div className="flex w-full justify-between">
              <div className="flex gap-x-3">
                <button
                  title="Notify by Email"
                  className="flex gap-x-1 items-center hover:bg-primary-100 hover:text-white px-2 rounded-md border  font-semibold"
                  type="button"
                  onClick={() => notifyTechHandler("Email")}
                >
                  <MdOutlineMarkEmailRead />
                  Email
                </button>
                <button
                  title="Notify by SMS"
                  className="flex gap-x-1 items-center hover:bg-primary-100 hover:text-white px-2 rounded-md border  font-semibold"
                  type="button"
                  onClick={() => notifyTechHandler("SMS")}
                >
                  <FaSms />
                  SMS
                </button>
                <button
                  title="Notify by both (Email & SMS)"
                  className="flex gap-x-1 items-center hover:bg-primary-100 hover:text-white px-2 rounded-md border  font-semibold"
                  onClick={() => notifyTechHandler("Both")}
                  type="button"
                >
                  <MdOutlineNotificationsNone />
                  Both
                </button>
              </div>
              <Button text="Cancel" variant="btn_cancel" onClick={onClose} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
