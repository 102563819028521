import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import { get_industry_service_area } from "../../features/GeoIndustrySlice";
import { DotsLoader } from "../../components";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
function ServiceAreas() {
    const { isLoading, service_area } = useSelector((state) => state.geoIndustry);
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = React.useState("");

  useEffect(() => {
    dispatch(get_industry_service_area({industry_id:id}));
    // eslint-disable-next-line
  }, []);
  function exportToExcel() {
    const data = service_area;
    const currentDate = new Date();
    const rows = [
      ["Industry Service Area",`Total Record: ${data?.length}`,`Export Date: ${dayjs(currentDate).format("ddd, MMM D, YYYY h:mm A")}`, ],
      ["Cities", "States", "Zips",], // Add headings
    ];

    data.forEach((item) => {
      const { city, state, zip } = item;
      rows.push([
        city,
        state,
        zip,
      ]); // Add a row without domains
    });

    const worksheet = XLSX.utils.aoa_to_sheet(rows);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const fileName = "industry service areas.xlsx";

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    if (typeof window.navigator.msSaveBlob !== "undefined") {
      // For IE browser
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    }
  }

  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "City", field: "city", flex: 1, minWidth: 300 },
    { headerName: "State", field: "state", flex: 1, minWidth: 100 },
    { headerName: "Zip", field: "zip", flex: 1, minWidth: 100 },
  ];
  const data = service_area?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  return (
    <>
      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
        {isLoading && <DotsLoader text="Please Wait" />}
        <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
          <h1 className="text-2xl font-extrabold">Industry Service Areas</h1>
        </div>

        <div className="bg-white !my-3 border rounded">
          <MUIDataTable
            columnDefs={columnDefs}
            items={data?.map((record, index) => {
              let counter = index + 1;
              let records={...record,_id:index}
              let id = record.id;
              let city = record?.city;
              let state = record?.state;
              let zip = record?.zip;
              return {
                records: records,
                id,
                counter,
                city,
                state,
                zip,
              };
            })}
            searchText={searchInput}
            setSearchText={setSearchInput}
            pagination="No"
            totalItems={service_area?.length}
            exportButton={exportToExcel}
          />
        </div>
      </div>
    </>
  );
}

export default ServiceAreas;
