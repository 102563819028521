import React from "react";
import { Button } from "../../components";
const DNSVerificationResultModal = ({ onClose, data }) => {
  return (
    <div className="fixed inset-0 flex items-start justify-center z-40 bg-black/60">
      <div className="bg-white p-8 rounded-lg shadow-lg relative min-w-[500px] max-w-[90%]">
        <div className="relative flex flex-col">
          <div className="flex gap-y-3 flex-col">
            <div className="flex gap-x-2">
              <strong>Acknowledged</strong>
              <span>{data?.acknowledged ? "Yes" : "No"}</span>
            </div>
            <div className="flex gap-x-2">
              <strong>Matched Count:</strong>
              <span>{data?.matchedCount || ""}</span>
            </div>
            <div className="flex gap-x-2">
              <strong>Modified Count: </strong>
              <span>{data?.modifiedCount || ""}</span>
            </div>
            <div className="flex gap-x-2">
              <strong>Upserted Count: </strong>
              <span>{data?.upsertedCount}</span>
            </div>
            <div className="flex gap-x-2">
              <strong>Upserted ID: </strong>
              <span>{data?.upsertedId || ""}</span>
            </div>
          </div>
        </div>
        <div className="flex justify-end !mt-4">
          <Button text="Cancel" variant="btn_danger" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default DNSVerificationResultModal;
