import React from "react";
import VehicleInfoSection from "../Common/Sections/VehicleInfoSection";
const GasDelivery = ({ formik, serviceName }) => {
  return (
    <VehicleInfoSection
      formik={formik}
      serviceName={serviceName}
      fieldsToShow={[
        "car_year",
        "car_make",
        "car_model",
        "is_neutral",
        "car_color",
        "car_type",
        "gallons",
        "fuel_type",
      ]}
    />
  );
};

export default GasDelivery;
