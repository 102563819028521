import React, { useState } from "react";
import { useSelector } from "react-redux";
import { tow_truck_no_bg } from "../../../../images";
import { DotsLoader } from "../../../../components";
import { generateQuotingPriceRequest } from "../../../../services/agentlessFormService";
import { toast } from "react-toastify";
function Quote({ formik, record }) {
  const { isLoading, quote, isError } = useSelector((state) => state.leads);
  const service = record?.records?.filter(
    (service) => service?._id === formik?.values.job_category_id
  );
  const [isLoader, setIsLoader] = useState(false);
  const generate_quoting_price_request = async () => {
    setIsLoader(true);
    try {
      const res = await generateQuotingPriceRequest(formik?.values?._id);
      if (res.status === 200 || res.status === 201) {
        formik.setValues({
          ...formik.values,
          quoting_request_status: res.data?.quoting_request_status,
        });
        toast.success(res.data.message || "Quoting request sent successfully");
      } else {
        toast.error(res.data.message || "Quoting request couldn't be send");
      }
    } catch (err) {
      toast.error(
        err?.response.data.message || "Quoting request couldn't be send"
      );
      console.log("👊 ~ constgenerate_quoting_price_request=async ~ err:", err);
    }
    setIsLoader(false);
  };
  return (
    <div>
      {isLoading || isLoader ? (
        <DotsLoader />
      ) : quote ? (
        <div className={["flex md:flex-row flex-col gap-x-2"].join(" ")}>
          <div className="sm:col-span-12 md:col-span-6 mb-4 md:!mb-0  bg-white rounded-2xl shadow-client">
            <div className="flex justify-center items-center flex-col p-4">
              <h5 className="text-client-100 pb-2.5 w-full border-b border-client-100">
                Here is the estimated cost of your service.
              </h5>
              <img
                src={tow_truck_no_bg}
                alt=""
                className="w-full max-w-[900px]"
              />
              <h2 className="mt-5">
                Best price - {quote?.currency}
                {formik.values?.quoting_request_price || quote?.service_cost}
              </h2>
              {formik.values?.quoting_request_status === "Pending" ? (
                <div>Your request has been sent, please wait for approval.</div>
              ) : formik.values?.quoting_request_status === "Quoted" ? (
                <div className="text-green-600 !mt-4">
                  Your request has been approved.
                </div>
              ) : formik.values?.quoting_request_status === "Rejected" ? (
                <div className="text-red-600 !mt-4">
                  Your request has been rejected.
                </div>
              ) : (
                <div>
                  Do you want to send this price to Quoting Team?{" "}
                  <button
                    className="text-blue-500 cursor-pointer hover:underline"
                    onClick={generate_quoting_price_request}
                  >
                    Click here
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="sm:col-span-12 md:col-span-6 p-4 bg-white rounded-2xl shadow-client">
            <h5 className="text-client-100 pb-2.5 w-full border-b border-client-100">
              Review your information
            </h5>
            <div>
              <div className="col-span-1 text-client-100 pb-1.25 pl-0 border-b border-client-50 ml-2.5 mb-1.25 font-bold">
                Basic Information
              </div>

              <div className="ml-3">
                <div className=" overflow-y-auto">
                  <div className="sm:col-span-2">
                    <div className="grid grid-cols-2">
                      <div className="col-span-1">
                        <label htmlFor="phone" className="text-client-50">
                          Phone:{" "}
                        </label>{" "}
                        <span id="phone">{formik.values.phone}</span>
                      </div>

                      <div className="col-span-1">
                        <label htmlFor="name" className="text-client-50">
                          Name:{" "}
                        </label>{" "}
                        <span id="name">{formik.values.name}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div className="grid grid-cols-2">
                      <div className="col-span-1">
                        <label
                          htmlFor="alternate_phone"
                          className="text-client-50"
                        >
                          Alternate No:{" "}
                        </label>{" "}
                        <span id="alternate_phone">
                          {formik.values.alternate_phone}
                        </span>
                      </div>

                      <div className="col-span-1">
                        <label htmlFor="email" className="text-client-50">
                          Email:{" "}
                        </label>{" "}
                        <span id="email">{formik.values.email}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="sm:col-span-2 md:col-span-1 text-client-100 pb-1.25 pl-0 border-b border-client-50 ml-2.5 mb-1.25 font-bold">
                Service & Car Info
              </div>

              <div className="ml-3">
                <div className="grid grid-cols-2">
                  <div className="sm:col-span-2">
                    <div className="grid grid-cols-2">
                      <div className="col-span-1">
                        <label htmlFor="service" className="text-client-50">
                          Service:{" "}
                        </label>{" "}
                        <span id="service">{service[0]?.name}</span>
                      </div>

                      <div className="col-span-1">
                        <label htmlFor="name" className="text-client-50">
                          Car Year:{" "}
                        </label>{" "}
                        <span id="name">{formik.values.car_year}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div className="grid grid-cols-2">
                      <div className="col-span-1">
                        <label
                          htmlFor="alternate_phone"
                          className="text-client-50"
                        >
                          Car Make:{" "}
                        </label>{" "}
                        <span id="alternate_phone">
                          {formik.values.car_make}
                        </span>
                      </div>

                      <div className="col-span-1">
                        <label htmlFor="email" className="text-client-50">
                          Car Model:{" "}
                        </label>{" "}
                        <span id="email">{formik.values.car_model}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div className="grid grid-cols-2">
                      <div className="col-span-1">
                        <label
                          htmlFor="alternate_phone"
                          className="text-client-50"
                        >
                          Neutral:{" "}
                        </label>{" "}
                        <span id="alternate_phone">
                          {formik.values.is_neutral ? "Yes" : "No"}
                        </span>
                      </div>

                      <div className="col-span-2">
                        <label htmlFor="email" className="text-client-50">
                          Instruction:{" "}
                        </label>{" "}
                        <span id="email">{formik.values.email}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="sm:col-span-2 md:col-span-1 text-client-100 pb-1.25 pl-0 border-b border-client-50 ml-2.5 mb-1.25 font-bold">
                Pickup & Drop Location
              </div>

              <div className="ml-3">
                <div className="grid grid-cols-2">
                  <div className="sm:col-span-2">
                    <div className="grid grid-cols-2">
                      <div className="col-span-2">
                        <label htmlFor="phone" className="text-client-50">
                          Pickup Address:{" "}
                        </label>{" "}
                        <span id="phone">
                          {formik.values.complete_pickup_address}
                        </span>
                      </div>
                      {formik.values.complete_drop_address ? (
                        <div className="col-span-2">
                          <label htmlFor="name" className="text-client-50">
                            Drop Address:{" "}
                          </label>{" "}
                          <span id="name">
                            {formik.values.complete_drop_address}
                          </span>
                        </div>
                      ) : null}

                      {formik.values.distance ? (
                        <div className="col-span-2">
                          <label htmlFor="name" className="text-client-50">
                            Distance:{" "}
                          </label>{" "}
                          <span id="name">{formik.values.distance} Miles</span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        isError && (
          <div className="border border-[#b12d2d4a] text-danger bg-[#ff00002e] text-center rounded-1.25 font-pop text-[80%] p-2.5">
            Service cannot be provided due to Location
          </div>
        )
      )}
    </div>
  );
}

export default Quote;
