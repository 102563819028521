import api from "./api";

export const getJobTypes = async () => {
  return await api.get("/api/jobTypes");
};

export const createJobType = async (name) => {
  return await api.post("/api/jobTypes", { name });
};

export const updateJobType = async (id, name) => {
  return await api.put("/api/jobTypes", { id, name });
};

export const deleteJobType = async (id) => {
  return await api.delete("/api/jobTypes", { data: { id } });
};
