import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getMonths } from "../services/monthsService";

const initialState = {
  isError: false,
  isLoader: false,
  months: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Countries
export const get_months = createAsyncThunk("get_months", async (token,thunkAPI) => {
  try {
    return await getMonths();
  } catch (error) {
    const message =getErrorMessage(error)
    return thunkAPI.rejectWithValue(message);
  }
});

export const monthsSlice = createSlice({
  name: "getMonths",
  initialState,
  reducers: {
    monthsReset: (state) => {
      state.isError = false;
      state.isLoader = false;
      state.months = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_months.pending, (state) => {
        state.isLoader = true;
        state.months = [];
      })
      .addCase(get_months.fulfilled, (state, action) => {
        state.isLoader = false;
        state.months = action.payload?.data;
      })
      .addCase(get_months.rejected, (state, action) => {
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.months = [];
      });
  },
});

export const { monthsReset } = monthsSlice.actions;
export default monthsSlice.reducer;
