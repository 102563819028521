import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import {
  get_industries_db,
  updateAPILink,
  updateTotalDeployedCount,
} from "../../features/IndustrySlice";
import { NavLink } from "react-router-dom";
import { DotsLoader } from "../../components";
import api from "../../services/api";
import { toast } from "react-toastify";
import QueryResponseModal from "./QueryResponseModal";
import { FaEdit, FaSave, FaTimes } from "react-icons/fa";
import ImportDBExcel from "./ImportDBExcel";
import { FiChevronDown } from "react-icons/fi";
import { IconButton, TextField } from "@mui/material";
import dayjs from "dayjs";
import EditCellInput from "./EditCellInput";
const IndustryDatabase = () => {
  const dispatch = useDispatch();
  const { isLoading, industryDB } = useSelector((state) => state?.industry);
  const [searchText, setSearchText] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [newAPILink, setNewAPIlink] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSmallLoader, setIsSmallLoader] = useState(false);
  const [queryResponse, setQueryResponse] = useState(null);
  const [oldQueryResult, setOldQueryResult] = useState(null);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isImport, setIsImport] = useState(false);
  const [reloadPage, setReloadPage] = React.useState({
    isReload: "No",
    interval: -1,
  });
  const [secondsLeft, setSecondsLeft] = React.useState(null);
  const [queryResult, setQueryResult] = useState({
    queries: [],
    industry_name: "",
  });
  const [showExportOptions, setShowExportOptions] = React.useState(false);
  const optionsRef = React.useRef();
  const option = [
    { label: "Don't Reload", value: "No" },
    { label: "10 Sec", value: "10" },
    { label: "20 Sec", value: "20" },
    { label: "30 Sec", value: "30" },
    { label: "Custom", value: "custom" },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setShowExportOptions(false);
    }
  };
  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setShowExportOptions(false);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  useEffect(() => {
    dispatch(
      get_industries_db({
        size: paginationModel.pageSize,
        page: 1,
      })
    );
    // eslint-disable-next-line
  }, []);
  const user = JSON.parse(localStorage.getItem("user"));
  const cronFunctions = industryDB?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const hasDesiredStatus =
    industryDB &&
    industryDB?.records?.filter(
      ({ db_update, regenerate_domain_db }) =>
        regenerate_domain_db > 0 || db_update > 1
    )?.length > 0;
  const saveBlobAsExcelFile = (blobData, dbName) => {
    const downloadLink = window.URL.createObjectURL(new Blob([blobData]));

    const link = document.createElement("a");
    link.href = downloadLink;
    link.setAttribute("download", `${dbName}.xlsx`); // Set the filename

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadLink);
  };
  const handleExport = async (dbName) => {
    setIsLoader(true);
    try {
      const response = await api.get(
        `/api/industries/export_database_new/${dbName}`,
        {
          responseType: "blob", // Specify the response type as Blob
        }
      );
      if (response?.status === 200) {
        const blobData = response.data;
        saveBlobAsExcelFile(blobData, dbName);
        toast.success("Database export successfully");
      } else {
        toast.error("Database couldn't be export");
      }

      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      toast.error("Database couldn't be export");
      console.error("Error fetching Excel data:", error);
    }
  };
  const openImportFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsImport(true);
  };
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsImport(false);
  };
  const regenrateDomainDB = async ({ id, domain }) => {
    setIsLoader(true);
    try {
      const res = await api.post(
        `/api/industries/regenerate_domain_database/${id}/${domain}`
      );
      if (res.status === 200) {
        dispatch(get_industries_db());
        toast.success(res?.data?.message);
      } else {
        toast.error(
          res?.data?.message
            ? res?.data?.message
            : res?.message
            ? res?.message
            : `Database regenerate process couldn't be started`
        );
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.log("~ file: ImportExcel.jsx:94 ~ onSubmit ~ error", error);
      toast.error(
        error.response?.data?.message
          ? error.response?.data?.message
          : error.response?.message
          ? error.response?.message
          : `Database regenerate process couldn't be started`
      );
    }
  };
  const handleSaveTags = async (params) => {
    const { id, field, api } = params;
    try {
      dispatch(
        updateAPILink({ id: params.row.records.id, domain: params.newAPILink })
      );
      await api.setEditCellValue({ id, field, value: params.newAPILink });
      api.stopCellEditMode({ id, field });
    } catch (error) {
      console.error("Save Error:", error);
    }
  };

  const CustomCell = (params) => {
    const handleEditClick = () => {
      params.api.startCellEditMode({ id: params.id, field: params.field });
      setNewAPIlink(params?.row.domain);
    };
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton
          onClick={handleEditClick}
          color="primary"
          sx={{ marginRight: 1 }}
        >
          <FaEdit size={16} />
        </IconButton>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://${params?.row.domain}`}
        >
          {params?.row.domain}
        </a>
      </div>
    );
  };
  const roleLevelColumns =
    user?.role_level === 1
      ? [
          {
            headerName: "Runner Name",
            field: "runner_name",
            minWidth: 120,
            flex: 1,
          },
          {
            headerName: "Total Purchased",
            field: "totalPurchased",
            flex: 1,
            minWidth: 100,
          },
          {
            headerName: "Database Name",
            field: " db_name",
            minWidth: 200,
            flex: 1,
            renderCell: (params) => (
              <NavLink
                rel="noopener noreferrer"
                to={`/industry_database/${params?.row?.db_name}`}
                className="text-[#000] no-underline hover:underline hover:text-blue-600"
              >
                {params?.row?.db_name}
              </NavLink>
            ),
          },
          {
            headerName: "Update database",
            field: "database",
            minWidth: 200,
            renderCell: (params) => {
              const handleOpenModal = async (buttonType) => {
                try {
                  setIsLoader(true);
                  const response = await api.post(
                    "/api/industries/get_industry_query_pending_list",
                    {
                      industry_id: params?.row?.records?.id,
                      query_type: buttonType,
                    }
                  );

                  if (response.status === 200) {
                    setQueryResult({
                      queries: response.data.records || response.data.metaData,
                      industry_name: params?.row?.industry_name,
                    });
                    setModalOpen(true);
                  } else {
                    toast.error("Some error occurred");
                  }
                  setIsLoader(false);
                } catch (error) {
                  setIsLoader(false);
                  console.error("Error fetching data:", error);
                }
              };
              const totalQuery = params?.row?.totalQuery;
              return params.row.totalQuery > 1 ? (
                <div className="flex justify-between items-center gap-x-2">
                  <span
                    onClick={() => handleOpenModal("Pending")}
                    className="text-black-600 cursor-pointer no-underline hover:underline bg-blue-300 min-w-[36px] text-center hover:bg-blue-400 px-2 py-1 rounded-sm font-medium"
                    title="api/industries/get_industry_query_pending_list"
                  >
                    {totalQuery}
                  </span>
                  <span>Update Needed</span>
                </div>
              ) : (
                <span title="nothing to update">N/A</span>
              );
            },
          },
          {
            headerName: "Export Excel",
            field: "excel",
            flex: 1,
            minWidth: 100,
            renderCell: (params) =>
              params?.row?.db_name ? (
                <span
                  onClick={() => handleExport(params?.row?.db_name)}
                  className="underline text-blue-600 cursor-pointer"
                >
                  Export
                </span>
              ) : null,
          },
          {
            field: "testing_domain",
            headerName: "Test Link",
            renderCell: (params) => {
              return (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://${params?.row.testing_domain}`}
                >
                  {params?.row.testing_domain}
                </a>
              );
            },
            flex: 1,
            minWidth: 150,
          },
          {
            field: "domain",
            headerName: "API Link",
            renderCell: CustomCell,
            renderEditCell: (params) => (
              <EditCellInput
                params={params}
                preValue={newAPILink}
                handleSaveTags={handleSaveTags}
              />
            ),
            flex: 1,
            minWidth: 250,
            editable: true,
          },
          {
            headerName: "Import Excel",
            field: "actions",
            renderCell: (params) => (
              <div className="flex items-center w-full">
                <span
                  onClick={openImportFormHandler(params?.row?.records?.id)}
                  className="underline text-blue-600 cursor-pointer"
                >
                  Import Complete Excel
                </span>
              </div>
            ),
            width: 150,
          },
          {
            headerName: "Regenerate Domains DB",
            field: "regenrate",
            renderCell: (params) => (
              <div className="flex items-center w-full">
                <span
                  onClick={() => {
                    const c = window.confirm(
                      `Are you sure want to Regenerate database for ${params.row.industry_name}?`
                    );
                    if (!c) {
                      return;
                    }
                    regenrateDomainDB({
                      id: params?.row?.records?.id,
                      domain: params?.row?.domain,
                    });
                  }}
                  className="underline text-blue-600 cursor-pointer"
                >
                  Regenerate DB
                </span>
              </div>
            ),
            width: 150,
          },
        ]
      : [
          {
            headerName: "Database Name",
            field: " db_name",
            minWidth: 200,
            flex: 1,
            renderCell: (params) => (
              <NavLink
                rel="noopener noreferrer"
                to={`/industry_database/${params?.row?.db_name}`}
                className="text-[#000] no-underline hover:underline hover:text-blue-600"
              >
                {params?.row?.db_name}
              </NavLink>
            ),
          },
        ];
  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false, width: 60 },
    {
      headerName: "Industry Name",
      field: "industry_name",
      flex: 1,
      minWidth: 120,
    },
    ...roleLevelColumns,
  ].filter(Boolean);
  const handleCloseModal = () => {
    setQueryResult(null);
    setModalOpen(false);
  };
  const diffString = (oldText, newText) => {
    const diff = require("diff");
    const differences = diff.diffWords(oldText, newText);
    let result = "";
    differences.forEach((part) => {
      if (part.added) {
        result += `<span style="background-color: lightgreen;">${part.value}</span>`;
      } else if (part.removed) {
        result += `<span style="background-color: lightcoral;">${part.value}</span>`;
      } else {
        result += part.value;
      }
    });

    return result;
  };
  const processDifferences = (differences) => {
    const result = {};
    for (const key in differences) {
      if (differences.hasOwnProperty(key)) {
        const { old, new: newValue } = differences[key];
        if (old && newValue) {
          const highlightedDiff = diffString(old || "", newValue || "");
          result[key] = highlightedDiff; // Store the result for each key
        } else {
          result[key] = old || newValue;
        }
      }
    }

    return result;
  };
  const getDifferences = (metaData) => {
    const oldData = metaData.old || {};
    const newData = metaData.new || {};
    const excludedKeys = ["_id"];
    const differences = {};
    let keysToProcess = [];

    if (Object.keys(oldData).length > 0 && Object.keys(newData).length > 0) {
      keysToProcess = Object.keys(newData).filter((key) => key in oldData);
    } else if (
      !Object.keys(oldData).length > 0 &&
      Object.keys(newData).length > 0
    ) {
      keysToProcess = Object.keys(newData);
    } else if (
      Object.keys(oldData).length > 0 &&
      !Object.keys(newData).length > 0
    ) {
      keysToProcess = Object.keys(oldData);
    }
    keysToProcess.forEach((key) => {
      if (!excludedKeys.includes(key)) {
        const oldValue = oldData[key] !== undefined ? oldData[key] : null;
        const newValue = newData[key] !== undefined ? newData[key] : null;
        if (oldValue !== newValue) {
          differences[key] = {
            old: oldValue,
            new: newValue,
          };
        }
      }
    });
    const highlightedDifferences = processDifferences(differences);
    return highlightedDifferences;
  };

  function QueryModal({ queries }) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 top-0 left-0 right-0 bottom-0 px-4 bg-black/60">
        <div className="relative bg-white p-4 rounded-lg shadow-md max-w-[80%] w-full">
          <button
            className="absolute top-3 right-7 text-red-600 text-2xl"
            onClick={handleCloseModal}
          >
            <FaTimes />
          </button>
          <h1 className="text-center border-b pb-2 font_pop font-semibold text-lg">
            Total Queries for {queries?.industry_name}:{" "}
            {queries?.queries?.length}
          </h1>
          <div className="max-h-[50vh] overflow-y-auto">
            {/* <HighlightedChanges data={queryResult?.queries} /> */}
            {queries?.queries?.map((record, index) => (
              <div className="border-b mt-3 border-[#b6b6b6]" key={index}>
                <div className="text-sm font-medium flex flex-col">
                  {record?.username ? (
                    <div>
                      {`${index + 1}. `} <strong>{record.column_name}</strong>{" "}
                      column{" "}
                      <strong>
                        {record.operation === "Delete"
                          ? `Deleted`
                          : `${record.operation}ed`}
                      </strong>{" "}
                      by{" "}
                      <span className="text-lg font-bold text-red-600">
                        {`${record?.username}`}
                      </span>
                      {" at "}{" "}
                      {dayjs(record.createdAt).format("DD MMM YYYY, hh:mm A")}{" "}
                    </div>
                  ) : null}
                  <div>
                    {Object.keys(getDifferences(record?.metaData) || {}).map(
                      (key) => (
                        <div key={key}>
                          <strong>{key}</strong>:{" "}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: getDifferences(record?.metaData)[
                                key
                              ]?.replace("</span><span", "</span> -> <span"),
                            }}
                          />
                        </div>
                      )
                    )}
                  </div>
                  {record?.metaData?.old ? (
                    <span
                      className="ml-1 text-blue-600 hover:underline cursor-pointer"
                      onClick={() => setOldQueryResult(record)}
                    >
                      See old record
                    </span>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
          <div className="mt-2 flex justify-end items-center">
            <button
              className="border bg-red-600 text-white p-2 text-sm"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
  const HighlightedChanges = ({ data }) => {
    // Function to highlight changes between old and new
    const highlightChanges = (oldData, newData) => {
      const result = [];

      for (const key in newData) {
        if (newData[key] !== oldData[key]) {
          // If the value has changed, we wrap it in a <span> with highlighting
          result.push(
            <div key={key}>
              <strong>{key}:</strong>
              <span style={{ color: "red", textDecoration: "line-through" }}>
                {oldData[key] || "N/A"}
              </span>
              → <span style={{ color: "green" }}>{newData[key]}</span>
            </div>
          );
        } else {
          // If the value hasn't changed, just display it normally
          result.push(
            <div key={key}>
              <strong>{key}:</strong> {newData[key]}
            </div>
          );
        }
      }
      return result;
    };

    return (
      <div>
        {data.map((entry) => (
          <div key={entry._id} className="change-log">
            <h3>
              Changes by {entry.username} (Operation: {entry.operation})
            </h3>
            <div>
              <strong>Column:</strong> {entry.column_name}
            </div>
            {entry.metaData.old && entry.metaData.new ? (
              <div className="metaData-changes">
                <h4>Changes:</h4>
                {highlightChanges(entry.metaData.old, entry.metaData.new)}
              </div>
            ) : (
              <div>No changes to display</div>
            )}
            <hr />
          </div>
        ))}
      </div>
    );
  };

  function OldQueryModal() {
    const formattedJson = JSON.stringify(oldQueryResult?.metaData, null, 2);

    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 top-0 left-0 right-0 bottom-0 px-4 bg-black/60">
        <div className="relative bg-white p-4 rounded-lg shadow-md max-w-[70%] w-full">
          <button
            className="absolute top-3 right-7 text-red-600 text-3xl"
            onClick={() => setOldQueryResult(null)}
          >
            <FaTimes />
          </button>
          <h1 className="text-center border-b pb-2 font-pop font-semibold text-lg">
            {queryResult?.industry_name}
          </h1>
          <div className="max-h-[50vh] overflow-y-auto">
            <div className="border-b mt-3 border-[#b6b6b6]">
              <div className="text-sm font-medium flex flex-col">
                {/* <span>{formattedJson}</span> */}
                <pre>{formattedJson}</pre>
              </div>
            </div>
          </div>
          <div className="mt-2 flex justify-end items-center">
            <button
              className="border bg-red-600 text-white p-2 text-sm"
              onClick={() => setOldQueryResult(null)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
  const allTotalDeployedTest = async () => {
    if (isSmallLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    setIsSmallLoader(true);
    for (const item of industryDB?.records) {
      const { runner_path, industry_name, runner_name } = item;

      if (runner_name == null) {
        dispatch(
          updateTotalDeployedCount({
            industry: industry_name,
            deployedCount: 0,
          })
        );
        continue;
      }
      const query = `select count(*) as total_domain from domains`;
      try {
        const promises = runner_path?.split(",")?.map(async (runner) => {
          const response = await api.post(
            `${runner}api/setup_front_backend/run_query_on_server`,
            { sql_query: query }
          );
          if (response.status === 200) {
            if (query === `select count(*) as total_domain from domains`) {
              return response.data?.data[0]?.total_domain || 0;
            } else {
              return response.data?.data;
            }
          } else {
            console.error(
              `Request for ${runner.runner_name} failed with status ${response.status}`
            );
            toast.error(
              `Query for ${runner.runner_name} failed with status ${
                response.status
              }, ${
                response.data?.data?.message
                  ? response.data?.data?.message
                  : response.data
              }`
            );
            return 0;
          }
        });

        const results = await Promise.all(promises);
        const deployedCount = results.reduce(
          (total, count) => total + count,
          0
        );
        if (query === `select count(*) as total_domain from domains`) {
          dispatch(
            updateTotalDeployedCount({ industry: industry_name, deployedCount })
          );
          toast.success(`Query completed for ${industry_name}`);
        } else {
          setQueryResponse({ industry: industry_name, data: results });
        }
      } catch (error) {
        toast.error(
          `Query for ${industry_name} failed with status ${
            error?.response?.status
          }, ${
            error?.response?.data?.data?.message
              ? error?.response?.data?.data?.message
              : error?.response?.data
          }`
        );
        console.log("🚀 ~ Industries ~ error:", error);
      } finally {
        setIsLoader(false);
      }
    }
    setIsSmallLoader(false);
  };
  const AutoReloader = () => {
    return (
      <div className="w-auto ml-3 justify-center md:justify-end flex md:!mt-0 pr-1">
        {reloadPage?.isReload === "custom" ? (
          <input
            type="text"
            className="h-[31px] mr-2 bg-white border border-gray-300 text-gray-900 text-sm rounded-[0.2rem] w-[119px] p-2.5 "
            placeholder="Seconds"
            value={reloadPage?.interval}
            onChange={(e) => {
              setReloadPage({ ...reloadPage, interval: e.target.value });
            }}
            onFocus={() => setSecondsLeft("")}
            onBlur={(e) => {
              setSecondsLeft(e.target.value);
            }}
            autoFocus={reloadPage.isReload === "custom" && secondsLeft !== ""}
          />
        ) : null}
        <div className="relative pr-1">
          <button
            onClick={() => setShowExportOptions(!showExportOptions)}
            text="Export"
            className="relative float-right border min-w-[125px] w-full h-[31px] text-sm flex justify-between items-center px-0.5 rounded-[0.2rem] text-black font-regular"
          >
            <span>
              {" "}
              {
                option?.find(({ value }) => value === reloadPage.isReload)
                  ?.label
              }
            </span>
            <span>
              {" "}
              <FiChevronDown />
            </span>
          </button>
          {showExportOptions && (
            <div className="absolute top-full rounded-t-[0.2rem] !mt1.25 bg-white z-10 border-x border-b border-[#ddd] -ml-px overflow-hidden transition ease-in-out delay-150">
              <ul ref={optionsRef} className="!pl-0 !mb-0 w-full">
                {option?.map((option, index) => {
                  return (
                    <li
                      key={index}
                      className="w-full cursor-pointer px-2.5 !py-1.25 border-t border-[#ddd] hover:bg-[#e1e1e1]"
                      onClick={(e) => {
                        setReloadPage({
                          isReload: option?.value,
                          interval:
                            option?.value === "custom"
                              ? ""
                              : option?.value === "No"
                              ? -1
                              : +option?.value,
                        });
                        setSecondsLeft(
                          option?.value === "custom"
                            ? ""
                            : option?.value === "No"
                            ? -1
                            : +option?.value
                        );
                        setShowExportOptions(!showExportOptions);
                      }}
                    >
                      {option.label}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  };
  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_industries_db({
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handleRowEditStart = (_params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (_params, event) => {
    event.defaultMuiPrevented = true;
  };
  return (
    <>
      {queryResponse ? (
        <QueryResponseModal
          industry={queryResponse?.industry}
          data={queryResponse?.data}
          handleClose={() => setQueryResponse(null)}
        />
      ) : null}
      {isImport && (
        <ImportDBExcel
          editingRecord={editingRecord}
          modalTitle="Import Excel"
          onCancelForm={cancelFormHandler}
        />
      )}
      {isModalOpen && (
        <QueryModal queries={queryResult} onClose={handleCloseModal} />
      )}
      {oldQueryResult && <OldQueryModal />}
      {isLoader ? <DotsLoader /> : null}
      <PageHeader
        route="Setting > Allowed IP"
        heading="Industries Database List"
        isAllowed={true}
        buttonTitle="Show Total Deploy"
        addIcon="No"
        onClick={allTotalDeployedTest}
        // CustomButtons={AutoReloader}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={cronFunctions?.map((record, index) => {
            const {
              industry_name,
              db_name,
              totalPurchased,
              runner_name,
              deployedCount,
              db_update,
              regenerate_domain_db,
              totalQuery,
              testing_domain,
              runner_path,
              domain,
            } = record;
            const counter = offset + index + 1;
            return {
              counter,
              records: record,
              industry_name,
              db_name,
              totalPurchased,
              runner_name,
              deployedCount,
              db_update,
              regenerate_domain_db,
              totalQuery,
              testing_domain,
              runner_path,
              domain,
            };
          })}
          totalItems={industryDB?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="Yes"
          isLoading={isLoading}
          additionalMsg={` ${
            hasDesiredStatus && reloadPage?.isReload !== "No"
              ? `| Reload time ${secondsLeft}`
              : ""
          }`}
          // LineOne={AutoReloader}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          editMode="cell"
          // onRowEditStart={handleRowEditStart}
          // onRowEditStop={handleRowEditStop}
          onProcessRowUpdateError={console.error}
          // processRowUpdate={handleSaveTags}
        />
      </div>
    </>
  );
};

export default IndustryDatabase;
