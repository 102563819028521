import React from "react";
import MUIDataTable from "../../../../components/molecules/DataTable/muigrid";
import { Button, DotsLoader } from "../../../../components";
import CustomModal from "./Modal";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import CustomNotifyModal from "./CustomModal";
import ToggleSwitch from "../../../../components/molecules/ToggleButton";
import { getFullName } from "../../../../util/common";
import { useSelector } from "react-redux";
import { userPermissionNew } from "../../../../util/userPermissionNew";

function CardManagement({ formik, job_id }) {
  const [isModal, setIsModal] = React.useState(null);
  const [isNotifyModal, setIsNotifyModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(null);
  const { usersDrd, CRMVendorsDrd } = useSelector((state) => state.users);
  const handleConfirm = async (id, action) => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    setIsLoading(true);
    const msg =
      action === "freeze"
        ? "frozen"
        : action === "unfreeze"
        ? "unfrozen"
        : "deleted";
    try {
      const res = await api.post(
        `/api/jobs/${action}_virtual_card/${job_id}/${id}`
      );
      if (res.status === 200) {
        toast.success(`Card ${msg} successfully`);
        formik.setFieldValue(
          "virtual_cards_info",
          res.data.job.virtual_cards_info
        );
      } else {
        toast.error(res?.data?.message || `Card couldn't be ${msg}`);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.message || `Card couldn't be ${msg}`);
      console.log("🚀 ~ handleConfirm ~ err:", err);
    }
  };
  const handleFreezeUnfreeze = async (id, action) => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    setIsLoading(true);
    const msg =
      action === "freeze"
        ? "frozen"
        : action === "unfreeze"
        ? "unfrozen"
        : "deleted";
    try {
      const res = await api.post(`/api/jobs/freeze_unfreeze_virtual_card`, {
        lead_id: job_id,
        card_id: id,
        type: action,
      });
      if (res.status === 200) {
        toast.success(`Card ${msg} successfully`);
        formik.setFieldValue(
          "virtual_cards_info",
          res.data.job.virtual_cards_info
        );
      } else {
        toast.error(res?.data?.message || `Card couldn't be ${msg}`);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.message || `Card couldn't be ${msg}`);
      console.log("🚀 ~ handleConfirm ~ err:", err);
    }
  };
  const notifyTechHandler = async (notifyBy, id) => {
    setIsLoading(true);
    try {
      const res = await api.post(
        `/api/jobs/send_detail_to_vendor/${job_id}/${id}`,
        { notify_by: notifyBy }
      );
      if (res?.status === 200) {
        toast.success(`Card info sent successfully`);
        setIsNotifyModal(null);
      } else {
        toast.error(res?.data?.message || `Card info couldn't be sent`);
      }
    } catch (err) {
      console.error("🚀 ~ file: index.jsx:157 ~ notifyTechHandler ~ err:", err);
      toast.error(err?.response?.data?.message || `Card couldn't be sent`);
    }
    setIsLoading(false);
  };
  const getBalanceAmount = async (record) => {
    setIsLoading(true);
    try {
      const res = await api.post(
        `/api/jobs/get_virtual_card_balance/${record?.id}`
      );
      if (res.status === 200) {
        toast.success(
          `Card balance: $${
            +res.data?.currentPeriod?.limit - +res.data?.currentPeriod?.spent
          }`
        );
        const currentCardsInfo = formik.values.virtual_cards_info || [];

        // Check if there's a matching card_info.id in the current array
        const updatedCardsInfo = currentCardsInfo.map((card) => {
          if (card.card_info?.id === res.data.id) {
            // Replace the matching card with the new data from res.data
            return {
              ...card,
              card_info: res.data, // Update the card_info with the new object from res.data
              balance:
                +res.data?.currentPeriod?.limit -
                +res.data?.currentPeriod?.spent,
            };
          }
          return card; // Return the existing card if no match
        });
        formik.setFieldValue("virtual_cards_info", updatedCardsInfo);
      } else {
        toast.error(res?.data?.message || "Card request couldn't be rejected");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(
        err?.response?.data?.message || "Card request couldn't be rejected"
      );
      console.log("🚀 ~ handleConfirm ~ err:", err);
    }
  };
  const getRowHeight = (params) => {
    const rowHeight = 30; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    if (data?.sent_to && data?.sent_to.length > 0) {
      const tagHeight = 20; // height of each tag
      const numTags = data.sent_to.length;
      return rowHeight + tagHeight * numTags + cellContentHeight;
    }
  };
  function getUniqueVendors(array) {
    const uniqueVendors = [];
    const vendorIds = new Set();

    array.forEach((item) => {
      if (!vendorIds.has(item.vendor_id)) {
        uniqueVendors.push(item);
        vendorIds.add(item.vendor_id);
      }
    });
    return uniqueVendors;
  }
  const getVendorName = (id) => {
    return CRMVendorsDrd?.find(({ _id }) => _id === id) || null;
  };
  const columnDefs = [
    {
      headerName: "#",
      field: "counter",
      sortable: false,
      filterable: false,
      width: 60,
    },

    {
      minWidth: 150,
      headerName: "Requested by",
      field: "requested_by",
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="font-bol">
            {getFullName(
              usersDrd?.find(
                ({ _id }) => params?.row?.records?.requested_by === _id
              )
            )}
          </span>
        );
      },
    },
    {
      headerName: "Cardholder Name",
      field: "name",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Vendor Name",
      field: "vendor_name",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const vendors = getUniqueVendors(params.row?.sent_to);
        return vendors?.length ? (
          <div className="flex flex-col">
            {vendors?.map((vendor, index) => {
              return (
                <span key={index} className="font-bol">
                  {getFullName(getVendorName(vendor?.vendor_id))}
                </span>
              );
            })}
          </div>
        ) : (
          <span className="font-bol">
            {getFullName(formik?.values?.vendor_id)}
          </span>
        );
      },
    },
    { headerName: "Last 4 digits", field: "lastFour", flex: 1, minWidth: 80 },
    {
      headerName: "Requested Amount",
      field: "requested_amount",
      flex: 1,
      minWidth: 80,
      type: "number",
    },
    {
      headerName: "Sanctioned Amount",
      field: "sanction_amount",
      flex: 1,
      minWidth: 80,
      type: "number",
    },
    {
      headerName: "Final Amount",
      field: "sanctioned_amount",
      flex: 1,
      minWidth: 80,
      type: "number",
    },
    {
      headerName: "Spent Amount",
      field: "spent_amount",
      flex: 1,
      minWidth: 80,
      type: "number",
    },

    userPermissionNew("Show Vendor Balance")
      ? {
          headerName: "Balance",
          field: "balance",
          flex: 1,
          minWidth: 100,
          align: "right",
          type: "number",
          renderCell: (params) => {
            return params?.row?.records?.balance ||
              params?.row?.records?.balance === 0 ? (
              <span className="teext-right">
                {params?.row?.records?.balance}
              </span>
            ) : params.row.records.card_info?.status === "DELETED" ||
              params.row.bulk_delete_request !== "NOT_REQUESTED" ? (
              <span>0</span>
            ) : params?.row?.records?.card_info &&
              params.row.bulk_delete_request === "NOT_REQUESTED" ? (
              <button
                className="text-blue-500 hover:underline"
                type="button"
                onClick={() =>
                  getBalanceAmount(params?.row?.records?.card_info)
                }
              >
                Show Balance
              </button>
            ) : (
              <span>-</span>
            );
          },
        }
      : null,
    { headerName: "Status", field: "status", flex: 1, minWidth: 100 },
    {
      headerName: "Response by",
      field: "action_by",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <span className="font-bol">
          {getFullName(
            usersDrd?.find(({ _id }) => params?.row?.records?.action_by === _id)
          )}
        </span>
      ),
    },
    userPermissionNew("Freeze/Unfreeze Card")
      ? {
          headerName: "Freeze/Unfreeze",
          field: "freeze",
          flex: 1,
          headerAlign: "center",
          minWidth: 130,
          renderCell: (params) => {
            return params.row.records.requested_status === "APPROVED" &&
              params.row.records.card_info?.status !== "DELETED" &&
              params.row.bulk_delete_request === "NOT_REQUESTED" ? (
              <ToggleSwitch
                checked={params.row?.card_info?.status === "FROZEN"}
                unique_by={params?.row?.records?._id}
                onChange={() => {
                  if (params.row?.card_info?.status === "FROZEN") {
                    handleFreezeUnfreeze(params?.row?.records?._id, "unfreeze");
                  } else {
                    handleFreezeUnfreeze(params?.row?.records?._id, "freeze");
                  }
                }}
              />
            ) : null;
          },
        }
      : null,
    userPermissionNew("Send Vendor Card Detail")
      ? {
          headerName: "",
          field: "send",
          flex: 1,
          minWidth: 100,
          renderCell: (params) => {
            return params.row.records.requested_status === "APPROVED" &&
              params.row.records.card_info?.status !== "DELETED" &&
              params.row.bulk_delete_request === "NOT_REQUESTED" ? (
              <button
                // className="bg-primary-100 p-1 text-white font-semibold w-16"
                className="bg-primary-10 p-1 text-blue-500 hover:underline font-semibol w-16"
                type="button"
                onClick={() => setIsNotifyModal(params?.row?.records)}
              >
                {params?.row?.records?.is_email_sent
                  ? "Resend Detail"
                  : "Send Detail"}
              </button>
            ) : null;
          },
        }
      : null,
    userPermissionNew("Delete Vendor Card")
      ? {
          headerName: "",
          field: "delete",
          flex: 1,
          minWidth: 100,
          renderCell: (params) => {
            return params.row.records.requested_status === "APPROVED" &&
              params.row.records.card_info?.status !== "DELETED" &&
              params.row.bulk_delete_request === "NOT_REQUESTED" ? (
              <button
                className="bg-primary-10 p-1 text-blue-500 hover:underline font-semibol w-16"
                onClick={() =>
                  handleConfirm(params?.row?.records?._id, "delete")
                }
                type="button"
              >
                Delete Card
              </button>
            ) : null;
          },
        }
      : null,
  ].filter(Boolean);
  return (
    <div className="px-2 !mt-4">
      {isLoading ? <DotsLoader /> : null}
      <div className="col-12 md:border mb-3 px-0 md:px-3">
        <div className="md:col-2 p-2 flex justify-between">
          <h5 className="">Card Management</h5>
          {userPermissionNew("New Card Request") ? (
            <Button
              text="New Card Request"
              variant="btn_submit"
              onClick={() => setIsModal(true)}
            />
          ) : null}
        </div>
        {isModal ? (
          <CustomModal
            onClose={() => setIsModal(false)}
            invoiceDetail={isModal}
            job_id={job_id}
            formik={formik}
            // reloadReport={reloadReport}
          />
        ) : null}
        {isNotifyModal ? (
          <CustomNotifyModal
            onClose={() => setIsNotifyModal(null)}
            notifyTechHandler={notifyTechHandler}
            record={isNotifyModal}
            isLoader={isLoading}
          />
        ) : null}
        <div className={["mt-2"].join(" ")}>
          <MUIDataTable
            items={formik.values?.virtual_cards_info?.map((record, index) => {
              let counter = index + 1;
              const {
                card_info,
                requested_amount,
                requested_status,
                sanctioned_amount,
                spent_amount,
                sent_to,
                bulk_delete_request,
              } = record;
              return {
                counter,
                records: record,
                card_info,
                requested_amount,

                limit: card_info
                  ? card_info?.currentPeriod?.limit
                  : requested_amount,
                status:
                  bulk_delete_request === "IN_PROCESS"
                    ? "DELETED"
                    : card_info
                    ? card_info?.status
                    : requested_status,
                sanction_amount: sanctioned_amount ? +sanctioned_amount - 1 : 0,
                sanctioned_amount,
                name: card_info?.name || "",
                lastFour: card_info?.lastFour || "",
                spent_amount: spent_amount || 0,
                sent_to,
                bulk_delete_request,
              };
            })}
            columnDefs={columnDefs}
            pagination="No"
            searchable="No"
            displayCount="No"
            toolbar="No"
            height={`${
              formik.values?.virtual_cards_info?.length ? "auto" : "100px"
            }`}
            hideFooter
            pinnedColumns={{
              left: ["counter"],
            }}
            gridOptions={getRowHeight}
          />
        </div>
      </div>
    </div>
  );
}

export default CardManagement;
