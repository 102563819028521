import React from "react";
import { DotsLoader, FormInput, Modal } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  add_affiliate,
  update_affiliate,
} from "../../../features/callCentersSlice";
import { useFormik } from "formik";
import errorMessage from "../../../util/errorMessage";
function AddUpdateForm({ editingRecord, onCancelForm, modalTitle }) {
  const { isLoading } = useSelector((state) => state.callCenters);
  const [errors, setErrors] = React.useState([]);
  const dispatch = useDispatch();
  let initialValues = {
    name: "",
  };

  if (editingRecord) {
    const { _id, name } = editingRecord;
    initialValues = {
      id: _id,
      name,
    };
  }

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    // validationSchema: JobStatusSchema,
    onSubmit: async (values) => {
      if (!isLoading) {
        if (!editingRecord) {
          try {
            const res = await dispatch(add_affiliate(values));
            if (res?.payload?.status === 200 || res?.payload?.status === 201) {
              toast.success("Affiliate created");
              onCancelForm();
            } else {
              errorMessage({
                payload: res.payload,
                setErrors: setErrors,
                action: "Affiliate",
                msg: "created",
              });
            }
          } catch (error) {
            toast.error("Affiliate couldn't be created");
          }
        } else {
          try {
            const res = await dispatch(update_affiliate(values));
            if (res?.payload?.status === 200) {
              toast.success("Affiliate updated");
              onCancelForm();
            } else {
              errorMessage({
                payload: res.payload,
                setErrors: setErrors,
                action: "Affiliate",
                msg: "updated",
              });
            }
          } catch (error) {
            toast.error("Affiliate couldn't be updated");
          }
        }
      } else {
        toast.error("Please wait until previous action be completed");
      }
    },
  });

  return (
    <Modal
      isUpdate={!!editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={formik.handleSubmit}
      isLoading={isLoading}
      onClick={onCancelForm}
    >
      {isLoading ? <DotsLoader /> : null}
      <form className="mt-4">
        <FormInput errors={errors} name="name" label="Name" formik={formik} />
      </form>
    </Modal>
  );
}

export default AddUpdateForm;
