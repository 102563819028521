import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  create_service_cost,
  get_service_cost_data,
  update_service_cost,
} from "../../features/quotePricingSlice";
import { get_job_category } from "../../features/jobCategorySlice";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
const AddServiceSlot = ({ editServiceData, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState([]);
  const jobTypes = useSelector((state) => state.jobCategory);
  const serviceCost = useSelector((state) => state.quotePricing);
  let initialValues = {
    job_category_id: "",
    payment_type: "",
    start_time: "",
    end_time: "",
    la_zip: "",
  };
  if (editServiceData) {
    const { _id, job_category_id, payment_type, start_time, end_time, la_zip } =
      editServiceData;
    initialValues = {
      id: _id,
      job_category_id,
      payment_type,
      start_time,
      end_time,
      la_zip,
    };
  }
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (!jobTypes?.isLoading) {
        if (!editServiceData) {
          try {
            const res = await dispatch(create_service_cost(values));

            if (res?.payload?.status === 200) {
              formik.setFieldValue("service_cost_id", res?.payload?.data?._id);

              try {
                const data = await dispatch(
                  get_service_cost_data(res?.payload?.data?.job_category_id)
                );
                if (data?.payload?.status === 200) {
                  toast.success("Service Cost Slot be created");
                  onCancelForm();
                }
              } catch (error) {
                errorMessage({
                  payload: res.payload,
                  setErrors: setErrors,
                  action: "Service Cost Slot",
                  msg: "created",
                });
              }
            } else {
              errorMessage({
                payload: res.payload,
                setErrors: setErrors,
                action: "Service Cost Slot",
                msg: "created",
              });
            }
          } catch (error) {
            errorMessage({
              payload: error,
              setErrors: setErrors,
              action: "Service Cost Slot",
              msg: "created",
            });
          }
        } else {
          try {
            const res = await dispatch(update_service_cost(values));

            if (res?.payload?.status === 200) {
              formik.setFieldValue("service_cost_id", res?.payload?.data?._id);

              try {
                const data = await dispatch(
                  get_service_cost_data(
                    res?.payload?.data?.job_category_id?._id
                  )
                );
                if (data?.payload?.status === 200) {
                  toast.success("Service Cost Slot be updated");
                  onCancelForm();
                }
              } catch (error) {
                errorMessage({
                  payload: res.payload,
                  setErrors: setErrors,
                  action: "Service Cost Slot",
                  msg: "updated",
                });
              }
            } else {
              errorMessage({
                payload: res.payload,
                setErrors: setErrors,
                action: "Service Cost Slot",
                msg: "updated",
              });
            }
          } catch (error) {
            errorMessage({
              payload: error,
              setErrors: setErrors,
              action: "Service Cost Slot",
              msg: "updated",
            });
          }
        }
      } else {
        toast.error("Please wait until previous action be completed");
      }
    },
  });
  React.useEffect(() => {
    if (jobTypes.record?.length === 0) {
      dispatch(get_job_category());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Modal
        isUpdate={!!editServiceData}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={jobTypes?.isLoading}
        onClick={onCancelForm}
      >
        {jobTypes?.isLoading || serviceCost?.isLoading ? <DotsLoader /> : null}
        <form className="grid md:grid-cols-2 gap-x-5 mt-5">
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="job_category_id"
              label="Industry Categories"
              formik={formik}
              options={jobTypes?.record?.records}
              labelProp="name"
              valueProp="_id"
            />
          </div>
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="payment_type"
              label="Payment Type"
              formik={formik}
              options={[
                {
                  value: "COD",
                  label: "COD",
                },
                {
                  value: "INSURANCE",
                  label: "Insurance",
                },
              ]}
              labelProp="label"
              valueProp="value"
            />
          </div>

          <div className="mb-3">
            <FormInput
              errors={errors}
              name="start_time"
              label="Start Time"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="end_time"
              label="End Time"
              formik={formik}
            />
          </div>
          <FormInput
            errors={errors}
            name="la_zip"
            label="Zip Codes"
            formik={formik}
          />
        </form>
      </Modal>
    </>
  );
};

export default AddServiceSlot;
