import { DataGridPro } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";
import { Button } from "../../components";

function JobListing({ onCancelModal, data }) {
  const { isLoading, aooDataFilters } = useSelector(
    (state) => state.reports
  );
  const columnDefs = [
    {
      field: "Job #",
      renderCell: (params) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/jobs/update/${params.row._id}`}
          className="text-blue-700 hover:no-underline"
        >
          {params.row.job_number}
        </a>
      ),
      width: 60,
    },
    {
      headerName: "Created At",
      field: "createdAt",
      flex: 1,
      minWidth: 120,
      cellClassName: "multiline-cell",
    },

    { headerName: "Client", field: "name", lex: 1, minWidth: 200 },
    { headerName: "Industry", field: "industry_id", flex: 1, minWidth: 100 },
    { headerName: "Status", field: "jobStatus" },
    { headerName: "Phone", field: "phone", flex: 1, minWidth: 120 },
    { headerName: "Tech Name", field: "vendor_name", flex: 1, minWidth: 120 },
    { headerName: "Price", field: "service_cost", flex: 1, minWidth: 120 },
    {
      headerName: "Address",
      field: "complete_pickup_address",
      width: 180,
      wrap: true,
      cellClassName: "multiline-cell",
    },
  ];

  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    const data = params?.model;
    const numberOfLines = data?.complete_pickup_address
      ? Math.ceil(data?.complete_pickup_address?.length / 40)
      : 0;
    const addressHeight = rowHeight + numberOfLines * 30 + 20;
    // const numLines = data?.complete_pickup_address?.split(/,\s*/)?.length;;
    return addressHeight;
  };
  return (
    <div className="w-full h-screen z-[100] fixed top-0 left-0 opacity-100 bg-black/[0.75] flex items-center justify-center">
      <div className="relative">
      <div className="absolute top-0 right-0 p-2 z-20 -mt-6 -mr-6">
      <button
        onClick={onCancelModal}
        className="bg-white rounded-full text-gray-700 hover:text-red-500 p-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
      <div className="z-10 w-auto w-full md:max-w-[1224px] md:min-w-[730px] max-h-[calc(100vh-130px)] overflow-y-auto bg-neutral-100 p-3.5 border-[10px] border-white">
        <div className="pt-0 max-w-[1194px] h-full px-3.5 m-auto">
          <header className="bg-primary-100 h-[60px] flex items-center justify-center shadow-lg">
            <h1 className=" text-2xl text-white m-4 font-medium">
              {data?.length} Jobs
            </h1>
          </header>
          <div className="md:p-4">
            <div style={{ height: 425, width: 1111 }}>
              <DataGridPro
                rows={
                  isLoading
                    ? []
                    : data?.length > 0
                    ? data?.map((record, index) => {
                        const {
                          _id,
                          job_number,
                          name,
                          phone,
                          job_category_id,
                          createdBy,
                          createdAt,
                          complete_pickup_address,
                          scheduled_on,
                          job_status_id,
                          industry_id,
                          service_cost,
                        } = record;
                        const industry_name = aooDataFilters?.industries?.find(
                          ({ _id }) => _id === industry_id
                        )?.name;
                        const vendor_id=record?.vendor_id[0]
                        return {
                          _id,
                          counter: index + 1,
                          records: record,
                          job_number,
                          job_type: job_category_id?.name,
                          created_by: createdBy?.username,
                          name,
                          phone,
                          service_cost,
                          jobStatus: aooDataFilters?.job_statuses?.find(
                            ({ _id }) => _id === job_status_id
                          )?.name,
                          createdAt: dayjs(createdAt).format(
                            "ddd, MMM D, YYYY h:mm A"
                          ),
                          scheduled_on:
                            scheduled_on &&
                            dayjs(scheduled_on).format(
                              "ddd, MMM D, YYYY h:mm A"
                            ),
                          industry_id: industry_name,
                          complete_pickup_address,
                          vendor_name:`${vendor_id?.first_name?vendor_id?.first_name:""} ${vendor_id?.last_name?vendor_id?.last_name:""}`
                        };
                      })
                    : []
                }
                columns={columnDefs}
                getRowId={(row) => row?.records?._id}
                headerHeight={40} // Set the height of the header
                classes={{
                  columnHeader: "bg-[#f8f8f8] shadow-sm",
                  columnHeaderTitle:
                    "tableHead font-bold text-md capitalize px-0",
                  row: "text-md",
                }}
                getRowHeight={getRowHeight}
              />
            </div>
          </div>

          <div className="!p-4 text-right">
            <Button
              text="Cancel"
              className="mx-2"
              onClick={onCancelModal}
              variant="btn_cancel"
            />
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
export default JobListing;
