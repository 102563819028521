import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { FormInput, FormSelectInput, Loader } from "../../../../components";
const NewCreateBatchModal = ({ onClose, filters, totalItems }) => {
  const [isLoader, setIsLoader] = useState(false);
  const [payload, setPayload] = React.useState({
    is_enable: true,
    notification_email: "",
    schedule_value: "",
    schedule_type: "",
    batch_type: "domain_indexing",
  });
  async function createBatches() {
    if (!payload?.batches?.length) return toast.error("No keywords found");
    if (!filters || !filters?.rules?.length)
      return toast.error("Please select the filter");
    if (!payload?.notification_email) return toast.error("Email is required");
    if (!payload.short_note) return toast.error("Short Note is required");
    if (!payload?.batches?.filter(({ isSelected }) => !!isSelected)?.length)
      return toast.error("No batch selected");
    for (let batch of payload?.batches?.filter(
      ({ isSelected }) => !!isSelected
    )) {
      if (Object.values(batch)[0] === "") {
        toast.error("All selected batch names must be filled out.");
        return;
      }
    }
    const batchValues = payload?.batches
      ?.filter(({ isSelected }) => !!isSelected)
      ?.map((batch) => ({
        name: Object.values(batch)[0],
        size: batch.batch_count,
      }));
    const scheduleValue =
      payload?.schedule_type !== "manual" &&
      payload?.schedule_type === "minutes"
        ? payload.schedule_value
        : payload?.schedule_type !== "manual" &&
          payload?.schedule_type !== "minutes"
        ? [+payload.schedule_value]
        : null;
    delete payload.is_batch;
    setIsLoader(true);
    try {
      const response = await api.post("/api/domains/create_batches_new", {
        ...payload,
        schedule_value: scheduleValue,
        batches: batchValues,
        filters,
      });

      if (response.status === 200) {
        toast.success(`${response?.data || "Batches created"}`);
        setPayload({ ...payload, batches: [] });
        onClose();
      } else {
        toast.error(`${response?.data || "Batches couldn't be created"}`);
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
      toast.error(
        `${error?.response?.data?.message || "Batches couldn't be created"}`
      );
    }
  }
  const handleInputChange = (index, value) => {
    const updatedBatchNames = payload?.batches.map((batch, i) => {
      if (i === index) {
        const batchKey = Object.keys(batch)[0];
        return { ...batch, [batchKey]: value };
      }
      return batch;
    });
    setPayload({ ...payload, batches: updatedBatchNames });
  };
  const handleCheckboxChange = (index) => {
    const updatedBatchNames = payload?.batches.map((batch, i) => {
      if (i === index) {
        return { ...batch, isSelected: !batch?.isSelected };
      }
      return batch;
    });
    setPayload({ ...payload, batches: updatedBatchNames });
  };
  // const convertArrayToObject = (array) => {
  //   return array.reduce((acc, current) => {
  //     acc[current.field] = current.data;
  //     return acc;
  //   }, {});
  // };
  useEffect(() => {
    const updatePayload = () => {
      const totalItemsLength = totalItems || 0;
      const numberOfBatches = Math.ceil(totalItemsLength / 15000);
      const batchNames = [];
      for (let i = 1; i <= numberOfBatches; i++) {
        const startIndex = (i - 1) * 15000;
        const endIndex = Math.min(i * 15000, totalItemsLength);
        const batchCount = endIndex - startIndex;
        batchNames.push({
          [`batch_${i}`]: "",
          batch_count: batchCount,
          isSelected: true,
        });
      }
      setPayload({
        ...payload,
        batches: batchNames,
      });
    };
    updatePayload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const weeklyOptions = [
    { value: 0, label: "Sunday" },
    { value: 1, label: "Monday" },
    { value: 2, label: "Tuesday" },
    { value: 3, label: "Wednesday" },
    { value: 4, label: "Thursday" },
    { value: 5, label: "Friday" },
    { value: 6, label: "Saturday" },
  ];
  const daysInMonth = [];
  for (let i = 1; i < 31; i++) {
    const label = `${i}`;
    daysInMonth.push({ value: i, label });
  }
  const hoursInDay = [];

  for (let i = 0; i < 24; i++) {
    const hour = i % 12 === 0 ? 12 : i % 12; // Convert 0 to 12 for 12-hour clock
    const amPm = i < 12 ? "AM" : "PM";
    const label = `${hour} ${amPm}`;
    hoursInDay.push({ value: i, label });
  }
  return (
    <>
      <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40 bg-black/60">
        <div className="bg-white p-4 rounded-lg shadow-lg relative w-auto min-w-[600px]">
          <div className="flex items-center grid grid-cols-2 gap-x-4">
            <div className="mb-4 w-full">
              <FormSelectInput
                name="is_enable"
                label="Enabled"
                onChange={(value) =>
                  setPayload({ ...payload, is_enable: value })
                }
                values={payload.is_enable}
                options={[
                  { value: "true", label: "Yes" },
                  { value: "false", label: "No" },
                ]}
                convertor={(value) => value === "true"}
                valueProp="value"
                labelProp="label"
              />
            </div>

            <div className="mb-4 w-full">
              <FormInput
                name="notification_email"
                label="Notification Email"
                onChange={(e) =>
                  setPayload({ ...payload, notification_email: e.target.value })
                }
                value={payload?.notification_email}
              />
            </div>

            <div className="mb-4">
              <FormSelectInput
                name="schedule_type"
                label="Schedule Type"
                value={payload.schedule_type}
                onChange={(value) =>
                  setPayload({
                    ...payload,
                    schedule_type: value,
                    schedule_value: null,
                  })
                }
                options={[
                  { value: "monthly", label: "Monthly" },
                  { value: "weekly", label: "Weekly" },
                  { value: "daily", label: "Daily" },
                  { value: "minutes", label: "Minutes" },
                  { value: "manual", label: "Manual" },
                ]}
                valueProp="value"
                labelProp="label"
              />
            </div>
            {payload?.schedule_type !== "manual" &&
            payload?.schedule_type !== "minutes" ? (
              <div className="mb-4 w-full">
                <FormSelectInput
                  name="schedule_value"
                  label={`Schedule ${
                    payload?.schedule_type === "weekly"
                      ? "day for every week"
                      : payload?.schedule_type === "daily"
                      ? "hour for every day"
                      : payload?.schedule_type === "monthly"
                      ? "date for every month"
                      : []
                  }`}
                  value={payload.schedule_value}
                  onChange={(value) =>
                    setPayload({ ...payload, schedule_value: value })
                  }
                  options={
                    payload?.schedule_type === "weekly"
                      ? weeklyOptions
                      : payload?.schedule_type === "daily"
                      ? hoursInDay
                      : payload?.schedule_type === "monthly"
                      ? daysInMonth
                      : []
                  }
                  valueProp="value"
                  labelProp="label"
                  className="w-full"
                />
              </div>
            ) : payload?.schedule_type !== "manual" &&
              payload?.schedule_type === "minutes" ? (
              <div className="mb-4 w-full">
                <FormSelectInput
                  name="schedule_value"
                  label="Schedule Minutes"
                  value={payload.schedule_value}
                  onChange={(value) => {
                    setPayload({ ...payload, schedule_value: value });
                  }}
                  options={[
                    { value: "every_minute", label: "Every Minute" },
                    { value: "every_5_minutes", label: "Every 5 minutes" },
                    { value: "every_10_minutes", label: "Every 10 minutes" },
                    { value: "every_15_minutes", label: "Every 15 minutes" },
                    { value: "every_20_minutes", label: "Every 20 minutes" },
                    { value: "every_25_minutes", label: "Every 25 minutes" },
                    { value: "every_30_minutes", label: "Every 30 minutes" },
                    { value: "every_hour", label: "Every Hour" },
                  ]}
                  valueProp="value"
                  labelProp="label"
                />
              </div>
            ) : null}
          </div>
          <div
            key={"short_note"}
            className="flex w-full flex-col justify-between items-start mb-3 border-b-2 pb-2"
          >
            <label htmlFor={"short_note"} className="block font-semibold">
              Short Note
            </label>
            <input
              id={"short_note"}
              type="text"
              name="short_note"
              value={payload?.short_note}
              onChange={(e) =>
                setPayload({ ...payload, short_note: e.target.value })
              }
              placeholder="Short Note"
              className="h-[31px] w-full shado-sm appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline relative"
            />
          </div>
          <span className="block font-semibold">
            Total Keywords: {totalItems || 0}
          </span>
          {payload?.batches?.map((batch, index) => {
            const batchKey = Object.keys(batch)[0];
            return (
              <div
                key={batchKey}
                className="flex w-full flex-row grid grid-cols-10 justify-between items-center mb-1"
              >
                <div className="flex flex-row items-center col-span-2">
                  <input
                    className={`form-checkbox h-5 w-5 text-primary-100 rounded focus:ring-0 cursor-pointer mr-2`}
                    type="checkbox"
                    checked={batch?.isSelected}
                    onChange={(e) => handleCheckboxChange(index)}
                  />
                  <label htmlFor={batchKey} className="block font-semibold ">
                    {batchKey}
                  </label>
                </div>
                <input
                  id={batchKey}
                  type="text"
                  value={batch[batchKey]}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  placeholder="Batch Name"
                  className="h-[31px] w-full col-span-7 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline relative"
                />
                <span className="text-end">{batch?.batch_count}</span>
              </div>
            );
          })}
          <div className="flex justify-end !mt-4">
            {isLoader ? (
              <Loader />
            ) : (
              <>
                <button
                  className="px-4 py-2 mr-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  onClick={createBatches}
                >
                  OK
                </button>
                <button
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewCreateBatchModal;
