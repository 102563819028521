import api from "./api";

export const getAgentCallsByOverall = async (data) => {
  return await api.get('/api/reports/dashboard_summary_reports_by_vendor_call_compare_overall', {params:data})
}
export const getIndustryCallsByDate = async (data) => {
  return await api.get('/api/reports/dashboard_summary_reports_call_compare_by_date', {params:data})
}
export const getAgentCallsByDate = async (data) => {
  return await api.get('/api/reports/dashboard_summary_reports_by_vendor_call_compare_by_date', {params:data})
}
export const getVendorCallsByDate = async (data) => {
  return await api.get('/api/reports/dashboard_summary_reports_vendor_job_summary', {params:data})
}