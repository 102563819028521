const userPermission = (item) => {
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  if (user?.tasks?.length > 0 && user?.role) {
    if (
      user?.tasks?.some(
        (obj) => obj.name === item || user?.menu_permissions?.includes(item)
      )
    ) {
      return true;
    }
    return false;
  }else{
    return true;
  }
};
export default userPermission;
