import React from "react";
import { DotsLoader, Modal } from "../../components";
import Select from "react-select";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import { sideMenu } from "../../components/organisms/Sidebar/menu.config";
import SubRole from "./SubRole";
import api from "../../services/api";
import { get_roles } from "../../features/rolesSlice";
import { useDispatch } from "react-redux";
function NewSelectTaskForm({ editingRecord, onCancelForm, modalTitle, roles }) {
  const dispatch=useDispatch()
  const [selectedData, setSelectedData] = React.useState([]);
  const side_menus = [...sideMenu, { label: "Switch Office" }];
  const [searchText, setSearchText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [role_id, setRole_id] = React.useState();
  const assignTask = async () => {
    if (role_id) {
      setIsLoading(true);
      try {
        const res = await api.post(
          `/api/roles/${role_id}/update_role_permissions`,
          { menu_permissions: selectedData }
        );
        if (res?.status === 200) {
          toast.success("Permission updated");
          dispatch(get_roles());
          onCancelForm();
        } else {
          errorMessage({
            payload: res.data?.message,
            action: "Roles Permissions",
            msg: "assigned",
          });
        }
        setIsLoading(false);
      } catch (error) {
        console.error(
          "🚀  file: NewSelectTaskForm.jsx:101  assignTask ~ error:",
          error
        );
        setIsLoading(false);
        errorMessage({
          payload: error?.response?.data?.message,
          action: "Roles Permissions",
          msg: "assigned",
        });
      }
    } else {
      toast.error("Please Select a role");
    }
  };
  const tasks = side_menus?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        // searchInput &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const onSingleselect = (item) => {
    if (!role_id) return toast.error("Please select the role");
    setSelectedData(handleCheckboxSelection(item));
  };
  
  function handleCheckboxSelection(label) {
    const findMenuItem = (menu, currentLabel) => {
      for (const item of menu) {
        if (item.label === currentLabel) {
          return item;
        } else if (item.children) {
          const foundInChildren = findMenuItem(item.children, currentLabel);
          if (foundInChildren) {
            return foundInChildren;
          }
        }
      }
      return null;
    };

    const menuItem = findMenuItem(side_menus, label);

    if (!menuItem) {
      console.error("Menu item not found");
      return selectedData;
    }

    const getParentLabel = (menu, currentLabel) => {
      const parentItem = menu.find((item) => {
        if (
          item.children &&
          item.children.some((child) => child.label === currentLabel)
        ) {
          return true;
        }
        return false;
      });
      return parentItem ? parentItem.label : null;
    };

    const getDescendantLabels = (menu, currentLabel) => {
      const foundItem = findMenuItem(menu, currentLabel);
      if (!foundItem) {
        return [];
      }

      const descendantLabels = [currentLabel];
      if (foundItem.children) {
        for (const child of foundItem.children) {
          descendantLabels.push(
            ...getDescendantLabels(foundItem.children, child.label)
          );
        }
      }
      return descendantLabels;
    };

    const parentLabel = getParentLabel(side_menus, label);
    const descendantLabels = getDescendantLabels(side_menus, label);

    if (menuItem.children) {
      if (selectedData.includes(label)) {
        // Deselecting checkbox for a parent item
        const remainingSelectedLabels = selectedData.filter(
          (selectedLabel) => !descendantLabels.includes(selectedLabel)
        );

        // If all children and sub-children are deselected, remove the parent label too
        if (
          remainingSelectedLabels.includes(label) &&
          parentLabel &&
          !remainingSelectedLabels.some(
            (selectedLabel) =>
              getParentLabel(side_menus, selectedLabel) === parentLabel
          )
        ) {
          remainingSelectedLabels.push(parentLabel);
        }

        return remainingSelectedLabels.filter(
          (selectedLabel) => selectedLabel !== label
        );
      } else {
        // Selecting checkbox for a parent item
        const updatedSelectedLabels = Array.from(
          new Set([...selectedData, label, ...descendantLabels])
        );

        // If any child or sub-child is selected, select the parent label
        if (parentLabel && !updatedSelectedLabels.includes(parentLabel)) {
          updatedSelectedLabels.push(parentLabel);
        }

        return updatedSelectedLabels;
      }
    } else {
      // Simple label without children
      if (selectedData.includes(label)) {
        const remainingLabels = selectedData.filter(
          (selectedLabel) => !descendantLabels.includes(selectedLabel)
        );

        // If a child or sub-child label is deselected and no labels of its parent remain, remove the parent label
        if (
          parentLabel &&
          !remainingLabels.some(
            (selectedLabel) =>
              getParentLabel(side_menus, selectedLabel) === parentLabel
          )
        ) {
          return remainingLabels.filter(
            (selectedLabel) => selectedLabel !== parentLabel
          );
        }

        return remainingLabels;
      } else {
        // If a child or sub-child label is selected, select its parent label
        const updatedSelectedLabels = [...selectedData, label];

        // Add descendant labels only if the label has children or sub-children
        if (descendantLabels.length > 1) {
          updatedSelectedLabels.push(...descendantLabels.slice(1));
        }

        // Add parent label if it exists and is not already in the list
        if (parentLabel && !updatedSelectedLabels.includes(parentLabel)) {
          updatedSelectedLabels.push(parentLabel);
        }

        return updatedSelectedLabels;
      }
    }
  }
  function handleSelectAll() {
    const allLabels = getAllLabels();
    // Check if all labels are already selected
    const allLabelsSelected = allLabels.every((label) =>
      selectedData.includes(label)
    );
    if (allLabelsSelected) {
      // If all labels are selected, remove all labels
      return selectedData.filter((label) => !allLabels.includes(label));
    } else {
      // If not all labels are selected, select all labels
      return Array.from(new Set([...selectedData, ...allLabels]));
    }
  }

  function getAllLabels() {
    const labels = [];
    const collectLabels = (menu) => {
      for (const item of menu) {
        labels.push(item.label);

        if (item.children) {
          collectLabels(item.children);
        }
      }
    };

    collectLabels(side_menus);
    return labels;
  }
  function handleSelectAllCheckboxChange() {
    if (!role_id) return toast.error("Please select the role");
    const updatedSelectedData = handleSelectAll();
    // Update your state or perform any other necessary actions with updatedSelectedData
    setSelectedData(updatedSelectedData);
  }
  const isSelectAll = () => {
    const allLabels = getAllLabels();
    // Check if all labels are already selected
    const allLabelsSelected = allLabels.every((label) =>
      selectedData.includes(label)
    );
    if (allLabelsSelected) {
      // If all labels are selected, remove all labels
      return true;
    } else {
      // If not all labels are selected, select all labels
      return false;
    }
  };
  const handleRoleChange = (id) => {
    const selectedRole = roles?.find(({ _id }) => _id === id);
    if (selectedRole) {
      setRole_id(id);
      setSelectedData(selectedRole?.menu_permissions||[]);
    }
  };
  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        onSubmit={assignTask}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <div className="flex flex-row gap-x-2">
          <Select
            onChange={(e) => handleRoleChange(e?._id)}
            name="role_id"
            className="col-span-6 w-full"
            options={roles}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option._id}
            placeholder="Select Role"
          />
          <div className="relative w-full col-span-6 md:col-span-6 flex justify-end items-end">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                // aria-hidden="true"
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              className="h-[38px] bg-white border border-gray-300 text-gray-900 text-sm rounded-[0.2rem] w-full pl-12 p-2.5 "
              placeholder="Search"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="mt-2 h-[460px] overflow-y-auto">
          <div className="flex items-center gap-2 border-b py-2">
            <input
              className="h-5 w-5 focus:ring-0 cursor-pointer form-checkbox text-primary-100 rounded-sm"
              type={"checkbox"}
              checked={isSelectAll()}
              onClick={handleSelectAllCheckboxChange}
            />
            Select All
          </div>
          {tasks.map((item, key) => {
            const { label, children } = item;
            return (
              <div key={key}>
                {item?.children?.length ? (
                  <SubRole
                    children={children}
                    label={label}
                    onSingleselect={onSingleselect}
                    selectedData={selectedData}
                  />
                ) : (
                  <div
                    className="flex items-center gap-2 border-b py-2"
                    title={label}
                  >
                    <input
                      className="h-5 w-5 focus:ring-0 cursor-pointer form-checkbox text-primary-100 rounded-sm"
                      type={"checkbox"}
                      onChange={() => onSingleselect(label)}
                      checked={selectedData?.includes(label)}
                    />
                    <span>{label}</span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
}

export default NewSelectTaskForm;
