import React, { useEffect, useState } from "react";
import api from "../../services/api";
import PageHeader from "../../components/molecules/PageHeader";
import { DotsLoader } from "../../components";

function IndustryWiseStatus() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const get_industry_status_report = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`api/industries/business_status`);
      if (res.status === 200) {
        setData(res.data);
      }
    } catch (err) {
      console.log("👊 ~ constget_industry_status_report= ~ err:", err);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    get_industry_status_report();
  }, []);
  const renderDetailItem = (label, value) => (
    <p>
      <b className="text-[#828282] text-sm">{label ?? ""}:</b>{" "}
      <span className="text-sm font-semibold">{value ?? ""}</span>
    </p>
  );
  const renderHeaderItem = (label, value) => (
    <p>
      <b className="text-black text-xs">{label ?? ""}:</b>{" "}
      <span className="text-xs">{value ?? ""}</span>
    </p>
  );
  const renderItemHeader = (label, Icon) => (
    <div className="font-bold border-b border-neutral-300 pb-1 flex items-center gap-x-2 mb-1 text-black">
      {Icon ? <Icon size={18} /> : null}
      {label ?? ""}
    </div>
  );
  const renderItemFooter = (label, value) => (
    <div className="font-bold border-t w-full border-neutral-300 px-1 flex items-center mb-1 text-black justify-betwee">
      <span>{label ?? ""}</span>
      {": "}
      <span className="ml-2">{value ?? ""}</span>
    </div>
  );
  return (
    <div>
      <PageHeader
        heading=""
        breadcrumb="/reports/industry_wise_business_status"
      />
      {isLoading ? <DotsLoader /> : null}
      {data.map((item, index) => (
        <div
          key={item.industry_details._id}
          className={`bg-white dark:bg-gray-800 last:border-none dark:border-white/20 !py-0 mb-2 shadow-sm border border-neutral-200 mt-2`}
        >
          <details>
            <summary
              className={`px-6 !py-3 flex bg-neutral-50 items-center justify-between flex-wrap gap-2 dark:border-white/20`}
            >
              <div className="flex items-center flex-wrap text-sm gap-3 !text-black">
                <p className="font-bold text-black">{index + 1}.</p>
                {renderHeaderItem("Industry", item.industry_details.name)}
                {renderHeaderItem("Active Domain", item.activeDomainCount)}
                {renderHeaderItem(
                  "Active In Area Domains",
                  item.activeInAreaDomainCount
                )}
                {renderHeaderItem(
                  "Total Unique Domain Which have Leads",
                  item.domainGotLead
                )}
                {renderHeaderItem(
                  "Total Unique Domain Which DID Leads",
                  item.domainDidJob
                )}
                {renderHeaderItem(
                  "Total Revenue ($)",
                  item.totalRevenue?.toLocaleString()
                )}
              </div>
            </summary>
            <div className="px-6 py-3 grid grid-cols-4 gap-x-3">
              <div>
                {renderItemHeader("Position wise Domain Count")}
                {renderDetailItem(
                  "Position 101",
                  item.position101.domain_count
                )}
                {renderDetailItem(
                  "Position 50-99",
                  item.positionBetween50And99.domain_count
                )}
                {renderDetailItem(
                  "Position 20-49",
                  item.positionBetween20And49.domain_count
                )}
                {renderDetailItem(
                  "Position 10-19",
                  item.positionBetween10And19.domain_count
                )}
                {renderDetailItem(
                  "Position 4-9",
                  item.positionBetween4And9.domain_count
                )}

                {renderDetailItem(
                  "Position 1-3",
                  item.positionBetween1And3.domain_count
                )}
                {renderItemFooter(
                  "Total",
                  (
                    item.position101.domain_count +
                    item.positionBetween50And99.domain_count +
                    item.positionBetween20And49.domain_count +
                    item.positionBetween10And19.domain_count +
                    item.positionBetween4And9.domain_count +
                    item.positionBetween1And3.domain_count
                  )?.toLocaleString()
                )}
              </div>
              <div>
                {renderItemHeader("Position wise Lead Count")}
                {renderDetailItem("Position 101", item.position101.lead_count)}
                {renderDetailItem(
                  "Position 50-99",
                  item.positionBetween50And99.lead_count
                )}
                {renderDetailItem(
                  "Position 20-49",
                  item.positionBetween20And49.lead_count
                )}
                {renderDetailItem(
                  "Position 10-19",
                  item.positionBetween10And19.lead_count
                )}
                {renderDetailItem(
                  "Position 4-9",
                  item.positionBetween4And9.lead_count
                )}
                {renderDetailItem(
                  "Position 1-3",
                  item.positionBetween1And3.lead_count
                )}
                {renderItemFooter(
                  "Total",
                  (
                    item.position101.lead_count +
                    item.positionBetween50And99.lead_count +
                    item.positionBetween20And49.lead_count +
                    item.positionBetween10And19.lead_count +
                    item.positionBetween4And9.lead_count +
                    item.positionBetween1And3.lead_count
                  )?.toLocaleString()
                )}
              </div>
              <div>
                {renderItemHeader("Position wise Job Count")}
                {renderDetailItem("Position 101", item.position101.job_count)}

                {renderDetailItem(
                  "Position 50-99",
                  item.positionBetween50And99.job_count
                )}
                {renderDetailItem(
                  "Position 20-49",
                  item.positionBetween20And49.job_count
                )}
                {renderDetailItem(
                  "Position 10-19",
                  item.positionBetween10And19.job_count
                )}
                {renderDetailItem(
                  "Position 4-9",
                  item.positionBetween4And9.job_count
                )}
                {renderDetailItem(
                  "Position 1-3",
                  item.positionBetween1And3.job_count
                )}
                {renderItemFooter(
                  "Total",
                  (
                    item.position101.job_count +
                    item.positionBetween50And99.job_count +
                    item.positionBetween20And49.job_count +
                    item.positionBetween10And19.job_count +
                    item.positionBetween4And9.job_count +
                    item.positionBetween1And3.job_count
                  )?.toLocaleString()
                )}
              </div>
              {item?.jobStatusWithCount?.length > 6 ? (
                <div className="col-span-4 mt-2">
                  {renderItemHeader("Status wise Job Count")}
                  <div className="grid grid-cols-4">
                    {item.jobStatusWithCount.map((status) => (
                      <div key={status._id}>
                        {renderDetailItem(`${status.name}`, status.jobsCount)}
                      </div>
                    ))}
                  </div>
                  <div className="mt-2">
                    {renderItemFooter(
                      "Total",
                      item.jobStatusWithCount
                        .reduce((total, status) => total + status.jobsCount, 0)
                        ?.toLocaleString()
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  {renderItemHeader("Status wise Job Count")}
                  {item.jobStatusWithCount.map((status) => (
                    <div key={status._id}>
                      {renderDetailItem(`${status.name}`, status.jobsCount)}
                    </div>
                  ))}
                  <div className="mt-2">
                    {renderItemFooter(
                      "Total",
                      item.jobStatusWithCount
                        .reduce((total, status) => total + status.jobsCount, 0)
                        ?.toLocaleString()
                    )}
                  </div>
                </div>
              )}
              {/* {renderItemFooter(
                "Total",
                (
                  item.position101.domain_count +
                  item.positionBetween50And99.domain_count +
                  item.positionBetween20And49.domain_count +
                  item.positionBetween10And19.domain_count +
                  item.positionBetween4And9.domain_count +
                  item.positionBetween1And3.domain_count
                )?.toLocaleString()
              )}
              {renderItemFooter(
                "Total",
                (
                  item.position101.lead_count +
                  item.positionBetween50And99.lead_count +
                  item.positionBetween20And49.lead_count +
                  item.positionBetween10And19.lead_count +
                  item.positionBetween4And9.lead_count +
                  item.positionBetween1And3.lead_count
                )?.toLocaleString()
              )}
              {renderItemFooter(
                "Total",
                (
                  item.position101.job_count +
                  item.positionBetween50And99.job_count +
                  item.positionBetween20And49.job_count +
                  item.positionBetween10And19.job_count +
                  item.positionBetween4And9.job_count +
                  item.positionBetween1And3.job_count
                )?.toLocaleString()
              )}
              {renderItemFooter(
                "Total",
                item.jobStatusWithCount
                  .reduce((total, status) => total + status.jobsCount, 0)
                  ?.toLocaleString()
              )} */}
            </div>
          </details>
        </div>
      ))}
    </div>
  );
}

export default IndustryWiseStatus;
