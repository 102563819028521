import React from "react";
import { FaChevronDown } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { update_general_setting } from "../../../features/generalSettingSlice";
import InputForm from "./InputForms";
import SelectForm from "./SelectForm";
import { Button } from "../../../components";
import errorMessage from "../../../util/errorMessage";
function AdditionalInformation({ settings }) {
  let initialValues = {
    setting_type: "additionalInfo",
    card_number: "",
    exp_month: "",
    exp_year: "",
    cvv: "",
    zip_code: "",
  };

  React.useEffect(() => {
    setAdditionalInfo(settings[0]?.additionalInfo);
  }, [settings]);

  const dispatch = useDispatch();

  const [additionalInfo, setAdditionalInfo] = React.useState(initialValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setAdditionalInfo({
      ...additionalInfo,
      [name]: value,
    });
  };
  const handleSubmit = async () => {
    try {
      const res = await dispatch(
        update_general_setting(
          additionalInfo?.setting_type === "additionalInfo"
            ? additionalInfo
            : { ...additionalInfo, setting_type: "additionalInfo" }
        )
      );
      if (res?.payload?.status === 200) {
        toast.success("Additional Info successfully updated");
      } else {
        errorMessage({
          payload: res.payload,
          action: "Additional Info",
          msg: "updated",
        });
      }
    } catch (error) {
      console.error(
        "Error ~ file: AddUpdateForm.jsx ~ line 59 ~ onSubmit: ~ error",
        error
      );
      errorMessage({
        payload: error,
        action: "Additional Info",
        msg: "updated",
      });
    }
  };

  return (
    <details className="border mt-3 mb-3" open>
      <summary className="flex flex-row items-center text-normal px-2 font-semibold font-pop justify-between border-b hover:cursor-pointer  bg-gray-100 hover:bg-gray-300 py-2 px-1">
        <span>Additional Information</span>
        <FaChevronDown iconClass="fas fa-times" />
      </summary>
      <div className="flex flex-col p-2">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <div className="md:grid grid-cols-12 gap-x-2 mt-2">
                <div className="col-span-3  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="card_number"
                      placeholder="Card Number"
                      onChange={(e) => handleInputChange(e)}
                      value={additionalInfo?.card_number}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-span-3  md:mt-md-2">
                  <div className="mb-3">
                    <SelectForm
                      name="exp_month"
                      options={[
                        { label: "January", value: "1" },
                        { label: "February", value: "2" },
                        { label: "March", value: "3" },
                        { label: "April", value: "4" },
                        { label: "May", value: "5" },
                        { label: "June", value: "6" },
                        { label: "July", value: "7" },
                        { label: "August", value: "8" },
                        { label: "September", value: "9" },
                        { label: "October", value: "10" },
                        { label: "November", value: "11" },
                        { label: "December", value: "12" },
                      ]}
                      label="CC Expire Month"
                      type="text"
                      labelProp="label"
                      valueProp="value"
                      value={additionalInfo?.exp_month}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                </div>

                <div className="col-span-3  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="exp_year"
                      placeholder="CC Expire Year"
                      onChange={(e) => handleInputChange(e)}
                      value={additionalInfo?.exp_year}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-span-3  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="cvv"
                      placeholder="CVV"
                      onChange={(e) => handleInputChange(e)}
                      value={additionalInfo?.cvv}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-span-3  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="zip_code"
                      placeholder="Zip Code"
                      onChange={(e) => handleInputChange(e)}
                      value={additionalInfo?.zip_code}
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <Button text="Save" variant="btn_submit" onClick={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
    </details>
  );
}

export default AdditionalInformation;
