import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DotsLoader, Modal } from "../../components";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { get_child_geo_vendors } from "../../features/GeoVendorsSlice";

function ChildVendors({ onCancelForm, modalTitle, id }) {
  const { isLoading, child } = useSelector((state) => state.geoVendors);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(get_child_geo_vendors(id));
    // eslint-disable-next-line
  }, []);
  const vendors = child?.filter((vendor) =>
    vendor?.name?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 80 },
    { headerName: "Vendor Name", field: "name", flex: 1, minWidth: 200 },
    { headerName: "Phone", field: "phone", flex: 1, minWidth: 200 },
    { headerName: "State", field: "state_id", flex: 1, minWidth: 100 },
    { headerName: "City", field: "city_id", flex: 1, minWidth: 100 },
    { headerName: "Zip Code", field: "zip_id", flex: 1, minWidth: 100 },
    { headerName: "Description", field: "description", flex: 1, minWidth: 200 },
  ];
  return (
    <Modal
      title={modalTitle}
      onCancelModal={onCancelForm}
      CloseOnly
      hideButtons
      isLoading={isLoading}
      onClick={onCancelForm}
    >
      {isLoading && <DotsLoader />}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={vendors?.map((record, index) => {
            let counter = index + 1;
            let name = record?.name;
            let phone = record?.phone;
            let state_id = record?.state_id?.name;
            let city_id = record.city_id?.name;
            let description = record?.description;
            let zip_id = record?.zip_id?.zip;
            let search_log_count = record?.search_log_count;
            let tech_count = record?.tech_count;
            return {
              counter,
              records: { ...record, _id: counter },
              name,
              phone,
              state_id,
              city_id,
              description,
              zip_id,
              search_log_count,
              tech_count,
            };
          })}
          totalItems={child?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          paginationMode={"client"}
          pagination="No"
          height={400}
        />
      </div>
    </Modal>
  );
}

export default ChildVendors;
