import React, { useState, useEffect } from "react";
import { DotsLoader } from "../../../components";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { FaInfoCircle } from "react-icons/fa";
import CustomModal from "../../../components/molecules/Modal/customModal";
import { IoCloseOutline } from "react-icons/io5";
const AddUpdateToGscServer = ({
  onCancelForm,
  modalTitle,
  selectedData,
  gscEmaiList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [filteredDomains, setNewFilterdDomains] = useState(selectedData);
  const [selectedEmailId, setSelectedEmailId] = useState("");
  const [gscServerDomains, setGscServerDomains] = useState([]);
  const [error, setError] = useState({});
  const getGscServerDomains = async (selectedEmailId) => {
    setIsLoading(true);
    try {
      const res = await api.get(`/api/gsc/server/domains/${selectedEmailId}`);
      if (res.status === 200 || res.status === 201) {
        setGscServerDomains(res?.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const submitGscData = async () => {
    const error = {};
    setIsLoading(true);
    if (!selectedEmailId) {
      error.GSC_server_id = "Email is required.";
    }
    if (Object.keys(error).length > 0) {
      setError(error);
      setIsLoading(false);
      return;
    } else {
      setError({});
    }
    if (!filteredDomains?.length) {
      toast.error("Domains not selected!");
      setIsLoading(false);
      return;
    }
    try {
      const payload = {
        GSC_server_id: selectedEmailId,
        domains: filteredDomains,
      };
      const res = await api.post(
        "/api/domains/attach_GSC_server",
        payload
      );
      if (res.status === 200 || res.status === 201) {
        toast.success("Record created successfully.");
        onCancelForm();
      } else {
        toast.error("Record couldn't created");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error || "Record couldn't created");
      } else {
        toast.error(err?.response?.data);
        return;
      }
      console.log(err);
    }
  };
  const handleChange = (event) => {
    const selectedId = event.target.value;
    const selected = gscEmaiList.find((email) => email._id === selectedId);
    setSelectedEmailId(selectedId);
    if (selected) {
      getGscServerDomains(selected._id);
    }
  };

  useEffect(() => {
    getFilteredDomain();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gscServerDomains]);

  const getFilteredDomain = () => {
    const domainsFromGscServer = new Set(
      gscServerDomains.map((item) => item.domain)
    );
    const selectedDomains = selectedData.filter(
      (domain) => !domainsFromGscServer.has(domain)
    );
    setNewFilterdDomains(selectedDomains);
  };

  const handleRemoveNewDomain = (index) => {
    const updatedData = filteredDomains.filter((_, i) => i !== index);
    setNewFilterdDomains(updatedData);
  };

  const handleDomainDelete = async (domain) => {
    const c = window.confirm(`Are you sure you want to delete ${domain} from GSC server?`);
    if (!c) return;
    setIsLoading(true);
    try {
      const payload = {
        domain: domain
      };
      const res = await api.post(`/api/gsc/server/detach_domain`, payload);
      if (res.status === 200 || res.status === 201) {
        toast.success("Records deleted successfully");
      }
      setIsLoading(false);
    } catch (err) {
      toast.error("Records couldn't deleted");
      setIsLoading(false);
      console.log(err);
    }
    getGscServerDomains(selectedEmailId);
  };
  return (
    <>
      <CustomModal
        handleModal={onCancelForm}
        cancelButtonRef={onCancelForm}
        className="max-w-5xl"
        modalType={true}
        title={modalTitle}
        onSubmit={submitGscData}
      >
        {isLoading ? <DotsLoader /> : null}

        <div
          class="flex items-center p-4 mb-4 text-lg text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800"
          role="alert"
        >
          <FaInfoCircle />
          <div>
            <span class="font-medium ml-2">
              You have selected {filteredDomains?.length} new domains to update
              in GSC.
            </span>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-4 w-full">
          <div className="w-full">
            <select
              value={selectedEmailId}
              name="GSC_server_id"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded bg-white text-gray-700"
            >
              <option value="">Select an Email</option>
              {gscEmaiList?.map((email) => (
                <option key={email._id} value={email._id}>
                  {`${email.server_ip} | ${email.email} (${email.domain_count}/${email.domain_limit})`}
                </option>
              ))}
            </select>
            {error.GSC_server_id && (
              <div className="text-red-500">{error.GSC_server_id}</div>
            )}
          </div>
        </div>
        <div className="flex p-0 mt-2">
          <div
            className={`flex-1 bg-gray-100 p-4 shadow ${
              !gscServerDomains.length ? "w-full" : ""
            }`}
            style={{ maxHeight: "calc(60vh - 2rem)" }}
          >
            <h2 className="text-lg font-semibold mb-2 sticky top-0 bg-gray-100">
              New Domains ({filteredDomains?.length})
            </h2>
            <div
              className="overflow-y-auto"
              style={{ maxHeight: "calc(40vh - 2rem - 2.5rem)" }}
            >
              {filteredDomains?.map((domain, index) => (
                <div key={index} className="mb-1 text-blue-600">
                  {domain}
                  <button
                    type="button"
                    onClick={() => handleRemoveNewDomain(index)}
                    className="ml-2 p-1 rounded-full hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 text-black"
                  >
                    <IoCloseOutline size={18} />
                  </button>
                </div>
              ))}
            </div>
          </div>
          {gscServerDomains && gscServerDomains.length ? (
            <div
              className="flex-1 bg-gray-100 p-4 shadow ml-4"
              style={{ maxHeight: "calc(60vh - 2rem)" }}
            >
              <h2 className="text-lg font-semibold mb-2 sticky top-0 bg-gray-100">
                GSC Server Domains ({gscServerDomains?.length})
              </h2>
              <div
                className="overflow-y-auto"
                style={{ maxHeight: "calc(40vh - 2rem - 2.5rem)" }}
              >
                {gscServerDomains?.map((item, index) => (
                  <div key={item._id} className="mb-1 text-blue-600">
                    {item.domain}
                    <button
                      type="button"
                      onClick={() => handleDomainDelete(item?.domain)}
                      className="ml-2 p-1 rounded-full hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 text-black"
                    >
                      <IoCloseOutline size={18} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </CustomModal>
    </>
  );
};

export default AddUpdateToGscServer;
