import React from "react";
import { Button } from "../../components";
import Breadcrumbs from "../../components/molecules/Breadcrumbs";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import DateRangeComp from "../../components/molecules/DateRangePicker";

function CallDetailRecords() {
  // const [dateRange, setDateRange] = React.useState({
  //   to_date: null,
  //   from_date: null,
  // });
  const columnDefs = [
    {
      headerName: "Caller IDs",
      field: "counter",
      pinned: "right", // Make the column pinned to the left
        sortable: false,
    },
    { headerName: "Start Time", field: "lead_id", flex: 1, minWidth: 200 },
    { headerName: "Answered Time", field: "type", flex: 1, minWidth: 200 },
    { headerName: "Stop Time", field: "jobType", flex: 1, minWidth: 200 },
    { headerName: "Direction", field: "Direction", flex: 1, minWidth: 200 },
    { headerName: "Talk Time", field: "Talk", flex: 1, minWidth: 200 },
    { headerName: "Call time", field: "Call", flex: 1, minWidth: 200 },
    { headerName: "Caller", field: "Caller", flex: 1, minWidth: 200 },
    { headerName: "Callee", field: "Callee", flex: 1, minWidth: 200 },
    { headerName: "Caller Name", field: "lerN", flex: 1, minWidth: 200 },
    { headerName: "Callee Name", field: "createdBy", flex: 1, minWidth: 200 },
    { headerName: "Ring Duration", field: "Ring", flex: 1, minWidth: 200 },
    { headerName: "Answered", field: "Answered", flex: 1, minWidth: 200 },
    { headerName: "Missed", field: "Missed", flex: 1, minWidth: 200 },
    { headerName: "Abandoned", field: "Abandoned", flex: 1, minWidth: 200 },
  ];
  return (
    <div className="!mt-2">
      <div className="flex flex-wrap w-full justify-start items-center gap-x-10">
        <Breadcrumbs />
        <DateRangeComp
          inputClassName="bg-[#e9ecef] text-sm rounded py-1 px-2 border border-[#ced4da]"
          pickerClassName="-translate-x-[ left-auto"
          dateRangePicker
          button
          onChange={(e) => {
            const start = new Date(e.selection.startDate.toDateString());
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            const end = new Date(e.selection.endDate.toDateString());
            end.setHours(23);
            end.setMinutes(59);
            end.setSeconds(59);
            // setDateRange({ from_date: start, to_date: end });
          }}
        />
      </div>
      <div className="flex flex-row justify-between !mt-3 gap-x-2">
        <div className="w-full ">
          <button className="border relative w-full text-left p-2">
            Search
          </button>
        </div>
        <Button text="Search" variant="btn_submit" />
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          row={[]}
          columnDefs={columnDefs}
          pagination="No"
          searchable="No"
          pinnedColumns={{
            left: ['counter'],
          }}
        />
      </div>
    </div>
  );
}

export default CallDetailRecords;
