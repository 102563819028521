import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import {
  delete_sms_template,
  get_sms_template,
} from "../../features/smsTemplateSlice";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { get_industry_drd } from "../../features/IndustrySlice";

const SMSTemplate = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.smsTemplate);
  const { industryDrd } = useSelector((state) => state.industry);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_sms_template(record_id));
        if (res?.payload?.status === 200) {
          toast.success("SMS Template deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "SMS Template",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          action: "SMS Template",
          msg: "deleted",
        });
      }
    }
  };

  useEffect(() => {
    dispatch(get_sms_template({ page: 1, size: paginationModel.pageSize }));
    dispatch(get_industry_drd())
    // eslint-disable-next-line
  }, []);
  const templates = record?.records?.filter(
    (template) =>
      template?.title?.toLowerCase().includes(searchText.toLowerCase()) ||
      template?.slug?.toLowerCase().includes(searchText.toLowerCase())
  );
  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    if (data?.industries && data?.industries.length > 1) {
      const tagHeight = 25; // height of each tag
      const numTags = data.industries.length;
      return rowHeight + tagHeight * numTags + cellContentHeight;
    }else{
      return rowHeight
    }
  };
  const TagsCellRenderer = (data) => {
    const tags = data?.value?.map((tag,index) => (
      <span
        key={tag}
        style={{
          margin: "2px",
          color: "black",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
        }}
      >
        {tag?.name?`${index+1}. ${tag?.name}`:`${index+1}- ${industryDrd?.find(({_id})=>_id===tag)?.name}`}
      </span>
    ));

    return (
      <div style={{ display: "flex", flexDirection:'column', width: "auto" }}>
        {tags}
      </div>
    );
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Title", field: "title", flex: 1 },
    {
      headerName: "Industries",
      field: "industries",
      renderCell: TagsCellRenderer,
      flex: 1,
      minWidth: 200,
    },
    { headerName: "Slug", field: "slug", flex: 1 },
    { headerName: "Default", field: "is_default" },
    { headerName: "Status", field: "status" },
    {
      field: "actions",
      renderCell: (params) =>
        params.row.records?.active ? (
          <div>
            {userPermission("Update SMS Template") && (
              <FaEdit
                onClick={openFormHandler(params.row.records)}
                className="my_navIcon"
                title="Update SMS Template"
              />
            )}
            {userPermission("Delete SMS Template") && (
              <FaTrashAlt
                onClick={deleteRecordHandler(params.row.records._id)}
                className="my_navIcon"
                title="Delete SMS Template"
              />
            )}
          </div>
        ) : null,
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_sms_template({
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add SMS template"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > SMS Templates"
        heading="SMS Templates Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add SMS Template")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={templates?.map((record, index) => {
            let counter = index + 1;
            let title = record.title;
            let slug = record.slug;
            let is_default = record.is_default ? "Yes" : "No";
            let status = record.active ? "Active" : "InActive";
            let industries = record.industries;
            return {
              records: record,
              counter,
              title,
              slug,
              is_default,
              status,
              industries
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          gridOptions={getRowHeight}
        />
      </div>
    </>
  );
};

export default SMSTemplate;
