import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiGetTimezones } from "../services";

const initialState = {
  isError: false,
  isLoading: false,
  timezones: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Time Zones
export const get_timezone = createAsyncThunk(
  "get_timezone",
  async (thunkAPI) => {
    try {
      return await apiGetTimezones();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const timezoneSlice = createSlice({
  name: "getTimeZone",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.timezones = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_timezone.pending, (state) => {
        state.isLoading = true;
        state.timezones = [];
      })
      .addCase(get_timezone.fulfilled, (state, action) => {
        state.isLoading = false;
        state.timezones = action.payload?.data;
      })
      .addCase(get_timezone.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.timezones = [];
      });
  },
});

export const { reset } = timezoneSlice.actions;
export default timezoneSlice.reducer;
