import React, { useEffect, useState } from "react";
import { Loader } from "../../../components";
import api from "../../../services/api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
function SaveFilterModal({ setIsFilterSave, onClose, filters, setFilters, filterData }) {
  const [loading, setLoading] = useState(false);
  const showCheckbox = filterData.some(item => item.is_default === true) ? false : true;
  const handleSaveFilters = async (formValues) => {
    const payload = {
      report_name: "INTELIQUENT_NUMBER_REPORT",
      data: { filters },
      note: formValues?.filter_name,
      ...(formValues?.is_default === true && {
        is_default: formValues.is_default,
      }),
    };
    setLoading(true);
    try {
      const res = await api.post(`api/report_filter_columns`, payload);
      if (res.status === 200) {
        toast.success(
          res?.data?.error || res?.data?.message || "Filter saved successfully"
        );
        setFilters(res?.data);
        onClose();
      } else {
        toast.error(
          res?.data?.error || res?.data?.message || "Filter couldn't saved"
        );
      }
      setLoading(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.error ||
          err?.response?.data?.message ||
          "Filter couldn't saved"
      );
      console.log("🚀 ~ getLatestPosition ~ err:", err);
      setLoading(false);
    }
  };

  const handleClick = async () => {
    const checkboxHtml = showCheckbox
      ? `<label>
          <input type="checkbox" id="swal-input2">
          Set to default
        </label>`
      : "";

    const { value: formValues } = await Swal.fire({
      title: "Save Selected Filters",
      html: `
        <input id="swal-input1" class="swal2-input" placeholder="Enter the Filter Name">
        ${checkboxHtml}
      `,
      focusConfirm: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      preConfirm: () => {
        const filter_name = document.getElementById("swal-input1").value;
        const is_default = showCheckbox
          ? document.getElementById("swal-input2").checked
          : false;
        if (!filter_name) {
          Swal.showValidationMessage("Please enter the filter name!");
        }
        return { filter_name, is_default };
      },
    });

    if (formValues) {
      const result = await Swal.fire({
        title: "Are you sure you want to perform this action?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      if (result.isConfirmed) {
        handleSaveFilters(formValues);
        setIsFilterSave(false);
        Swal.fire({
          icon: "success",
          title: "Your filter has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        setIsFilterSave(false);
      }
    } else {
      setIsFilterSave(false);
    }
  };

  useEffect(() => {
    handleClick();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex justify-end !mt-4">
          <Loader />
        </div>
      ) : null}
    </>
  );
}
export default SaveFilterModal;
