import React, { useEffect } from "react";
import { Button, DotsLoader } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  generalSettingReset,
  get_general_setting,
} from "../../features/generalSettingSlice";
import GeneralSetting from "./components/GeneralSetting";
import AdditionalInformation from "./components/AdditionalInformation";
import CallConfiguration from "./components/CallConfiguration";
import EmailConfiguration from "./components/EmailConfiguration";
import TwilioConfiguration from "./components/TwilioConfiguration";
import GoogleAPIKey from "./components/GoogleAPIKey";
import EventsSection from "./components/Events";
import { toast } from "react-toastify";
import { sync_twilio_dids } from "../../services/generalSettingService";

function GeneralSettings() {
  const { isLoading, settings } = useSelector((state) => state.generalSetting);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(get_general_setting());
    return () => {
      dispatch(generalSettingReset());
    };
    // eslint-disable-next-line
  }, []);
  const syncTwilioDids = async () => {
    try {
      const res = await sync_twilio_dids();
      if (res.status === 200) {
        toast.success("Sync Twilio DIDs Successfully");
      }
    } catch (err) {
      toast.success("Twilio DIDs couldn't be Synced");
    }
  };
  return (
    <div className="!p-2 bg-white rounded transition duration-[2s] ease-out ">
      {isLoading && <DotsLoader />}
      <div className="flex justify-end items-center mb-2">
        <Button
          text="Sync Twilio DIDs"
          variant="btn_submit"
          onClick={syncTwilioDids}
        />
      </div>
      <div className="!p-5 bg-white rounded transition duration-[2s] ease-out border">
        <GeneralSetting settings={settings} />

        <AdditionalInformation settings={settings} />

        <EventsSection settings={settings} />
      </div>

      <div className="grid md:grid-cols-2 gap-x-2">
        <div className="w-full">
          <CallConfiguration settings={settings} />
          <EmailConfiguration settings={settings} />
        </div>
        <div className="w-full">
          <TwilioConfiguration settings={settings} />
          <GoogleAPIKey settings={settings} />
        </div>
      </div>
    </div>
  );
}

export default GeneralSettings;
