import React, { useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import api from "../../../../services/api";
import { Loader } from "../../../../components";
const HrefDetailModal = ({
  onClose,
  sortingModel,
  queryOptions,
  setSelectedData,
  selectedData,
  getSERPDomainReport,
  paginationModel,
  detailType,
}) => {
  const [isLoader, setIsLoader] = useState(false);
  const [fetchDetails, setFetchDetails] = useState([]);
  const fetchKeywords = async () => {
    if (!queryOptions?.rules?.length)
      return toast.error("Please select the filters");
    const c = window.confirm(
      "Are you sure want to fetch the domains DR detail?"
    );
    if (!c) return;
    setIsLoader(true);
    try {
      const res = await api.post("/api/domains/fetch_domain_ahref_details", {
        filters: queryOptions,
        fetch_details: fetchDetails,
      });
      if (res.status === 200) {
        toast.success("Domain ahref detail fetched successfully");
        onClose()
        await getSERPDomainReport({
          ...sortingModel,
          filters: queryOptions,
          page: 1,
          size: paginationModel.pageSize,
        });
      } else {
        toast.error("Domain ahref detail couldn't be fetched");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error("Domain ahref detail couldn't be fetched");
      console.log("🚀 ~ fetchKeywords ~ err:", err);
    }
  };
  const fetchKeywordsForSelectedDomains = async () => {
    if (!selectedData?.length) return toast.error("Please select the domains");
    const c = window.confirm(
      "Are you sure want to fetch the domains DR detail?"
    );
    if (!c) return;
    const domains = selectedData?.map(({ domain }) => domain);
    setIsLoader(true);
    try {
      const res = await api.post("/api/domains/fetch_domain_ahref_details", {
        filters: {
          groupOp: "AND",
          rules: [
            {
              field: "domain",
              op: "isAnyOf",
              data: domains,
            },
          ],
        },
        fetch_details: fetchDetails,
      });
      if (res.status === 200) {
        toast.success("Domain ahref detail fetched successfully");
        await getSERPDomainReport({
          ...sortingModel,
          filters: queryOptions,
          page: 1,
          size: paginationModel.pageSize,
        });
        setSelectedData([]);
        onClose()
      } else {
        toast.error("Domain ahref detail couldn't be fetched");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error("Domain ahref detail couldn't be fetched");
      console.log("🚀 ~ fetchKeywords ~ err:", err);
    }
  };

  const handleConfirm = async () => {
    if (detailType === "by_filter") {
      fetchKeywords();
    } else if (detailType === "by_selected") {
      fetchKeywordsForSelectedDomains();
    }
  };
  const fetchDetailOptions = [
    { value: "ahref_domain_DR_status", label: "Ahref Domain DR Status" },
    {
      value: "ahref_domain_Backlink_status",
      label: "Ahref Domain Backlink Status",
    },
  ];
  return (
    <>
      <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40">
        <div className="bg-white p-8 rounded-lg shadow-lg relative w-[460px]">
          <h2 className="text-lg font-semibold mb-4">
            Fetch Ahref Detail -{" "}
            {detailType === "by_filter"
              ? "By Filters"
              : "for Selected Domains "}
          </h2>
          {/* <p className="mb-4">Are you sure you want to restart the application?</p> */}

          <div className=" !px-0.5 !mb-3">
            <label className="block">Select Fetch Detail Type:</label>
            <MultiSelect
              // styles={style}
              placeholder="Fetch Detail"
              options={fetchDetailOptions}
              optionLabel="label"
              optionValue="value"
              filter
              maxSelectedLabels={2}
              value={fetchDetails}
              onChange={(e) => setFetchDetails(e.value)}
              className="h-[38px] w-full !rounded-[4px] border !border-[#e5e7eb] hover:border-[#ced4da]"
            />
          </div>
          <div className="flex justify-end !mt-4">
            {isLoader ? (
              <Loader />
            ) : (
              <>
                <button
                  className="px-4 py-2 mr-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  onClick={handleConfirm}
                >
                  OK
                </button>
                <button
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HrefDetailModal;
