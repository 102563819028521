import React, { useEffect } from "react";
import { Button } from "../../../components";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import ReportHead from "../../../reports/Components/Header";
import Picker from "../../../reports/Components/Picker";
import Select from "react-select";
import { get_users_drd } from "../../../features/usersSlice";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { MultiSelect } from "primereact/multiselect";
import dayjs from "dayjs";
import {
  callCenterReset,
  get_agent_performance,
} from "../../../features/callCentersSlice";
import { get_campaigns } from "../../../features/campaignSlice";
import { Pie, Bar, Line } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import { FaPhone } from "react-icons/fa";
import DateRangeComp from "../../../components/molecules/DateRangePicker";
const MissedCallsReport = () => {
  const dispatch = useDispatch();
  const componentRef = React.useRef(null);
  const { isLoading, agentPerformance } = useSelector(
    (state) => state.callCenters
  );
  const { record } = useSelector((state) => state.campaigns);
  const { usersDrd } = useSelector((state) => state.users);
  const [searchText, setSearchText] = React.useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [dates, setDates] = React.useState({
    from_date: new Date().toISOString().slice(0, 10),
    to_date: new Date().toISOString().slice(0, 10),
  });
  const initialFilters = {
    campaign: [],
    user: [],
    archive: "N",
    start_date: new Date().toISOString().slice(0, 10),
    end_date: new Date().toISOString().slice(0, 10),
  };
  const [filters, setFilters] = React.useState(initialFilters);
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(get_campaigns());
    dispatch(get_users_drd());
    dispatch(get_agent_performance(filters));
    return () => {
      dispatch(callCenterReset());
    };
    // eslint-disable-next-line
  }, []);
  const columnDefs = [
    {
      headerName: "#",
      field: "counter",
      flex: 1,
      minWidth: 80,
      cellClassName: "multiline-cell",
    },
    { headerName: "Full Name", field: "full_name", flex: 1, minWidth: 100 },
    {
      headerName: "Login Time",
      field: "login_time",
      flex: 1,
      minWidth: 100,
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Total Calls",
      field: "calls",
      flex: 1,
      minWidth: 100,
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Incoming Calls",
      field: "inbound_calls",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Outgoing Calls",
      field: "outbound_calls",
      flex: 1,
      minWidth: 150,
    },
    { headerName: "Total Talk", field: "talk", flex: 1, minWidth: 100 },
    { headerName: "Average Talk", field: "avg_talk" },
    {
      headerName: "Inbound Average Talk",
      field: "inbound_avg_talk",
      flex: 1,
      minWidth: 150,
    },

    {
      headerName: "Outbound Average Talk",
      field: "outbound_avg_talk",
      flex: 1,
      minWidth: 150,
    },
    { headerName: "Pause Seconds", field: "pause_sec", flex: 1, minWidth: 100 },

    { headerName: "Wait Seconds", field: "wait_sec", flex: 1, minWidth: 100 },
    { headerName: "Dead Seconds", field: "dead_sec", flex: 1, minWidth: 100 },
    {
      headerName: "Dispo Seconds",
      field: "dispo_sec",
      flex: 1,
      minWidth: 100,
    },
  ];
  const exportOption = [
    {
      label: "Export CSV",
      value: "csv",
    },
  ];
  const jobTypeOptions = [
    { value: "Y", label: "Yes" },
    { value: "N", label: "No" },
  ];
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Login Report",
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_agent_performance({
        ...filters,
        page: +params.page + 1,
        size: params.pageSize,
        start_date: dayjs(dates?.from_date).format("YYYY-MM-DD"),
        end_date: dayjs(dates?.to_date).format("YYYY-MM-DD"),
      })
    );
  };
  const agentPerform = agentPerformance?.filter((record) =>
    record?.full_name?.toLowerCase().includes(searchText.toLowerCase())
  );
  const data = {
    labels: [
      "Total Calls",
      "Answered Calls",
      "Missed Calls",
      "30 Sec",
      "60 Sec",
      "90 Sec",
    ],
    datasets: [
      {
        data: [50, 40, 10, 5, 15, 20],
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true, // Allow chart to be responsive
    maintainAspectRatio: false, // Prevent chart from maintaining aspect ratio
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          usePointStyle: true,
        },
      },
      datalabels: {
        display: true, // Show datalabels
        color: "black",
        formatter: (value, context) => {
          return (
            (
              (value * 100) /
              context.dataset.data.reduce((a, b) => a + b, 0)
            ).toFixed(2) + "%"
          );
        },
        font: {
          weight: "bold",
        },
      },
    },
  };

  const barData = {
    labels: ["10-1", "10-3", "10-3", "10-4", "10-5", "10-6", "10-7"], // Dates on the x-axis
    datasets: [
      {
        label: "Total Calls",
        data: [50, 40, 30, 60, 65, 72, 89], // Values for Total Calls
        backgroundColor: "rgba(54, 162, 235, 1)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 2,
        pointRadius: 5,
        fill: false,
      },
      {
        label: "Answered Calls",
        data: [30, 20, 10, 35, 65, 42, 74], // Values for Answered Calls
        backgroundColor: "rgba(75, 192, 192, 1)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
        pointRadius: 5,
        fill: false,
      },
      {
        label: "Missed Calls",
        data: [20, 26, 33, 46, 32, 24, 10], // Values for Missed Calls
        backgroundColor: "rgba(255, 99, 132, 1)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 2,
        pointRadius: 5,
        fill: false,
      },
      {
        label: "30 Sec",
        data: [20, 13, 23, 32, 43, 54, 65], // Values for Missed Calls
        backgroundColor: "rgba(255, 206, 86, 1)",
        borderColor: "rgba(255, 206, 86, 1)",
        borderWidth: 2,
        pointRadius: 5,
        fill: false,
      },
      {
        label: "60 Sec",
        data: [32, 10, 13, 19, 28, 37, 46], // Values for Missed Calls
        backgroundColor: "rgba(153, 102, 255, 1)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 2,
        pointRadius: 5,
        fill: false,
      },
      {
        label: "90 Sec",
        data: [46, 29, 38, 47, 56, 66, 34], // Values for Missed Calls
        backgroundColor: "rgba(255, 159, 64, 1)",
        borderColor: "rgba(255, 159, 64, 1)",
        borderWidth: 2,
        pointRadius: 5,
        fill: false,
      },
    ],
  };

  const barOptions = {
    //  indexAxis: "y",
    scales: {
      xAxes: [
        {
          stacked: false, // To stack bars if necessary
        },
      ],
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          usePointStyle: true,
        },
      },
      datalabels: {
        display: true, // Show datalabels
        color: "black",
        formatter: (value, context) => {
          return (
            (
              (value * 100) /
              context.dataset.data.reduce((a, b) => a + b, 0)
            ).toFixed(2) + "%"
          );
        },
        font: {
          weight: "bold",
        },
      },
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) => {
          // Customize the title, e.g., show the date here
          const dateIndex = tooltipItem[0].index;
          return `Date: ${data.labels[dateIndex]}`;
        },
      },
    },
  };

  return (
    <>
      <div className="container-fluid">
        <ReportHead route="Reports > Missed Calls" heading="Login IP" />

        <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
          <div className="md:col-span-8">
            <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
              <div className="md:col-span-4 !px-0.5 !mb-3">
                <MultiSelect
                  // styles={style}
                  placeholder="Campaign"
                  options={record}
                  optionLabel="campaign_name"
                  optionValue="campaign_id"
                  filter
                  maxSelectedLabels={2}
                  value={filters.campaign}
                  onChange={(e) =>
                    setFilters({ ...filters, campaign: e.value })
                  }
                  className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                />
              </div>
              <div className="md:col-span-4 !px-0.5 !mb-3">
                <MultiSelect
                  // styles={style}
                  placeholder="Users"
                  options={usersDrd}
                  optionLabel="first_name"
                  optionValue="_id"
                  filter
                  maxSelectedLabels={2}
                  value={filters.user}
                  onChange={(e) => setFilters({ ...filters, user: e.value })}
                  className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                />
              </div>
              <div className="md:col-span-4 !px-0.5">
                <Select
                  // styles={style}
                  placeholder="Archive"
                  options={jobTypeOptions}
                  getOptionLabel={(options) => options.label}
                  getOptionValue={(options) => options.value}
                  onChange={(e) =>
                    setFilters({ ...filters, job_type: e.value })
                  }
                  className="w-full"
                  value={
                    filters.job_type
                      ? {
                          value: filters.job_type,
                          label: jobTypeOptions?.find(
                            ({ value }) => value === filters.job_type
                          )?.label,
                        }
                      : null
                  }
                />
              </div>

              <div className="md:col-span-4 !px-0.5">
                <Button
                  text="Search"
                  variant="btn_submit"
                  onClick={() =>
                    dispatch(
                      get_agent_performance({
                        ...filters,
                        start_date: dayjs(dates?.from_date).format(
                          "YYYY-MM-DD"
                        ),
                        end_date: dayjs(dates?.to_date).format("YYYY-MM-DD"),
                      })
                    )
                  }
                />
                <Button
                  className="ml-2"
                  text="Reset"
                  variant="btn_cancel"
                  onClick={() => {
                    setFilters(initialFilters);
                    dispatch(get_agent_performance(initialFilters));
                  }}
                />
              </div>
            </div>
          </div>

          <div className="md:col-span-4 !px-0.5">
            <Picker filterData={dates} setFiltersData={setDates} />
          </div>
        </div>
        <div className="grid grid-cols-2 mt-3">
          <div className="flex flex-col border shadow-md ">
            <div className="flex justify-between items-center p-2">
              <span className="text-lg font-semibold">
                Inbound Calls Compare View
              </span>

              <DateRangeComp
                dateRangePicker
                mainClass="!w-[50%]"
                inputClassName="form-control bg-white focus:shadow-none border-[#a9a9a9] focus:border-2 focus:border-[#a9a9a9]"
                pickerClassName="-translate-x-[30%]"
                onChange={onchange}
              />
            </div>

            <Bar data={barData} options={barOptions} />
          </div>
          <div className="flex flex-col border shadow-md ">
            <div className="flex justify-between items-center p-2">
              <span className="text-lg font-semibold">Inbound Calls</span>

              <DateRangeComp
                dateRangePicker
                mainClass="!w-[50%]"
                inputClassName="form-control bg-white focus:shadow-none border-[#a9a9a9] focus:border-2 focus:border-[#a9a9a9]"
                pickerClassName="-translate-x-[30%]"
                onChange={onchange}
              />
            </div>
            <div className="h-80 flex items-center justify-center">
              <Pie data={data} options={options} />
            </div>

            <div className="grid grid-cols-3 mt-3">
              <div className="flex flex-row w-full items-center p-1">
                <div className="rounded-full border flex justify-center items-center h-[40px] w-[40px] bg-[#36A2EB]/20">
                  <FaPhone color="#36A2EB" />
                </div>
                <div className="flex flex-col ml-2 w-auto">
                  <small className="font-pop text-lg font-bold">50</small>
                  <small className="font-pop">Total Calls</small>
                </div>
              </div>
              <div className="flex flex-row w-full items-center p-1">
                <div className="rounded-full border flex justify-center items-center h-[40px] w-[40px] bg-[#4BC0C0]/20">
                  <FaPhone color="#4BC0C0" />
                </div>
                <div className="flex flex-col ml-2 w-auto">
                  <small className="font-pop text-lg font-bold">40 (80%)</small>
                  <small className="font-pop">Answered Calls</small>
                </div>
              </div>
              <div className="flex flex-row w-full items-center p-1">
                <div className="rounded-full border flex justify-center items-center h-[40px] w-[40px] bg-[#FF6384]/20">
                  <FaPhone color="#FF6384" />
                </div>
                <div className="flex flex-col ml-2 w-auto">
                  <small className="font-pop text-lg font-bold">10 (20%)</small>
                  <small className="font-pop">Missed Calls</small>
                </div>
              </div>
              <div className="flex flex-row w-full items-center p-1">
                <div className="rounded-full border flex justify-center items-center h-[40px] w-[40px] bg-[#FFCE56]/20">
                  <FaPhone color="#FFCE56" />
                </div>
                <div className="flex flex-col ml-2 w-auto">
                  <small className="font-pop text-lg font-bold">5 (10%)</small>
                  <small className="font-pop">30 Seconds</small>
                </div>
              </div>
              <div className="flex flex-row w-full items-center p-1">
                <div className="rounded-full border flex justify-center items-center h-[40px] w-[40px] bg-[#9966FF]/20">
                  <FaPhone color="#9966FF" />
                </div>
                <div className="flex flex-col ml-2 w-auto">
                  <small className="font-pop text-lg font-bold">15 (30%)</small>
                  <small className="font-pop">60 Seconds</small>
                </div>
              </div>
              <div className="flex flex-row w-full items-center p-1">
                <div className="rounded-full border flex justify-center items-center h-[40px] w-[40px] bg-[#FF9F40]/20">
                  <FaPhone color="#FF9F40" />
                </div>
                <div className="flex flex-col ml-2 w-auto">
                  <small className="font-pop text-lg font-bold">20 (40%)</small>
                  <small className="font-pop">90 Seconds</small>
                </div>
              </div>
            </div>
          </div>

          <div className="h-96 flex flex-col border shadow-md ">
            <Line data={barData} options={barOptions} />
          </div>
        </div>

        <div className="bg-white my-3 border rounded">
          <MUIDataTable
            exportOption={exportOption}
            handlePrint={handlePrint}
            columnDefs={columnDefs}
            items={agentPerform?.map((record, index) => {
              const {
                avg_talk,
                calls,
                dead_sec,
                dispo_sec,
                full_name,
                inbound_avg_talk,
                inbound_calls,
                login_time,
                outbound_avg_talk,
                outbound_calls,
                pause_sec,
                talk,
                user,
                wait_sec,
              } = record;
              return {
                counter: index + 1,
                records: { ...record, _id: index + 1 },
                avg_talk,
                calls,
                dead_sec,
                dispo_sec,
                full_name,
                inbound_avg_talk,
                inbound_calls,
                login_time,
                outbound_avg_talk,
                outbound_calls,
                pause_sec,
                talk,
                user,
                wait_sec,
              };
            })}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            totalItems={agentPerformance?.length}
            searchText={searchText}
            setSearchText={setSearchText}
            paginationModel={paginationModel}
            isLoading={isLoading}
          />
        </div>
        <div className="d-none"></div>
      </div>
    </>
  );
};

export default MissedCallsReport;
