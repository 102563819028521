import React from "react";
import Breadcrumbs from "../../components/molecules/Breadcrumbs";
import DateRangeComp from "../../components/molecules/DateRangePicker";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Bar } from "react-chartjs-2";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
function CallsByDIDs() {
  // const [dateRange, setDateRange] = React.useState({
  //   to_date: null,
  //   from_date: null,
  // });
  // const [paginationModel, setPaginationModel] = React.useState({
  //   pageSize: 100,
  //   page: 1,
  // });
  const datas = {
    labels: [],
    previousDate: {
      label: "08/10/2019 - 09/30/2019",
      dataSet: [0],
    },
    currentDate: {
      label: "10/01/2019 - 11/20/2019",
      dataSet: [0],
    },
  };
  const columnDefs = [
    {
      headerName: "DID",
      field: "counter",
      pinned: "right", // Make the column pinned to the left
      sortable: false,
    },
    { headerName: "Extension", field: "lead_id", flex: 1, minWidth: 200 },
    { headerName: "Inbound", field: "type", flex: 1, minWidth: 200 },
    { headerName: "Answered", field: "jobType", flex: 1, minWidth: 200 },
    { headerName: "Missed", field: "Direction", flex: 1, minWidth: 200 },
    { headerName: "Abandoned", field: "Abandoned", flex: 1, minWidth: 200 },
    { headerName: "Avg Ring Time", field: "Ring", flex: 1, minWidth: 200 },
    { headerName: "Talk Time", field: "Talk", flex: 1, minWidth: 200 },
    { headerName: "Avg Talk Time", field: "Time", flex: 1, minWidth: 200 },
    { headerName: "Avg Wait Time", field: "Wait", flex: 1, minWidth: 200 },
    {
      headerName: "Avg Abandoned Time",
      field: "AbandonedTalk",
      flex: 1,
      minWidth: 200,
    },
  ];
  return (
    <div className="!mt-2">
      <div className="flex flex-wrap w-full justify-start items-center gap-x-10">
        <Breadcrumbs />
        <DateRangeComp
          inputClassName="bg-[#e9ecef] text-sm rounded py-1 px-2 border border-[#ced4da]"
          pickerClassName="-translate-x-[ left-auto"
          dateRangePicker
          button
          onChange={(e) => {
            const start = new Date(e.selection.startDate.toDateString());
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            const end = new Date(e.selection.endDate.toDateString());
            end.setHours(23);
            end.setMinutes(59);
            end.setSeconds(59);
            // setDateRange({ from_date: start, to_date: end });
          }}
        />
      </div>
      <div className="border !p-2 mt-3">
        <Tabs>
          <TabList className="!flex !flex-wrap !pl-0 react-tabs__tab-list !border-b-[#dee2e6]">
            <Tab
              selectedClassName="!bg-primary-100 text-white !border-2 !border-[#5f5f5f] !border-b-0"
              className="!flex !justify-between !rounded-t !mx-2 !py-2 bg-[#f9f9f9] border border-[#dee2e6] -bottom-px relative py-1.5 !px-3 cursor-pointer md:max-w-[23%]"
            >
              <p className="font mb-0 truncate w-full text-normal font-semibold">
                By Count
              </p>
            </Tab>
            <Tab
              selectedClassName="!bg-primary-100 text-white !border-2 !border-[#5f5f5f] !border-b-0"
              className="!flex !justify-between !rounded-t !mx-2 !py-2 bg-[#f9f9f9] border border-[#dee2e6] -bottom-px relative py-1.5 !px-3 cursor-pointer md:max-w-[23%]"
            >
              <p className="font mb-0 truncate w-full text-normal font-semibold">
                By Time
              </p>
            </Tab>
          </TabList>

          <TabPanel>
            <div>
              <Bar
                // pointStyle="star"
                data={{
                  labels: datas.labels,
                  responsive: true,
                  offset: true,
                  datasets: [
                    {
                      label: "Inbound Calls",
                      // pointStyle: "rounded",
                      backgroundColor: "#3d96bb",
                      barThickness: 30,
                      categoryPercentage: 1,
                      data: datas.previousDate.dataSet, //From API
                    },
                    {
                      label: "Answered Calls",
                      backgroundColor: "#48c7d3",
                      barThickness: 30,
                      categoryPercentage: 1,
                      // pointStyle: "rectangle",
                      data: datas.currentDate.dataSet, //From API
                    },
                    {
                      label: "Missed Calls",
                      backgroundColor: "#51d6aa",
                      barThickness: 30,
                      categoryPercentage: 1,
                      // pointStyle: "rectangle",
                      data: datas.currentDate.dataSet, //From API
                    },
                    {
                      label: "Abandoned Calls",
                      backgroundColor: "#235c6e",
                      barThickness: 30,
                      categoryPercentage: 1,
                      // pointStyle: "rectangle",
                      data: datas.currentDate.dataSet, //From API
                    },
                  ],
                }}
                height={320}
                options={{
                  offsetGridLines: true,
                  drawTicks: true,
                  layout: {
                    padding: {
                      top: 30,
                      right: 40,
                      bottom: 40,
                    },
                  },
                  plugins: {
                    legend: {
                      display: true,
                      position: "bottom", // Lowercase "left"
                      align: "center", // Lowercase "start"
                      labels: {
                        boxWidth: 15,
                        // usePointStyle: true,
                      },
                    },
                  },

                  responsive: true,
                  maintainAspectRatio: false,
                }}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div>
              <Bar
                // pointStyle="star"
                data={{
                  labels: datas.labels,
                  responsive: true,
                  offset: true,
                  datasets: [
                    {
                      label: "Total Talk Time Calls",
                      // pointStyle: "rounded",
                      backgroundColor: "#3d96bb",
                      barThickness: 30,
                      categoryPercentage: 1,
                      data: datas.previousDate.dataSet, //From API
                    },
                  ],
                }}
                height={320}
                options={{
                  offsetGridLines: true,
                  drawTicks: true,
                  layout: {
                    padding: {
                      top: 30,
                      right: 40,
                      bottom: 40,
                    },
                  },
                  plugins: {
                    legend: {
                      display: true,
                      position: "bottom", // Lowercase "left"
                      align: "center", // Lowercase "start"
                      labels: {
                        boxWidth: 15,
                        // usePointStyle: true,
                      },
                    },
                  },

                  responsive: true,
                  maintainAspectRatio: false,
                }}
              />
            </div>
          </TabPanel>
        </Tabs>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          row={[]}
          columnDefs={columnDefs}
          // paginationModel={paginationModel}
          pagination="No"
        />
      </div>
    </div>
  );
}

export default CallsByDIDs;
