import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { createGeoIndustry, deleteGeoIndustry, getGeoIndustry, getGeoIndustryDrd, getIndustryServiceArea, updateGeoIndustry } from "../services/GeoIndustryService";
const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
  record: [],
  industryDrd:[],
  service_area:[],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    // eslint-disable-next-line no-mixed-operators
    error?.response?.data?.error || 
    // eslint-disable-next-line no-mixed-operators
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Industry Data
export const get_geo_industry = createAsyncThunk(
  "get_geo_industry",
  async (thunkAPI) => {
    try {
      return await getGeoIndustry();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Industry Data
export const get_industry_service_area = createAsyncThunk(
  "get_industry_service_area",
  async (data,thunkAPI) => {
    try {
      return await getIndustryServiceArea(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create New Industry
export const get_geo_industry_drd = createAsyncThunk(
  "get_geo_industry_drd",
  async (thunkAPI) => {
    try {
      return await getGeoIndustryDrd();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Industry
export const create_geo_industry = createAsyncThunk(
  "create_geo_industry",
  async (data, thunkAPI) => {
    try {
      return await createGeoIndustry(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Industry
export const update_geo_industry = createAsyncThunk(
  "update_geo_industry",
  async (data, thunkAPI) => {
    try {
      return await updateGeoIndustry(data?.id, data?.name);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Industry
export const delete_geo_industry = createAsyncThunk(
  "delete_geo_industry",
  async (id, thunkAPI) => {
    try {
      return await deleteGeoIndustry(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const geoIndustrySlice = createSlice({
  name: "geo_industries",
  initialState,
  reducers: {
    geoIndustryReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.industryDrd=[];
      state.service_area=[];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_geo_industry.pending, (state,action) => {
        state.isLoading = true;
      })
      .addCase(get_geo_industry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data
      })
      .addCase(get_geo_industry.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(get_geo_industry_drd.pending, (state) => {
        state.isLoading = true;
        state.industryDrd = [];
      })
      .addCase(get_geo_industry_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.industryDrd = action.payload?.data;
      })
      .addCase(get_geo_industry_drd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(create_geo_industry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_geo_industry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.push(action.payload.data[0]);
      })
      .addCase(create_geo_industry.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_geo_industry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_geo_industry.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.findIndex(
          ({ id }) => id === action?.payload?.data[0]?.id
        );
        state.record[result] = {
          ...state.record[result],
          ...action.payload.data[0],
        };
      })
      .addCase(update_geo_industry.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_geo_industry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_geo_industry.fulfilled, (state, action) => {
        const result = state.record.findIndex(
          ({ id }) => id === action?.payload?.data?.id
        );
        state.record[result] = {
          ...state.record[result],
          ...action.payload.data,
        };
      })
      .addCase(delete_geo_industry.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_industry_service_area.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_industry_service_area.fulfilled, (state, action) => {
        state.isLoading = false;
        state.service_area=action.payload?.data;
      })
      .addCase(get_industry_service_area.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
export const { geoIndustryReset } = geoIndustrySlice.actions;
export default geoIndustrySlice.reducer;
