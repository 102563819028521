import api from "./api";

export const createTag = async ({ name, color }) => {
  return await api.post("/api/tags", { name, color });
};

export const updateTag = async (data) => {
  return await api.put("/api/tags", data);
};

export const getTags = async (data) => {
  return await api.get("/api/tags",{params: data});
};
export const getTagsDrd = async () => {
  return await api.get("/api/tags/drd");
};

export const deleteTag = async (id) => {
  return await api.delete("/api/tags", { data: { id } });
};
