import React from "react";
import { Pagination } from "@material-ui/lab";
function Paginations({
  offset,
  lastItemIndex,
  page,
  lastPageIndex,
  count,
  onChange,
  totalItems,
}) {
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-6 flex items-center justify-between">
        <div className="w-fit py-[5px] text-base font-normal font-mont text-heading my-2">
          Displaying {offset + 1}-{lastItemIndex ? lastItemIndex : 0} of{" "}
          {totalItems} result
        </div>
      </div>
      <div className="col-span-6 flex items-center justify-end">
        {lastPageIndex > 0 && (
          <Pagination
            color="primary"
            shape="rounded"
            size="medium"
            showFirstButton
            showLastButton
            count={count}
            page={page}
            onChange={onChange}
          />
        )}
      </div>
    </div>
  );
}

export default Paginations;
