import React, { useEffect, useState } from "react";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";
import api from "../../services/api";
import AddCardModal from "./AddCardModal";
import { useParams, useLocation } from "react-router-dom";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import dayjs from "dayjs";
import { TbListDetails } from "react-icons/tb";
import { Modal } from "../../components";
import ToggleSwitch from "../../components/molecules/ToggleButton";
import { userPermissionNew } from "../../util/userPermissionNew";

const UsersCardList = () => {
  const location = useLocation();
  const { userId } = useParams();
  const [record, setRecord] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCardModal, setIsCardModal] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [cardDetail, setCardDetail] = useState(null);

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsCardModal(true);
  };
  const cancelFormHandler = () => setIsCardModal(false);
  const handleFreezeUnfreeze = async (id, action) => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    setIsLoading(true);
    const msg =
      action === "freez_virtual_card"
        ? "freezed"
        : action === "unfreez_virtual_card"
        ? "unfreezed"
        : "";
    try {
      const res = await api.put(`/api/bill/${action}/${id}`);
      if (res.status === 200) {
        toast.success(`Card ${msg} successfully`);
        getVirtaulCardList()
      } else {
        toast.error(res?.data?.message || `Card couldn't be ${msg}`);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.message || `Card couldn't be ${msg}`);
      console.log("🚀 ~ handleConfirm ~ err:", err);
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false, width: 80 },
    { headerName: "Name", field: "name", flex: 1, minWidth: 150 },
    { headerName: "Last Four Digit", field: "lastFour", flex: 1 },
    { headerName: "Status", field: "status", flex: 1 },
    { headerName: "Type", field: "type", flex: 1 },
    { headerName: "Amount", field: "amount", flex: 1 },
    { headerName: "Spent", field: "spent", flex: 1 },
    { headerName: "Recurring", field: "recurring", flex: 1 },
    { headerName: "Valid Thru", field: "validThru", flex: 1 },
    {
      headerName: "Created Time",
      field: "createdTime",
      flex: 1,
      filterable: false,
    },
    userPermissionNew("Freeze/Unfreeze Card")
      ? {
          headerName: "Freeze/Unfreeze",
          field: "freeze",
          flex: 1,
          headerAlign: "center",
          minWidth: 130,
          renderCell: (params) => {
            return params.row.status === "ACTIVATED" || params.row.status === "FROZEN" ? (
              <ToggleSwitch
                checked={params.row?.status === "FROZEN"}
                unique_by={params?.row?.records?.id}
                onChange={() => {
                  if (params.row?.status === "FROZEN") {
                    handleFreezeUnfreeze(params?.row?.records?.id, "unfreez_virtual_card");
                  } else {
                    handleFreezeUnfreeze(params?.row?.records?.id, "freez_virtual_card");
                  }
                }}
              />
            ) : null;
          },
        }
      : null,
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex flex-row">
          <FaTrashAlt
            onClick={() => deleteRecordHandler(params.row.id)}
            className="my_navIcon"
            title="Delete Card"
          />
          <FaEdit
            onClick={openFormHandler(params.row.records)}
            className="my_navIcon"
            title="Update Card Type"
          />
          <TbListDetails
            onClick={() => getCardDetail(params.row.records)}
            className="my_navIcon"
            title="Card detail"
          />
        </div>
      ),
      width: 100,
      filterable: false,
    },
  ].filter(Boolean);

  const deleteRecordHandler = async (recordId) => {
    if (!window.confirm("Are you sure you want to delete the selected card?"))
      return;

    setIsLoading(true);
    try {
      const res = await api.delete(`/api/bill/delete_virtual_card/${recordId}`);
      if (res.status === 200 || res.status === 201) {
        toast.success(res?.data?.message || "Card deleted successfully");
        getVirtaulCardList();
      } else {
        toast.error(res?.data?.message || "Card couldn't be deleted");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || "Card couldn't be deleted");
    } finally {
      setIsLoading(false);
    }
  };
  const getCardDetail = async (record) => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `/api/bill/get_virtual_card_detail/${record?.id}`
      );
      if (res.status === 200 || res.status === 201) {
        setCardDetail({ detail: res.data, card: record });
      } else {
        toast.error(res?.data?.message || "Card detail couldn't be found");
      }
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Card detail couldn't be found"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getVirtaulCardList = async () => {
    setIsLoading(true);
    try {
      const payload = {
        userId: userId,
        budgetId: location?.state?.budgetId,
      };
      const res = await api.post("/api/bill/get_budget_user_cards", payload);
      if (res.status === 200 || res.status === 201) {
        setRecord(res.data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.error || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getVirtaulCardList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, location?.state?.budgetId]);

  const filteredRecords = record?.results?.filter((e) =>
    Object.keys(e).some((key) =>
      e[key]?.toString()?.toLowerCase()?.includes(searchText.toLowerCase())
    )
  );

  return (
    <>
      <PageHeader
        heading="Card Listings"
        onClick={openFormHandler(0)}
        isAllowed={true}
        buttonTitle="Add Card"
      />
      {isCardModal && (
        <AddCardModal
          modalTitle="Add Card"
          onCancelForm={cancelFormHandler}
          budgetId={location?.state?.budgetId}
          ownerId={userId}
          editingRecord={editingRecord}
          getVirtaulCardList={getVirtaulCardList}
        />
      )}
      {cardDetail ? (
        <Modal
          isUpdate={!!editingRecord}
          title={`Card detail of ${cardDetail?.card?.name || ""}`}
          onCancelModal={() => setCardDetail(null)}
          isLoading={isLoading}
          onClick={() => setCardDetail(null)}
          CloseOnly
          hideButtons
        >
          <div className="grid grid-col-1">
            <div className="flex">
              <span>
                <strong>Expiration Date:</strong>
              </span>
              <span className="ml-2">{cardDetail?.detail?.expirationDate}</span>
            </div>
            <div className="flex">
              <span>
                <strong>CVV:</strong>
              </span>
              <span className="ml-2">{cardDetail?.detail?.cvv}</span>
            </div>
            <div className="flex">
              <span>
                <strong>Card Number:</strong>
              </span>
              <span className="ml-2">{cardDetail?.detail?.cardNumber}</span>
            </div>
          </div>
        </Modal>
      ) : null}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={filteredRecords?.map((record, index) => {
            const {
              id,
              name,
              lastFour,
              status,
              type,
              recurring,
              createdTime,
              validThru,
              currentPeriod,
            } = record;
            return {
              counter: index + 1,
              records: { ...record },
              id,
              name,
              lastFour,
              status,
              type,
              recurring: recurring ? "Yes" : "No",
              createdTime: dayjs(createdTime).format("ddd, MMM D, YYYY h:mm A"),
              validThru,
              amount: currentPeriod?.limit,
              spent: currentPeriod?.spent,
            };
          })}
          totalItems={filteredRecords?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
          showCount="No"
          height={`calc(100vh - 280px)`}
        />
      </div>
    </>
  );
};

export default UsersCardList;
