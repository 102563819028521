import api from "./api";

export const getAssignedServiceZips = async (data) => {
  return await api.get(`https://api.cmsdeployer.com//api/domain-prime-time/vendor_industries/${data?.vendor_id}`,{params:{industry_id:data?.industry_id}});
};
export const createAssignedServiceZips = async (data) => {
  return await api.post("https://api.cmsdeployer.com/api/domain-prime-time/vendor_industries",data);
};
export const updateAssignedServiceZips = async (data) => {
  return await api.put(`https://api.cmsdeployer.com/api/domain-prime-time/vendor_industries/${data.id}`,data);
};
export const assignVendorIndustryLocations = async (data) => {
  return await api.post("/api/vendors/vendor_industries_locations",data);
}
export const updateVendorIndustryLocations = async (data) => {
  return await api.put(`/api/vendors/domain_prime_time/vendor_industries_locations`,data);
}