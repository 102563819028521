import React from "react";
import Breadcrumbs from "../../components/molecules/Breadcrumbs";
import DateRangeComp from "../../components/molecules/DateRangePicker";
// import faker from 'faker';
import "chart.js/auto";
import { Bar } from "react-chartjs-2";
// import {
//   startOfMonth,
//   endOfMonth,
//   addDays,
//   format,
//   startOfDay,
//   addHours,
//   addMonths,
// } from "date-fns";
// import 'react-chartjs-2/dist/react-chartjs-2.css';
function CompnaySummary() {
  const chartLabels=[
    "5 Tue",
    "6 Wed",
    "7 Thu",
    "8 Fri",
    "9 Sat"
];
  // const generateLabelsForDateRange = (startDate, endDate) => {
  //   const labels = [];
  //   const startMonth = format(startDate, "MMMM");
  //   const endMonth = format(endDate, "MMMM");
  //   const startYear = format(startDate, "yyyy");
  //   const endYear = format(endDate, "yyyy");

  //   const isSameMonth = startMonth === endMonth && startYear === endYear;

  //   if (isSameMonth) {
  //     const isSameDate = startDate.getDate() === endDate.getDate();
  //     const isSameWeek = isSameDate && startDate.getDay() === endDate.getDay();
  //     const isMoreThanOneWeek =
  //       isSameMonth &&
  //       !isSameDate &&
  //       endDate - startDate >= 7 * 24 * 60 * 60 * 1000;

  //     if (isSameDate) {
  //       // Same date, generate labels with 4-hour intervals
  //       let currentDate = startOfDay(startDate);
  //       const lastDate = startOfDay(endDate);

  //       while (currentDate <= endDate) {
  //         labels.push(format(currentDate, "H:mm"));
  //         currentDate = addHours(currentDate, 4); // Add 4 hours to get to the next interval
  //       }
  //     } else if (isSameWeek) {
  //       // Same week, generate labels with day names
  //       let currentDate = startOfDay(startDate);

  //       while (currentDate <= endDate) {
  //         labels.push(format(currentDate, "EEEE")); // Day of the week
  //         currentDate = addDays(currentDate, 1); // Add 1 day
  //       }
  //     } else if (isMoreThanOneWeek) {
  //       // More than 1 week, generate labels with 7-day intervals
  //       let currentDate = startOfDay(startDate);

  //       while (currentDate <= endDate) {
  //         labels.push(format(currentDate, "d EEE")); // Day of the month
  //         currentDate = addDays(currentDate, 7); // Add 7 days
  //       }
  //     } else {
  //       // Same month but different days, generate labels with day numbers
  //       let currentDate = startOfDay(startDate);

  //       while (currentDate <= endDate) {
  //         labels.push(format(currentDate, "d EEE")); // Day of the month
  //         currentDate = addDays(currentDate, 1); // Add 1 day
  //       }
  //     }
  //   } else {
  //     // Different months or years, generate labels for the start and end months
  //     // labels.push(startMonth); // Start with the name of the first month

  //     let currentMonth = startDate; // Initialize with the start date

  //     while (currentMonth <= endDate) {
  //       labels.push(format(currentMonth, "MMMM yyyy")); // Add the month and year label
  //       currentMonth = addMonths(currentMonth, 1); // Move to the next month
  //     }

  //     // labels.push(endMonth); // End with the name of the last month
  //   }

  //   return labels;
  // };

  // const [dateRange, setDateRange] = React.useState({
  //   to_date: null,
  //   from_date: null,
  // });
  // React.useEffect(() => {
  //   if (dateRange.from_date && dateRange.to_date) {
  //     setChartLabels(
  //       generateLabelsForDateRange(dateRange.from_date, dateRange.to_date)
  //     );
  //   }
  // }, [dateRange.to_date, dateRange.from_date]);
  // const options = {
  //   plugins: {
  //     title: {
  //       display: true,
  //       text: "Chart.js Bar Chart - Stacked",
  //     },
  //   },
  //   responsive: true,
  //   scales: {
  //     x: {
  //       stacked: true,
  //     },
  //     y: {
  //       stacked: true,
  //     },
  //   },
  // };
  // const labels = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  // ];
  // const data = {
  //   labels,
  //   // datasets: [
  //   //   {
  //   //     label: 'Dataset 1',
  //   //     data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
  //   //     backgroundColor: 'rgb(255, 99, 132)',
  //   //   },
  //   //   {
  //   //     label: 'Dataset 2',
  //   //     data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
  //   //     backgroundColor: 'rgb(75, 192, 192)',
  //   //   },
  //   //   {
  //   //     label: 'Dataset 3',
  //   //     data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
  //   //     backgroundColor: 'rgb(53, 162, 235)',
  //   //   },
  //   // ],
  // };
  // const config = {
  //   type: "bar",
  //   data: data,
  //   options: {
  //     plugins: {
  //       title: {
  //         display: true,
  //         text: "Chart.js Bar Chart - Stacked",
  //       },
  //     },
  //     responsive: true,
  //     scales: {
  //       x: {
  //         stacked: true,
  //       },
  //       y: {
  //         //   stacked: true
  //       },
  //     },
  //   },
  // };
  // const DATA_COUNT = 7;
  // const NUMBER_CFG = { count: DATA_COUNT, min: -100, max: 100 };
  // const labels2 = Utils.months({count: 7});
  const datas = {
    labels: chartLabels,
    previousDate: {
      label: "08/10/2019 - 09/30/2019",
      dataSet: [10000, 150000, 10000, 150000],
    },
    currentDate: {
      label: "10/01/2019 - 11/20/2019",
      dataSet: [10000, 225000, 10000, 225000],
    },
  };
  return (
    <div className="!mt-2">
      <div className="flex flex-wrap w-full justify-start items-center gap-x-10">
        <Breadcrumbs />
        
        <DateRangeComp
          inputClassName="bg-[#e9ecef] text-sm rounded py-1 px-2 border border-[#ced4da]"
          pickerClassName="-translate-x-[ left-auto"
          dateRangePicker
          button
          onChange={(e) => {
            const start = new Date(e.selection.startDate.toDateString());
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            const end = new Date(e.selection.endDate.toDateString());
            end.setHours(23);
            end.setMinutes(59);
            end.setSeconds(59);
            // setDateRange({ from_date: start, to_date: end });
          }}
        />
      </div>
      <div className="border !p-2 mt-3">
      <div className="flex-cols md:flex flex-wrap justify-between mx-0.5 border-b py-2">
          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[15%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Total Inbound</span>
            <span className="text-base font-medium font-pop">
              1
            </span>
          </div>
          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[15%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Total Outbound</span>
            <span className="text-base font-medium font-pop">
              17
            </span>
          </div>
          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[15%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Total Ext to Ext</span>
            <span className="text-base font-medium font-pop">
              0
            </span>
          </div>
          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[15%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Time Answered</span>
            <span className="text-base font-medium font-pop">
              0
            </span>
          </div>
          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[15%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Total Missed</span>
            <span className="text-base font-medium font-pop">
              0
            </span>
          </div>
          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[15%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Total Abandoned</span>
            <span className="text-base font-medium font-pop">
              0
            </span>
          </div>
          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[15%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Total Voicemails</span>
            <span className="text-base font-medium font-pop">
              0
            </span>
          </div>
        </div>
        <div>
          <Bar
            // pointStyle="star"
            data={{
              labels: datas.labels,
              responsive: true,
              offset: true,
              datasets: [
                {
                  label: "Total Call Time",
                  pointStyle: "rounded",
                  backgroundColor: "#6ED3FF",
                  barThickness: 30,
                  categoryPercentage: 1,
                  data: datas.previousDate.dataSet, //From API
                },
                {
                  label: "Total Talk Time",
                  backgroundColor: "#1497FF",
                  barThickness: 30,
                  categoryPercentage: 1,
                  pointStyle: "rounded",
                  data: datas.currentDate.dataSet, //From API
                },
              ],
            }}
            height={320}
            options={{
              offsetGridLines: true,
              drawTicks: true,
              layout: {
                padding: {
                  top: 30,
                  right: 40,
                  bottom: 40,
                },
              },
              plugins: {
                legend: {
                  display: true,
                  position: "left", // Lowercase "left"
                  align: "start", // Lowercase "start"
                  labels: {
                    usePointStyle: true,
                  },
                },
              },

              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  stacked: true,
                  ticks: {
                    padding: 5,
                  },
                  gridLines: {
                    display: false,
                  },
                },
                y: {
                  stacked: false,
                  gridLines: {
                    drawBorder: false,
                  },
                  ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 6,
                    padding: 20,
                    callback(n) {
                      if (n < 1e3) return n;
                      if (n >= 1e3) return +(n / 1e3).toFixed(1) + "K";
                    },
                  },
                },
              },
            }}
          />
        </div>
        <div className="grid grid-cols-4 gap-4">
          <div className="gap-y-3">
            <div className="text-base font-semibold text-[#5e6d7a]">
            Time Statistics
            </div>
            <div className="border p-2">
              <div className="flex flex-row">
                <input
                  className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
                  type="checkbox"
                />
                <div className="sc-bzPmhk iyuAcY">Total Call Time</div>
              </div>
              <div className="!ml-7 text-xl font-semibold font-pop">0:44:01</div>
            </div>

            <ul className="ml-0 pl-0 !mt-2">
              <li className="flex flex-row justify-between border p-1.5 !mt-2">
                <div className="flex flex-row">
                  <input
                    className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
                    type="checkbox"
                  />
                  <div className="text-sm">Total Talk Time</div>
                </div>
                <div className="text-sm font-bold">0:41:59</div>
              </li>
              <li className="flex flex-row justify-between border p-1.5 !mt-2">
                <div className="flex flex-row">
                  <input
                    className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
                    type="checkbox"
                  />
                  <div className="text-sm">Total Ring Time</div>
                </div>
                <div className="text-sm font-bold">0:01:31</div>
              </li>
              <li className="flex flex-row justify-between border p-1.5 !mt-2">
                <div className="flex flex-row">
                  <input
                    className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
                    type="checkbox"
                  />
                  <div className="text-sm">Total Abandoned Time</div>
                </div>
                <div className="text-sm font-bold">0:00:36</div>
              </li>
              <li className="flex flex-row justify-between border p-1.5 !mt-2">
                <div className="flex flex-row">
                  <input
                    className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
                    type="checkbox"

                  />
                  <div className="text-sm">Average Call Time</div>
                </div>
                <div className="text-sm font-bold">0:02:26</div>
              </li>

            </ul>
          </div>
          <div className="gap-y-3">
            <div className="text-base font-semibold text-[#5e6d7a]">
            Inbound Statistics
            </div>
            <div className="border p-2">
              <div className="flex flex-row">
                <input
                  className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
                  type="checkbox"
                />
                <div className="sc-bzPmhk iyuAcY">Total Inbound</div>
              </div>
              <div className="!ml-7 text-xl font-semibold font-pop">1</div>
            </div>

            <ul className="ml-0 pl-0 !mt-2">
              <li className="flex flex-row justify-between border p-1.5 !mt-2">
                <div className="flex flex-row">
                  <input
                    className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
                    type="checkbox"
                  />
                  <div className="text-sm">Total Inbound Answered</div>
                </div>
                <div className="text-sm font-bold">1</div>
              </li>
              <li className="flex flex-row justify-between border p-1.5 !mt-2">
                <div className="flex flex-row">
                  <input
                    className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
                    type="checkbox"
                  />
                  <div className="text-sm">Total Inbound Missed</div>
                </div>
                <div className="text-sm font-bold">0</div>
              </li>
              <li className="flex flex-row justify-between border p-1.5 !mt-2">
                <div className="flex flex-row">
                  <input
                    className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
                    type="checkbox"
                  />
                  <div className="text-sm">Total Inbound Abandoned</div>
                </div>
                <div className="text-sm font-bold">0</div>
              </li>

            </ul>
          </div>
          <div className="gap-y-3">
            <div className="text-base font-semibold text-[#5e6d7a]">
            Outbound Statistics
            </div>
            <div className="border p-2">
              <div className="flex flex-row">
                <input
                  className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
                />
                <div className="sc-bzPmhk iyuAcY">Total Outbound</div>
              </div>
              <div className="!ml-7 text-xl font-semibold font-pop">17</div>
            </div>

            <ul className="ml-0 pl-0 !mt-2">
              <li className="flex flex-row justify-between border p-1.5 !mt-2">
                <div className="flex flex-row">
                  <input
                    className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
                    type="checkbox"
                  />
                  <div className="text-sm">Total Outbound Answered</div>
                </div>
                <div className="text-sm font-bold">12</div>
              </li>
              <li className="flex flex-row justify-between border p-1.5 !mt-2">
                <div className="flex flex-row">
                  <input
                    className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
                    type="checkbox"
                  />
                  <div className="text-sm">Total Outbound Abandoned</div>
                </div>
                <div className="text-sm font-bold">5</div>
              </li>

            </ul>
          </div>
          <div className="gap-y-3">
            <div className="text-base font-semibold text-[#5e6d7a]">
            Ext to Ext Statistics
            </div>
            <div className="border p-2">
              <div className="flex flex-row">
                <input
                  className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
                  type="checkbox"
                />
                <div className="sc-bzPmhk iyuAcY">Total Ext to Ext</div>
              </div>
              <div className="!ml-7 text-xl font-semibold font-pop">0</div>
            </div>

            <ul className="ml-0 pl-0 !mt-2">
              <li className="flex flex-row justify-between border p-1.5 !mt-2">
                <div className="flex flex-row">
                  <input
                    className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
                    type="checkbox"
                  />
                  <div className="text-sm">Total Ext to Ext Answered</div>
                </div>
                <div className="text-sm font-bold">0</div>
              </li>
              <li className="flex flex-row justify-between border p-1.5 !mt-2">
                <div className="flex flex-row">
                  <input
                    className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
                    type="checkbox"
                  />
                  <div className="text-sm">Total Ext to Ext Missed</div>
                </div>
                <div className="text-sm font-bold">0</div>
              </li>
              <li className="flex flex-row justify-between border p-1.5 !mt-2">
                <div className="flex flex-row">
                  <input
                    className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
                    type="checkbox"
                  />
                  <div className="text-sm">Total Ext to Ext Abondoned</div>
                </div>
                <div className="text-sm font-bold">0</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompnaySummary;
