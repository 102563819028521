import React from "react";
import { DotsLoader, FormInput, Modal } from "../../components";

import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { create_industry, update_industry } from "../../features/IndustrySlice";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";

const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.industry);
  const [errors, setErrors] = React.useState([]);

  let initialValues = {
    name: "",
  };
  if (editingRecord) {
    const { name } = editingRecord;
    initialValues = {
      name,
    };
  }
  const formik = useFormik({
    initialValues,

    validateOnBlur: true,

    // validationSchema: Yup.object().shape({
    //   name: Yup.string().required("Name is required").trim(),
    // }),

    onSubmit: async (values) => {
      if (!editingRecord) {
        try {
          const res = await dispatch(create_industry(values?.name));
          if (res?.payload?.status === 200) {
            toast.success("Industry created");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Industry",
              msg: "created",
            });
          }
        } catch (error) {
          toast.error("Industry couldn't be created");
        }
      } else {
        try {
          const res = await dispatch(
            update_industry({ id: editingRecord?._id, name: values?.name })
          );
          if (res?.payload?.status === 200) {
            toast.success("Industry updated");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Industry",
              msg: "updated",
            });
          }
        } catch (error) {
          toast.error("Industry couldn't be updated");
        }
      }
    },
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="mt-4">
          <FormInput errors={errors} name="name" label="Name" formik={formik} />
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;
