import api from "./api";

export const getGeoLoc = async (data) => {
    return await api.get(`https://api.cmsdeployer.com/api/geo_locations`,{params:data});
  };
  export const getGeoLocDetail = async ({type,location}) => {
    return await api.get(`/api/vendors/domain_prime_time/get_location_details?type=${type}&location=${location}`);
  };
  // export const getValidLocations = async (data) => {
  //   return await api.post(`https://api.cmsdeployer.com/api/geo_locations/get_valid_locations`,data);
  // };
  export const getValidLocations = async (data) => {
    return await api.post(`/api/vendors/domain_prime_time/get_valid_locations`,data);
  };
  export const getGeoLocationDetail = async (data) => {
    return await api.post(`/api/vendors/domain_prime_time/get_location_detailsMYSQL`,data);
  };
  export const getGeoBulkLocationDetail = async (data) => {
    return await api.post(`https://api.cmsdeployer.com/api/geo_locations/get_bulk_location_details`,data);
  };

  export const getSearchLogsList = async (data) => {
    return await api.post(`https://api.cmsdeployer.com/api/domain-prime-time/search_logs_list`,data);
  };
  export const saveSearchLog = async (data) => {
    return await api.post(`https://api.cmsdeployer.com/api/domain-prime-time/save_search`,data);
  };
  export const updateSearchLog = async (data) => {
    return await api.put(`https://api.cmsdeployer.com/api/domain-prime-time/update_search_logs/${data?.id}`,data);
  };

  export const deleteSearchLog = async (id) => {
    return await api.delete(`https://api.cmsdeployer.com/api/domain-prime-time/delete_search_logs/${id}`);
  };
  export const getDistance = async (data) => {
    return await api.post(`https://api.cmsdeployer.com/api/geo_locations/get_distance`,data);
  };

  export const getStates = async (data) => {
    return await api.get(`/api/vendors/domain_prime_time/get_states`,{params:data});
  };
  export const getCities = async (data) => {
    return await api.get(`https://api.cmsdeployer.com/api/geo_locations/get_cities`,{params:data});
  };
  export const getScrubDomains = async (data) => {
    return await api.post(`https://api.cmsdeployer.com/api/geo_locations/scrub_domains`,data);
  };
  export const getDomainDataReport = async (data) => {
    return await api.post(`https://api.cmsdeployer.com/api/geo_locations/domain_data_report`,data);
  };
  export const getVendorsLocationReport = async (data) => {
    return await api.post(`https://api.cmsdeployer.com/api/geo_locations/get_vendor_industry_locations`,data);
  };
  export const getVendorByIndustry = async (id) => {
    return await api.get(`https://api.cmsdeployer.com/api/geo_locations/get_vendors_by_industry_id?industry_id=${id}`);
  };
  export const getScrubReport = async (data) => {
    return await api.post(`https://api.cmsdeployer.com/api/geo_locations/scrub_domains_report`,data);
  };

  export const getSearchLogDetail = async (data) => {
    return await api.get(`https://api.cmsdeployer.com/api/geo_locations/get_location_details_by_log_id/${data}`);
  };
  export const getSearchLogById = async (data) => {
    return await api.get(`https://api.cmsdeployer.com/api/geo_locations/get_search_log_details_by_log_id/${data}`);
  };
  export const assignVendors = async (data) => {
    return await api.post(`https://api.cmsdeployer.com/api/geo_locations/update_search_log_details`,data);
  };
  export const deleteLocation = async (id) => {
    return await api.delete(`https://api.cmsdeployer.com/api/domain-prime-time/delete_search_log_deta/${id}`);
  };
  export const getAssignedDomain = async (id) => {
    return await api.get(`https://api.cmsdeployer.com/api/domain-prime-time/search_log_detail_domains/${id}`);
  };
  