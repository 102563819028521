import React, { useEffect } from "react";
import { Button } from "../../../components";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import ReportHead from "../../../reports/Components/Header";
import Picker from "../../../reports/Components/Picker";
import Select from "react-select";
import { get_users_drd } from "../../../features/usersSlice";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { MultiSelect } from "primereact/multiselect";
import dayjs from "dayjs";
import {
  callCenterReset,
  get_agent_performance,
} from "../../../features/callCentersSlice";
import { get_campaigns } from "../../../features/campaignSlice";
const AgentPerformance = () => {
  const dispatch = useDispatch();
  const componentRef = React.useRef(null);
  const { isLoading, agentPerformance } = useSelector(
    (state) => state.callCenters
  );
  const { record } = useSelector((state) => state.campaigns);
  const { usersDrd } = useSelector((state) => state.users);
  const [searchText, setSearchText] = React.useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [dates, setDates] = React.useState({
    from_date: new Date().toISOString().slice(0, 10),
    to_date: new Date().toISOString().slice(0, 10),
  });
  const initialFilters = {
    campaign: [],
    user: [],
    archive: "N",
    start_date: new Date().toISOString().slice(0, 10),
    end_date: new Date().toISOString().slice(0, 10),
  };
  const [filters, setFilters] = React.useState(initialFilters);
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(get_campaigns());
    dispatch(get_users_drd());
    dispatch(get_agent_performance(filters));
    return () => {
      dispatch(callCenterReset());
    };
    // eslint-disable-next-line
  }, []);
  const columnDefs = [
    {
      headerName: "#",
      field: "counter",
      flex: 1,
      minWidth: 80,
      cellClassName: "multiline-cell",
    },
    { headerName: "Full Name", field: "full_name", flex: 1, minWidth: 100 },
    {
      headerName: "Login Time",
      field: "login_time",
      flex: 1,
      minWidth: 100,
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Total Calls",
      field: "calls",
      flex: 1,
      minWidth: 100,
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Incoming Calls",
      field: "inbound_calls",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Outgoing Calls",
      field: "outbound_calls",
      flex: 1,
      minWidth: 150,
    },
    { headerName: "Total Talk", field: "talk", flex: 1, minWidth: 100 },
    { headerName: "Average Talk", field: "avg_talk" },
    {
      headerName: "Inbound Average Talk",
      field: "inbound_avg_talk",
      flex: 1,
      minWidth: 150,
    },

    {
      headerName: "Outbound Average Talk",
      field: "outbound_avg_talk",
      flex: 1,
      minWidth: 150,
    },
    { headerName: "Pause Seconds", field: "pause_sec", flex: 1, minWidth: 100 },

    { headerName: "Wait Seconds", field: "wait_sec", flex: 1, minWidth: 100 },
    { headerName: "Dead Seconds", field: "dead_sec", flex: 1, minWidth: 100 },
    {
      headerName: "Dispo Seconds",
      field: "dispo_sec",
      flex: 1,
      minWidth: 100,
    },
  ];
  const exportOption = [
    {
      label: "Export CSV",
      value: "csv",
    },
  ];
  const jobTypeOptions = [
    { value: "Y", label: "Yes" },
    { value: "N", label: "No" },
  ];
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Login Report",
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_agent_performance({
        ...filters,
        page: +params.page + 1,
        size: params.pageSize,
        start_date: dayjs(dates?.from_date).format("YYYY-MM-DD"),
        end_date: dayjs(dates?.to_date).format("YYYY-MM-DD"),
      })
    );
  };
  const agentPerform = agentPerformance?.filter((record) =>
    record?.full_name?.toLowerCase().includes(searchText.toLowerCase())
  );
  return (
    <>
      <div className="container-fluid">
        <ReportHead route="Reports > Agent Performance" heading="Login IP" />

        <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
          <div className="md:col-span-8">
            <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
              <div className="md:col-span-4 !px-0.5 !mb-3">
                <MultiSelect
                  // styles={style}
                  placeholder="Campaign"
                  options={record}
                  optionLabel="campaign_name"
                  optionValue="campaign_id"
                  filter
                  maxSelectedLabels={2}
                  value={filters.campaign}
                  onChange={(e) =>
                    setFilters({ ...filters, campaign: e.value })
                  }
                  className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                />
              </div>
              <div className="md:col-span-4 !px-0.5 !mb-3">
                <MultiSelect
                  // styles={style}
                  placeholder="Users"
                  options={usersDrd}
                  optionLabel="first_name"
                  optionValue="_id"
                  filter
                  maxSelectedLabels={2}
                  value={filters.user}
                  onChange={(e) => setFilters({ ...filters, user: e.value })}
                  className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                />
              </div>
              <div className="md:col-span-4 !px-0.5">
                <Select
                  // styles={style}
                  placeholder="Archive"
                  options={jobTypeOptions}
                  getOptionLabel={(options) => options.label}
                  getOptionValue={(options) => options.value}
                  onChange={(e) =>
                    setFilters({ ...filters, job_type: e.value })
                  }
                  className="w-full"
                  value={
                    filters.job_type
                      ? {
                          value: filters.job_type,
                          label: jobTypeOptions?.find(
                            ({ value }) => value === filters.job_type
                          )?.label,
                        }
                      : null
                  }
                />
              </div>

              <div className="md:col-span-4 !px-0.5">
                <Button
                  text="Search"
                  variant="btn_submit"
                  onClick={() =>
                    dispatch(
                      get_agent_performance({
                        ...filters,
                        start_date: dayjs(dates?.from_date).format(
                          "YYYY-MM-DD"
                        ),
                        end_date: dayjs(dates?.to_date).format("YYYY-MM-DD"),
                      })
                    )
                  }
                />
                <Button
                  className="ml-2"
                  text="Reset"
                  variant="btn_cancel"
                  onClick={() => {
                    setFilters(initialFilters);
                    dispatch(get_agent_performance(initialFilters));
                  }}
                />
              </div>
            </div>
          </div>

          <div className="md:col-span-4 !px-0.5">
            <Picker filterData={dates} setFiltersData={setDates} />
          </div>
        </div>
        <div className="bg-white my-3 border rounded">
          <MUIDataTable
            exportOption={exportOption}
            handlePrint={handlePrint}
            columnDefs={columnDefs}
            items={agentPerform?.map((record, index) => {
              const {
                avg_talk,
                calls,
                dead_sec,
                dispo_sec,
                full_name,
                inbound_avg_talk,
                inbound_calls,
                login_time,
                outbound_avg_talk,
                outbound_calls,
                pause_sec,
                talk,
                user,
                wait_sec,
              } = record;
              return {
                counter: index + 1,
                records: { ...record, _id: index + 1 },
                avg_talk,
                calls,
                dead_sec,
                dispo_sec,
                full_name,
                inbound_avg_talk,
                inbound_calls,
                login_time,
                outbound_avg_talk,
                outbound_calls,
                pause_sec,
                talk,
                user,
                wait_sec,
              };
            })}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            totalItems={agentPerformance?.length}
            searchText={searchText}
            setSearchText={setSearchText}
            paginationModel={paginationModel}
            isLoading={isLoading}
          />
        </div>
        <div className="d-none"></div>
      </div>
    </>
  );
};

export default AgentPerformance;
