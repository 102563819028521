import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get_login_logs } from "../../features/reportsSlice";
import dayjs from "dayjs";
import { useReactToPrint } from "react-to-print";
import ReportHead from "../Components/Header";
import Picker from "../Components/Picker";
import Select from "react-select";
import { get_users } from "../../features/usersSlice";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";

const LoginLogs = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);
  const componentRef = React.useRef(null);
  const [searchText, setSearchText] = React.useState("");
  const currentDate = new Date();
  const initialFilters = {
    from_date:
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        0,
        0,
        0,
        0
      )
        .toISOString()
        .slice(0, 23) + "Z",
    to_date: new Date().toISOString().slice(0, 23) + "Z",
      }
  const [filters, setFilters] = React.useState(initialFilters);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const { isLoading, record } = useSelector((state) => state.reports);
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(
      get_login_logs({
        page: 1,
        size: paginationModel.pageSize,
      })
    );
    // eslint-disable-next-line
  }, []);
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "User", field: "firstName", flex: 1 },
    {
      headerName: "User IP",
      field: "user_ip",
      flex: 1,

      cellRendererFramework: (params) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://whatismyipaddress.com/ip/${params?.data?.user_ip}`}
        >
          {params?.data?.user_ip}
        </a>
      ),
    },
    { headerName: "Browser", field: "broswer", flex: 1 },
    { headerName: "OS", field: "OS", flex: 1 },
    { headerName: "Login At", field: "login_at", flex: 1 },
  ];
  const exportOption = [
    {
      label: "Export PDF",
      value: "pdf",
    },
    {
      label: "Export CSV",
      value: "csv",
    },
  ];

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Login Report",
  });
  useEffect(() => {
    dispatch(get_users());
    // eslint-disable-next-line
  }, []);
  const multiSelectHandler = (e) => {
    let arr = [];
    // eslint-disable-next-line
    e?.map((val) => {
      arr = [...arr, val?.value];
    });
  };
  const style = {
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#ffe7c7",
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused || state.hover ? "#a9a9a9" : null,
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  const group = [
    {
      label: "Users",
      options: users.users,
    },
  ];

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_login_logs({
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  return (
    <>
      <div className="container-fluid">
        <ReportHead route="Reports > Login IP Report" heading="Login IP" />
        <div className="row">
          <div className="col-md-8 mb-2">
            <Select
              styles={style}
              isMulti
              options={group}
              getOptionLabel={(options) => options?.first_name}
              getOptionValue={(options) => options?._id}
              onChange={(e) => {
                multiSelectHandler(e);
              }}
              className="w-full"
            />
          </div>
          <div className="col-md-4 !px-0.5">
          <Picker filterData={filters} setFiltersData={setFilters} />
          </div>
        </div>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          exportOption={exportOption}
          handlePrint={handlePrint}
          columnDefs={columnDefs}
          items={record?.records?.map((record, index) => {
            let counter = index + 1;
            let firstName =
              record?.user_id?.first_name +
              " " +
              (record?.user_id?.last_name ? record?.user_id?.last_name : "") +
              " (" +
              record?.user_id?.username +
              ")";
            let broswer = record?.browser;
            let OS = record?.os;
            let user_ip = record?.login_ip;
            let login_at = dayjs(record?.createdAt).format(
              "ddd, MMM D, YYYY h:mm A"
            );
            return {
              counter,
              firstName,
              user_ip,
              broswer,
              OS,
              login_at,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          getRowId={"_id"}
        />
      </div>
    </>
  );
};

export default LoginLogs;
