import React from "react";
import CustomModal from "../../components/molecules/Modal/customModal";

const ResponseModal = ({ onCancelForm, data }) => {
  return (
    <>
      <CustomModal
        handleModal={onCancelForm}
        cancelButtonRef={onCancelForm}
        className="max-w-3xl"
        title="URL Response"
      >
        <div className="grid md:grid-cols-1 gap-2 w-full"></div>
        {data && (
          <div className="mt-1">
            <h2 className="text-xl font-semibold mb-2">API Response:</h2>
            <pre className="bg-gray-200 p-2 rounded-md">
              {JSON.stringify(data, null, 2)}
            </pre>
          </div>
        )}
      </CustomModal>
    </>
  );
};

export default ResponseModal;
