import api from "./api";

export const getGoogleSearchConsole = async (data) => {
  return await api.get(`/api/google_search_console`,{params:data});
};
export const createGoogleSearchConsole = async (data) => {
  return await api.post("/api/google_search_console", data);
};
export const updateGoogleSearchConsole = async (data) => {
  return await api.put(`/api/google_search_console/${data?.id}`, data);
};
export const deleteGoogleSearchConsole = async (id) => {
  return await api.delete(`/api/google_search_console/${id}`);
};
