import React from "react";
import { useNavigate } from "react-router-dom";
import { plusImg } from "../../../images";
export default function CreateEventButton() {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate("/leads/add")}
      className="border p-2 rounded-full flex items-center shadow-md hover:shadow-2xl"
    >
      <img src={plusImg} alt="create_event" className="w-7 h-7" />
      <span className="pl-3 pr-7 font-sans"> Create</span>
    </button>
  );
}
