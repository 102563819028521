import api from "./api";
export const getConversation = async (data) => {
  if(!data?.filters?.rules?.length) delete data.filters
    return await api.post(`/api/leads/get_all_conversations`,data);
  };
  export const deleteConversation = async (data) => {
    return await api.post(`/api/leads/delete_conversations`,data);
  };
  export const replyToConversation = async (data) => {
    return await api.post(`/api/leads/reply_to_comversation`,data);
  };
  