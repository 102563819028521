import React, { useEffect, useState } from "react";
import { Button, DotsLoader } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { get_geo_industry } from "../../features/GeoIndustrySlice";
import {
  clearScrubReport,
  get_scrub_report,
  get_search_logs,
} from "../../features/geoLocSlice";
import { get_geo_states } from "../../features/GeoVendorsSlice";
import { MultiSelect } from "primereact/multiselect";

function ScrubReport() {
  const [searchText, setSearchText] = React.useState();
  const [filterData, setfilterData] = useState({
    industry_id: [],
    search_log_ids: [],
  });
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearScrubReport());
    dispatch(get_geo_states());
    dispatch(get_search_logs({ merchant: user?.user_group }));
    dispatch(get_geo_industry());
    // eslint-disable-next-line
  }, []);

  const { isLoading, searchLogs, scrubReport } = useSelector(
    (state) => state.geoLoc
  );
  const industry = useSelector((state) => state.geoIndustry);

  const columnDefs = [
    { headerName: "#", field: "counter" },
    { headerName: "Industry", field: "industry_name", flex: 1 },
    { headerName: "Total Purchased", field: "totalDomains", flex: 1 },
    { headerName: "Total In Area", field: "totalInArea", flex: 1 },
    { headerName: "Total Out Area", field: "totalOutOfArea", flex: 1 },
    {
      headerName: "Available To Purchase",
      field: "availableLocations",
      flex: 1,
    },
  ];
  return (
    <div>
      <PageHeader route="Setting > Vendors" heading="Scrub Domains Report" />
      <div className="items-center !m-2 bg-slate-50 !my-2 border rounded p-3">
        {isLoading && <DotsLoader />}

        <div className="flex flex-row items-center">
          <div className="w-full ml-2">
            <label className="inputLabel" htmlFor="industries">
              {"Industries"}
            </label>
            <MultiSelect
              placeholder="Select Record"
              options={industry?.record}
              optionLabel="name"
              optionValue="id"
              filter
              maxSelectedLabels={2}
              value={filterData?.industry_id}
              onChange={(e) => {
                setfilterData({ ...filterData, industry_id: e.value });
              }}
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
            />
          </div>
          <div className="w-full ml-2">
            <label className="inputLabel" htmlFor="logs">
              {"Search Logs"}
            </label>
            <MultiSelect
              placeholder="Select Record"
              options={searchLogs}
              optionLabel={(option) =>
                `${option?.description} | ${option?.vendor_id?.name} | ${option?.industry_id?.name}`
              }
              optionValue={(option) => option}
              filter
              maxSelectedLabels={2}
              value={filterData?.search_log_ids}
              onChange={(e) => {
                setfilterData({ ...filterData, search_log_ids: e.value });
              }}
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
            />
          </div>
          <Button
            text={"Search"}
            className="py-1.5 px-3 align-middle mx-2 bg-primary-100 text-white !mt-6"
            onClick={() => {
              const payload = filterData?.industry_id?.map((industryId) => {
                return {
                  id: industryId,
                  search_log_ids: filterData?.search_log_ids
                    ?.filter((log) => log.industry_id.id === industryId)
                    ?.map((log) => log.id),
                };
              });
              dispatch(get_scrub_report({ industries: payload }));
            }}
          />
          <Button
            text="Reset"
            className="py-1.5 px-3 align-middle bg-menu text-white !mt-6"
            onClick={() => {
              setfilterData({ industry_id: [], search_log_ids: [] });
              dispatch(clearScrubReport());
            }}
          />
        </div>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={scrubReport?.map((record, index) => {
            let counter = index + 1;
            let records = { ...record, _id: record?.industry_id };
            let industry_name = industry?.record?.find(
              ({ id }) => id === record?.industry_id
            )?.name;
            let totalDomains = record?.totalDomains;
            let totalInArea = record?.totalInArea;
            let totalOutOfArea = record?.totalOutOfArea;
            let availableLocations = record?.availableLocations;
            return {
              counter,
              records,
              industry_name,
              totalDomains,
              totalInArea,
              totalOutOfArea,
              availableLocations,
            };
          })}
          totalItems={0}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
        />
      </div>
    </div>
  );
}

export default ScrubReport;
