import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import { Button, Loader } from "../../components";
import { useDispatch } from "react-redux";
import { get_commission_report } from "../../features/reportsSlice";
const CustomModal = ({
  onClose,
  selectedData,
  filters,
  paginationModel,
  setSelectedData,
}) => {
  console.log("🚀 ~ selectedData:", selectedData);
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const [description, setDescription] = useState("");
  const handleConfirm = async () => {
    const leads = selectedData?.map(({ _id }) => _id);
    setIsLoader(true);
    try {
      const res = await api.post("api/jobs/create_invoice", {
        description,
        leads,
      });
      if (res.status === 200) {
        toast.success("Invoice created successfully");
        setSelectedData([]);
        dispatch(get_commission_report({ ...filters, ...paginationModel }));
        onClose();
      } else {
        toast.error(res?.data?.message || "Invoice couldn't be created");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error(
        err?.response?.data?.message || "Invoice couldn't be created"
      );
      console.log("🚀 ~ handleConfirm ~ err:", err);
    }
  };
  const totalSum = selectedData.reduce((sum, item) => sum + item.total, 0);
  const totalTechfee = selectedData.reduce(
    (sum, item) => sum + item.tech_fee,
    0
  );
  const totalCompPro = selectedData.reduce(
    (sum, item) => sum + item.company_profit,
    0
  );
  return (
    <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40 bg-black/60">
      <div className="bg-white p-8 rounded-lg shadow-lg relative w-[500px]">
        <h2 className="text-lg font-semibold mb-4">Create Vendor Invoice</h2>
        <div className="grid grid-cols-2 font-pop font-medium gap-x-2">
          <div className="grid grid-cols-4 justify-between p-1">
            <span className="col-span-3">Total Jobs:</span>
            <span>{selectedData?.length}</span>
          </div>
          <div className="grid grid-cols-6 justify-between p-1">
            <span className="col-span-5">Total Amount ($):</span>
            <span className="text-right">{totalSum}</span>
          </div>
          <div className="grid grid-cols-4 justify-between p-1">
            <span className="col-span-3">Tech Profit ($):</span>
            <span>{totalTechfee}</span>
          </div>
          <div className="grid grid-cols-6 flex justify-between p-1">
            <span className="col-span-5">Company Profit ($):</span>
            <span className="text-right">{totalCompPro}</span>
          </div>
        </div>
        {/* <div className="grid grid-cols-6 justify-between col-span-2 p-1 mt-2">
            <span className="col-span-5">
              Total Invoice Payable Amount ($):
            </span>
            <span className="text-right">{totalTechfee}</span>
          </div> */}
          <div className="flex mb-2 font-medium flex-col justify-between items-center text-ml-3 !p-2 w-full hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Total Invoice Payable Amount ($):</span>
            <span className="text-base font-medium font-pop">
              {( parseFloat(totalSum)- parseFloat(totalTechfee) )?.toLocaleString()}
            </span>
          </div>
        <div className="relative flex flex-row">
          <textarea
            className="bg-white border border-gray-300 text-sm rounded w-full p-2.5 "
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            name="description"
            placeholder={"Description"}
          />
        </div>
        <div
          className={`flex items-center !py-1.5 !px-2.5 mt-2`}
          // onClick={() => handleIndustries(category)}
        >
          <input
            type="checkbox"
            // checked={formik.values?.industries?.some(
            //   (id) => id === category
            // )}
            // onChange={(e) => handleIndustries(category)}
            className={`form-checkbox h-5 w-5 text-primary-100 rounded-xs focus:ring-0 cursor-pointer mr-2`}
            // value={category}
          />
          <label className={`text-xs text-[#495057] cursor-pointer`}>
            Do you want to send this invoice to the Vendor (Via Email)?
          </label>
        </div>
        <div className="flex justify-end !mt-4">
          {isLoader ? (
            <Loader />
          ) : (
            <>
              <Button text="Cancel" variant="btn_cancel" onClick={onClose} />
              <Button
                text="Create Invoice"
                variant="btn_submit"
                onClick={handleConfirm}
                className="ml-2"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
