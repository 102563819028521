import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { DotsLoader } from "../../../components";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import PageHeader from "../../../components/molecules/PageHeader";
import { get_audio_files } from "../../../features/callCentersSlice";
import api from "../../../services/api";
import userPermission from "../../../util/userPermission";
import UploadAudioFile from "./UploadAudiofile";

function AudioUpload() {
  const dispatch = useDispatch();
  const { isLoading, audioFiles } = useSelector((state) => state.callCenters);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  useEffect(() => {
    dispatch(get_audio_files());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [currentlyPlayingAudio, setCurrentlyPlayingAudio] =
    React.useState(null);
  const handlePlay = async (audio_filename) => {
    const audioElement = document.getElementById(audio_filename);
    if (audioElement) {
      if (currentlyPlayingAudio && currentlyPlayingAudio !== audioElement) {
        currentlyPlayingAudio.pause();
      }
      audioElement.play();
      setCurrentlyPlayingAudio(audioElement);
    }
  };
  const handleDelete = async (file_name) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (!c) return;
    setLoader(true);
    try {
      const res = await api.delete(
        `/api/cc/dids/options/call_audio/${file_name}`
      );
      if (res.status === 200) {
        toast.success(res?.data?.message || "Audio file deleted successfully");
        dispatch(get_audio_files());
      } else {
        toast.error(res?.data?.message || "Audio file couldn't be deleted");
      }
      setLoader(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Audio file couldn't be deleted"
      );
      setLoader(false);
      console.log("🚀 ~ handleDelete ~ err:", err);
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 60 },
    { headerName: "File Name", field: "real_file_name", flex: 1 },
    { headerName: "Size", field: "formatedSize", minWidth: 120 },
    {
      headerName: "Status",
      field: "wav_asterisk_valid",
      minWidth: 120,
      renderCell: (params) =>
        params.row?.wav_asterisk_valid === "" ? (
          <span className="text-green-600">Valid format</span>
        ) : (
          <span className="text-red-600">Invalid format</span>
        ),
    },
    {
      headerName: "Format",
      field: "audio_format",
      minWidth: 100,
      align: "cente",
      headerAlign: "centr",
    },
    {
      headerName: "Audio",
      field: "sounds_url",
      flex: 1,
      renderCell: (params) => (
        <audio
          id={params.row.audio_filename}
          controls
          className="h-10 w-[250px] relative"
          onPlay={() => handlePlay(params.row.audio_filename)}
        >
          {params.row.sounds_url && (
            <source src={params.row.sounds_url} type="audio/mpeg" />
          )}
        </audio>
      ),
    },
    {
      field: "actions",
      align: "center",
      renderCell: (params) => (
        <div>
          {userPermission("Update Call Center") && (
            <FaTrash
              onClick={() => handleDelete(params.row.audio_filename)}
              className="my_navIcon"
              title="Delete audio"
            />
          )}
        </div>
      ),
    },
  ];
  return (
    <>
      {isEditing && (
        <UploadAudioFile
          editingRecord={editingRecord}
          modalTitle="Upload Audio Files"
          onCancelForm={cancelFormHandler}
        />
      )}
      {loader ? <DotsLoader /> : null}
      <PageHeader
        route="Call Center > Inbound Groups"
        heading="Audio File Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Inbound")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={audioFiles?.map((record, index) => {
            let counter = index + 1;
            const {
              audio_filename,
              sounds_url,
              real_file_name,
              audio_filesize,
              audio_format,
              wav_asterisk_valid,
            } = record;
            const formatedSize =
              audio_filesize > 1000 && audio_filesize < 1000000
                ? (audio_filesize / 1000).toFixed(2) + " KB"
                : (audio_filesize / 1000000).toFixed(2) + " MB";
            return {
              counter,
              records: { ...record, _id: counter + 1 },
              audio_filename,
              sounds_url,
              real_file_name: real_file_name || audio_filename,
              audio_format,
              audio_filesize,
              formatedSize,
              wav_asterisk_valid,
            };
          })}
          pagination="No"
          totalItems={audioFiles?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          paginationMode="client"
          density="standard"
        />
      </div>
    </>
  );
}

export default AudioUpload;
