import React from "react";
import { DotsLoader, FormInput, Modal } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { create_google_console, update_google_console } from "../../features/googleConsoleSlice";
const GoogleConsoleForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.googleConsole);
  const [errors, setErrors] = React.useState([]);

  const initialValues = {
    host_ip: "",
    gmail_account: "",
    client_id: "",
    client_secret: "",
    refresh_token: "",
    ...(editingRecord && {
      id: editingRecord?.id,
      client_id: editingRecord?.client_id,
      client_secret: editingRecord?.client_secret,
      gmail_account: editingRecord?.gmail_account,
      host_ip: editingRecord?.host_ip,
      refresh_token: editingRecord?.refresh_token,
    }),
  };
  const handleSubmit = async (values) => {
    const action = editingRecord ? update_google_console : create_google_console;
    const message = editingRecord ? "updated" : "created";
    try {
      const res = await dispatch(action(values));
      if (res.payload?.status === 200 || res.payload?.status === 201) {
        toast.success(`Google Search Console ${message}`);
        onCancelForm();
      } else {
        if (Array.isArray(res.payload)) {
          setErrors(res.payload);
        } else {
          toast.error(
            res.payload
              ? res.payload
              : `Google Search Console couldn't be ${message}`
          );
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload)) {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload
            ? error.payload
            : `Google Search Console couldn't be ${message}`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}

        <form className="mt-4">
          <div className="!mb-3">
            <FormInput
              errors={errors}
              name="host_ip"
              label="Host IP"
              formik={formik}
            />
          </div>
          <div className="!mb-3">
            <FormInput
              errors={errors}
              name="gmail_account"
              label="Gmail Account"
              formik={formik}
            />
          </div>
          <div className="!mb-3">
            <FormInput
              errors={errors}
              name="client_id"
              label="Client ID"
              formik={formik}
            />
          </div>
          <div className="!mb-3">
            <FormInput
              errors={errors}
              name="client_secret"
              label="Client Secret Key"
              formik={formik}
            />
          </div>
          <div className="!mb-3">
            <FormInput
              errors={errors}
              name="refresh_token"
              label="Refresh Token"
              formik={formik}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default GoogleConsoleForm;
