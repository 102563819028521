import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getAgentCallsByDate, getIndustryCallsByDate, getVendorCallsByDate, } from "../services/dashboardService";

const initialState = {
  isError: false,
  isLoading: false,
  isAgentLoading: false,
  isIndustryLoading: false,
  isVendorLoading: false,
  agentCallsByByDate: [],
  industryCallsOverAll:{},
  industryCallsByDate:[],
  agentCallsOverAll:{},
  vendorJobsOverAll:{},
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
export const get_industry_calls_by_date = createAsyncThunk(
  "get_industry_calls_by_date",
  async (data,thunkAPI) => {
    try {
      return await getIndustryCallsByDate(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_agent_calls_by_date = createAsyncThunk(
  "get_agent_calls_by_date",
  async (data,thunkAPI) => {
    try {
      return await getAgentCallsByDate(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_vendor_calls_by_date = createAsyncThunk(
  "get_vendor_calls_by_date",
  async (data,thunkAPI) => {
    try {
      return await getVendorCallsByDate(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    allowedIPReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_industry_calls_by_date.pending, (state) => {
        state.isIndustryLoading = true;
      })
      .addCase(get_industry_calls_by_date.fulfilled, (state, action) => {
        state.isIndustryLoading = false;
        state.industryCallsByDate = action.payload?.data?.by_date;
        state.industryCallsOverAll = action.payload?.data?.by_overall;
      })
      .addCase(get_industry_calls_by_date.rejected, (state, action) => {
        state.isIndustryLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.industryCallsByDate = [];
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(get_agent_calls_by_date.pending, (state) => {
        state.isAgentLoading = true;
      })
      .addCase(get_agent_calls_by_date.fulfilled, (state, action) => {
        state.isAgentLoading = false;
        state.agentCallsByByDate = action.payload?.data?.by_date;
        state.agentCallsOverAll = action.payload?.data?.by_overall;
      })
      .addCase(get_agent_calls_by_date.rejected, (state, action) => {
        state.isAgentLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.agentCallsOverAll = {};
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(get_vendor_calls_by_date.pending, (state) => {
        state.isVendorLoading = true;
      })
      .addCase(get_vendor_calls_by_date.fulfilled, (state, action) => {
        state.isVendorLoading = false;
        // state.agentCallsByByDate = action.payload?.data?.by_date;
        state.vendorJobsOverAll = action.payload?.data?.summary;
      })
      .addCase(get_vendor_calls_by_date.rejected, (state, action) => {
        state.isVendorLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload?action.payload:"Unknown Error")
      });
  },
});

export const { allowedIPReset } = dashboardSlice.actions;
export default dashboardSlice.reducer;
