import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import GoogleConsoleForm from "./AddUpdateServer";
import userPermission from "../../util/userPermission";
import { delete_gsc_server, get_gsc_server } from "../../features/serverSlice";

const GoogleServer = () => {
  const dispatch = useDispatch();
  const { isLoading, servers } = useSelector(
    (state) => state.servers
  );
  const [searchText, setSearchText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  useEffect(() => {
    dispatch(get_gsc_server());
    // eslint-disable-next-line
  }, []);
  const googleConsoles = servers?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_gsc_server(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Server deleted successfully");
          dispatch(get_gsc_server());
        } else {
          errorMessage({
            payload: res.payload,
            action: "Server",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          action: "Server",
          msg: "deleted",
        });
      }
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 80 },
    { headerName: "Server IP", field: "server_ip", flex: 1 },
    { headerName: "Total Domains", field: "domain_count", flex: 1 },
    {
      headerName: "Action",
      field: "action",
      flex: 1,
      renderCell: (params) => (
        <div>
          <div className="flex flex-row">
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update AllowedIP"
            />
            <FaTrashAlt
              onClick={deleteRecordHandler(params.row.records.id)}
              className="my_navIcon"
              title="Delete AllowedIP"
            />
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      <PageHeader
        route="Setting > User Roles"
        heading="Server List"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Allowed IP")}
      />
      {isEditing && (
        <GoogleConsoleForm
          editingRecord={editingRecord}
          modalTitle="Add Server"
          onCancelForm={cancelFormHandler}
        />
      )}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={googleConsoles?.map((record, index) => {
            let counter = index + 1;
            const {
              server_ip,
              domain_count,
            } = record;
            return {
              counter,
              server_ip,
              domain_count,
              records: record,
            };
          })}
          totalItems={servers?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default GoogleServer;
