import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../../components/molecules/PageHeader";
import {
  get_buying_report,
  resetBuyingDomains,
} from "../../../features/domainCenterSlice";
import { get_geo_industry } from "../../../features/GeoIndustrySlice";
import userPermission from "../../../util/userPermission";
import { Button, DotsLoader } from "../../../components";
import Select from "react-select";
import { MultiSelect } from "primereact/multiselect";
import { get_search_logs } from "../../../features/geoLocSlice";
import Paginations from "../../../components/molecules/DataTable/Pagination";
import { FaSearch } from "react-icons/fa";
import SelectInput from "../../../components/atoms/SelectInput";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { MdSave } from "react-icons/md";
import BatchSaveModal from "./saveBatch";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import * as XLSX from "xlsx";
const DomainBuying = () => {
  const dispatch = useDispatch();
  const { isLoading, buyingDomains } = useSelector(
    (state) => state.domainCenter
  );
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const handleSort = (column) => {
    if (sortColumn === column) {
      // If the same column is clicked again, reverse the sort direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // If a different column is clicked, set the column and ascending sort direction
      setSortColumn(column);
      setSortDirection("desc");
    }
  };
  const initialFilters = {
    industry_id: "",
    unique_by: "",
    search_log_ids: [],
    population_from: "",
    population_to: "",
    location_status: "Available",
  };
  const { searchLogs } = useSelector((state) => state.geoLoc);
  const industry = useSelector((state) => state.geoIndustry);
  const [searchText, setSearchText] = useState("");
  const [filterData, setfilterData] = useState(initialFilters);
  const [selected, setSelected] = React.useState([]);
  const [searchPayload, setSearchPayload] = React.useState([]);
  const [isSaveBatch, setIsSaveBatch] = React.useState(false);
  const [isselectedAll, setIsSelectedAll] = React.useState(false);
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  useEffect(() => {
    dispatch(resetBuyingDomains());
    dispatch(get_geo_industry());
    dispatch(get_search_logs({ merchant: user?.user_group }));
    // eslint-disable-next-line
  }, []);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const statusOptions = [
    { value: "Available", label: "Available to Buy" },
    { value: "InArea", label: "In Area" },
  ];
  const options = [
    {
      value: 1,
      label: "1 Records",
    },
    {
      value: 2,
      label: "2 Records",
    },
    {
      value: 5,
      label: "5 Records",
    },
    {
      value: 10,
      label: "10 Records",
    },
    {
      value: 25,
      label: "25 Records",
    },
    {
      value: 50,
      label: "50 Records",
    },
    {
      value: 75,
      label: "75 Records",
    },
    {
      value: 100,
      label: "100 Records",
    },
    {
      value: 1000,
      label: "1000 Records",
    },
    {
      value: buyingDomains?.totalItems,
      label: "All Records",
    },
  ];

  const count = Math.ceil(
    buyingDomains?.totalItems / paginationModel?.pageSize
  );
  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  const lastPageIndex = +paginationModel?.pageSize - 1;
  const lastItemIndex = Math.min(
    offset + paginationModel?.pageSize,
    buyingDomains?.totalItems
  );

  const handleRecordsPerPageChange = ({ target: { value } }) => {
    setPaginationModel({ ...paginationModel, page: 1, pageSize: value });
    dispatch(
      get_buying_report({
        ...filterData,
        page: 1,
        size: value,
      })
    );
  };
  const handlePageChange = (page) => {
    setPaginationModel({ ...paginationModel, page: page });
    dispatch(
      get_buying_report({
        ...filterData,
        page,
        size: +paginationModel?.pageSize,
      })
    );
  };
  const searchLogsFilter = searchLogs?.filter((log) =>
    log?.industry_id?.name
      ?.toLowerCase()
      .includes(
        filterData.industry_id
          ? industry?.record
              ?.find(({ id }) => id === filterData.industry_id)
              ?.name?.toLowerCase()
          : ""
      )
  );

  const handleSearchButton = () => {
    if (filterData.industry_id) {
      setPaginationModel({ ...paginationModel, page: 1 });
      dispatch(
        get_buying_report({
          ...filterData,
          page: paginationModel?.page,
          size: paginationModel?.pageSize,
        })
      );
      const selectedNames = filterData?.search_log_ids?.map((id) => {
        const record = searchLogs.find((item) => item.id === id);
        return record
          ? `${record.description} | ${record?.vendor_id?.name} | ${record?.industry_id?.name}`
          : "";
      });
      setSearchPayload({
        industry_id: industry?.record?.find(
          ({ id }) => id === filterData?.industry_id
        )?.name,
        search_log_ids: selectedNames ? selectedNames : [],
        population_from: filterData?.population_from,
        population_to: filterData?.population_to,
        location_status: statusOptions?.find(
          ({ value }) => value === filterData?.location_status
        )?.label,
      });
    } else {
      toast.error("Please select Industry");
    }
  };

  let filteredData = [];

  const filterByQuery = (item) => {
    return (
      item?.state?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      item?.city?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      item?.states?.some((state) =>
        state?.toLowerCase()?.includes(searchText?.toLowerCase())
      ) ||
      item?.cities?.some((city) =>
        city?.toLowerCase()?.includes(searchText?.toLowerCase())
      ) ||
      item?.zip?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      item?.area_code?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      item?.zip_population?.toString()?.includes(searchText)
    );
  };
  if (filterData?.unique_by === "") {
    filteredData = buyingDomains?.data?.filter((item) => filterByQuery(item));
  }
  const onSingleselect = ({ city, state }) => {
    if (selected.length > 0) {
      if (selected.some((data) => data.state === state && data.city === city)) {
        const updatedSelected = selected.filter(
          (data) => data.state !== state || data.city !== city
        );
        setSelected(updatedSelected);
      } else {
        setSelected([...selected, { state, city }]);
      }
    } else {
      setSelected([...selected, { state, city }]);
    }
  };

  const isChecked = ({ city, state }) => {
    if (selected?.length > 0) {
      if (
        selected?.filter((data) => data?.state === state && data.city === city)
          .length > 0
      ) {
        return true;
      }
    }
    return false;
  };

  const handleSelectAll = () => {
    if (!isselectedAll) {
      const uniqueRecords = new Set();

      if (filterData.unique_by === "") {
        buyingDomains?.data
          ?.flatMap((item) => `${item.state}_${item?.city}`)
          .forEach((combination) => uniqueRecords.add(combination));
      } else if (filterData.unique_by === "state") {
        buyingDomains?.data
          ?.flatMap((item) =>
            item.cities.map((city) => `${item.state}_${city?.city}`)
          )
          .forEach((combination) => uniqueRecords.add(combination));
      } else if (filterData.unique_by === "city") {
        buyingDomains?.data
          ?.flatMap((item) =>
            item.states.flatMap((state) => `${state}_${item?.city}`)
          )
          .forEach((combination) => uniqueRecords.add(combination));
      } else if (filterData.unique_by === "zip") {
        buyingDomains?.data
          ?.flatMap((item) =>
            item.states.flatMap((state) =>
              item.cities.map((city) => `${state}_${city?.city}`)
            )
          )
          .forEach((combination) => uniqueRecords.add(combination));
      }
      const allRecords = Array.from(uniqueRecords).map((combination) => {
        const [state, city] = combination.split("_");
        return { state, city };
      });
      setSelected(allRecords);
      setIsSelectedAll(true);
    } else {
      setSelected([]);
      setIsSelectedAll(false);
    }
  };
  const exportData = () => {
    const rows = [
      ["State", "City", "Zip", "Zip Population", "Area Code"], // Add headings
    ];

    buyingDomains?.data.forEach((item) => {
      const { state, city, zip_details } = item;

      if (zip_details.length === 0) {
        rows.push([state, city, "", "", ""]); // Add a row without zip details
      } else {
        zip_details.forEach((zipDetail, index) => {
          const { zip, zip_population, area_code } = zipDetail;
          if (index === 0) {
            rows.push([
              state,
              city,
              zip,
              zip_population?.toLocaleString(),
              area_code,
            ]); // Add the first row with zip details
          } else {
            rows.push([
              state,
              city,
              zip,
              zip_population?.toLocaleString(),
              area_code,
            ]); // Add subsequent rows with empty state and city
          }
        });
      }
    });

    const worksheet = XLSX.utils.aoa_to_sheet(rows);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const fileName = "data.xlsx";

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    if (typeof window.navigator.msSaveBlob !== "undefined") {
      // For IE browser
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    }
  };

  return (
    <>
      <PageHeader
        route="Setting > Vendors"
        heading="Buying Domains"
        isAllowed={userPermission("null")}
      />
      {isSaveBatch && (
        <BatchSaveModal
          industry_id={filterData.industry_id}
          search_filter={searchPayload}
          // editingRecord={editingRecord}
          onSingleselect={onSingleselect}
          data={selected}
          title="Save Batch"
          onCancelForm={() => setIsSaveBatch(false)}
        />
      )}
      {isLoading && <DotsLoader />}
      <div className="items-center !m-2 bg-slate-50 !my-2 border rounded p-3">
        <div className="flex flex-wrap items-center">
          <div className="w-1/5">
            <label className="inputLabel" htmlFor="industries">
              {"Industries"}
            </label>
            <Select
              name="industries"
              placeholder="Select Industries"
              onChange={(e) =>
                setfilterData({ ...filterData, industry_id: e.id })
              }
              options={industry?.record}
              valueKey="id"
              labelKey="name"
              className="w-full"
              menuPosition="fixed"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={
                filterData?.industry_id
                  ? {
                      id: filterData.industry_id,
                      name: industry?.record?.find(
                        ({ id }) => id === filterData?.industry_id
                      )?.name,
                    }
                  : null
              }
            />
          </div>

          <div className="w-1/5 ml-2">
            <label className="inputLabel" htmlFor="logs">
              {"Search Logs"}
            </label>
            <MultiSelect
              placeholder="Select Record"
              options={searchLogsFilter}
              optionLabel={(option) =>
                `${option?.description} | ${option?.vendor_id?.name} | ${option?.industry_id?.name}`
              }
              optionValue="id"
              filter
              maxSelectedLabels={2}
              value={filterData?.search_log_ids}
              onChange={(e) => {
                setfilterData({ ...filterData, search_log_ids: e.value });
              }}
              disabled={!filterData?.industry_id ? true : false}
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
            />
          </div>
          <div className="w-1/5 ml-2">
            <label className="inputLabel" htmlFor="industries">
              {"Status"}
            </label>
            <Dropdown
              value={filterData?.location_status}
              onChange={(e) =>
                setfilterData({ ...filterData, location_status: e.value })
              }
              options={statusOptions}
              optionLabel="label"
              optionValue="value"
              placeholder="Status"
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
            />
          </div>
          <div className="w-1/5 mx-2">
            <label className="inputLabel" htmlFor="industries">
              {"Population"}
            </label>
            <div className="flex flex-row">
              <input
                className="form-control"
                placeholder="From"
                onChange={(e) =>
                  setfilterData({
                    ...filterData,
                    population_from: e.target.value,
                  })
                }
                value={filterData?.population_from}
              />
              <input
                className="form-control ml-1"
                placeholder="To"
                onChange={(e) =>
                  setfilterData({
                    ...filterData,
                    population_to: e.target.value,
                  })
                }
                value={filterData?.population_to}
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-3 flex justify-center !mt-6">
            <div className="float-right">
              <Button
                text={"Search"}
                className="py-1.5 px-3 align-middle mr-2 bg-primary-100 text-white"
                onClick={handleSearchButton}
              />
              <Button
                text="Clear"
                className="py-1.5 px-3 align-middle bg-menu text-white"
                onClick={() => {
                  dispatch(resetBuyingDomains());
                  setfilterData(initialFilters);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {buyingDomains.totalItems <= paginationModel?.pageSize && (
        <>
          <div className="col-span-6 flex items-center justify-between">
            <div className="w-fit py-[5px] text-base font-normal font-mont text-heading my-2">
              Displaying {offset + 1}-{lastItemIndex ? lastItemIndex : 0} of{" "}
              {buyingDomains?.totalItems ? buyingDomains?.totalItems : 0} result
            </div>
          </div>
        </>
      )}

      {buyingDomains?.totalItems > paginationModel?.pageSize && (
        <Paginations
          offset={offset}
          lastItemIndex={lastItemIndex}
          items={buyingDomains?.data}
          page={paginationModel?.page}
          lastPageIndex={lastPageIndex}
          count={count}
          onChange={(e, page) => handlePageChange(page)}
          totalItems={buyingDomains?.totalItems}
        />
      )}
      {searchText && (
        <div className="col-span-6 flex items-center justify-between">
          <div className="w-fit text-base font-normal font-mont text-heading">
            Sub Searched {filteredData?.length ? filteredData?.length : 0} out
            of {paginationModel?.pageSize ? paginationModel?.pageSize : 0}{" "}
            records
          </div>
        </div>
      )}
      <div className="row mb-3 mt-2 mt-md-0">
        <div className="col-8">
          <input
            className={"form-control-sm border h-100 w-100 pl-5"}
            style={{ minHeight: 31 }}
            placeholder="Search"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          <div className="absolute top-[3px] left-[25px] border-r pr-[10px] flex justify-center items-center h-[25px]">
            <FaSearch />
          </div>
        </div>
        <div className="col-2">
          <button
            onClick={exportData}
            text="Export"
            className="relative float-right border ml-1 w-[119px] h-[31px] text-sm"
          >
            Export DID data
          </button>
        </div>
        <div className="col-2">
          <SelectInput
            className={"form-control-sm float-right border"}
            onChange={handleRecordsPerPageChange}
            options={options}
            value={paginationModel?.pageSize}
          />
        </div>
      </div>
      <div className="table-responsive max-h-[55vh] mb-2 bg-white data-records">
        <table className="table table-bordered table-striped">
          <thead className="sticky top-0 bg-white">
            <tr className="bg-white border-[#ccc]">
              {buyingDomains?.data?.length > 0 && (
                <th className="sticky w-[7%]">
                  <input
                    type={"checkbox"}
                    onChange={handleSelectAll}
                    checked={selected?.length === buyingDomains?.data?.length}
                    className="border-0 cursor-pointer"
                  />
                </th>
              )}

              <th className="sticky w-[7%]">#</th>
              <th className="sticky w-1/3">
                Available City to Purchase
                <spna className="!text-sm float-right font-semibold font_pop text-[#767676]">
                  {buyingDomains?.data?.length}
                  {sortColumn === "city" && sortDirection === "desc" ? (
                    <BsArrowDown
                      className="cursor-pointer"
                      onClick={() => handleSort("city")}
                    />
                  ) : (
                    <BsArrowUp
                      className="cursor-pointer"
                      onClick={() => handleSort("city")}
                    />
                  )}
                </spna>
              </th>

              <th className="sticky">
                {"Zip Detail"}
                <span className="!text-sm float-right font-semibold font_pop text-[#767676]">
                  {filterData?.unique_by === "" ||
                  filterData?.unique_by === "zip"
                    ? buyingDomains?.totals?.totalZips
                    : `${buyingDomains?.totals?.totalZips} / ${Number(
                        buyingDomains?.totals?.totalPopulation
                      )?.toLocaleString()}`}
                </span>
                <span className="float-right ml-1">
                  {sortColumn === "zip" && sortDirection === "desc" ? (
                    <BsArrowDown
                      className="cursor-pointer"
                      onClick={() => handleSort("zip")}
                    />
                  ) : (
                    <BsArrowUp
                      className="cursor-pointer"
                      onClick={() => handleSort("zip")}
                    />
                  )}
                </span>
              </th>
              <th className="sticky">
                Zip Population
                <span className="float-right ml-1">
                  {sortColumn === "total_population" &&
                  sortDirection === "desc" ? (
                    <BsArrowDown
                      className="cursor-pointer"
                      onClick={() => handleSort("total_population")}
                    />
                  ) : (
                    <BsArrowUp
                      className="cursor-pointer"
                      onClick={() => handleSort("total_population")}
                    />
                  )}
                </span>
              </th>
            </tr>
          </thead>

          <tbody className="max-h-[45vh] mb-2 ">
            {buyingDomains?.data?.length > 0 &&
              filteredData
                ?.sort((a, b) => {
                  if (sortColumn === "total_population") {
                    const populationA = +a?.total_population || 0;
                    const populationB = +b?.total_population || 0;

                    if (populationA < populationB) {
                      return sortDirection === "asc" ? -1 : 1;
                    }
                    if (populationA > populationB) {
                      return sortDirection === "asc" ? 1 : -1;
                    }
                    return 0;
                  } else if (sortColumn === "zip") {
                    const lengthA = a.zip_details?.length;
                    const lengthB = b.zip_details?.length;

                    if (lengthA < lengthB) {
                      return sortDirection === "asc" ? -1 : 1;
                    }
                    if (lengthA > lengthB) {
                      return sortDirection === "asc" ? 1 : -1;
                    }
                    return 0;
                  } else {
                    // Handle sorting for other columns as needed
                    // You can use a similar approach as before, comparing the values
                    // of the selected column and considering the sort direction.
                    // For example:
                    const valA = String(a[sortColumn]).toLowerCase();
                    const valB = String(b[sortColumn]).toLowerCase();

                    if (valA < valB) {
                      return sortDirection === "asc" ? -1 : 1;
                    }
                    if (valA > valB) {
                      return sortDirection === "asc" ? 1 : -1;
                    }
                    return 0;
                  }
                })
                ?.map((data, index) => {
                  return (
                    <>
                      {filterData?.unique_by === "" ? (
                        <tr
                          key={index}
                          className="border-b[1px] border-slate-200 p-4 text-black "
                        >
                          <td>
                            <input
                              type={"checkbox"}
                              checked={isChecked({
                                state: data.state,
                                city: data.city,
                              })}
                              onChange={() =>
                                onSingleselect({
                                  state: data.state,
                                  city: data.city,
                                })
                              }
                            />
                          </td>
                          <td className="text-black custom-height">
                            {offset + index + 1}
                          </td>
                          <td className="text-black custom-height">
                            {data?.city} - {data?.state}
                          </td>
                          <td className="text-black">
                            <details className="">
                              <summary className="flex flex-row items-center text-sm px-2 text-blue-500 font-semibold font-pop justify-between hover:cursor-pointer hover:underline py-2 px-1">
                                {data?.zip_details?.length}
                              </summary>
                              <div className="table-responsive max-h-[250px] mb-2 bg-transparent">
                                <tr className="border-[#ccc] bg-transparent font-sm">
                                  <td className="text-xs font-semibold text-black">
                                    #
                                  </td>
                                  <td className="text-xs font-semibold text-black">
                                    Zip
                                  </td>
                                  <td className="text-xs font-semibold text-black">
                                    Zip Population
                                  </td>
                                  <td className="text-xs font-semibold text-black">
                                    Area Code
                                  </td>
                                </tr>
                                {data?.zip_details?.map((zip, index) => {
                                  return (
                                    <tr className="border-t border-[#ccc] bg-transparent font-sm">
                                      <td className="text-xs font-semibold text-black">
                                        {index + 1}
                                      </td>
                                      <td className="text-xs font-semibold text-black">
                                        {zip?.zip}
                                      </td>
                                      <td className="text-xs font-semibold text-black">
                                        {zip?.zip_population
                                          ? zip?.zip_population?.toLocaleString()
                                          : ""}
                                      </td>
                                      <td className="text-xs font-semibold text-black">
                                        {zip?.area_code}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </div>
                            </details>
                          </td>
                          <td className="text-black custom-height">
                            {data?.total_population
                              ? Number(data?.total_population)?.toLocaleString()
                              : ""}
                          </td>
                        </tr>
                      ) : null}
                    </>
                  );
                })}
          </tbody>
        </table>

        {buyingDomains?.length === 0 && (
          <div className="flex items-center justify-center !w-[calc(100vw-210px)] text-center">
            No record found
          </div>
        )}
        {selected?.length > 0 && (
          <div
            className="w-12 h-12 rounded-full bg-client-50 flex justify-center items-center fixed bottom-16 right-8 cursor-pointer"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            title="Save Batch"
            onClick={() => setIsSaveBatch(true)}
          >
            <MdSave size={30} className="text-white" />
          </div>
        )}
      </div>
    </>
  );
};

export default DomainBuying;
