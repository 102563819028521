import api from "./api";

export const getCardTypes = async ({token,data}) => {
  if (token) {
    return await api.get(`/api/frontend/card_type`, {
      headers: {
        frontend_token: token,
      },
    });
  } else {
    return await api.get("/api/card_type",{params:data});
  }
};
export const getCardTypesDrd = async (token) => {

    return await api.get("/api/card_type/drd");
};

export const createCardType = async (data) => {
  return await api.post("/api/card_type", data);
};
export const updateCardType = async (data) => {
  return await api.put("/api/card_type", data);
};
