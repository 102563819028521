import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createGscServer,
  createServer,
  deleteGscServer,
  deleteServer,
  getAvailableGscServer,
  getGscServer,
  getRunners,
  getRunnerApps,
  updateGscServer,
  updateServer,
} from "../services/serverService";

const initialState = {
  isError: false,
  isLoading: false,
  runner_apps: [],
  availableServers: [],
  message: "",
  runners: [],
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Google Seach Console
export const get_gsc_server = createAsyncThunk(
  "get_gsc_server",
  async (thunkAPI) => {
    try {
      return await getGscServer();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Google Seach Console
export const get_available_gsc_server = createAsyncThunk(
  "get_available_gsc_server",
  async (thunkAPI) => {
    try {
      return await getAvailableGscServer();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create New Google Search Console
export const create_gsc_server = createAsyncThunk(
  "create_gsc_server",
  async (data, thunkAPI) => {
    try {
      return await createGscServer(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Google Search Console
export const update_gsc_server = createAsyncThunk(
  "update_gsc_server",
  async (data, thunkAPI) => {
    try {
      return await updateGscServer(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Google Search Console
export const delete_gsc_server = createAsyncThunk(
  "delete_gsc_server",
  async (id, thunkAPI) => {
    try {
      return await deleteGscServer(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_runner_apps = createAsyncThunk("get_runner_apps", async (thunkAPI) => {
  try {
    return await getRunnerApps();
  } catch (error) {
    const message = getErrorMessage(error);
    return thunkAPI.rejectWithValue(message);
  }
});

export const create_server = createAsyncThunk(
  "create_server",
  async (data, thunkAPI) => {
    try {
      return await createServer(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update User
export const update_server = createAsyncThunk(
  "update_server",
  async (data, thunkAPI) => {
    try {
      return await updateServer(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete User
export const delete_server = createAsyncThunk(
  "delete_server",
  async (id, thunkAPI) => {
    try {
      return await deleteServer(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_runners = createAsyncThunk("get_runners", async (thunkAPI) => {
  try {
    return await getRunners();
  } catch (error) {
    const message = getErrorMessage(error);
    return thunkAPI.rejectWithValue(message);
  }
});

export const gscServerSlice = createSlice({
  name: "gscServerSlice",
  initialState,
  reducers: {
    googleConsoleReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.runner_apps = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_gsc_server.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_gsc_server.fulfilled, (state, action) => {
        state.isLoading = false;
        state.runner_apps = action.payload?.data;
      })
      .addCase(get_gsc_server.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.runner_apps = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_gsc_server.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_gsc_server.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_gsc_server.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_gsc_server.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_gsc_server.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_gsc_server.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_gsc_server.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_gsc_server.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_gsc_server.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_available_gsc_server.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_available_gsc_server.fulfilled, (state, action) => {
        state.isLoading = false;
        state.availableServers = action.payload?.data;
      })
      .addCase(get_available_gsc_server.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.availableServers = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_runner_apps.pending, (state) => {
        state.isLoading = true;
        state.isLoader = true;
        state.runner_apps = [];
      })
      .addCase(get_runner_apps.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.runner_apps = action.payload?.data;
      })
      .addCase(get_runner_apps.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.runner_apps = [];
      })
      .addCase(create_server.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_server.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_server.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_server.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_server.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_server.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_server.fulfilled, (state, action) => {
        const result = state.runner_apps.findIndex(
          ({ id }) => id === action?.payload?.data?.id
        );
        state.runner_apps[result].active = false;
      })
      .addCase(get_runners.pending, (state) => {
        state.isLoading = true;
        state.isLoader = true;
        state.runners = [];
      })
      .addCase(get_runners.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.runners = action.payload?.data;
      })
      .addCase(get_runners.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.runners = [];
      });
  },
});

export const { googleConsoleReset } = gscServerSlice.actions;
export default gscServerSlice.reducer;
