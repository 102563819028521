import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { addTableRow, deleteTableRow, downloadDatabse, exportSampleTable, exportTable, getDbTables, getTableRows, importExcel, updateDatabse, updateTableRow } from "../services/databaseServices";

const initialState = {
  isError: false,
  isLoading: false,
  message: "",
  dbTables: [],
  tableRows: [],
  customTags: [],
};

// Get Database Tables
export const get_db_tables = createAsyncThunk(
  "get_db_tables",
  async (dbName, thunkAPI) => {
    try {
      return await getDbTables(dbName);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_table_rows = createAsyncThunk(
  "get_table_rows",
  async ({ dbName, tableName }, thunkAPI) => {
    try {
      return await getTableRows(dbName, tableName);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const export_table = createAsyncThunk(
  "export_table",
  async ({ dbName, tableName }, thunkAPI) => {
    try {
      return await exportTable(dbName, tableName);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const export_sample_table = createAsyncThunk(
  "export_sample_table",
  async ({ dbName, tableName }, thunkAPI) => {
    try {
      return await exportSampleTable(dbName, tableName);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const import_excel = createAsyncThunk(
  "import_excel",
  async ({ file, dbName, tableName }, thunkAPI) => {
    try {
      return await importExcel(file, dbName, tableName);
    } catch (error) {
      const message =
        error.response ||
        error.response.data ||
        error.response.data.message ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const update_table_rows = createAsyncThunk(
  "update_table_rows",
  async ({ dbName, tableName, data }, thunkAPI) => {
    try {
      return await updateTableRow(dbName, tableName, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const add_table_rows = createAsyncThunk(
  "add_table_rows",
  async ({ dbName, tableName, data }, thunkAPI) => {
    try {
      return await addTableRow(dbName, tableName, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const delete_table_rows = createAsyncThunk(
  "delete_table_rows",
  async ({ dbName, tableName, ids }, thunkAPI) => {
    try {
      return await deleteTableRow(dbName, tableName, ids);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const download_db = createAsyncThunk(
  "download_db",
  async (dbName, thunkAPI) => {
    try {
      return await downloadDatabse(dbName);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const update_database = createAsyncThunk(
  "update_database",
  async (id, thunkAPI) => {
    try {
      return await updateDatabse(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_custom_tags = createAsyncThunk(
  "get_custom_tags",
  async ({ dbName, tableName }, thunkAPI) => {
    try {
      return await getTableRows(dbName, tableName);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const databaseSlice = createSlice({
  name: "database",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.message = "";
    },

    add_new_record: (state, action) => {
      // eslint-disable-next-line
      action.payload.map((val) => {
        state.tableRows.data.push(val);
      });
    },
    delete_record: (state, action) => {
      // eslint-disable-next-line
      action.payload.map((id) => {
        state.tableRows.data = state.tableRows.data.filter(
          (row) => row.id !== +id
        );
      });
    },

    edit_record: (state, action) => {
      // eslint-disable-next-line
      action.payload.map((val) => {
        const result = state.tableRows.data.findIndex(
          ({ id }) => id === val?.id
        );
        state.tableRows.data[result] = {
          ...state.tableRows.data[result],
          ...val,
        };
      });
    },
    updateState: (state, action) => {
      state.tableRows.data = state.tableRows.data?.map((item, i) => {
        if (action.payload.index === i) {
          return { ...item, [action.payload.e.name]: action.payload.e.value };
        } else {
          return item;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_db_tables.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_db_tables.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dbTables = action.payload.data;
      })
      .addCase(get_db_tables.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_table_rows.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_table_rows.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tableRows = action.payload.data;
      })
      .addCase(get_table_rows.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(update_table_rows.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_table_rows.fulfilled, (state, action) => {
        state.isLoading = false;
       
      })
      .addCase(update_table_rows.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_table_rows.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_table_rows.fulfilled, (state) => {
        state.isLoading = false;
        
      })
      .addCase(delete_table_rows.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(add_table_rows.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_table_rows.fulfilled, (state, action) => {
        state.isLoading = false;
        
      })
      .addCase(add_table_rows.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(download_db.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(download_db.fulfilled, (state, action) => {
        state.isLoading = false;
        window.open(action.payload.data.path, "_blank")?.focus();
        toast.success("Database export successfully");
      })
      .addCase(download_db.rejected, (state) => {
        state.isLoading = false;
        toast.error("Database couldn't be exported");
      })
      .addCase(export_table.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(export_table.fulfilled, (state, action) => {
        state.isLoading = false;
        // window.open(action?.payload?.data?.path, "_blank")?.focus();
        // toast.success("Table export successfully");
      })
      .addCase(export_table.rejected, (state) => {
        state.isLoading = false;
        // toast.error("Table couldn't be exported");
      })
      .addCase(export_sample_table.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(export_sample_table.fulfilled, (state, action) => {
        state.isLoading = false;
        // window.open(action.payload.data.path, "_blank")?.focus();
        // toast.success("Sample Table export successfully");
      })
      .addCase(export_sample_table.rejected, (state) => {
        state.isLoading = false;
        // toast.error("Sample Table couldn't be exported");
      })
      .addCase(get_custom_tags.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_custom_tags.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customTags = action.payload.data;
      })
      .addCase(get_custom_tags.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(import_excel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(import_excel.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Table import successfully");
      })
      .addCase(import_excel.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(
          action.payload.data ? action.payload.data : "Table couldn't be import"
        );
      })
      .addCase(update_database.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_database.fulfilled, (state, action) => {
        state.isLoading = false;
        // const message= action.payload.data.message
        // window.open(action.payload.data.path, "_blank")?.focus();
        // toast.success(message?message:"Database update successfully");
      })
      .addCase(update_database.rejected, (state,action) => {
        state.isLoading = false;
        // const message= action.payload?action.payload:action.payload?.data?.message
        // window.open(action.payload.data.path, "_blank")?.focus();
        // toast.error(message?message:"Database couldn't be updated");
      });
  },
});

export const {
  reset,
  add_new_record,
  delete_record,
  edit_record,
  updateState,
} = databaseSlice.actions;
export default databaseSlice.reducer;
