import React from "react";
import {
  Button,
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { get_inbound_group } from "../../../features/inboundGroupSlice";
import { toast } from "react-toastify";
import FormMultiSelectInput from "../../../components/molecules/FormMultiSelectInput";
import {
  create_campaigns,
  create_dispositions,
  create_pause_codes,
  delete_dispositions,
  delete_pause_codes,
  get_dispositions,
  get_pause_codes,
  update_campaigns,
  update_dispositions,
  update_pause_codes,
} from "../../../features/campaignSlice";
import { Button as MUIButton } from "@material-ui/core";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import {
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import InputForm from "../../GeneralSetting/components/InputForms";
import SelectForm from "../../GeneralSetting/components/SelectForm";
import errorMessage from "../../../util/errorMessage";
function AddPauseCode({ editingRecord, onCancelForm, onSave, newRecord }) {
  const inbound = useSelector((state) => state.inbound);

  const { isLoading } = useSelector((state) => state.campaigns);
  const { record } = inbound;
  const dispatch = useDispatch();
  let initialDisposition = {
    answering_machine: "N",
    completed: "N",
    customer_contact: "N",
    dnc: "N",
    human_answered: "N",
    not_interested: "N",
    sale: "N",
    scheduled_callback: "N",
    selectable: "N",
    status: "",
    status_name: "",
    unworkable: "N",
  };
  let initialPauseCode = {
    pause_code: "",
    pause_code_name: "",
  };
  let initialValues = {
    campaign_name: "",
    active: "Y",
    allow_closers: "Y",
    campaign_allow_inbound: "Y",
    dial_method: "RATIO",
    local_call_time: "24hours",
    dial_prefix: "",
    manual_dial_prefix: "",
    campaign_cid: "",
    campaign_recording: "ALLFORCE",
    closer_campaigns: "",
    xfer_groups: "",
    auto_dial_level: "1",
  };
  let xfer_groups_ids = [];
  let closer_campaigns_ids = [];
  if (editingRecord) {
    const {
      campaign_id,
      campaign_name,
      active,
      allow_closers,
      campaign_allow_inbound,
      dial_method,
      local_call_time,
      dial_prefix,
      manual_dial_prefix,
      campaign_cid,
      campaign_recording,
      closer_campaigns,
      xfer_groups,
      auto_dial_level,
    } = editingRecord;
    closer_campaigns_ids = closer_campaigns;
    xfer_groups_ids = xfer_groups;
    initialValues = {
      id: campaign_id,
      campaign_name,
      active,
      allow_closers,
      campaign_allow_inbound,
      dial_method,
      local_call_time,
      dial_prefix,
      manual_dial_prefix,
      campaign_cid,
      campaign_recording,
      closer_campaigns: closer_campaigns_ids,
      xfer_groups: xfer_groups_ids,
      auto_dial_level,
    };
  }
  const [inGroupOptions, setInGroupOptions] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState("Campaigns");
  const [isEditing, setIsEditing] = React.useState(false);
  const [bodyPayload, setBodyPayload] = React.useState({});

  const [errors, setErrors] = React.useState([]);
  const [dispositions, setDispositions] = React.useState([]);
  const [pauseCodes, setPauseCode] = React.useState([]);
  const columnDefs = [
    {
      field: "action",
      headerName: "Action",
      type: "actions",
      cellClassName: "actions",
      disableColumnMenu: false,
      width: 120,
      getActions: (params) => {
        const actionItems = [
          <GridActionsCellItem
            icon={<FaTrash size={16} />}
            label="Delete"
            onClick={deleteRecordHandler({ status: params?.row.status })}
            color="inherit"
            title="Delete Desposition"
          />,
        ];
        actionItems.push(
          <GridActionsCellItem
            icon={<FaEdit size={16} />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params)}
            color="inherit"
            title="Update Disposition"
          />
        );
        return actionItems.reverse();
      },
    },
    { headerName: "Status", field: "status", flex: 1 },
    {
      headerName: "Status Name ",
      field: "status_name",
      flex: 1,
    },
    {
      headerName: "AGENT SELECTABLE",
      field: "selectable",
      flex: 1,
      type: "singleSelect",
    },
    {
      headerName: "HUMAN ANSWER",
      field: "human_answered",
      flex: 1,
      type: "singleSelect",
    },
    {
      headerName: "SALE",
      field: "sale",
      flex: 1,
      type: "singleSelect",
    },
    {
      headerName: "DNC",
      field: "dnc",
      flex: 1,
      type: "singleSelect",
    },
    {
      headerName: "SCHEDULED CALLBACK",
      field: "scheduled_callback",
      flex: 1,
      type: "singleSelect",
    },
    {
      headerName: "ANSWERING MACHINE",
      field: "answering_machine",
      // flex: 1,
      type: "singleSelect",
      editable: true,
      cellClassName: "editable-cell", // Add a custom class for styling
    },
  ];
  const pauseCodesColumns = [
    {
      field: "action",
      headerName: "Action",
      type: "actions",
      cellClassName: "actions",
      disableColumnMenu: false,
      width: 120,
      getActions: (params) => {
        const actionItems = [
          <GridActionsCellItem
            icon={<FaTrash size={16} />}
            label="Delete"
            onClick={deleteRecordHandler({
              pause_code: params?.row.pause_code,
            })}
            color="inherit"
            title="Delete Pause Code"
          />,
        ];
        actionItems.push(
          <GridActionsCellItem
            icon={<FaEdit size={16} />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params)}
            color="inherit"
            title="Update Pause Code"
          />
        );
        return actionItems.reverse();
      },
    },
    { headerName: "Campaign", field: "campaign_name", flex: 1 },
    { headerName: "Pause Code", field: "pause_code", flex: 1 },
    {
      headerName: "Pause Code Name",
      field: "pause_code_name",
      flex: 1,
      type: "singleSelect",
    },
  ];

  const getDispositions = async () => {
    const res = await dispatch(get_dispositions(formik.values.id));
    if (res.payload?.status) {
      const record = res.payload.data?.map((data, index) => ({
        id: index + 1,
        ...data,
      }));
      setDispositions(record);
    }
  };
  const getPauseCodes = async () => {
    const res = await dispatch(get_pause_codes(formik.values.id));
    if (res.payload?.status) {
      const record = res.payload.data?.map((data, index) => ({
        id: index + 1,
        campaign_name: formik.values.campaign_name,
        ...data,
      }));
      setPauseCode(record);
    }
  };
  React.useEffect(() => {
    if (activeTab === "Dispositions") {
      getDispositions();
    } else if (activeTab === "Pause Codes") {
      getPauseCodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleSubmit = async (values) => {
    const action = editingRecord ? update_campaigns : create_campaigns;
    const message = editingRecord ? "updated" : "created";
    try {
      const res = await dispatch(action(values));
      if (res.payload?.status === 200) {
        toast.success(`Campaign successfully  ${message}`);
        onSave(!newRecord);
        onCancelForm();
      } else {
        if (Array.isArray(res.payload)) {
          setErrors(res.payload);
        } else {
          toast.error(
            res.payload ? res.payload : `Campaign couldn't be ${message}`
          );
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload)) {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload ? error.payload : `Campaign couldn't be ${message}`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    // validationSchema: CampaignSchema,

    onSubmit: handleSubmit,
  });
  React.useEffect(() => {
    if (record === 0) {
      dispatch(get_inbound_group());
    }

    if (record?.length > 0) {
      let arr = [];
      // eslint-disable-next-line
      record?.map((val) => {
        arr = [...arr, { label: val?.group_name, value: val?.group_id }];
        setInGroupOptions(arr);
      });
    }
    // eslint-disable-next-line
  }, []);
  const closer_campaigns_handler = (e) => {
    let arr = [];
    // eslint-disable-next-line
    e?.map((val) => {
      arr = [...arr, val?.value];
    });
    formik.setFieldValue("closer_campaigns", arr);
  };
  const xfer_groups_handler = (e) => {
    let arr = [];
    // eslint-disable-next-line
    e?.map((val) => {
      arr = [...arr, val?.value];
    });
    formik.setFieldValue("xfer_groups", arr);
  };

  let default_closer_campaigns = [];
  closer_campaigns_ids?.map((val) => {
    const selectedIndex = record.findIndex((item) => item.group_id === val);
    default_closer_campaigns = [
      ...default_closer_campaigns,
      {
        name: `${record[selectedIndex]?.group_id} - ${record[selectedIndex]?.group_name}`,
        _id: record[selectedIndex]?.group_id,
      },
    ];
    return { default_closer_campaigns };
  });

  let default_xfer_groups = [];
  xfer_groups_ids?.map((val) => {
    const selectedIndex = record.findIndex((item) => item.group_id === val);
    default_xfer_groups = [
      ...default_xfer_groups,
      {
        name: `${record[selectedIndex]?.group_id} - ${record[selectedIndex]?.group_name}`,
        _id: record[selectedIndex]?.group_id,
      },
    ];
    return { default_xfer_groups };
  });
  const handleNewRecord = () => {
    if (activeTab === "Dispositions") {
      setBodyPayload(initialDisposition);
    } else {
      setBodyPayload(initialPauseCode);
    }
    setIsEditing(true);
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <MUIButton
          variant="text"
          startIcon={<FaPlus size={18} />}
          sx={{ fontSize: "0.8125rem", background: "" }}
          onClick={handleNewRecord}
          className="text-black btnSecondary text-sm "
        >
          Add {activeTab}
        </MUIButton>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
      </GridToolbarContainer>
    );
  }
  const cancelFormHandler = () => {
    setBodyPayload(null);
    setIsEditing(false);
  };

  // const openFormHandler = (record) => () => {
  //   setBodyPayload(record);
  //   setIsEditing(true);
  // };
  const handleEditClick = (params) => () => {
    setBodyPayload(params?.row);
    setIsEditing(true);
  };
  const addUpdateDispositions = async () => {
    try {
      const action =
        activeTab === "Dispositions"
          ? bodyPayload.id
            ? update_dispositions
            : create_dispositions
          : activeTab === "Pause Codes"
          ? bodyPayload.id
            ? update_pause_codes
            : create_pause_codes
          : null;
      // delete bodyPayload?.id;
      const res = await dispatch(
        action({ campaign_id: formik.values.id, ...bodyPayload })
      );
      if (res?.payload?.data?.status) {
        if (activeTab === "Dispositions") {
          getDispositions();
        } else if (activeTab === "Pause Codes") {
          getPauseCodes();
        }
        toast.success(res.payload.data.message);
        cancelFormHandler();
      }
    } catch (err) {
      console.error(
        "🚀 ~ file: PauseCode.jsx:513 ~ processRowUpdate ~ err:",
        err
      );
    }
  };
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const action =
          activeTab === "Dispositions"
            ? delete_dispositions
            : delete_pause_codes;
        const res = await dispatch(
          action({ campaign_id: formik.values.id, ...record_id })
        );
        if (res.payload?.status === 200) {
          toast.success(`${activeTab} deleted`);
          if (activeTab === "Dispositions") {
            getDispositions();
          } else if (activeTab === "Pause Codes") {
            getPauseCodes();
          }
        } else {
          errorMessage({
            payload: res.payload,
            action: activeTab,
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: activeTab, msg: "deleted" });
      }
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBodyPayload({
      ...bodyPayload,
      [name]: value,
    });
  };
  const options = [
    { label: "Yes", value: "Y" },
    { label: "No", value: "N" },
  ];
  return (
    <>
      <Modal
        // isUpdate={!!editingRecord}
        title={`${
          isEditing ? (bodyPayload?.id ? "Update" : "Add New") : ""
        } ${activeTab}`}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={
          activeTab === "Campaigns"
            ? formik.handleSubmit
            : activeTab === "Dispositions"
            ? ""
            : ""
        }
        isLoading={isLoading}
        onClick={onCancelForm}
        hideButtons={isEditing}
      >
        {isLoading ? <DotsLoader /> : null}
        <Tabs>
          <TabList className="!flex !flex-wrap !pl-0 react-tabs__tab-list !border-b-[#dee2e6]">
            <Tab
              selectedClassName="!bg-primary-100 text-white !border-2 !border-[#5f5f5f] !border-b-0"
              className="!flex !justify-between !rounded-t !mx-2 !py-4 bg-[#f9f9f9] border border-[#dee2e6] -bottom-px relative py-1.5 px-3 cursor-pointer flex-[0_0_23%] w-full md:max-w-[23%]"
              onClick={() => {
                cancelFormHandler();
                setActiveTab("Update Campaigns");
              }}
            >
              <p className="font mb-0 truncate w-full text-normal font-semibold">
                Campaigns
              </p>
            </Tab>
            <Tab
              selectedClassName="!bg-primary-100 text-white text-xl !border-2 !border-primary-100 !border-b-0"
              className="!flex !justify-between !rounded-t !mx-2 !py-4 bg-[#f9f9f9] border !border-primary-100 -bottom-px relative py-1.5 px-3 cursor-pointer flex-[0_0_23%] w-full md:max-w-[23%]"
              onClick={() => {
                cancelFormHandler();
                setActiveTab("Dispositions");
              }}
            >
              <p className="font mb-0 truncate w-full text-normal font-semibold">
                Dispositions
              </p>
            </Tab>
            <Tab
              selectedClassName="!bg-primary-100 text-white !border-2 !border-[#5f5f5f] !border-b-0"
              className="!flex !justify-between !rounded-t !mx-2 !py-4 bg-[#f9f9f9] border border-[#dee2e6] -bottom-px relative py-1.5 px-3 cursor-pointer flex-[0_0_23%] w-full md:max-w-[23%]"
              onClick={() => {
                cancelFormHandler();
                setActiveTab("Pause Codes");
              }}
            >
              <p className="font mb-0 truncate w-full text-normal font-semibold">
                Pause Codes
              </p>
            </Tab>
          </TabList>
          {/* Campaigns */}
          <TabPanel>
            <div className="bg-white my-3 border rounded w-full min-w-[900px] min-h-[50vh]">
              <form className="grid md:grid-cols-4 gap-x-5 !mt-3 mx-2.5">
                <div className="mb-4">
                  <FormInput
                    errors={errors}
                    name="campaign_name"
                    label="Campaign Name"
                    formik={formik}
                  />
                </div>

                <div className="mb-4">
                  <FormSelectInput
                    errors={errors}
                    name="active"
                    label="Active"
                    formik={formik}
                    options={[
                      { value: "Y", label: "Y" },
                      { value: "N", label: "N" },
                    ]}
                    valueProp="value"
                    labelProp="label"
                  />
                </div>

                <div className="mb-4">
                  <FormSelectInput
                    errors={errors}
                    name="allow_closers"
                    label="Allow Closer"
                    formik={formik}
                    options={[
                      { value: "Y", label: "Y" },
                      { value: "N", label: "N" },
                    ]}
                    valueProp="value"
                    labelProp="label"
                  />
                </div>

                <div className="mb-4">
                  <FormSelectInput
                    errors={errors}
                    name="campaign_allow_inbound"
                    label="Allow Inbound and Blended"
                    formik={formik}
                    options={[
                      { value: "Y", label: "Y" },
                      { value: "N", label: "N" },
                    ]}
                    valueProp="value"
                    labelProp="label"
                  />
                </div>

                <div className="mb-4">
                  <FormSelectInput
                    errors={errors}
                    name="dial_method"
                    label="Dial Method"
                    formik={formik}
                    options={[
                      { value: "MANUAL", label: "MANUAL" },
                      { value: "RATIO", label: "RATIO" },
                      { value: "ADAPT_HARD_LIMIT", label: "ADAPT HARD LIMIT" },
                      { value: "ADAPT_TAPERED", label: "ADAPT TAPERED" },
                      { value: "ADAPT_AVERAGE", label: "ADAPT AVERAGE" },
                      { value: "INBOUND_MAN", label: "INBOUND MAN" },
                    ]}
                    valueProp="value"
                    labelProp="label"
                  />
                </div>
                <div className="mb-4">
                  <FormInput
                    errors={errors}
                    name="dial_prefix"
                    label="Dial Prefix"
                    formik={formik}
                  />
                </div>
                <div className="mb-4">
                  <FormInput
                    errors={errors}
                    name="manual_dial_prefix"
                    label="Manual Dial Prefix"
                    formik={formik}
                  />
                </div>

                <div className="mb-4">
                  <FormInput
                    errors={errors}
                    name="campaign_cid"
                    label="Campaign CallerID"
                    formik={formik}
                  />
                </div>

                <div className="mb-4">
                  <FormMultiSelectInput
                    errors={errors}
                    name="closer_campaigns"
                    label="Allowed Inbound Groups"
                    defaultValue={
                      editingRecord ? default_closer_campaigns : null
                    }
                    formik={formik}
                    userOptions={inGroupOptions}
                    multiSelectHandler={closer_campaigns_handler}
                  />
                </div>

                <div className="grid-span-6">
                  <FormMultiSelectInput
                    errors={errors}
                    name="xfer_groups"
                    label="Allowed Transfer Groups"
                    defaultValue={editingRecord ? default_xfer_groups : null}
                    formik={formik}
                    userOptions={inGroupOptions}
                    multiSelectHandler={xfer_groups_handler}
                  />
                </div>
              </form>
            </div>
          </TabPanel>
          {/* Dispositions */}
          <TabPanel>
            {isEditing && activeTab === "Dispositions" ? (
              <>
                <div className="bg-white my-3 border rounded w-full min-w-[900px] min-h-[50vh]">
                  <form className="grid md:grid-cols-4 gap-x-5 !mt-3 mx-2.5">
                    <div className="mb-4">
                      <InputForm
                        name="status"
                        placeholder="Status"
                        onChange={(e) => handleInputChange(e)}
                        value={bodyPayload?.status}
                        type="text"
                      />
                    </div>
                    <div className="mb-4">
                      <InputForm
                        name="status_name"
                        placeholder="Status Name"
                        onChange={(e) => handleInputChange(e)}
                        value={bodyPayload?.status_name}
                        type="text"
                      />
                    </div>
                    <div className="mb-3">
                      <SelectForm
                        name="selectable"
                        options={options}
                        label="Agent Selectable"
                        type="text"
                        labelProp="label"
                        valueProp="value"
                        value={bodyPayload?.selectable}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>
                    <div className="mb-3">
                      <SelectForm
                        name="human_answered"
                        options={options}
                        label="Human Ansered"
                        type="text"
                        labelProp="label"
                        valueProp="value"
                        value={bodyPayload?.human_answered}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>
                    <div className="mb-3">
                      <SelectForm
                        name="sale"
                        options={options}
                        label="Sale"
                        type="text"
                        labelProp="label"
                        valueProp="value"
                        value={bodyPayload?.sale}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>
                    <div className="mb-3">
                      <SelectForm
                        name="dnc"
                        options={options}
                        label="DNC"
                        type="text"
                        labelProp="label"
                        valueProp="value"
                        value={bodyPayload?.dnc}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>
                    <div className="mb-3">
                      <SelectForm
                        name="scheduled_callback"
                        options={options}
                        label="Scheduled Callback"
                        type="text"
                        labelProp="label"
                        valueProp="value"
                        value={bodyPayload?.scheduled_callback}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>
                    <div className="mb-3">
                      <SelectForm
                        name="answering_machine"
                        options={options}
                        label="Answering Machine"
                        type="text"
                        labelProp="label"
                        valueProp="value"
                        value={bodyPayload?.answering_machine}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>
                  </form>
                </div>

                <div className=" flex justify-between">
                  <Button
                    text="Close"
                    className="mx-2"
                    onClick={onCancelForm}
                    variant="btn_danger"
                  />
                  <div>
                    <Button
                      text="Back"
                      className="mx-2"
                      onClick={cancelFormHandler}
                      variant="btn_cancel"
                    />
                    <Button
                      text={bodyPayload?.id ? `Update` : "Save"}
                      className=""
                      onClick={addUpdateDispositions}
                      variant="btn_submit"
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="bg-white my-3 border rounded w-full min-w-[900px]">
                <MUIDataTable
                  items={dispositions}
                  columnDefs={columnDefs}
                  pagination="No"
                  height="46vh"
                  searchable="No"
                  displayCount="No"
                  CustomToolbar={CustomToolbar}
                />
              </div>
            )}
          </TabPanel>
          {/* Pause Codes */}
          <TabPanel>
            {isEditing && activeTab === "Pause Codes" ? (
              <>
                <div className="bg-white my-3 border rounded w-full min-w-[900px] min-h-[50vh]">
                  <form className="grid md:grid-cols-2 gap-x-5 !mt-3 mx-2.5">
                    <div className="mb-4">
                      <InputForm
                        name="pause_code"
                        placeholder="Pause Code"
                        onChange={(e) => handleInputChange(e)}
                        value={bodyPayload?.pause_code}
                        type="text"
                      />
                    </div>
                    <div className="mb-4">
                      <InputForm
                        name="pause_code_name"
                        placeholder="Pause Code Name"
                        onChange={(e) => handleInputChange(e)}
                        value={bodyPayload?.pause_code_name}
                        type="text"
                      />
                    </div>
                  </form>
                </div>
                <div className=" flex justify-between">
                  <Button
                    text="Close"
                    className="mx-2"
                    onClick={onCancelForm}
                    variant="btn_danger"
                  />
                  <div>
                    <Button
                      text="Back"
                      className="mx-2"
                      onClick={cancelFormHandler}
                      variant="btn_cancel"
                    />
                    <Button
                      text={bodyPayload?.id ? `Update` : "Save"}
                      className=""
                      onClick={addUpdateDispositions}
                      variant="btn_submit"
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="bg-white my-3 border rounded w-full min-w-[900px]">
                <MUIDataTable
                  items={pauseCodes}
                  columnDefs={pauseCodesColumns}
                  pagination="No"
                  height="46vh"
                  searchable="No"
                  displayCount="No"
                  isLoading={isLoading}
                  CustomToolbar={CustomToolbar}
                />
              </div>
            )}
          </TabPanel>
        </Tabs>
      </Modal>
    </>
  );
}

export default AddPauseCode;
