import React, { useState } from "react";
import { DotsLoader, Modal, FormSelectInput } from "../../components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import api from "../../services/api";
import TagsInput from "../../components/atoms/CustomTagsInput";
import errorMessage from "../../util/errorMessage";

const AddPhoneModal = ({ onCancelForm, modalTitle, data, onPageSubmit }) => {
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([]);

  const initialValues = {
    domain: "",
    phones: "",
  };

  const PhoneNumberSchema = Yup.object().shape({
    domain: Yup.string().required("Domain is required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: PhoneNumberSchema,
    onSubmit: async (values) => {
      if (!phoneNumbers?.length) {
        toast.error("Phone Number required!");
        return;
      }
      setIsLoading(true);
      try {
        const payload = {
          domain: data?.records.find((record) => record._id === values.domain)
            ?.domain,
          phones: phoneNumbers,
        };
        const res = await api.post("/api/nation/add_phone_number", payload);
        if (res.status === 200 || res.status === 201) {
          toast.success("Phone Number Added successfully.");
          onPageSubmit();
        } else {
          errorMessage({
            payload: res.payload,
            setErrors: setErrors,
            action: "Phone Number",
            msg: "Added",
          });
        }
      } catch (err) {
        if (err?.response?.data?.error) {
          errorMessage({
            payload: err?.response?.data?.error,
            setErrors: setErrors,
            action: "Phone Number",
            msg: "Added",
          });
        } else {
          toast.error(err?.response?.data || "An error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <Modal
      title={modalTitle}
      onCancelModal={onCancelForm}
      formIsValid={formik.isValid && formik.dirty}
      onSubmit={formik.handleSubmit}
      isLoading={isLoading}
      onClick={onCancelForm}
    >
      {isLoading && <DotsLoader />}

      <div className="mb-3">
        <FormSelectInput
          name="domain"
          label="Domain"
          formik={formik}
          options={data?.records}
          labelProp="domain"
          valueProp="_id"
          errors={errors}
        />
      </div>
      <div className="col-span-4 mt-2">
        <TagsInput
          tags={phoneNumbers}
          setTags={setPhoneNumbers}
          placeholder="Add phone number"
          name="phones"
        />
      </div>
      {phoneNumbers.length ? (
        <span className="font-semibold text-sm ml-1 text-gray-500">
          count : {phoneNumbers.length}
        </span>
      ) : null}
    </Modal>
  );
};

export default AddPhoneModal;
