import React, { useState } from "react";
import { DotsLoader } from "../../../../components";
import MUIDataTable from "../../../../components/molecules/DataTable/muigrid";
import PageHeader from "../../../../components/molecules/PageHeader";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import dayjs from "dayjs";
function SERPKeywordsSearches() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [record, setRecord] = useState([]);
  const [batches, setBatches] = useState([]);
  const params = useParams();
  const { batchId } = params;
  const [filters, setFilters] = React.useState({
    batch_id: batchId ? batchId : "",
    totalItems: 0,
    pageSize: 1000,
    page: 1,
  });
  const getSERPBatches = async (filter) => {
    setIsLoading(true);
    try {
      const res = await api.get("/api/domains/list_batches", {
        params: { ...filter },
      });
      if (res.status === 200) {
        setBatches(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getSERPBatches ~ err:", err);
    }
  };
  const getSERPReport = async (filter) => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `/api/domains/list_searches/${filter?.batch_id}/${filter?.page}`
      );
      if (res.status === 200) {
        setRecord(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  React.useEffect(() => {
    getSERPBatches();
  }, []);
  React.useEffect(() => {
    if (batchId && batches?.length > 0) {
      // getSERPReport();
      const totalItems = batches?.find(
        ({ valueserp_id }) => valueserp_id === batchId
      )?.searches_total_count;
      getSERPReport({ page: 1, batch_id: batchId });
      setFilters({
        page: 1,
        batch_id: batchId,
        totalItems,
        pageSize: 1000,
      });
    }
  }, [batchId, batches]);

  const columnDefs = [
    { headerName: "#", field: "counter", width: 80, filterable: false },
    {
      field: "domain",
      headerName: "Domain",
      renderCell: (params) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.row["domain"]}`}
            className="text-[#000] hover:text-blue-600 hover:underline no-underline"
          >
            {params?.row["domain"]}
          </a>
        );
      },
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Keywords",
      field: "q",
      flex: 1,
    },
    { headerName: "Primary", field: "is_primary", flex: 1 },
    {
      headerName: "Latest Position",
      field: "latest_position",
      flex: 1,
      filterable: false,
      align: "center",
    },
    {
      headerName: "Position History",
      field: "position_history",
      flex: 1,
      filterable: false,
      align: "center",
      renderCell: (params) =>
        params?.row?.position_history?.length > 0 ? (
          <div className="flex justify-center items-center w-full">
            <Tooltip
              title={
                <div className="flex flex-col text-sm w-[full]">
                  {params?.row?.position_history?.map((item, index) => (
                    <span key={index}>
                      {`${index + 1}. Date: ${dayjs(item?.on_date).format(
                        "ddd, MMM D, YYYY h:mm:ss A"
                      )}, Position: ${item?.position}`}
                    </span>
                  ))}
                </div>
              }
            >
              <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
                {params?.row?.position_history?.length}
              </span>
            </Tooltip>
          </div>
        ) : null,
    },
    { headerName: "Location", field: "location", flex: 1 },
  ];
  const domainList = record?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const SingleSelectRenderer = () => {
    return (
      <div className="flex">
        <select
          onChange={(e) => {
            const totalItems = batches?.find(
              ({ valueserp_id }) => valueserp_id === e.target.value
            )?.searches_total_count;
            getSERPReport({ page: 1, batch_id: e.target.value });
            setFilters({
              page: 1,
              batch_id: e.target.value,
              totalItems,
              pageSize: 1000,
            });
          }}
          value={filters?.batch_id}
          className="border p-1 rounded h-[35px] ml-2 w-full md:min-w-[250px]"
        >
          <option value="">Select Batch</option>
          {batches?.map((option) => (
            <option
              key={option?._id}
              value={option?.valueserp_id}
              className="flex justify-between"
            >
              {option?.name}
            </option>
          ))}
        </select>
      </div>
    );
  };
  const handlePageSizeChange = () => {
    toast.error("Page size couldn't be changed");
  };
  const handlePageChange = (params) => {
    setFilters({ ...filters, pageSize: 1000, page: params.page + 1 });
    getSERPReport({ batch_id: filters.batch_id, page: params.page + 1 });
  };
  return (
    <>
      <PageHeader
        route="Setting > Industries"
        heading="Searches"
        // onClick={() => setIsEditing(true)}
        // isAllowed={userPermission("Add Industry")}
        // buttonTitle="Add keywords"
      />
      {isLoading ? <DotsLoader /> : null}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={domainList?.map((record, index) => {
            const counter = index + 1;
            const {
              domain,
              is_primary,
              location,
              q,
              position_history,
              latest_position,
            } = record;
            return {
              records: { ...record, _id: counter },
              counter,
              domain,
              is_primary: is_primary ? "Yes" : "No",
              location,
              q,
              position_history,
              latest_position: latest_position?.matched
                ? `${latest_position?.matched?.position} - ${dayjs(
                    latest_position?.on_date
                  )?.format("DD/MM/YYYY")}`
                : null,
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          totalItems={filters?.totalItems}
          isLoading={isLoading}
          CustomComponent={SingleSelectRenderer}
          paginationModel={filters}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          hidePageSelector="Yes"
          customSize={1000}
        />
      </div>
    </>
  );
}

export default SERPKeywordsSearches;
