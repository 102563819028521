import React, { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { Dropdown } from "primereact/dropdown";
import {
  get_job_status,
  get_job_status_with_job_count,
  migrate_job_status,
} from "../../features/jobStatusSlice";
import { useNavigate } from "react-router-dom";
const MigrateJobStatus = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, jobStatuses, record } = useSelector(
    (state) => state.jobStatus
  );
  const [searchText, setSearchText] = useState("");
  const [assignInbound, setAssignInbound] = useState({
    oldJobStatusID: null,
    newJobStatusID: null,
  });

  useEffect(() => {
    dispatch(get_job_status_with_job_count());
    dispatch(
      get_job_status({
        active: 1,
        get_all_records: 1,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAssign = async () => {
    if (assignInbound?.oldJobStatusID && assignInbound?.newJobStatusID) {
      const old = jobStatuses?.find(
        ({ _id }) => _id === assignInbound?.oldJobStatusID
      )?.name;
      const newStatus = record?.records?.find(
        ({ _id }) => _id === assignInbound?.newJobStatusID
      )?.name;
      const c = window.confirm(
        `Are you sure you want to migrate from ${old} to ${newStatus}?`
      );
      if (c) {
        try {
          const res = await dispatch(migrate_job_status(assignInbound));
          if (res?.payload?.status === 200) {
            toast.success("Status migrated successfully");
            await dispatch(get_job_status_with_job_count());
            setAssignInbound({ oldJobStatusID: null, newJobStatusID: null });
            // cancelFormHandler();
          } else {
            errorMessage({
              payload: res.payload,
              action: "Status",
              msg: "migrated",
            });
          }
        } catch (err) {
          console.log("👊 ~ handleAssign ~ err:", err);
          errorMessage({
            payload: err.payload,
            action: "Inbound Group",
            msg: "assigned",
          });
        }
      }
    }
  };
  const jobStatus = jobStatuses?.filter((status) =>
    status?.name?.toLowerCase().includes(searchText.toLowerCase())
  );

  const columnDefs = [
    { headerName: "#", field: "counter", width: 100, filterable: false },
    { headerName: "Job Status", field: "name", flex: 1 },
    {
      headerName: "Job Count",
      field: "jobsCount",
      flex: 1,
      renderCell: (params) => (
        <button
          onClick={() => navigate(`/jobs/${params?.row?.records?._id}`)}
          className="text-blue-500"
        >
          {params?.row?.jobsCount}
        </button>
      ),
    },
    {
      headerName: "New Job Status",
      field: "job_status",
      minWidth: 300,
      filterable: false,
      renderCell: (params) => (
        <Dropdown
          value={
            assignInbound?.oldJobStatusID === params?.row?.records?._id
              ? assignInbound?.newJobStatusID
              : null
          }
          onChange={(e) => {
            setAssignInbound({
              oldJobStatusID: params?.row?.records?._id,
              newJobStatusID: e.value,
            });
          }}
          options={record?.records}
          optionLabel="name"
          optionValue="_id"
          placeholder="Select New Job Status"
          className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
          filter
          // disabled={!!params.row.records?.inbound_group_id}
        />
      ),
    },
    {
      headerName: "Action",
      field: "actions",
      filterable: false,
      renderCell: (params) => (
        <div className="flex flex-row justify-center items-center">
          {assignInbound?.oldJobStatusID === params?.row?.records?._id ? (
            <div className="flex justify-center">
              {userPermission("Update Industry") && (
                <FaSave onClick={handleAssign} className="my_navIcon" />
              )}
            </div>
          ) : null}
        </div>
      ),

      width: 150,
    },
  ];
  return (
    <div>
      <PageHeader route="Setting > Industries" heading="Migrate Job Status" />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={jobStatus?.map((record, index) => {
            const { name, jobsCount, _id } = record;
            let counter = index + 1;
            return {
              records: record,
              counter,
              name,
              jobsCount,
              _id,
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
          density="standard"
        />
      </div>
    </div>
  );
};

export default MigrateJobStatus;
