import React, { useEffect, useState } from "react";
import { Button } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { Dropdown } from "primereact/dropdown";
import {
  get_aoo_data_report,
  resetAOOReport,
  get_aoo_filters,
} from "../../features/reportsSlice";
import JobListing from "./JobListing";
import { toast } from "react-toastify";
import { utils, writeFile } from "xlsx";
import ReportHead from "../Components/Header";
import JobsOnMap from "./MapView";
import { FaMapMarkedAlt, FaTable } from "react-icons/fa";
import { MultiSelect } from "primereact/multiselect";
import Picker from "../Components/Picker";
const OOAStatusReport = () => {
  const dispatch = useDispatch();
  const { isLoading, aooDataReport, aooDataFilters } = useSelector(
    (state) => state.reports
  );
  const [editingRecord, setEditingRecord] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isMapView, setIsMapView] = useState(false);
  const [searchText, setSearchText] = useState("");
  const currentDate = new Date();
  const initialFilter = {
    date_from:
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        0,
        0,
        0,
        0
      )
        .toISOString()
        .slice(0, 23) + "Z",
    date_to: new Date().toISOString().slice(0, 23) + "Z",
    industry_ids: [],
    job_status_ids: [],
    output_by: "",
    price_to: "",
    price_from: "",
  };
  const [filterData, setfilterData] = useState(initialFilter);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setIsMapView(false);
  };
  const openFormHandler = (data) => {
    setEditingRecord(data);
    setIsEditing(true);
  };
  const openMapHandler = (data) => {
    setEditingRecord(data);
    setIsMapView(true);
  };
  const outputByOption = [
    { label: "State", value: "state" },
    { label: "City", value: "city" },
    { label: "Zip", value: "zip" },
  ];
  useEffect(() => {
    dispatch(get_aoo_filters(filterData));
    // eslint-disable-next-line
  }, [
    filterData.industry_ids,
    filterData.job_status_ids,
    filterData.date_from,
    filterData.date_to,
  ]);
  const logs = aooDataReport?.filter((log) => {
    const searchTextLower = searchText.toLowerCase();
    return (
      searchTextLower === "" ||
      log?.pickup_state?.toLowerCase().includes(searchTextLower) ||
      log?.pickup_city?.toLowerCase().includes(searchTextLower) ||
      log?.pickup_zip?.toLowerCase().includes(searchTextLower) ||
      log.pickup_state === null ||
      log.pickup_city === null ||
      log.pickup_zip === null
    );
  });
  const statusCounts = {};
  logs.forEach((item) => {
    const { job_status_id, count } = item;
    const jobStatus = aooDataFilters?.job_statuses?.find(
      ({ _id }) => _id === job_status_id
    )?.name;
    if (!statusCounts[jobStatus]) {
      statusCounts[jobStatus] = {
        "Total Count": 0,
      };
    }
    statusCounts[jobStatus]["Total Count"] += count;
  });

  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    {
      field: "show by",
      renderCell: (params) => (
        <div className="flex flex-row">
          <FaTable
            onClick={() => openFormHandler(params.row?.leads)}
            className="my_navIcon"
          />
          <FaMapMarkedAlt
            onClick={() => openMapHandler(params.row?.leads)}
            className="my_navIcon"
          />
        </div>
      ),
      width: 100,
    },
    { headerName: "State", field: "pickup_state", flex: 1, minWidth: 200 },
    { headerName: "City", field: "pickup_city", flex: 1, minWidth: 150 },
    { headerName: "Zip", field: "pickup_zip", flex: 1, minWidth: 150 },
    {
      headerName: "Total Count",
      field: "total_count",
      renderCell: (params) => (
        <div
          className="text-blue-600 hover:underline cursor-pointer"
          onClick={() => openFormHandler(params.row.leads)}
        >
          {params.row.total_count}
        </div>
      ),
      minWidth: 150,
      sortable: true,
      valueGetter: (params) => params.row.total_count, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2, // Provide a sorting function
    },
    ...Object.keys(statusCounts).map((status) => ({
      field: `${status}`,
      renderCell: (params) => (
        <div
        >
          {params.row[status]?params.row[status]:'-'}
        </div>
      ),
      minWidth: 150,
      sortable: true,
      valueGetter: (params) => params.row[status], // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2,

    })),
  ];
  useEffect(() => {
    dispatch(resetAOOReport());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReport = async () => {
    if (
      !filterData?.industry_ids?.length === 0 ||
      !filterData?.job_status_ids?.length === 0 ||
      !filterData?.output_by
    ) {
      return toast.error("Industry, Job Status and Output by are required...");
    }
    try {
      const res = await dispatch(get_aoo_data_report(filterData));
      if (res.payload?.status === 200) {
        if (res.payload.data?.length === 0) {
          return toast.error("No record found");
        }
      }
    } catch (err) {
      console.error("🚀 ~ file: index.jsx:100 ~ getReport ~ err:", err);
    }
  };

  const exportToExcel = () => {
    try {
      const workbook = utils.book_new();
      const sheetName = "AOO Status Report";
      const headings = ["Sr.", "Count", "Job Status", "State", "City", "Zip"];
      const formattedData = aooDataReport?.map((item, index) => [
        index + 1,
        item?.count ? item?.count : "-",
        aooDataFilters?.job_statuses?.find(
          ({ _id }) => _id === item?.job_status_id
        )?.name,
        item?.pickup_state ? item?.pickup_state : "-",
        item?.pickup_city ? item?.pickup_city : "-",
        item?.pickup_zip ? item?.pickup_zip : "-",
      ]);

      const worksheetData = [headings, ...formattedData];
      const worksheet = utils.aoa_to_sheet(worksheetData);
      utils.book_append_sheet(workbook, worksheet, sheetName);

      writeFile(workbook, "AOO Status Report.xlsx");
    } catch (err) {
      console.error("🚀 ~ file: index.jsx:358 ~ exportToExcel ~ err:", err);
    }
  };

  // Use reduce to accumulate the data
  const summaryData = aooDataReport.reduce((acc, item) => {
    const { pickup_state, job_status_id, count, pickup_city, pickup_zip, leads } =
      item;
    const jobStatus = aooDataFilters?.job_statuses?.find(
      ({ _id }) => _id === job_status_id
    )?.name;

    let key;

    // Determine the key based on the priority of pickup_zip, pickup_city, and pickup_state
    if (pickup_zip) {
      key = `${pickup_state}_${pickup_city}_${pickup_zip}`;
    } else if (pickup_city) {
      key = `${pickup_state}_${pickup_city}`;
    } else {
      key = pickup_state;
    }

    // Create an entry for the key if it doesn't exist
    if (!acc[key]) {
      acc[key] = {
        pickup_state: pickup_state ? pickup_state : "-",
        pickup_city: pickup_city ? pickup_city : "-",
        pickup_zip: pickup_zip ? pickup_zip : "-",
        total_count: 0,
        leads:[]
      };
    }
    // Update total_count and job_status count for the key
    acc[key].total_count += count;
    acc[key].leads=[...acc[key].leads,...leads];
    acc[key][jobStatus] = (acc[key][jobStatus] || 0) + count;
    return acc;
  }, {});
  const summaryArray = Object.values(summaryData);

  const summaryArrayWithSrNo = summaryArray.map((item, index) => ({
    ...item,
    counter: index + 1,
  }));
  return (
    <>
      {isEditing && (
        <JobListing onCancelModal={cancelFormHandler} data={editingRecord} />
      )}

      {isMapView && (
        <JobsOnMap onCancelModal={cancelFormHandler} records={editingRecord} />
      )}
      <ReportHead route="Reports > Commission Report" heading="Login IP" />
      <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
        <div className="md:col-span-9">
          <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
            <div className="md:col-span-3 !px-0.5">
              <label className="inputLabel" htmlFor="industries">
                {"Industries"}
              </label>
              <MultiSelect
                placeholder="Select Industry"
                options={aooDataFilters?.industries}
                optionLabel={(option) =>
                  `${option?.name} (${option?.jobs_count})`
                }
                optionValue="_id"
                filter
                value={filterData?.industry_ids}
                onChange={(e) => {
                  setfilterData({ ...filterData, industry_ids: e.value });
                }}
                className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              />
            </div>
            <div className="md:col-span-3 !px-0.5">
              <label className="inputLabel" htmlFor="vendors">
                {"Job Status"}
              </label>
              <MultiSelect
                placeholder="Select Status"
                options={aooDataFilters?.job_statuses}
                optionLabel={(option) =>
                  `${option?.name} (${option?.jobs_count})`
                }
                optionValue="_id"
                filter
                maxSelectedLabels={2}
                value={filterData?.job_status_ids}
                onChange={(e) => {
                  setfilterData({ ...filterData, job_status_ids: e.value });
                }}
                className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              />
            </div>
            <div className="md:col-span-3 !px-0.5">
              <label className="inputLabel" htmlFor="vendors">
                {"Output By"}
              </label>
              <Dropdown
                placeholder="Select Output By"
                options={outputByOption}
                optionLabel="label"
                optionValue="value"
                value={filterData?.output_by}
                onChange={(e) => {
                  setfilterData({ ...filterData, output_by: e.value });
                }}
                className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              />
            </div>
            <div className="md:col-span-3 !px-0.5">
              <div className="grid grid-cols-12 gap-2">
                <div className="md:col-span-6 !px-0.5">
                  <label className="inputLabel" htmlFor="vendors">
                    {"Price From"}
                  </label>
                  <input
                    className="p-2 h-[38px] w-full !rounded-[4px] focus:!shadow-none border"
                    onBlur={(e) => {
                      delete filterData?.output_by;
                      dispatch(get_aoo_filters(filterData));
                    }}
                    onChange={(e) =>
                      setfilterData({
                        ...filterData,
                        price_from: e.target.value,
                      })
                    }
                    value={filterData?.price_from}
                  />
                </div>
                <div className="md:col-span-6 !px-0.5">
                  <label className="inputLabel" htmlFor="vendors">
                    {"Price To"}
                  </label>
                  <input
                    className=" p-2 h-[38px] w-full !rounded-[4px] focus:!shadow-none border"
                    onBlur={(e) => {
                      delete filterData?.output_by;
                      dispatch(get_aoo_filters(filterData));
                    }}
                    onChange={(e) =>
                      setfilterData({ ...filterData, price_to: e.target.value })
                    }
                    value={filterData?.price_to}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-3 flex justify-center !mt-2">
            <div className="float-right">
              <Button
                text={"Search"}
                className="py-1.5 px-3 align-middle mr-2 bg-primary-100 text-white"
                onClick={getReport}
              />
              <Button
                text="Reset"
                className="py-1.5 px-3 align-middle bg-menu text-white"
                onClick={() => {
                  setfilterData({
                    industry_id: "",
                    job_status_id: "",
                    output_by: "",
                    price_to: "",
                    price_from: "",
                  });
                  dispatch(resetAOOReport());
                }}
              />
            </div>
          </div>
        </div>
        <div className="md:col-span-3 !px-0.5">
          <Picker
            filterData={filterData}
            setFiltersData={setfilterData}
            dropdown_menu={[
              { label: "Closed", value: "closed" },
              { label: "Created", value: "created" },
            ]}
            dropdown_label="Report by"
            valueKey={"report_by"}
            fromKey={"date_from"}
            toKey={"date_to"}
          />
        </div>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          exportButton={exportToExcel}
          columnDefs={columnDefs}
          items={summaryArrayWithSrNo}
          totalItems={aooDataReport?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          paginationMode={"client"}
          pagination="No"
        />
      </div>
    </>
  );
};

export default OOAStatusReport;
