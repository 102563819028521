import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createServiceCost,
  createServiceCostData,
  getServiceCostData,
  updateServiceCost,
  updateServiceCostData,
} from "../services/quotePricingService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  bulk_data: {},
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Service Cost Data
export const get_service_cost_data = createAsyncThunk(
  "get_service_cost_data",
  async (id, thunkAPI) => {
    try {
      return await getServiceCostData(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Service Cost Slot
export const create_service_cost = createAsyncThunk(
  "create_service_cost",
  async (data, thunkAPI) => {
    try {
      return await createServiceCost(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Service Cost Slot
export const update_service_cost = createAsyncThunk(
  "update_service_cost",
  async (data, thunkAPI) => {
    try {
      return await updateServiceCost(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Service Cost Slot
export const create_service_cost_data = createAsyncThunk(
  "create_service_cost_data",
  async (data, thunkAPI) => {
    try {
      return await createServiceCostData(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const update_service_cost_data = createAsyncThunk(
  "update_service_cost_data",
  async (data, thunkAPI) => {
    try {
      return await updateServiceCostData(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const quotePricingSlice = createSlice({
  name: "service_cost",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
    add_bulk_data: (state, action) => {
      state.bulk_data = action?.payload?.data;
    },
    cost_update: (state, action) => {
      const recordService = state.record.findIndex(
        ({ _id }) => _id === action?.payload?.service_cost_id
      );
      const recordData = state.record[recordService]?.data?.findIndex(
        ({ _id }) => _id === action?.payload?.data_id
      );
      const recordLocation = state.record[recordService]?.data[
        recordData
      ]?.location_data?.locations_price?.findIndex(
        ({ _id }) => _id === action?.payload?.location_id
      );

      const bulkData = state.bulk_data.bulk_data.findIndex(
        ({ data_id }) => data_id === action?.payload?.data_id
      );
      const bulkLocation = state.bulk_data.bulk_data[
        bulkData
      ].location_data?.locations_price.findIndex(
        ({ _id }) => _id === action?.payload?.location_id
      );

      state.record[recordService].data[
        recordData
      ].location_data.locations_price[recordLocation].cost =
        +action.payload.cost;

      state.bulk_data.bulk_data[bulkData].location_data.locations_price[
        bulkLocation
      ].cost = +action.payload.cost;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(create_service_cost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_service_cost.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_service_cost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(create_service_cost_data.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_service_cost_data.fulfilled, (state, action) => {
        state.isLoading = false;

        const result = state.record.findIndex(
          ({ _id }) => _id === action?.payload?.data?.service_cost_id
        );
        if (state?.record[result]?.data) {
          state.record[result].data = [
            ...state?.record[result]?.data,
            action.payload.data,
          ];
        }
      })
      .addCase(create_service_cost_data.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_service_cost_data.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_service_cost_data.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload.data;
      })
      .addCase(get_service_cost_data.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
      })
      .addCase(update_service_cost_data.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_service_cost_data.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_service_cost_data.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.bulk_data = {};
      })
      .addCase(update_service_cost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_service_cost.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_service_cost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.record = [];
      });
  },
});

export const { reset, add_bulk_data, cost_update } = quotePricingSlice.actions;
export default quotePricingSlice.reducer;
