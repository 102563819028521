import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DotsLoader } from "../../components";
import PageHeader from "../../components/molecules/PageHeader";
import { get_db_tables } from "../../features/databaseSlice";

function DatabaseTables() {
  const { isLoading, dbTables } = useSelector((state) => state.database);
  const [searchInput, setSearchInput] = useState("");
  const data = dbTables?.data?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  const params = useParams();
  const { dbName } = params;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (dbName) {
      dispatch(get_db_tables(dbName));
    }
    // eslint-disable-next-line
  }, [dbName]);

  return (
    <>
      {/* <Breadcrumbs /> */}
      <PageHeader heading={dbName?.replaceAll("_", " ")} />
      {isLoading && <DotsLoader text="Please Wait" />}
      <div className="bg-white my-3 border rounded p-2">
        <div className="relative w-full col-span-6 md:col-span-6 !w-1/2">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <input
            type="text"
            className="h-[31px] bg-white border border-gray-300 text-gray-900 text-sm rounded-[0.2rem] w-full pl-12 p-2.5 "
            placeholder="Search"
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
          />
        </div>
        <div className="bg-white my-3 borde rounded overflow-y-auto flex flex-wrap flex-row w-full border-[#ccc]">
          <div className="table-responsive">
            <table className="table table-striped table-bordered ">
              <thead
                className=""
                style={{
                  position: "sticky",
                  top: 0,
                  left: 230,
                  right: 0,
                }}
              >
                <tr>
                  <th className="sticky">#</th>
                  <th className="sticky">Table Name</th>
                  <th className="sticky">Row Count</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((table, index) => {
                  let counter = index + 1;
                  let { tableName, rowCount } = table;
                  return (
                    <tr
                      className="relative cursor-pointer text-black"
                      key={index}
                      onClick={() =>
                        navigate(`/industry_database/${dbName}/${table.tableName}`)
                      }
                    >
                      <td className="text-black">{counter}</td>
                      <td className="text-black">{tableName}</td>
                      <td className="text-black">{rowCount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default DatabaseTables;
