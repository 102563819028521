import React, { useEffect, useState } from "react";
import { Button, DotsLoader, Modal } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import { get_geo_industry } from "../../features/GeoIndustrySlice";
import { useNavigate } from "react-router-dom";
import {
  clearScrubDomains,
  get_domain_data_report,
  get_vendors_by_industry,
} from "../../features/geoLocSlice";
import Select from "react-select";
import { get_geo_states } from "../../features/GeoVendorsSlice";
import { toast } from "react-toastify";
import { MultiSelect } from "primereact/multiselect";
import { utils, writeFile } from "xlsx";
const DomainDataReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, vendorsByIdustry,domainReport } = useSelector(
    (state) => state.geoLoc
  );
  const industry = useSelector((state) => state.geoIndustry);
  const vendors = useSelector((state) => state.geoVendors);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [filterData, setfilterData] = useState({
    industry_id: "",
    vendor_ids: [],
  });
  useEffect(() => {
    dispatch(clearScrubDomains());
    dispatch(get_geo_states());
    // dispatch(get_vendors_by_industry(filterData.industry_id));
    dispatch(get_geo_industry());
    // eslint-disable-next-line
  }, []);
  const exportToExcel = () => {
    const workbook = utils.book_new();
    const sheetName = "Domains Data Report";
    const inAreaDomains = domainReport?.in_area_domains || [];
    const notFoundForLocations = domainReport?.not_found_for_locations || [];
    const outAreaDomains = domainReport?.out_area_domains || [];
    const purchasedDomains = domainReport?.purchased_domains || [];

    const maxLength = Math.max(
      inAreaDomains?.length,
      notFoundForLocations?.length,
      outAreaDomains?.length,
      purchasedDomains?.length
    );

    const transposedData = [
      [
        `Purchased Domains (${purchasedDomains?.length})`,
        `In Area Domains (${inAreaDomains?.length})`,
        `Out Area Domains (${outAreaDomains?.length})`,
        `Available To Purchase (${notFoundForLocations?.length})`,
      ],
      ...Array.from({ length: maxLength }, (_, index) => [
        purchasedDomains[index] || "",
        inAreaDomains[index]?.domain || "",
        outAreaDomains[index]?.domain || "",
        notFoundForLocations[index] || "",
      ]),
    ];

    const worksheet = utils.aoa_to_sheet(transposedData);
    utils.book_append_sheet(workbook, worksheet, sheetName);

    writeFile(
      workbook,
      `${
        industry?.record?.find(({ id }) => id === filterData?.industry_id)?.name
      }Domain Data Report.xlsx`
    );
  };

  return (
    <>
      <PageHeader
        route="Setting > Vendors"
        heading="Domain Data Report"
        onClick={() => navigate("/geolocation/locations")}
        // isAllowed={userPermission("Geo Location")}
      />
      <>
        <div className="items-center !m-2 bg-slate-50 !my-2 border rounded p-3">
          {isLoading && <DotsLoader />}

          <div className="flex flex-row items-center">
            <div className="flex flex-col w-full ml-2">
              <label className="inputLabel" htmlFor="industries">
                {"Industries"}
              </label>
              <Select
                name="industries"
                placeholder="Select Industries"
                onChange={(e) => {
                  setfilterData({
                    ...filterData,
                    industry_id: e.id,
                  });
                  dispatch(get_vendors_by_industry(e.id));
                }}
                options={industry?.record}
                valueKey="id"
                labelKey="name"
                className="w-full"
                menuPosition="fixed"
                getOptionLabel={(option) =>
                  `${option.name} ${
                    option?.totalPurchased ? `(${option?.totalPurchased})` : ""
                  }`
                }
                getOptionValue={(option) => option.id}
                value={
                  filterData?.industry_id
                    ? {
                        id: filterData.industry_id,
                        name: `${
                          industry?.record?.find(
                            ({ id }) => id === filterData?.industry_id
                          )?.name
                        } (${
                          industry?.record?.find(
                            ({ id }) => id === filterData?.industry_id
                          )?.totalPurchased
                        })`,
                      }
                    : null
                }
              />
            </div>
            <div className="w-full ml-2 flex flex-col ">
              <label className="inputLabel" htmlFor="logs">
                {"Vendors"}
              </label>
              <MultiSelect
                placeholder="Select Vendors"
                options={vendorsByIdustry}
                optionLabel={(option) =>
                  `${option?.company_name && option?.company_name!=='null'?option?.company_name + ' | ':''}${option?.first_name} ${option?.last_name?option?.last_name:""}`
                }
                optionValue="id"
                filter
                maxSelectedLabels={2}
                value={filterData?.vendor_ids}
                onChange={(e) => {
                  setfilterData({ ...filterData, vendor_ids: e.value });
                }}
                disabled={!filterData?.industry_id ? true : false}
                className="h-[38px] max-w-[600px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              />
            </div>
            <Button
              text={"Search"}
              className="py-1.5 px-3 align-middle mx-2 bg-primary-100 text-white !mt-6"
              onClick={() => {
                if (
                  !filterData?.industry_id ||
                  filterData?.search_log_ids?.length === 0
                ) {
                  toast.error("Please Select Industry or Search Log");
                } else {
                  dispatch(get_domain_data_report(filterData));
                }
              }}
            />
            <Button
              text="Reset"
              className="py-1.5 px-3 align-middle bg-menu text-white !mt-6"
              onClick={() => {
                setfilterData({ industry_id: "", vendor_id: "" });
                dispatch(clearScrubDomains());
              }}
            />
          </div>
        </div>
        {
          domainReport?.in_area_domains?.length > 0 ||
          domainReport?.out_area_domains?.length > 0 ||
          domainReport?.not_found_for_locations?.length > 0 ? (
            <>
              <div className="flex justify-end !mb-1">
                <button
                  className={`ml-2 btn btn-outline-secondary h-[40.39px]`}
                  type="button"
                  data-testid="button-clearAll"
                  onClick={exportToExcel}
                >
                  Export Result
                </button>
              </div>
              <div className="flex flex-row">
                <div className="border w-1/4 !mr-1">
                  <details open>
                    <summary className="flex !flex-row w-full items-center text-normal px-2 font-semibold font-pop justify-between border-b hover:cursor-pointer bg-gray-100 hover:bg-gray-300 py-2 px-1">
                      <span>{`Total Purchased Domain (${domainReport?.purchased_domains?.length})`}</span>
                    </summary>
                    <div className="flex flex-col max-h-[58vh] overflow-y-auto ">
                      <div className="overflow-x-auto">
                        <div className="py-2 inline-block min-w-full">
                          <div className="overflow-hidden">
                            {domainReport?.purchased_domains?.map(
                              (project, index) => (
                                <li className="pl-2 list-none bg-white border-b text-xs font-bold text-gray-900 py-2">
                                  {index + 1}.
                                  <a
                                    className="text-black no-underline ml-2"
                                    key={index}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://${project}`}
                                  >
                                    {project}
                                  </a>
                                </li>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </details>
                  <hr className="border-violet-100 my-2" />
                </div>
                <div className="border w-1/4 !ml-1">
                  <details open>
                    <summary className="flex !flex-row w-full items-center text-normal font-semibold font-pop justify-between border-b hover:cursor-pointer bg-gray-100 hover:bg-gray-300 py-2 px-1">
                      <span>{`Purchased In Area (${domainReport?.in_area_domains?.length})`}</span>
                      <span>{`${
                        domainReport?.in_area_domains?.length > 0
                          ? (
                              (domainReport?.in_area_domains?.length /
                              domainReport?.purchased_domains?.length) *
                              100
                            )?.toFixed(2)
                          : "0"
                      }%`}</span>
                    </summary>
                    <div className="flex flex-col max-h-[58vh] overflow-y-auto ">
                      <div className="overflow-x-auto">
                        <div className="py-2 inline-block min-w-full">
                          <div className="overflow-hidden">
                            {domainReport?.in_area_domains?.map(
                              (project, index) => (
                                <li className="pl-2 list-none bg-white border-b text-xs font-bold text-gray-900 py-2 flex jutify-between w-full">
                                  {index + 1}.
                                  <div className="flex flex-col">
                                    <a
                                      className="text-black no-underline ml-2"
                                      key={index}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={`https://${project?.domain}`}
                                    >
                                      {project?.domain}{" "}
                                      <small className="ml-2 font_pop font-bold">{` (Generated By: ${project?.generate_domain_by})`}</small>
                                    </a>
                                    <small className="ml-2 font_pop font-semibold">
                                      {`${project?.location}`}
                                    </small>
                                  </div>
                                </li>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </details>
                  <hr className="border-violet-100 my-2" />
                </div>
                <div className="border w-1/4 !ml-1">
                  <details open>
                    <summary className="flex !flex-row w-full items-center text-normal font-semibold font-pop justify-between border-b hover:cursor-pointer bg-gray-100 hover:bg-gray-300 py-2 px-1">
                      <span>{`Purchased Out of Area (${domainReport?.out_area_domains?.length})`}</span>
                      <span>{`${
                        domainReport?.out_area_domains?.length > 0
                          ? (
                              (domainReport?.out_area_domains?.length /
                              domainReport?.purchased_domains?.length) *
                              100
                            )?.toFixed(2)
                          : "0"
                      }%`}</span>
                    </summary>
                    <div className="flex flex-col max-h-[58vh] overflow-y-auto ">
                      <div className="overflow-x-auto">
                        <div className="py-2 inline-block min-w-full">
                          <div className="overflow-hidden">
                            {domainReport?.out_area_domains?.map(
                              (project, index) => (
                                <li className="pl-2 list-none bg-white border-b text-xs font-bold text-gray-900 py-2 flex jutify-between w-full">
                                  {index + 1}.
                                  <div className="flex flex-col">
                                    <a
                                      className="text-black no-underline ml-2"
                                      key={index}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={`https://${project?.domain}`}
                                    >
                                      {project?.domain}{" "}
                                      <small className="ml-2 font_pop font-bold">{` (Generated By: ${project?.generate_domain_by})`}</small>
                                    </a>
                                    <small className="ml-2 font_pop font-semibold">
                                      {`${project?.location}`}
                                    </small>
                                  </div>
                                </li>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </details>
                  <hr className="border-violet-100 my-2" />
                </div>
                <div className="border w-1/4 !ml-1">
                  <details open>
                    <summary className="flex !flex-row w-full items-center text-normal font-semibold font-pop justify-between border-b hover:cursor-pointer bg-gray-100 hover:bg-gray-300 py-2 px-1">
                      <span>{`Available City to Purchase (${domainReport?.not_found_for_locations?.length})`}</span>
                    </summary>
                    <div className="flex flex-col max-h-[58vh] overflow-y-auto ">
                      <div className="overflow-x-auto">
                        <div className="py-2 inline-block min-w-full">
                          <div className="overflow-hidden">
                            {domainReport?.not_found_for_locations?.map(
                              (project, index) => (
                                <li
                                  className="pl-2 list-none bg-white border-b text-xs font-bold text-gray-900 py-2"
                                  key={index}
                                >
                                  {index + 1}.
                                  <span className="text-black no-underline ml-2">
                                    {project}
                                  </span>
                                </li>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </details>
                  <hr className="border-violet-100 my-2" />
                </div>
              </div>
              <div></div>

              {selectedIndex !== null && (
                <Modal
                  title={"Search Logs"}
                  onCancelModal={() => setSelectedIndex(null)}
                  onClick={() => setSelectedIndex(null)}
                  CloseOnly={true}
                  hideButtons={true}
                >
                  <div className="border ">
                    {domainReport?.in_area_domains[
                      selectedIndex
                    ]?.search_logs?.map((logs, index) => {
                      return (
                        <details open>
                          <summary className="flex !flex-row w-full items-center text-normal px-2 font-semibold font-pop justify-between border-b hover:cursor-pointer bg-gray-100 hover:bg-gray-300 py-2 px-1">
                            <div className="text-sm text-gray-900">
                              <span className="ml-3">{+index + 1}</span>
                              <span className="ml-4">{logs?.description}</span>
                            </div>
                            <span>
                              Total Payloads: {logs?.payloads?.length}
                            </span>
                          </summary>
                          <div className="flex flex-col max-h-[40vh] overflow-y-auto">
                            <div className="overflow-x-auto">
                              <div className="py-2 inline-block min-w-full">
                                <div className="overflow-hidden">
                                  <table className="min-w-full">
                                    <thead>
                                      <th
                                        scope="col"
                                        className="text-xs font-bold text-gray-900 p-2"
                                      >
                                        Type
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-xs font-bold text-gray-900 p-2"
                                      >
                                        State
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-xs font-bold text-gray-900 p-2"
                                      >
                                        Radius
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-xs font-bold text-gray-900 p-2"
                                      >
                                        Location
                                      </th>
                                    </thead>
                                    <tbody>
                                      {logs?.payloads?.map((record, index) => {
                                        let data = JSON.parse(record);
                                        return (
                                          <tr
                                            className="bg-white border-b"
                                            key={index}
                                          >
                                            <td className="text-sm text-gray-900 font-light text-xs py-2 whitespace-nowrap">
                                              {data?.type}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light text-xs py-2 whitespace-nowrap">
                                              {
                                                vendors?.states?.find(
                                                  ({ id }) =>
                                                    id === data.state_id
                                                )?.name
                                              }
                                            </td>
                                            <td className="text-sm text-gray-900 font-light text-xs py-2 whitespace-nowrap">
                                              {data.radiusInMeters}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light text-xs py-2 whitespace-nowrap">
                                              {data.locations}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>
                      );
                    })}
                  </div>
                </Modal>
              )}
            </>
          ) : (
            domainReport?.in_area_domains?.length === 0 &&
            domainReport?.not_found_for_locations?.length === 0 &&
            toast.error("No record found")
          )
          // }
        }
      </>
    </>
  );
};

export default DomainDataReport;
