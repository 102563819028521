import React, { Suspense, lazy, memo } from "react";
import TowingServiceSection from "./TowingServiceSection";
import NonVehicleTow from "./NonVehicleTow";
import TireChangeSection from "./TireChangeSection";
import BatteryReplacement from "./BatteryReplacement";
import AutoTransportSection from "./AutoTransportSection";
import TireReplacementSection from "./TireReplacementSection";
import TwoWayTowSection from "./TwoWayTowSection";
import JumpStartSection from "./JumpStartSection";
import TireInflationSection from "./TireInflationSection";
import LockOutService from "./LockOutService";
import StorageService from "./StorageService";
import LoadUnloadSection from "./LoadUnloadSection";
import WinchOutSection from "./WinchOutSection";
import LocksmithServiceSection from "./LocksmithServiceSection";
import SpecialRequest from "./SpecialRequest";
import GasDelivery from "./GasDelivery";
import MobileMechanic from "./MobileMechanic";

const DefaultComponent = () => <div></div>;
const componentMap = {
  "Tow Service": TowingServiceSection,
  "Non-Vehicle Tow": NonVehicleTow,
  "Tire Change": TireChangeSection,
  "Battery Replacement": BatteryReplacement,
  "Auto-Transport": AutoTransportSection,
  "Tire Replacement": TireReplacementSection,
  "Two-way Tow": TwoWayTowSection,
  "Jump Start": JumpStartSection,
  "Tire Inflation": TireInflationSection,
  "Car Lockout": LockOutService,
  "Lockout": LockOutService,
  Storage: StorageService,
  "Load/Unload": LoadUnloadSection,
  Winchout: WinchOutSection,
  "Stuck in Ditch": WinchOutSection,
  Locksmith: LocksmithServiceSection,
  "Special Request": SpecialRequest,
  "Gas Delivery": GasDelivery,
  "Mobile Mechanic": MobileMechanic,
};
const DynamicComponent = memo(
  function DynamicComponent({ serviceName, formik }) {
    const SelectedComponent = componentMap[serviceName] || DefaultComponent;
    return (
      // <Suspense fallback={<div>Loading...</div>}>
      <SelectedComponent formik={formik} serviceName={serviceName} />
      // </Suspense>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.serviceName === nextProps.serviceName &&
      JSON.stringify(prevProps.formik.values) ===
        JSON.stringify(nextProps.formik.values)
    );
  }
);

export default DynamicComponent;
