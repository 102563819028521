import React from "react";
import { FaChevronDown } from "react-icons/fa";
import { Button } from "../../../components";
import { toast } from "react-toastify";
import { update_general_setting } from "../../../features/generalSettingSlice";
import { useDispatch } from "react-redux";
import SelectForm from "./SelectForm";
import InputForm from "./InputForms";
import errorMessage from "../../../util/errorMessage";
function EmailConfiguration({ settings }) {
  let initialValues = {
    setting_type: "emailConfiguration",
    type: "",
    username: "",
    password: "",
    send_grid_api_key: "",
    host: "",
    security_layer: "",
  };

  React.useEffect(() => {
    setEmailConfiguration(settings[0]?.emailConfiguration);
  }, [settings]);

  const dispatch = useDispatch();

  const [emailConfiguration, setEmailConfiguration] =
    React.useState(initialValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setEmailConfiguration({
      ...emailConfiguration,
      [name]: value,
    });
  };
  const handleSubmit = async () => {
    try {
      const res = await dispatch(
        update_general_setting(
          emailConfiguration?.setting_type === "emailConfiguration"
            ? emailConfiguration
            : { ...emailConfiguration, setting_type: "emailConfiguration" }
        )
      );
      if (res?.payload?.status === 200) {
        toast.success("Additional Info successfully updated");
      } else {
        errorMessage({
          payload: res.payload,
          action: "Email Configuration",
          msg: "updated",
        });
      }
    } catch (error) {
      console.error(
        "Error ~ file: AddUpdateForm.jsx ~ line 59 ~ onSubmit: ~ error",
        error
      );
      errorMessage({
        payload: error,
        action: "Email Configuration",
        msg: "updated",
      });
    }
  };

  return (
    <details className="border mt-3 mb-3 ">
      <summary className="flex flex-row items-center text-normal px-2 font-semibold font-pop justify-between border-b hover:cursor-pointer  bg-gray-100 hover:bg-gray-300 py-2 px-1">
        <span>Email Configuration</span>
        <FaChevronDown iconClass="fas fa-times" />
      </summary>
      <div className="flex flex-col p-2">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <div className="flex justify-center mb-3 flex flex-row gap-x-4 flex-wrap">
                <div
                  className="hover:cursor-pointer flex flex-row item-center"
                  onClick={() =>
                    setEmailConfiguration({
                      ...emailConfiguration,
                      type: "SendGrid",
                    })
                  }
                >
                  <input
                    type="radio"
                    className="cursor-pointer"
                    name="type"
                    checked={
                      emailConfiguration?.type === "SendGrid" ? true : false
                    }
                  />
                  <label className="cursor-pointer ml-1">Send Grid</label>
                </div>
                <div
                  className="cursor-pointer flex flex-row items-center"
                  onClick={() =>
                    setEmailConfiguration({
                      ...emailConfiguration,
                      type: "smtp",
                    })
                  }
                >
                  <input
                    type="radio"
                    className="cursor-pointer"
                    checked={emailConfiguration?.type === "smtp" ? true : false}
                  />
                  <label className="cursor-pointer ml-1">SMTP</label>
                </div>
              </div>
              <div className="md:grid grid-cols-12 gap-x-2">
                <div className="col-span-6  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="username"
                      placeholder="User Name"
                      onChange={(e) => handleInputChange(e)}
                      value={emailConfiguration?.username}
                      type="text"
                    />
                  </div>
                </div>

                <div className="col-span-6  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="password"
                      placeholder="Password"
                      onChange={(e) => handleInputChange(e)}
                      value={emailConfiguration?.password}
                      type="text"
                    />
                  </div>
                </div>

                {emailConfiguration?.type === "SendGrid" && (
                  <>
                    <div className="col-span-6  md:mt-md-2">
                      <div className="mb-3">
                        <InputForm
                          name="send_grid_api_key"
                          placeholder="Send Grid API Key"
                          onChange={(e) => handleInputChange(e)}
                          value={emailConfiguration?.send_grid_api_key}
                          type="text"
                        />
                      </div>
                    </div>
                  </>
                )}
                {emailConfiguration?.type === "smtp" && (
                  <>
                    <div className="col-span-6  md:mt-md-2">
                      <div className="mb-3">
                        <InputForm
                          name="host"
                          placeholder="Host"
                          onChange={(e) => handleInputChange(e)}
                          value={emailConfiguration?.host}
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="col-span-6  md:mt-md-2">
                      <div className="mb-1">
                        <SelectForm
                          name="security_layer"
                          options={[
                            { label: "TLS", value: "tls" },
                            { label: "SSL", value: "ssl" },
                          ]}
                          label="Security Layer "
                          labelProp="label"
                          valueProp="value"
                          value={emailConfiguration?.security_layer}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="col-span-6">
                  <Button
                    text="Validate & Save Credentials"
                    variant="btn_submit"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </details>
  );
}

export default EmailConfiguration;
