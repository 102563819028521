import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import PageHeader from "../../components/molecules/PageHeader";
import api from "../../services/api";
import userPermission from "../../util/userPermission";

function IndustrySummary() {
  const params = useParams();
  const { id } = params;
  const [summaryReport, setSummaryReport] = useState({});
  const [searchText, setSearchText] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("domain_count");
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const getSummaryReport = async () => {
    try {
      setIsLoading(true);
      const res = await api.post(
        `/api/industries/${id}/industry_domains_report`
      );
      if (res.status === 200) {
        setSummaryReport(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(
        "🚀 ~ file: IndustrySummary.jsx:9 ~ getSummaryReport ~ err:",
        err
      );
    }
  };
  React.useEffect(() => {
    getSummaryReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isSelected = (id) => {
    if (selectedData?.length > 0) {
      if (selectedData?.filter((domain) => domain === id).length > 0) {
        return true;
      }
    }
    return false;
  };
  const onSingleSelect = ({ checked, data }) => {
    if (checked) {
      setSelectedData([...selectedData, data]);
    } else {
      setSelectedData(selectedData?.filter((domain) => domain !== data));
    }
  };
  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = filteredData?.map((item) => item?.domain);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
          checked={selectedData?.length === filteredData?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={isSelected(params.row.domain)}
          onChange={(e) =>
            onSingleSelect({
              checked: e.target.checked,
              data: params.row.domain,
            })
          }
          className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
      disableColumnMenu: true,
    },
    { headerName: "#", field: "counter" },
    { headerName: "Domain", field: "domain", flex: 1, minWidth: 200 },
    { headerName: "Phone", field: "phone", flex: 1, minWidth: 200 },
    {
      headerName: "Call Center Phone",
      field: "callCenterPhone",
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Call Center Merchant",
      field: "callCenterMerchant",
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Call Center Ring Group",
      field: "callCenterRingGroup",
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Matched",
      field: "matched",
      flex: 1,
      minWidth: 200,
    },
  ];
  React.useEffect(() => {
    if (summaryReport?.records?.length) {
      setFilteredData(summaryReport?.records);
      setSelectedFilter("domain_count");
    }
  }, [summaryReport?.records]);

  const handleFilter = (filter_type) => {
    setSelectedFilter(filter_type);
    if (selectedData?.length > 0) {
      setSelectedData([]);
    }
    if (filter_type === "domain_count") {
      setFilteredData(summaryReport?.records);
    } else if (filter_type === "matched_phone_numbers") {
      const data = summaryReport?.records?.filter(
        ({ isCallCenterPhoneMatched, callCenterPhone, phone }) =>
          callCenterPhone === phone && isCallCenterPhoneMatched
      );
      setFilteredData(data);
    } else if (filter_type === "unmatched_phone_numbers") {
      const data = summaryReport?.records?.filter(
        ({ callCenterPhone, phone }) =>
          callCenterPhone && phone !== callCenterPhone
      );
      setFilteredData(data);
    } else if (filter_type === "call_center_phone_not_found") {
      const data = summaryReport?.records?.filter(
        ({ callCenterPhone }) => !callCenterPhone
      );
      setFilteredData(data);
    } else if (filter_type === "ring_group_phone_numbers") {
      const data = summaryReport?.records?.filter(
        ({ callCenterRingGroup, callCenterMerchant }) =>
          callCenterRingGroup === summaryReport?.summary?.ring_group &&
          summaryReport?.summary?.merchant === callCenterMerchant
      );
      setFilteredData(data);
    } else if (filter_type === "unmatched_ring_group") {
      const data = summaryReport?.records?.filter(
        ({ callCenterRingGroup, callCenterPhone }) =>
          callCenterPhone &&
          callCenterRingGroup !== summaryReport?.summary?.ring_group
      );
      setFilteredData(data);
    } else if (filter_type === "unmatched_merchant") {
      const data = summaryReport?.records?.filter(
        ({ callCenterMerchant, callCenterPhone }) =>
          callCenterPhone &&
          callCenterMerchant !== summaryReport?.summary?.merchant
      );
      setFilteredData(data);
    } else if (filter_type === "total_unmatched") {
      const data = summaryReport?.records?.filter(
        ({
          callCenterPhone,
          phone,
          callCenterMerchant,
          callCenterRingGroup,
        }) =>
          !callCenterPhone ||
          callCenterPhone !== phone ||
          callCenterMerchant !== summaryReport?.summary?.merchant ||
          callCenterRingGroup !== summaryReport?.summary?.ring_group
      );
      setFilteredData(data);
    }
  };
  const updatePhone = async () => {
    if (!selectedData?.length) {
      return toast.error("Please select the record");
    }
    setIsLoading(true);
    try {
      const res = await api.post(
        `/api/industries/${id}/update_call_center_phone_number`,
        selectedData
      );
      if (res.status === 200) {
        toast.success("Call center phone numbers updated");
      } else {
        toast.error(
          res?.response?.data?.message
            ? res?.response?.data?.message
            : "Call center phone numbers couldn't be updated"
        );
      }
      setIsLoading(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Call center phone numbers couldn't be updated"
      );
      setIsLoading(false);
      console.log(
        "🚀 ~ file: IndustrySummary.jsx:180 ~ updatePhone ~ err:",
        err
      );
    }
  };
  const formatNumber = (number) => {
    const formattedNumber =(number && parseInt(number?.replace(",", ""), 10)) || 0;
    return formattedNumber;
  };
  const getRowClass = (params) => {
    if (params.row.records.isCallCenterPhoneMatched){
      return '!bg-[#6c6] text-white'
    } else if (params.row.records.callCenterPhone && !params.row.records.isCallCenterPhoneMatched){
      return '!bg-[#ce2424] text-white'
    } else if (!params.row.records.callCenterPhone){
      return '!bg-[#f76e00] text-white'
    }
  };
  return (
    <>
      <PageHeader
        route="Setting > Tags"
        heading="Industry Domains Phone Number Report"
        breadcrumb={`/settings/industries/industry_domains_report/${
          summaryReport?.summary?.industry_name || ""
        }`}
        extraButton={true}
        extraButtonClick={updatePhone}
        additionalBtnTitle={"Update Phone"}
        additionalBtnAllow={userPermission("Add Industry")}
      />
      <div className="flex-col md:fle flex-wra justify-between mx-0.5 grid grid-cols-6 gap-x-2">
        <div className="flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-full hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Merchant</span>
          <span className="text-base font-medium font-pop">
            {summaryReport?.summary?.merchant
              ? summaryReport?.summary?.merchant
              : 0}
          </span>
        </div>
        <div className="flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-full hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Ring Group</span>
          <span className="text-base font-medium font-pop">
            {summaryReport?.summary?.ring_group
              ? summaryReport?.summary?.ring_group
              : 0}
          </span>
        </div>

        <button
          onClick={() => handleFilter("ring_group_phone_numbers")}
          className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-full hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
          ${
            selectedFilter === "ring_group_phone_numbers"
              ? "border-l-2 border-primary-100"
              : ""
          }
          `}
        >
          <span className="text-sm font-pop">Ring Group Phone Numbers</span>
          <span className="text-base font-medium font-pop">
            {summaryReport?.summary?.ring_group_phone_numbers
              ? summaryReport?.summary?.ring_group_phone_numbers
              : 0}
          </span>
        </button>

        <button
          onClick={() => handleFilter("domain_count")}
          className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-full hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
          ${
            selectedFilter === "domain_count"
              ? "border-l-2 border-primary-100"
              : ""
          }
          `}
        >
          <span className="text-sm font-pop">Domain Count</span>
          <span className="text-base font-medium font-pop">
            {summaryReport?.summary?.domain_count
              ? summaryReport?.summary?.domain_count
              : 0}
          </span>
        </button>

        <button
          onClick={() => handleFilter("matched_phone_numbers")}
          className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-full hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
          ${
            selectedFilter === "matched_phone_numbers"
              ? "border-l-2 border-primary-100"
              : ""
          }
          `}
        >
          <span className="text-sm font-pop">Matched Phone No.</span>
          <span className="text-base font-medium font-pop">
            {summaryReport?.summary?.matched_phone_numbers
              ? summaryReport?.summary?.matched_phone_numbers
              : 0}
          </span>
        </button>

        <button
          onClick={() => handleFilter("total_unmatched")}
          className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-full hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
          ${
            selectedFilter === "total_unmatched"
              ? "border-l-2 border-primary-100"
              : ""
          }
          `}
        >
          <span className="text-sm font-pop">Total Unmatched</span>
          <span className="text-base font-medium font-pop">
            {formatNumber(summaryReport?.summary?.unmatched_phone_numbers) +
              formatNumber(summaryReport?.summary?.unmatched_merchants) +
              formatNumber(summaryReport?.summary?.unmatched_ring_groups) +
              formatNumber(summaryReport?.summary?.call_center_phone_not_found)}
          </span>
        </button>

        <button
          onClick={() => handleFilter("unmatched_phone_numbers")}
          className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-full hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
          ${
            selectedFilter === "unmatched_phone_numbers"
              ? "border-l-2 border-primary-100"
              : ""
          }
          `}
        >
          <span className="text-sm font-pop">Unmatched Phone</span>
          <span className="text-base font-medium font-pop">
            {summaryReport?.summary?.unmatched_phone_numbers
              ? summaryReport?.summary?.unmatched_phone_numbers
              : 0}
          </span>
        </button>

        <button
          onClick={() => handleFilter("unmatched_merchant")}
          className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-full hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
          ${
            selectedFilter === "unmatched_merchant"
              ? "border-l-2 border-primary-100"
              : ""
          }
          `}
        >
          <span className="text-sm font-pop">Unmatched Merchant</span>
          <span className="text-base font-medium font-pop">
            {summaryReport?.summary?.unmatched_merchants
              ? summaryReport?.summary?.unmatched_merchants
              : 0}
          </span>
        </button>

        <button
          onClick={() => handleFilter("unmatched_ring_group")}
          className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-full hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
          ${
            selectedFilter === "unmatched_ring_group"
              ? "border-l-2 border-primary-100"
              : ""
          }
          `}
        >
          <span className="text-sm font-pop">Unmatched Ring Group</span>
          <span className="text-base font-medium font-pop">
            {summaryReport?.summary?.unmatched_ring_groups
              ? summaryReport?.summary?.unmatched_ring_groups
              : 0}
          </span>
        </button>

        <button
          onClick={() => handleFilter("call_center_phone_not_found")}
          className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-full hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
          ${
            selectedFilter === "call_center_phone_not_found"
              ? "border-l-2 border-primary-100"
              : ""
          }
          `}
        >
          <span className="text-sm font-pop">Call Center Phone Not Found</span>
          <span className="text-base font-medium font-pop">
            {summaryReport?.summary?.call_center_phone_not_found
              ? summaryReport?.summary?.call_center_phone_not_found
              : 0}
          </span>
        </button>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={filteredData?.map((record, index) => {
            let counter = index + 1;
            const {
              domain,
              phone,
              callCenterPhone,
              callCenterMerchant,
              callCenterRingGroup,
              isCallCenterPhoneMatched,
            } = record;
            const matched = isCallCenterPhoneMatched ? "Yes" : "No";
            return {
              counter,
              domain,
              phone,
              callCenterPhone,
              matched,
              callCenterRingGroup,
              callCenterMerchant,
              records: { ...record, _id: index + 1 },
            };
          })}
          pagination="No"
          totalItems={summaryReport?.records?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          getRowClass={getRowClass}
        />
      </div>
    </>
  );
}

export default IndustrySummary;
