import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  agentCreateJob,
  agentlessCreateJob,
  agentlessGetCategory,
  agentlessGetPhone,
} from "../services/agentlessFormService";
import { createLead } from "../services/leads";

const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
  record: [],
  message: "",
  phone: "",
  quote: null,
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
export const agentless_get_phone = createAsyncThunk(
  "agentless_get_phone",
  async (token, thunkAPI) => {
    try {
      return await agentlessGetPhone(token);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const agentless_get_category = createAsyncThunk(
  "agentless_get_category",
  async (token, thunkAPI) => {
    try {
      return await agentlessGetCategory(token);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const agentless_create_job = createAsyncThunk(
  "agentless_create_job",
  async (data, thunkAPI) => {
    try {
      return await agentlessCreateJob(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const agent_create_job = createAsyncThunk(
  "agent_create_job",
  async (data, thunkAPI) => {
    try {
      return await agentCreateJob(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create Lead
export const get_quotes = createAsyncThunk(
  "get_quotes",
  async (data, thunkAPI) => {
    try {
      return await createLead(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const agentlessSlice = createSlice({
  name: "agentlessForm",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(agentless_get_phone.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(agentless_get_phone.fulfilled, (state, action) => {
        state.isLoading = false;
        state.phone = action.payload.data.phone;
      })
      .addCase(agentless_get_phone.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(agentless_get_category.pending, (state) => {
        state.isLoading = true;
        state.record = [];
      })
      .addCase(agentless_get_category.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(agentless_get_category.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(agentless_create_job.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(agentless_create_job.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(agentless_create_job.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_quotes.pending, (state) => {
        state.isLoading = true;
        state.quote = null;
      })
      .addCase(get_quotes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.quote = action.payload.data;
      })
      .addCase(get_quotes.rejected, (state,action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(agent_create_job.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(agent_create_job.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(agent_create_job.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
export const { reset } = agentlessSlice.actions;
export default agentlessSlice.reducer;
