import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createSystemJobStatus,
  getSystemJobStatus,
  updateSystemJobStatus,
} from "../services/systemJobStatusService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  jobStatuses: [],
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Job Statuss
export const get_system_job_status = createAsyncThunk(
  "get_system_job_status",
  async (data, thunkAPI) => {
    try {
      return await getSystemJobStatus(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Job Status
export const create_system_job_status = createAsyncThunk(
  "create_system_job_status",
  async (data, thunkAPI) => {
    try {
      return await createSystemJobStatus(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Job Status
export const update_system_job_status = createAsyncThunk(
  "update_system_job_status",
  async (data, thunkAPI) => {
    try {
      return await updateSystemJobStatus(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const systemJobStatusSlice = createSlice({
  name: "system_job_status",
  initialState,
  reducers: {
    jobStatusReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.jobStatuses = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_system_job_status.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_system_job_status.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_system_job_status.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
      })
      .addCase(create_system_job_status.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_system_job_status.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record?.push(action.payload.data);
      })
      .addCase(create_system_job_status.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_system_job_status.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_system_job_status.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record?.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record[result] = {
          ...state.record[result],
          ...action.payload.data,
        };
      })
      .addCase(update_system_job_status.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { jobStatusReset } = systemJobStatusSlice.actions;
export default systemJobStatusSlice.reducer;
