import { MultiSelect } from "primereact/multiselect";
import React, { useState } from "react";
import { MdCancel } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, DotsLoader } from "../../../components";
import TagsInput from "../../../components/atoms/CustomTagsInput";
import { create_batch } from "../../../features/domainCenterSlice";
import { get_tld } from "../../../features/TldSlice";

const BatchSaveModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, record } = useSelector((state) => state.tld);
  const initialFilters = {
    industry_id: props.industry_id,
    search_filter: props.search_filter,
    locations: props.data,
    tlds: [],
    keywords: [],
    note: "",
    description: "",
  };
  const [keywords, setKeywords] = useState([]);
  const [filterData, setfilterData] = useState(initialFilters);
  React.useEffect(() => {
    dispatch(get_tld());
    // eslint-disable-next-line
  }, []);
  const handleSave = async () => {
    if (filterData?.note!==""){
      try {
        const res = await dispatch(
          create_batch({ ...filterData, keywords: keywords })
        );
        if (res?.payload?.status === 200) {
          props.onCancelForm()
          toast.success("Record saved");
          navigate(`/domain_center/batch_report/${res.payload?.data?.id}`)
        }
      } catch (err) {
        console.error("🚀 ~ file: saveBatch.jsx:62 ~ handleSave ~ err:", err);
        toast.error("Coludn't be added");
      }
    }else{toast.error("Note is required")}

    
  };
  return (
    <div className="w-full h-screen z-[100] fixed top-0 left-0 opacity-100 bg-black/[0.75] flex items-center justify-center">
      {isLoading && <DotsLoader />}
      <div className="relative">
      <div className="absolute top-0 right-0 p-2 z-20 -mt-6 -mr-6">
      <button
        onClick={props.onCancelModal}
        className="bg-white rounded-full text-gray-700 hover:text-red-500 p-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
      <div
        className="z-10 w-auto w-full md:max-w-[1024px] md:min-w-[730px] max-h-[calc(100vh-130px)] overflow-y-auto bg-neutral-100 p-3.5 border-[10px] border-white"
        // ref={modalRef}
      >
        <div className="pt-0 max-w-[994px] h-full px-3.5 m-auto">
          {!props.hideHeader && (
            <header className="bg-primary-100 h-[60px] flex items-center justify-between shadow-lg">
              <h1 className=" text-2xl text-white m-4 font-medium">
                {props.isUpdate
                  ? props.title.replaceAll("Add", "Edit")
                  : props.title}
              </h1>
              <h1 className=" text-2xl text-white m-4 font-medium">
                {`Selected Records: ${props?.data?.length}`}
              </h1>
            </header>
          )}
          <div className="md:p-4">
            <div className="grid grid-cols-3 gap-x-2 items-center mb-2">
              <div className="span-col-1">
                <label className="inputLabel" htmlFor="logs">
                  {"TLDs"}
                </label>
                <MultiSelect
                  placeholder="Select TLDs"
                  options={record}
                  optionLabel="name"
                  optionValue="name"
                  filter
                  maxSelectedLabels={2}
                  value={filterData?.tlds}
                  onChange={(e) => {
                    setfilterData({ ...filterData, tlds: e.value });
                  }}
                  // disabled={!filterData?.industry_id ? true : false}
                  className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                />
              </div>
              <div className="span-col-1 ">
                <label className="inputLabel" htmlFor="industries">
                  {"Note"}
                </label>
                <input
                  className="form-control"
                  placeholder="Note"
                  onChange={(e) =>
                    setfilterData({
                      ...filterData,
                      note: e.target.value,
                    })
                  }
                  value={filterData?.note}
                />
              </div>
              <div className="span-col-1">
                <label className="inputLabel" htmlFor="industries">
                  {"Description"}
                </label>
                <textarea
                  className="form-control"
                  placeholder="Description"
                  onChange={(e) =>
                    setfilterData({
                      ...filterData,
                      description: e.target.value,
                    })
                  }
                  value={filterData?.description}
                />
              </div>
            </div>
            <TagsInput
              tags={keywords}
              setTags={setKeywords}
              placeholder="Add keywords"
            />
            <div className="max-h-[260px] overflow-y-auto">
              {props.data?.map((obj, index) => (
                <div key={index}>
                  <li className="pl-2 list-none border-b text-xs font-bold text-gray-900 py-2   flex flex-row items-center justify-between hover:cursor-pointer hover:bg-gray-100 py-2 px-1">
                    <div className="text-sm text-gray-900">
                      <span className="ml-3">{+index + 1}</span>
                      <span className="ml-4">{obj?.state}</span>
                      <span className="ml-4">{obj?.city}</span>
                    </div>
                    <span>
                      <MdCancel
                        size={20}
                        className="ml-1 cursor-pointer"
                        color="#042b42"
                        onClick={() =>
                          props.onSingleselect({
                            state: obj.state,
                            city: obj.city,
                          })
                        }
                      />
                    </span>
                  </li>
                </div>
              ))}
            </div>
          </div>

          {!props.hideButtons && (
            <div className="!p-4 text-right">
              <Button
                text="Cancel"
                className="mx-2"
                onClick={props.onCancelForm}
                variant="btn_cancel"
              />
              <Button
                text={"Submit"}
                className=""
                onClick={handleSave}
                isLoading={props?.isLoading}
                variant="btn_submit"
                disabled={keywords?.length>0?false:true}
              />
            </div>
          )}
          {props.CloseOnly && (
            <div className="!p-4 text-right">
              <Button
                text="Cancel"
                className="mx-2"
                onClick={props.onCancelForm}
                variant="btn_cancel"
              />
            </div>
          )}
        </div>
      </div>
    </div>
    </div>
  );
};
export default BatchSaveModal;
