import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { DotsLoader } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { delete_tld, get_tld } from "../../features/TldSlice";
import dayjs from "dayjs";
const Tlds = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.tld);

  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_tld(record_id));
        if (res?.payload?.status === 200) {
          toast.success("TLD deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "TLD",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "TLD", msg: "deleted" });
      }
    }
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  useEffect(() => {
    dispatch(
      get_tld()
    );
    // eslint-disable-next-line
  }, []);
  const industries = record?.filter((tld) =>
    tld?.name?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Name", field: "name", flex: 1 },
    { headerName: "Created At", field: "created_at", flex: 1 },
    // { headerName: "Active", field: "active", flex: 1 },
    {
      field: "actions",
      renderCell: (params) =>
      // params.row.records.active ? (
          <div>
            {userPermission("Domain Center") && (
              <FaEdit
                onClick={openFormHandler(params.row.records)}
                className="my_navIcon"
                title="Update TLD"
              />
            )}
            {userPermission("Domain Center") && (
              <FaTrashAlt
                onClick={deleteRecordHandler(params.row.records.id)}
                className="my_navIcon"
                title="Delete TLD"
              />
            )}
          </div>
      // ):null
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add TLDs"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Industries"
        heading="TLD Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Domain Center")}
      />
      {isLoading && <DotsLoader />}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={industries?.map((record, index) => {
            let counter = index + 1;
            let name = record.name;
            let created_at = dayjs(record?.createdAt).format("ddd, MMM D, YYYY h:mm A")
            let active=record.active?"Yes":"No"
            return {
              records: {...record},
              counter,
              name,
              created_at,
              active
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default Tlds;
