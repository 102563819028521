import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { FaEdit, FaEye, FaTrashAlt } from "react-icons/fa";
import { DotsLoader } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import {
  delete_geo_industry,
  get_geo_industry,
} from "../../features/GeoIndustrySlice";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
const GeoIndustry = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.geoIndustry);
  const navigate = useNavigate();
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_geo_industry(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Industry deleted");
          dispatch(get_geo_industry());
        } else {
          errorMessage({
            payload: res.payload,
            action: "Industry",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Industry", msg: "deleted" });
      }
    }
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  useEffect(() => {
    dispatch(get_geo_industry());
    // eslint-disable-next-line
  }, []);
  const industries = record?.filter((industry) =>
    industry?.name?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Industry Title", field: "name", flex: 1 },
    { headerName: "Created At", field: "created_at", flex: 1 },
    {
      field: "actions",
      renderCell: (params) => (
        <div>
          {userPermission("Geo Location") && (
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update Industry"
            />
          )}
          {userPermission("Geo Location") && (
            <FaTrashAlt
              onClick={deleteRecordHandler(params.row.records._id)}
              className="my_navIcon"
              title="Delete Industry"
            />
          )}
          {userPermission("Geo Location") && (
            <FaEye
              onClick={() => {
                navigate(
                  `/geolocation/geo_industry/service_area/${params?.row?.records?.id}`
                );
              }}
              className="my_navIcon"
            />
          )}
        </div>
      ),
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Industry"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Industries"
        heading="Industries Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Geo Location")}
      />
      {isLoading && <DotsLoader />}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={industries?.map((record, index) => {
            let counter = index + 1;
            let _id = record.id;
            let name = record.name;
            let created_at = dayjs(record.createdAt).format(
              "ddd, MMM D, YYYY h:mm A"
            );
            return {
              records: { ...record, _id: _id },
              counter,
              name,
              created_at,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default GeoIndustry;
