import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import {
  FaEdit,
  FaPlus,
  FaPowerOff,
  FaTrashAlt,
  FaSearch,
} from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { delete_merchant, get_merchants } from "../../features/merchantSlice";
import { get_timezone } from "../../features/timezoneSlice";
import { get_countries } from "../../features/countriesSlice";
import { get_industry_drd } from "../../features/IndustrySlice";
import { toast } from "react-toastify";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import api from "../../services/api";
import { get_cc_cards, get_cc_providers } from "../../features/didSlice";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
const Merchants = () => {
  const { industryDrd } = useSelector((state) => state.industry);
  const [idsArray, setIdsArray] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  // const [records, setRecords] = useState([]);
  const { timezones } = useSelector((state) => state.timezone);
  const { countries } = useSelector((state) => state.countries);
  const { record } = useSelector((state) => state.merchant);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [connectcx, setConnectcx] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const dispatch = useDispatch();

  const getCX = async () => {
    try {
      const res = await api.get("/api/connectcx/getAllCustomers");
      if (res.status === 200) {
        setConnectcx(res.data);
      }
    } catch (err) {
      console.log("🚀 ~ getCX ~ err:", err);
    }
  };

  const getMerchantList = async (filter) => {
    setIsLoader(true);
    let payload = { ...filter };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      await dispatch(get_merchants(payload));
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      console.log(err);
      if (err?.payload?.response?.data?.error) {
        toast.error(err?.payload?.response?.data?.error || "An error occurred");
      } else {
        toast.error(err?.payload?.response?.data || "An error occurred");
      }
    }
  };
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const reloadData = () => {
    getMerchantList({
      filters: queryOptions,
      page: paginationModel.page + 1,
      size: paginationModel.pageSize,
    });
  };
  const getMerchantById = async (record_id) => {
    try {
      setIsLoader(true);
      const res = await api.get(`/api/merchants/${record_id}`);
      if (res.status === 200) {
        setIsLoader(false);
        return res.data;
      } else {
        setIsLoader(false);
        return null;
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoader(false);
  };

  const openFormHandler = (record) => async () => {
    let data = record;
    if (data) {
      data = await getMerchantById(data._id);
    }
    setEditingRecord(data);

    setEditingRecord(record);
    setIsEditing(true);
  };

  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_merchant(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Merchant deleted");
        } else {
          errorMessage({
            payload: res.payload,
            action: "Merchant",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Merchant", msg: "deleted" });
      }
    }
  };
  const setMerchantLive = (records) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await api.post(
          `/api/merchants/live_merchant/${records?._id}`,
          { live_merchant: !records?.live_merchant }
        );
        if (res?.status === 200) {
          toast.success("Merchant marked as live successfully");
        } else {
          errorMessage({
            payload: res?.data?.message,
            action: "Merchant",
            msg: "marked as live",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error?.response?.data?.message,
          action: "Merchant",
          msg: "marked as live",
        });
      }
    }
  };
  const get_timezones = async () => {
    if (timezones?.length === 0) {
      try {
        const res = await dispatch(get_timezone());
        if (res?.payload?.status === 200) {
          if (countries?.length === 0) {
            dispatch(get_countries());
          }
        }
      } catch (error) {
        console.error("Timezones couldn't be loaded", error);
      }
    }
  };

  useEffect(() => {
    getMerchantList({
      page: 1,
      size: paginationModel.pageSize,
    });
    get_timezones();
    dispatch(get_industry_drd({ type: "all" }));
    getCX();
    dispatch(get_cc_cards());
    dispatch(get_cc_providers());
    // eslint-disable-next-line
  }, []);
  const merchant = record?.data?.filter(
    (record) =>
      record?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      record?.address?.toLowerCase().includes(searchText.toLowerCase()) ||
      record?.email?.toLowerCase().includes(searchText.toLowerCase()) ||
      record?.industry_id?.name
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      record?.job_category_id?.name
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      record?.street?.toLowerCase().includes(searchText.toLowerCase())
  );
  const statusOption = [
    { value: true, label: "Active" },
    { value: false, label: "InActive" },
  ];
  const TagsCellRenderer = (data) => {
    const values = idsArray?.includes(data?.row?._id)
      ? data?.value
      : data?.value?.slice(0, 1);
    const tags = values?.map((tag, index) => (
      <span
        key={tag.id}
        style={{
          margin: "2px",
          color: "black",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
          // backgroundColor:"#f5f5f5"
        }}
      >
        {index + 1}. {tag?.name}
      </span>
    ));

    return (
      <div className="flex flex-col">
        <div className="flex flex-col w-auto">{tags}</div>
        {data?.value?.length > 1 ? (
          !idsArray?.includes(data?.row?._id) ? (
            <div
              className="flex flex-col w-auto ml-3 text-blue-600 cursor-pointer"
              onClick={() => setIdsArray([...idsArray, data?.row?._id])}
            >
              {`See More (${data?.value?.length - 1})`}
            </div>
          ) : (
            <div
              className="flex flex-col w-auto ml-3 text-blue-600 cursor-pointer"
              onClick={() =>
                setIdsArray((preArray) => {
                  if (!preArray || !data?.row?._id) return preArray;
                  return preArray.filter((id) => id !== data.row._id);
                })
              }
            >
              {`See Less`}
            </div>
          )
        ) : null}
      </div>
    );
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 10 },
    { headerName: "Name", field: "name", flex: 1 },
    { headerName: "Username", field: "username", flex: 1 },
    {
      headerName: "Website",
      field: "website",
      flex: 1,
      renderCell: (params) =>
        params?.row?.website ? params?.row?.website : "N/A",
    },
    { headerName: "Email", field: "email", flex: 1 },
    {
      headerName: "Industry",
      field: "industry_id",
      flex: 1,
      renderCell: TagsCellRenderer,
      minWidth: 100,
      type: "singleSelect",
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) => value.name,
      valueOptions: industryDrd,
      valueGetter: (params) => params?.row?.industry_id,
      valueFormatter: (params) => params.value,
    },
    {
      headerName: "CC Url",
      field: "call_center_id",
      filterable: false,
      flex: 1,
      renderHeader: (params) => (
        <div className="flex justify- items-center w-full">
          <Tooltip
            title={
              <div className="flex flex-col text-sm w-[full]">
                Call Center URL
              </div>
            }
          >
            <span className="font-medium">CC Url</span>
          </Tooltip>
        </div>
      ),
      renderCell: (params) =>
        params?.row?.call_center_id?._id
          ? params?.row?.call_center_id?.url
          : "N/A",
    },
    {
      headerName: "Status",
      field: "active",
      width: 80,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: statusOption,
      renderCell: (params) => {
        const status = statusOption.find(
          (status) => status.label === params?.row?.status
        );
        return status ? status.label : "N/A";
      },
    },
    {
      headerName: "Country",
      field: "country_id",
      flex: 1,
      minWidth: 100,
      type: "singleSelect",
      getOptionValue: (option) => option?._id,
      getOptionLabel: (option) => option.name,
      valueOptions: countries,
      renderCell: (params) =>
        params?.row?.country_id?._id ? params?.row?.country_id?.name : "N/A",
    },
    {
      field: "actions",
      filterable: false,
      flex: 1,
      renderCell: (params) =>
        params.row.records.active &&
        params.row.records.name !== "superadmin" ? (
          <div>
            {userPermission("Update Merchant") && (
              <FaEdit
                onClick={openFormHandler(params.row.records)}
                className="my_navIcon"
                title="Update Merchant"
              />
            )}
            {userPermission("Delete Merchant") && (
              <FaTrashAlt
                onClick={deleteRecordHandler(params.row.record_id)}
                className="my_navIcon"
                title="Delete Merchant"
              />
            )}
            <button
              className={`my_navIcon ${
                params?.row?.live_merchant ? "text-green-500" : "text-red-500"
              }`}
              onClick={setMerchantLive(params.row.records)}
              title="Make the merchant live"
            >
              <FaPowerOff
                className={`my_navIcon ${
                  params?.row?.live_merchant ? "text-green-500" : "text-red-500"
                }`}
              />
            </button>
          </div>
        ) : null,
    },
  ];

  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      getMerchantList({
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    let payload = {
      page: 1,
      size: paginationModel.pageSize,
    };
    if (queryOptions.groupOp && queryOptions.rules.length > 0) {
      payload.filters = queryOptions;
    }
    getMerchantList({ ...payload });
  };
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getMerchantList({
      filters: queryOptions,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="flex items-center space-x-1 my-0">
        <div className="justify-between">
          <GridToolbarColumnsButton className="!text-[#042a42]" />
          <GridToolbarDensitySelector className="!text-[#042a42]" />
          <GridToolbarFilterButton
            ref={setFilterButtonEl}
            className="!text-[#042a42]"
          />
          <MUIButton
            variant="text"
            onClick={handleNewFilter}
            startIcon={<FaSearch size={16} />}
            sx={{ fontSize: "0.8125rem" }}
            className={"!text-[#042a42]"}
          >
            Apply filter
          </MUIButton>
        </div>
      </GridToolbarContainer>
    );
  }

  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    const items = data?.industry_id;
    // const items = data?.recipients ? JSON.parse(data?.recipients) : [];
    const numberOfLines = items?.length ? items?.length : 0;
    const addressHeight = rowHeight + numberOfLines * 20;
    if (data?.industry_id && data?.industry_id.length > 1) {
      if (!idsArray?.includes(data?._id)) return rowHeight + 15;
      const tagHeight = 25; // height of each tag
      const numTags = data.industry_id.length;
      if (addressHeight > rowHeight + tagHeight * numTags + cellContentHeight) {
        return addressHeight;
      } else if (idsArray?.includes(data?._id)) {
        return rowHeight + tagHeight * numTags + cellContentHeight;
      } else {
        return rowHeight + tagHeight * numTags + cellContentHeight;
      }
    }
    if (addressHeight > rowHeight + cellContentHeight) {
      return addressHeight;
    } else {
      return rowHeight + cellContentHeight;
    }
  };
  return (
    <div className="container-fluid">
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Merchant"
          onCancelForm={cancelFormHandler}
          connectcx={connectcx}
          reloadData={reloadData}
        />
      )}
      <div className="mb-3 mt-5 mt-md-3">
        <div
          style={{
            fontSize: 16,
            fontWeight: 500,
            color: "#343434",
            fontFamily: "Montserrat",
          }}
        >{`Settings > Merchant`}</div>
      </div>
      <div className="mt-5 mt-md-3">
        <div className="flex flex-row justify-between px-1.7">
          <div className="">
            <div
              style={{
                fontSize: 20,
                fontWeight: 500,
                color: "#343434",
                fontFamily: "Montserrat",
              }}
            >{`Merchant Listing`}</div>
          </div>
          <div className="">
            {userPermission("Add Merchant") && (
              <button
                type="button"
                className="py-1.5 px-3 align-middle bg-primary-100 border-primary-100 text-white float-right flex items-center rounded-0"
                onClick={openFormHandler(0)}
              >
                <FaPlus className="mr-2" />
                Add New
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={merchant?.map((record, index) => {
            const { live_merchant, website } = record;
            let counter = index + offset + 1;
            let record_id = record._id;
            let name = record.name;
            let email = record.email;
            let industry = record.industry_id?.name;
            let job_category = record?.job_category_id?.map((val, ind) => {
              return `${val?.name}`;
            });
            let status = record.active ? "Active" : "InActive";
            let username = record.username;
            let country_id = record.country_id;
            let industry_id = record.industry_id;
            let call_center_id = record.call_center_id;
            return {
              _id: record_id,
              records: record,
              record_id,
              counter,
              name,
              email,
              industry,
              job_category,
              status,
              live_merchant,
              website,
              username,
              country_id,
              industry_id,
              call_center_id,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoader}
          CustomToolbar={CustomToolbar}
          onFilterModelChange={onFilterChange}
          columnVisibilityModel={{
            country_id: false,
          }}
          gridOptions={getRowHeight}
        />
      </div>
    </div>
  );
};

export default Merchants;
