import React, { useState } from "react";

import { Backdrop, Button, DotsLoader, FormInput } from "../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import api from "../../services/api";

const CreateUsernameModal = ({ editingRecord, onCancelForm }) => {
  const [isLoader, setIsLoader] = useState(false);
  let initialValues = {
    id: editingRecord?._id,
    username: "",
  };
  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validateOnBlur: true,

    validationSchema: Yup.object().shape({
      username: Yup.string(),
    }),

    onSubmit: async (values) => {
      setIsLoader(true);
      try {
        const res = await api.post("api/vendors/addLogin", values);
        if (res?.status === 200 || res?.status === 201) {
          toast.success("Email sent successfully");
          onCancelForm();
        } else {
          errorMessage({
            payload: res.data?.error,
            action: "Username",
            msg: "created",
          });
        }
      } catch (err) {
        console.log("👊 ~ onSubmit: ~ err:", err);
        errorMessage({
          payload: err.response.data?.error,
          action: "Username",
          msg: "created",
        });
      } finally {
        setIsLoader(false);
      }
    },
  });
  return (
    <>
      <Backdrop onClick={onCancelForm} />
      <div className="fixed w-full max-w-[500px] max-h-[calc(100vh-64px)] overflow-y-auto top-8 left-1/2 bg-white rounded z-[200] shadow-[0_2px_8px_rgba(0,_0,_0,_0.26)] -translate-x-1/2">
        {isLoader ? <DotsLoader /> : null}
        <header className="border-b- border-[#3b0062 ">
          <h5 className=" mb-3 text-[#343434] pb-2.5 w-full border-b border-[#D9D9D9] font-semibold m-3">
            Create Username for{" "}
            <span className="text-primary-100 font-bold font-pop">{`${
              editingRecord?.first_name
                ? editingRecord?.first_name
                : "" +
                  " " +
                  (editingRecord?.last_name ? editingRecord?.last_name : "")
            }`}</span>{" "}
            vendor
          </h5>
        </header>
        <div className="p-4">
          <FormInput name="username" label="User Name" formik={formik} />
        </div>
        <div className="p-4 text-right">
          <Button
            text="Submit"
            className="mr-2"
            onClick={formik.handleSubmit}
            isLoading={isLoader}
            variant="btn_submit"
          />
        </div>
      </div>
    </>
  );
};

export default CreateUsernameModal;
