import React, { useEffect, useState } from "react";
import { FaSearch, FaTrash, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import dayjs from "dayjs";
import { get_web_query, delete_web_query } from "../../features/reportsSlice";
import { toast } from "react-toastify";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton } from "@material-ui/core";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { get_industry_drd } from "../../features/IndustrySlice";
import PageHeader from "../../components/molecules/PageHeader";
import EmailModal from "./Modal";
const WebQueryReport = () => {
  const dispatch = useDispatch();
  const { isLoading, webQueryReport } = useSelector((state) => state.reports);
  const { industryDrd } = useSelector((state) => state.industry);
  const [emailModal, setEmailModal] = React.useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const currentTime = new Date().getTime(); // Get current timestamp in milliseconds
  const twentyFourHours = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  const startTime = currentTime - twentyFourHours;
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "AND",
    rules: [
      {
        field: "createdAt",
        op: "gte",
        data: startTime,
      },
    ],
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };

  const onFilterChange = React.useCallback((filterModel) => {
    console.log("👊 ~ onFilterChange ~ filterModel:", filterModel);
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      setPaginationModel({ ...paginationModel, page: 1 });
      dispatch(
        get_web_query({
          ...sortingModel,
          page: 1,
          size: paginationModel.pageSize,
        })
      );
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.field === "tlds"
              ? "cn"
              : rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "onOrBefore"
              ? "lte"
              : rule.operator === "before"
              ? "lt"
              : rule.operator === "onOrAfter"
              ? "gte"
              : rule.operator === "after"
              ? "gt"
              : rule.operator,
          data:
            rule.field === "createdAt" || rule.field === "message_date"
              ? new Date(rule.value).getTime()
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    dispatch(
      get_web_query({
        ...sortingModel,
        filters: queryOptions,
        page: 1,
        size: paginationModel.pageSize,
      })
    );
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    const payload = {
      ...sortingModel,
      page: +params.page + 1,
      size: params.pageSize,
    };

    if (queryOptions.rules.length > 0) {
      payload.filters = queryOptions;
    } else {
      delete payload.filters;
    }
    dispatch(get_web_query(payload));
  };
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const payload = {
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
    };

    if (queryOptions.rules.length > 0) {
      payload.filters = queryOptions;
    } else {
      delete payload.filters;
    }
    dispatch(get_web_query(payload));
    dispatch(get_industry_drd());
    // eslint-disable-next-line
  }, []);

  const filterIP = webQueryReport?.data?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        {selectedData?.length ? (
          <span className="text-sm font-semibold bg-[#fcebc5] py-1 px-1.5">
            {`${selectedData?.length} items are selected `}
            <span
              className="text-blue-600 hover:underline cursor-pointer ml-2"
              onClick={() => setSelectedData([])}
            >
              Clear
            </span>
          </span>
        ) : null}
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
        {selectedData?.length ? (
          <MUIButton
            variant="text"
            onClick={handleBulkDelete}
            startIcon={<FaTrash size={16} />}
            sx={{ fontSize: "0.8125rem" }}
            className={"!text-[#042a42]"}
          >
            Delete Selected
          </MUIButton>
        ) : null}
      </GridToolbarContainer>
    );
  }
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));
  const isSelected = (data) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter(({ _id }) => _id === data?._id).length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = webQueryReport?.data?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const onSingleSelect = ({ checked, data }) => {
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            (msg) => msg?._id !== data?._id
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:116 ~ onSingleSelect ~ err:", err);
    }
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={
            webQueryReport?.data?.length &&
            selectedData?.length === webQueryReport?.data?.length
          }
          disabled={!webQueryReport?.data?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={isSelected(params.row.records)}
          onChange={(e) =>
            onSingleSelect({
              checked: e.target.checked,
              data: params.row.records,
            })
          }
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
      disableColumnMenu: true,
    },
    { headerName: "#", field: "counter", filterable: false, width: 60 },
    {
      field: "domain",
      headerName: "Domain",
      renderCell: (params) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.row.domain}`}
            className="text-[#000] hover:text-blue-600 hover:underline no-underline"
          >
            {params?.row.domain}
          </a>
        );
      },
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Industry",
      field: "industry_name",
      flex: 1,
      type: "singleSelect",
      minWidth: 150,
      getOptionValue: (value) => value?.name,
      getOptionLabel: (value) => value.name,
      valueOptions: industryDrd,
      renderCell: (params) => params.value.industry_name,
      valueGetter: (params) => params.row.industry_name,
      valueFormatter: (params) => params.value.industry_name,
    },
    {
      headerName: "Contact Name",
      field: "contact_name",
      flex: 1,
      filterable: true,
      minWidth: 180,
    },
    { headerName: "Email", field: "email", flex: 1, minWidth: 200 },
    { headerName: "Phone", field: "phone", flex: 1, minWidth: 150 },
    { headerName: "User IP", field: "user_ip", flex: 1, minWidth: 150 },
    {
      headerName: "Message",
      field: "message",
      minWidth: 200,
      wrap: true,
      renderCell: (params) => {
        const substring = params.row.message.substring(0, 100);
        return (
          <div>
            <span>{substring}</span>
            {params.row.message?.length > 100 ? (
              <>
                <span>...</span>
                <LightTooltip title={params.row.message}>
                  <span className="text-blue-600 ml-1 !rounded hover:cursor-pointer">
                    Read more
                  </span>
                </LightTooltip>
              </>
            ) : null}
          </div>
        );
      },
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Message Date",
      field: "message_date",
      flex: 1,
      type: "dateTime",
    },
    {
      headerName: "Message Date",
      field: "format_message_date",
      flex: 1,
      filterable: false,
      minWidth: 220,
    },
    {
      headerName: "Created At",
      field: "createdAt",
      flex: 1,
      type: "dateTime",
    },
    {
      headerName: "Created At",
      field: "formatCreatedAt",
      flex: 1,
      filterable: false,
      minWidth: 220,
    },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex flex-row">
          <FaTrashAlt
            onClick={() => handleDelete(params.row.records?._id)}
            className="my_navIcon"
            title="Delete Record"
          />
        </div>
      ),
      width: 100,
    },
  ];
  const getRowHeight = (params) => {
    const rowHeight = 30; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    const substring = data?.message?.substring(0, 100);
    const numberOfLines = substring ? Math.ceil(substring?.length / 30) : 0;
    const addressHeight = rowHeight + numberOfLines * 30 + 0;
    return addressHeight + cellContentHeight;
  };

  const handleDelete = async (id) => {
    const c = window.confirm(`Are you sure want to delete this record?`);
    if (c) {
      try {
        const res = await dispatch(delete_web_query({ ids: [id] }));
        if (res?.payload?.status === 200) {
          toast.success("Record deleted successfully");
          const payload = {
            ...paginationModel,
            ...sortingModel,
            size: paginationModel.pageSize,
          };

          if (queryOptions.rules.length > 0) {
            payload.filters = queryOptions;
          } else {
            delete payload.filters;
          }
          dispatch(get_web_query(payload));
        } else {
          toast.error("Record couldn't deleted");
        }
      } catch (err) {
        toast.error("Record couldn't deleted");
        console.error("🚀 ~ file: index.jsx:172 ~ handleDelete ~ err:", err);
      }
    }
  };
  const handleBulkDelete = async () => {
    const c = window.confirm(
      `Are you sure want to delete the selected records?`
    );
    if (c) {
      const ids = selectedData?.map(({ _id }) => _id);
      try {
        const res = await dispatch(delete_web_query({ ids }));
        if (res?.payload?.status === 200) {
          toast.success("Records deleted successfully");
          setSelectedData([]);
          const payload = {
            ...paginationModel,
            ...sortingModel,
            size: paginationModel.pageSize,
          };

          if (queryOptions.rules.length > 0) {
            payload.filters = queryOptions;
          } else {
            delete payload.filters;
          }
          dispatch(get_web_query(payload));
        } else {
          toast.error("Records couldn't deleted");
        }
      } catch (err) {
        toast.error("Records couldn't deleted");
        console.error("🚀 ~ file: index.jsx:172 ~ handleDelete ~ err:", err);
      }
    }
  };
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    dispatch(
      get_web_query({
        filters: queryOptions,
        page: paginationModel.page,
        size: paginationModel.pageSize,
        sort_field: params[0]?.field,
        sort_by: params[0]?.sort || "default",
      })
    );
  };
  const emailFilter = (filter) => {
    const payload = {
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
    };

    if (filter.rules.length > 0) {
      payload.filters = filter;
    } else {
      delete payload.filters;
    }
    dispatch(get_web_query(payload));
  };
  const LineOne = () => {
    return (
      <button
        className="font-medium text-blue-600"
        onClick={() => setEmailModal(true)}
      >
        {webQueryReport?.emailsWithMultipleRecords?.length} emails have multiple
        records
      </button>
    );
  };

  return (
    <>
      <PageHeader heading="Web Queries" />
      {emailModal ? (
        <EmailModal
          onClose={() => setEmailModal(false)}
          emailData={webQueryReport?.emailsWithMultipleRecords || []}
          emailFilter={emailFilter}
          setFilter={setQueryOptions}
          filters={queryOptions}
        />
      ) : null}

      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={filterIP?.map((record, index) => {
            const {
              domain,
              contact_name,
              email,
              phone,
              user_ip,
              message,
              createdAt,
              industry_name,
              message_date,
            } = record;
            let counter = index + 1;
            return {
              records: record,
              counter,
              message_date: new Date(message_date),
              industry_name,
              domain,
              contact_name,
              email,
              phone,
              user_ip,
              message,
              createdAt: new Date(createdAt),
              format_message_date: message_date
                ? dayjs(message_date).format("ddd, MMM D, YYYY h:mm A")
                : "",
              formatCreatedAt: dayjs(createdAt).format(
                "ddd, MMM D, YYYY h:mm A"
              ),
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          paginationModel={paginationModel}
          totalItems={webQueryReport?.totalItems}
          gridOptions={getRowHeight}
          CustomToolbar={CustomToolbar}
          onFilterModelChange={onFilterChange}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          columnVisibilityModel={{ createdAt: false, message_date: false }}
          LineOne={
            webQueryReport?.emailsWithMultipleRecords?.length ? LineOne : null
          }
          initialState={{
            filter: {
              filterModel: {
                items: [
                  {
                    field: "createdAt",
                    operator: "onOrAfter",
                    value: dayjs(startTime).format("YYYY-MM-DDTHH:mm"),
                  },
                ],
                logicOperator: "and",
                quickFilterValues: [],
                quickFilterLogicOperator: "and",
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default WebQueryReport;
