import { DataGridPro } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { job_call_logs } from "../../../features/jobsSlice";
import { sendIframeMsg } from "../../../util/iFrame";
import { Button } from "../../../components";

function JobListing({ onCancelModal, createLeadInitial, callType }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [vicidialId, setVicidialId] = React.useState(null);
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const receiveMessage = (event) => {
    if (event?.data?.action === "vendor_id_confirm") {
      if (document.visibilityState === "visible") {
        setVicidialId(event?.data?.vicidial_id);
      }
    }
  };
  window.addEventListener("message", receiveMessage);
  const openJob = (job) => {
    sendIframeMsg({
      action: "send_vendor_lead_code",
      user: user?.dialer_data?.dialer_user,
      pass: user?.dialer_data?.dialer_pass,
      vendor_lead_code: job?.records?.lead_num,
    });
    dispatch(
      job_call_logs({
        lead_id: job?._id,
        type: "Client",
        vicidial_id: vicidialId ? vicidialId : null,
        call_type: callType,
      })
    );
    navigate(`/jobs/update/${job?._id}`);
  };
  const { isLoading, multipleJobs } = useSelector((state) => state.jobs);
  const columnDefs = [
    {
      field: "Job #",
      renderCell: (params) => (
        <span
          onClick={() => openJob(params.row)}
          className="text-blue-600 cursor-pointer hover:underline"
        >
          {params.row.job_number}
        </span>
      ),
      width: 60,
    },
    {
      headerName: "Created By",
      field: "created_by",
      flex: 1,
      minWidth: 100,
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Created At",
      field: "createdAt",
      flex: 1,
      minWidth: 120,
      cellClassName: "multiline-cell",
    },

    { headerName: "Client", field: "name" },
    { headerName: "Industry", field: "industry_id", flex: 1, minWidth: 100 },
    { headerName: "Status", field: "jobStatus" },
    { headerName: "Phone", field: "phone", flex: 1, minWidth: 120 },
    {
      headerName: "Address",
      field: "complete_pickup_address",
      width: 180,
      wrap: true,
      cellClassName: "multiline-cell",
    },
  ];

  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    const data = params?.model;
    const numberOfLines = data?.complete_pickup_address
      ? Math.ceil(data?.complete_pickup_address?.length / 40)
      : 0;
    const addressHeight = rowHeight + numberOfLines * 30 + 20;
    return addressHeight;
  };
  return (
    <div>
      <div className="w-full h-screen z-[100] fixed top-0 left-0 opacity-100 bg-black/[0.75] flex items-center justify-center">
        <div className="z-10 w-full md:max-w-[1224px] md:min-w-[730px] max-h-[calc(100vh-130px)] overflow-y-auto bg-neutral-100 p-3.5 border-[10px] border-white">
          <div className="pt-0 max-w-[1194px] h-full px-3.5 m-auto">
            <header className="bg-primary-100 h-[60px] flex items-center justify-center shadow-lg">
              <h1 className=" text-2xl text-white m-4 font-medium">
                {multipleJobs?.records?.length} Existing Jobs found
              </h1>
            </header>
            <div className="md:p-4">
              <div style={{ height: 425, width: "100.1%" }}>
                <DataGridPro
                  rows={
                    isLoading
                      ? []
                      : multipleJobs.records?.length > 0
                      ? multipleJobs.records?.map((record, index) => {
                          const {
                            _id,
                            job_number,
                            name,
                            phone,
                            job_category_id,
                            createdBy,
                            createdAt,
                            complete_pickup_address,
                            scheduled_on,
                            job_status_id,
                            industry_id,
                          } = record;
                          return {
                            _id,
                            counter: index + 1,
                            records: record,
                            job_number,
                            job_type: job_category_id?.name,
                            created_by: createdBy?.username,
                            name,
                            phone,
                            jobStatus: job_status_id?.name,
                            createdAt: dayjs(createdAt).format(
                              "ddd, MMM D, YYYY h:mm A"
                            ),
                            scheduled_on:
                              scheduled_on &&
                              dayjs(scheduled_on).format(
                                "ddd, MMM D, YYYY h:mm A"
                              ),
                            industry_id: industry_id?.name,
                            complete_pickup_address,
                          };
                        })
                      : []
                  }
                  columns={columnDefs}
                  getRowId={(row) => row?.records?._id}
                  headerHeight={40} // Set the height of the header
                  classes={{
                    columnHeader: "bg-[#f8f8f8] shadow-sm",
                    columnHeaderTitle:
                      "tableHead font-bold text-md capitalize px-0",
                    row: "text-md",
                  }}
                  getRowHeight={getRowHeight}
                />
              </div>
            </div>

            <div className="!p-4 text-right">
              <Button
                text="Close"
                className="mx-2"
                onClick={onCancelModal}
                variant="btn_cancel"
              />
              <Button
                text={"Create New Lead"}
                className=""
                onClick={createLeadInitial}
                // isLoading={props?.isLoading}
                variant="btn_submit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default JobListing;
