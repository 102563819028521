import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import AddUpdateProvider from "./AddUpdateProvider";
import { get_providers } from "../../features/ProviderSlice";
import userPermission from "../../util/userPermission";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
const Providers = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.provider);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  useEffect(() => {
    dispatch(get_providers());
    // eslint-disable-next-line
  }, []);
  const providers = record?.filter(
    (provider) =>
      provider?.provider_id?.toLowerCase().includes(searchText.toLowerCase()) ||
      provider?.name?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Name", field: "name", flex: 1 },
    { headerName: "Key Code", field: "key_code", flex: 1 },
    { headerName: "URL", field: "url", flex: 1 },
    { headerName: "Sandbox URL", field: "sandbox_url", flex: 1 },
    { headerName: "Status", field: "status", flex: 1 },
    {
      field: "actions",
      renderCell: (params) =>
        params.row.records?.active ? (
          <div>
            {userPermission("Update Payment Provider") && (
              <FaEdit
                onClick={openFormHandler(params.row.records)}
                className="my_navIcon"
                title="Update Provider"
              />
            )}
          </div>
        ) : null,
      width: 120,
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  return (
    <>
      {isEditing && (
        <AddUpdateProvider
          editingRecord={editingRecord}
          modalTitle="Add Payment Provider"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Payment Providers"
        heading="Payment Providers Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Provider")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={providers?.map((record, index) => {
            let counter = index + 1;
            let name = record.name;
            let key_code = record.key_code;
            let url = record.url;
            let sandbox_url = record?.sandbox_url;
            let status = record.active ? "Yes" : "No";
            return {
              counter,
              records: record,
              name,
              key_code,
              url,
              sandbox_url,
              status,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          paginationMode={"client"}
        />
      </div>
    </>
  );
};

export default Providers;
