import dayjs from "dayjs";
import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../context/GlobalContext";

export default function Day({ day, rowIdx }) {
  const [dayEvents, setDayEvents] = useState([]);
  const {
    setDaySelected,
    setShowEventModal,
    filteredEvents,
    setSelectedEvent,
    setViewType
  } = useContext(GlobalContext);

  useEffect(() => {
    const events = filteredEvents?.filter(
      (evt) =>
        dayjs(evt.scheduled_on).format("DD-MM-YY") === day.format("DD-MM-YY")
    );
    setDayEvents(events);
  }, [filteredEvents, day]);

  function getCurrentDayClass() {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? "bg-blue-600 text-white rounded-full w-7"
      : "";
  }
  return (
    <div className="border border-gray-200 flex flex-col">
      <header className="flex flex-col items-center">
        {rowIdx === 0 && (
          <p className="text-sm mt-1">{day.format("ddd").toUpperCase()}</p>
        )}
        <p
          className={`text-sm p-1 my-0.5 text-center  ${getCurrentDayClass()}`}
        >
          {day.format("DD")}
        </p>
      </header>
      <div
        className="flex-1 cursor-pointer"
      >
        {dayEvents?.slice(0, 3)?.map((evt, idx) => (
          <div
            key={idx}
            onClick={() => {
              setSelectedEvent(evt);
              setShowEventModal(true);
            }}
            className={`${
              evt.job_created ? "bg-indigo-200" : "bg-purple-200"
            } p-1 mx-1.5 text-gray-600 text-sm rounded mb-1 truncate`}
          >
            {evt?.job_created ? "Job#" : "Lead#"}{" "}
            {evt?.job_created ? evt?.job_number : evt?.lead_num}{" - "}
            {evt.industry_id?.name}
          </div>
        ))}
        {dayEvents?.length > 3 && (
          <div
            onClick={() => {
              setDaySelected(day);
              setViewType('day')
            }}
            className={`bg-red-200 p-0.5 mx-0.5 text-gray-600 text-sm rounded truncate `}
          >
            See More {`- ${dayEvents?.length - 3}`}
          </div>
        )}
      </div>
    </div>
  );
}
