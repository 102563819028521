import React from "react";
import { FaPhone } from "react-icons/fa";
import { FiMaximize } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import SelectInput from "../../components/atoms/SelectInput";
import { get_agent_calls_by_date } from "../../features/dashboardSlice";
import { Bar, Doughnut } from "react-chartjs-2";
import Picker from "../../reports/Components/Picker";
import dayjs from "dayjs";
import { Loader } from "../../components";
import { toast } from "react-toastify";
import StatsLoader from "../../components/organisms/Statsloader";
import GraphLoader from "../../components/organisms/GraphLoader";
import PieChartLoader from "../../components/organisms/PieChartLoader";
function ByAgent({
  searchTypes,
  selectedSearchType,
  setSelectedSearchType,
  setGraphData,
  setIsGraphModal,
  barOptions,
  pieOptions,
  days,
  months,
}) {
  const dispatch = useDispatch();
  const { isAgentLoading, agentCallsOverAll } = useSelector(
    (state) => state.dashboard
  );
  const [searchText, setSearchText] = React.useState("");
  const { isLoading, users } = useSelector((state) => state.users);
  const localDate = new Date();

  // Get the time zone offset in minutes
  const timeZoneOffsetMinutes = localDate.getTimezoneOffset();
  // Convert the offset to hours and minutes
  const offsetHours = Math.floor(Math.abs(timeZoneOffsetMinutes) / 60);
  const offsetMinutes = Math.abs(timeZoneOffsetMinutes) % 60;
  // Determine the sign of the offset
  const offsetSign = timeZoneOffsetMinutes > 0 ? "-" : "+";
  // Format the offset in the desired format
  const offsetString = `${offsetSign}${offsetHours
    .toString()
    .padStart(2, "0")}:${offsetMinutes.toString().padStart(2, "0")}`;
  const fromDate =
    new Date(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      0,
      0,
      0,
      0
    )
      .toISOString()
      .slice(0, 23) + "Z";
  const getTimezoneString = () => {
    const date = new Date();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  };
  const toDate = new Date().toISOString().slice(0, 23) + "Z";
  const [agentFilter, setAgentFilter] = React.useState({
    start_date: fromDate,
    end_date: toDate,
    day: localDate.getDay() + 1,
    vendor_id: "all",
    timezone: offsetString,
    timezone_name: getTimezoneString(),
  });

  const [agentRecord, setAgentRecord] = React.useState({
    byDays: {
      labels: [],
      datasets: [
        {
          label: "Calls",
          data: [], // Values for Total Calls
          backgroundColor: "rgba(4, 42, 66,1)",
          borderColor: "rgba(4, 42, 66, 1)",
          borderWidth: 2,
          pointRadius: 5,
          fill: false,
        },
        {
          label: "Bookings",
          data: [], // Values for Answered Calls
          backgroundColor: "rgba(255, 183, 0,1)",
          borderColor: "rgba(255, 183, 0, 1)",
          borderWidth: 2,
          pointRadius: 5,
          fill: false,
        },
      ],
    },
    byDates: {
      labels: [],
      datasets: [
        {
          label: "Calls",
          data: [], // Values for Total Calls
          backgroundColor: "rgba(4, 42, 66,1)",
          borderColor: "rgba(4, 42, 66, 1)",
          borderWidth: 2,
          pointRadius: 5,
          fill: false,
        },
        {
          label: "Bookings",
          data: [], // Values for Answered Calls
          backgroundColor: "rgba(255, 183, 0,1)",
          borderColor: "rgba(255, 183, 0, 1)",
          borderWidth: 2,
          pointRadius: 5,
          fill: false,
        },
      ],
    },
    overAll: {
      labels: ["Calls", "Bookings"],
      datasets: [
        {
          data: [],
          backgroundColor: ["rgba(4, 42, 66,1)", "rgba(255, 183, 0,1)"],
          borderColor: ["rgba(4, 42, 66,1)", "rgba(255, 183, 0,1)"],
          borderWidth: 1,
        },
      ],
    },
  });
  const getDayIndex = (day) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return days.indexOf(day);
  };
  const getDatesForDay = (start, end, day) => {
    const dates = [];
    const days = [];
    let currentDate = new Date(start);
    const endDate = new Date(end);
    while (currentDate <= endDate) {
      if (currentDate.getDay() === getDayIndex(day)) {
        days.push(new Date(currentDate));
      }
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return { days, dates };
  };
  const handleGetDatesClick = (selectedDay) => {
    const startDate = dayjs(agentFilter?.start_date).format("YYYY-MM-DD");
    const endDate = dayjs(agentFilter?.end_date).format("YYYY-MM-DD");
    if (startDate && endDate) {
      const { days, dates } = getDatesForDay(
        agentFilter?.start_date,
        agentFilter?.end_date,
        selectedDay
      );
      const dayLabels = days?.map(
        (date) => `${date?.getMonth() + 1}-${date?.getDate()}`
      );
      const dateLabels = dates?.map(
        (date) => `${date?.getMonth() + 1}-${date?.getDate()}`
      );
      setAgentRecord({
        ...agentRecord,
        byDays: {
          ...agentRecord?.byDays,
          labels: dayLabels,
        },
        byDates: {
          ...agentRecord?.byDates,
          labels: dateLabels,
        },
      });
      // setResultDates(objects);
      // getDashboardData();
    } else {
      // Handle error, both start date and end date must be selected
    }
  };
  React.useEffect(() => {
    handleGetDatesClick(
      days?.find(({ value }) => value === +agentFilter.day)?.label
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentFilter]);
  const getDashboardAgentData = async () => {
    let payload = { ...agentFilter };
    if (agentFilter?.vendor_id === "all") {
      payload = { ...payload, vendor_id: "" };
    }
    const res = await dispatch(get_agent_calls_by_date({ ...payload }));

    if (res?.payload?.status === 200) {
      const data = res?.payload.data?.by_overall;
      // if (!data?.calls && !data?.sales) {
      //   // toast.info("No Record found by agent");
      // }
      const newData = [data?.calls, data?.sales];

      setAgentRecord((prevState) => ({
        ...prevState,
        overAll: {
          ...prevState.overAll,
          datasets: [
            {
              ...prevState.overAll.datasets[0],
              data: newData,
            },
          ],
        },
      }));
    }

    if (res?.payload?.status === 200) {
      const data = res?.payload?.data?.by_date;
      const callsData = [];
      const bookingsData = [];
      const callsByDays = [];
      const bookingsByDays = [];

      agentRecord.byDates.labels.forEach((label) => {
        const foundData = data?.find(
          (item) => `${item.Month}-${item.Day}` === label
        );

        if (foundData) {
          callsData.push(foundData.total);
          bookingsData.push(foundData.sale_status);
        } else {
          callsData.push(0);
          bookingsData.push(0);
        }
      });
      agentRecord.byDays.labels.forEach((label) => {
        const foundData = data?.find(
          (item) => `${item.Month}-${item.Day}` === label
        );

        if (foundData) {
          callsByDays.push(foundData.total);
          bookingsByDays.push(foundData.sale_status);
        } else {
          callsByDays.push(0);
          bookingsByDays.push(0);
        }
      });

      const updatedDatasets = agentRecord.byDates.datasets.map((item) => {
        if (item.label === "Calls") {
          return { ...item, data: callsData };
        } else if (item.label === "Bookings") {
          return { ...item, data: bookingsData };
        }
        return item;
      });

      const updatedDatasetsByDay = agentRecord.byDays.datasets.map((item) => {
        if (item.label === "Calls") {
          return { ...item, data: callsByDays };
        } else if (item.label === "Bookings") {
          return { ...item, data: bookingsByDays };
        }
        return item;
      });

      setAgentRecord((prevState) => ({
        ...prevState,
        byDates: {
          ...prevState.byDates,
          datasets: updatedDatasets,
        },
        byDays: {
          ...prevState.byDays,
          datasets: updatedDatasetsByDay,
        },
      }));
    }
  };
  React.useEffect(() => {
    if (
      agentRecord?.byDays?.labels?.length > 0 ||
      agentRecord?.byDates?.labels?.length > 0
    ) {
      getDashboardAgentData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentRecord?.byDays?.labels, agentRecord?.byDates?.labels]);
  const agentOptions = users?.users
    ?.filter((user) => user?.dialer_user?.dialer_user)
    ?.map((user) => ({
      value: user?.dialer_user?.dialer_user,
      label: `${user?.first_name || ""} ${user?.last_name || ""}`,
    }));
  const agentOption = agentOptions?.filter((user) =>
    user?.label?.toLowerCase()?.includes(searchText?.toLowerCase())
  );
  return (
    <div className="relative border p-2 mt-2">
      <div className="flex flex-col md:flex-row justify-between">
        <div className="flex flex-col">
          <h2>By Agents</h2>
          <div className="grid grid-cols-2 mt-3">
            {isAgentLoading ? (
              <StatsLoader />
            ) : (
              <div className="flex flex-row w-full items-center p-1">
                <div className="rounded-full border flex justify-center items-center h-[40px] w-[40px] bg-[#042A42]/20">
                  <FaPhone color="#042A42" />
                </div>
                <div className="flex flex-col ml-2 w-auto">
                  <small className="font-pop text-lg font-bold">
                    {agentCallsOverAll?.calls || 0}
                  </small>
                  <small className="font-pop">Total Calls</small>
                </div>
              </div>
            )}
            {isAgentLoading ? (
              <StatsLoader />
            ) : (
              <div className="flex flex-row w-full items-center p-1">
                <div className="rounded-full border border-[#FFB700] flex justify-center items-center h-[40px] w-[40px] bg-[#FFB700]/20">
                  <FaPhone color="#FFB700" />
                </div>
                <div className="flex flex-col ml-2 w-auto">
                  <small className="font-pop text-lg font-bold">
                    {agentCallsOverAll?.sales}{" "}
                    {agentCallsOverAll?.calls && agentCallsOverAll?.sales
                      ? `(${(
                          (+agentCallsOverAll?.sales /
                            +agentCallsOverAll?.calls) *
                          100
                        )?.toFixed(2)})%`
                      : "(0%)"}
                  </small>
                  <small className="font-pop">Booking</small>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex max-w-1/4">
          <Picker
            filterData={agentFilter}
            setFiltersData={setAgentFilter}
            dropdown_label="Agent"
            dropdown_menu={
              agentOption
                ? [{ value: "all", label: "All" }, ...agentOption]
                : [{ value: "all", label: "All" }]
            }
            valueKey="vendor_id"
            value
            fromKey={"start_date"}
            toKey={"end_date"}
            rangeValue={"Today"}
            setSearchText={setSearchText}
            searchText={searchText}
            searchable
            isLoader={isLoading}
          />
        </div>
      </div>

      <div className="grid md:grid-cols-3 mt-3 gap-x-1">
        <div className="flex flex-col border shadow-md min-h-[286px] justify-center relative">
          <div className="flex flex-row justify-between p-2">
            <div className="space-x-2 flex flex-row">
              {searchTypes.map((type, index) => (
                <div key={index} className="flex items-center">
                  <input
                    type="checkbox"
                    id={`search-type-${index}-agent`}
                    name="searchType"
                    value={type}
                    checked={selectedSearchType === type}
                    onChange={() => setSelectedSearchType(type)}
                  />
                  <label htmlFor={`search-type-${index}`} className="ml-1">
                    {type}
                  </label>
                </div>
              ))}
            </div>
            <FiMaximize
              className="cursor-pointer"
              onClick={() => {
                setGraphData({
                  title: `Calls Compare View By ${selectedSearchType}`,
                  graphOptions: barOptions,
                  data: {
                    ...agentRecord,
                    labels: agentRecord?.byDays.labels,
                    datasets: agentRecord?.byDays.datasets,
                  },
                  graphType: "Bar",
                });
                setIsGraphModal(true);
              }}
            />
          </div>
          <div className="flex justify-between items-center p-2">
            <span className="text-lg font-semibold">
              Calls Compare View By Days
            </span>
            {selectedSearchType !== "Years" ? (
              <SelectInput
                className={
                  "h-[31px] bg-white border border-gray-300 float-right rounded-[0.2rem]"
                }
                onChange={(e) => {
                  const day = days?.find(
                    ({ value }) => value === +e.target.value
                  )?.label;
                  handleGetDatesClick(day);
                  setAgentFilter({
                    ...agentFilter,
                    day: e.target.value,
                  });
                }}
                options={selectedSearchType === "Days" ? days : months}
                value={agentFilter.day}
              />
            ) : null}
          </div>
          {isAgentLoading ? (
            <GraphLoader />
          ) : (
            <Bar
              data={{
                labels: agentRecord?.byDays.labels,
                datasets: agentRecord?.byDays.datasets,
              }}
              options={barOptions}
            />
          )}
        </div>
        <div className="flex flex-col border shadow-md min-h-[286px] justify-center relative">
          <div className="flex justify-between items-center p-2">
            <span className="text-lg font-semibold">
              Calls Compare View By Date
            </span>
            <FiMaximize
              className="cursor-pointer"
              onClick={() => {
                setGraphData({
                  title: `Calls Compare View By Date`,
                  graphOptions: barOptions,
                  data: {
                    labels: agentRecord?.byDates.labels,
                    datasets: agentRecord?.byDates.datasets,
                  },
                  graphType: "Bar",
                });
                setIsGraphModal(true);
              }}
            />
          </div>
          {isAgentLoading ? (
            <GraphLoader />
          ) : (
            <Bar
              data={{
                labels: agentRecord?.byDates.labels,
                datasets: agentRecord?.byDates.datasets,
              }}
              options={barOptions}
            />
          )}
        </div>
        <div className="flex flex-col border shadow-md min-h-[286px] justify-center relative">
          <div className="flex justify-between items-center p-2">
            <span className="text-lg font-semibold">Inbound Calls</span>
          </div>
          <div className="h-60 flex items-center justify-center pb-3">
            {isAgentLoading ? (
              <PieChartLoader />
            ) : (
              <Doughnut data={agentRecord?.overAll} options={pieOptions} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ByAgent;
