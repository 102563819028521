import React, { useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { FormInput, Modal, DotsLoader } from "../../../components";
import errorMessage from "../../../util/errorMessage";
import * as Yup from "yup";
import api from "../../../services/api";
import { FaInfoCircle } from "react-icons/fa";
function AddCloudflareEmailModal({ onCancelForm, modalTitle, onSubmit }) {
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [aliasError, setAliasError] = useState("");
  const initialValues = {
    alias_name: "",
  };
  const validationSchema = Yup.object({
    alias_name: Yup.string().required("Alias Name is required"),
  });
  const handleSubmit = async (values) => {
    setIsLoading(true);

    try {
      const res = await api.post("/api/cloudFlare/email_alias", {
        alias_name: values?.alias_name,
      });

      if (res.status === 200 || res.status === 201) {
        toast.success("Alias created successfully.");
        onSubmit();
      } else {
        errorMessage({
          payload: res.data?.error || res.data,
          setErrors: setErrors,
          action: "Alias",
          msg: "created",
        });
      }
    } catch (err) {
      const errorData = err?.response?.data;
      if (errorData?.error) {
        errorMessage({
          payload: errorData.error,
          setErrors: setErrors,
          action: "Alias",
          msg: "created",
        });
      } else {
        setAliasError(err?.response?.data?.message);
        console.error(err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validationSchema,
    onSubmit: handleSubmit,
  });
  return (
    <Modal
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={formik.handleSubmit}
      onClick={onCancelForm}
    >
      {isLoading ? <DotsLoader /> : null}
      <div className="w-full flex justify-center mt-2">
        <div className="w-full max-w-md mx-4">
          <FormInput
            errors={errors}
            name="alias_name"
            label="Alias"
            formik={formik}
          />
          {aliasError ? (
            <div
              class="flex items-center p-2 mb-2 text-sm text-red-500 dark:text-yellow-300 "
              role="alert"
            >
              <FaInfoCircle />
              <div>
                <span class="font-medium ml-2">{aliasError}</span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
}

export default AddCloudflareEmailModal;
