import React from "react";
import {
  car_lockout_gray,
  car_lockout_white,
  gas_station_gray,
  gas_station_white,
  jump_start_gray,
  jump_start_white,
  stuck_in_gray,
  stuck_in_white,
  tire_change_gray,
  tire_change_white,
  tow_gray,
  tow_white,
} from "../../../../images";
function Services({ props }) {
  const { record, formik, setIsDisable } = props;
  const [isHover, setIsHover] = React.useState(null);

  const isSelected = (id) => {
    if (formik?.values?.job_category_id) {
      if (formik.values.job_category_id === id) {
        return true;
      }
    }
    return false;
  };
  React.useEffect(() => {
    if (formik?.values?.job_category_id) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
    // eslint-disable-next-line
  }, [formik?.values?.job_category_id]);

  const handleServiceSelection = (service) => {
    if (!service?.drop_location_required) {
      formik.setFieldValue("drop_latitude", "");
      formik.setFieldValue("drop_longitude", "");
      formik.setFieldValue("drop_country", "");
      formik.setFieldValue("drop_city", "");
      formik.setFieldValue("drop_state", "");
      formik.setFieldValue("drop_zip", "");
      formik.setFieldValue("complete_drop_address", "");
      formik.setFieldValue("distance", "");
    }
    formik.setFieldValue("job_category_id", service?._id);
  };
  const image = (service, index, _id) => {
    let img_white = "";
    let img_gray = "";
    switch (service) {
      case "Battery Replacement":
        img_white = jump_start_white;
        img_gray = jump_start_gray;
        break;
      case "Tire Replacement":
        img_white = tire_change_white;
        img_gray = tire_change_gray;
        break;
      case "Winchout":
        img_white = stuck_in_white;
        img_gray = stuck_in_gray;
        break;
      case "Jump Start":
        img_white = jump_start_white;
        img_gray = jump_start_gray;
        break;
      case "Car Lockout":
        img_white = car_lockout_white;
        img_gray = car_lockout_gray;
        break;
      case "Tire Change":
        img_white = tire_change_white;
        img_gray = tire_change_gray;
        break;
      case "Gas Delivery":
        img_white = gas_station_white;
        img_gray = gas_station_gray;
        break;
      case "Tow Service":
        img_white = tow_white;
        img_gray = tow_gray;
        break;
      default:
        break;
    }
    return (
      <span
        className="bg-no-repeat bg-center bg-contain w-8 h-8"
        style={{
          backgroundImage: `url(${
            isHover === index || isSelected(_id) ? img_white : img_gray
          })`,
        }}
      />
    );
  };
  return (
    <div className="grid grid-cols-2 gap-x-2">
      {record?.map((data, index) => {
        const { _id, name } = data;
        return (
          <li
            className="!mb-2 list-none active"
            key={_id}
            onMouseOver={() => setIsHover(index)}
            onMouseOut={() => setIsHover(null)}
            onClick={() => handleServiceSelection(data)}
          >
            <div
              className={`hover:bg-client-100 hover:!text-white flex flex-col items-center w-full p-1.25 !rounded !border !border-[#e3e3e3] cursor-pointer
              ${
                isSelected(_id)
                  ? "bg-client-100 !text-white"
                  : "text-black bg-[#fff]"
              }`}
            >
              {image(name, index, _id)}
              <span className="text-xs font-pop">{name}</span>
            </div>
          </li>
        );
      })}
    </div>
  );
}

export default Services;
