// InteliquentNumbers
import React, { useState, useEffect, useRef } from "react";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import {
  FaSearch,
  FaRegFileExcel,
  FaEdit,
  FaTrash,
  FaCheck,
} from "react-icons/fa";
import {
  Button as MUIButton,
  TextField,
  IconButton,
  TablePagination,
} from "@mui/material";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { Button, DotsLoader } from "../../../components";
import PageHeader from "../../../components/molecules/PageHeader";
import api from "../../../services/api";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime"; // Import the plugin
import exportReport from "../../../components/molecules/ExcelReport";
import Tooltip from "@mui/material/Tooltip";
import CustomEditCell from "./CustomEditCell";
import FiltersDropdown from "../../Domains/FiltersDropdown";
import SaveFilterModal from "./SaveFilterModal";
import { HiOutlineRefresh } from "react-icons/hi";
import _ from "lodash";
function InteliquentNumbers() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [isFilterSave, setIsFilterSave] = useState(false);
  dayjs.extend(relativeTime);
  dayjs.extend(utc);
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });

  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const getSERPDomainReport = async (filter) => {
    setIsLoading(true);
    if (!filter?.filters?.rules?.length) {
      delete filter.filters;
    }
    delete filter?.pageSize;
    try {
      const res = await api.post("/api/domains/inteliquent_numbers", filter); //vishnu
      if (res.status === 200) {
        setRecord(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };

  const getDefaultReport = async (filter) => {
    setIsLoading(true);
    if (!filter?.filters?.rules?.length) {
      delete filter.filters;
    }
    delete filter?.pageSize;
    try {
      const res = await api.post("/api/domains/inteliquent_numbers", filter);
      if (res.status === 200 || res.status === 201) {
        const data = res?.data?.userFiltersAndColumns?.find(
          (filter) => filter.is_default === true
        );
        if (data?.is_default) {
          setQueryOptions(data?.data?.filters);
          const item = processFilters(filterModel.items);
          setFilterModel({
            ...filterModel,
            items: item,
          });
          getSERPDomainReport({
            ...paginationModel,
            ...sortingModel,
            filters: data?.data?.filters || { groupOp: "", rules: [] },
            page: 1,
            size: paginationModel.pageSize,
          });
        } else {
          setRecord(res.data);
        }
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchDefaultReport = async () => {
      try {
        await getDefaultReport({
          ...paginationModel,
          ...sortingModel,
          size: paginationModel.pageSize,
          filters: queryOptions,
        });
      } catch (error) {
        console.error("Error fetching default report:", error);
      }
    };

    fetchDefaultReport();
  }, []);

  const getRowHeight = (params) => {
    const rowHeight = 30; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    if (data?.domain && data.domain?.length > 0) {
      const tagHeight = 20; // height of each tag
      const numTags = data?.domain.length;
      return rowHeight + tagHeight * numTags + cellContentHeight;
    }
  };
  const CustomSelectInput = ({ item, applyValue }) => {
    const [value, setValue] = useState(item.value);
    return (
      <div fullWidth>
        {/* <InputLabel>Value</InputLabel> */}
        <TextField
          value={value || ""}
          variant="standard"
          label="Filter value"
          onChange={(event) => {
            setValue(event.target.value);
            applyValue({ ...item, value: event.target.value });
          }}
          fullWidth
        />
      </div>
    );
  };
  const domainCountOperators = [
    {
      label: "=",
      value: "arraySize",
      InputComponent: CustomSelectInput,
      InputComponentProps: { type: "singleSelect" },
      getApplyFilterFn: (filterItem) => {
        return (params) => {
          return params.value === filterItem.value;
        };
      },
    },
  ];
  const deleteStatusOptions = [
    { value: "ActiveDomain", label: "Active Domain" },
    { value: "PendingToImgDelete", label: "Pending To Img Delete" },
    { value: "PendingToDataDelete", label: "Pending To Data Delete" },
    { value: "Deleted", label: "Deleted" },
  ];
  const statusOption = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const CustomCell = (params) => {
    const handleEditClick = () => {
      params.api.startCellEditMode({ id: params.id, field: params.field });
    };
    return (
      <>
        {params?.row?.cc_data?.cx_did_id ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={handleEditClick}
              color="primary"
              sx={{ marginLeft: 1 }}
            >
              <FaEdit size={18} />
            </IconButton>
            <div>{params?.row?.cc_data?.did_description}</div>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton sx={{ marginLeft: 2 }}></IconButton>
            <div>N/A</div>
          </div>
        )}
      </>
    );
  };

  const handleCellEditCommit = async (
    id,
    field,
    value,
    cellId,
    params,
    prevValue
  ) => {
    try {
      const res = await api.post("/api/domains/update_did_info", {
        phone_number: params?.row?.phone,
        did_description: value,
        call_center_id: params?.row?.cc_data?.call_center_id,
      });

      if (res.status === 200 || res.status === 201) {
        setRecord((prevData) => ({
          ...prevData,
          data: prevData.data.map((item) =>
            item._id === id
              ? {
                  ...item,
                  cc_data: {
                    ...item.cc_data,
                    did_description: value,
                  },
                }
              : item
          ),
        }));
        params.api.setEditCellValue({ id: cellId, field, value: value });
        params.api.stopCellEditMode({
          id: cellId,
          field: field,
          value: value,
        });
        toast.success("Row updated successfully!");
      } else {
        throw new Error(res?.data?.message || "Row couldn't be updated");
      }
    } catch (error) {
      console.error("Update Error:", error);
      toast.error("Failed to update row.");
      // Revert to previous value
      params.api.setEditCellValue({ id: cellId, field, value: prevValue });
      params.api.stopCellEditMode({
        id: cellId,
        field: field,
        value: prevValue,
      });
    }
  };

  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = batchList.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };

  const onSingleSelect = ({ checked, data }) => {
    if (isLoading) {
      return toast.error("Please wait until the previous process is complete.");
    }
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          if (!prevSelectedData?.some(({ _id }) => _id === data._id)) {
            return [...prevSelectedData, data];
          }
          return prevSelectedData;
        });
      } else {
        setSelectedData((prevSelectedData) => {
          return prevSelectedData.filter(({ _id }) => _id !== data._id);
        });
      }
    } catch (err) {
      console.error("Error in onSingleSelect:", err);
    }
  };

  const isSelected = (data) => {
    return selectedData?.some(({ _id }) => _id === data._id);
  };

  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={
            batchList?.length && selectedData?.length === batchList?.length
          }
          disabled={!batchList?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <div className="">
          {isSelected(params.row.records) && isLoading ? (
            <div>test</div>
          ) : (
            <input
              type="checkbox"
              checked={isSelected(params.row.records)}
              onChange={(e) =>
                onSingleSelect({
                  checked: e.target.checked,
                  data: params.row.records,
                })
              }
              className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
            />
          )}
        </div>
      ),
      disableColumnMenu: true,
    },
    { headerName: "#", field: "counter", width: 60, filterable: false },
    {
      headerName: "Phone Number",
      field: "phone",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 100,
    },
    {
      field: "domain",
      headerName: "Domain",
      renderCell: (params) => (
        <div>
          {params?.row?.domain?.length > 0 ? (
            params.row.domain.map((item, index) => (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://${item}`}
                className="text-[#000] hover:text-blue-600 hover:underline no-underline"
                key={index}
              >
                {item}
              </a>
            ))
          ) : (
            <span>N/A</span>
          )}
        </div>
      ),
      flex: 1,
      minWidth: 200,
      disableColumnMenu: true,
    },
    {
      headerName: "Connex Customer",
      field: "connex_customer",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 120,
      renderCell: (params) => (
        <div>
          {params?.row?.connex_customer ? (
            <span>{params?.row?.connex_customer}</span>
          ) : (
            "N/A"
          )}
        </div>
      ),
    },
    {
      headerName: "Domain Count",
      field: "domain_count",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 100,
      filterOperators: domainCountOperators,
    },
    {
      headerName: "Phone Status",
      field: "phone_status",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 100,
      renderCell: (params) => (
        <div>
          {params.row.phone_status === "Deleted" ? (
            <span
              className={`text-xs font-medium me-2 px-3.5 py-0.5 rounded-full bg-yellow-300 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300`}
            >
              {params.row.phone_status}
            </span>
          ) : (
            <span>{params.row.phone_status}</span>
          )}
        </div>
      ),
    },
    {
      headerName: "CC Site Name",
      field: "cc_data.did_description",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 250,
      editable: true,
      renderHeader: (params) => (
        <div className="flex justify- items-center w-full">
          <Tooltip
            title={
              <div className="flex flex-col text-sm w-[full]">
                Call Center Site Name
              </div>
            }
          >
            <span className="font-medium">CC Site Name</span>
          </Tooltip>
        </div>
      ),
      renderCell: CustomCell,
      renderEditCell: (params) =>
        params.row?.cc_data?.cx_did_id ? (
          <CustomEditCell
            {...params}
            onCellEditCommit={handleCellEditCommit}
            prevValue={params?.row?.cc_data?.did_description}
          />
        ) : (
          <span className="text-center">
            {params.row?.cc_data?.did_description}
          </span>
        ),
    },
    {
      field: "domain_data.0.is_expired",
      headerName: "Expired",
      flex: 1,
      minWidth: 80,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: statusOption,
      renderCell: (params) => {
        const status = statusOption.find(
          (status) => status.value === params?.row?.domain_data[0]?.is_expired
        );
        return status ? status.label : "N/A";
      },
    },
    {
      field: "domain_data.0.delete_status",
      headerName: "Delete Status",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: deleteStatusOptions,
      renderCell: (params) => {
        const status = deleteStatusOptions.find(
          (status) =>
            status.value === params?.row?.domain_data[0]?.delete_status
        );
        return status ? status.label : "N/A";
      },
    },
    {
      headerName: "Expiration",
      field: "domain_data.0.expiration_date",
      flex: 1,
      type: "date",
      minWidth: 120,
      valueGetter: (params) =>
        new Date(params.row["domain_data.0.expiration_date"]),
      renderCell: (params) =>
        params.row.domain_data?.length ? (
          <span className="hover:underline cursor-pointer">
            {params?.row?.domain_data[0]?.expiration_date
              ? dayjs(params?.row?.domain_data[0]?.expiration_date).format(
                  "ddd, MMM D, YYYY"
                )
              : "N/A"}
          </span>
        ) : (
          <span>N/A</span>
        ),
    },
    {
      headerName: "Generated By",
      field: "domain_data.0.generate_domain_by",
      flex: 1,
      minWidth: 110,
      type: "singleSelect",
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        "domain_data.0.generate_domain_by": value?.value,
      }),
      valueOptions: [
        { value: "city", label: "City" },
        { value: "city_alias", label: "City Alias" },
        { value: "nation", label: "National" },
      ],
      renderCell: (params) => {
        const status = [
          { value: "city", label: "City" },
          { value: "city_alias", label: "City Alias" },
          { value: "nation", label: "National" },
        ].find(
          (status) =>
            status.value === params?.row?.domain_data[0]?.generate_domain_by
        );
        return status ? status.label : "N/A";
      },
    },
  ];
  const batchList = record?.data?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getSERPDomainReport({
      filters: queryOptions,
      ...sortingModel,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    getSERPDomainReport({
      ...sortingModel,
      filters: queryOptions,
      page: +page + 1,
      size: paginationModel.pageSize,
    });
  };
  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
    getSERPDomainReport({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: newPageSize,
    });
  };
  const processFilters = (filters) => {
    return filters.map((filter) => {
      if (
        filter.operator === "isAnyOf" &&
        filter.value?.length &&
        filter.value[0]?.includes(",")
      ) {
        return {
          ...filter,
          value: filter.value[0].split(",").map((item) => item.trim()),
        };
      }
      return filter;
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    const item = processFilters(filterModel.items);
    setFilterModel({
      ...filterModel,
      items: item,
    });
    if (filterModel?.items?.length === 0) {
      getSERPDomainReport({
        ...sortingModel,
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    processFilters(filterModel.items)?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field === "domain_count" ? "domain" : rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "onOrBefore"
              ? "lte"
              : rule.operator === "before"
              ? "lt"
              : rule.operator === "onOrAfter"
              ? "gte"
              : rule.operator === "after"
              ? "gt"
              : /* : rule.operator === "isEmpty" && rule.field === "connex_customer"
              ? "arraySize"
              : rule.operator === "isEmpty" && rule.field === "domain"
              ? "arraySize" */
                rule.operator,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : /* : rule.field === "connex_customer" || rule.field === "domain"
              ? 0 */
              rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getSERPDomainReport({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
        <MUIButton
          variant="text"
          disabled={!checkDeleteConditions(selectedData)}
          onClick={deleteNumber}
          startIcon={<FaTrash size={14} />}
          sx={{
            fontSize: "0.8125rem",
            color: !selectedData.length ? "#6B7280" : "#042a42",
            pointerEvents: !selectedData.length ? "none" : "auto",
          }}
        >
          Delete Number
        </MUIButton>

        <div className="ml-auto ">
          <div className="flex w-fit gapx-2 items-center">
            <TablePagination
              color="primary"
              shape="rounded"
              size="medium"
              showFirstButton
              showLastButton
              count={
                record?.totalItems ? record?.totalItems : record?.length || 0
              }
              page={paginationModel.page - 1 || 0}
              onPageChange={(e, page) => handleTopPageChange(page)}
              rowsPerPage={paginationModel.pageSize || 0}
              onRowsPerPageChange={(e) =>
                handleTopPageSizeChange(e.target.value)
              }
              component="div"
              rowsPerPageOptions={[
                25,
                50,
                75,
                100,
                250,
                500,
                1000,
                1500,
                2000,
                record?.totalItems ? record?.totalItems : record?.length || 0,
              ]}
            />
            <MUIButton
              variant="text"
              startIcon={<FaRegFileExcel size={16} />}
              onClick={(e) => handleExport()}
              sx={{
                borderRadius: "6px",
                marginRight: "5px",
                border: "0px solid",
                borderColor: "#e8eaee",
                height: "26px",
                fontSize: "0.8125rem",
                paddingLeft: 1,
                paddingRight: 1,
                color: "#000",
              }}
            >
              Export
            </MUIButton>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    getSERPDomainReport({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
  };
  const syncData = async () => {
    const c = window.confirm("Are you sure want to start the process?");
    if (!c) return;
    setLoading(true);
    try {
      const res = await api.post(
        "https://api.logicalcrm.com/api/dataSync/start",
        {
          sync_type: "update_inteliquent_phone_nums",
        }
      );
      if (res.status === 200) {
        toast.success(res.data.message || "Process Start successfully");
      } else if (res.status === 201) {
        toast.info(res.data.message || "Process already running");
      } else {
        toast.error(res.data.message || "Process couldn't be Started");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.message || "Process couldn't be Started");
      console.log("🚀 ~ syncData ~ err:", err);
    }
  };

  const handleExport = () => {
    const Header = [
      "Sr",
      "Phone Number",
      "Domain",
      "Connex Customer",
      "Phone Status",
      "CC Site Name",
      "Expired",
      "Delete Status",
      "Expiration",
      "Generated By",
    ];
    const items = batchList.map((record, index) => {
      const {
        phone = " ",
        domain = [],
        phone_status = " ",
        cc_data = [],
        connex_customer = " ",
        domain_data = [],
      } = record;
      const deleteStatus = deleteStatusOptions.find(
        (status) => status.value === domain_data[0]?.delete_status
      );
      const generatedByDomain = [
        { value: "city", label: "City" },
        { value: "city_alias", label: "City Alias" },
        { value: "nation", label: "National" },
      ];
      const generatedStatus = generatedByDomain.find(
        (status) => status.value === domain_data[0]?.generate_domain_by
      );
      const addNAIfEmpty = (value) =>
        value === undefined || value === null || value === "" ? "N/A" : value;
      const add1IfPhoneNotEmpty = (phone) =>
        phone === undefined || phone === null || phone === "" ? "N/A" : phone;
      return {
        sr: (index + 1).toString(),
        phone: add1IfPhoneNotEmpty(phone),
        domain: domain.length ? domain.join(", ") : "N/A",
        connex_customer: addNAIfEmpty(connex_customer[0]),
        phone_status: addNAIfEmpty(phone_status),
        cc_site_name: addNAIfEmpty(cc_data?.did_description),
        expired: domain_data[0]?.is_expired ? "Yes" : "No",
        delete_status: deleteStatus?.label || "N/A",
        expiration_status: domain_data[0]?.expiration_date
          ? dayjs(domain_data[0]?.expiration_date).format("ddd, MMM D, YYYY")
          : "N/A",
        generate_domain_by: generatedStatus?.label || "N/A",
      };
    });

    exportReport(
      items,
      Header,
      "inteliquent No Report",
      "inteliquent No Report"
    );
  };

  const operator = ({ operator, field }) => {
    const isSelect =
      columnDefs?.find((item) => item.field === field)?.type === "singleSelect";
    const isNumber =
      columnDefs?.find((item) => item.field === field)?.type === "number";
    const isDate =
      columnDefs?.find((item) => item.field === field)?.type === "date";
    return operator === "cn"
      ? "contains"
      : operator === "eq" && !isSelect && !isNumber
      ? "equals"
      : operator === "eq" && isSelect && !isNumber
      ? "is"
      : operator === "eq" && !isSelect && isNumber
      ? "="
      : operator === "not" && !isSelect && isNumber
      ? "!="
      : operator === "gt" && isDate
      ? "after"
      : operator === "gte" && isDate
      ? "onOrAfter"
      : operator === "lt" && isDate
      ? "before"
      : operator === "lte" && isDate
      ? "onOrBefore"
      : operator === "gt"
      ? ">"
      : operator === "gte"
      ? ">="
      : operator === "lt"
      ? "<"
      : operator === "lte"
      ? "<="
      : operator === "arraySize"
      ? "isEmpty"
      : operator;
  };
  const [filterModel, setFilterModel] = React.useState({
    items: queryOptions.rules?.map(({ field, op, data }) => ({
      field,
      operator: operator({ operator: op, field }),
      value: data,
      // data === true ? 1 : data === false ? 0 : data,
    })),
    logicOperator: "and",
    quickFilterValues: [],
    quickFilterLogicOperator: "and",
  });

  const findMatchingObject = () => {
    return _.find(record?.userFiltersAndColumns, (item) => {
      if (item.data.filters) {
        return _.isEqual(item.data.filters, queryOptions);
      } else {
        return _.isEqual(item.data, queryOptions);
      }
    });
  };
  const isAllObjectMatched = () => {
    return record?.userFiltersAndColumns?.some((obj) => {
      return (
        obj.data.groupOp === queryOptions.groupOp &&
        queryOptions.rules.every((rule) =>
          obj.data.rules.some(
            (objRule) =>
              objRule.field === rule.field &&
              objRule.op === rule.op &&
              objRule.data === rule.data
          )
        )
      );
    });
  };
  const areObjectsEqual = (selectedFilter) => {
    return _.isEqual(selectedFilter, queryOptions);
  };
  const handleDeleteFilters = async (id) => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;

    setLoading(true);
    try {
      const res = await api.delete(`api/report_filter_columns/${id}`);
      if (res.status === 200) {
        toast.success(
          res?.data?.error || res?.data?.message || "Filter saved successfully"
        );
        getSERPDomainReport((prevState) => ({
          ...prevState,
          userFiltersAndColumns: prevState.userFiltersAndColumns.filter(
            (filter) => filter._id !== id
          ),
        }));
      } else {
        toast.error(
          res?.data?.error || res?.data?.message || "Filter couldn't saved"
        );
      }
      setLoading(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.error ||
          err?.response?.data?.message ||
          "Filter couldn't saved"
      );
      console.log("🚀 ~ getLatestPosition ~ err:", err);
      setLoading(false);
    }
  };
  const CustomComponent = () => {
    return (
      <>
        <div className="mb-2 flex">
          <div className="w-full md:w-1/2 lg:w-64 flex items-center gap-x-2">
            <FiltersDropdown
              options={record?.userFiltersAndColumns}
              value={findMatchingObject()}
              areObjectsEqual={(data) => areObjectsEqual(data)}
              handleDeleteFilters={(id) => handleDeleteFilters(id)}
              onRemove={() => {
                setQueryOptions({
                  groupOp: "AND",
                  rules: [],
                });
                setPaginationModel({
                  pageSize: paginationModel.pageSize,
                  page: 1,
                });
                getSERPDomainReport({
                  ...sortingModel,
                  page: 1,
                  size: paginationModel.pageSize,
                });
                setSelectedData([]);
              }}
              onChange={(data) => {
                const filters = data?.groupOp
                  ? data
                  : data?.filters
                  ? data.filters
                  : { groupOp: "AND", rules: [] };
                setFilterModel({
                  items: filters?.rules?.map(({ field, op, data }) => ({
                    field: field,
                    operator: operator({ operator: op, field }),
                    value: data,
                  })),
                  logicOperator: "and",
                  quickFilterValues: [],
                  quickFilterLogicOperator: "and",
                });
                setQueryOptions(filters);
                setPaginationModel({
                  pageSize: paginationModel.pageSize,
                  page: 1,
                });
                getSERPDomainReport({
                  ...sortingModel,
                  filters: filters,
                  page: 1,
                  size: paginationModel.pageSize,
                });
              }}
            />
            {record?.userFiltersAndColumns &&
            queryOptions?.rules?.length &&
            !isAllObjectMatched() ? (
              <div className="flex flex-row space-x-1">
                <button
                  className="bg-primary-100 text-white w-[25px] h-[25px] rounded-full flex justify-center items-center z-10"
                  onClick={() => setIsFilterSave(true)}
                >
                  <FaCheck />
                </button>
              </div>
            ) : null}
          </div>
          <div className="w-full  flex items-center justify-end">
            {LineOne()}
          </div>
        </div>
      </>
    );
  };
  const LineOne = () => {
    const displayTimeDifference = (endTime) => {
      const now = dayjs();
      const end = dayjs.utc(endTime); // Make sure to use utc() if your time is in UTC

      const diffHours = now.diff(end, "hour");
      const diffMinutes = now.diff(end, "minute");

      if (diffHours < 24) {
        // If the difference is less than 24 hours, but more than an hour, show "X hours ago"
        if (diffHours >= 1) {
          return diffHours === 1 ? "1 hour ago" : `${diffHours} hours ago`;
        } else {
          // If the difference is less than an hour, show "X minutes ago"
          return diffMinutes === 1
            ? "1 minute ago"
            : `${diffMinutes} minutes ago`;
        }
      } else {
        // Otherwise, format the date
        return end.format("ddd, MMM D, YYYY h:mm A");
      }
    };
    return (
      <div className="flex items-center justify-between">
        <div className="text-sm text-gray-700 mt-auto mr-2">
          <p className="text-orange-600 font-semibold">
            {record?.syncStatus != null &&
            record?.syncStatus?.status === "InProgress"
              ? `running from ${displayTimeDifference(
                  record?.syncStatus?.start_time
                )}`
              : ""}
            {record?.syncStatus != null &&
            record?.syncStatus?.status === "Complete"
              ? `Last synced on ${dayjs(record.syncStatus.end_time).format(
                  "MMM, D YYYY h:mm A"
                )}`
              : ""}
          </p>
        </div>
        <div className="mt-auto flex justify-end">
          <Button
            text="Sync"
            variant="btn_submit"
            onClick={syncData}
            className="w-[100px]"
          />
        </div>
        <div className="mt-auto flex justify-end">
          <button
            className="bg-primary-100 text-white  h-[37px] w-[35px] flex justify-center items-center rounded-sm ml-1"
            title="Refresh Data"
            onClick={() => {
              setQueryOptions({
                groupOp: "AND",
                rules: [],
              });
              setPaginationModel({
                pageSize: paginationModel.pageSize,
                page: 1,
              });
              getSERPDomainReport({
                ...sortingModel,
                filters: { groupOp: "", rules: [] },
                page: 1,
                size: paginationModel.pageSize,
              });
              setSelectedData([]);
              setFilterModel({
                items: [],
                logicOperator: "and",
                quickFilterValues: [],
                quickFilterLogicOperator: "and",
              });
            }}
          >
            <HiOutlineRefresh />
          </button>
        </div>
      </div>
    );
  };
  function checkDeleteConditions(data) {
    if (!data || data.length === 0) {
      return false;
    }
    return data.every(
      (item) =>
        item.domain.length === 0 &&
        item.connex_customer.length === 0 &&
        !item.cc_data &&
        item.phone_status !== "Deleted"
    );
  }

  const deleteNumber = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to perform this action?"
    );
    if (!confirmed) return;
    setIsLoading(true);
    try {
      const payload = {
        phone_ids: selectedData.map((item) => item._id),
      };
      const res = await api.post("/api/domains/deregister_phone", payload);
      if (res.status === 200 || res.status === 201) {
        setFilterModel({
          items: [],
          logicOperator: "and",
          quickFilterValues: [],
          quickFilterLogicOperator: "and",
        });
        getSERPDomainReport({
          ...sortingModel,
          filters: queryOptions,
          ...paginationModel,
          size: paginationModel.pageSize,
        });
        toast.success(res?.data?.message || "Number deleted successfully.");
      } else {
        toast.error(res.data.message || "Failed to delete number.");
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.message ||
          "An error occurred while delete the number."
      );
    } finally {
      setIsLoading(false);
      setSelectedData([]);
    }
  };
  return (
    <>
      <PageHeader route="Setting > Industries" heading="" />
      {isFilterSave ? (
        <SaveFilterModal
          setIsFilterSave={setIsFilterSave}
          onClose={() => {
            setIsFilterSave(false);
          }}
          filters={queryOptions}
          setFilters={(data) =>
            setRecord((prevDomains) => ({
              ...prevDomains,
              userFiltersAndColumns: [
                ...(prevDomains?.userFiltersAndColumns || []),
                data,
              ],
            }))
          }
          filterData={record?.userFiltersAndColumns}
        />
      ) : null}
      {isLoading || loading ? <DotsLoader /> : null}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={batchList?.map((record, index) => {
            const counter = index + 1;
            const {
              _id,
              domain,
              phone_status,
              phone,
              connex_customer,
              domain_data,
              cc_data,
            } = record;
            return {
              records: { ...record },
              _id,
              counter,
              domain,
              phone,
              phone_status,
              connex_customer: connex_customer[0],
              domain_data: domain_data || [],
              cc_data: cc_data || {},
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          paginationModel={paginationModel}
          totalItems={record?.totalItems}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          showCount="No"
          topPagination
          //   density="standard"
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          columnVisibilityModel={{
            domain_count: false,
            "domain_data.0.phone": false,
          }}
          gridOptions={getRowHeight}
          editMode="cell"
          searchable="No"
          CustomComponent={CustomComponent}
          filterModel={filterModel}
        />
      </div>
    </>
  );
}

export default InteliquentNumbers;
