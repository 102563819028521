import React, { useState } from "react";
import api from "../../../../services/api";
import PageHeader from "../../../../components/molecules/PageHeader";
import { Button, DotsLoader } from "../../../../components";
import MUIDataTable from "../../../../components/molecules/DataTable/muigrid";
import { useDispatch, useSelector } from "react-redux";
import {
  get_industry,
  industryReset,
} from "../../../../features/IndustrySlice";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { FaDownload, FaSearch } from "react-icons/fa";
import {
  InputLabel,
  Button as MUIButton,
  MenuItem,
  Select,
  TablePagination,
  Tooltip,
} from "@mui/material";
import CreateBatchModal from "./CreateBatchModal";
import dayjs from "dayjs";
import CreateKeywordsModal from "./CreateKeywords";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SummaryDetailModal from "./SummaryModal";
import AddKeywordModal from "./AddKeywordModal";

function DomainKeywords() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [record, setRecord] = useState([]);
  const [isKeywordModal, setKeywordModal] = useState(false);
  const dispatch = useDispatch();
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const industry = useSelector((state) => state.industry);
  const [isCreateBatch, setIsCreateBatch] = React.useState(false);
  const [showSummary, setShowSummary] = React.useState(false);
  const [isKeywords, setIsKeywords] = React.useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const getSERPReport = async (filter) => {
    setIsLoading(true);
    if (!filter?.filters?.rules?.length) {
      delete filter.filters;
    }
    delete filter?.pageSize;
    try {
      const res = await api.post("/api/domains/domain_keyword", filter);
      if (res.status === 200) {
        setRecord(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  React.useEffect(() => {
    getSERPReport({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
      filters: queryOptions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const sortedIndustries = [...(industry?.record?.records || [])].sort((a, b) =>
    a?.name?.localeCompare(b?.name)
  );
  const CustomSelectInput = ({ item, applyValue }) => {
    const [value, setValue] = useState(item.value);
    return (
      <div fullWidth>
        <InputLabel>Value</InputLabel>
        <Select
          value={value || ""}
          onChange={(event) => {
            setValue(event.target.value);
            applyValue({ ...item, value: event.target.value });
          }}
          fullWidth
        >
          {sortedIndustries?.map((option) => (
            <MenuItem key={option._id} value={option._id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  };
  const getLatestPosition = async (keyword_id) => {
    setLoading(true);
    try {
      const res = await api.post(`/api/domains/current_keyword_position`, {
        keyword_id,
      });
      if (res.status === 200) {
        const data = res.data[0];
        const updatedItems = record?.data?.map((item) =>
          item._id === data._id ? data : item
        );
        toast.success("Latest position updated");
        setRecord({ ...record, data: updatedItems });
      } else {
        toast.error("Couldn't update the latest position");
      }
      setLoading(false);
    } catch (err) {
      toast.error("Couldn't update the latest position");
      console.log("🚀 ~ getLatestPosition ~ err:", err);
      setLoading(false);
    }
  };
  const batchList = record?.data?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const onSingleSelect = ({ checked, data }) => {
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            (domain) => domain?.id !== data?.id
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:116 ~ onSingleSelect ~ err:", err);
    }
  };
  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = batchList?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (data) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter(({ domain }) => domain === data?.domain).length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={
            batchList?.length && selectedData?.length === batchList?.length
          }
          disabled={!batchList?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={isSelected(params.row.records)}
          onChange={(e) =>
            onSingleSelect({
              checked: e.target.checked,
              data: params.row.records,
            })
          }
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
      disableColumnMenu: true,
    },
    { headerName: "#", field: "counter", width: 80, filterable: false },
    {
      field: "domain",
      headerName: "Domain",
      renderCell: (params) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.row["domain"]}`}
            className="text-[#000] hover:text-blue-600 hover:underline no-underline"
          >
            {params?.row["domain"]}
          </a>
        );
      },
      flex: 1,
      minWidth: 200,
      disableColumnMenu: true,
      // filterable: false,
    },
    {
      headerName: "Population",
      field: "population",
      flex: 1,
      disableColumnMenu: true,
      type: "number",
    },
    {
      headerName: "Address",
      field: "domain_address",
      flex: 1,
      disableColumnMenu: true,
      // filterable: false,
    },
    {
      headerName: "Batch ID",
      field: "valueserp_BatchID",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <span
          onClick={() =>
            navigate(`/domain_rank/searches/${params?.row?.valueserp_BatchID}`)
          }
          className="text-blue-600 hover:underline cursor-pointer"
        >
          {params?.row?.valueserp_BatchID}
        </span>
      ),
      // filterable: false,
    },
    {
      headerName: "Industry",
      field: "industry_id",
      flex: 1,
      type: "singleSelect",
      minWidth: 150,
      valueOptions: sortedIndustries,
      disableColumnMenu: true,
      renderCell: (params) => params.row?.industry_id,
      filterOperators: [
        {
          label: "is",
          value: "is",
          InputComponent: CustomSelectInput,
          InputComponentProps: { type: "singleSelect" },
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
      ],
    },
    {
      headerName: "Latest Position",
      field: "latest_position",
      flex: 1,
      align: "center",
      disableColumnMenu: true,
      type: "number",
    },
    {
      headerName: "Position History",
      field: "positions",
      flex: 1,
      filterable: false,
      align: "center",
      disableColumnMenu: true,
      valueGetter: (params) => params?.row?.positions?.length, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2, // Provide a sorting function
      renderCell: (params) =>
        params?.row?.positions?.length > 0 ? (
          <div className="flex justify-center items-center w-full">
            <Tooltip
              title={
                <div className="flex flex-col text-sm w-[full]">
                  {params?.row?.positions?.map((item, index) => (
                    <span key={index}>
                      {`${index + 1}. Date: ${dayjs(item?.on_date).format(
                        "ddd, MMM D, YYYY h:mm:ss A"
                      )}, Position: ${item?.position}`}
                    </span>
                  ))}
                </div>
              }
            >
              <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
                {params?.row?.positions?.length}
              </span>
            </Tooltip>
          </div>
        ) : null,
    },
    {
      headerName: "KD",
      field: "latest_ahref_keyword_difficulty",
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
      disableColumnMenu: true,
      type: "number",
      valueGetter: (params) => params?.row?.ahref_keyword_difficulty?.length, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2, // Provide a sorting function
      renderCell: (params) =>
        params?.row?.ahref_keyword_difficulty?.length > 0 ? (
          <div className="flex justify-between items-center w-full">
            <span>
              {params?.row?.latest_ahref_keyword_difficulty &&
              params?.row?.latest_ahref_keyword_difficulty !== -1
                ? params?.row?.latest_ahref_keyword_difficulty
                : "N/A"}
            </span>
            <Tooltip
              title={
                <div className="flex flex-col text-sm w-[full]">
                  {params?.row?.ahref_keyword_difficulty?.map((item, index) => (
                    <span key={index}>
                      {`${index + 1}. Date: ${dayjs(item?.log_date).format(
                        "ddd, MMM D, YYYY h:mm:ss A"
                      )}, Difficulty: ${item?.difficulty || "N/A"}`}
                    </span>
                  ))}
                </div>
              }
            >
              <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
                {params?.row?.ahref_keyword_difficulty?.length}
              </span>
            </Tooltip>
          </div>
        ) : null,
    },
    {
      headerName: "KV",
      field: "latest_ahref_keyword_volume",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      type: "number",
      disableColumnMenu: true,
      valueGetter: (params) => params?.row?.ahref_keyword_volume?.length, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2, // Provide a sorting function
      renderCell: (params) =>
        params?.row?.ahref_keyword_volume?.length > 0 ? (
          <div className="flex justify-between items-center w-full">
            <span>
              {params?.row?.latest_ahref_keyword_volume &&
              params?.row?.latest_ahref_keyword_volume !== -1
                ? params?.row?.latest_ahref_keyword_volume
                : "N/A"}
            </span>
            <Tooltip
              title={
                <div className="flex flex-col text-sm w-[full]">
                  {params?.row?.ahref_keyword_volume?.map((item, index) => (
                    <span key={index}>
                      {`${index + 1}. Date: ${dayjs(item?.log_date).format(
                        "ddd, MMM D, YYYY h:mm:ss A"
                      )},  Volume: ${item?.volume}`}
                    </span>
                  ))}
                </div>
              }
            >
              <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
                {params?.row?.ahref_keyword_volume?.length}
              </span>
            </Tooltip>
          </div>
        ) : null,
    },
    {
      headerName: "Primary",
      field: "is_primary",
      flex: 1,
      type: "singleSelect",
      disableColumnMenu: true,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        is_primary: value?.value,
      }),
      valueOptions: [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
      ],
      filterOperators: [
        {
          label: "is",
          value: "is",
          getApplyFilterFn: (filterItem) => {
            // Assuming is_primary is stored as 1 for "Yes" and 0 for "No"
            return (params) => {
              return params.value === filterItem.value;
            };
          },
          InputComponent: ({ item, applyValue }) => {
            // Custom select input for the filter
            const handleChange = (event) =>
              applyValue({ ...item, value: event.target.value });
            return (
              <div fullWidth size="small">
                <InputLabel id={`select-label-${item.columnField}`}>
                  Primary
                </InputLabel>
                <Select
                  labelId={`select-label-${item.columnField}`}
                  value={item.value ?? ""}
                  onChange={handleChange}
                  label="Primary"
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
              </div>
            );
          },
        },
      ],
    },
    {
      headerName: "Keywords",
      field: "keyword",
      flex: 1,
      disableColumnMenu: true,
      // filterable: false
    },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <span
          className="text-blue-600 hover:underline cursor-pointer"
          onClick={() => getLatestPosition(params.row.records?._id)}
        >
          Get Latest Position
        </span>
      ),
    },
  ];
  React.useEffect(() => {
    dispatch(get_industry({ data: { page: 1, size: 1000 } }));
    return () => {
      dispatch(industryReset());
    };
    // eslint-disable-next-line
  }, []);
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getSERPReport({
      ...sortingModel,
      filters: queryOptions,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    getSERPReport({
      ...sortingModel,
      filters: queryOptions,
      page: +page + 1,
      size: paginationModel.pageSize,
    });
  };
  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
    getSERPReport({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: newPageSize,
    });
  };
  const LineOne = () => {
    return (
      <TablePagination
        color="primary"
        shape="rounded"
        size="medium"
        showFirstButton
        showLastButton
        count={
          record?.totalItems ? record?.totalItems : record?.data?.length || 0
        }
        page={paginationModel.page - 1 || 0}
        onPageChange={(e, page) => handleTopPageChange(page)}
        rowsPerPage={paginationModel.pageSize || 0}
        onRowsPerPageChange={(e) => handleTopPageSizeChange(e.target.value)}
        component="div"
        rowsPerPageOptions={[
          25,
          50,
          75,
          100,
          250,
          500,
          1000,
          1500,
          record?.totalItems ? record?.totalItems : record?.data?.length || 0,
        ]}
      />
    );
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      getSERPReport({
        ...sortingModel,
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // ?.toLowerCase()?.includes(searchInput?.toLowerCase())
    // const result = ruless?.reduce(
    //   (acc, { field, data }) => ({
    //     ...acc,
    //     [field]: data ? data : data === 0 ? data : null, // Use value if exists; otherwise, use null
    //   }),
    //   {}
    // );
    // setQueryOptions(result);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getSERPReport({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };
  const fetchKeywords = async () => {
    if (!queryOptions?.rules?.length)
      return toast.error("Please select the filters");
    const c = window.confirm(
      "Are you sure want to fetch the Keyword difficulties & volumes?"
    );
    if (!c) return;
    setLoading(true);
    try {
      const res = await api.post(
        "/api/domains/fetch_keyword_difficulty_and_vol",
        { filters: queryOptions }
      );
      if (res.status === 200) {
        toast.success("Keywords and Volume fetched successfully");
        await getSERPReport({
          filters: queryOptions,
          page: paginationModel?.page,
          size: paginationModel.pageSize,
        });
      } else {
        toast.error("Keywords and Volume couldn't be fetched");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Keywords and Volume couldn't be fetched");
      console.log("🚀 ~ fetchKeywords ~ err:", err);
    }
  };
  const fetchKeywordsForSeelctedDomains = async () => {
    const c = window.confirm(
      "Are you sure want to fetch the Keyword difficulties & volumes?"
    );
    if (!c) return;
    const keywords = selectedData?.map(({ keyword }) => keyword);
    setIsLoading(true);
    try {
      const res = await api.post(
        "/api/domains/fetch_keyword_difficulty_and_vol",
        {
          filters: {
            groupOp: "AND",
            rules: [
              {
                field: "keyword",
                op: "isAnyOf",
                data: keywords,
              },
            ],
          },
        }
      );
      if (res.status === 200) {
        toast.success("Keywords and Volume fetched successfully");
        await getSERPReport({
          filters: queryOptions,
          page: paginationModel?.page,
          size: paginationModel.pageSize,
        });
        setSelectedData([]);
      } else {
        toast.error("Keywords and Volume couldn't be fetched");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Keywords and Volume couldn't be fetched");
      console.log("🚀 ~ fetchKeywords ~ err:", err);
    }
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={fetchKeywords}
          startIcon={<FaDownload size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Fetch Keywords & Vol by filter
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={fetchKeywordsForSeelctedDomains}
          startIcon={<FaDownload size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Fetch KD & KV for selected Domains
        </MUIButton>
      </GridToolbarContainer>
    );
  }
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    getSERPReport({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      sort_field: params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
  };
  const showSummaryButton = () => {
    return (
      <>
        <Button
          text="Add Keyword"
          className="mr-1 py-1.5 !px-3 align-middle bg-primary-100 border-primary-100 text-white float-left flex items-center rounded-0"
          onClick={() => setKeywordModal(true)}
        />

        <Button
          text="Summary"
          variant="btn_cancel"
          className="mr-1"
          onClick={() => setShowSummary(true)}
        />
      </>
    );
  };
  const cancelFormHandler = () => {
    setKeywordModal(false);
  };
  return (
    <>
      <PageHeader
        route="Setting > Industries"
        heading="Keywords"
        isAllowed={true}
        onClick={() => setIsKeywords(true)}
        buttonTitle={"Generate Keywords"}
        // onClick={() => setIsCreateBatch(true)}
        extraButtonClick={() => setIsCreateBatch(true)}
        additionalBtnTitle="Create Batch"
        extraButton={true}
        additionalBtnAllow={true}
        addIcon="No"
        editIcon="No"
        CustomButtons={showSummaryButton}
      />
      {isLoading || loading ? <DotsLoader /> : null}
      {isCreateBatch ? (
        <CreateBatchModal onClose={() => setIsCreateBatch(false)} />
      ) : null}
      {isKeywords ? (
        <CreateKeywordsModal
          onClose={() => setIsKeywords(false)}
          getSERPReport={getSERPReport}
        />
      ) : null}
      {showSummary ? (
        <SummaryDetailModal
          onCancelForm={() => setShowSummary(false)}
          data={record.summary}
        />
      ) : null}
      {isKeywordModal && (
        <AddKeywordModal
          modalTitle="Add Keyword"
          onCancelForm={cancelFormHandler}
          onSubmitForm={() => {
            getSERPReport({
              ...paginationModel,
              ...sortingModel,
              size: paginationModel.pageSize,
              filters: queryOptions,
            });
          }}
        />
      )}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={batchList?.map((record, index) => {
            const counter = index + 1;
            const {
              domain,
              industry_id,
              is_primary,
              keyword,
              domain_address,
              valueserp_BatchID,
              positions,
              population,
              is_indexed,
              ahref_keyword_volume,
              ahref_keyword_difficulty,
              latest_ahref_keyword_volume,
              latest_ahref_keyword_difficulty,
            } = record;
            // const sortedPositions = positions.sort(
            //   (a, b) => new Date(b.on_date) - new Date(a.on_date)
            // );
            const cleanedPositions =
              positions?.filter((pos) => pos !== null) || [];
            const sortedPositions =
              cleanedPositions.length > 0
                ? cleanedPositions.sort(
                    (a, b) => new Date(b.on_date) - new Date(a.on_date)
                  )
                : [];

            const cleanedValoume =
              ahref_keyword_volume?.filter((pos) => pos !== null) || [];
            const sortedVolume =
              cleanedValoume.length > 0
                ? cleanedValoume.sort(
                    (a, b) => new Date(b.log_date) - new Date(a.log_date)
                  )
                : [];

            const cleanedDifficulty =
              ahref_keyword_difficulty?.filter((pos) => pos !== null) || [];
            const sortedDifficulty =
              cleanedDifficulty.length > 0
                ? cleanedDifficulty.sort(
                    (a, b) => new Date(b.log_date) - new Date(a.log_date)
                  )
                : [];
            return {
              records: { ...record },
              counter,
              domain,
              is_indexed,
              population: (+population || 0)?.toLocaleString(),
              domain_address,
              valueserp_BatchID,
              industry_id: sortedIndustries?.find(
                ({ _id }) => _id === industry_id
              )?.name,
              keyword,
              is_primary: is_primary ? 1 : 0,
              positions: sortedPositions,
              ahref_keyword_volume: sortedVolume,
              ahref_keyword_difficulty: sortedDifficulty,
              latest_position: sortedPositions?.length
                ? `${sortedPositions[0]?.position} - ${dayjs(
                    sortedPositions[0]?.on_date
                  )?.format("YYYY-MM-DD")}`
                : null,
              latest_ahref_keyword_volume,
              latest_ahref_keyword_difficulty,
            };
          })}
          // customSize={2000}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          LineOne={LineOne}
          paginationModel={paginationModel}
          totalItems={record?.totalItems}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          topPagination
          hideTopPageSelector="Yes"
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
        />
      </div>
    </>
  );
}

export default DomainKeywords;
