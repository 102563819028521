import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get_login_logs } from "../../features/reportsSlice";
import ReportHead from "../Components/Header";
import Picker from "../Components/Picker";
import Select from "react-select";
import { get_users } from "../../features/usersSlice";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";

const AgentlessReport = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const { isLoading, record } = useSelector((state) => state.reports);
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(get_login_logs());
    // eslint-disable-next-line
  }, []);
  const currentDate = new Date();
  const initialFilters = {
    from_date:
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        0,
        0,
        0,
        0
      )
        .toISOString()
        .slice(0, 23) + "Z",
    to_date: new Date().toISOString().slice(0, 23) + "Z",
      }
  const [filters, setFilters] = React.useState(initialFilters);
  const columnDefs = [
    { headerName: "#", field: "counter", },
    { headerName: "Lead ID", field: "lead_id",flex: 1 },
    
    { headerName: "Type", field: "type", flex:1 },
    { headerName: "Job Type", field: "jobType",flex:1 },
    { headerName: "Created By", field: "createdBy", flex: 1 },
    { headerName: "Created At", field: "createdAt", flex: 1 },
  ];

  useEffect(() => {
    dispatch(get_users());
    // eslint-disable-next-line
  }, []);
  const multiSelectHandler = (e) => {
    let arr = [];
    // eslint-disable-next-line
    e?.map((val) => {
      arr = [...arr, val?.value];
    });
  };
  const style = {
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#ffe7c7",
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused || state.hover ? "#a9a9a9" : null,
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };

  return (
    <>
      <div className="container-fluid">
        <ReportHead route="Reports > Agentless Report" heading="Login IP" />

        <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
          <div className="md:col-span-8">
            <Select
              styles={style}
              placeholder="Lead Type"
              isMulti
              options={users}
              getOptionLabel={(options) => options?.first_name}
              getOptionValue={(options) => options?._id}
              onChange={(e) => {
                multiSelectHandler(e);
              }}
              className="w-full"
            />
          </div>

          <div className="md:col-span-4 md:!px-0.5 !mb-3">
          <Picker filterData={filters} setFiltersData={setFilters} />
          </div>
        </div>
      
      <div className="flex-cols md:flex flex-wrap justify-between mx-0.5">
        <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[24%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Total Link Sent</span>
          <span className="text-base font-medium font-pop">0</span>
        </div>
        <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[24%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Sent Never Opened</span>
          <span className="text-base font-medium font-pop">0</span>
        </div>
        <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[24%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Sent Opened</span>
          <span className="text-base font-medium font-pop">0</span>
        </div>
        <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[24%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Sent Completed/Jobs Created</span>
          <span className="text-base font-medium font-pop">0</span>
        </div>
      </div>
      <div className="bg-white my-3 border rounded">
          <MUIDataTable
            // exportOption={exportOption}
            // handlePrint={handlePrint}
            row={[]}
            columnDefs={columnDefs}
            onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.totalItems?record?.totalItems:0}
          // searchText={searchText}
          // setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          />
      </div>
      <div className="d-none"></div>
      </div>
    </>
  );
};

export default AgentlessReport;
