import styles from "./styles.module.css";

const Avatar = ({
  name,
  size = 35,
  fontSize = 14,
  type = "default",
  dot = false,
  count = 0,
}) => {
  let text = name
    ? name
        .split(" ")
        .slice(0, 2)
        .map((string) => (string ? string[0].toUpperCase() : ""))
    : "";

  const dotSize = Math.ceil(size / Math.sqrt(2) - size / 2);

  return (
    <div
      className={styles.avatar}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        fontSize: `${fontSize}px`,
        borderRadius: `${Math.floor(size / 2)}px`,
        borderColor:
          type === "default" ? "#eeecec" : type === "room" ? "#08517a" : "#0b0",
          backgroundColor:
          type === "default" ? "#eeecec" : type === "room" ? "#08517a" : "#0b0",
        color:
          type === "default" ? "#646464" : type === "room" ? "#08517a" : "#0b0",
      }}
    >
      <div>{text}</div>
      {dot && (
        <div
          className={styles.greenDot}
          style={{ width: dotSize, height: dotSize, borderRadius: dotSize / 2 }}
        ></div>
      )}
      {!!count && <div className={styles.count}>{count}</div>}
    </div>
  );
};

export default Avatar;
