import React, { useEffect, useState } from "react";
import FormSelectInput from "../FormSelectInput";
import FormInput from "../FormInput";

function VehicleInfoSection({ formik, fieldsToShow = [], serviceName = "" }) {
  const currentYear = new Date().getFullYear();
  const [yearsOption, setYearsOption] = useState([]);

  useEffect(() => {
    let years = [];
    for (let i = 1970; i <= currentYear; i++) {
      years.push({ label: i, value: i });
    }
    setYearsOption(years);
  }, [currentYear]);
  return (
    <div className="bg-white border  px-2 md:px-4 mt-3 ">
      <div className="pl-2 pt-3 !mb-2">
        <div className="font-semibold text-lg">{`${serviceName}:`}</div>
      </div>
      <div className="font-semibold text-sm text-gray-500 px-2 mb-4">
        What is the Year/Make/Model of the vehicle?
      </div>
      <div
        className={`grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4 mt-2 ${
          fieldsToShow?.length === 5
            ? `lg:grid-cols-${fieldsToShow?.length}`
            : "lg:grid-cols-4"
        }`}
      >
        {fieldsToShow.includes("car_year") && (
          <FormSelectInput
            name="extra_fields.car_year"
            options={yearsOption}
            valueProp="value"
            labelProp="label"
            label="Year"
            isCustomOption="Select an year"
            formik={formik}
            value={formik.values?.extra_fields?.car_year}
          />
        )}

        {fieldsToShow.includes("car_make") && (
          <FormInput
            name="extra_fields.car_make"
            type="text"
            label="Make"
            formik={formik}
            value={formik.values?.extra_fields?.car_make}
          />
        )}
        {fieldsToShow.includes("car_model") && (
          <FormInput
            name="extra_fields.car_model"
            type="text"
            label="Model"
            formik={formik}
            value={formik.values?.extra_fields?.car_model}
          />
        )}
        {fieldsToShow.includes("is_neutral") && (
          <FormSelectInput
            name="extra_fields.is_neutral"
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
            convertor={(value) => (value ? value === "true" : "")}
            formik={formik}
            valueProp="value"
            labelProp="label"
            label="Neutral"
            isCustomOption
            value={formik.values?.extra_fields?.is_neutral}
          />
        )}

        {fieldsToShow.includes("car_color") && (
          <FormInput
            name="extra_fields.car_color"
            type="text"
            label="What's the color of the vehicle?"
            placeholder={"Enter the color"}
            formik={formik}
            value={formik.values?.extra_fields?.car_color}
          />
        )}

        {fieldsToShow.includes("drivetrain") && (
          <FormSelectInput
            name="extra_fields.drivetrain"
            options={[
              { value: "front_wheel", label: "Front Wheel Drive" },
              { value: "rear_wheel", label: "Rear Wheel Drive" },
              { value: "four_wheel", label: "Four Wheel Drive" },
              { value: "unknown", label: "Unknown" },
            ]}
            valueProp="value"
            labelProp="label"
            label="Drivetrain"
            formik={formik}
            value={formik.values?.extra_fields?.drivetrain}
            isCustomOption
          />
        )}

        {fieldsToShow.includes("duty_type") && (
          <FormSelectInput
            name="extra_fields.duty_type"
            options={[
              { label: "Light Duty", value: "light_duty" },
              { label: "Medium Duty", value: "medium_duty" },
              { label: "Heavy Duty", value: "heavy_duty" },
            ]}
            valueProp="value"
            labelProp="label"
            label="Type Of Duty"
            formik={formik}
            value={formik.values?.extra_fields?.duty_type}
            isCustomOption
          />
        )}

        {fieldsToShow.includes("is_tires_ok") && (
          <FormSelectInput
            name="extra_fields.is_tires_ok"
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
            convertor={(value) => (value ? value === "true" : "")}
            valueProp="value"
            labelProp="label"
            label="Are the tires okay?"
            formik={formik}
            value={formik.values?.extra_fields?.is_tires_ok}
            isCustomOption
          />
        )}

        {fieldsToShow.includes("car_type") && (
          <FormSelectInput
            name="extra_fields.car_type"
            options={[
              { label: "Diesel", value: "Diesel" },
              { label: "Gas", value: "Gas" },
              { label: "Electric", value: "Electric" },
              { label: "Hybrid", value: "Hybrid" },
            ]}
            valueProp="value"
            labelProp="label"
            label="Is the vehicle diesel, gas, electric or hybrid?"
            formik={formik}
            value={formik.values?.extra_fields?.car_type}
            isCustomOption
          />
        )}

        {fieldsToShow.includes("is_running") && (
          <FormSelectInput
            name="extra_fields.is_running"
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
            convertor={(value) => (value ? value === "true" : "")}
            valueProp="value"
            labelProp="label"
            label="Is the vehicle still operable/running?"
            formik={formik}
            value={formik.values?.extra_fields?.is_running}
            isCustomOption
          />
        )}
        {fieldsToShow.includes("tow_type") && (
          <FormSelectInput
            name="extra_fields.tow_type"
            options={[
              { label: "Regular tow", value: "regular" },
              { label: "Secondary tow", value: "secondary" },
            ]}
            valueProp="value"
            labelProp="label"
            label="Is it a regular tow or a secondary tow?"
            formik={formik}
            value={formik.values?.extra_fields?.tow_type}
            isCustomOption
          />
        )}
        {fieldsToShow.includes("has_spare_tire") && (
          <FormSelectInput
            name="extra_fields.has_spare_tire"
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
            convertor={(value) => (value ? value === "true" : "")}
            valueProp="value"
            labelProp="label"
            label="Does the client has a good spare tire?"
            formik={formik}
            value={formik.values?.extra_fields?.has_spare_tire}
            isCustomOption
          />
        )}
        {fieldsToShow.includes("which_tire_to_be_change") && (
          <FormInput
            name="extra_fields.which_tire_to_be_change"
            type="text"
            label="Which Tire needs to be change?"
            formik={formik}
            value={formik.values?.extra_fields?.which_tire_to_be_change}
            placeholder=""
          />
        )}
        {fieldsToShow.includes("tire_size") && (
          <FormInput
            name="extra_fields.tire_size"
            type="text"
            label="What is the tire size?"
            formik={formik}
            value={formik.values?.extra_fields?.tire_size}
            placeholder={"Tire Size"}
          />
        )}
        {fieldsToShow.includes("used_or_new_tire") && (
          <FormSelectInput
            name="extra_fields.used_or_new_tire"
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
            convertor={(value) => (value ? value === "true" : "")}
            valueProp="value"
            labelProp="label"
            label="Does the client used or new tire?"
            formik={formik}
            value={formik.values?.extra_fields?.used_or_new_tire}
            isCustomOption
          />
        )}
        {fieldsToShow.includes("needs_to_be_jump") && (
          <FormInput
            name="extra_fields.needs_to_be_jump"
            type="text"
            label="How many better needs to be jump?"
            formik={formik}
            value={formik.values?.extra_fields?.needs_to_be_jump}
          />
        )}
        {fieldsToShow.includes("tire_count_needs_to_inflate") && (
          <FormInput
            name="extra_fields.tire_count_needs_to_inflate"
            type="text"
            label="How many tires need to inflate?"
            formik={formik}
            value={formik.values?.extra_fields?.tire_count_needs_to_inflate}
          />
        )}
        {fieldsToShow.includes("which_tire_needs_to_inflate") && (
          <FormInput
            name="extra_fields.which_tire_needs_to_inflate"
            type="text"
            label="Which tire needs to be inflate?"
            formik={formik}
            value={formik.values?.extra_fields?.which_tire_needs_to_inflate}
          />
        )}
        {fieldsToShow.includes("days_to_stored") && (
          <FormInput
            name="extra_fields.days_to_stored"
            type="text"
            label="How many days needs to be stored?"
            formik={formik}
            value={formik.values?.extra_fields?.days_to_stored}
          />
        )}
        {fieldsToShow.includes("fuel_type") && (
          <FormInput
            name="extra_fields.fuel_type"
            type="text"
            label="What type of fuel do they need?"
            formik={formik}
            value={formik.values?.extra_fields?.fuel_type}
          />
        )}
        {fieldsToShow.includes("gallons") && (
          <FormInput
            name="extra_fields.gallons"
            type="text"
            label="How many gallons?"
            formik={formik}
            value={formik.values?.extra_fields?.gallons}
          />
        )}
      </div>
    </div>
  );
}

export default VehicleInfoSection;
