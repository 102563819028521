import React from "react";
import { FaChevronDown } from "react-icons/fa";
import { Button } from "../../../components";
import SelectForm from "./SelectForm";
import InputForm from "./InputForms";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { update_general_setting } from "../../../features/generalSettingSlice";
import errorMessage from "../../../util/errorMessage";
function TwilioConfiguration({ settings }) {
  let initialValues = {
    setting_type: "twilioConfiguration",
    sid: "",
    status: "",
    auth_token: "",
    from_number: "",
  };

  React.useEffect(() => {
    setTwilioConfiguration(settings[0]?.twilioConfiguration);
  }, [settings]);

  const dispatch = useDispatch();

  const [twilioConfiguration, setTwilioConfiguration] =
    React.useState(initialValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setTwilioConfiguration({
      ...twilioConfiguration,
      [name]: value,
    });
  };
  const handleSubmit = async () => {
    try {
      const res = await dispatch(
        update_general_setting(
          twilioConfiguration?.setting_type === "twilioConfiguration"
            ? twilioConfiguration
            : { ...twilioConfiguration, setting_type: "twilioConfiguration" }
        )
      );
      if (res?.payload?.status === 200) {
        toast.success("Additional Info successfully updated");
      } else {
        errorMessage({payload:res.payload, action:"Twilio Configuration", msg:'updated'})
      }
    } catch (error) {
      console.error(
        "Error ~ file: AddUpdateForm.jsx ~ line 59 ~ onSubmit: ~ error",
        error
      );
      errorMessage({payload:error, action:"Twilio Configuration", msg:'updated'})
    }
  };

  return (
    <details className="border mt-3 mb-3 ">
      <summary className="flex flex-row items-center text-normal px-2 font-semibold font-pop justify-between border-b hover:cursor-pointer  bg-gray-100 hover:bg-gray-300 py-2 px-1">
        <span>Twilio Configuration</span>
        <FaChevronDown iconClass="fas fa-times" />
      </summary>
      <div className="flex flex-col p-2">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <div className="md:grid grid-cols-12 gap-x-2 mt-2">
                <div className="col-span-6  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="sid"
                      placeholder="Twilio Sid"
                      onChange={(e) => handleInputChange(e)}
                      value={twilioConfiguration?.sid}
                      type="text"
                    />
                  </div>
                </div>

                <div className="col-span-6  md:mt-md-2">
                  <div className="mb-3">
                    <SelectForm
                      name="status"
                      options={[
                        { label: "Active", value: "active" },
                        { label: "InActive", value: "inactive" },
                      ]}
                      label="Status"
                      labelProp="label"
                      valueProp="value"
                      value={twilioConfiguration?.status}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                </div>
                <div className="col-span-6  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="auth_token"
                      placeholder="Twilio Auth Token"
                      onChange={(e) => handleInputChange(e)}
                      value={twilioConfiguration?.auth_token}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-span-6  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="from_number"
                      placeholder="Twilio Form"
                      onChange={(e) => handleInputChange(e)}
                      value={twilioConfiguration?.from_number}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-span-6">
                  <Button
                    text="Validate & Save Credentials"
                    variant="btn_submit"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </details>
  );
}

export default TwilioConfiguration;
