import React from "react";
import dayjs from "dayjs";
import en from "dayjs/locale/en";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { HiChevronDown } from "react-icons/hi";
import { useRef } from "react";
import DateRangeComp from "../../../components/molecules/DateRangePicker";
import { RxCross2 } from "react-icons/rx";
import { FaSearch } from "react-icons/fa";
import { Button, Loader } from "../../../components";
function Picker({
  filterData,
  setFiltersData,
  dropdown_label,
  dropdown_menu,
  valueKey,
  fromKey,
  toKey,
  isDropdown,
  rangeValue,
  searchText,
  setSearchText,
  searchable,
  isLoader,
  onChange,
  seachButtonOnClick,
  seachButton,
}) {
  dayjs.locale({
    ...en,
    weekStart: 1,
  });
  const from_date = fromKey ? fromKey : "from_date";
  const to_date = toKey ? toKey : "to_date";
  var endDate = new Date();
  var startDate = new Date();
  startDate.setDate(endDate.getDate() - 7);
  dayjs.extend(advancedFormat);
  const [showPicker, setShowPicker] = React.useState(false);
  const [showfilter, setShowfilter] = React.useState(false);
  const [isCustom, setIsCustom] = React.useState(false);

  const [rangeBy, setRangeBy] = React.useState(
    rangeValue ? rangeValue : "Today"
  );
  const filterRef = useRef();
  const pickerRef = useRef();
  const filters = dropdown_menu
    ? dropdown_menu
    : [
        {
          label: "Job Created",
          value: "job_created",
        },
        {
          label: "Job date",
          value: "job_date",
        },
        {
          label: "Job end date",
          value: "job_end_date",
        },
      ];

  const picker = [
    {
      label: "Custom",
      value: "custom",
    },
    {
      label: "Today",
      value: "today",
    },
    {
      label: "Yesterday",
      value: "yesterday",
    },
    {
      label: "This Week (Sun - Sat)",
      value: "this_week",
    },
    {
      label: "Last Week",
      value: "last_week",
    },
    {
      label: "Last 7 days",
      value: "last_7_days",
    },
    {
      label: "Last 15 days",
      value: "last_15_days",
    },
    {
      label: "This Month",
      value: "this_month",
    },
    {
      label: "Last Month",
      value: "last_month",
    },
    {
      label: "Last 3 Months",
      value: "last_3_months",
    },
    {
      label: "6 Months",
      value: "6_months",
    },
    {
      label: "Last 6 Months",
      value: "last_6_months",
    },
    {
      label: "Last 12 Months",
      value: "last_12_months",
    },
    {
      label: "This Year",
      value: "this_year",
    },
    {
      label: "Last Year",
      value: "last_year",
    },
  ];
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setShowPicker(false);
      setShowfilter(false);
    }
  };
  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setShowfilter(false);
    }
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);

  const handleDateRange = (rangeBy) => {
    setRangeBy(rangeBy.label);
    setShowPicker(false);
    if (rangeBy.value === "custom") {
      setIsCustom(true);
    } else {
      setIsCustom(false);
      setFiltersData({
        ...filterData,
        [to_date]: getDate(rangeBy.value).endDate,
        [from_date]: getDate(rangeBy.value).startDate,
      });
    }
    if (onChange) {
      onChange({
        [to_date]: getDate(rangeBy.value).endDate,
        [from_date]: getDate(rangeBy.value).startDate,
      });
    }
  };
  const getDate = (range) => {
    let startDate = new Date();
    let endDate = new Date();

    switch (range) {
      case "today":
        startDate.setHours(0, 0, 0, 0);
        break;
      case "yesterday":
        startDate = dayjs().subtract(1, "day").startOf("day").toDate();
        endDate = dayjs().subtract(1, "day").endOf("day").toDate();
        break;
      case "this_week":
        startDate = dayjs().startOf("week", { weekStart: 1 }).toDate();
        break;
      case "last_week":
        startDate = dayjs().subtract(1, "week").startOf("week").toDate();
        endDate = dayjs().subtract(1, "week").endOf("week").toDate();
        break;
      case "last_7_days":
        startDate = dayjs().subtract(7, "day").startOf("day").toDate();
        endDate = dayjs().subtract(1, "day").endOf("day").toDate();
        break;
      case "last_15_days":
        startDate = dayjs().subtract(15, "day").startOf("day").toDate();
        endDate = dayjs().subtract(1, "day").endOf("day").toDate();
        break;
      case "this_month":
        startDate = dayjs().startOf("month").toDate();
        break;
      case "last_month":
        startDate = dayjs().subtract(1, "month").startOf("month").toDate();
        endDate = dayjs().subtract(1, "month").endOf("month").toDate();
        break;
      case "last_3_months":
        startDate = dayjs().subtract(3, "month").startOf("month").toDate();
        endDate = dayjs().subtract(1, "month").endOf("month").toDate();
        break;
      case "6_months":
        startDate = dayjs()
          .subtract(6, "month")
          .set("date", dayjs().date())
          .toDate();
        endDate = new Date();
        break;
      case "last_6_months":
        startDate = dayjs().subtract(6, "month").startOf("month").toDate();
        endDate = dayjs().subtract(1, "month").endOf("month").toDate();
        break;
      case "last_12_months":
        startDate = dayjs().subtract(12, "month").startOf("month").toDate();
        endDate = dayjs().subtract(1, "month").endOf("month").toDate();
        break;
      case "this_year":
        startDate = dayjs().startOf("year").toDate();
        endDate = dayjs().endOf("year").toDate();
        break;
      case "last_year":
        startDate = dayjs().subtract(1, "year").startOf("year").toDate();
        endDate = dayjs().subtract(1, "year").endOf("year").toDate();
        break;
      default:
        break;
    }
    return { startDate, endDate };
  };

  return (
    <div className="w-full md:min-w-1/4 border border-[#ddd] cursor-pointer relative transition ease-in-out delay-150">
      <div>
        <div className="flex justify-between">
          <div
            className="px-2.5 pt-2.5 font-pop "
            onClick={() => setShowPicker(!showPicker)}
          >
            {rangeBy}
          </div>
          {seachButton ? (
            <Button
              text={<FaSearch size={14} />}
              variant="btn_cancel"
              className="max-h-[30px] !bg-[#042A42] ml-1 rounded-bl"
              onClick={seachButtonOnClick}
            />
          ) : null}
        </div>

        <div
          className="p-2.5 pt-0 font-semibold font-pop "
          onClick={() => setShowPicker(!showPicker)}
        >
          {dayjs(filterData[from_date]).format("MMM Do, YYYY")} -{" "}
          {dayjs(filterData[to_date]).format("MMM Do, YYYY")}
        </div>
        {isCustom && (
          <div className="border-t border-[#ddd] w-full flex flex-col p-2.5">
            <span className="text-sm font-pop text-center">From - To</span>
            <DateRangeComp
              inputClassName="!font-medium w-full text-center bg-[#e9ecef] text-sm rounded !py-1 !px-2 border border-[#ced4da] !mb-0 rounded-0"
              pickerClassName="!left-1/2 !top-8 -translate-x-[50%]"
              dateRange
              onChange={(e) => {
                const endDate = new Date(e.selection.endDate);
                endDate.setHours(23, 59, 59, 59);
                const formattedEndDate =
                  endDate.toISOString().slice(0, 23) + "Z";

                const startDate = new Date(e.selection.startDate);
                startDate.setHours(0, 0, 0, 0);
                const formattedStartDate =
                  startDate.toISOString().slice(0, 23) + "Z";

                setFiltersData({
                  ...filterData,
                  [to_date]: formattedEndDate,
                  [from_date]: formattedStartDate,
                });
                if (onChange) {
                  onChange({
                    [to_date]: formattedEndDate,
                    [from_date]: formattedStartDate,
                  });
                }
              }}
            />
          </div>
        )}
      </div>

      {showPicker && (
        <div className="absolute bg-white w-[100.5%] z-10 border-l border-[#ddd] border-r -ml-px overflow-hidden transition ease-in-out delay-150">
          <ul ref={pickerRef}>
            {picker?.map((data, index) => {
              return (
                <li
                  key={index}
                  className="p-2.5 border-t border-[#ddd] hover:bg-[#e1e1e1]"
                  onClick={() => handleDateRange(data)}
                >
                  {data.label}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {isDropdown !== "No" ? (
        <div
          className="flex items-center justify-between p-2.5 border-t border-[#ddd] font-semibold text-normal font-pop"
          onClick={() => setShowfilter(!showfilter)}
        >
          {dropdown_label
            ? `${dropdown_label}${
                filterData[valueKey]
                  ? ": " +
                    dropdown_menu?.find(
                      ({ value }) => value === filterData[valueKey]
                    )?.label
                  : ""
              }`
            : `By: Job end date`}
          <div>
            {filterData[valueKey] ? (
              <RxCross2
                className="mr-2"
                onClick={(event) => {
                  event.stopPropagation();
                  setFiltersData({ ...filterData, [valueKey]: null });
                  // if (onChange) {
                  //   onChange();
                  // }
                }}
              />
            ) : null}

            <HiChevronDown />
          </div>
        </div>
      ) : null}

      {showfilter && (
        <div className="absolute bg-white w-[100.5%] z-10 border-b border-l border-[#ddd] border-r -ml-px overflow-hidden transition ease-in-out delay-150">
          <ul
            ref={filterRef}
            className="pl-0 border- mb-0 max-h-[450px] overflow-y-auto "
          >
            {searchable ? (
              <div className="w-100">
                <input
                  className={
                    "form-control-sm border h-100 w-100 pl-5 !rounded-none"
                  }
                  style={{ minHeight: 31 }}
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
                <div className="absolute top-[3px] left-[10px] border-r pr-[10px] flex justify-center items-center h-[25px]">
                  <FaSearch />
                </div>
              </div>
            ) : null}

            {isLoader ? (
              <div className="w-full flex justify-center align-center">
                <Loader />
              </div>
            ) : (
              filters?.map((list, index) => {
                return (
                  <li
                    key={index}
                    className="p-2.5 border-t border-[#ddd] hover:bg-[#e1e1e1]"
                    onClick={() => {
                      setFiltersData({
                        ...filterData,
                        [valueKey]: list?.value,
                      });
                      setShowfilter(false);
                      // if (onChange) {
                      //   onChange();
                      // }
                    }}
                  >
                    {list.label}
                  </li>
                );
              })
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Picker;
