import React from "react";
const PieChartLoader = () => {
  return (
    <div className="max-w-md mx-auto">
      <div className="loader w-52 h-52 animate-pulse">
        {/* Circle Skeleton Loader */}
        <div className="h-full w-full bg-gray-300 rounded-full flex items-center justify-center">
          <div className="h-32 w-32 bg-white rounded-full"></div>
        </div>
      </div>
    </div>
  );
};

export default PieChartLoader;
