import React from "react";
import FormInput from "../Common/FormInput";
import VehicleInfoSection from "../Common/Sections/VehicleInfoSection";
const LocksmithServiceSection = ({ formik, serviceName }) => {
  return (
    <>
      <VehicleInfoSection
        formik={formik}
        fieldsToShow={[
          "car_year",
          "car_make",
          "car_model",
          "is_neutral",
          "car_color",
        ]}
        serviceName={serviceName}
      />
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="w-full bg-neutral-50">
          <div className="flex flex-col p-2">
            <h6 className="font-semibold w-full px-1 py-0 text-base">
              What type of Locksmith Service?
            </h6>
            <div className="flex flex-col lg:flex-row items-start lg:items-center space-y-2 lg:space-y-0 lg:space-x-4 mb-4">
              <div className="flex items-center space-x-4 px-2 py-2">
                <label className="flex items-center cursor-pointer">
                  <input
                    type="radio"
                    name="locksmith_type"
                    value="car_locksmith"
                    className="mr-2 cursor-pointer"
                    checked={
                      formik?.values?.extra_fields?.locksmith_type ===
                      "car_locksmith"
                    }
                    onChange={(e) =>
                      formik?.setFieldValue(
                        "extra_fields.locksmith_type",
                        e.target.value
                      )
                    }
                  />
                  Car Locksmith
                </label>
                <label className="flex items-center cursor-pointer">
                  <input
                    type="radio"
                    name="locksmith_type"
                    value="residential_locksmith"
                    className="mr-2 cursor-pointer"
                    checked={
                      formik?.values?.extra_fields?.locksmith_type ===
                      "residential_locksmith"
                    }
                    onChange={(e) =>
                      formik?.setFieldValue(
                        "extra_fields.locksmith_type",
                        e.target.value
                      )
                    }
                  />
                  Residential
                </label>
                <label className="flex items-center cursor-pointer">
                  <input
                    type="radio"
                    name="locksmith_type"
                    value="other_locksmith"
                    className="mr-2 cursor-pointer"
                    checked={
                      formik?.values?.extra_fields?.locksmith_type ===
                      "other_locksmith"
                    }
                    onChange={(e) =>
                      formik?.setFieldValue(
                        "extra_fields.locksmith_type",
                        e.target.value
                      )
                    }
                  />
                  Others
                </label>
              </div>
              <FormInput
                name="extra_fields.locksmith_specify"
                type="text"
                placeholder="Specify"
                formik={formik}
                value={formik.values?.extra_fields?.locksmith_specify}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocksmithServiceSection;
