import React, { useState, useRef } from "react";
import { DotsLoader, FormInput, Modal } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { create_tags, update_tag } from "../../features/tagsSlice";
import { HexColorPicker } from "react-colorful";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
function useVisible(initialIsVisible) {
  const [isPalette, setIsPalette] = useState(initialIsVisible);
  const inputRef = useRef(null);
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsPalette(false);
    }
  };

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsPalette(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { inputRef, isPalette, setIsPalette };
}

const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const { isLoading } = useSelector((state) => state.tags);
  const { inputRef, isPalette, setIsPalette } = useVisible(false);
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState([]);

  let initialValues = {
    name: "",
    color: "",
  };

  if (editingRecord) {
    const { _id, name, color } = editingRecord;
    initialValues = { id: _id, name, color };
  }

  const formik = useFormik({
    initialValues,

    validateOnBlur: true,

    // validationSchema: TagSchema,

    onSubmit: async (values) => {
      if (!editingRecord) {
        try {
          const res = await dispatch(create_tags(values));
          if (res?.payload?.status === 200) {
            toast.success("Tag created");
            onCancelForm();
          } else {
            errorMessage({payload:res.payload, setErrors:setErrors, action:"Tag", msg:'created'})
          }
        } catch (error) {
          errorMessage({payload:error, setErrors:setErrors, action:"Tag", msg:'created'})
        }
      } else {
        try {
          const res = await dispatch(update_tag(values));
          if (res?.payload?.status === 200) {
            toast.success("Tag updated");
            onCancelForm();
          } else {
            errorMessage({payload:res.payload, setErrors:setErrors, action:"Tag", msg:'updated'})
          }
        } catch (error) {
          errorMessage({payload:error, setErrors:setErrors, action:"Tag", msg:'updated'})
        }
      }
    },
  });
  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="mt-4">
          <FormInput errors={errors} name="name" label="Name" formik={formik}   />
          <div className="flex flex-row items-center mt-2">
            
            <div className="w-full">
              <FormInput errors={errors}
                name="color"
                label="Color"
                formik={formik}
                onClick={() => {
                  setIsPalette(!isPalette)
                }}
                readOnly={true}
                placeholder="Click here"
                className="!cursor-pointer"
                // onFocus={() => setIsPalette(!isPalette)}
              />
            </div>
            {
              formik?.values?.color&&
              <div
                className="w-10 h-10 flex border-2 rounded-sm items-center justify-center cursor-pointer -mt-1"
                onClick={() => setIsPalette(!isPalette)}
              >
                <div
                  className="w-9 h-9 rounded"
                  style={{ backgroundColor: formik?.values?.color }}
                />
              </div>
            }

            <div>
              
            </div>
          </div>
          {isPalette && (
            <div ref={inputRef} className="w-[200px]">
              <HexColorPicker
                color={formik.values.color}
                onChange={(e) => formik.setFieldValue("color", e)}
              />
            </div>
          )}
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;
