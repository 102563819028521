import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TextField } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
function SingleFormSchedule({ formik }) {
  return (
    <div className="flex flex-row gap-x-4">
      <div className="w-1/2">
        <textarea
          className={[
            "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
            `rounded-l-none`,
            // formik?.errors?.comments && formik?.touched?.comments ? "invalid" : "",
          ].join(" ")}
          rows={3}
          value={formik?.value?.comments}
          name="comments"
          onChange={(e)=>formik?.setFieldValue("comments", e.target.value)}
        />
        <small className="font-pop">Please Enter the Comments</small>
      </div>
        <div className="mb-2 flex justify-center items-center col-md-6 w-1/2 justify-between gap-x-2">
          <div className="flex flex-col justify-center w-1/2">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                value={formik?.values?.scheduled_on}
                onChange={(val) => {
                  formik?.setFieldValue("scheduled_on", val.toString());
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <small className="font-pop">Please Select the Start Date</small>
          </div>
          <div className="flex flex-col justify-center w-1/2">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                value={formik?.values?.scheduled_end}
                onChange={(val) => {
                  formik?.setFieldValue("scheduled_end", val.toString());
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <small className="font-pop">Please Select the End Date</small>
          </div>
        </div>
    </div>
  );
}

export default SingleFormSchedule;
