import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";

import AddUpdateForm from "./AddUpdateForm";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { get_system_job_status } from "../../features/systemJobStatusSlice";
import { getFormattedDate, getFullName } from "../../util/common";
import api from "../../services/api";
import { toast } from "react-toastify";
import { DotsLoader } from "../../components";
import {
  MdOutlineRadioButtonUnchecked,
  MdRadioButtonChecked,
} from "react-icons/md";

const SystemJobStatus = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.systemJobStatus);
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const [isLoader, setIsLoader] = useState(false);

  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  useEffect(() => {
    dispatch(get_system_job_status());
    // eslint-disable-next-line
  }, []);
  const set_default_job_status = async (id) => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    setIsLoader(true);
    try {
      const res = await api.put(
        `api/jobs/system_job_status/set_default_job_status/${id}`
      );
      if (res.status === 200) {
        toast.success(`Status set as default successfully`);
      } else {
        toast.error(res?.data?.message || `Status couldn't be set as default`);
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error(
        err?.response?.data?.message || `Status couldn't be set as default`
      );
      console.log("🚀 ~ handleConfirm ~ err:", err);
    }
  };
  const jobStatus = record?.filter((status) =>
    status?.name?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "name", field: "name", flex: 1 },
    {
      headerName: "Synced in All",
      field: "syncedInAllMerchants",
      width: 120,
      flex: 1,
    },
    { headerName: "Created By", field: "createdBy", flex: 1 },
    { headerName: "Created At", field: "createdAt", flex: 1 },
    user?.company_name === "Creative IT"
      ? {
          headerName: "Is Default",
          field: "defaultJobStatus",
          width: 120,
          flex: 1,
          renderCell: (params) => {
            return (
              <>
                {params.row.defaultJobStatus === "Yes" ? (
                  <div className="flex gap-x-1 items-center">
                    <MdRadioButtonChecked className="text-[#060]" size={22} />
                    <span>Yes</span>
                  </div>
                ) : (
                  <button
                    onClick={() =>
                      set_default_job_status(params?.row?.records?._id)
                    }
                    className="flex gap-x-1 items-center"
                  >
                    <MdOutlineRadioButtonUnchecked size={22} />
                    <span className="text-blue-500">Set as default</span>
                  </button>
                )}
              </>
            );
          },
        }
      : null,
    user?.company_name === "Creative IT"
      ? {
          field: "actions",
          width: 100,
          align: "center",
          headerAlign: "center",
          renderCell: (params) => (
            <div>
              <FaEdit
                onClick={openFormHandler(params.row.records)}
                className="my_navIcon"
                title="Update Job Status"
              />
            </div>
          ),
        }
      : null,
  ].filter(Boolean);
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add System Job Status"
          onCancelForm={cancelFormHandler}
        />
      )}
      {isLoader ? <DotsLoader /> : null}
      <PageHeader
        route="Setting > Job Status"
        heading="System Job Status Listing"
        onClick={openFormHandler(0)}
        isAllowed={user?.company_name === "Creative IT"}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={jobStatus?.map((record, index) => {
            let counter = index + 1;
            const {
              name,
              createdBy,
              defaultJobStatus,
              syncedInAllMerchants,
              createdAt,
            } = record;
            return {
              records: record,
              counter,
              name,
              createdBy: `${getFullName(createdBy)} (${createdBy?.username})`,
              syncedInAllMerchants: syncedInAllMerchants ? "Yes" : "No",
              createdAt: getFormattedDate(createdAt),
              defaultJobStatus: defaultJobStatus ? "Yes" : "No",
            };
          })}
          pagination="No"
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default SystemJobStatus;
