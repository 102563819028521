import api from "./api";

export const getMonitor = async () => {
  return await api.get("/api/uptime_robot");
};
export const createMonitor = async (data) => {
  return await api.post("/api/uptime_robot/add_monitor", data);
};
export const UpdateMonitor = async (data) => {
  return await api.put("/api/uptime_robot/update_monitor", data);
};
export const deleteMonitor = async (id) => {
  return await api.delete(`api/uptime_robot/delete_monitor/${id}`);
};
export const updateMonitorStatus = async (data) => {
  return await api.put("/api/uptime_robot/pause_and_resume", data);
};
