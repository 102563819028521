import React from "react";
import { phone, id, email, call } from "../../../../images";
import CustomSelect from "../../Components/Selectors";

function BasicInfo({ formik, setIsDisable, errors }) {
  React.useEffect(() => {
      if (formik?.values?.phone) {
        setIsDisable(false);
      } else {
        setIsDisable(true);
      }
    if (formik.values.lead_token)
      {
        setIsDisable(false);
      } else {
        setIsDisable(true);
      }
    // eslint-disable-next-line
  }, [formik?.values?.phone, formik?.values?.name,formik.values.lead_token]);
  return (
    <form className="grid lg:grid-cols-2 gap-4">
      <CustomSelect
        name="phone"
        icon={phone}
        isPhoneInput
        formik={formik}
        placeholder="Phone No."
        label="What is phone number?"
        isOnChange={(e) => formik.setFieldValue("phone", e)}
        value={formik.values.phone}
        errors={errors}
      />

      <CustomSelect
        icon={id}
        label="Please enter the name."
        placeholder="Full Name"
        isInput={true}
        formik={formik}
        name="name"
        value={formik?.values?.name}
        errors={errors}
      />
      <CustomSelect
        icon={email}
        label="Please enter an email."
        placeholder="Email"
        isInput={true}
        formik={formik}
        name="email"
        value={formik?.values?.email}
        errors={errors}
      />
      <CustomSelect
        name="alternate_phone"
        icon={call}
        label="Have an another phone number?"
        placeholder="Alternate Phone No."
        formik={formik}
        isPhoneInput
        isOnChange={(e) => formik.setFieldValue("alternate_phone", e)}
        value={formik?.values?.alternate_phone}
        errors={errors}
      />
    </form>
  );
}

export default BasicInfo;
