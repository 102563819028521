import React, { useState } from "react";
import { Button } from "../../components";
import Breadcrumbs from "../../components/molecules/Breadcrumbs";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import DateRangeComp from "../../components/molecules/DateRangePicker";
import { Bar } from "react-chartjs-2";
import SelectInput from "../../components/atoms/SelectInput";
function ExtensionSummary() {
  const [perPage, setPerPage] = useState(10);
  // const [dateRange, setDateRange] = React.useState({
  //   to_date: null,
  //   from_date: null,
  // });
  const datas = {
    labels: ["2888, Lozano (N/A)"],
    previousDate: {
      label: "08/10/2019 - 09/30/2019",
      dataSet: [2.0],
    },
  };
  const columnDefs = [
    { headerName: "Extension", field: "counter" },
    { headerName: "First Name", field: "lead_id", flex: 1, minWidth: 200 },

    { headerName: "Last Name", field: "type", flex: 1, minWidth: 200 },
    { headerName: "Department", field: "jobType", flex: 1, minWidth: 200 },
    { headerName: "Site", field: "Direction", flex: 1, minWidth: 200 },
    { headerName: "Total Inbound", field: "Talk", flex: 1, minWidth: 200 },
    {
      headerName: "Total Inbound Answered",
      field: "Call",
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Total Inbound Missed",
      field: "Caller",
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Total Inbound Abandoned",
      field: "Callee",
      flex: 1,
      minWidth: 200,
    },
    { headerName: "Total Outbound", field: "lerN", flex: 1, minWidth: 200 },
    {
      headerName: "Total Outbound Answered",
      field: "createdBy",
      flex: 1,
      minWidth: 200,
    },
    { headerName: "Talk Time", field: "Ring", flex: 1, minWidth: 200 },
    { headerName: "Avg Talk Time", field: "Answered", flex: 1, minWidth: 200 },
    { headerName: "Avg Ring Time", field: "Missed", flex: 1, minWidth: 200 },
    {
      headerName: "Avg Abandoned Time",
      field: "Abandoned",
      flex: 1,
      minWidth: 200,
    },
  ];
  const options = [
    { label: "Total Inbound Abandoned", values: "" },
    { label: "Total Inbound Answered", values: "" },
    { label: "Total Inbound", values: "" },
    { label: "Total Inbound Missed", values: "" },
    { label: "Inbound Talk Time", values: "" },
    { label: "Total Outbound Abandoned", values: "" },
    { label: "Total Outbound Answered", values: "" },
    { label: "Total Outbound", values: "" },
    { label: "Total Outbound Missed", values: "" },
    { label: "Outbound Talk Time", values: "" },
  ];
  return (
    <div className="!mt-2">
      <div className="flex flex-wrap w-full justify-start items-center gap-x-10">
        <Breadcrumbs />
        <DateRangeComp
          inputClassName="bg-[#e9ecef] text-sm rounded py-1 px-2 border border-[#ced4da]"
          pickerClassName="-translate-x-[ left-auto"
          dateRangePicker
          button
          onChange={(e) => {
            const start = new Date(e.selection.startDate.toDateString());
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            const end = new Date(e.selection.endDate.toDateString());
            end.setHours(23);
            end.setMinutes(59);
            end.setSeconds(59);
            // setDateRange({ from_date: start, to_date: end });
          }}
        />
      </div>
      <div className="flex flex-row justify-between !mt-3 gap-x-2">
        <div className="w-full ">
          <button className="border relative w-full text-left p-2">
            Search
          </button>
        </div>
        <Button text="Search" variant="btn_submit" />
      </div>
      <div className="bg-[#f7f8fa] mt-3 border">
        <div className="flex items-center justify-between mb-3 !p-3">
          <div className="flex items-center">
            <span className="mr-3 text-xl font-semibold text-[#5e6d7a]">Top {perPage} extension summary</span>
            <div className="justify-center md:justify-end gap-2 flex md:!mt-0 md:col-span-2 pr-1">
              <SelectInput
                className={
                  "h-[36px] bg-white border border-gray-300 float-right rounded-[0.2rem]"
                }
                options={options}
              />
            </div>
          </div>
          <div className="flex items-center">
            <span className="mr-3">Extensions to display</span>
            <div className="justify-center md:justify-end gap-2 flex md:!mt-0 md:col-span-2 pr-1">
              <button
                className={`px-3 border h-9 shadow-datePicker hover:bg-primary-100 hover:text-white ${
                  perPage === 10 ? "bg-primary-100 text-white" : ""
                }`}
                onClick={() => setPerPage(10)}
              >
                10
              </button>
              <button
                className={`px-3 border h-9 shadow-datePicker hover:bg-primary-100 hover:text-white ${
                  perPage === 20 ? "bg-primary-100 text-white" : ""
                }`}
                onClick={() => setPerPage(20)}
              >
                20
              </button>
              <button
                className={`px-3 border h-9 shadow-datePicker hover:bg-primary-100 hover:text-white ${
                  perPage === 30 ? "bg-primary-100 text-white" : ""
                }`}
                onClick={() => setPerPage(30)}
              >
                30
              </button>
            </div>
          </div>
        </div>
        <div>
          <Bar
            // pointStyle="star"
            data={{
              labels: datas.labels,
              responsive: true,
              offset: true,
              datasets: [
                {
                  label: "Total Inbound",
                  pointStyle: "rounded",
                  backgroundColor: "#52d6aa",
                  barThickness: 550,
                  categoryPercentage: 1,
                  data: datas.previousDate.dataSet, //From API
                },
              ],
            }}
            height={320}
            options={{
              offsetGridLines: true,
              drawTicks: true,
              layout: {
                padding: {
                  top: 30,
                  right: 40,
                  bottom: 40,
                },
              },
              plugins: {
                legend: {
                  display: false,
                  position: "left", // Lowercase "left"
                  align: "start", // Lowercase "start"
                  labels: {
                    usePointStyle: true,
                  },
                },
              },

              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  stacked: true,
                  ticks: {
                    padding: 5,
                  },
                  gridLines: {
                    display: false,
                  },
                },
                y: {
                  stacked: false,
                  gridLines: {
                    drawBorder: false,
                  },
                  ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 6,
                    padding: 20,
                    callback(n) {
                      if (n < 1e3) return n;
                      if (n >= 1e3) return +(n / 1e3).toFixed(1) + "K";
                    },
                  },
                },
              },
            }}
          />
        </div>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          row={[]}
          columnDefs={columnDefs}
          pagination="No"
          pinnedColumns={{
            left: ["counter"],
          }}
        />
      </div>
    </div>
  );
}

export default ExtensionSummary;
