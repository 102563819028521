import React from "react";
import { Button, FormInput, DotsLoader } from "../../../components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import errorMessage from "../../../util/errorMessage";
import api from "../../../services/api";
const CompanyEmail = ({ companyEmail }) => {
  const [errors, setErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const initialValues = {
    new_email: "",
  };
  const CompanyEmail = Yup.object().shape({
    new_email: Yup.string()
      .required("Company Email Alias is required")
      .matches(
        /^[a-zA-Z0-9._-]+$/,
        "Email alias can only contain alphanumeric characters (letters and numbers), hyphens, underscores, and dots"
      ),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: CompanyEmail,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const payload = {
          new_email: values?.new_email,
        };
        const res = await api.put(
          "/api/users/change_user_company_email",
          payload
        );
        if (res.status === 200 || res.status === 201) {
          toast.success("Company Email changed successfully!");
        } else {
          errorMessage({
            payload: res.payload,
            setErrors: setErrors,
            action: "Company",
            msg: "changed",
          });
        }
      } catch (err) {
        toast.error(
          err?.response?.data?.message ||
            "An error occurred while changing the email"
        );
      } finally {
        setLoading(false);
      }
    },
  });
  function getDomainWithAtFromEmail(email) {
    // Find the index of the '@' character
    const atIndex = email.indexOf("@");
    if (atIndex !== -1) {
      return email.substring(atIndex);
    } else {
      return "Invalid email address";
    }
  }
  return (
    <>
      <h5 class="text-client-50 pb-2.5 w-full border-b border-client-50">
      Change Company Email ({companyEmail})
      </h5>
      <div className="border mt-3 mb-3">
        {loading && <DotsLoader />}
        <div className="flex flex-col p-2">
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div className="md:grid grid-cols-1 gap-y-2 mt-1">
                  <div>
                    <div class="w-full max-w-sm relative mt-4">
                      <div class="relative">
                        <FormInput
                          name="new_email"
                          placeholder="Enter Email Alias"
                          type="text"
                          label="Enter Email Alias"
                          formik={formik}
                          errors={errors}
                        />
                        <span
                          class="absolute right-1 top-1 rounded bg-slate-700 py-1 px-2.5 border border-transparent text-center text-sm text-white transition-all shadow-sm hover:shadow focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                          type="button"
                        >
                          {getDomainWithAtFromEmail(companyEmail)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Button text="Save" variant="btn_submit" type="submit" />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CompanyEmail;
