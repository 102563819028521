import React, { useContext } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import GlobalContext from "../context/GlobalContext";
import { Button } from "../../../components";
import styles from "../style.module.css";

export default function JobModal() {
  const {
    setShowJobModal,
    selectedMoreJobs,
    setShowEventModal,
    setSelectedEvent,
    showEventModal
  } = useContext(GlobalContext);
  const optionsRef = React.useRef();
  const handleHideDropdown = (event) => {
    if(!showEventModal){
        if (event.key === "Escape" ) {
            setShowJobModal(false);
        }
    }
  };
  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target) && showEventModal===false) {
        setShowJobModal(false);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  return (
    <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center">
      <form className="bg-white rounded-lg shadow-2xl md:w-1/4"
      ref={optionsRef}
      >
        <header className="bg-gray-100 px-4 py-2 flex justify-between items-center">
          <span className="material-icons-outlined text-gray-400"></span>
          <div className="flex justify-between">
          <span className="material-icons-outlined text-black">
            {selectedMoreJobs?.length}
          </span>
            <button onClick={() => setShowJobModal(false)}>
              <span className="material-icons-outlined text-menu">
                <AiOutlineCloseCircle size={25} />
              </span>
            </button>
          </div>
        </header>
        <div className="p-3">
          <div className="max-h-[310px] overflow-y-auto">
            {selectedMoreJobs?.map((job, index) => {
              return (
                <div
                  key={index}
                  className={styles.card}
                  onClick={() => {
                    setSelectedEvent(job);
                    setShowEventModal(true);
                  }}
                >
                  <h5>
                    {job?.industry_id?.name} -{" "}
                    {job?.job_created ? "Job #" : "Lead #"}{" "}
                    {job?.job_created ? job?.job_number : job?.lead_num}
                  </h5>
                  <p className="mid-margin-bottom">
                    {job.complete_pickup_address}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <footer className="flex justify-end border-t !p-3">
          <Button
            text="Close"
            onClick={() => setShowJobModal(false)}
            variant="btn_cancel"
          />
        </footer>
      </form>
    </div>
  );
}
