import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createTag,
  deleteTag,
  getTags,
  getTagsDrd,
  updateTag,
} from "../services/tagsService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  tagsDrd: [],
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Tags
export const get_tags = createAsyncThunk("get_tags", async (data, thunkAPI) => {
  try {
    return await getTags(data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
// Get Tags Drd
export const get_tags_drd = createAsyncThunk(
  "get_tags_drd",
  async (thunkAPI) => {
    try {
      return await getTagsDrd();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create New Tags
export const create_tags = createAsyncThunk(
  "create_tags",
  async (data, thunkAPI) => {
    try {
      return await createTag(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Tags
export const update_tag = createAsyncThunk(
  "update_tag",
  async (data, thunkAPI) => {
    try {
      return await updateTag(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Tag
export const delete_tag = createAsyncThunk(
  "delete_tag",
  async (id, thunkAPI) => {
    try {
      return await deleteTag(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_tags.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_tags.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_tags.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
      })
      .addCase(create_tags.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_tags.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.records.push(action.payload.data);
      })
      .addCase(create_tags.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_tag.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_tag.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.records.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record.records[result] = {
          ...state.record.records[result],
          ...action.payload.data,
        };
      })
      .addCase(update_tag.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_tag.fulfilled, (state, action) => {
        const result = state.record.records.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record[result].active = false;
      })
      .addCase(get_tags_drd.pending, (state) => {
        state.isLoading = true;
        state.tagsDrd = [];
      })
      .addCase(get_tags_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        const sortedTags = [...action.payload?.data].sort((a, b) =>
          a?.name?.localeCompare(b?.name)
        );
        state.tagsDrd = sortedTags;
      })
      .addCase(get_tags_drd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = tagsSlice.actions;
export default tagsSlice.reducer;
