import React from "react";
import { FaEdit, FaPlus } from "react-icons/fa";
import Breadcrumbs from "../Breadcrumbs";
function PageHeader(props) {
  const {
    heading,
    onClick,
    isAllowed,
    extraButton,
    extraButtonClick,
    additionalBtnTitle,
    additionalBtnAllow,
    additionalComponent,
    isHead,
    CustomComponent,
    breadcrumb,
    buttonTitle,
    addIcon,
    editIcon,
    className,
    CustomButtons,
    LineOne,
    Icon,
    iconClassName,
  } = props;
  return (
    <div className="ml-0.5 mt-[0.75rem] sm:mt-0.5">
      <div className="!mb-2 flex justify-between">
        <Breadcrumbs breadcrumb={breadcrumb} />
        {LineOne ? <LineOne /> : null}
      </div>
      {isHead === "No" ? null : (
        <div className="flex flex-col-reverse items-center md:flex-row  md:justify-between gap-2">
          <div
            className={`col-span-6 flex flex-row justify-between w-1/ items-center ${className}`}
          >
            <div className="font-semibold font-mont text-heading capitalize">
              {heading}
            </div>
            {CustomComponent ? <CustomComponent /> : null}
          </div>

          <div className="col-span-6">
            {CustomButtons ? <CustomButtons /> : null}
            {extraButton && additionalBtnAllow && (
              <button
                type="button"
                className="py-1.5 !px-3 !ml-1 align-middle bg-primary-100 border-primary-100 text-white float-right flex items-center rounded-0 min-h-[36px]"
                onClick={extraButtonClick}
                disabled={!additionalBtnAllow}
              >
                {editIcon !== "No" ? Icon ? <Icon className={iconClassName} /> : <FaEdit className="mr-2" /> : null}

                {additionalBtnTitle}
              </button>
            )}
            {isAllowed && (
              <button
                type="button"
                className="py-1.5 !px-3 align-middle bg-primary-100 border-primary-100 text-white float-right flex items-center rounded-0"
                onClick={onClick}
                disabled={!isAllowed}
              >
                {addIcon !== "No" ? <FaPlus className="mr-2" /> : null}
                {buttonTitle ? buttonTitle : "Add New"}
              </button>
            )}
            {additionalComponent ? additionalComponent : null}
          </div>
        </div>
      )}
    </div>
  );
}

export default PageHeader;
