import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DotsLoader, Modal } from "../../components";
import { get_tasks, get_task_by_user, update_user_tasks } from "../../features/tasksSlice";
import Select from "react-select";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";

function SelectTaskForm({ editingRecord, onCancelForm, modalTitle, users }) {
  const { isLoading, record } = useSelector((state) => state.tasks);
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState();
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    dispatch(get_tasks());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
const getAssignedTasks=async()=>{
  try{
    const res= await dispatch(get_task_by_user(selectedRole?._id))
    if(res?.payload?.status===200){
     setSelected(res?.payload?.data)
    }

   }catch (error){
     console.error(error)
   }
}
  React.useEffect(() => {
    if(selectedRole?._id){
      getAssignedTasks()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole?._id]);

  const selectAll = () => {
    let id = [];
    if(selectedRole?._id){
      if (selected?.length !== record.length) {
        // eslint-disable-next-line
        record.map((val) => {
          id = [...id, val._id];
        });
        setSelected(id);
      } else if (selected?.length === record?.length) {
        setSelected([]);
      }
    }else{
      toast.error("Please Select a user")
    }
  };
  const onSingleselect = (val) => {
    if( selectedRole?._id ){
      if (selected?.length > 0) {
        if (selected.filter((_id) => _id === val._id).length > 0) {
          const arr = selected.filter((_id) => _id !== val._id);
          setSelected(arr);
        } else {
          setSelected([...selected, val?._id]);
        }
      } else {
        setSelected([...selected, val?._id]);
      }
    }else{
      toast.error("Please Select a user.")
    }
  };

  const isChecked = (val) => {
    if (selected?.length > 0) {
      if (selected?.filter((_id) => _id === val).length > 0) {
        return true;
      }
    }
    return false;
  };

  const assignTask=async()=>{
    try {
      const res = await dispatch(update_user_tasks({id:selectedRole._id,tasks:selected}));
      if (res?.payload?.status === 200) {
        toast.success("Permission updated");
        onCancelForm();
      } else {
        errorMessage({payload:res.payload, action:"User Permissions", msg:'assigned'})
      }
    } catch (error) {
      errorMessage({payload:error, action:"User Permissions", msg:'assigned'})
    }
  }

  const tasks = record?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        // searchInput &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        onSubmit={assignTask}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <div className="flex flex-row gap-x-2">
          <Select
            onChange={(e) => setSelectedRole(e)}
            name="job_status_id"
            className="col-span-6 w-full"
            options={users}
            getOptionLabel={(option) => option.username}
            getOptionValue={(option) => option._id}
            placeholder="Select User"
          />
          <div className="relative w-full col-span-6 md:col-span-6 flex justify-end items-end">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                // aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              className="h-[38px] bg-white border border-gray-300 text-gray-900 text-sm rounded-[0.2rem] w-full pl-12 p-2.5 "
              placeholder="Search"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
        </div>
        <ul
          className={`w-full bg-white border-l border-[#ddd] border-r shadow-lg !my-2 h-[50vh] overflow-y-auto`}
        >
          <li
            className={`text-black px-2.5 !py-1 border-t border-[#ddd] hover:bg-[#e1e1e1] cursor-pointer flex justify-between flex-row`}
            onClick={selectAll}
          >
            <span>All</span>
            <input
              type={"checkbox"}
              checked={
                selected?.length === record?.length && record?.length > 0
              }
            />
          </li>
          {tasks?.map((task, index) => {
            return (
              <li
                className={`text-black px-2.5 !py-1 border-t border-[#ddd] hover:bg-[#e1e1e1] cursor-pointer flex justify-between flex-row`}
                key={index}
                onClick={() => onSingleselect(task)}
              >
                <span>{task.name}</span>
                <input type={"checkbox"} checked={isChecked(task._id)} />
              </li>
            );
          })}
        </ul>
      </Modal>
    </>
  );
}

export default SelectTaskForm;
