import { DataGridPro } from "@mui/x-data-grid-pro";
import React from "react";
import { Button } from "../../../../components";

const VendorsListModal = ({ onCancelForm, data }) => {
  const modalRef = React.useRef(null);
  const [searchText, setSearchText] = React.useState("");
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      onCancelForm();
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onCancelForm();
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const vendors = data?.vendors?.filter((e) => {
    const fullName = [e.first_name, e.last_name].filter(Boolean).join(' ').toLowerCase();
    const searchQuery = searchText?.toLowerCase() ?? '';
    return (
      fullName.includes(searchQuery) ||
      (e.company_name && e.company_name.toLowerCase().includes(searchQuery)) ||
      (e.phone && e.phone.includes(searchText)) ||
      (e.phone2 && e.phone2.includes(searchText)) ||
      (e.city && e.city.toLowerCase().includes(searchQuery)) ||
      (e.state && e.state.toLowerCase().includes(searchQuery))
    );
});
  return (
    <>
      <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
        <div className="container-fluid mt-2  text-center">
          <div
            className="inline-block bg-white w-3/4 my-0 mx-auto p-4 bg-red-600 rounded  max-h-[87vh]"
            ref={modalRef}
          >
            <header className="flex items-center justify-center shadow-lg bg-primary-100 text-white h-[50px] mb-3 text-xl">
              <h1 className="text-2xl text-white m-4 font-medium">
                {`(${data?.domain}) `}Vendors List
              </h1>
            </header>
            <div className="flex flex-row justify-between">
              <input
                placeholder="Search"
                onChange={(e) => setSearchText(e.target.value)}
                className="!w-1/3 mb-2 placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md !py-2 px-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                value={searchText}
              />
              <span className="text-xl">{vendors?.length} records found</span>
            </div>

            <div className="h-[50vh] mb-2">
              <DataGridPro
                rows={
                  vendors?.length
                    ? vendors?.map((item, index) => {
                        const {
                          id,
                          first_name,
                          last_name,
                          company_name,
                          phone,
                          phone2,
                          city,
                          state,
                          vendor_type,
                        } = item;
                        return {
                          id,
                          counter: index + 1,
                          name: `${first_name || ""} ${last_name || ""}`,
                          company_name,
                          phone: `${phone ? phone : ""}${
                            phone2 ? ", " + phone2 : ""
                          }`,
                          city,
                          state,
                          vendor_type,
                        };
                      })
                    : []
                }
                columns={[
                  { headerName: "#", field: "counter", width: 80 },
                  { headerName: "Name", field: "name", flex: 1, minWidth: 200 },
                  {
                    headerName: "Phone",
                    field: "phone",
                    flex: 1,
                    minWidth: 200,
                  },
                  {
                    headerName: "Company Name",
                    field: "company_name",
                    flex: 1,
                    minWidth: 200,
                  },
                  {
                    headerName: "State",
                    field: "state",
                    flex: 1,
                    minWidth: 150,
                  },
                  { headerName: "City", field: "city", flex: 1, minWidth: 150 },
                  {
                    headerName: "Vendor type",
                    field: "vendor_type",
                    flex: 1,
                    minWidth: 150,
                  },
                ]}
              />
            </div>
            <Button
              text="Cancel"
              variant="btn_cancel"
              className="ml-3"
              onClick={onCancelForm}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorsListModal;
