import api from "./api";

export const getPaymentGateways = async (data) => {
  return await api.get("/api/payment_gateway",{params:data});
};
export const getPaymentGatewaysDrd = async () => {
  return await api.get("/api/payment_gateway/drd");
};
export const createPaymentGateway = async (data) => {
  return await api.post("/api/payment_gateway", data);
};

export const updatePaymentGateway = async (data) => {
  return await api.put("/api/payment_gateway", data);
};
