import api from "./api";

export const getGscServer = async () => { //not in use
  return await api.get(`/api/gsc/server`);
};
export const getAvailableGscServer = async () => { //not in use
  return await api.get(`/api/gsc/available_servers`);
};
export const createGscServer = async (data) => { //not in use
  return await api.post("/api/gsc/server", data);
};
export const updateGscServer = async (data) => { //not in use
  return await api.put(`/api/gsc/server`, data);
};
export const deleteGscServer = async (id) => { //not in use
  return await api.delete(`/api/gsc/server`, {data:{id}});
};

export const getRunnerApps = async () => {
  return await api.get(`/api/runner_app/list`);
};
export const createServer = async (values) => {
  return await api.post("/api/runner_app/add", values);
};

export const updateServer = async (data) => {
  return await api.put(`/api/runner_app/${data.id}`, data);
};

export const deleteServer = async (id) => {
  return await api.delete(`/api/runner_app/${id}`);
};

export const getRunners = async () => {
  return await api.get(`api/runner_app/runner_list`);
};