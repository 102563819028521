import React from "react";
import FormSelectInput from "../Common/FormSelectInput";
import VehicleInfoSection from "../Common/Sections/VehicleInfoSection";
import VehicleTrailerDetailsSection from "../Common/Sections/VehicleTrailerDetailsSection";
const TireChangeSection = ({ formik, serviceName }) => {
  return (
    <>
      <VehicleInfoSection
        serviceName={serviceName}
        formik={formik}
        fieldsToShow={[
          "car_year",
          "car_make",
          "car_model",
          "is_neutral",
          "car_color",
          "duty_type",
          "has_spare_tire",
          "which_tire_to_be_change",
        ]}
      />
      <VehicleTrailerDetailsSection
        formik={formik}
        fieldsToShow={[
          ...(formik.values.extra_fields?.duty_type &&
          formik.values.extra_fields?.duty_type !== "light_duty"
            ? [
                "is_trailer",
                "weight",
                "height",
                "length",
                "width",
                "axles_count",
                "is_dually",
                "loaded_with",
              ]
            : []),
        ]}
      />
      <div class="flex flex-row gap-2 mb-4 mt-3">
        <div class="basis-1/3">
          <div className="flex flex-col">
            <label className="font-semibold text-xs text-gray-500 mb-1">
              Does the client has regular lugnuts or locking lugnuts?
            </label>
            <FormSelectInput
              name="extra_fields.lugnuts_type"
              options={[
                { label: "Regular Lugnuts", value: "regular" },
                { label: "Locking Lugnuts", value: "locking" },
              ]}
              formik={formik}
              isCustomOption
              value={formik.values?.extra_fields?.lugnuts_type}
              valueProp="value"
              labelProp="label"
            />
          </div>
        </div>
        <div class="basis-3/4">
          <div className="flex flex-col">
            <label
              className="font-semibold text-xs text-gray-500 mb-1"
              htmlFor="make"
            >
              If its locking lugnuts, do they the keys to remove it?
            </label>
            <FormSelectInput
              name="extra_fields.lugnuts_keys_to_remove"
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              convertor={(value) => (value ? value === "true" : "")}
              formik={formik}
              valueProp="value"
              labelProp="label"
              isCustomOption
              onChange={(value) => {
                if (value === false) {
                  window.alert(
                    "Note: If no locking lugnuts just offer a tow service instead"
                  );
                }
                formik?.setFieldValue(
                  "extra_fields.lugnuts_keys_to_remove",
                  value
                );
              }}
              value={formik.values?.extra_fields?.lugnuts_keys_to_remove}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TireChangeSection;
