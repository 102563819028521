import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import { Button, FormInput, Loader } from "../../components";
const CustomModal = ({ onClose, invoiceDetail, reloadReport }) => {
  const [isLoader, setIsLoader] = useState(false);
  const [amount, setAmount] = useState(0);
  const handleConfirm = async () => {
    setIsLoader(true);
    try {
      const res = await api.post("api/jobs/invoice_clearence", {
        invoice_id: invoiceDetail?._id,
        amount: amount,
      });
      if (res.status === 200) {
        toast.success("Invoice created successfully");
        reloadReport();
        onClose();
      } else {
        toast.error(res?.data?.message || "Invoice couldn't be created");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error(
        err?.response?.data?.message || "Invoice couldn't be created"
      );
      console.log("🚀 ~ handleConfirm ~ err:", err);
    }
  };
  return (
    <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40 bg-black/60">
      <div className="bg-white p-8 rounded-lg shadow-lg relative w-[500px]">
        <h2 className="text-lg font-semibold mb-4">Invoice Clearence</h2>
        <div className="relative flex flex-col">
          <FormInput
            value={invoiceDetail?.total_payable_amount || 0}
            name="total_payable"
            placeholder={"Total Payable"}
            readOnly={true}
            label="Total Payable"
          />
          <FormInput
            value={(invoiceDetail?.paid_amount || 0)?.toFixed(2)}
            name="total_paid"
            placeholder={"Total Paid"}
            readOnly={true}
            label="Total Paid"
            className={"my-3"}
          />
          <FormInput
            value={(
              (+invoiceDetail?.total_payable_amount || 0) -
              (+invoiceDetail?.paid_amount || 0)
            )?.toLocaleString()}
            name="previous_balance"
            placeholder={"Remaining Balance"}
            readOnly={true}
            label="Remaining Balance"
          />
          <FormInput
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            name="amount"
            placeholder={"Amount"}
            label="Amount"
            className={"my-3"}
            type={"number"}
          />
          <FormInput
            value={(
              invoiceDetail?.total_payable_amount -
              Number(invoiceDetail?.paid_amount + Number(amount))
            ).toFixed(2)}
            name="amount"
            placeholder={"Net Balance"}
            readOnly={true}
            label="Net Balance"
          />
        </div>
        <div className="flex justify-end !mt-4">
          {isLoader ? (
            <Loader />
          ) : (
            <>
              <Button text="Cancel" variant="btn_cancel" onClick={onClose} />
              <Button
                text="Submit"
                variant="btn_submit"
                onClick={handleConfirm}
                className="ml-2"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
