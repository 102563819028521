import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { FaEdit, FaEye, FaSave } from "react-icons/fa";
import { TbMailForward } from "react-icons/tb";
import { useSelector, useDispatch } from "react-redux";
import {
  assign_inbound_to_industry,
  get_industry,
  industryReset,
} from "../../features/IndustrySlice";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { get_inbound_group_drd } from "../../features/inboundGroupSlice";
import { Dropdown } from "primereact/dropdown";
import api from "../../services/api";
import { Loader } from "../../components";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
const Industry = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.industry);
  const { inboundDrd } = useSelector((state) => state.inbound);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [reportPayload, setReportPayload] = useState(null);
  const [assignInbound, setAssignInbound] = useState({});
  const navigate = useNavigate();
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  useEffect(() => {
    dispatch(get_inbound_group_drd());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAssign = async () => {
    if (assignInbound?.industry_id && assignInbound?.inbound_group_id) {
      const industry = record?.records?.find(
        ({ _id }) => _id === assignInbound?.industry_id
      )?.name;
      const inbound = inboundDrd?.find(
        ({ group_id }) => group_id === assignInbound?.inbound_group_id
      )?.group_name;
      const c = window.confirm(
        `Are you sure you want to assign ${inbound} to ${industry}?`
      );
      if (c) {
        try {
          const res = await dispatch(assign_inbound_to_industry(assignInbound));
          if (res?.payload?.status === 200) {
            await dispatch(
              get_industry({
                data: { page: 1, size: paginationModel.pageSize },
              })
            );
            toast.success("Inbound Group Assign");
            setAssignInbound({});
            // cancelFormHandler();
          } else {
            errorMessage({
              payload: res.payload,
              action: "Inbound Group",
              msg: "assigned",
            });
          }
        } catch (err) {
          errorMessage({
            payload: err.payload,
            action: "Inbound Group",
            msg: "assigned",
          });
        }
      }
    }
  };
  useEffect(() => {
    dispatch(
      get_industry({ data: { page: 1, size: paginationModel.pageSize } })
    );
    return () => {
      dispatch(industryReset());
    };
    // eslint-disable-next-line
  }, []);

  const sortedData = record?.records?.map((item) => {
    const inbound_group_id = record?.industryInboundGroups?.find(
      (group) => group.industry_id === item?._id
    )?.inbound_group_id;
    const last_domain_report_time = record?.industryDomainReportLogs?.find(
      (logs) => logs?.industry_id === item?._id
    )?.createdAt;
    return {
      ...item,
      inbound_group_id: inbound_group_id || null,
      last_domain_report_time: last_domain_report_time || null,
    };
  });
  const industries = sortedData?.filter(
    (industry) =>
      industry?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      industry?.inbound_group_id
        ?.toLowerCase()
        .includes(searchText.toLowerCase())
  );

  const formattedInboundOptions = inboundDrd.map((option) => ({
    ...option,
    group_name: `${option.group_name} (${option.group_id})`,
  }));

  const sendDomainNumberReport = async ({ id, name, last, reportType }) => {
    if (last && last !== "modify") {
      setReportModal(true);
      setReportPayload({ id, name, last });
      return;
    }
    const c =
      last === "modify"
        ? true
        : window.confirm(
            `Are you sure to generate the report for ${name} industry`
          );
    if (c) {
      setIsLoader(true);
      try {
        const res = await api.post(
          `/api/industries/${id}/industry_domains_report/send_via_email_notification`,
          { report_type: reportType }
        );
        if (res?.status === 200) {
          toast.success(
            res?.data?.message || "Report has been sent to your email"
          );
          setReportModal(false);
          setReportPayload(null);
        } else {
          errorMessage({
            payload: res.payload,
            action: "Report",
            msg: "sent",
          });
        }
        setIsLoader(false);
      } catch (error) {
        setIsLoader(false);
        errorMessage({ payload: error, action: "Report", msg: "sent" });
      }
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100, filterable: false },
    { headerName: "Industry Title", field: "name", flex: 1 },
    {
      field: "inbound",
      minWidth: 300,
      filterable: false,
      renderCell: (params) => (
        <Dropdown
          value={
            assignInbound?.industry_id === params?.row?.records?._id
              ? assignInbound?.inbound_group_id
              : params?.row?.records?.inbound_group_id
          }
          onChange={(e) => {
            setAssignInbound({
              industry_id: params?.row?.records?._id,
              inbound_group_id: e.value,
            });
          }}
          options={formattedInboundOptions}
          optionLabel="group_name"
          optionValue="group_id"
          placeholder="Select Inbound Group"
          className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
          filter
          // disabled={!!params.row.records?.inbound_group_id}
        />
      ),
    },
    {
      headerName: "Action",
      field: "actions",
      filterable: false,
      renderCell: (params) => (
        <div className="flex flex-row justify-center items-center">
          {assignInbound?.industry_id === params?.row?.records?._id &&
          assignInbound?.inbound_group_id !==
            params?.row?.records?.inbound_group_id ? (
            <div className="flex justify-center">
              {userPermission("Update Industry") && (
                <FaSave onClick={handleAssign} className="my_navIcon" />
              )}
            </div>
          ) : null}
          {userPermission("Update Industry") && (
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update Industry"
            />
          )}
          <TbMailForward
            size={18}
            onClick={() =>
              sendDomainNumberReport({
                id: params.row.records?._id,
                name: params.row.records?.name,
                last: params.row?.records?.last_domain_report_time,
                reportType: "Latest",
              })
            }
            className="my_navIcon"
            title="Click to get phone number report on email"
          />
          <FaEye
            size={18}
            onClick={() => {
              navigate(
                `/settings/industries/industry_domains_report/${params?.row?.records?._id}`
              );
            }}
            className="my_navIcon"
            title="Click to get phone number report on email"
          />
        </div>
      ),

      width: 150,
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_industry({
        data: {
          page: +params.page + 1,
          size: params.pageSize,
        },
      })
    );
  };

  const SendReport = () => {
    return (
      <>
        <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40">
          <div className="bg-white p-8 rounded-lg shadow-lg relative max-w-[80%]">
            <h2 className="text-lg font-semibold mb-4">Confirmation</h2>
            {`This report was generated on ${
              reportPayload?.last
                ? dayjs(reportPayload?.last).format("ddd, MMM D, YYYY h:mm A")
                : ""
            }. Do you want to send a previous report or create a new one?`}
            <div className="flex justify-end !mt-4">
              {isLoader ? (
                <Loader />
              ) : (
                <>
                  <button
                    className="px-4 py-2 mr-2 bg-menu text-white"
                    onClick={() => {
                      sendDomainNumberReport({
                        ...reportPayload,
                        reportType: "Latest",
                        last: "modify",
                      });
                    }}
                  >
                    Send Latest Report
                  </button>
                  <button
                    className="px-4 py-2 mr-2 bg-primary-100 text-white"
                    onClick={() =>
                      sendDomainNumberReport({
                        ...reportPayload,
                        reportType: "Last",
                        last: "modify",
                      })
                    }
                  >
                    Send Last Report
                  </button>
                  <button
                    className="px-4 py-2 bg-gray-300 text-gray-700 hover:bg-gray-400"
                    onClick={() => {
                      setReportModal(false);
                      setReportPayload(null);
                    }}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Industry"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Industries"
        heading="Industries Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Industry")}
      />
      {reportModal ? <SendReport /> : null}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={industries?.map((record, index) => {
            let counter = index + 1;
            let name = record.name;
            let status = record.active;
            return {
              records: record,
              counter,
              name,
              status,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          density="standard"
        />
      </div>
    </>
  );
};

export default Industry;
