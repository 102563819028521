import React, { useState, useContext, useEffect } from "react";
import { getMonth } from "./util";
import CalendarHeader from "./components/CalendarHeader";
import Sidebar from "./components/Sidebar";
import Month from "./components/Month";
import GlobalContext from "./context/GlobalContext";
import EventModal from "./components/EventModal";
import { useDispatch, useSelector } from "react-redux";
import { DotsLoader } from "../../components";
import JobModal from "./components/jobsModal";
import { get_industry_drd } from "../../features/IndustrySlice";
import { get_job_status } from "../../features/jobStatusSlice";
import Days from "./components/Days";
import Weeks from "./components/Weeks";
import {
  get_all_geo_vendors,
  get_geo_vendors,
} from "../../features/GeoVendorsSlice";
import { get_jobs_data } from "../../features/jobsSlice";
function Scheduler() {
  const { isLoading, record } = useSelector((state) => state.jobs);
  const vendors = useSelector((state) => state.geoVendors);
  const [currenMonth, setCurrentMonth] = useState(getMonth());
  const { monthIndex, showEventModal, showJobModal, filters, viewType } =
    useContext(GlobalContext);
  const start_date = currenMonth[0][0].toISOString();
  const end_date = currenMonth[4][6].toISOString();
  const dispatch = useDispatch();
  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);
  useEffect(() => {
    dispatch(get_industry_drd());
    dispatch(get_job_status({ page: 1, size: 1000, active: 1 }));

    dispatch(
      get_jobs_data({
        from_date: start_date,
        to_date: end_date,
        get_all_records: 1,
      })
    );
    dispatch(get_geo_vendors({ page: 1, size: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currenMonth]);
  useEffect(() => {
    if (vendors?.record?.totalItems) {
      dispatch(
        get_all_geo_vendors({ page: 1, size: vendors?.record?.totalItems })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendors?.record?.totalItems]);
  const getLeadsByFilter = () => {
    dispatch(
      get_jobs_data({
        from_date: start_date,
        to_date: end_date,
        get_all_records: 1,
        ...filters,
      })
    );
  };
  const onReset = () => {
    dispatch(
      get_jobs_data({
        from_date: start_date,
        to_date: end_date,
        get_all_records: 1,
      })
    );
  };
  return (
    <>
      {showEventModal && <EventModal />}
      {showJobModal && <JobModal />}
      {isLoading && <DotsLoader />}
      <div className="h-screen flex flex-col bg-white">
        <CalendarHeader getLeadsByFilter={getLeadsByFilter} onReset={onReset} />
        <div className="flex-cols md:flex flex-1">
          <Sidebar />
          {viewType === "month" ? (
            <Month month={currenMonth} />
          ) : viewType === "week" ? (
            <Weeks events={record?.records} />
          ) : viewType === "day" ? (
            <Days events={record?.records} />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Scheduler;
