import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Button } from "../../components";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import PageHeader from "../../components/molecules/PageHeader";
import {
  assign_vendors,
  get_search_logs,
  get_search_log_details_by_log_id,
} from "../../features/geoLocSlice";
import {
  get_child_geo_vendors,
  get_geo_parent_vendors,
  get_geo_states,
} from "../../features/GeoVendorsSlice";
import { MultiSelect } from "primereact/multiselect";
import { toast } from "react-toastify";
import ZipsModal from "./Zips";
function AssignTech() {
  const { isLoading, searchLogById } = useSelector((state) => state.geoLoc);

  const geoState = useSelector((state) => state.geoVendors);
  const vendors = useSelector((state) => state.geoVendors);
  const [location_zips, setLocation_zips] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const { id } = params;
  // let editingRecord = location?.state;
  const [editingRecord, setEditingRecord] = useState(location?.state);
  const [filterData, setfilterData] = useState({
    records: [],
    search_log_id: id,
  });
  const getSearchLogs = async () => {
    try {
      const res = await dispatch(
        get_search_logs({ merchant: user?.user_group })
      );
      if (res?.payload?.status === 200) {
        setEditingRecord(res.payload.data?.find((item) => item.id === +id));
      }
    } catch (err) {
      console.error("🚀 ~ file: AssignTech.jsx:44 ~ getSearchLogs ~ err:", err);
    }
  };
  const [searchText, setSearchText] = useState("");
  React.useEffect(() => {
    dispatch(get_geo_states());
    if (editingRecord) {
      dispatch(get_search_log_details_by_log_id(id));
      dispatch(get_geo_parent_vendors());
    } else {
      getSearchLogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingRecord]);

  useEffect(() => {
    const result = searchLogById?.search_log_details
      ?.filter((record) => record.vendor_ids !== null) // Exclude objects with null vendor_ids
      .map((record) => ({
        id: record.id,
        vendor_ids:
          record.vendor_ids !== null
            ? record.vendor_ids.split(",").map(Number)
            : [], // Set vendor_ids as an empty array if it's null
      }));
    setfilterData({ ...filterData, records: result });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchLogById?.search_log_details]);

  const vendorsList = vendors?.parentVendorDrd?.filter(
    ({ id, parent_id }) =>
      id === editingRecord?.vendor_id?.id ||
      parent_id === editingRecord?.vendor_id?.id
  );
  React.useEffect(() => {
    if (editingRecord?.vendor_id?.id) {
      dispatch(get_child_geo_vendors(editingRecord?.vendor_id?.id));
    }
    // eslint-disable-next-line
  }, [editingRecord]);
  const selectVendor = ({ record_id, vendor_ids }) => {
    if (filterData?.records?.length > 0) {
      const indexOfExisting = filterData?.records?.findIndex(
        ({ id }) => id === record_id
      );
      if (indexOfExisting > -1) {
        const updatedRecord = { id: record_id, vendor_ids: vendor_ids };
        const updatedRecords = [...filterData.records];
        updatedRecords[indexOfExisting] = updatedRecord;
        setfilterData({ ...filterData, records: updatedRecords });
      } else {
        const updatedRecord = { id: record_id, vendor_ids: vendor_ids };
        const updatedRecords = [...filterData.records];
        updatedRecords?.push(updatedRecord);
        setfilterData({ ...filterData, records: updatedRecords });
      }
    } else {
      const updatedRecord = { id: record_id, vendor_ids: vendor_ids };
      const updatedRecords = [...filterData.records];
      updatedRecords?.push(updatedRecord);
      setfilterData({ ...filterData, records: updatedRecords });
    }
  };
  const customOperatorsYesorNo = [
    {
      value: "yes",
      label: "Assigned",
      getApplyFilterFn: (filterItem, column) => {
        return (params) =>
          String(params.value)
            .toLowerCase()
            .includes(String(filterItem.operator).toLowerCase());
      },
    },
    {
      value: "no",
      label: "Not Assigned",
      getApplyFilterFn: (filterItem, column) => {
        return (params) =>
          String(params.value)
            .toLowerCase()
            .includes(String(filterItem.operator).toLowerCase());
      },
    },
    // Add more custom operators as needed
  ];
  const columnDefs = [
    { headerName: "#", field: "counter", width: 60 },
    { headerName: "Search By", field: "type", width: 150 },
    { headerName: "State", field: "state", width: 150 },
    {
      headerName: "Radius",
      field: "radiusInMeters",
      width: 100,
    },
    {
      headerName: "Zips",
      field: "id",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <span
          className="text-blue-600 cursor-pointer hover:underline"
          onClick={() =>
            setLocation_zips({
              zips: JSON.parse(
                params?.row?.records.zips ? params?.row?.records?.zips : null
              ),
              payload: JSON.parse(
                params?.row?.records.payload
                  ? params?.row?.records?.payload
                  : null
              ),
              state: params.row.state,
            })
          }
        >
          Show
        </span>
      ),
    },
    { headerName: "Locations", field: "locations", width: 200 },
    {
      headerName: "Vendors",
      field: "isAssigned",
      flex: 1,
      minWidth: 500,
      filterOperators: customOperatorsYesorNo,
      renderCell: (params) => {
        const records = [...vendorsList, ...vendors?.child];
        const selectedVendorIds = filterData?.records?.find(
          ({ id }) => id === params?.row?.records?.id
        )?.vendor_ids;
        const selectedRecords = records?.filter((record) =>
          selectedVendorIds?.includes(record.id)
        );
        const remainingRecords = records?.filter(
          (record) => !selectedVendorIds?.includes(record.id)
        );
        const sortedArray = selectedRecords?.concat(remainingRecords);
        return (
          <MultiSelect
            placeholder="Select Vendors"
            options={sortedArray}
            optionLabel={(option) =>
              `${option?.description ? option?.description + " | " : ""} ${
                option?.name
              } ${option?.phone ? " | " + option?.phone : ""}`
            }
            optionValue="id"
            filter
            maxSelectedLabels={1}
            value={
              filterData?.records?.find(
                ({ id }) => id === params?.row?.records?.id
              )?.vendor_ids
            }
            onChange={(e) =>
              selectVendor({
                record_id: params?.row?.records?.id,
                vendor_ids: e.value,
              })
            }
            className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
          />
        );
      },
    },
  ];

  const handleSave = async () => {
    const convertedObject = {
      ...filterData,
      records: filterData.records
        ?.filter((record) => record.vendor_ids.length > 0)
        ?.map((record) => ({
          ...record,
          vendor_ids: record.vendor_ids.join(","), // Convert the vendor_ids array to a string separated by commas
        })),
    };
    try {
      const res = await dispatch(assign_vendors(convertedObject));
      if (res?.payload?.status === 201) {
        toast.success("Vendors Assign Successfully");
      } else {
        toast.error("Vendors couldn't be assigned");
      }
    } catch (err) {
      toast.error("Vendors couldn't be assigned");
      console.error("🚀 ~ file: AssignTech.jsx:124 ~ handleSave ~ err:", err);
    }
  };
  const summary_counts = JSON.parse(
    editingRecord?.summary_counts ? editingRecord?.summary_counts : null
  );
  const summart_detail = `\n| Total Cities: ${
    summary_counts?.total_cities ? summary_counts?.total_cities : 0
  } | Unique Cities: ${
    summary_counts?.unique_cities ? summary_counts?.unique_cities : 0
  } | Total Zips: ${
    summary_counts?.total_zips ? summary_counts?.total_zips : 0
  } | Unique Zips: ${
    summary_counts?.unique_zips ? summary_counts?.unique_zips : 0
  } | Total States: ${
    summary_counts?.total_states ? summary_counts?.total_states : 0
  } | Unique States: ${
    summary_counts?.unique_states ? summary_counts?.unique_states : 0
  }`;
  const additionalComponent = (
    <div className="text-lg font-medium font_pop text-heading">
      {searchLogById?.search_log
        ? `Description: ${searchLogById?.search_log[0]?.description} | Vendor: ${searchLogById?.search_log[0]?.vendor_name} | Industry: ${searchLogById?.search_log[0]?.industry_name}`
        : ""}
    </div>
  );
  return (
    <>
      <PageHeader
        route="Setting > Vendors"
        heading="Assign Vendors"
        additionalComponent={additionalComponent}
      />
      {location_zips?.zips?.length > 0 ? (
        <ZipsModal
          zips={location_zips?.zips}
          state={location_zips?.state}
          payload={location_zips?.payload}
          onCancelForm={() => setLocation_zips(null)}
        />
      ) : null}

      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={searchLogById?.search_log_details?.map((record, index) => {
            let id = record?.id;
            let payload = JSON.parse(record?.payload);
            let counter = index + 1;
            let type = payload?.type;
            let state = geoState?.states?.find(
              ({ id }) => id === payload?.state_id
            )?.name;
            let radiusInMeters = `${+payload?.radiusInMeters / 1609} miles`;
            let locations = payload?.locations;
            let isAssigned =
              filterData?.records?.filter(({ id }) => id === record?.id)
                ?.length > 0
                ? "yes"
                : "no";
            return {
              id,
              counter,
              records: { ...record, _id: record?.id },
              type,
              state,
              radiusInMeters,
              locations,
              isAssigned,
            };
          })}
          totalItems={searchLogById?.search_log_details?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          paginationMode={"client"}
          pagination="No"
          searchable="No"
          height={"62.5vh"}
          additionalMsg={summart_detail}
        />
        <div className="flex justify-center items-center">
          <Button
            text="Submit"
            variant="btn_submit"
            onClick={handleSave}
            className="!mb-3"
          />
        </div>
      </div>
    </>
  );
}

export default AssignTech;
