import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PageHeader from "../../../components/molecules/PageHeader";
import errorMessage from "../../../util/errorMessage";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import {
  callCenterReset,
  delete_spam_numbers,
  get_spam_numbers,
} from "../../../features/callCentersSlice";
const SpamNumbers = () => {
  const dispatch = useDispatch();
  const { isLoading, spamNumbers } = useSelector((state) => state.callCenters);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_spam_numbers(record_id));
        if (res.payload?.status === 200) {
          toast.success("Spam Number deleted");
          dispatch(get_spam_numbers());
        } else {
          errorMessage({
            payload: res.payload,
            action: "Spam Number",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          action: "Spam Number",
          msg: "deleted",
        });
      }
    }
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  useEffect(() => {
    dispatch(get_spam_numbers());
    return () => {
      dispatch(callCenterReset());
    };
    // eslint-disable-next-line
  }, []);
  const filteredNumbers = Array.isArray(spamNumbers)
    ? spamNumbers?.filter((e) => {
        return Object.keys(e)?.some(
          (key) =>
            e[key] &&
            e[key]
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchText?.toLowerCase())
        );
      })
    : [];
  const columnDefs = [
    { headerName: "#", field: "counter" },
    { headerName: "Phone", field: "phone_number", flex: 1 },
    { headerName: "InGroup Id", field: "group_id", flex: 1 },
    { headerName: "InGroup Name", field: "group_name", flex: 1 },
    {
      field: "actions",
      renderCell: (params) => (
        <div>
          <FaTrashAlt
            onClick={deleteRecordHandler(params.row.phone_number)}
            className="my_navIcon"
            title="Delete Ingroup"
          />
        </div>
      ),
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Spam Numbers"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        heading="Spam Numbers Listing"
        onClick={openFormHandler(0)}
        isAllowed={true}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={filteredNumbers?.map((record, index) => {
            let counter = index + 1;
            let status = record.active === "Y" ? "Active" : "InActive";
            const { phone_number, group_id, group_name } = record;
            return {
              counter,
              records: { ...record, _id: group_id },
              phone_number,
              group_name,
              group_id,
              status,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={spamNumbers?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          paginationMode="client"
        />
      </div>
    </>
  );
};

export default SpamNumbers;
