import React from "react";
import {
  DotsLoader,
  FormInput,
  Modal,
  FormTextArea,
} from "../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  create_decline_reasons,
  update_decline_reasons,
} from "../../features/declineReasonSlice";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import * as Yup from "yup";
const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.declineReason);
  const [errors, setErrors] = React.useState([]);
  let initialValues = {
    reason: "",
    description: "",
  };

  if (editingRecord) {
    const { _id, reason, description } = editingRecord;
    initialValues = {
      id: _id,
      reason,
      description,
    };
  }
  const addEditReason = Yup.object({
    reason: Yup.string().required("Reason is required"),
    description: Yup.string().required("Description is required"),
  });
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validationSchema: addEditReason,
    onSubmit: async (values) => {
      if (!editingRecord) {
        try {
          const res = await dispatch(create_decline_reasons(values));
          if (res?.payload?.status === 200) {
            toast.success("Decline Reason created");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Decline Reason",
              msg: "created",
            });
          }
        } catch (error) {
          toast.error("Decline Reason couldn't be created");
        }
      } else {
        try {
          const res = await dispatch(update_decline_reasons(values));
          if (res?.payload?.status === 200) {
            toast.success("Decline Reason updated");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Decline Reason",
              msg: "update",
            });
          }
        } catch (error) {
          toast.error("Decline Reason couldn't be updated");
        }
      }
    },
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="mt-4">
          <div className="mb-3 mt-4">
            <FormInput
              errors={errors}
              name="reason"
              label="Name"
              formik={formik}
            />
          </div>
          <div>
            <FormTextArea
              label="Description"
              rows={5}
              name="description"
              formik={formik}
              type="text"
              errors={errors}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;
