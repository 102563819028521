import React, { useRef } from "react";
import {
  FaBan,
  FaCheck,
  FaLevelDownAlt,
  FaPause,
  FaRegClock,
  FaRegStopCircle,
  FaUsers,
} from "react-icons/fa";
import { TbPhoneCall, TbPhoneCalling } from "react-icons/tb";
import { RiLoginBoxFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { call_monitor, get_realtime_data } from "../../features/dialerSlice";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { BiSupport, BiUserVoice } from "react-icons/bi";
import { MdOutlineScreenshotMonitor } from "react-icons/md";
import { toast } from "react-toastify";
import { userPermissionNew } from "../../util/userPermissionNew";
function RealTime() {
  const dispatch = useDispatch();
  const dialer = useSelector((state) => state.dialer);
  const [activeAction, setActiveAction] = React.useState(null);
  const [isTabActive, setIsTabActive] = React.useState(true);
  const { realtime } = dialer;
  React.useEffect(() => {
    if (userPermissionNew("Real Time")) {
      dispatch(get_realtime_data());
      const timerID = setInterval(() => {
        if (userPermissionNew("Real Time") && isTabActive) {
          dispatch(get_realtime_data());
        }
      }, 5000);
      return () => {
        clearInterval(timerID);
      };
    } else {
      toast.error("You are not authorized for this report.");
    }

    // eslint-disable-next-line
  }, [isTabActive]);
  const iframeUseRef = useRef();
  const sendPostMessage = () => {
    if (!iframeUseRef?.current) return;
    const iframe = iframeUseRef.current.querySelector("iframe");
    console.warn("--------- sendMessage ------", iframe);
    iframe?.contentWindow?.postMessage({ action: "close_session" }, "*");
  };
  const handleAction = async (payload) => {
    try {
      const res = await dispatch(
        call_monitor({
          ...payload,
        })
      );
      if (res?.payload?.status === 200 || res?.payload?.status === 201) {
        setActiveAction({
          ...payload,
        });
      }
    } catch (err) {
      console.log("👊 ~ handleAction ~ err:", err);
    }
  };
  const handleVisibilityChange = () => {
    // Check if the document is hidden
    if (document.hidden) {
      setIsTabActive(false); // Tab is inactive
    } else {
      setIsTabActive(true); // Tab is active
    }
  };

  React.useEffect(() => {
    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  const getRole = (user) => {
    return (
      realtime?.final_users?.find(({ dialer_user }) => dialer_user === user)
        ?.role_id?.name || ""
    );
  };
  const columnDefs = [
    { headerName: "EXTEN", field: "extension", width: 100 },
    { headerName: "Call Type", field: "call_type", width: 80 },
    { headerName: "AGENT", field: "full_name", flex: 1, minWidth: 120 },
    {
      headerName: "Action",
      renderCell: (params) => {
        return (
          <div className="flex flex-row justify-between">
            {userPermissionNew("Barge") &&
              (activeAction &&
              activeAction.type === "BARGE" &&
              activeAction?.session_id === params?.row?.conf_exten &&
              activeAction?.server_ip === params?.row?.server_ip ? (
                <FaRegStopCircle
                  title="Stop Barge"
                  size={24}
                  className="cursor-pointer"
                  onClick={() => {
                    sendPostMessage();
                    setActiveAction(null);
                  }}
                />
              ) : (
                <BiSupport
                  title="Barge"
                  size={24}
                  className="cursor-pointer"
                  onClick={() => {
                    if (dialer?.status === "") {
                      sendPostMessage();
                      handleAction({
                        session_id: params?.row?.conf_exten,
                        server_ip: params?.row?.server_ip,
                        type: "BARGE",
                      });
                    } else {
                      toast.error("Please logout your dialer for this action");
                    }
                  }}
                />
              ))}
            {userPermissionNew("Monitor") &&
              (activeAction &&
              activeAction.type === "MONITOR" &&
              activeAction?.session_id === params?.row?.conf_exten &&
              activeAction?.server_ip === params?.row?.server_ip ? (
                <FaRegStopCircle
                  title="Stop Monitor"
                  size={24}
                  className="cursor-pointer mx-2"
                  onClick={() => {
                    sendPostMessage();
                    setActiveAction(null);
                  }}
                />
              ) : (
                <MdOutlineScreenshotMonitor
                  title="Monitor"
                  size={24}
                  className="mx-2 cursor-pointer"
                  onClick={() => {
                    if (dialer?.status === "") {
                      sendPostMessage();
                      handleAction({
                        session_id: params?.row?.conf_exten,
                        server_ip: params?.row?.server_ip,
                        type: "MONITOR",
                      });
                    } else {
                      toast.error("Please logout your dialer for this action");
                    }
                  }}
                />
              ))}
            {userPermissionNew("Whisper") &&
              (activeAction &&
              activeAction.type === "WHISPER" &&
              activeAction?.session_id === params?.row?.conf_exten &&
              activeAction?.server_ip === params?.row?.server_ip ? (
                <FaRegStopCircle
                  title="Stop Whisper"
                  size={24}
                  className="cursor-pointer"
                  onClick={() => {
                    sendPostMessage();
                    setActiveAction(null);
                  }}
                />
              ) : (
                <BiUserVoice
                  title="Whisper"
                  size={24}
                  className="cursor-pointer"
                  onClick={() => {
                    if (dialer?.status === "") {
                      sendPostMessage();
                      handleAction({
                        session_id: params?.row?.conf_exten,
                        server_ip: params?.row?.server_ip,
                        type: "WHISPER",
                      });
                    } else {
                      toast.error("Please logout your dialer for this action");
                    }
                  }}
                />
              ))}
          </div>
        );
      },
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "ROLE",
      field: "role",
      minWidth: 150,
      renderCell: (params) => {
        return <span>{getRole(params?.row?.user)}</span>;
      },
    },
    { headerName: "STATUS", field: "status", width: 100 },
    { headerName: "CALLS", field: "calls_today", width: 100 },
    { headerName: "PAUSE CODE", field: "pause_code" },
    {
      headerName: "CUSTOMER/PHONE",
      field: "customer_name",
      flex: 1,
      minWidth: 150,
    },
    { headerName: "TIME", field: "call_time_ms", width: 100 },
    {
      headerName: "INBOUND GROUP",
      field: "inbound_group",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "SITE NAME",
      field: "did_description",
      flex: 1,
      minWidth: 200,
    },
    { headerName: "CAMPAIGN", field: "campaign_name", flex: 1, minWidth: 100 },
  ];
  const columnDefs2 = [
    { headerName: "Status", field: "status", flex: 1 },
    {
      field: "campaign_id",
      headerName: "In Group",
      renderCell: (params) => (
        <div className="flex flex-row">
          {`${params?.row?.campaign_id} - ${params?.row?.campaign_name}`}
        </div>
      ),
      flex: 1,
      minWidth: 200,
    },
    { headerName: "Customer Phone", field: "phone_number", flex: 1 },
    { headerName: "Queue Time", field: "call_time", flex: 1 },
    { headerName: "Queue Priority", field: "queue_priority", flex: 1 },
    { headerName: "Call Type", field: "call_type" },
    { headerName: "Fronter", field: "fronter", flex: 1 },
    { headerName: "Closer", field: "agent_only", flex: 1 },
  ];
  const getRowClass = (params) => {
    return params?.row?.bgcolor;
  };
  const storedIframe = localStorage.getItem("iframe");
  return (
    <div className="container-fluid">
      {storedIframe && (
        <div
          ref={iframeUseRef}
          dangerouslySetInnerHTML={{ __html: storedIframe }}
        />
      )}
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-8 gap-3.5 mt-4">
        <div className="bg-white p-4">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col" style={{ lineHeight: "20px" }}>
              <span className="font-pop text-[13px] font-semibold">
                {realtime?.records?.agent_stats?.loggedin}
              </span>
              <span className="font-pop text-[13px] font-semibold">
                Available
              </span>
            </div>
            <FaCheck
              size={40}
              style={{ fontWeight: "bolder" }}
              color={"#cccccc"}
            />
          </div>
        </div>
        <div className="bg-white p-4">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col" style={{ lineHeight: "20px" }}>
              <span className="font-pop text-[13px] font-semibold">
                {realtime?.records?.agent_stats?.loggedin}
              </span>
              <span className="font-pop text-[13px] font-semibold">
                Logged In
              </span>
            </div>
            <RiLoginBoxFill
              size={40}
              style={{ fontWeight: "bolder" }}
              color={"#cccccc"}
            />
          </div>
        </div>
        <div className="bg-white p-4">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col" style={{ lineHeight: "20px" }}>
              <span className="font-pop text-[13px] font-semibold">
                {realtime?.records?.agent_stats?.paused}
              </span>
              <span className="font-pop text-[13px] font-semibold">PAUSE</span>
            </div>
            <FaPause
              size={40}
              style={{ fontWeight: "bolder" }}
              color={"#cccccc"}
            />
          </div>
        </div>

        <div className="bg-white p-4">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col" style={{ lineHeight: "20px" }}>
              <span className="font-pop text-[13px] font-semibold">
                {realtime?.records?.agent_stats?.wait}
              </span>
              <span className="font-pop text-[13px] font-semibold">
                WAITING
              </span>
            </div>
            <FaRegClock
              size={40}
              style={{ fontWeight: "bolder" }}
              color={"#cccccc"}
            />
          </div>
        </div>
        <div className="bg-white p-4">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col" style={{ lineHeight: "20px" }}>
              <span className="font-pop text-[13px] font-semibold">
                {realtime?.records?.agent_stats?.dead}
              </span>
              <span className="font-pop text-[13px] font-semibold">DEAD</span>
            </div>
            <FaBan
              size={40}
              style={{ fontWeight: "bolder" }}
              color={"#cccccc"}
            />
          </div>
        </div>

        <div className="bg-white p-4">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col" style={{ lineHeight: "20px" }}>
              <span className="font-pop text-[13px] font-semibold">
                {realtime?.records?.agent_stats?.dispo}
              </span>
              <span className="font-pop text-[13px] font-semibold">
                DISPOSITION
              </span>
            </div>
            <FaLevelDownAlt
              size={40}
              style={{ fontWeight: "bolder" }}
              color={"#cccccc"}
            />
          </div>
        </div>

        <div className="bg-white p-4">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col" style={{ lineHeight: "20px" }}>
              <span className="font-pop text-[13px] font-semibold">
                {realtime?.records?.agent_stats?.ringing}
              </span>
              <span className="font-pop text-[13px] font-semibold">
                Ringing
              </span>
            </div>
            <TbPhoneCalling
              size={40}
              style={{ fontWeight: "bolder" }}
              color={"#cccccc"}
            />
          </div>
        </div>

        <div className="bg-white p-4">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col" style={{ lineHeight: "20px" }}>
              <span className="font-pop text-[13px] font-semibold">
                {realtime?.records?.agent_stats?.incall}
              </span>
              <span className="font-pop text-[13px] font-semibold">
                In Call
              </span>
            </div>
            <TbPhoneCall
              size={40}
              style={{ fontWeight: "bolder" }}
              color={"#cccccc"}
            />
          </div>
        </div>
      </div>
      <div className="bg-white my-3 border rounded mh-70-vh overflow-y-auto">
        <div className="bg-white p-2">
          <div className="ag-theme-alpine">
            <div className="w-full p-2 pt-0 flex items-center text-base font-normal font-mont text-heading ">
              <FaUsers className="mr-1" />
              Calls in Queue
            </div>
            <DataGridPro
              rows={
                realtime?.records?.realtime_stats?.call_waiting
                  ? realtime?.records?.realtime_stats?.call_waiting
                  : {}
              }
              columns={columnDefs2}
              autoHeight
              components={{
                Toolbar: GridToolbar,
              }}
              componentsProps={{
                toolbar: {
                  showColumnSelector: false,
                },
              }}
              getRowId={(row) => row?.id}
              classes={{
                columnHeader: "bg-[#f8f8f8] shadow-sm",
                columnHeaderTitle:
                  "tableHead font-bold text-md capitalize px-0",
                row: "text-md",
              }}
              rowHeight={32}
            />
          </div>
        </div>
      </div>
      <div className="bg-white my-3 border rounded mh-70-vh overflow-y-auto">
        <div className="bg-white p-2">
          <div className="ag-theme-alpine">
            <div className="w-full p-2 flex items-center text-base font-normal font-mont text-heading">
              <FaUsers className="mr-1" />
              Agent
            </div>
            <DataGridPro
              rows={
                realtime?.records?.agent_realtime
                  ? realtime?.records?.agent_realtime
                  : []
              }
              columns={columnDefs}
              components={{
                Toolbar: GridToolbar,
              }}
              componentsProps={{
                toolbar: {
                  showColumnSelector: false,
                },
              }}
              getRowId={(row) => row?.id}
              classes={{
                columnHeader: "bg-[#f8f8f8] shadow-sm",
                columnHeaderTitle:
                  "tableHead font-bold text-md capitalize px-0",
                row: "text-md",
              }}
              getRowClassName={getRowClass}
              autoHeight
              rowHeight={32}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RealTime;
