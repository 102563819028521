import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  FormTextArea,
  Modal,
} from "../../../components";
import {
  create_purcahse_registrar,
  update_purcahse_registrar,
} from "../../../features/purchaseRegistrarSlice";
const PurchaseRegistrarModal = ({
  editingRecord,
  onCancelForm,
  modalTitle,
}) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.purchaseRegistrar);
  const [errors, setErrors] = React.useState([]);
  const initialValues = {
    username: "",
    registrar_company: "namesilo.com",
    credential: "",
    ...(editingRecord && {
      id: editingRecord._id,
      username: editingRecord.username,
      credential: JSON.stringify(editingRecord.credential),
    }),
  };
  const companyOption = [
    { value: "namesilo.com", label: "namesilo.com" },
    { value: "above.com", label: "above.com" },
  ];
  const validateForm = (values) => {
    const errors = [];
    // Add validation for other fields if needed
    if (!values.username) {
      errors.push({ username: "username is required" });
    }

    if (!values.credential) {
      errors.push({ credential: "credential is required" });
    }
    // Add validation for other fields if needed
    setErrors(errors);
    return errors;
  };
  const handleSubmit = async (values) => {
    const errors = validateForm(formik.values);
    if (errors && errors?.length > 0) {
      return;
    }
    const action = editingRecord
      ? update_purcahse_registrar
      : create_purcahse_registrar;
    const message = editingRecord ? "updated" : "created";
    try {
      const res = await dispatch(
        action({
          ...values,
          credential: JSON.parse(formik.values.credential),
        })
      );
      if (res?.payload?.status === 200 || res?.payload?.status === 201) {
        toast.success(`Domain registrar ${message} successfully`);
        onCancelForm();
      } else {
        if (Array.isArray(res.payload)) {
          setErrors(res.payload);
        } else {
          toast.error(
            res.payload
              ? res.payload
              : `Domain registrar couldn't be ${message}`
          );
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload)) {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload
            ? error.payload
            : `Domain registrar couldn't be ${message}`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="mt-4">
          <div className="!mb-3">
            <FormInput
              errors={errors}
              name="username"
              label="Name"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="registrar_company"
              label="Registrar Company"
              formik={formik}
              options={companyOption}
              valueProp="value"
              labelProp="label"
            />
          </div>
          <FormTextArea
            label="Credential Keys"
            rows={5}
            name="credential"
            formik={formik}
            type="text"
            errors={errors}
          />
        </form>
      </Modal>
    </>
  );
};

export default PurchaseRegistrarModal;
