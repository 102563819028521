import React, { useRef, useState } from "react";
import { FaTags } from "react-icons/fa";
import { BiPlusMedical } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import userPermission from "../../../util/userPermission";
import { useSelector, useDispatch } from "react-redux";
import { add_lead_tag, delete_lead_tag } from "../../../features/jobsSlice";
function useVisible(initialIsVisible) {
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const inputRef = useRef(null);
  const listRef = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      listRef.current &&
      !listRef.current.contains(event.target)
    ) {
      setIsVisible(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { inputRef, isVisible, setIsVisible, listRef };
}

export default function TagsInput(props) {
  const dispatch = useDispatch();

  const { inputRef, isVisible, setIsVisible, listRef } = useVisible(false);
  const { leadTags } = useSelector((state) => state.jobs);

  const [value, setValue] = useState("");
  const suggestionTags = props?.tags?.filter((a) =>
    a.name?.toLowerCase().includes(value?.toLowerCase())
  );
  const onSelect = async (tag) => {
    if (leadTags?.length > 0) {
      if (leadTags?.filter((item) => item?._id === tag?._id).length > 0) {
        try {
          await dispatch(
            delete_lead_tag({ lead_id: props.id, tag_id: tag._id })
          );
        } catch (err) {
          console.error("🚀 ~ file: index.jsx:69 ~ onSelect ~ err:", err);
        }
      } else {
        try {
          await dispatch(add_lead_tag({ lead_id: props.id, tag_id: tag._id }));
        } catch (err) {
          console.error("🚀 ~ file: index.jsx:69 ~ onSelect ~ err:", err);
        }
      }
    } else {
      try {
        await dispatch(add_lead_tag({ lead_id: props.id, tag_id: tag._id }));
      } catch (err) {
        console.error("🚀 ~ file: index.jsx:69 ~ onSelect ~ err:", err);
      }
    }
  };
  const isSelected = (val) => {
    if (leadTags?.length > 0) {
      if (leadTags?.filter((item) => item?._id === val._id).length > 0) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="flex items-center bg-white  px-4.5 min-h-[40px]">
      <div className="flex mt-3">
        <FaTags size={20} />
        <label className="ml-[5px] mb-0 mr-5">Tags</label>
        {userPermission("Add Lead Tag") && (
          <BiPlusMedical
            size={20}
            color="rgba(255, 183, 0, 1)"
            onClick={() => setIsVisible(!isVisible)}
            cursor="pointer"
          />
        )}

        {isVisible && (
          <>
            <input
              onChange={(e) => setValue(e.target.value)}
              autoFocus
              className="h-[31px] shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-2 w-25 relative"
              ref={inputRef}
            />
            <div
              className="h-40 absolute z-[100] left-[20%] w-1/5 bg-white z-10 mt-10 shadow-md overflow-y-auto px-2.5 rounded-b-md"
              ref={listRef}
            >
              {suggestionTags?.length > 0 ? (
                suggestionTags?.map((val) => {
                  return (
                    <li
                      key={val?._id}
                      onClick={() => {
                        onSelect(val);
                      }}
                      className="flex justify-between items-center flex-row w-full pl-2.5 mt-0.5 rounded-md cursor-pointer hover:bg-[#eaecef]"
                      name="list-item"
                      id="list-item"
                    >
                      <div className="flex items-center flex-row w-full">
                        <div
                          className="h-4 w-4 rounded mr-2.5"
                          style={{ backgroundColor: val?.color }}
                        />
                        {val.name}
                      </div>
                      {isSelected(val) && <span color="black">✓</span>}
                    </li>
                  );
                })
              ) : (
                <div className="flex justify-center items-center">
                  <h5 className="font">No Data Found</h5>
                </div>
              )}
            </div>
          </>
        )}
        <div className="flex flex-wrap">
          {leadTags?.length > 0 &&
            userPermission("List Lead Tag") &&
            leadTags?.map((val, index) => {
              return (
                <div
                  key={val._id}
                  style={{ backgroundColor: val?.color }}
                  className="px-0.5 ml-1.5 !my-0.5 flex justify-center items-center h-[23px] cursor-default"
                >
                  <span className="text-white">{val?.name}</span>
                  {userPermission("Remove Lead Tag") && (
                    <MdCancel
                      size={20}
                      className="ml-1 cursor-pointer text-white"
                      onClick={() => onSelect(val)}
                    />
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
