import React from "react";
import { FaChevronDown } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "../../../components";
import { update_general_setting } from "../../../features/generalSettingSlice";
import errorMessage from "../../../util/errorMessage";
import InputForm from "./InputForms";
import SelectForm from "./SelectForm";

function GeneralSetting({ settings }) {
  let initialValues = {
    setting_type: "generalSetings",
    company_name: "",
    sender_identity: "",
    notification_email: "",
    bcc_email: "",
    company_url: "",
    company_address: "",
    office_number: "",
    anti_theft_email: "",
    agent_logout_session: "",
    doing_business_as: "",
    change_password_days: "",
    qa_email: "",
    max_users: "",
    manuall_calling_through: "",
    recording_url: "",
  };

  React.useEffect(() => {
    setGeneralSetings(settings[0]?.generalSetings);
  }, [settings]);

  const dispatch = useDispatch();

  const [generalSetings, setGeneralSetings] = React.useState(initialValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setGeneralSetings({
      ...generalSetings,
      [name]: value,
    });
  };
  const handleSubmit = async () => {
    try {
      const res = await dispatch(
        update_general_setting(
          generalSetings?.setting_type === "generalSetings"
            ? generalSetings
            : { ...generalSetings, setting_type: "generalSetings" }
        )
      );
      if (res?.payload?.status === 200) {
        toast.success("General Setting successfully updated");
      } else {
        errorMessage({payload:res.payload, action:"General Setting", msg:'updated'})
      }
    } catch (error) {
      console.error(
        "Error ~ file: AddUpdateForm.jsx ~ line 59 ~ onSubmit: ~ error",
        error
      );
      errorMessage({payload:error, action:"General Setting", msg:'updated'})
    }
  };
  return (
    <details className="border" open>
      <summary className="flex flex-row items-center text-normal px-2 font-semibold font-pop justify-between border-b hover:cursor-pointer  bg-gray-100 hover:bg-gray-300 py-2 px-1">
        <span> General Settings </span>
        <FaChevronDown iconClass="fas fa-times" />
      </summary>
      <div className="flex flex-col p-2">
        <div className="sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <div className="md:grid md:grid-cols-2 gap-x-2 mt-2">
                <div className="w-full">
                  <div className="mb-3">
                    <InputForm
                      name={`company_name`}
                      placeholder="Company Name"
                      onChange={(e) => handleInputChange(e)}
                      value={generalSetings?.company_name}
                      type="text"
                    />
                  </div>
                </div>

                <div className="w-full  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="sender_identity"
                      placeholder="Sender Identity"
                      onChange={(e) => handleInputChange(e)}
                      value={generalSetings?.sender_identity}
                      type="text"
                    />
                  </div>
                </div>

                <div className="w-full  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="notification_email"
                      placeholder="Notification Email"
                      onChange={(e) => handleInputChange(e)}
                      value={generalSetings?.notification_email}
                      type="text"
                    />
                  </div>
                </div>

                <div className="w-full  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="bcc_email"
                      placeholder="BCC Email"
                      onChange={(e) => handleInputChange(e)}
                      value={generalSetings?.bcc_email}
                      type="text"
                    />
                  </div>
                </div>

                <div className="w-full  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="company_url"
                      placeholder="Company URL"
                      onChange={(e) => handleInputChange(e)}
                      value={generalSetings?.company_url}
                      type="text"
                    />
                  </div>
                </div>

                <div className="w-full  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="company_address"
                      placeholder="Company Address"
                      onChange={(e) => handleInputChange(e)}
                      value={generalSetings?.company_address}
                      type="text"
                    />
                  </div>
                </div>

                <div className="w-full  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="office_number"
                      placeholder="Office Number"
                      onChange={(e) => handleInputChange(e)}
                      value={generalSetings?.office_number}
                      type="text"
                    />
                  </div>
                </div>

                <div className="w-full  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="anti_theft_email"
                      placeholder="Anti Theft Email"
                      onChange={(e) => handleInputChange(e)}
                      value={generalSetings?.anti_theft_email}
                      type="text"
                    />
                  </div>
                </div>
                <div className=" w-full md:mt-md-2">
                  <div className="mb-3">
                    <SelectForm
                      name="agent_logout_session"
                      options={[
                        { label: "0 minutes", value: "0" },
                        { label: "5 minutes", value: "5" },
                        { label: "10 minutes", value: "10" },
                        { label: "15 minutes", value: "15" },
                        { label: "20 minutes", value: "20" },
                        { label: "25 minutes", value: "25" },
                        { label: "30 minutes", value: "30" },
                      ]}
                      label="Agent Logout Session"
                      type="text"
                      labelProp="label"
                      valueProp="value"
                      value={generalSetings?.agent_logout_session}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                </div>
                <div className="w-full  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="doing_business_as"
                      placeholder="Doing Business As"
                      onChange={(e) => handleInputChange(e)}
                      value={generalSetings?.doing_business_as}
                      type="text"
                    />
                  </div>
                </div>
                <div className="w-full  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="change_password_days"
                      placeholder="Change Password Days"
                      onChange={(e) => handleInputChange(e)}
                      value={generalSetings?.change_password_days}
                      type="text"
                    />
                  </div>
                </div>
                <div className="w-full  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="qa_email"
                      placeholder="QA Email"
                      onChange={(e) => handleInputChange(e)}
                      value={generalSetings?.qa_email}
                      type="text"
                    />
                  </div>
                </div>
                <div className="w-full  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="max_users"
                      placeholder="Maximum no of Users"
                      onChange={(e) => handleInputChange(e)}
                      value={generalSetings?.max_users}
                      type="text"
                    />
                  </div>
                </div>
                <div className="w-full  md:mt-md-2">
                  <div className="mb-3">
                    <SelectForm
                      name="manuall_calling_through"
                      options={[
                        { label: "SIP", value: "sip" },
                        { label: "Custom", value: "custom" },
                      ]}
                      label="Manual Calling through"
                      type="text"
                      labelProp="label"
                      valueProp="value"
                      value={generalSetings?.manuall_calling_through}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                </div>
                <div className="w-full  md:mt-md-2">
                  <div className="mb-3">
                    <InputForm
                      name="recording_url"
                      placeholder="Recording URL"
                      onChange={(e) => handleInputChange(e)}
                      value={generalSetings?.recording_url}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-span-6">
                  <Button
                    text="Save"
                    variant="btn_submit"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </details>
  );
}

export default GeneralSetting;
