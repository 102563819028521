import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  create_job_status,
  update_job_status,
} from "../../features/jobStatusSlice";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";

const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.jobStatus);
  const { record } = useSelector((state) => state.systemJobStatus);

  const [errors, setErrors] = React.useState([]);

  let initialValues = {
    name: "",
    systemJobStatusId: "",
  };

  if (editingRecord) {
    const { _id, name, systemJobStatusId } = editingRecord;
    initialValues = {
      id: _id,
      name,
      systemJobStatusId: systemJobStatusId?._id,
    };
  }

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    // validationSchema: JobStatusSchema,

    onSubmit: async (values) => {
      if (!isLoading) {
        if (!editingRecord) {
          try {
            const res = await dispatch(create_job_status(values));
            if (res?.payload?.status === 200 || res?.payload?.status === 201) {
              toast.success("Job Status created");
              onCancelForm();
            } else {
              errorMessage({
                payload: res.payload,
                setErrors: setErrors,
                action: "Job Status",
                msg: "created",
              });
            }
          } catch (error) {
            toast.error("Job Status couldn't be created");
          }
        } else {
          try {
            const res = await dispatch(update_job_status(values));
            if (res?.payload?.status === 200 || res?.payload?.status === 201) {
              toast.success("Job Status updated");
              onCancelForm();
            } else {
              errorMessage({
                payload: res.payload,
                setErrors: setErrors,
                action: "Job Status",
                msg: "updated",
              });
            }
          } catch (error) {
            toast.error("Job Status couldn't be updated");
          }
        }
      } else {
        toast.error("Please wait until previous action be completed");
      }
    },
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="mt-4">
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="systemJobStatusId"
              label="System Job Status"
              formik={formik}
              options={record}
              valueProp="_id"
              labelProp="name"
            />
          </div>
          <FormInput errors={errors} name="name" label="Name" formik={formik} />
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;
