import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import PageHeader from "../../../components/molecules/PageHeader";
import { get_geo_industry } from "../../../features/GeoIndustrySlice";
import api from "../../../services/api";
import { Button } from "../../../components";
import { toast } from "react-toastify";
import AliasListModal from "./AliasList";

const MultipleDomain = () => {
  const dispatch = useDispatch();
  const [domains, setDomains] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [cityAlias, setCityAlias] = useState([]);
  const industry = useSelector((state) => state.geoIndustry);
  useEffect(() => {
    dispatch(get_geo_industry());
    // eslint-disable-next-line
  }, []);
  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    const totalItems = data.domains?.length;
    if (data?.domains.length > 1) {
      const tagHeight = 25; // height of each tag
      return rowHeight + tagHeight * totalItems + cellContentHeight;
    } else {
      return rowHeight;
    }
  };
  const getMultipleDomains = async (data) => {
    setIsLoader(true);
    try {
      const res = await api.post(
        `/api/domains/multiple_domains_for_city`,
        data
      );
      if (res.status === 200) {
        setDomains(res.data);
      }
      setIsLoader(false);
    } catch (err) {
      console.error(
        "🚀 ~ file: MajorCities.jsx:36 ~ getMajorCities() ~ err:",
        err
      );
      setIsLoader(false);
    }
  };
  const TagsCellRenderer = (data) => {
    const tags = data?.value?.map((tag, index) => (
      <span
        key={index}
        style={{
          backgroundColor: tag.color,
          color: "#000",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
        }}
      >
        {tag}
      </span>
    ));

    return (
      <div style={{ display: "flex", flexWrap: "wrap", width: "auto" }}>
        {tags}
      </div>
    );
  };
  const record = domains?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const columnDefs = [
    { headerName: "#", field: "counter", width: 60 },
    { headerName: "Total", field: "total", flex: 1, minWidth: 150 },
    { headerName: "Location", field: "location", flex: 1, minWidth: 150 },
    { headerName: "State", field: "state", flex: 1, minWidth: 150 },
    {
      headerName: "City Alias",
      field: "city_alias",
      flex: 1,
      minWidth: 150,
      wrap: true,
      cellClassName: "multiline-cell",
      renderCell: (params) => (
        <div className="flex justify-center items-cente w-full flex-col">
          {params?.row?.city_alias}
          {params?.row?.records?.city_alias?.split(",")?.length > 3 ? (
            <span
              className="text-blue-600 cursor-pointer"
              onClick={() =>
                setCityAlias(params?.row?.records?.city_alias?.split(","))
              }
            >
              See More
            </span>
          ) : null}
        </div>
      ),
    },

    {
      headerName: "Domains",
      field: "domains",
      flex: 1,
      renderCell: TagsCellRenderer,
      minWidth: 150,
    },
  ];
  const handleCopyDomains = () => {
    // Extract domains and remove duplicates
    const allDomains = domains.map((item) => item.domains).join(",");
    // Copy to clipboard
    navigator.clipboard
      .writeText(allDomains)
      .then(() => toast.success("Domains copied to clipboard!"))
      .catch((err) => console.error("Failed to copy domains: ", err));
  };
  return (
    <>
      <PageHeader route="Setting > Vendors" heading="Multiple Domains Report" />
      {cityAlias?.length ? (
        <AliasListModal
          onCancelForm={() => setCityAlias(null)}
          data={cityAlias}
        />
      ) : null}
      <div className="items-center !m-2 bg-slate-50 !my-2 border rounded p-3">
        <div className="flex flex-row items-center">
          <div className="flex flex-col w-full ml-2">
            <label className="inputLabel" htmlFor="industries">
              {"Industry"}
            </label>
            <Select
              name="industry"
              placeholder="Select Industry"
              onChange={(e) => {
                getMultipleDomains({ industry_id: e.id });
              }}
              options={industry?.record}
              valueKey="id"
              labelKey="name"
              className="w-full"
              menuPosition="fixed"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
          </div>
        </div>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={record?.map((record, index) => {
            let counter = index + 1;
            const { total, state, location, domains, city_alias } = record;
            return {
              counter,
              records: record,
              total,
              state,
              location,
              city_alias: city_alias
                ?.split(",")
                ?.slice(0, 3)
                ?.toString()
                ?.replaceAll(",", ", "),
              domains: domains?.split(","),
            };
          })}
          totalItems={domains?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoader}
          paginationMode={"client"}
          gridOptions={getRowHeight}
          LineOne={() => (
            <Button
              text={"Copy all Domains"}
              variant="btn_submit"
              onClick={handleCopyDomains}
            />
          )}
        />
      </div>
    </>
  );
};

export default MultipleDomain;
