import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createGoogleSearchConsole,
  deleteGoogleSearchConsole,
  getGoogleSearchConsole,
  updateGoogleSearchConsole,
} from "../services/googleConsoleService";

const initialState = {
  isError: false,
  isLoading: false,
  searchConsole: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Google Seach Console
export const get_google_console = createAsyncThunk(
  "get_google_console",
  async (thunkAPI) => {
    try {
      return await getGoogleSearchConsole();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Google Search Console
export const create_google_console = createAsyncThunk(
  "create_google_console",
  async (data, thunkAPI) => {
    try {
      return await createGoogleSearchConsole(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Google Search Console
export const update_google_console = createAsyncThunk(
  "update_google_console",
  async (data, thunkAPI) => {
    try {
      return await updateGoogleSearchConsole(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Google Search Console
export const delete_google_console = createAsyncThunk(
  "delete_google_console",
  async (id, thunkAPI) => {
    try {
      return await deleteGoogleSearchConsole(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const googleConsoleSlice = createSlice({
  name: "googleConsole",
  initialState,
  reducers: {
    googleConsoleReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.searchConsole = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_google_console.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_google_console.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchConsole = action.payload?.data;
      })
      .addCase(get_google_console.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.searchConsole = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_google_console.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_google_console.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchConsole?.push(action.payload.data);
      })
      .addCase(create_google_console.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_google_console.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_google_console.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.searchConsole?.findIndex(
          ({ id }) => id === action?.payload?.data?.id
        );
        state.searchConsole[result] = {
          ...state.searchConsole[result],
          ...action.payload.data,
        };
      })
      .addCase(update_google_console.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_google_console.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_google_console.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchConsole=state.searchConsole?.filter(({id})=>id!==+action.payload.data);
      })
      .addCase(delete_google_console.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { googleConsoleReset } = googleConsoleSlice.actions;
export default googleConsoleSlice.reducer;
