import React from "react";

import {
  DotsLoader,
  FormInput,
  Modal,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { create_tasks, update_tasks } from "../../features/tasksSlice";
import errorMessage from "../../util/errorMessage";

const TaskForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.tasks);
  const [errors, setErrors] = React.useState([]);

  let initialValues = {
    name: "",
    description: "",
  };

  if (editingRecord) {
    const { _id, name, description } = editingRecord;
    initialValues = {
      _id,
      name,
      description,
    };
  }
  const formik = useFormik({
    initialValues,

    validateOnBlur: true,

    // validationSchema: TaskSchema,
    onSubmit: async (values) => {
      if (!editingRecord) {
        try {
          const res = await dispatch(create_tasks(values));
          if (res?.payload?.status === 200) {
            toast.success("Task created");
            onCancelForm();
          } else {
            errorMessage({payload:res.payload, setErrors:setErrors, action:"Task", msg:'created'})
          }
        } catch (error) {
          toast.error("Task couldn't be created");
        }
      } else {
        try {
          const res = await dispatch(update_tasks(values));
          if (res?.payload?.status === 200) {
            toast.success("Task updated");
            onCancelForm();
          } else {
            errorMessage({payload:res.payload, setErrors:setErrors, action:"Task", msg:'update'})
          }
        } catch (error) {
          toast.error("Task couldn't be updated");
        }
      }
    },
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form>
          <div className="!mb-3">
          <FormInput errors={errors} name="name" label="Name" formik={formik} />

          </div>
          <FormInput errors={errors} name="description" label="Description" formik={formik} />

        </form>
      </Modal>
    </>
  );
};

export default TaskForm;
