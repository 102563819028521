import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../../components";
import api from "../../../services/api";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import errorMessage from "../../../util/errorMessage";
import { get_alternate_domains_phone } from "../../../features/domainCenterSlice";
import * as Yup from "yup";

const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.domainCenter);
  const [errors, setErrors] = React.useState([]);

  let initialValues = {
    areaCode: "",
    alternate_code: "",
    isActive: true,
  };

  if (editingRecord) {
    const { _id, areaCode, alternate_code, isActive } = editingRecord;
    initialValues = {
      id: _id,
      areaCode,
      alternate_code,
      isActive,
    };
  }
  const validationSchema = Yup.object({
    areaCode: Yup.string().required("Area Code is required"),
    alternate_code: Yup.number().required("Alternate Code is required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (!editingRecord) {
        try {
          const res = await api.post("/api/alternate_areacodes", values);
          if (res.status === 201) {
            toast.success("Alternate Area Code created");
            dispatch(get_alternate_domains_phone());
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Alternate Area Code",
              msg: "created",
            });
          }
        } catch (error) {
          toast.error("Alternate Area Code couldn't be created");
        }
      }
    },
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="mt-4">
          <div className="!mb-3">
            <FormInput
              errors={errors}
              name="areaCode"
              label="Area Code"
              formik={formik}
            />
          </div>

          <div className="!mb-3">
            <FormInput
              errors={errors}
              name="alternate_code"
              label="Alternate Code"
              formik={formik}
            />
          </div>
          <div className="!mb-3">
            <FormSelectInput
              errors={errors}
              name="isActive"
              label="Active"
              formik={formik}
              options={[
                { value: "true", label: "Yes" },
                { value: "false", label: "No" },
              ]}
              valueProp="value"
              labelProp="label"
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;
