import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../../components";
import {
  get_audio_files,
  update_call_menu,
} from "../../../features/callCentersSlice";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { update_inbound_group } from "../../../features/inboundGroupSlice";
function RouteDetailView({ data, onCancelForm, modalTitle, callManu }) {
  const [queuePriority, setQueuePriority] = useState();
  const { audioFiles } = useSelector((state) => state.callCenters);
  const { record, isLoading } = useSelector((state) => state.inbound);
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = React.useState([]);
  const [formikData, setFormikData] = React.useState({});

  const initialValues = {
    group_name: "",
    group_color: "Green",
    active: "Y",
    queue_priority: "0",
    drop_call_seconds: "3600",
    icbq_call_time_id: "24hours",
    ...(data && {
      id: data.group_id,
      group_name: data.group_name,
      group_color:
        data?.group_color?.toLowerCase() === "green"
          ? "#008000"
          : data.group_color,
      active: data.active,
      queue_priority: data.queue_priority,
      drop_call_seconds: data.drop_call_seconds,
      icbq_call_time_id: data.icbq_call_time_id,
    }),
  };
  const initialMenuValues = {
    menu_id: "",
    menu_name: "",
    menu_prompt: "",
    menu_timeout_prompt: "",
    menu_timeout: "",
    menu_invalid_prompt: "",
    menu_repeat: "",
    ...(data &&
      data.type === "Call Menu" && {
        menu_id: data?.menu_id,
        menu_name: data?.menu_name ? data?.menu_name : "",
        menu_prompt: data?.menu_prompt ? data?.menu_prompt : "",
        menu_timeout_prompt: data?.menu_timeout_prompt
          ? data?.menu_timeout_prompt
          : "",
        menu_timeout: data?.menu_timeout ? data?.menu_timeout : "",
        menu_invalid_prompt: data?.menu_invalid_prompt
          ? data?.menu_invalid_prompt
          : "",
        menu_repeat: data?.menu_repeat ? data?.menu_repeat : "",
      }),
  };
  const handleSubmit = async (values) => {
    const action =
      data?.type === "Inbound Group" ? update_inbound_group : update_call_menu;
    const message = data?.type === "Inbound Group" ? "In Group" : "Call Menu";
    const payload =
      data?.type === "Inbound Group"
        ? values
        : { ...values, menu_options: selectedOptions };
    try {
      const res = await dispatch(action(payload));
      if (res.payload?.status === 200) {
        toast.success(`${message} successfully updated`);
        onCancelForm();
      } else {
        if (Array.isArray(res.payload)) {
          setErrors(res.payload);
        } else {
          toast.error(
            res.payload ? res.payload : `${message} couldn't be updated`
          );
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload)) {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload ? error.payload : `${message} couldn't be updated`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues:
      data?.type === "Inbound Group"
        ? initialValues
        : { ...formikData, ...initialMenuValues } || initialMenuValues,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });
  useEffect(() => {
    const priority = [];
    for (let i = 99; i >= -99; i--) {
      const label =
        i === 0
          ? `${i} - Even`
          : i > 0
          ? `${i} - Higher`
          : i < 0
          ? `${i} - Lower`
          : "";
      priority.push({ label, value: i });
    }
    setQueuePriority(priority);
  }, []);
  const getCallMenuDetail = async () => {
    setLoader(true);
    try {
      const res = await api.get(
        `/api/cc/dids/options/call_menu/${data?.menu_id}`
      );
      if (res.status === 200) {
        setSelectedOptions(res.data?.call_menu_options);
        setFormikData(res?.data?.call_menu[0]);
      }
    } catch (err) {
      console.log("🚀 ~ getCallMenuDetail ~ err:", err);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (data?.type === "Call Menu") {
      dispatch(get_audio_files());
      getCallMenuDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal
      title={modalTitle}
      onCancelModal={onCancelForm}
      onClick={onCancelForm}
      onSubmit={formik.handleSubmit}
    >
      {loader || isLoading ? <DotsLoader /> : null}
      {data?.type === "Inbound Group" ? (
        <div className="grid md:grid-cols-4 gap-x-5 mt-5 mx-2.5">
          <div className="mb-3">
            <FormInput
              name="group_name"
              label="Group Name"
              formik={formik}
              errors={errors}
            />
          </div>
          <div className="mb-3">
            <FormInput
              name="group_color"
              label="Group Color"
              formik={formik}
              errors={errors}
              type={"color"}
            />
          </div>
          <div className="mb-3">
            <FormSelectInput
              name="active"
              label="Active"
              options={[
                { value: "Y", label: "Y" },
                { value: "N", label: "N" },
              ]}
              valueProp="value"
              labelProp="label"
              formik={formik}
              errors={errors}
            />
          </div>
          <div className="mb-3">
            <FormSelectInput
              name="queue_priority"
              label="Queue Priority"
              options={queuePriority}
              valueProp="value"
              labelProp="label"
              formik={formik}
              errors={errors}
            />
          </div>
          <div className="mb-3">
            <FormInput
              name="drop_call_seconds"
              label="Drop Call Seconds"
              formik={formik}
              errors={errors}
            />
          </div>
        </div>
      ) : (
        <div className="bg-white my-3 border rounded">
          <div className="grid md:grid-cols-3 gap-3 mt-3 mx-2.5">
            <div className="">
              <FormInput
                name="menu_name"
                label="Menu Name"
                formik={formik}
                errors={errors}
              />
            </div>
            <div className="">
              <FormSelectInput
                name="menu_prompt"
                label="Menu Prompt"
                options={audioFiles}
                valueProp="audio_filename"
                labelProp="real_file_name"
                formik={formik}
                errors={errors}
              />
            </div>
            <div className="">
              <FormSelectInput
                name="menu_timeout_prompt"
                label="Menu Timeout Prompt"
                options={audioFiles}
                valueProp="audio_filename"
                labelProp="real_file_name"
                formik={formik}
                errors={errors}
              />
            </div>
            <div className="">
              <FormInput
                name="menu_timeout"
                label="Menu Timeout"
                formik={formik}
                errors={errors}
              />
            </div>
            <div className="">
              <FormSelectInput
                name="menu_invalid_prompt"
                label="Menu Invalid Prompt"
                options={audioFiles}
                valueProp="audio_filename"
                labelProp="real_file_name"
                formik={formik}
                errors={errors}
              />
            </div>
            <div className="">
              <FormInput
                name="menu_repeat"
                label="Menu Repeat"
                formik={formik}
                errors={errors}
              />
            </div>
          </div>
          <div className="w-full flex flex-row items-center justify-between !mt-1 p-2 text-xl text- font-medium border-b">
            <span>Call Menu Options</span>
            <span>Total Records: {selectedOptions?.length}</span>
          </div>
          <div className="grid grid-cols-2 gap-3 p-2 max-h-[52vh]">
            {selectedOptions?.map((item, index) => {
              const { option_value, option_route, option_route_value } = item;
              return (
                <div
                  className="flex flex-row justify-between items-center shadow-sm p-2"
                  key={index}
                >
                  <span className="font-semibold text-base text-menu">{`${option_value} - ${option_route}: ${
                    option_route === "CALLMENU"
                      ? callManu?.find(
                          ({ menu_id }) => menu_id === option_route_value
                        )?.menu_name
                      : option_route === "INGROUP"
                      ? record?.find(
                          ({ group_id }) => group_id === option_route_value
                        )?.group_name
                      : option_route_value
                  }`}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Modal>
  );
}

export default RouteDetailView;
