import api from "./api";

export const getDomainsReport = async (data) => {
  return await api.get(
    "https://domainchecker.ecommcube.com/api/domain_checker/list_domains_data",
    { params: data }
  );
};
export const generateReport = async () => {
  return await api.post(
    `https://domainchecker.ecommcube.com/api/domain_checker/add_domains_in_queue`,
  );
};
export const regenerateReport = async (domains) => {
  return await api.post(
    `https://domainchecker.ecommcube.com/api/domain_checker/add_down_domains_in_queue`,
    domains
  );
};

export const updateDomainsCloudflare = async (domain) => {
  return await api.post(`/api/domains/update_domain_cloudflare_account`, domain);
};