import React, { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import AddHours from "./AddHours";
import {
  get_service_hours,
  delete_service_hours,
} from "../../../features/nationSitesSlice";
import { DotsLoader } from "../../../components";
import PageHeader from "../../../components/molecules/PageHeader";
import { toast } from "react-toastify";
import errorMessage from "../../../util/errorMessage";
const ServiceHours = () => {
  const { isLoading, serviceHours } = useSelector((state) => state.nation);
  console.log("👊 ~ ServiceHours ~ serviceHours:", serviceHours);
  const [editingRecord, setEditingRecord] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(get_service_hours());
    // eslint-disable-next-line
  }, []);

  const openFormHandler = (item) => () => {
    setEditingRecord(item);
    setShowModal(true);
  };
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_service_hours(record_id));
        if (res?.payload?.status === 200) {
          dispatch(get_service_hours());
          toast.success("Service Hours deleted");
        } else {
          errorMessage({
            payload: res.payload,
            action: "Service Hours",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          action: "Service Hours",
          msg: "deleted",
        });
      }
    }
  };
  return (
    <>
      {showModal && (
        <AddHours
          editingRecord={editingRecord}
          // modalTitle="Search Log History"
          onCloseModal={() => setShowModal(false)}
        />
      )}
      {isLoading && <DotsLoader text="Please Wait" />}

      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
        <PageHeader
          onClick={openFormHandler(0)}
          isAllowed={true}
          heading="Service Hours"
        />
        <div className="bg-white !my-3 border rounded !p-5">
          <div class="grid grid-cols-1 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-5">
            {serviceHours?.records?.map((item, index) => {
              return (
                <div className="bg-white rounded-lg overflow-hidden mr-2 shadow-md col-span-1">
                  <div className="!px-1 h-auto ">
                    <span className="flex items-end justify-between w-full py-3">
                      <span className="text-red-500 text-medium font-semibold flex items-center">
                        Sr. <span className="ml-1">{index + 1}</span>
                      </span>
                      <div className="flex flex-row">
                        <FaEdit
                          onClick={openFormHandler(item)}
                          className="my_navIcon cursor-pointer"
                          size={16}
                        />
                        <FaTrashAlt
                          onClick={deleteRecordHandler(item._id)}
                          className="my_navIcon"
                          title="Delete Service Hours"
                          size={18}
                        />
                      </div>
                    </span>

                    {item?.hours !== undefined &&
                      item?.hours?.map(({ day, opens, closes }) => {
                        return (
                          <div className="flex flex-wrap items-start justify-between">
                            <div className="text-base font-medium px-2 !py-0.5 w-auto capitalize">
                              {day}
                            </div>
                            <div className="text-sm text-gray-700 px-2 py-1.5 w-auto text-start">
                              {`${opens} ${opens && closes && " - "} ${closes}`}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceHours;
