import React, { useState, useEffect } from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FaInfoCircle } from "react-icons/fa";
import api from "../../services/api";
import errorMessage from "../../util/errorMessage";
import * as Yup from "yup";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { getRunnerDRD } from "../../services/domainRunnerService";
const UpdateDNSModal = ({
  onCancelForm,
  modalTitle,
  selectedData,
  apiSlug,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [showMore, setShowMore] = useState(false);
  const [runners, setRunners] = useState([]);
  useEffect(() => {
    const fetchRunners = async () => {
      try {
        const runnerData = await getRunnerDRD();
        setRunners(runnerData.data);
      } catch (error) {
        toast.error("Failed to fetch runners");
      }
    };

    fetchRunners();
  }, []);
  const runnerOptions = runners.map((runner) => ({
    value: runner._id,
    label: `${runner.runner_name} | ${runner.ip}`,
  }));
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const initialValues = {
    ...(apiSlug === "update_dns_serverip" && { dns_server_ip: "" }),
    ...(apiSlug === "update_domains_runner" && { runner_id: "" }),
  };

  const handleSubmit = async (values) => {
    let apiUrl;
    let successMessage;
    let errorAction;
    if (apiSlug === "update_dns_serverip") {
      apiUrl = "/api/domains/update_DNS_IP";
      successMessage = "Domains successfully updated with DNS server.";
      errorAction = "DNS server IP";
    } else if (apiSlug === "update_domains_runner") {
      apiUrl = "/api/domains/update_domain_runner";
      successMessage = "Domains runner successfully updated.";
      errorAction = "Domains runner";
    } else {
      throw new Error("Invalid Method");
    }
    try {
      setIsLoading(true);

      const payload = {
        domains: selectedData.map((item) => item.domain),
        ...(apiSlug === "update_dns_serverip" && {
          DNSServerIP: values?.dns_server_ip,
        }),
        ...(apiSlug === "update_domains_runner" && {
          domain_runner_id: values?.runner_id,
        }),
      };

      const res = await api.post(apiUrl, payload);

      if (res.status === 200 || res.status === 201) {
        toast.success(
          apiSlug === "update_dns_serverip"
            ? `${res.data?.modifiedCount} ${successMessage}`
            : successMessage
        );
        onCancelForm();
      } else {
        errorMessage({
          payload: res.payload,
          setErrors: setErrors,
          action: errorAction,
          msg: "update",
        });
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if (err?.response?.data?.error) {
        errorMessage({
          payload: err.response.data.error,
          setErrors: setErrors,
          action: errorAction || "Unknown action",
          msg: "update",
        });
      } else {
        toast.error(err?.response?.data || "An unknown error occurred.");
        return;
      }
      console.log(err);
    }
  };

  const validationSchema = Yup.object({
    ...(apiSlug === "update_dns_serverip" && {
      dns_server_ip: Yup.string().required("DNS Server IP is required"),
    }),
    ...(apiSlug === "update_domains_runner" && {
      runner_id: Yup.string().required("Domain's Runner is required"),
    }),
  });

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validationSchema,
    onSubmit: handleSubmit,
  });
  return (
    <Modal
      title={modalTitle}
      onCancelModal={onCancelForm}
      formIsValid={formik.isValid}
      onSubmit={formik.handleSubmit}
      isLoading={isLoading}
      onClick={onCancelForm}
    >
      {isLoading ? <DotsLoader /> : null}
      <div
        className="flex items-center p-4 mb-4 text-lg text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800"
        role="alert"
      >
        <FaInfoCircle />
        <div>
          <span className="font-medium ml-2">
            You have selected ({selectedData?.length}) domains.
          </span>
        </div>
      </div>
      <div className="flex p-0 mt-0">
        <div
          className={`flex-1 bg-gray-100 p-2 shadow w-full`}
          style={{ maxHeight: "calc(50vh - 2rem)" }}
        >
          <span
            className="ml-2 text-yellow-800 cursor-pointer font-semibold"
            onClick={toggleShowMore}
          >
            {showMore ? (
              <>
                <IoIosArrowDown className="cursor-pointer mt-1 mr-1" />
                Hide Domains..
              </>
            ) : (
              <>
                <IoIosArrowForward className="cursor-pointer mt-1" />
                Show Domains..
              </>
            )}
          </span>
          {showMore && (
            <div
              className="overflow-y-auto border-t"
              style={{ maxHeight: "calc(40vh - 2rem - 2.5rem)" }}
            >
              {selectedData
                ?.map((item) => item?.domain)
                ?.map((item, index) => (
                  <div key={index} className="mb-1 text-blue-600">
                    {item}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <form className="mt-4">
        <div className="!mb-3">
          {apiSlug === "update_dns_serverip" ? (
            <FormInput
              errors={errors}
              name="dns_server_ip"
              label="DNS Server IP"
              formik={formik}
            />
          ) : null}
          {apiSlug === "update_domains_runner" ? (
            <FormSelectInput
              errors={errors}
              name="runner_id"
              label="Runner"
              formik={formik}
              options={runnerOptions}
              valueProp="value"
              labelProp="label"
            />
          ) : null}
        </div>
      </form>
    </Modal>
  );
};

export default UpdateDNSModal;
