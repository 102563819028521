import React, { useEffect, useState } from "react";
import ToggleSwitch from "../../../components/molecules/ToggleButton";
import FormInput from "../Common/FormInput";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { BiRefresh } from "react-icons/bi";
import { useSelector } from "react-redux";
import { FaCirclePlus, FaCircleMinus } from "react-icons/fa6";
const libraries = ["places", "drawing", "geometry"];

const LocationSection = ({ formik, handleSendLocationForm }) => {
  const { record } = useSelector((state) => state.jobCategory);
  const isDroped = record?.records?.find(
    (service) => service._id === formik?.values?.job_category_id
  )?.drop_location_required;
  const [autoAddress, setAutoAddress] = useState(isDroped);
  const [isCustomDistance, setIsCustomDistance] = useState(false);
  const [isAddress2, setIsAddress2] = useState(false);
  const [customDistance, setCustomDistance] = React.useState("");
  const [autocompleteInstances, setAutocompleteInstances] = useState({
    pickup: null,
    drop: null,
    drop_2: null,
  });
  useEffect(() => {
    setAutoAddress(isDroped);
  }, [isDroped]);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_API,
    libraries: libraries,
  });

  const onLoad = React.useCallback((prefix, autocompleteInstance) => {
    setAutocompleteInstances((prevInstances) => ({
      ...prevInstances,
      [prefix]: autocompleteInstance,
    }));
  }, []);
  async function calculateRoute() {
    const {
      pickup_latitude,
      pickup_longitude,
      drop_latitude,
      drop_longitude,
      drop_2_latitude,
      drop_2_longitude,
    } = formik.values;

    if (
      !pickup_latitude ||
      !pickup_longitude ||
      !drop_latitude ||
      !drop_longitude
    ) {
      formik.setFieldValue("distance", "");
      return;
    }

    if (!isLoaded) {
      console.warn("Google Maps API is not loaded yet.");
      return;
    }

    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();

    // Calculate distance from Pickup to Drop
    const route1 = await directionsService.route({
      origin: `${pickup_latitude}, ${pickup_longitude}`,
      destination: `${drop_latitude}, ${drop_longitude}`,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    let totalDistance = route1.routes[0].legs[0].distance.value;

    // If Drop 2 is present, calculate distance from Drop to Drop 2 and add it to the total
    if (drop_2_latitude && drop_2_longitude) {
      const route2 = await directionsService.route({
        origin: `${drop_latitude}, ${drop_longitude}`,
        destination: `${drop_2_latitude}, ${drop_2_longitude}`,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      });
      totalDistance += route2.routes[0].legs[0].distance.value;
    }

    // Convert meters to miles and set the value in formik
    formik.setFieldValue("distance", (totalDistance / 1609.34).toFixed(2) || 0);
  }

  React.useEffect(() => {
    if (
      formik?.values?.drop_latitude &&
      formik?.values?.drop_longitude &&
      formik?.values?.pickup_latitude &&
      formik?.values?.pickup_longitude
    ) {
      calculateRoute();
    }
    // eslint-disable-next-line
  }, [
    formik?.values?.pickup_latitude,
    formik?.values?.drop_latitude,
    formik?.values?.drop_2_latitude,
  ]);
  const onChangeAddress = (prefix) => {
    const autocomplete = autocompleteInstances[prefix];
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const lat = place?.geometry?.location.lat();
      const lng = place?.geometry?.location.lng();

      formik.setFieldValue(
        `complete_${prefix}_address`,
        place?.formatted_address
      );
      formik.setFieldValue(`${prefix}_latitude`, lat);
      formik.setFieldValue(`${prefix}_longitude`, lng);

      extractAddress(place, prefix);
    } else {
      console.warn("Autocomplete is not loaded yet!");
    }
  };

  const extractAddress = (place, prefix) => {
    if (!Array.isArray(place?.address_components)) return;

    formik.setFieldValue(`${prefix}_zip`, null);
    formik.setFieldValue(`${prefix}_city`, null);
    formik.setFieldValue(`${prefix}_state`, null);
    formik.setFieldValue(`${prefix}_country`, null);

    place.address_components.forEach((component) => {
      const types = component.types;
      const value = component;

      if (types.includes("postal_code")) {
        formik.setFieldValue(`${prefix}_zip`, value?.long_name);
      }
      if (
        types.includes("locality") ||
        types.includes("administrative_area_level_3")
      ) {
        formik.setFieldValue(`${prefix}_city`, value?.long_name);
      }
      if (types.includes("administrative_area_level_1")) {
        formik.setFieldValue(`${prefix}_state`, value?.short_name);
      }
      if (types.includes("country")) {
        formik.setFieldValue(`${prefix}_country`, value?.long_name);
      }
    });
  };
  const CustomButton = ({ prefix }) => {
    const resetAddress = () => {
      formik.setFieldValue(`${prefix}_zip`, null);
      formik.setFieldValue(`${prefix}_city`, null);
      formik.setFieldValue(`${prefix}_state`, null);
      formik.setFieldValue(`${prefix}_country`, null);
      formik.setFieldValue(`${prefix}_latitude`, null);
      formik.setFieldValue(`${prefix}_longitude`, null);
      formik.setFieldValue(`complete_${prefix}_address`, null);
    };
    return formik.values[`complete_${prefix}_address`] ? (
      <button onClick={resetAddress}>
        <BiRefresh size={25} className="" cursor="pointer" />
      </button>
    ) : null;
  };
  const requiredLocation = [
    "pickup",
    isDroped ? "drop" : null,
    isAddress2 ? "drop_2" : null,
  ].filter(Boolean);
  return (
    <div className="bg-white border px-2 md:px-4 mt-3">
      <div className="pl-2 pt-3 !mb-2 flex justify-between">
        <div className="font-semibold text-lg">Location:</div>
        <div className="flex w-auto gap-x-2">
          <span className="font-semibold mr-">Auto complete address:</span>
          <ToggleSwitch
            checked={autoAddress}
            unique_by="auto_address"
            onChange={() => setAutoAddress(!autoAddress)}
          />
          <div className="flex justify-end space-x-2 text-sm">
            <button
              onClick={() => {
                const prefix="drop_2"
                formik.setFieldValue(`${prefix}_zip`, null);
                formik.setFieldValue(`${prefix}_city`, null);
                formik.setFieldValue(`${prefix}_state`, null);
                formik.setFieldValue(`${prefix}_country`, null);
                formik.setFieldValue(`${prefix}_latitude`, null);
                formik.setFieldValue(`${prefix}_longitude`, null);
                formik.setFieldValue(`complete_${prefix}_address`, null);
                setIsAddress2(false);
              }}
              className="flex items-center justify-center w-24 h-6 text-menu border rounded-sm hover:bg-menu hover:text-white group"
            >
              <FaCircleMinus className="mr-1" />
              <span className="text-menu group-hover:text-white">Remove</span>
            </button>
            {}
            <button
              onClick={() => setIsAddress2(true)}
              className="flex items-center justify-center w-24 h-6 text-primary-100 border rounded-sm text-black hover:bg-primary-100 hover:text-white group"
            >
              <FaCirclePlus className="mr-1" />
              <span className="text-menu group-hover:text-white">Add</span>
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="w-full ">
          <div
            className={`grid ${
              isDroped && isAddress2
                ? "grid-cols-3"
                : isDroped || isAddress2
                ? "grid-cols-2"
                : "grid-cols-1"
            } gap-4 my-2`}
          >
            {requiredLocation?.map((prefix, index) => {
              return (
                <div key={prefix} className="bg-neutral-50 p-2 border shadow">
                  {autoAddress ? (
                    isLoaded && (
                      <Autocomplete
                        onPlaceChanged={() => onChangeAddress(prefix)}
                        onLoad={(autocompleteInstance) =>
                          onLoad(prefix, autocompleteInstance)
                        }
                      >
                        <FormInput
                          name={`complete_${prefix}_address`}
                          type="text"
                          label={`${
                            !isDroped
                              ? ""
                              : prefix === "pickup"
                              ? "Pickup"
                              : prefix === "drop"
                              ? "Drop"
                              : prefix === "drop_2"
                              ? "2nd Drop-Off"
                              : ""
                          } Address: ${index > 0 ? "(Only if needed)" : ""}`}
                          formik={formik}
                          CustomButton={
                            `complete_${prefix}_address` ? CustomButton : null
                          }
                          prefix={prefix}
                        />
                      </Autocomplete>
                    )
                  ) : (
                    <FormInput
                      name={`complete_${prefix}_address`}
                      type="text"
                      label={`${
                        prefix === "pickup"
                          ? "Pickup"
                          : prefix === "drop"
                          ? "Drop"
                          : prefix === "drop_2"
                          ? "2nd Drop-Off"
                          : ""
                      } Address: ${index > 0 ? "(Only if needed)" : ""}`}
                      formik={formik}
                      CustomButton={
                        `complete_${prefix}_address` ? CustomButton : null
                      }
                      prefix={prefix}
                    />
                  )}
                  <div className="grid grid-cols-3 gap-4 mt-2">
                    <FormInput
                      name={`${prefix}_zip`}
                      type="text"
                      placeholder="Zip"
                      formik={formik}
                    />
                    <FormInput
                      name={`${prefix}_city`}
                      type="text"
                      placeholder="City"
                      formik={formik}
                    />
                    <FormInput
                      name={`${prefix}_state`}
                      type="text"
                      placeholder="State"
                      formik={formik}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <button
            className="text-white bg-primary-100 font-semibold hover:underline text-sm shadow py-0.5 px-1 mb-1"
            onClick={handleSendLocationForm}
          >
            Send Location Link
          </button>
          {formik.values.complete_pickup_address &&
          formik.values.complete_drop_address ? (
            <div className="flex justify-between">
              <small className="font-pop">
                {formik?.values?.distance
                  ? `Distance is ${formik?.values?.distance} miles`
                  : ""}
              </small>
              {isCustomDistance ? (
                <div className="flex flex-row gap-x-2 items-centr justify-cente">
                  <input
                    className="bg-transparent border-b border-black"
                    value={customDistance}
                    onChange={(e) => setCustomDistance(e.target.value)}
                    name="distance"
                  />
                  <div className="flex flex-row gap-x-2 items-end">
                    <button
                      onClick={() => {
                        formik.setFieldValue("distance", customDistance);
                        setCustomDistance("");
                        setIsCustomDistance(false);
                      }}
                      className="text-sm text-blue-500"
                    >
                      Done
                    </button>
                    <button
                      onClick={() => {
                        setCustomDistance("");
                        setIsCustomDistance(false);
                      }}
                      className="text-sm text-red-500"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row gap-x-2">
                  <button
                    onClick={() => setIsCustomDistance(true)}
                    type="button"
                    className="text-blue-500"
                  >
                    Want to change the distance?
                  </button>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default LocationSection;
