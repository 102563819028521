import React from "react";
import Stepper from "react-stepper-horizontal";

const Steppers = ({ props }) => {
  const { currentStep, sections } = props;

  return (
    <Stepper
      steps={sections}
      activeStep={currentStep}
      activeColor="#d9d9d9"
      defaultBarColor="#858484"
      completeColor="#5b6eb6"
      completeBarColor="#5b6eb6"
      size={24}
      titleFontSize={12}
      circleFontSize={14}
    />
  );
};

export default Steppers;
