import React, { useState } from "react";
import { DotsLoader, Modal } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import TagsInput from "../../../components/atoms/CustomTagsInput";
import {
  add_spam_numbers,
  get_spam_numbers,
} from "../../../features/callCentersSlice";
function AddUpdateForm({ editingRecord, onCancelForm, modalTitle }) {
  const { isLoading } = useSelector((state) => state.callCenters);
  const dispatch = useDispatch();
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const handleSubmit = async () => {
    const payload = phoneNumbers?.map((phone) => ({
      phone_number: phone,
      group_id: "SPAMDIDS",
    }));
    try {
      const res = await dispatch(add_spam_numbers(payload));
      if (res.payload?.status === 200) {
        toast.success(`Spam number successfully added`);
        dispatch(get_spam_numbers());
        onCancelForm();
      } else {
        if (Array.isArray(res.payload)) {
          toast.error(res.payload[0]);
        } else {
          toast.error(
            res.payload ? res.payload : `Spam number couldn't be added`
          );
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload)) {
        toast.error(error.payload[0]);
      } else {
        toast.error(
          error.payload ? error.payload : `Spam number couldn't be added`
        );
      }
    }
  };

  return (
    <Modal
      isUpdate={!!editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      onClick={onCancelForm}
    >
      {isLoading?<DotsLoader/>:null}
      <div className="grid md:grid-cols-1 gap-x-5 mt-5 mx-2.5">
        <TagsInput
          tags={phoneNumbers}
          setTags={setPhoneNumbers}
          placeholder="Add phone number"
        />
      </div>
    </Modal>
  );
}

export default AddUpdateForm;
