import React, { useState, useEffect } from "react";
import { FormInput } from "../../components";
import CustomModal from "../../components/molecules/Modal/customModal";

function IndustryFormData({
  onCancelForm,
  onSave,
  initialIndustryDetails,
  industryDrd,
  editingRecord,
}) {
  const [industryDetails, setIndustryDetails] = useState(
    initialIndustryDetails
  );
  useEffect(() => {
    setIndustryDetails(initialIndustryDetails);
  }, [initialIndustryDetails]);

  const handleInputChange = (industry_id, field, value) => {
    setIndustryDetails((prevDetails) =>
      prevDetails.map((industry) =>
        industry.industry_id === industry_id
          ? { ...industry, [field]: value }
          : industry
      )
    );
  };

  const handleSave = () => {
    const filteredData = industryDetails?.filter(item => item.port || item.default_domain);
    onSave(filteredData);
    onCancelForm();
  };

  return (
    <CustomModal
      multiple
      handleModal={onCancelForm}
      cancelButtonRef={onCancelForm}
      className="max-w-5xl"
      modalType={true}
      title={editingRecord ? "Edit Record" : "Add Record"}
      onSubmit={(e) => {
        e.preventDefault();
        handleSave();
      }}
    >
      {industryDetails?.length ? (
        <div className="max-h-[300px] overflow-auto">
          {industryDetails.map(
            ({ industry_id, name, port, default_domain }) => {
              const industry_name =
                name ||
                industryDrd.find((option) => option._id === industry_id)?.name;

              return (
                <div key={industry_id} className="p-2 rounded-lg border-b">
                  <div className="flex gap-2">
                    <span className="font-semibold text-lg mb-2 w-[200px] ">
                      {industry_name}
                    </span>
                    <div class="w-[250px]">
                      <FormInput
                        label="Port"
                        value={port}
                        onChange={(e) =>
                          handleInputChange(industry_id, "port", e.target.value)
                        }
                        placeholder="Enter port"
                        readOnly={false}
                      />
                    </div>
                    <div class="w-[450px]">
                      <FormInput
                        label="Default Domain"
                        value={default_domain}
                        onChange={(e) =>
                          handleInputChange(
                            industry_id,
                            "default_domain",
                            e.target.value
                          )
                        }
                        placeholder="Enter default domain"
                        readOnly={false}
                      />
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      ) : null}
    </CustomModal>
  );
}

export default IndustryFormData;
