import React, { useState } from "react";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { FaSearch } from "react-icons/fa";
import { Box, Button, Button as MUIButton } from "@mui/material";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { DotsLoader } from "../../components";
import api from "../../services/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import { toast } from "react-toastify";
import { get_users_drd } from "../../features/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import { get_industry_drd } from "../../features/IndustrySlice";
function VendorBalance({ filters, record = [], setRecord }) {
  const { usersDrd } = useSelector((state) => state.users);
  const { industryDrd } = useSelector((state) => state.industry);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedData, setSelectedData] = React.useState([]);
  const dispatch = useDispatch();
  dayjs.extend(relativeTime);
  dayjs.extend(utc);

  // Function to get ISO week number for a date
  const getISOWeek = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const yearStart = new Date(d.getFullYear(), 0, 1);
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  };
  const [selectedWeek, setSelectedWeek] = useState(getISOWeek(new Date()));
  // Function to get date range for a week
  const getDateRangeForWeek = (year, week) => {
    const startDate = new Date(year, 0, (week - 1) * 7 + 1);
    const endDate = new Date(year, 0, week * 7, 23, 59, 59, 59);
    return { startDate, endDate };
  };

  // Function to generate weeks of the year along with date ranges
  const generateWeeks = (year) => {
    const weeks = [];
    for (let week = 1; week <= 53; week++) {
      const { startDate, endDate } = getDateRangeForWeek(year, week);
      weeks.push({
        weekName: `Week ${week}: ${startDate.toDateString()} - ${endDate.toDateString()}`,
        weekNumber: week,
        startDate,
        endDate,
      });
    }
    return weeks;
  };

  //   const handleWeekChange = (e) => {
  //     const weekNumber = parseInt(e);
  //     setSelectedWeek(weekNumber);
  //     const selectedWeekData = weeksOfYear.find(
  //       (week) => week.weekNumber === weekNumber
  //     );
  //     setFilters({
  //       ...filters,
  //       start_date: selectedWeekData.startDate.toISOString().slice(0, 23) + "Z",
  //       end_date: selectedWeekData.endDate.toISOString().slice(0, 23) + "Z",
  //     });
  //     get_all_jobs({
  //       ...paginationModel,
  //       ...sortingModel,
  //       size: paginationModel.pageSize,
  //       filters: queryOptions,
  //       start_date: selectedWeekData.startDate.toISOString().slice(0, 23) + "Z",
  //       end_date: selectedWeekData.endDate.toISOString().slice(0, 23) + "Z",
  //     });
  //   };

  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const get_all_jobs = async (filter) => {
    setIsLoading(true);
    if (!filter?.filters?.rules?.length) {
      delete filter.filters;
    }
    delete filter?.pageSize;
    try {
      const res = await api.post("/api/reports/vendor_balance_report", {
        ...filter,
      });
      if (res.status === 200) {
        const dataArray = Object.entries(
          res.data?.data ? res.data?.data : {}
        ).map(([key, value]) => ({
          id: key, // Assuming you want to keep the original keys as IDs
          ...value,
        }));
        const sortedDataArray = [...(dataArray || [])]?.sort((a, b) =>
          a?.vendor?.first_name.localeCompare(b?.vendor?.first_name)
        );
        const rows = sortedDataArray?.flatMap((entry) => {
          const vendorName = `${entry.vendor?.first_name || ""} ${
            entry.vendor?.last_name || ""
          }`;
          const vendorId = entry.vendor._id;
          const summary = entry.summary;

          const vendorRow = {
            id: vendorId,
            path: [vendorName],
            ...entry.vendor, // Include all vendor details
            ...summary,
          };
          const jobs = entry.jobs.map((job) => ({
            id: job._id,
            path: [vendorName, job.job_number],
            ...job, // Include all job details
            summary: { ...summary },
          }));
          return [vendorRow, ...jobs];
        });

        const total_job_amount = dataArray.reduce(
          (sum, item) => sum + item?.summary?.job_amount,
          0
        );
        const total_pending_job_amount = dataArray.reduce(
          (sum, item) => sum + item?.summary?.pending_invoiced,
          0
        );
        const total_invoiced_amount = dataArray.reduce(
          (sum, item) => sum + item?.summary?.total_invoiced,
          0
        );
        const total_vendor_owe = dataArray.reduce(
          (sum, item) => sum + item?.summary?.vendor_owe,
          0
        );
        const total_vendor_profit = dataArray.reduce(
          (sum, item) => sum + item?.summary?.vendor_profit,
          0
        );
        const total_pending_jobs = dataArray.reduce(
          (sum, item) =>
            sum +
            item?.jobs?.filter(({ vendor_invoice_id }) => !vendor_invoice_id)
              ?.length,
          0
        );
        const total_jobs = dataArray.reduce(
          (sum, item) => sum + item?.jobs?.length,
          0
        );
        setRecord({
          data: rows,
          totalItems: res.data.totalItems,
          summary: {
            total_jobs,
            total_pending_jobs,
            total_job_amount,
            total_pending_job_amount,
            total_invoiced_amount,
            total_vendor_owe,
            total_vendor_profit,
          },
        });
      } else {
        toast.error(res.data.message || "Record couldn't be loaded");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.message || "Record couldn't be loaded");
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  React.useEffect(() => {
    dispatch(get_users_drd());
    dispatch(get_industry_drd());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    get_all_jobs({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
      filters: queryOptions,
      ...filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);
  const columnDefs = [
    {
      headerName: "Jobs Total",
      field: "service_cost",
      flex: 1,
      minWidth: 100,
      type: "number",
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <span className="font-bold">{params.row.job_amount?.toFixed(2)}</span>
        ) : (
          <span>{params.row.service_cost?.toFixed(2)}</span>
        ),
    },
    {
      headerName: "Pending Invoiced",
      field: "pending_invoiced",
      flex: 1,
      minWidth: 100,
      type: "number",
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <span className="font-bold">
            {params.row.pending_invoiced?.toFixed(2)}
          </span>
        ) : params.row.vendor_invoice_id === "Yes" ? (
          <span>{0?.toFixed(2)}</span>
        ) : params.row.vendor_invoice_id === "No" ? (
          <span>{params.row.service_cost?.toFixed(2)}</span>
        ) : null,
    },
    {
      headerName: "Invoiced",
      field: "total_invoiced",
      flex: 1,
      minWidth: 100,
      type: "number",
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <span className="font-bold">
            {params.row.total_invoiced?.toFixed(2)}
          </span>
        ) : params.row.vendor_invoice_id === "No" ? (
          <span>{0?.toFixed(2)}</span>
        ) : params.row.vendor_invoice_id === "Yes" ? (
          <span>{params.row.service_cost?.toFixed(2)}</span>
        ) : null,
    },
    {
      headerName: "Part Charge",
      field: "parts_charge",
      flex: 1,
      minWidth: 100,
      type: "number",
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <span className="font-bold">
            {params.row.parts_charge?.toFixed(2)}
          </span>
        ) : (
          <span>{params.row.parts_charge?.toFixed(2)}</span>
        ),
    },
    {
      headerName: "Teach %",
      field: "tech_percent",
      flex: 1,
      minWidth: 100,
      type: "number",
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <span className="font-bold">
            {params.row.techPercent?.toFixed(2)}
          </span>
        ) : (
          <span>
            {(
              (params.row.tech_fee /
                (params.row.service_cost - params.row.parts_charge)) *
              100
            )?.toFixed(2)}
          </span>
        ),
    },
    {
      headerName: "Tech Share",
      field: "tech_fee",
      flex: 1,
      minWidth: 100,
      type: "number",
      renderCell: (params) => {
        return params.rowNode.type === "group" ? (
          <span className="font-bold">
            {params.row.vendor_profit?.toFixed(2)}
          </span>
        ) : (
          <span>{params.row.tech_fee?.toFixed(2)}</span>
        );
      },
    },
    {
      headerName: "Company Share",
      field: "company_profit",
      flex: 1,
      minWidth: 100,
      type: "number",
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <span className="font-bold">{params.row.vendor_owe?.toFixed(2)}</span>
        ) : (
          <span>
            {(
              Math.round(
                params.row.service_cost * 100 - params.row.tech_fee * 100
              ) / 100
            )?.toFixed(2)}
          </span>
        ),
    },
    {
      headerName: "Invoice Created",
      field: "vendor_invoice_id",
      flex: 1,
      type: "singleSelect",
      disableColumnMenu: true,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        vendor_invoice_id: value?.value,
      }),
      filterOperators: [
        {
          label: "Yes",
          value: "!=",
          getApplyFilterFn: (filterItem) => {
            // Assuming vendor_invoice_id is stored as 1 for "Yes" and 0 for "No"
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
        {
          label: "No",
          value: "=",
          getApplyFilterFn: (filterItem) => {
            // Assuming vendor_invoice_id is stored as 1 for "Yes" and 0 for "No"
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
      ],
    },
    {
      field: "createdBy",
      headerName: "Created By",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) =>
        `${value.first_name || ""} ${value.last_name || ""}`,
      valueOptions: usersDrd,
      renderCell: (params) => params.value?.createdBy,
      valueGetter: (params) => params.row?.createdBy,
      valueFormatter: (params) => params.value?.createdBy,
    },
    {
      headerName: "Created At",
      field: "createdAt",
      flex: 1,
      minWidth: 120,
      sortable: true,
      type: "date",
      valueGetter: (params) => new Date(params.row.createdAt),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      renderCell: (params) => {
        const rawDate = params.row.createdAt;
        if (params.rowNode.type !== "group" && rawDate) {
          return rawDate;
        }
        return "";
      },
    },
    { headerName: "Status", field: "job_status_id" },
    {
      headerName: "Scheduled On",
      field: "scheduled_on",
      sortable: true,
      valueGetter: (params) => new Date(params.row.scheduled_on),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      type: "date",
      renderCell: (params) => {
        const rawDate = params.row.scheduled_on;
        if (params.rowNode.type !== "group" && rawDate) {
          return rawDate;
        }
        return "";
      },
    },
    {
      headerName: "Closed",
      field: "is_closed",
      renderCell: (params) =>
        params.rowNode.type === "group" ? null : <span>{params.value}</span>,
      disableColumnMenu: true,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        is_closed: value?.value,
      }),
      filterOperators: [
        {
          label: "Yes",
          value: true,
          getApplyFilterFn: (filterItem) => {
            // Assuming vendor_invoice_id is stored as 1 for "Yes" and 0 for "No"
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
        {
          label: "No",
          value: false,
          getApplyFilterFn: (filterItem) => {
            // Assuming vendor_invoice_id is stored as 1 for "Yes" and 0 for "No"
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
      ],
    },
    {
      headerName: "Closed At",
      field: "closed_date",
      flex: 1,
      minWidth: 120,
      sortable: false,
      valueGetter: (params) => new Date(params.row.closed_date),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      type: "date",
      renderCell: (params) => {
        const rawDate = params.row.closed_date;
        if (params.rowNode.type !== "group" && rawDate) {
          return rawDate;
        }
        return "";
      },
    },
    // { headerName: "Industry", field: "industry_id", flex: 1, minWidth: 100 },
    {
      field: "industry_id",
      headerName: "Industry",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) => value?.name,
      valueOptions: industryDrd,
      renderCell: (params) => params.value?.industry_id,
      valueGetter: (params) => params.row?.industry_id,
      valueFormatter: (params) => params.value?.industry_id,
    },
    {
      headerName: "Client Type",
      field: "payment_type",
      flex: 1,
      minWidth: 120,
    },
  ];
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    get_all_jobs({
      filters: queryOptions,
      ...sortingModel,
      page: +params.page + 1,
      size: params.pageSize,
      ...filters,
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      get_all_jobs({
        ...sortingModel,
        ...filters,
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field === "domain_count" ? "domain" : rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "onOrBefore"
              ? "lte"
              : rule.operator === "before"
              ? "lt"
              : rule.operator === "onOrAfter"
              ? "gte"
              : rule.operator === "after"
              ? "gt"
              : rule.operator,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    get_all_jobs({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      ...filters,
    });
  };

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        {selectedData?.length ? (
          <span className="text-sm font-semibold bg-[#fcebc5] py-1 px-1.5">
            {`${selectedData?.length} items are selected `}
            <span
              className="text-blue-600 hover:underline cursor-pointer ml-2"
              onClick={() => setSelectedData([])}
            >
              Clear
            </span>
          </span>
        ) : null}
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
      </GridToolbarContainer>
    );
  }
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    get_all_jobs({
      filters: queryOptions,
      page: 1,
      ...filters,
      size: paginationModel.pageSize,
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
  };
  const onSelectAll = (checked, vendorId) => {
    if (checked) {
      const vendorJobs = record?.data?.filter(
        ({ vendor_id, vendor_invoice_id }) =>
          vendor_id?._id === vendorId && !vendor_invoice_id
      );
      const updatedArray = vendorJobs?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (job) => {
    if (selectedData?.length > 0) {
      if (selectedData?.filter(({ _id }) => _id === job?._id).length > 0) {
        return true;
      }
    }
    return false;
  };
  const onSingleSelect = ({ event, checked, data }) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      if (checked) {
        if (selectedData?.length) {
          const firstVendorId = selectedData[0].vendor_id._id;
          if (firstVendorId !== data.vendor_id._id)
            return toast.error("Vendor are not the same as pre-selected jobs.");
        }
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
        event.stopPropagation();
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            (job) => job?._id !== data?._id
          );
          return updatedSelectedRecord;
        });
        event.stopPropagation();
      }
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:116 ~ onSingleSelect ~ err:", err);
    }
    event.stopPropagation();
  };
  function CustomGridTreeDataGroupingCell(props) {
    const { id, rowNode, field } = props;
    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(
      apiRef,
      gridFilteredDescendantCountLookupSelector
    );
    const filteredDescendantCount =
      filteredDescendantCountLookup[rowNode.id] ?? 0;
    React.useEffect(() => {
      const rowToExpand =
        selectedData?.length && selectedData[0].vendor_id?._id;
      if (rowToExpand) {
        apiRef.current.setRowChildrenExpansion(rowToExpand, true);
      }
    }, [props.row, id, apiRef]);
    const handleClick = (vendorId) => {
      if (rowNode.type !== "group") {
        return;
      }
      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);
      //   event.stopPropagation();
    };
    const getCount = (vendorId) => {
      return (
        record?.data?.filter(
          ({ vendor_id, vendor_invoice_id }) =>
            vendor_id?._id === vendorId && !vendor_invoice_id
        )?.length || 0
      );
    };
    return (
      <Box sx={{ ml: rowNode.depth * 4 }}>
        <div>
          {filteredDescendantCount > 0 ? (
            <Button
              onClick={() => handleClick(props.row._id)}
              tabIndex={-1}
              size="small"
            >
              {/* {props?.value} ({filteredDescendantCount}) */}
              {props?.value} ({getCount(props.row._id)})
            </Button>
          ) : (
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`/jobs/update/${props.row._id}`}
                className={`text-[#1976D2] hover:underline ${
                  props.row.records?.vendor_invoice_id ? "!ml-7" : ""
                }`}
              >
                {props.row.job_number}
              </a>
              {props?.row.records?.vendor_invoice_id ? (
                <small className="text-red-600 ml-2 font-medium">
                  Invoice Created
                </small>
              ) : null}
            </div>
          )}
        </div>
      </Box>
    );
  }

  const getTreeDataPath = (row) => row.path;
  const groupingColDef = {
    headerName: "Tech",
    flex: 1,
    minWidth: 400,
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  return (
    <div className="bg-white my-3 border rounded">
      {isLoading ? <DotsLoader /> : null}
      <MUIDataTable
        columnDefs={columnDefs}
        items={(record?.data || [])?.map((record, index) => {
          const {
            _id,
            job_number,
            company_profit,
            complete_pickup_address,
            createdAt,
            createdBy,
            industry_id,
            is_closed,
            job_status_id,
            payment_type,
            refund_amount,
            tags,
            tech_fee,
            service_cost,
            goa_price,
            closed_date,
            scheduled_on,
            path,
            vendor_invoice_id,

            job_amount,
            vendor_owe,
            vendor_profit,
            pending_invoiced,
            total_invoiced,
            parts_charge = 0,
          } = record;
          return {
            _id,
            // counter: index + 1,
            records: { ...record, _id: _id },
            job_number,
            createdBy: createdBy?.username,
            job_status_id: job_status_id?.name,
            createdAt: dayjs(createdAt).format("ddd, MMM D, YYYY h:mm A"),
            industry_id: industry_id?.name,
            complete_pickup_address,
            tags,
            path,
            company_profit: company_profit ? company_profit?.toFixed(2) : 0,
            is_closed: is_closed ? "Yes" : "No",
            payment_type,
            refund_amount: refund_amount ? refund_amount : 0,
            tech_fee: tech_fee ? tech_fee : 0,
            service_cost: service_cost ? service_cost : 0,
            goa_price: goa_price ? goa_price : 0,
            closed_date: closed_date
              ? dayjs(closed_date).format("ddd, MMM D, YYYY h:mm A")
              : "",
            scheduled_on: scheduled_on
              ? dayjs(scheduled_on).format("ddd, MMM D, YYYY h:mm A")
              : "",
            job_amount,
            vendor_owe,
            vendor_profit,
            vendor_invoice_id: vendor_invoice_id ? "Yes" : "No",
            pending_invoiced,
            total_invoiced,
            parts_charge,
            techPercent: (vendor_profit / (job_amount - parts_charge)) * 100,
          };
        })}
        searchText={searchText}
        setSearchText={setSearchText}
        isLoading={isLoading}
        paginationModel={paginationModel}
        totalItems={record?.totalItems}
        onPaginationModelChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onFilterModelChange={onFilterChange}
        CustomToolbar={CustomToolbar}
        topPagination
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        columnVisibilityModel={{
          domain_count: false,
          vendor_invoice_id: false,
          createdBy: false,
          createdAt: false,
          job_status_id: false,
          scheduled_on: false,
          is_closed: false,
          industry_id: false,
          closed_date: false,
          payment_type: false,
        }}
        treeData={true}
        getTreeDataPath={getTreeDataPath}
        groupingColDef={groupingColDef}
      />
    </div>
  );
}

export default VendorBalance;
