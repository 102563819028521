import React, { useEffect, useState } from "react";
import RoleForm from "./AddUpdateForm";
import { useSelector, useDispatch } from "react-redux";
import { get_roles } from "../../features/rolesSlice";
import PageHeader from "../../components/molecules/PageHeader";
import { get_tasks } from "../../features/tasksSlice";
import { FaEdit } from "react-icons/fa";
import userPermission from "../../util/userPermission";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import NewSelectTaskForm from "./NewSelectTaskForm";
import SelectTaskForm from "./SelectTaskForm";
import { useNavigate } from "react-router-dom";

const Roles = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.roles);
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [roleManager, setRoleManager] = useState(false);
  const navigate = useNavigate();
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setRoleManager(false);
  };

  // pending for future implementation
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  useEffect(() => {
    dispatch(get_roles());
    // eslint-disable-next-line
  }, []);
  const roles = record?.filter((role) =>
    role?.name?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "NAME", field: "name", flex: 1 },
    { headerName: "LEVEL", field: "level", flex: 1 },
    { headerName: "Status", field: "status", flex: 1 },
    {
      headerName: "Total Users",
      field: "usersCount",
      flex: 1,
      renderCell: (params) =>
        params.row.usersCount ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() =>
              navigate(`/settings/users`, {
                state: {
                  role_id: params.row?.records?._id,
                },
              })
            }
          >
            {params.row.usersCount}
          </span>
        ) : (
          <span>{params.row.usersCount || 0}</span>
        ),
    },
    {
      headerName: "Active Users",
      field: "activeUsersCount",
      flex: 1,
      renderCell: (params) =>
        params.row.activeUsersCount ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() =>
              navigate(`/settings/users`, {
                state: {
                  role_id: params.row?.records?._id,
                  active: 1,
                },
              })
            }
          >
            {params.row.activeUsersCount}
          </span>
        ) : (
          <span>{params.row.activeUsersCount || 0}</span>
        ),
    },
    {
      headerName: "Inactive Users",
      field: "inactiveUsersCount",
      flex: 1,
      renderCell: (params) =>
        params.row.inactiveUsersCount ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() =>
              navigate(`/settings/users`, {
                state: {
                  role_id: params.row?.records?._id,
                  active: 0,
                },
              })
            }
          >
            {params.row.inactiveUsersCount}
          </span>
        ) : (
          <span>{params.row.inactiveUsersCount || 0}</span>
        ),
    },
    {
      field: "actions",
      renderCell: (params) => (
        <div>
          <FaEdit
            onClick={openFormHandler(params.row.records)}
            className="my_navIcon"
            title="Update Role"
          />
        </div>
      ),
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  return (
    <>
      {isEditing && (
        <RoleForm
          editingRecord={editingRecord}
          modalTitle="Add Roles"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > User Roles"
        heading="User Roles Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Administration")}
        extraButton={true}
        extraButtonClick={() => navigate(`/settings/roles/user_permissions`, {state:record})}
        additionalBtnTitle={"Manage Tasks"}
        additionalBtnAllow={userPermission("Administration")}
      />
      {/* {roleManager && (
        <SelectTaskForm
          editingRecord={editingRecord}
          modalTitle="Manage Role Permissions"
          onCancelForm={cancelFormHandler}
          roles={record}
        />
      )} */}
      {roleManager && (
        <NewSelectTaskForm
          editingRecord={editingRecord}
          modalTitle="Manage Role Permissions"
          onCancelForm={cancelFormHandler}
          roles={record}
        />
      )}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={roles?.map((record, index) => {
            let counter = index + 1;
            const {
              usersCount,
              inactiveUsersCount,
              activeUsersCount,
              name,
              level,
              status,
            } = record;
            return {
              counter,
              name,
              level,
              status: status ? "Active" : "InActive",
              records: record,
              usersCount,
              inactiveUsersCount,
              activeUsersCount,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          paginationMode="client"
        />
      </div>
    </>
  );
};

export default Roles;
