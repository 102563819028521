import React, { useState, useEffect } from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
  FormTextArea,
} from "../../../../components";
import { useFormik } from "formik";
import { get_industry_drd } from "../../../../features/IndustrySlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import errorMessage from "../../../../util/errorMessage";
import api from "../../../../services/api";
import * as Yup from "yup";
const AddKeywordModal = ({ onCancelForm, modalTitle, onSubmitForm }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { industryDrd } = useSelector((state) => state.industry);
  const [errors, setErrors] = React.useState([]);

  let initialValues = {
    domain: "",
    industry_id: "",
    keyword: "",
    domain_address: "",
    is_primary: "",
    population: "",
  };
  const validationSchema = Yup.object({
    domain: Yup.string().required("Domain is required"),
    industry_id: Yup.string().required("Industry is required"),
    keyword: Yup.string().required("Keyword is required"),
    domain_address: Yup.string().required("Domain Address is required"),
    is_primary: Yup.string().required("Primary is required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const res = await api.post("api/domains/add_keyword", values);
        if (res?.payload?.status === 200 || res?.payload?.status === 201) {
          toast.success("Keyword created successfully");
          onCancelForm();
          onSubmitForm();
        } else {
          errorMessage({
            payload: res.payload,
            setErrors: setErrors,
            action: "Keyword",
            msg: "created",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          setErrors: setErrors,
          action: "Keyword",
          msg: "created",
        });
      }
      setIsLoading(false);
    },
  });
  useEffect(() => {
    dispatch(get_industry_drd());
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Modal
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="w-full mt-4 ">
          <div className="grid md:grid-cols-1">
            <div className="mb-3">
              <FormInput
                errors={errors}
                name="domain"
                label="Domain"
                formik={formik}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-x-5">
            <div className="mb-3">
              <FormSelectInput
                errors={errors}
                name="industry_id"
                label="Industry"
                formik={formik}
                options={industryDrd}
                valueProp="_id"
                labelProp="name"
              />
            </div>
            <div className="mb-3">
              <FormInput
                errors={errors}
                name="keyword"
                label="Keyword"
                formik={formik}
              />
            </div>
            <div className="mb-3">
              <FormSelectInput
                errors={errors}
                name="is_primary"
                label="Primary"
                formik={formik}
                options={[
                  { value: "true", label: "Yes" },
                  { value: "false", label: "No" },
                ]}
                convertor={(value) => value === "true"}
                valueProp="value"
                labelProp="label"
              />
            </div>
            <div className="mb-3">
              <FormInput
                errors={errors}
                name="population"
                label="Population"
                formik={formik}
                type="number"
              />
            </div>
          </div>
          <div className="grid md:grid-cols-1 gap-4 w-full mt-2">
            <FormTextArea
              label="Domain Address"
              rows={5}
              name="domain_address"
              formik={formik}
              type="text"
              errors={errors}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddKeywordModal;
