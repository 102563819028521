import React from "react";
import { DotsLoader, FormInput, Modal } from "../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import { create_tld, update_tld } from "../../features/TldSlice";

const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.industry);
  const [errors, setErrors] = React.useState([]);

  let initialValues = {
    name: "",
  };

  if (editingRecord) {
    const { name } = editingRecord;
    initialValues = {
      name,
    };
  }

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (!editingRecord) {
        try {
          const res = await dispatch(create_tld(values?.name));
          if (res?.payload?.status === 201) {
            toast.success("TLD created");
            onCancelForm();
          } else {
            errorMessage({payload:res.payload, setErrors:setErrors, action:"TLD", msg:'created'})
          }
        } catch (error) {
          console.error("🚀 ~ file: AddUpdateForm.jsx:48 ~ onSubmit: ~ error:", error)
          toast.error("TLD couldn't be created");
        }
      } else {
        try {
          const res = await dispatch(
            update_tld({ id: editingRecord?.id, name: values?.name })
          );
          if (res?.payload?.status === 200) {
            toast.success("TLD updated");
            onCancelForm();
          } else {
            errorMessage({payload:res.payload, setErrors:setErrors, action:"TLD", msg:'updated'})
          }
        } catch (error) {
          toast.error("TLD couldn't be updated");
        }
      }
    },
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="mt-4">
          <FormInput errors={errors} name="name" label="Name" formik={formik} />
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;
