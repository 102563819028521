import React, { useState } from "react";
import { DotsLoader, Modal } from "../../../components";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import api from "../../../services/api";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";

// Combined regex for IPv4 or IPv6
const ipRegex =
  /^(?:(?:[0-9]{1,3}\.){3}[0-9]{1,3}|([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4})$/;

const AddIPsModal = ({
  editingRecord,
  onCancelForm,
  modalTitle,
  submitForm,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = editingRecord
    ? {
        name: editingRecord.name || "",
        ip: editingRecord.ip || "",
      }
    : {
        ipRecords: [{ name: "", ip: "" }],
      };

  const validationSchema = editingRecord
    ? Yup.object({
        name: Yup.string().required("Name is required"),
        ip: Yup.string()
          .matches(ipRegex, "Invalid IP address (must be IPv4 or IPv6)")
          .required("IP is required"),
      })
    : Yup.object({
        ipRecords: Yup.array().of(
          Yup.object({
            name: Yup.string().required("Name is required"),
            ip: Yup.string()
              .matches(ipRegex, "Invalid IP address (must be IPv4 or IPv6)")
              .required("IP is required"),
          })
        ),
      });

  const handleSubmit = async (values, { setSubmitting }) => {
    const payloadData = editingRecord
      ? { name: values.name, ip: values.ip }
      : values.ipRecords.map((record) => ({
          name: record.name,
          ip: record.ip,
        }));
    setIsLoading(true);
    if (!editingRecord) {
      try {
        const res = await api.post("/api/domains/whitelisted_ip", payloadData);
        if (res?.status === 200 || res?.status === 201) {
          toast.success("IP added successfully");
          submitForm();
        } else {
          toast.error(res?.data?.message || "IP couldn't be created");
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || "IP couldn't be created");
      }
    } else {
      try {
        const res = await api.put(
          `/api/domains/whitelisted_ip/${editingRecord?._id}`,
          payloadData
        );
        if (res?.status === 200 || res?.status === 201) {
          toast.success("IP updated successfully");
          submitForm();
        } else {
          toast.error(res?.data?.message || "IP couldn't be updated");
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || "IP couldn't be updated");
      }
    }
    setIsLoading(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit }) => (
        <Modal
          isUpdate={!!editingRecord}
          title={modalTitle}
          onCancelModal={onCancelForm}
          isLoading={isLoading}
          onClick={onCancelForm}
          onSubmit={handleSubmit}
        >
          {isLoading && <DotsLoader />}
          <Form>
            {editingRecord ? (
              <div className="flex items-center gap-2">
                <div className="flex-1">
                  <Field
                    type="text"
                    name="name"
                    className="w-full border rounded-md p-2"
                    placeholder="Enter Name"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-600 text-sm"
                  />
                </div>
                <div className="flex-1">
                  <Field
                    type="text"
                    name="ip"
                    className="w-full border rounded-md p-2"
                    placeholder="Enter IP"
                  />
                  <ErrorMessage
                    name="ip"
                    component="div"
                    className="text-red-600 text-sm"
                  />
                </div>
              </div>
            ) : (
              <FieldArray name="ipRecords">
                {({ push, remove }) => (
                  <div className="mt-2">
                    {values.ipRecords.map((record, index) => (
                      <div key={index} className="flex items-start gap-2 mb-2">
                        <div className="flex-1 max-w-[200px]">
                          <Field
                            type="text"
                            name={`ipRecords[${index}].name`}
                            className="w-full border rounded-md p-2"
                            placeholder="Enter Name"
                          />
                          <div className="text-red-600 text-sm">
                            <ErrorMessage
                              name={`ipRecords[${index}].name`}
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="flex-1 max-w-[450px]">
                          <Field
                            type="text"
                            name={`ipRecords[${index}].ip`}
                            className="w-full border rounded-md p-2"
                            placeholder="Enter IP"
                          />
                          <div className="text-red-600 text-sm">
                            <ErrorMessage
                              name={`ipRecords[${index}].ip`}
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="flex items-center gap-2 mt-2">
                          <button
                            type="button"
                            onClick={() => push({ name: "", ip: "" })}
                            className="text-gray-400 hover:text-gray-600"
                          >
                            <AiFillPlusCircle size={22} />
                          </button>
                          {index !== 0 && (
                            <button
                              type="button"
                              onClick={() => remove(index)}
                              className="text-gray-400 hover:text-gray-600"
                            >
                              <AiFillMinusCircle size={22} />
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
            )}
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default AddIPsModal;
