import React, { useEffect, useState } from "react";
import { FaSearch, FaSms, FaTrash } from "react-icons/fa";
import { MdOutgoingMail } from "react-icons/md";
import { HiPhoneOutgoing } from "react-icons/hi";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import dayjs from "dayjs";
import {
  delete_site_conversation,
  get_site_conversation,
  reply_to_conversation,
} from "../../features/conversationSlice";
import { toast } from "react-toastify";
import {
  call_by_link,
  setDialerPhone,
  set_conversationId,
} from "../../features/dialerSlice";
import { useNavigate } from "react-router-dom";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton } from "@material-ui/core";
import { Button, DotsLoader } from "../../components";
import errorMessage from "../../util/errorMessage";

const Conversation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, conversation, replyLoader } = useSelector(
    (state) => state?.siteConversation
  );
  const [filterConditions, setFilterConditions] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    dispatch(
      get_site_conversation({
        filters: queryOptions,
        page: paginationModel.page,
        size: paginationModel.pageSize,
        sort_field: params[0]?.field,
        sort_by: params[0]?.sort || "default",
      })
    );
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      setPaginationModel({ ...paginationModel, page: 1 });
      dispatch(
        get_site_conversation({
          ...sortingModel,
          page: 1,
          size: paginationModel.pageSize,
        })
      );
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : // : rule.operator === "isEmpty"
                // ? "eq"
                rule.operator,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    dispatch(
      get_site_conversation({
        ...sortingModel,
        filters: queryOptions,
        page: 1,
        size: paginationModel.pageSize,
      })
    );
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_site_conversation({
        ...sortingModel,
        filters: queryOptions,
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  const [selectedData, setSelectedData] = useState([]);
  const [repliedData, setRepliedData] = useState({
    conversation_id: "",
    reply_via: "",
    subject: "",
    body: "",
  });
  const dialer = useSelector((state) => state.dialer);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    dispatch(
      get_site_conversation({
        ...paginationModel,
        ...sortingModel,
        size: paginationModel.pageSize,
        filters: queryOptions,
      })
    );
    return () => {
      // dispatch(siteConversationReset())
    };
    // eslint-disable-next-line
  }, []);

  // const handleFilterModelChange = (e) => {
  //   setFilterConditions(e?.items);
  // };
  const handleFilterModelChanges = (data, filterConditions) => {
    // Extract the filter conditions
    // Check if any of the values in filterConditions is undefined
    const isAnyValueUndefined = filterConditions.some((condition) => {
      return condition.value === undefined;
    });
    if (isAnyValueUndefined) {
      // If any value is undefined, don't apply filtering
      return conversation?.records;
    } else {
      // Filter the rows based on the filter conditions
      const filteredRows = conversation?.records?.filter((row) => {
        return filterConditions.every((condition) => {
          const { field, operator, value } = condition;
          // Customize this logic based on your filter requirements
          if (operator === "contains") {
            return String(row[field])
              ?.toLowerCase()
              ?.includes(value?.toLowerCase());
          }
          if (operator === "equals") {
            return (
              String(row[field])?.toLowerCase() === String(value)?.toLowerCase()
            );
          }
          if (operator === "startsWith") {
            return String(row[field])
              ?.toLowerCase()
              ?.startsWith(value?.toLowerCase());
          }
          if (operator === "endsWith") {
            return String(row[field])
              ?.toLowerCase()
              ?.endsWith(value.toLowerCase());
          }
          if (operator === "isEmpty") {
            return !String(row[field])?.trim();
          }
          if (operator === "isNotEmpty") {
            return String(row[field])?.trim() !== "";
          }
          if (operator === "isAnyOf") {
            return value?.includes(String(row[field]));
          }
          return true;
        });
      });
      return filteredRows;
    }
  };
  const filteredData = handleFilterModelChanges(
    conversation?.records,
    filterConditions
  );
  const filterIP = filteredData?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const isSelected = (id) => {
    if (selectedData?.length > 0) {
      if (selectedData?.filter((_id) => _id === id).length > 0) {
        return true;
      }
    }
    return false;
  };
  const onSingleSelect = ({ checked, data }) => {
    if (checked) {
      setSelectedData([...selectedData, data]);
    } else {
      setSelectedData(selectedData?.filter((id) => id !== data));
    }
  };
  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = filteredData?.map((item) => item?._id);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        {selectedData?.length > 0 ? (
          <MUIButton
            variant="text"
            onClick={handleDelete}
            startIcon={<FaTrash size={16} />}
            sx={{ fontSize: "0.8125rem" }}
            className="!text-[#042a42]"
            title="Delete Selected Conversation"
          >
            Delete
          </MUIButton>
        ) : null}
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
      </GridToolbarContainer>
    );
  }
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={
            params.row.records.population_status ||
            isSelected(params.row.records._id)
          }
          onChange={(e) =>
            onSingleSelect({
              checked: e.target.checked,
              data: params.row.records._id,
            })
          }
          className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
      disableColumnMenu: true,
    },
    { headerName: "#", field: "counter", filterable: false },
    { headerName: "Source", field: "source", flex: 1, filterable: false },
    { headerName: "Type", field: "type", flex: 1, filterable: false },
    { headerName: "Domain", field: "domain", flex: 1 },
    { headerName: "Created At", field: "createdAt", flex: 1 },
    { headerName: "Industry Name", field: "industry_name", flex: 1 },
    { headerName: "Full Name", field: "contact_name", flex: 1 },
    { headerName: "Email", field: "email", flex: 1 },
    { headerName: "Customer Number ", field: "phone", flex: 1 },
    {
      headerName: "Message",
      field: "message",
      width: 180,
      wrap: true,
      cellClassName: "multiline-cell",
    },
    { headerName: "Reply Date", field: "replied_at", flex: 1 },
    {
      headerName: "Elapsed Time",
      field: "elapsedTime",
      flex: 1,
      filterable: false,
    },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex flex-row items-center justify-center relative">
          <MdOutgoingMail
            size={22}
            onClick={() => {
              if (params.row.records.replied_at) {
                return toast.error(
                  "Reply is already send to this conversation"
                );
              }
              setRepliedData({
                ...repliedData,
                conversation_id: params.row?.records?._id,
                reply_via: "Email",
              });
            }}
            className={`my_navIcon ${
              params.row.records.replied_at ? "!text-gray-400" : ""
            }`}
            title="Reply to this message by Email"
          />
          <FaSms
            size={22}
            onClick={() => {
              if (params.row.records.replied_at) {
                return toast.error(
                  "Reply is already send to this conversation"
                );
              }
              setRepliedData({
                ...repliedData,
                conversation_id: params.row?.records?._id,
                reply_via: "SMS",
              });
            }}
            className={`my_navIcon ${
              params.row.records.replied_at ? "!text-gray-400" : ""
            }`}
            title="Reply to this message by SMS"
          />
          <HiPhoneOutgoing
            size={22}
            onClick={() => {
              callOn({
                number: params.row.phone,
                id: params.row?.records?._id,
              });
            }}
            className={`my_navIcon ${
              params.row.records.lead_id ? "!text-gray-400" : ""
            }`}
            title="Call back"
          />
        </div>
      ),
      width: 100,
    },
  ];
  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    const numberOfLines = data?.message
      ? Math.ceil(data?.message?.length / 40)
      : 0;
    const addressHeight = rowHeight + numberOfLines * 30 + 20;
    return addressHeight + cellContentHeight;
  };
  const receiveMessage = (event) => {
    if (document.visibilityState === "visible") {
      if (
        event?.data?.action === "ManualDialNext" &&
        event.data.phone_number &&
        event.data.api_click
      ) {
        dispatch(
          setDialerPhone({
            phone: event.data.phone_number,
            callType: "Outgoing",
            dialer_lead_id: event?.data?.lead_id,
            vicidial_id: event?.data?.vicidial_id,
          })
        );
        if (dialer?.conversation_id) {
          navigate("/leads/add");
        }
      }
    }
  };
  // function CustomToolbar({ setFilterButtonEl }) {
  //   return (
  //     <GridToolbarContainer>
  //       <GridToolbarColumnsButton className="!text-[#042a42]" />
  //       <GridToolbarDensitySelector className="!text-[#042a42]" />
  //       <GridToolbarFilterButton
  //         ref={setFilterButtonEl}
  //         className="!text-[#042a42]"
  //       />
  //       {selectedData?.length > 0 ? (
  //         <MUIButton
  //           variant="text"
  //           onClick={handleDelete}
  //           startIcon={<FaTrash size={16} />}
  //           sx={{ fontSize: "0.8125rem" }}
  //           className="!text-[#042a42]"
  //           title="Delete Selected Conversation"
  //         >
  //           Delete
  //         </MUIButton>
  //       ) : null}
  //     </GridToolbarContainer>
  //   );
  // }
  window.addEventListener("message", receiveMessage);
  const callOn = async ({ number, id }) => {
    if (dialer?.status === "PAUSE") {
      try {
        const res = await dispatch(call_by_link(number));
        if (res?.payload?.status === 200) {
          toast.success("Call Successfully");
          dispatch(
            set_conversationId({
              conversation_id: id,
            })
          );
        }
      } catch (err) {
        console.error("🚀 ~ file: index.jsx:68 ~ callOn ~ err:", err);
      }
    } else if (dialer?.status === "") {
      toast.error("Please login to dialer");
    } else {
      toast.error("Please Pasue your dialer before call");
    }
  };
  const handleDelete = async () => {
    const c = window.confirm(
      `Are you sure want to delete ${selectedData?.length} records?`
    );
    if (c) {
      try {
        const res = await dispatch(delete_site_conversation(selectedData));
        if (res?.payload?.status === 200) {
          toast.success("Record deleted successfully");
          dispatch(
            get_site_conversation({
              ...sortingModel,
              filters: queryOptions,
              page: paginationModel.page,
              size: paginationModel.pageSize,
            })
          );
        } else {
          toast.error("Record couldn't deleted");
        }
      } catch (err) {
        console.error("🚀 ~ file: index.jsx:172 ~ handleDelete ~ err:", err);
      }
    }
  };
  const handleReply = async () => {
    const payload = repliedData;
    if (payload.reply_via === "SMS") {
      delete payload.subject;
    }
    try {
      const res = await dispatch(reply_to_conversation(payload));
      if (res?.payload?.status === 200) {
        setRepliedData({
          conversation_id: "",
          reply_via: "",
          subject: "",
          body: "",
        });
        toast.success("Reply has send successfully");
        dispatch(
          get_site_conversation({
            ...sortingModel,
            filters: queryOptions,
            page: paginationModel.page,
            size: paginationModel.pageSize,
          })
        );
      } else {
        errorMessage({
          payload: res.payload,
          action: `${repliedData.reply_via}`,
          msg: "send",
        });
      }
    } catch (err) {
      console.error("🚀 ~ file: index.jsx:308 ~ handleReply ~ err:", err);
      errorMessage({
        payload: err.payload,
        action: `${repliedData.reply_via}`,
        msg: "send",
      });
    }
  };
  return (
    <>
      <PageHeader
        route="Setting > Allowed IP"
        heading="Conversation Listing"
        // onClick={openFormHandler(0)}
        // isAllowed={userPermission("Add Allowed IP")}
      />

      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={filterIP?.map((record, index) => {
            let {
              domain,
              contact_name,
              email,
              phone,
              message,
              industry_name,
              createdAt,
              replied_at,
            } = record;
            let counter = index + 1;
            const repliedDate = new Date(
              replied_at ? replied_at : createdAt
            );
            const receivedDate = new Date(createdAt);
            const timeDifferenceMs = Math.abs(repliedDate - receivedDate);
            const days = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
              (timeDifferenceMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
              (timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor((timeDifferenceMs % (1000 * 60)) / 1000);
            let elapsedTime = "";

            if (days > 0) {
              elapsedTime += `${days} d, `;
            }
            if (hours > 0) {
              elapsedTime += `${hours} hr, `;
            }
            if (minutes > 0) {
              elapsedTime += `${minutes} min, `;
            }
            if (seconds > 0) {
              elapsedTime += `${seconds} sec`;
            }
            return {
              records: record,
              counter,
              source: "Website",
              type: "webform",
              domain,
              createdAt: dayjs(createdAt).format(
                "ddd, MMM D, YYYY h:mm A"
              ),
              contact_name,
              email,
              phone,
              message,
              industry_name,
              replied_at: replied_at
              ? dayjs(replied_at).format("ddd, MMM D, YYYY h:mm A")
              : "",
              elapsedTime,
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          paginationModel={paginationModel}
          totalItems={conversation?.totalItems}
          gridOptions={getRowHeight}
          CustomToolbar={CustomToolbar}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={onFilterChange}
        />

        {repliedData?.conversation_id && repliedData?.reply_via ? (
          <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-cente items-center bg-transparent">
            <div className="container-fluid mt-2 text-center ">
              <div className="inline-block bg-white w-2/4 my-0 mx-auto p-2.5 bg-red-600 rounded border border-gray-300 max-h-[87vh] max-w-[380px] absolute right-5 bottom-10">
                {replyLoader && <DotsLoader />}
                <header className="bg-primary-100 h-[60px] flex items-center justify-center shadow-lg">
                  <h1 className=" text-2xl text-white m-4 font-medium">
                    Reply via {repliedData?.reply_via}
                  </h1>
                </header>
                <form
                  onSubmit={handleReply}
                  className="flex flex-col justify-between w-full bottom-0 bg-[#f8f8f8] p-3.7 float-right "
                >
                  {repliedData?.reply_via === "Email" ? (
                    <input
                      className="border w-full py-1.5 !px-3 !mb-2 rounded !focus:outline-none"
                      onChange={(e) =>
                        setRepliedData({
                          ...repliedData,
                          subject: e.target.value,
                        })
                      }
                      value={repliedData?.subject}
                      placeholder="Enter Subject"
                    />
                  ) : null}
                  <textarea
                    className="border w-full py-1.5 !px-3 rounded !focus:outline-none"
                    onChange={(e) =>
                      setRepliedData({ ...repliedData, body: e.target.value })
                    }
                    value={repliedData?.body}
                    placeholder="Enter Message"
                    rows={14}
                  />
                </form>
                <Button
                  text="Cancel"
                  variant="btn_cancel"
                  className="ml-3"
                  onClick={() => {
                    setRepliedData({
                      conversation_id: "",
                      reply_via: "",
                      subject: "",
                      body: "",
                    });
                  }}
                />
                <Button
                  text="Send"
                  variant="btn_submit"
                  className="ml-3"
                  onClick={handleReply}
                  type="submit"
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Conversation;
