import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createPaymentGateway,
  getPaymentGateways,
  getPaymentGatewaysDrd,
  updatePaymentGateway,
} from "../services/paymentGatewayService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  drd:[]
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Payment Gateway
export const get_payment_gateways = createAsyncThunk(
  "get_payment_gateways",
  async (data,thunkAPI) => {
    try {
      return await getPaymentGateways(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_payment_gateways_drd = createAsyncThunk(
  "get_payment_gateways_drd",
  async (thunkAPI) => {
    try {
      return await getPaymentGatewaysDrd();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create New Payment Gateway
export const create_payment_gateway = createAsyncThunk(
  "create_payment_gateways",
  async (data, thunkAPI) => {
    try {
      return await createPaymentGateway(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Payment Gateway
export const update_payment_gateway = createAsyncThunk(
  "update_payment_gateways",
  async (data, thunkAPI) => {
    try {
      return await updatePaymentGateway(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const paymentGatewaySlice = createSlice({
  name: "payment_gateways",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_payment_gateways.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_payment_gateways.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_payment_gateways.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
      })
      .addCase(get_payment_gateways_drd.pending, (state) => {
        state.isLoading = true;
        state.record = [];
      })
      .addCase(get_payment_gateways_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.drd = action.payload?.data;
      })
      .addCase(get_payment_gateways_drd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // state.drd = [];
      })
      .addCase(create_payment_gateway.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_payment_gateway.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.records.push(action.payload.data);
      })
      .addCase(create_payment_gateway.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_payment_gateway.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_payment_gateway.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.records.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record.records[result] = {
          ...state.record.records[result],
          ...action.payload.data,
        };
      })
      .addCase(update_payment_gateway.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { reset } = paymentGatewaySlice.actions;
export default paymentGatewaySlice.reducer;
