import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createMerchant,
  deleteMerchant,
  getMerchants,
  updateMerchants,
} from "../services";
import { getMerchantsCompanies, syncMerchantsCx, updateMerchantsCx } from "../services/merchantService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  merchantCompanies: [],
  message: "",
  errors:[],
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Merchants
export const get_merchants = createAsyncThunk(
  "get_merchants",
  async (data, thunkAPI) => {
    try {
      return await getMerchants(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Merchants
export const get_merchants_companies = createAsyncThunk(
  "get_merchants_companies",
  async (thunkAPI) => {
    try {
      return await getMerchantsCompanies();
    } catch (error) {
      const message = getErrorMessage(error);

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Merchants
export const create_merchant = createAsyncThunk(
  "create_merchant",
  async (data, thunkAPI) => {
    try {
      return await createMerchant(data);
    } catch (error) {
      const message = getErrorMessage(error);

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Merchants
export const update_merchant = createAsyncThunk(
  "update_merchant",
  async (data, thunkAPI) => {
    try {
      return await updateMerchants(data);
    } catch (error) {
      const message = getErrorMessage(error);

      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Update Merchants
export const update_merchant_cx = createAsyncThunk(
  "update_merchant_cx",
  async (data, thunkAPI) => {
    try {
      return await updateMerchantsCx(data);
    } catch (error) {
      const message = getErrorMessage(error);

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const sync_merchant_cx = createAsyncThunk(
  "sync_merchant_cx",
  async (data, thunkAPI) => {
    try {
      return await syncMerchantsCx(data);
    } catch (error) {
      const message = getErrorMessage(error);

      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Delete Merchants
export const delete_merchant = createAsyncThunk(
  "delete_merchant",
  async (id, thunkAPI) => {
    try {
      return await deleteMerchant(id);
    } catch (error) {
      const message = getErrorMessage(error);

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Merchants
export const get_filter_data = createAsyncThunk(
  "delete_merchant",
  async (id, thunkAPI) => {
    try {
      return await deleteMerchant(id);
    } catch (error) {
      const message = getErrorMessage(error);

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const merchantsSlice = createSlice({
  name: "getMerchants",
  initialState,
  reducers: {
    merchantReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.message = "";
      state.record= [];
      state.merchantCompanies= [];
      state.errors=[];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_merchants.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_merchants.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_merchants.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
      })
      .addCase(create_merchant.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_merchant.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.record?.data.push(action.payload.data);
      })
      .addCase(create_merchant.rejected, (state, action) => {
        state.isLoading = false;
        state.errors=Array.isArray(action.payload)&&action.payload
      })
      .addCase(update_merchant.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_merchant.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_merchant.rejected, (state, action) => {
        state.isLoading = false;
        state.errors=Array.isArray(action.payload)&&action.payload
      })
      .addCase(update_merchant_cx.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_merchant_cx.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record?.data.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record.data[result] = {
          ...state.record?.data[result],
          ...action.payload.data,
        };
      })
      .addCase(update_merchant_cx.rejected, (state, action) => {
        state.isLoading = false;
        state.errors=Array.isArray(action.payload)&&action.payload
      })
      .addCase(delete_merchant.fulfilled, (state, action) => {
        const result = state.record.data.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record.data[result].active = false;
      })
      .addCase(get_merchants_companies.pending, (state) => {
        state.isLoading = true;
        state.merchantCompanies = [];
      })
      .addCase(get_merchants_companies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.merchantCompanies = action.payload?.data?.companies;
      })
      .addCase(get_merchants_companies.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // state.merchantCompanies = [];
      })
      .addCase(sync_merchant_cx.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sync_merchant_cx.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(sync_merchant_cx.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // state.merchantCompanies = [];
      });
  },
});

export const { merchantReset } = merchantsSlice.actions;
export default merchantsSlice.reducer;
