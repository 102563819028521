import React from "react";
import { Chart } from "react-google-charts";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";

export const chartData1 = [
    ["Task", "Hours per Day"],
    ["Work", 11],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7],
  ];
  export const chartData2 = [
    ["Task", "Hours per Day"],
    ["Work", 11],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7],
  ];
  
  export const options1 = {
    title: "",
    legend: "bottom"
  };
  export const options2 = {
    title: "",
    legend: "bottom"
  };

const Dispatcherformance = ()=> {
    const columnDefs = [
        { headerName: "Dispatcher", field: "tech", },
        { headerName: "All Jobs", field: "all_jobs" },
        { headerName: "Done Jobs", field: "done_jobs" },
        { headerName: "Open Jobs", field: "open_jobs" },
        { headerName: "Canceled Jobs", field: "canceled_jobs" },
        { headerName: "Canceled %", field: "canceled" },
        { headerName: "Gross Amount", field: "gross_amunt" },
        { headerName: "Profit", field: "profit" },
        { headerName: "Average Sale", field: "avg_sale" },
        { headerName: "Average Profit", field: "avg_profit" },
      ];
      const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 100,
        page: 1,
      });
      const handlePageSizeChange = (newPageSize) => {
        setPaginationModel({ ...paginationModel, pageSize: newPageSize });
      };
      const handlePageChange = (params) => {
        setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
      };
    return (
        <div className="w-full bg-white p-2">
            <div className="w-full flex justify-between">
                <div className="w-full md:w-1/2">
                    <div className="text-center text-xl font-bold mt-2">By Sales Amount</div>
                    <Chart
                    chartType="PieChart"
                    data={chartData1}
                    options={options1}
                    width={"100%"}
                    height={"400px"}
                    />
                </div>
                <div className="w-full md:w-1/2">
                    <div className="text-center text-xl font-bold mt-2">By Done Jobs</div>
                    <Chart
                    chartType="PieChart"
                    data={chartData2}
                    options={options2}
                    width={"100%"}
                    height={"400px"}
                    />
                </div>
            </div>
            <div className="bg-white my-3 border rounded">
          <MUIDataTable
            columnDefs={columnDefs}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            totalItems={0}
            //   searchText={searchText}
            //   setSearchText={setSearchText}
            paginationModel={paginationModel}
            //   isLoading={isLoading}
          />
      </div>
        </div>
    )
}

export default Dispatcherformance;