import React from "react";
import { DotsLoader, FormInput, Modal } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import {
  create_monitor,
  get_monitors,
  update_monitor,
} from "../../features/uptimeRobotSlice ";

const RoleForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.roles);
  const [errors, setErrors] = React.useState([]);
  let initialValues = {
    friendly_name: "",
    url: "",
  };

  if (editingRecord) {
    const { id, friendly_name, url } = editingRecord;
    initialValues = {
      id,
      friendly_name,
      url,
    };
  }
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (!editingRecord) {
        try {
          const res = await dispatch(create_monitor(values));
          if (res?.payload?.status === 200) {
            toast.success("Uptime monitor created successfully");
            dispatch(get_monitors());
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Uptime monitor",
              msg: "created",
            });
          }
        } catch (error) {
          toast.error("Uptime monitor couldn't be created");
        }
      } else {
        try {
          const res = await dispatch(update_monitor(values));
          if (res?.payload?.status === 200) {
            dispatch(get_monitors());
            toast.success("Uptime monitor updated successfully");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Uptime monitor",
              msg: "updated",
            });
          }
        } catch (error) {
          toast.error("Uptime monitor couldn't be updated");
        }
      }
    },
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form>
          <div className="!mb-3">
            <FormInput
              errors={errors}
              name="friendly_name"
              label="Name"
              formik={formik}
            />
          </div>
          <div className="!mb-3">
            <FormInput errors={errors} name="url" label="URL" formik={formik} />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default RoleForm;
