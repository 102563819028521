import dayjs from "dayjs";
import React, { useContext } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import GlobalContext from "../context/GlobalContext";
import { useSelector } from "react-redux";
import { Button } from "../../../components";
import { Dropdown } from "primereact/dropdown";

export default function CalendarHeader({ getLeadsByFilter, onReset }) {
  const {
    monthIndex,
    setMonthIndex,
    filters,
    setFilters,
    setViewType,
    viewType,
  } = useContext(GlobalContext);
  const { industryDrd } = useSelector((state) => state.industry);
  const jobStatus = useSelector((state) => state.jobStatus);
  const { allVendors } = useSelector((state) => state.geoVendors);
  function handlePrevMonth() {
    setMonthIndex(monthIndex - 1);
  }
  function handleNextMonth() {
    setMonthIndex(monthIndex + 1);
  }
  // function handleReset() {
  //   setMonthIndex(
  //     monthIndex === dayjs().month()
  //       ? monthIndex + Math.random()
  //       : dayjs().month()
  //   );
  // }

  return (
    <header className="px-2 py-2 flex items-center">
      <h1 className="mr-10 text-xl text-gray-500 font-bold font-sans">
        Calendar
      </h1>
      <select
        className="bg-transparent rounded p-2 boder-solid border w-full border-slate-300 font-sans min-w-[100px]"
        onChange={(e) => setViewType(e.target.value)}
        value={viewType}
      >
        <option value={"day"}>Day</option>
        <option value={"week"}>Week</option>
        <option value={"month"}>Month</option>
      </select>
      <span
        className="material-icons-outlined flex items-center cursor-pointer text-gray-600 mx-1"
        onClick={handlePrevMonth}
      >
        <MdChevronLeft size={24} />
      </span>
      <button onClick={handleNextMonth}>
        <span className="material-icons-outlined cursor-pointer text-gray-600 mx-1">
          <MdChevronRight size={24} />
        </span>
      </button>
      <h2 className="ml-4 text-xl text-gray-500 font-bold font-sans">
        {dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM YYYY")}
      </h2>

      <div className="flex flex-row col-8 items-center">
        <Dropdown
          value={filters?.vendor_id}
          onChange={(e) =>
            setFilters({
              ...filters,
              vendor_id: e?.value,
            })
          }
          filter
          showClear
          options={allVendors?.records}
          optionLabel={(options) =>
            options.first_name +
            " " +
            (options.last_name ? options.last_name : "")
          }
          optionValue="crm_id"
          placeholder="Select Vendor"
          className="h-[38px] w-full min-w-[270px] !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
        />
        <Dropdown
          value={filters?.industry_id}
          onChange={(e) =>
            setFilters({
              ...filters,
              industry_id: e?.value,
            })
          }
          filter
          showClear
          options={industryDrd}
          optionLabel="name"
          optionValue="_id"
          placeholder="Select Industry"
          className="mx-2 h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
        />
        <Dropdown
          value={filters?.job_status_id}
          onChange={(e) =>
            setFilters({
              ...filters,
              job_status_id: e?.value,
            })
          }
          filter
          showClear
          options={jobStatus?.record?.records}
          optionLabel="name"
          optionValue="_id"
          placeholder="Select Job Status"
          className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
        />
        <Button
          text="Search"
          variant="btn_submit"
          className="!ml-2"
          onClick={getLeadsByFilter}
        />
        <Button
          text="Clear"
          variant="btn_cancel"
          className="!ml-2"
          onClick={() => {
            setFilters({
              industry_id: "",
              job_status_id: "",
            });
            onReset();
          }}
        />
      </div>
    </header>
  );
}
