import api from "./api";

export const createServiceCost = async (data) => {
  return await api.post(`/api/service_costs`,data);
};
export const updateServiceCost = async (data) => {
  return await api.put(`/api/service_costs`,data);
};
export const createServiceCostData = async (data) => {
  return await api.post("/api/service_costs/data", data);
};
export const getServiceCostData = async (id) => {
  return await api.get(`/api/service_costs/quotes/${id}`);
};
export const updateServiceCostData = async (data) => {
  return await api.post("/api/service_costs/bulk_data", data);
};