import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { get_login_logs } from "../../features/reportsSlice";
import { useReactToPrint } from "react-to-print";
import ReportHead from "../Components/Header";
import { get_users } from "../../features/usersSlice";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";

const OnlineUserReport = () => {
  const dispatch = useDispatch();
  const componentRef = React.useRef(null);
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(get_login_logs());
    // eslint-disable-next-line
  }, []);
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Job #", field: "firstName" },
    {
      headerName: "Created",
      field: "user_ip",

      cellRendererFramework: (params) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://whatismyipaddress.com/ip/${params?.data?.user_ip}`}
        >
          {params?.data?.user_ip}
        </a>
      ),
    },
    { headerName: "Status", field: "broswer" },
    { headerName: "Tags", field: "OS" },
    { headerName: "Closed", field: "login_at" },
    { headerName: "Job Type", field: "broswer" },
    { headerName: "Client Type", field: "OS" },
    { headerName: "Closed", field: "login_at" },
    { headerName: "Job Type", field: "broswer" },
    { headerName: "Client Type", field: "OS" },
    { headerName: "Address", field: "login_at" },

    { headerName: "Total", field: "broswer" },
    { headerName: "Cash", field: "OS" },
    { headerName: "Credit", field: "login_at" },
    { headerName: "Tech", field: "broswer" },
    { headerName: "Tech fee", field: "OS" },
    { headerName: "Tech Share", field: "login_at" },
    { headerName: "Tech Profit", field: "broswer" },
    { headerName: "Company Profit", field: "OS" },
  ];
  const exportOption = [
    // {
    //   label: "Export PDF",
    //   value: "pdf",
    // },
    {
      label: "Export CSV",
      value: "csv",
    },
  ];

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Login Report",
  });
  useEffect(() => {
    dispatch(get_users());
    // eslint-disable-next-line
  }, []);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  return (
    <>
      <div className="container-fluid">
        <ReportHead route="Reports > Online Users Report" heading="Login IP" />
      </div>
      
      <div className="bg-white my-3 border rounded">
          <MUIDataTable
            exportOption={exportOption}
            handlePrint={handlePrint}
            columnDefs={columnDefs}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            totalItems={0}
            //   searchText={searchText}
            //   setSearchText={setSearchText}
            paginationModel={paginationModel}
            //   isLoading={isLoading}
          />
      </div>
    </>
  );
};

export default OnlineUserReport;
