import dayjs from "dayjs";
import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import { Button } from "../../components";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";

const LeadJobListModal = ({ onCancelForm, data }) => {
  const [record, setRecord] = useState([]);
  const modalRef = React.useRef(null);
  const [searchText, setSearchText] = React.useState("");
  const [isLoading, setIsloading] = React.useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      onCancelForm();
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  // document.addEventListener("click", handleClickOutside, true);
  const searchedRecord = record?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const sortedData = [...(searchedRecord || [])]?.sort((a, b) => {
    // Parsing dates for comparison
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);

    // Comparing parsed dates
    return dateB - dateA;
  });
  const getJobLead = async (filter) => {
    setIsloading(true);
    setRecord([]);
    try {
      const res = await api.post(`/api/jobs/job_report`, filter);
      if (res.status === 200) {
        setRecord(res.data);
      }
      setIsloading(false);
    } catch (err) {
      setIsloading(false);
      console.log("🚀 ~ getJobLead ~ err:", err);
    }
  };

  React.useEffect(() => {
    getJobLead({
      filters: data?.filter,
      page: paginationModel.page,
      size: paginationModel.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openNewTab = ({ id }) => {
    if (isButtonDisabled) {
      toast.info("Please wait for a second to open this job");
      return;
    }
    setIsButtonDisabled(true);
    window.open(`/jobs/update/preview/${id}`, "_blank");

    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 1000);
  };
  const TagsCellRenderer = (data) => {
    const tags = data?.value?.map((tag) => (
      <span
        key={tag._id}
        style={{
          backgroundColor: tag.color,
          margin: "2px",
          color: "#fff",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          fontWeight: 700,
          fontSize: "85%",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
        }}
      >
        {tag.name}
      </span>
    ));

    return (
      <div style={{ display: "flex", flexWrap: "wrap", width: "auto" }}>
        {tags}
      </div>
    );
  };

  const columnDefs = [
    {
      field: "Job #",
      sortable: true,
      valueGetter: (params) => params.row.job_number,
      sortComparator: (v1, v2, row1, row2) => {
        return v1 - v2;
      },
      renderCell: (params) => (
        <span
          onClick={() => openNewTab({ id: params.row._id })}
          className="text-blue-600 cursor-pointer hover:underline"
        >
          {params.row.job_number}
        </span>
      ),
      width: 80,
    },
    { headerName: "Created By", field: "createdBy", flex: 1, minWidth: 120 },
    { headerName: "Name", field: "name", flex: 1, minWidth: 130 },
    { headerName: "Phone", field: "phone", flex: 1, minWidth: 130 },
    {
      headerName: "Tags",
      field: "tags",
      renderCell: TagsCellRenderer,
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Industry",
      field: "industry_id",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Address",
      field: "complete_pickup_address",
      width: 180,
      wrap: true,
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Created",
      field: "createdAt",
      flex: 1,
      minWidth: 150,
      sortable: true,
      valueGetter: (params) => params.row.createdAt,
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
    },
    // { headerName: "Total", field: "total", flex: 1, minWidth: 100 },
    // { headerName: "Tech Fee", field: "tech_fee", flex: 1, minWidth: 100 },
    // { headerName: "GOA Price", field: "goa_price", flex: 1, minWidth: 100 },
    // { headerName: "Refund", field: "refund_amount", flex: 1, minWidth: 100 },
    // { headerName: "Tech Profit", field: "tech_profit", flex: 1, minWidth: 100 },
    // {
    //   headerName: "Company Profit",
    //   field: "company_profit",
    //   flex: 1,
    //   minWidth: 130,
    // },
  ].filter(Boolean);
  const getRowHeight = (params) => {
    const rowHeight = 30; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    const numberOfLines = data?.complete_pickup_address
      ? Math.ceil(data?.complete_pickup_address?.length / 30)
      : 0;
    const addressHeight = rowHeight + numberOfLines * 30 + 20;
    if (data?.tags && data?.tags.length > 0) {
      const tagHeight = 20; // height of each tag
      const numTags = data.tags.length;
      if (addressHeight > rowHeight + tagHeight * numTags + cellContentHeight) {
        return addressHeight;
      } else {
        return rowHeight + tagHeight * numTags + cellContentHeight;
      }
    }
    if (addressHeight > rowHeight + cellContentHeight) {
      return addressHeight;
    } else {
      return rowHeight + cellContentHeight;
    }
  };
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getJobLead({
      filters: data?.filter,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  return (
    <>
      <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
        <div className="container-fluid mt-2 text-center ">
          <div
            className="inline-block bg-white w-3/4 my-0 mx-auto p-4 bg-red-600 rounded relative max-h-[87vh]"
            ref={modalRef}
          >
            <div className="absolute top-0 right-0 p-2 z-20 -mt-6 -mr-6">
              <button
                onClick={onCancelForm}
                className="bg-white rounded-full text-gray-700 hover:text-red-500 p-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <header className="flex items-center justify-center shadow-lg bg-primary-100 text-white h-[50px] mb-3 text-xl">
              <h1 className="text-2xl text-white m-4 font-medium flex justify-between w-full">
                <span>{data?.titles?.vendor}</span>
                <span>{data?.titles?.status}</span>
              </h1>
            </header>
            <div className="flex flex-row justify-between">
              <input
                placeholder="Search"
                onChange={(e) => setSearchText(e.target.value)}
                className="!w-1/3 mb-2 placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md !py-2 px-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                value={searchText}
              />
              <span className="text-xl">
                {searchedRecord?.length} records found
              </span>
            </div>

            <div className="h-[50vh] mb-2">
              <MUIDataTable
                items={sortedData?.map((item, index) => {
                  const {
                    _id,
                    lead_num,
                    job_number,
                    createdBy,
                    name,
                    phone,
                    is_declined,
                    createdAt,
                    job_created,
                    recording,
                    complete_pickup_address,
                    job_status_id,
                    industry_id,

                    total = 0,
                    tech_fee = 0,
                    goa_price = 0,
                    refund_amount = 0,
                    tech_profit = 0,
                    company_profit = 0,
                    tags,
                  } = item;
                  return {
                    id: _id,
                    _id,
                    counter: index + 1,
                    records: item,
                    lead_num,
                    createdBy: createdBy?.username,
                    name,
                    phone,
                    total,
                    tech_fee,
                    goa_price,
                    refund_amount,
                    tech_profit,
                    company_profit,
                    tags,
                    is_declined,
                    complete_pickup_address,
                    createdAt: dayjs(createdAt).format(
                      "ddd, MMM D, YYYY h:mm A"
                    ),
                    recording,
                    status: `${
                      is_declined
                        ? "Declined"
                        : job_created
                        ? "Job Created"
                        : "Open"
                    }`,
                    job_number,
                    job_status_id: job_status_id?.name,
                    industry_id: industry_id?.name,
                  };
                })}
                columnDefs={columnDefs}
                isLoading={isLoading}
                gridOptions={getRowHeight}
                onPaginationModelChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                paginationModel={paginationModel}
                searchable="No"
                showCount="No"
                totalItems={record?.totalItems}
                toolbar="No"
                height="46vh"
              />
            </div>
            <Button
              text="Cancel"
              variant="btn_cancel"
              className="ml-3"
              onClick={onCancelForm}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadJobListModal;
