import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  create_nation_site_vendors,
  get_nation_site_vendors,
  update_nation_site_vendors,
} from "../../../features/nationSitesSlice";
import TagsInput from "../../../components/atoms/CustomTagsInput";
const AddUpdateNationSiteVendorsModal = ({
  editingRecord,
  onCancelForm,
  modalTitle,
}) => {
  const dispatch = useDispatch();
  const { isLoading, nationSites } = useSelector((state) => state.nation);
  const [errors, setErrors] = React.useState([]);

  const initialValues = {
    national_site_id: "",
    names: [],
    ...(editingRecord && {
      id: editingRecord._id,
      national_site_id: editingRecord.national_site_id,
      name: editingRecord?.name,
    }),
  };
  const handleSubmit = async (values) => {
    const action = editingRecord
      ? update_nation_site_vendors
      : create_nation_site_vendors;
    const message = editingRecord ? "updated" : "created";
    try {
      const res = await dispatch(action(values));
      if (res.payload?.status === 200 || res.payload?.status === 201) {
        toast.success(
          res.payload?.data?.message || `National Site Vendor ${message} successfully`
        );
        dispatch(get_nation_site_vendors());
        onCancelForm();
      } else {
        if (Array.isArray(res.payload)) {
          setErrors(res.payload);
        } else {
          toast.error(
            res.payload?.data?.message
              ? res.payload?.data?.message
              : `National Site Vendor couldn't be ${message}`
          );
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload)) {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload ? error.payload : `National Site Vendor couldn't be ${message}`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  return (
    <Modal
      isUpdate={!!editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      formIsValid={formik.isValid}
      onSubmit={formik.handleSubmit}
      isLoading={isLoading}
      onClick={onCancelForm}
    >
      {isLoading ? <DotsLoader /> : null}
      <form className="mt-4">
        <div className="!mb-3">
          <FormSelectInput
            errors={errors}
            name="national_site_id"
            label="Nation Site"
            formik={formik}
            options={nationSites?.records}
            valueProp="_id"
            labelProp="domain"
          />
        </div>
        {editingRecord ? (
          <div className="!mb-3">
            <FormInput
              errors={errors}
              name="name"
              label="Name"
              formik={formik}
            />
          </div>
        ) : (
          <TagsInput
            tags={formik.values.names}
            setTags={(value) => formik.setFieldValue("names", value)}
            placeholder="Add name"
            label="Add Names"
            isFloat
          />
        )}
      </form>
    </Modal>
  );
};

export default AddUpdateNationSiteVendorsModal;
