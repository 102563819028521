import React from "react";
import { FaSearch } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { get_conversation } from "../../../features/messageCenterSlice";

function MsgContacts(props) {
  const { userId, setUserId, data, type } = props;
  const dispatch = useDispatch();
  const [filterText, setFilterText] = React.useState("");
  const users = data?.filter(
    (record) =>
      record?.username?.toLowerCase().includes(filterText.toLowerCase()) ||
      record?.first_name?.toLowerCase().includes(filterText.toLowerCase()) ||
      record?.last_name?.toLowerCase().includes(filterText.toLowerCase()) ||
      record?.name?.toLowerCase().includes(filterText.toLowerCase())
  );
  return (
    <>
      <div className="relative w-full px-3.7 !my-2">
        <input
          className={
            "shadow appearance-none border rounded w-full !py-2 !pr-3 pl-12 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-sm border w-full !pl-15 h-[31px]"
          }
          style={{ minHeight: 31 }}
          placeholder="Search"
          value={filterText}
          onChange={(e) => {
            setFilterText(e.target.value);
          }}
        />
        <div className="absolute top-[3px] left-6 border-r border-[#dee2e6] pr-2.5 flex justify-center items-center h-[25px]">
          <FaSearch />
        </div>
      </div>

      <div className="overflow-y-auto h-[500px]">
        {users?.map((data, index) => {
          let text = data?.username
            ? data?.username
                ?.split(" ")
                .slice(0, 2)
                .map((string) => (string ? string[0].toUpperCase() : ""))
            : data?.name
            ? data?.name
                ?.split(" ")
                .slice(0, 2)
                .map((string) => (string ? string[0].toUpperCase() : ""))
            : data?.first_name
                ?.split(" ")
                .slice(0, 2)
                .map((string) => (string ? string[0].toUpperCase() : ""));
          return (
            <div
              className={`wms-hysearch-result border-bottom flex items-center ${
                userId?.user?._id === data?._id ||
                userId?.client?._id === data?._id
                  ? "bg-[#f7f8f8] border-l-4 border-menu"
                  : ""
              }`}
              onClick={() => {
                setUserId(
                  type === "Client"
                    ? {
                        client: { _id: data?._id, name: data?.name },
                        user_type: "Client",
                      }
                    : type === "User"
                    ? {
                        user: {
                          _id: data?._id,
                          first_name: data?.first_name,
                          last_name: data?.last_name,
                          username: data?.username,
                        },
                        role: { name: data?.role_id?.name },
                        user_type: "User",
                      }
                    : type === "Vendor"
                    ? {
                        vendor: {
                          _id: data?._id,
                          first_name: data?.first_name,
                          last_name: data?.last_name,
                          username: data?.username,
                        },
                        role: { name: "Vendor" },
                        user_type: "Vendor",
                      }
                    : {}
                );
                dispatch(
                  get_conversation(
                    type === "Client"
                      ? { user_id: data?._id, user_type: "Client" }
                      : type === "Vendor"
                      ? { user_id: data?._id, user_type: "Vendor" }
                      : { user_id: data?._id, user_type: "User" }
                  )
                );
              }}
              key={index}
            >
              <div className="wms-imgcollage">
                <div className="wms-lettercoll">
                  <span>{text}</span>
                </div>
              </div>
              <div className="flex flex-row justify-between !w-[100%]">
                <div className="wms-hysearch-result-name wms-truncate-ellipsis !w-[90%]">
                  {data?.first_name
                    ? `${data?.first_name} ${
                        data?.last_name ? data?.last_name : null
                      }`
                    : data?.name}
                </div>
              </div>
              <span className="wms_menu_item_count" />
            </div>
          );
        })}
      </div>
    </>
  );
}

export default MsgContacts;
