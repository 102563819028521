import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createAllowedIp,
  deleteAllowedIp,
  getAllowedIpList,
  updateAllowedIp,
} from "../services/allowedIpService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get AllowedIP Data
export const get_allowed_ip = createAsyncThunk(
  "get_allowed_ip",
  async (data,thunkAPI) => {
    try {
      return await getAllowedIpList(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New AllowedIP
export const create_allowed_ip = createAsyncThunk(
  "create_allowed_ip",
  async (data, thunkAPI) => {
    try {
      return await createAllowedIp(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update AllowedIP
export const update_allowed_ip = createAsyncThunk(
  "update_allowed_ip",
  async (data, thunkAPI) => {
    try {
      return await updateAllowedIp(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete AllowedIP
export const delete_allowed_ip = createAsyncThunk(
  "delete_allowed_ip",
  async (id, thunkAPI) => {
    try {
      return await deleteAllowedIp(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const allowedIpSlice = createSlice({
  name: "allowedIp",
  initialState,
  reducers: {
    allowedIPReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_allowed_ip.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_allowed_ip.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_allowed_ip.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(create_allowed_ip.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_allowed_ip.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record?.records?.push(action.payload.data);
      })
      .addCase(create_allowed_ip.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_allowed_ip.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_allowed_ip.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record?.records?.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record.records[result] = {
          ...state.record?.records[result],
          ...action.payload.data,
        };
      })
      .addCase(update_allowed_ip.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_allowed_ip.fulfilled, (state, action) => {
        const result = state.record?.records?.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record.records[result].active = false;
      });
  },
});

export const { allowedIPReset } = allowedIpSlice.actions;
export default allowedIpSlice.reducer;
