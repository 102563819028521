import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { Loader, Button, FormInput } from "../../../../components";
const CustomModal = ({ onClose, job_id, formik }) => {
  const [isLoader, setIsLoader] = useState(false);
  const [amount, setAmount] = useState(0);
  const handleConfirm = async () => {
    setIsLoader(true);
    try {
      const res = await api.post(`api/jobs/virtual_card_request/${job_id}`, {
        request_amount: Number(amount),
      });
      if (res.status === 200 || res.status === 201) {
        console.log("👊 ~ handleConfirm ~ res:", res);
        toast.success("Request sent successfully for new card");
        formik.setFieldValue(
          "virtual_cards_info",
          res.data.job.virtual_cards_info
        );
        onClose();
      } else {
        toast.error(res?.data?.message || "Request couldn't be sent");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error(err?.response?.data?.message || "Request couldn't be sent");
      console.log("🚀 ~ handleConfirm ~ err:", err);
    }
  };
  return (
    <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40 bg-black/60">
      <div className="bg-white p-8 rounded-lg shadow-lg relative w-[500px]">
        <h2 className="text-lg font-semibold mb-4">Request for a new card</h2>
        <div className="relative flex flex-col">
          <FormInput
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            name="amount"
            placeholder={"Amount"}
            label="Amount"
            className={"my-3"}
            type={"number"}
          />
        </div>
        <div className="flex justify-end !mt-4">
          {isLoader ? (
            <Loader />
          ) : (
            <>
              <Button text="Cancel" variant="btn_cancel" onClick={onClose} />
              <Button
                text="Submit"
                variant="btn_submit"
                onClick={handleConfirm}
                className="ml-2"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
