import api from "./api";

export const getIndustry = async ({data}) => {
    return await api.get("/api/industries",{params:data});
};

export const getIndustriesDB = async (data) => {
  return await api.get("/api/industries/industry_db",{params:data});
};

export const createIndustry = async (name) => {
  return await api.post("/api/industries", { name });
};

export const updateIndustry = async (id, name) => {
  return await api.put(`/api/industries/${id}`, { name });
};
export const toggleActive = async (id) => {
  return await api.put(`/api/industries/toggle_active/${id}`);
};
export const deleteIndustry = async (id) => {
  return await api.delete(`/api/industries/${id}`);
};

export const getIndustryDrd = async (data) => {
  return await api.get("/api/industries/drd",{params:data});
};
export const assignInbounToIndustry = async (data) => {
  return await api.post("/api/industries/assign_inbound_group",data);
};