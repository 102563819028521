import React from "react";
import FormInput from "../Common/FormInput";
import VehicleTrailerDetailsSection from "../Common/Sections/VehicleTrailerDetailsSection";
const NonVehicleTow = ({ formik }) => {
  return (
    <div className="bg-white border px-2 md:px-4 mt-3">
      <div className="pl-2 pt-3 !mb-2">
        <div className="font-semibold text-lg">Non-vehicle Tow:</div>
      </div>
      <div class="flex flex-row gap-4 mb-4 mt-2 ml-2">
        <div class="basis-1/2 p-1">
          <div
            className="font-semibold text-xs text-gray-500 mb-1"
            htmlFor="to_be_towed"
          >
            What is the equipment or item that needs to be towed?
          </div>
          <FormInput
            id="to_be_towed"
            name="extra_fields.to_be_towed"
            type="text"
            formik={formik}
            value={formik.values?.extra_fields?.to_be_towed}
          />
        </div>

        <div class="basis-full ">
          <div className="font-semibold text-xs text-gray-500 mb-1">
            Dimensions or size of the equipment/item?
          </div>
          <VehicleTrailerDetailsSection
            formik={formik}
            fieldsToShow={["weight", "height", "length", "width"]}
          />
        </div>
      </div>
    </div>
  );
};

export default NonVehicleTow;
