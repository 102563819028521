import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  apiGetCityByCountryIdStateId,
  apiGetCountries,
  apiGetStatesByCountryId,
  apiGetZipByIds,
} from "../services";

const initialState = {
  isError: false,
  isLoader: false,
  countries: [],
  states: [],
  cities: [],
  zips: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Countries
export const get_countries = createAsyncThunk(
  "get_countries",
  async (thunkAPI) => {
    try {
      return await apiGetCountries();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get States
export const get_states = createAsyncThunk(
  "get_states",
  async (id, thunkAPI) => {
    try {
      return await apiGetStatesByCountryId(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Cities
export const get_cities = createAsyncThunk(
  "get_cities",
  async (data, thunkAPI) => {
    try {
      return await apiGetCityByCountryIdStateId(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Cities
export const get_zips = createAsyncThunk("get_zips", async (data, thunkAPI) => {
  try {
    return await apiGetZipByIds(data);
  } catch (error) {
    const message = getErrorMessage(error);
    return thunkAPI.rejectWithValue(message);
  }
});
export const countriesSlice = createSlice({
  name: "getCountries",
  initialState,
  reducers: {
    countriesReset: (state) => {
      state.isError = false;
      state.isLoader = false;
      state.countries = [];
      state.states = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_countries.pending, (state) => {
        state.isLoader = true;
        state.countries = [];
        state.states = [];
      })
      .addCase(get_countries.fulfilled, (state, action) => {
        state.isLoader = false;
        state.countries = action.payload?.data;
      })
      .addCase(get_countries.rejected, (state, action) => {
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.countries = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_states.pending, (state) => {
        state.isLoader = true;
        state.states = [];
      })
      .addCase(get_states.fulfilled, (state, action) => {
        state.isLoader = false;
        state.states = action.payload?.data;
      })
      .addCase(get_states.rejected, (state, action) => {
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        // state.states = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_cities.pending, (state) => {
        state.isLoader = true;
        state.cities = [];
      })
      .addCase(get_cities.fulfilled, (state, action) => {
        state.isLoader = false;
        state.cities = action.payload?.data;
      })
      .addCase(get_cities.rejected, (state, action) => {
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        // state.states = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_zips.pending, (state) => {
        state.isLoader = true;
        state.zips = [];
      })
      .addCase(get_zips.fulfilled, (state, action) => {
        state.isLoader = false;
        state.zips = action.payload?.data;
      })
      .addCase(get_zips.rejected, (state, action) => {
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        // state.states = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      });
  },
});

export const { countriesReset } = countriesSlice.actions;
export default countriesSlice.reducer;
