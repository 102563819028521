import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button } from "../../../components";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import PageHeader from "../../../components/molecules/PageHeader";
import { get_geo_industry } from "../../../features/GeoIndustrySlice";
import api from "../../../services/api";
import { MdSave } from "react-icons/md";
import BatchSaveModal from "./saveBatch";
import { toast } from "react-toastify";
import { FaSearch } from "react-icons/fa";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton } from "@mui/material";
import { get_countries } from "../../../features/countriesSlice";
const DomainLocationReport = () => {
  const dispatch = useDispatch();
  const [domains, setDomains] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const industry = useSelector((state) => state.geoIndustry);
  const [selectedData, setSelectedData] = useState([]);
  const [isSaveBatch, setIsSaveBatch] = React.useState(false);
  const [isStarted, setIsStarted] = React.useState(false);
  const { countries } = useSelector((state) => state.countries);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 2000,
    page: 1,
  });
  const [quickFilters, setquickFilters] = useState([
    {
      field: "domains",
      operator: "isEmpty",
      value: null,
    },
  ]);

  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "AND",
    rules: [
      {
        field: "domains",
        op: "isEmpty",
        data: null,
      },
    ],
  });
  const operator = ({ operator, field }) => {
    return operator === "cn"
      ? "contains"
      : operator === "eq" && field !== "population"
      ? "equals"
      : operator === "eq" && field === "population"
      ? "="
      : operator === "not"
      ? "!="
      : operator === "gt"
      ? ">"
      : operator === "gte"
      ? ">="
      : operator === "lt"
      ? "<"
      : operator === "lte"
      ? "<="
      : operator;
  };
  const [filterModel, setFilterModel] = React.useState({
    items: queryOptions.rules?.map(({ field, op, data }) => ({
      field,
      operator: operator({ operator: op, field }),
      value: data,
    })),
    logicOperator: "and",
    quickFilterValues: [],
    quickFilterLogicOperator: "and",
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const initialFilters = {
    industry_id: "",
    vendor_type: "",
    vendor_InOut: "All",
    country_id: "",
  };
  const [filterData, setfilterData] = useState(initialFilters);
  useEffect(() => {
    dispatch(get_countries());
    dispatch(get_geo_industry());
    // eslint-disable-next-line
  }, []);
  const get_domain_locations = async (data) => {
    const payload = { ...data };
    if (!data?.filters.rules.length) {
      delete payload.filters;
    }
    setIsLoader(true);
    try {
      const res = await api.post("api/vendors/domain_locations", payload);
      if (res.status === 200) {
        setDomains(res.data);
      }
      setIsLoader(false);
    } catch (err) {
      console.log("👊 ~ constget_domain_locations= ~ err:", err);
      setIsLoader(false);
    }
  };
  const getMultipleDomains = async (data) => {
    if (!data?.country_id) {
      return toast.error("Country is required");
    }
    if (!data?.industry_id) {
      return toast.error("Industry is required");
    }
    const payload = { ...data };
    delete payload.size;
    delete payload.page;
    delete setIsLoader(true);
    try {
      const res = await api.post(
        `api/vendors/generate_domain_locations`,
        payload
      );
      if (res.status === 200 || res.status === 201) {
        await get_domain_locations({
          ...sortingModel,
          filters: queryOptions,
          page: 1,
          size: paginationModel.pageSize,
        });
      }
      setIsStarted(true);
      setIsLoader(false);
    } catch (err) {
      console.log("👊 ~ getMultipleDomains ~ err:", err);
      setIsLoader(false);
    }
  };

  const record = domains?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const isSelected = ({ city, state }) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter(
          (data) => data?.state === state && data.city === city
        ).length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = domains?.records?.map((item) => ({
        state: item?.state,
        city: item?.city,
        state_code: item?.state_code,
      }));
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const onSingleSelect = ({ checked, data }) => {
    if (checked) {
      setSelectedData([...selectedData, data]);
    } else {
      setSelectedData(
        selectedData?.filter(
          (item) => item.state !== data.state || item.city !== data.city
        )
      );
    }
  };
  const TagsCellRenderer = (data) => {
    const tags = data?.value?.map((tag) => (
      <span
        key={tag?._id}
        style={{
          margin: "2px",
          color: "black",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
        }}
      >
        <a
          href={`/settings/vendors/update/${tag?._id}`}
          target="_blank"
          rel="noreferrer"
          className="text-blue-600 no-underline hover:underline cursor-pointer"
        >
          {`${tag?.first_name || ""} ${tag?.last_name || ""}`}
        </a>
      </span>
    ));

    return (
      <div style={{ display: "flex", flexDirection: "column", width: "auto" }}>
        {tags}
      </div>
    );
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
          checked={
            domains?.records?.length &&
            selectedData?.length > 0 &&
            selectedData?.length === domains?.records?.length
          }
        />
      ),
      filterable: false,
      sortable: false,
      width: 80,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={isSelected({
            state: params.row.state,
            city: params.row.city,
            state_code: params.row.state_code,
          })}
          onChange={(e) =>
            onSingleSelect({
              checked: e.target.checked,
              data: {
                state: params.row.state,
                city: params.row.city,
                state_code: params.row.state_code,
              },
            })
          }
          className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
    },
    { headerName: "#", field: "counter", width: 60, filterable: false },
    {
      headerName: "Vendor",
      field: "vendors",
      flex: 1,
      minWidth: 350,
      renderCell: TagsCellRenderer,
      filterable: false,
    },
    { headerName: "State", field: "state", flex: 1, minWidth: 150 },
    { headerName: "City", field: "city", flex: 1, minWidth: 150 },
    {
      headerName: "Population",
      field: "population",
      flex: 1,
      minWidth: 100,
      type: "number",
    },
    {
      headerName: "Domains",
      field: "domains",
      flex: 1,
      minWidth: 350,
      renderCell: (params) => {
        return (
          <a
            href={`https://www.${params.row.domains}`}
            className="hover:text-primary underline"
            target="_blank"
            rel="noreferrer"
          >
            {params.row.domains}
          </a>
        );
      },
    },
  ];
  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    const vendors = data?.vendors;
    if (data?.vendors && vendors.length > 0) {
      const tagHeight = 20; // height of each tag
      const numTags = vendors.length;
      return rowHeight + tagHeight * numTags + cellContentHeight;
    }
  };
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    get_domain_locations({
      ...sortingModel,
      filters: queryOptions,
      page: params.page + 1,
      size: params.pageSize,
    });
  };

  const sortedIndustries = [...industry?.record].sort((a, b) =>
    a?.industry_name.localeCompare(b?.industry_name)
  );
  const onFilterChange = React.useCallback((filterModel) => {
    setFilterModel(filterModel);
    if (filterModel?.items?.length === 0) {
      get_domain_locations({
        filters: {
          groupOp: "",
          rules: [],
        },
        page: 1,
        size: paginationModel.pageSize,
      });
      setquickFilters([]);
    }
    let ruless = [];
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          data: rule.value || rule.value === 0 ? rule.value : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line
  }, []);
  const handleNewFilter = () => {
    setFilterModel({
      items: queryOptions.rules?.map(({ field, op, data }) => ({
        field: field,
        operator: operator({ operator: op, field }),
        value: data,
      })),
      logicOperator: "and",
      quickFilterValues: [],
      quickFilterLogicOperator: "and",
    });
    setquickFilters((prev) => {
      const filteredRules = prev.filter(
        ({ field, operator }) =>
          field !== "domains" &&
          (operator !== "isNotEmpty" || operator !== "isEmpty")
      );
      const filterExist = queryOptions?.rules?.find(
        ({ field, op }) =>
          field === "domains" && (op === "isEmpty" || op === "isNotEmpty")
      );
      return filterExist
        ? [
            ...filteredRules,
            {
              field: "domains",
              operator: filterExist?.op,
              value: null,
            },
          ]
        : [];
    });
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    get_domain_locations({
      ...sortingModel,
      filters: queryOptions,
      page: paginationModel.page,
      size: paginationModel.pageSize,
    });
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        {selectedData?.length ? (
          <span className="text-sm font-semibold bg-[#fcebc5] py-1 px-1.5">
            {`${selectedData?.length} items are selected `}
            <span
              className="text-blue-600 hover:underline cursor-pointer ml-2"
              onClick={() => setSelectedData([])}
            >
              Clear
            </span>
          </span>
        ) : null}
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        {isStarted ? (
          <MUIButton
            variant="text"
            onClick={handleNewFilter}
            startIcon={<FaSearch size={16} />}
            sx={{ fontSize: "0.8125rem" }}
            className={"!text-[#042a42]"}
          >
            Apply filter
          </MUIButton>
        ) : null}
      </GridToolbarContainer>
    );
  }
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    get_domain_locations({
      filters: queryOptions,
      page: paginationModel.page,
      size: paginationModel.pageSize,
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
  };
  const handleFilterData = (type) => {
    const filter = [
      ...queryOptions.rules?.filter(
        ({ field, op }) =>
          field !== "domains" && op !== "isNotEmpty" && op !== "isEmpty"
      ),
    ];
    setquickFilters((prev) => {
      const filteredRules = prev.filter(
        ({ field, operator }) =>
          field !== "domains" &&
          operator !== "isNotEmpty" &&
          operator !== "isEmpty"
      );

      return [
        ...filteredRules,
        {
          field: "domains",
          operator: type,
          value: null,
        },
      ];
    });
    setQueryOptions((prev) => {
      const filteredRules = prev?.rules?.filter(
        ({ field, op }) =>
          field !== "domains" && op !== "isNotEmpty" && op !== "isEmpty"
      );
      return {
        ...prev,
        rules: [
          ...filteredRules,
          {
            field: "domains",
            op: type,
            data: null,
          },
        ],
      };
    });
    setQueryOptions((prev) => {
      const filteredRules = prev?.rules?.filter(
        ({ field, op }) =>
          field !== "domains" && op !== "isNotEmpty" && op !== "isEmpty"
      );
      return {
        ...prev,
        rules: [
          ...filteredRules,
          {
            field: "domains",
            op: type,
            data: null,
          },
        ],
      };
    });
    setFilterModel((prev) => {
      const filteredRules = prev?.items?.filter(
        ({ field, operator }) =>
          field !== "domains" &&
          operator !== "isNotEmpty" &&
          operator !== "isEmpty"
      );
      return {
        ...prev,
        items: [
          ...filteredRules,
          {
            field: "domains",
            operator: type,
            value: null,
          },
        ],
      };
    });
    get_domain_locations({
      ...sortingModel,
      filters: {
        ...queryOptions,
        rules: [
          ...filter,
          {
            field: "domains",
            op: type,
            data: null,
          },
        ],
      },
      page: 1,
      size: paginationModel.pageSize,
    });
  };
  const LineOne = () => {
    return isStarted ? (
      <div className="grid grid-cols-2 gap-2 ml-3">
        <button
          className={`flex flex-co justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${
          quickFilters?.find(({ field }) => field === "domains")?.operator ===
          "isNotEmpty"
            ? "border-l-2 border-primary-100"
            : ""
        }
        `}
          onClick={() => handleFilterData("isNotEmpty")}
        >
          <span className="text-sm font-pop">Purchased Domain:</span>
          <span className="text-base font-medium font-pop">
            {domains?.summary?.alreadyPurchasedLocationsCount}
          </span>
        </button>
        <button
          className={`flex justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
          ${
            quickFilters?.find(({ field }) => field === "domains")?.operator ===
            "isEmpty"
              ? "border-l-2 border-primary-100"
              : ""
          }
        `}
          onClick={() => handleFilterData("isEmpty")}
        >
          <span className="text-sm font-pop">Available to Purchase:</span>
          <span className="text-base font-medium font-pop">
            {domains?.summary?.availableToPurchase}
          </span>
        </button>
      </div>
    ) : null;
  };
  const industryName =
    sortedIndustries?.find(
      ({ mongo_id }) => mongo_id === filterData?.industry_id
    )?.name ?? "";

  const domainFilter = queryOptions?.rules?.find(
    ({ field, op }) =>
      field === "domains" && (op === "isEmpty" || op === "isNotEmpty")
  );

  const domainStatus =
    domainFilter?.op === "isEmpty"
      ? "Available to buy"
      : domainFilter?.op === "isNotEmpty"
      ? "Already Purchased"
      : "All";
  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  return (
    <>
      <PageHeader
        route="Setting > Vendors"
        heading="Domain Location Report"
        isHead="No"
      />
      {isSaveBatch && (
        <BatchSaveModal
          industry_id={filterData.industry_id}
          country_id={filterData.country_id}
          search_details={{
            industry: industryName,
            filters: queryOptions ?? {},
            vendor_type: filterData?.vendor_type ?? "",
            vendor_InOut: filterData?.vendor_InOut ?? "",
            domains: domainStatus,
          }}
          totalItems={domains?.totalItems}
          onSingleselect={onSingleSelect}
          locations={selectedData}
          title="Save Batch"
          onCancelForm={() => setIsSaveBatch(false)}
        />
      )}
      <div className="items-center !m-2 bg-slate-50 !my-2 border rounded p-3">
        <div className="flex flex-wra grid grid-cols-6 gap-x-2 items-center">
          <div className="w-full">
            <label className="inputLabel" htmlFor="Countries">
              {"Countries"}
            </label>
            <Select
              name="country_id"
              placeholder="Select Country"
              onChange={(e) =>
                setfilterData({ ...filterData, country_id: e._id })
              }
              options={countries}
              valueKey="_id"
              labelKey="name"
              className="w-full"
              menuPosition="fixed"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option._id}
              isDisabled={isStarted}
            />
          </div>
          <div className="w-full">
            <label className="inputLabel" htmlFor="industries">
              {"Industries"}
            </label>
            <Select
              name="industries"
              placeholder="Select Industries"
              onChange={(e) =>
                setfilterData({ ...filterData, industry_id: e.mongo_id })
              }
              options={sortedIndustries}
              valueKey="mongo_id"
              labelKey="name"
              className="w-full"
              menuPosition="fixed"
              getOptionLabel={(option) =>
                option.name ? option.name : option.industry_name
              }
              getOptionValue={(option) => option.mongo_id}
              value={
                filterData?.industry_id
                  ? {
                      mongo_id: filterData.industry_id,
                      name: industry?.record?.find(
                        ({ mongo_id }) => mongo_id === filterData?.industry_id
                      )?.industry_name,
                    }
                  : null
              }
              isDisabled={isStarted}
            />
          </div>
          <div className="w-full">
            <label className="inputLabel" htmlFor="industries">
              {"Vendor Type"}
            </label>
            <Select
              name="vendor_type"
              placeholder="Select Vendor Type"
              onChange={(e) =>
                setfilterData({ ...filterData, vendor_type: e.value })
              }
              options={[
                { label: "All", value: "All" },
                { label: "Global", value: "Global" },
                { label: "Own", value: "Own" },
              ]}
              valueKey="value"
              labelKey="label"
              className="w-full"
              menuPosition="fixed"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              isDisabled={isStarted}
            />
          </div>
          <div className="w-full">
            <label className="inputLabel" htmlFor="industries">
              {"Vendor In/Out"}
            </label>
            <Dropdown
              value={filterData?.vendor_InOut}
              onChange={(e) =>
                setfilterData({ ...filterData, vendor_InOut: e.value })
              }
              options={[
                { value: "All", label: "All" },
                { value: "InNet", label: "InNet" },
                { value: "OutNet", label: "OutNet" },
              ]}
              optionLabel="label"
              optionValue="value"
              placeholder="Vendor In/Out"
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              disabled={isStarted}
            />
          </div>
          <div className=" flex justify-start !mt-6 col-span-2">
            <div className="float-lef">
              {isStarted ? (
                <Button
                  text="Reset"
                  className="py-1.5 px-3 align-middle mx-2 bg-menu text-white"
                  onClick={() => {
                    setDomains([]);
                    setfilterData(initialFilters);
                    setIsStarted(false);
                    setquickFilters([
                      {
                        field: "domains",
                        operator: "isEmpty",
                        value: null,
                      },
                    ]);
                    setQueryOptions({
                      groupOp: "AND",
                      rules: [
                        {
                          field: "domains",
                          op: "isEmpty",
                          data: null,
                        },
                      ],
                    });
                    setFilterModel({
                      items: [
                        {
                          field: "domains",
                          operator: "isEmpty",
                          value: null,
                        },
                      ],
                      logicOperator: "and",
                      quickFilterValues: [],
                      quickFilterLogicOperator: "and",
                    });
                  }}
                />
              ) : (
                <Button
                  text={"Start"}
                  className="py-1.5 px-3 align-middle mr2 bg-primary-100 text-white"
                  onClick={() =>
                    getMultipleDomains({
                      ...filterData,
                      page: paginationModel?.page,
                      size: paginationModel?.pageSize,
                    })
                  }
                />
              )}

              {selectedData?.length > 0 ? (
                <Button
                  text={"Create Batch"}
                  className="py-1.5 px-3 align-middle bg-secondary text-white"
                  onClick={() => {
                    setIsSaveBatch(true);
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {selectedData?.length > 0 && (
        <div
          className="z-10 w-12 h-12 rounded-full bg-client-50 flex justify-center items-center fixed bottom-16 right-8 cursor-pointer"
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          title="Save Batch"
          onClick={() => {
            setIsSaveBatch(true);
          }}
        >
          <MdSave size={30} className="text-white" />
        </div>
      )}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={record?.map((record, index) => {
            let counter = offset + index + 1;
            const { state, vendors, domains, city, state_code, population } =
              record;
            return {
              counter,
              records: { ...record, counter: index + 1 },
              state,
              vendors,
              city,
              state_code,
              domains,
              population,
            };
          })}
          totalItems={domains?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          paginationModel={paginationModel}
          isLoading={isLoader}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          gridOptions={getRowHeight}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          customSize={2000}
          LineOne={LineOne}
          filterModel={filterModel}
        />
      </div>
    </>
  );
};

export default DomainLocationReport;
