import React, { useEffect } from "react";
import { Button } from "../../../components";
import { useSelector, useDispatch } from "react-redux";
import ReportHead from "../../../reports/Components/Header";
import Picker from "../../../reports/Components/Picker";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { Dropdown } from "primereact/dropdown";
import { get_inbound_group_drd } from "../../../features/inboundGroupSlice";
import dayjs from "dayjs";
import api from "../../../services/api";
import { get_users_drd } from "../../../features/usersSlice";
import {
  get_merchants,
  get_merchants_companies,
} from "../../../features/merchantSlice";
import { toast } from "react-toastify";
import { get_affiliate_report } from "../../../features/reportsSlice";
import { get_affiliate } from "../../../features/callCentersSlice";
const AffiliateReport = () => {
  const dispatch = useDispatch();
  const { inboundDrd } = useSelector((state) => state.inbound);
  const { usersDrd } = useSelector((state) => state.users);
  const { record } = useSelector((state) => state.merchant);
  const { affiliate } = useSelector((state) => state.callCenters);
  const [dispositions, setDispositions] = React.useState([]);
  const [merchants, setMerchant] = React.useState([]);
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const { isLoading, affiliateReport } = useSelector((state) => state.reports);
  const {
    data = [],
    sec_30_per = 0,
    sec_60_per = 0,
    sec_300_per = 0,
    sql_sec_30 = 0,
    sql_sec_60 = 0,
    sql_sec_300 = 0,
    total_answer = 0,
    total_calls = 0,
    total_noanswer = 0,
  } = affiliateReport;
  const [searchText, setSearchText] = React.useState("");
  const currentDate = new Date();
  const initialFilters = {
    start_date:
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        0,
        0,
        0,
        0
      )
        .toISOString()
        .slice(0, 23) + "Z",
    end_date: new Date().toISOString().slice(0, 23) + "Z",
    did: "",
    phone_number: "",
    custom_one: null,
    status: null,
    user_id: null,
    campaign_id: null,
    merchant_id: [],
  };
  const [filters, setFilters] = React.useState(initialFilters);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const getJobLead = async (filter) => {
    setDispositions([]);
    try {
      const res = await api.get(`/api/cc/dids/get_all_statuses`, {
        params: filter,
      });
      if (res.status === 200 && Array.isArray(res.data)) {
        setDispositions(res?.data);
      }
    } catch (err) {
      console.log("🚀 ~ getJobLead ~ err:", err);
    }
  };
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_affiliate_report({
        ...filters,
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  useEffect(() => {
    dispatch(get_affiliate_report(filters));
    dispatch(get_inbound_group_drd());
    getJobLead();
    dispatch(get_users_drd());
    dispatch(get_affiliate());
    // dispatch(get_users_drd());

    // eslint-disable-next-line
  }, []);
  const [currentlyPlayingAudio, setCurrentlyPlayingAudio] =
    React.useState(null);
  const handlePlay = async (vicidial_id) => {
    const audioElement = document.getElementById(vicidial_id);
    if (audioElement) {
      if (currentlyPlayingAudio && currentlyPlayingAudio !== audioElement) {
        currentlyPlayingAudio.pause();
      }
      audioElement.play();
      setCurrentlyPlayingAudio(audioElement);
    }
  };
  const filterData = data?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const columnDefs = [
    {
      field: "counter",
      headerName: "Sr.",
      width: 60,
      filterable: false,
    },
    {
      field: "call_date",
      headerName: "Date",
      width: 190,
    },
    {
      headerName: "Affiliate",
      field: "custom_one",
      flex: 1,
      minWidth: 150,
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Domain",
      field: "site_name",
      flex: 1,
      minWidth: 150,
      cellClassName: "multiline-cell",
    },
    {
      headerName: "DID",
      field: "did",
      flex: 1,
      minWidth: 150,
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Customer Phone",
      field: "phone_number",
      flex: 1,
      minWidth: 150,
    },
    { headerName: "Length", field: "length_in_sec" },
    {
      headerName: "Status",
      field: "status",
      width: 100,
      wrap: true,
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Recording",
      field: "location",
      flex: 1,
      minWidth: 300,
      renderCell: (params) => (
        <audio
          id={params.row.vicidial_id}
          controls
          className="h-10 w-[250px] relative"
          onPlay={() => handlePlay(params.row.vicidial_id)}
        >
          {params.row.location && (
            <source src={params.row.location} type="audio/mpeg" />
          )}
        </audio>
      ),
    },
    { headerName: "Ring Group", field: "campaign_id", flex: 1, minWidth: 100 },
    { headerName: "Forward To", field: "forward_to", flex: 1, minWidth: 100 },
  ];
  useEffect(() => {
    dispatch(get_merchants());
    // getCompanies();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (record?.data?.length) {
      getCompanies();
    }
    // eslint-disable-next-line
  }, [record]);
  function groupByCallCenterId(arr) {
    const groupedArray = [];
    let updatedArr = [];
    if (user.company_name === "Creative IT") {
      updatedArr = [
        ...arr,
        {
          _id: "631ed50d081de88a67e1fcf7",
          company_name: "Creative IT",
          call_center_id: {
            _id: "6422d976b40a3e298ea65fb9",
            url: "call81.towingwiz.com",
          },
        },
      ];
    } else {
      const defaultMerchant = record?.data?.find(
        ({ company_name }) => company_name === user?.company_name
      );
      const callCenterURL = arr?.find(
        ({ call_center_id }) =>
          call_center_id?._id === defaultMerchant?.user_group_id?.call_center_id
      )?.call_center_id;
      updatedArr = [
        {
          _id: defaultMerchant?._id,
          company_name: defaultMerchant?.company_name,
          call_center_id: callCenterURL,
        },
        ...arr,
      ];
    }

    // Create a map to store grouped items
    const map = new Map();

    // Iterate through the array
    updatedArr?.forEach((item) => {
      const callCenterId = item?.call_center_id?._id;
      const companyName = item.company_name;
      const companyId = item._id;
      const url = item?.call_center_id?.url;

      // If callCenterId doesn't exist in the map, add it
      if (!map.has(callCenterId)) {
        map.set(callCenterId, {
          label: url,
          call_center_id: callCenterId,
          items: [{ label: companyName, value: companyId }],
        });
      } else {
        // If callCenterId exists, push the item to its items array
        map
          .get(callCenterId)
          .items.push({ label: companyName, value: companyId });
      }
    });
    // Convert map values to array and push to groupedArray
    for (const value of map.values()) {
      groupedArray.push(value);
    }
    const defaultMerchant = record?.data?.find(
      ({ company_name }) => company_name === user?.company_name
    );
    const center_id =
      user.company_name === "Creative IT"
        ? "6422d976b40a3e298ea65fb9"
        : defaultMerchant?.user_group_id?.call_center_id;
    const merchantDrd = groupedArray?.filter(
      (item) => item.call_center_id === center_id
    );
    return merchantDrd;
  }
  const getCompanies = async () => {
    try {
      const res = await dispatch(get_merchants_companies());
      if (res?.payload?.status === 200) {
        // const groupedData =
        //   res?.payload?.data?.companies_by_call_center?.reduce((acc, item) => {
        //     const key = item?.call_center_id?.url
        //       ? item?.call_center_id?.url
        //       : "Without Call Center";
        //     if (key === "Without Call Center") {
        //       acc.withoutCallCenter = acc.withoutCallCenter || [];
        //       acc.withoutCallCenter.push(item);
        //     } else {
        //       acc[key] = acc[key] || [];
        //       acc[key].push(item);
        //     }
        //     return acc;
        //   }, {});
        const groupedData = groupByCallCenterId(
          res?.payload?.data?.companies_by_call_center
        );
        setMerchant(groupedData);
      }
    } catch (error) {
      console.error("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
    }
  };
  function checkSameCallCenter(_idArray) {
    // Extract the call center ID from the first item in dataArray
    const firstCallCenterId = merchants[0].call_center_id;

    // Check if all _id values belong to the same call center
    for (let i = 0; i < _idArray.length; i++) {
      const currentItem = merchants.find((item) =>
        item.items.some((subitem) => subitem.value === _idArray[i])
      );
      if (!currentItem || currentItem.call_center_id !== firstCallCenterId) {
        return false;
      }
    }

    return true;
  }
  const handleSelectMerchant = (e) => {
    if (filters?.merchant_id?.length) {
      if (!checkSameCallCenter(e)) {
        return toast.error(
          "You can select merchants from the same call center."
        );
      } else {
        setFilters({ ...filters, merchant_id: e });
      }
    } else {
      setFilters({ ...filters, merchant_id: e });
    }
  };
  return (
    <div className="container-fluid">
      <ReportHead heading="Inbound forwarding report" />
      <div className="grid grid-cols-1 gap-2 md:grid-cols-12 mb-3">
        <div className="md:col-span-9">
          <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
            <div className="md:col-span-3 !px-0.5 !mb-3">
              <input
                className="form-control"
                placeholder="DID"
                onChange={(e) =>
                  setFilters({ ...filters, did: e.target.value })
                }
                value={filters.did}
              />
            </div>
            <div className="md:col-span-3 !px-0.5 !mb-3">
              <input
                className="form-control"
                placeholder="Customer Phone#"
                onChange={(e) =>
                  setFilters({ ...filters, phone_number: e.target.value })
                }
                value={filters.phone_number}
              />
            </div>
            <div className="md:col-span-3 !px-0.5">
              <Dropdown
                value={filters.campaign_id}
                onChange={(e) =>
                  setFilters({ ...filters, campaign_id: e.value })
                }
                showClear
                options={inboundDrd}
                filter
                optionValue="group_id"
                optionLabel="group_name"
                placeholder="Select Ring Group"
                className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              />
            </div>
            <div className="md:col-span-3 !px-0.5">
              <Dropdown
                value={filters.status}
                onChange={(e) => setFilters({ ...filters, status: e.value })}
                options={dispositions}
                filter
                optionValue="status"
                optionLabel="status_name"
                placeholder="Select Status"
                showClear
                className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              />
            </div>
            <div className="md:col-span-3 !px-0.5">
              <Dropdown
                value={filters.user_id}
                onChange={(e) => setFilters({ ...filters, user_id: e.value })}
                options={usersDrd}
                filter
                optionValue="_id"
                optionLabel={(option) =>
                  `${option?.first_name || ""} ${option?.last_name || ""}`
                }
                placeholder="Select User"
                showClear
                className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              />
            </div>
            {/* <div className="md:col-span-3 !px-0.5">
              <MultiSelect
                value={filters.merchant_id}
                options={merchants}
                onChange={(e) => {
                  handleSelectMerchant(e.value);
                }}
                optionLabel="label"
                optionGroupLabel="label"
                optionGroupChildren="items"
                placeholder="Select Merchants"
                maxSelectedLabels={2}
                className="w-full"
                // onSelectAll={(e) => onSelectAll(e)}
              />
            </div> */}
            <div className="md:col-span-3 !px-0.5">
              <Dropdown
                value={filters.custom_one}
                onChange={(e) =>
                  setFilters({ ...filters, custom_one: e.value })
                }
                options={affiliate}
                filter
                optionValue="_id"
                optionLabel="name"
                placeholder="Select Affiliate"
                showClear
                className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              />
            </div>
            {/* <div className="md:col-span-3 !px-0.5">
              <FormControl
                sx={{ mt: 0, minWidth: 120, borderColor: "#6666" }}
                fullWidth
                variant="outlined"
              >
                <InputLabel htmlFor="merchant_id">{"Merchant"}</InputLabel>
                <Select
                  sx={{
                    borderColor: "#6666",
                    mt: 0,
                    background: "#fff",
                    height: 36,
                  }}
                  label="Merchant"
                  size="small"
                  fullWidth
                  native
                  name={"merchant_id"}
                  variant="outlined"
                  onChange={(e) =>
                    setFilters({ ...filters, merchant_id: e.target.value })
                  }
                  value={filters.merchant_id}
                >
                  <option value="default" label={user?.company_name}></option>
                  {merchants?.withoutCallCenter?.length > 0 &&
                    merchants?.withoutCallCenter?.map((item) => (
                      <option value={item._id} key={item._id}>
                        {item.company_name}
                      </option>
                    ))}
                  {Object.keys(merchants)?.map((key) => (
                    <optgroup label={key} key={key}>
                      {merchants[key]?.map((item) => (
                        <option value={item._id} key={item._id}>
                          {item.company_name}
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </Select>
              </FormControl>
            </div> */}
            <div className="md:col-span-3 !px-0.5">
              <Button
                text="Search"
                variant="btn_submit"
                onClick={() => dispatch(get_affiliate_report(filters))}
              />
              <Button
                className="ml-2"
                text="Reset"
                variant="btn_cancel"
                onClick={() => {
                  setFilters(initialFilters);
                  dispatch(get_affiliate_report(initialFilters));
                }}
              />
            </div>
          </div>
        </div>

        <div className="md:col-span-3 !px-0.5">
          <Picker
            filterData={filters}
            setFiltersData={setFilters}
            fromKey="start_date"
            toKey="end_date"
          />
        </div>
      </div>

      <div className="flex-cols md:flex flex-wrap justify-between mx-0.5">
        <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[16%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Total Calls</span>
          <span className="text-base font-medium font-pop">
            {total_calls ? total_calls?.toLocaleString() : 0}
          </span>
        </div>
        <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[16%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Answered</span>
          <span className="text-base font-medium font-pop">
            {total_answer ? total_answer?.toLocaleString() : 0}
          </span>
        </div>
        <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[16%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Not Answered</span>
          <span className="text-base font-medium font-pop">
            {total_noanswer ? total_noanswer?.toLocaleString() : 0}
          </span>
        </div>
        <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[16%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">30 Seconds</span>
          <span className="text-base font-medium font-pop">
            {`${sql_sec_30 ? sql_sec_30?.toLocaleString() : 0} (${
              sec_30_per ? sec_30_per?.toLocaleString() : 0
            }%)`}
          </span>
        </div>
        <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[16%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">60 Seconds</span>
          <span className="text-base font-medium font-pop">
            {`${sql_sec_60 ? sql_sec_60?.toLocaleString() : 0} (${
              sec_60_per ? sec_60_per?.toLocaleString() : 0
            }%)`}
          </span>
        </div>
        <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[16%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">300 Seconds</span>
          <span className="text-base font-medium font-pop">
            {`${sql_sec_300 ? sql_sec_300?.toLocaleString() : 0} (${
              sec_300_per ? sec_300_per?.toLocaleString() : 0
            }%)`}
          </span>
        </div>
      </div>

      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={filterData?.map((record, index) => {
            const {
              call_date,
              did,
              site_name,
              phone_number,
              length_in_sec,
              status,
              location,
              forward_to,
              campaign_id,
              user_group,
              custom_one,
            } = record;
            return {
              records: { ...record, _id: index + 1 },
              counter: index + 1,
              call_date: dayjs(call_date).format("ddd, MMM D, YYYY h:mm A"),
              did,
              site_name,
              phone_number,
              length_in_sec,
              status,
              location,
              forward_to,
              campaign_id,
              user_group,
              custom_one:
                affiliate?.find(({ _id }) => _id === custom_one)?.name || "",
            };
          })}
          totalItems={total_calls}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          density="standard"
        />
      </div>
      <div className="d-none"></div>
    </div>
  );
};

export default AffiliateReport;
