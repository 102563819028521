import React, { useEffect, useState } from "react";
import PageHeader from "../../components/molecules/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { get_industry_drd } from "../../features/IndustrySlice";
import { Button, DotsLoader } from "../../components";
import { get_all_geo_vendors } from "../../features/GeoVendorsSlice";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import dayjs from "dayjs";
import api from "../../services/api";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { BiCollapse, BiExpand } from "react-icons/bi";
import { toast } from "react-toastify";
function VendorLocationsByIndustry() {
  const dispatch = useDispatch();

  const { industryDrd } = useSelector((state) => state.industry);
  const { isAllRecordLoading, allVendors } = useSelector(
    (state) => state.geoVendors
  );
  const [filterData, setfilterData] = useState({
    industry_id: null,
    vendor_ids: [],
  });
  const [locations, setLocations] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isFull, setIsFull] = useState(null);
  const [filterConditions, setFilterConditions] = useState([]);
  const activeOption = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];
  const deleteOption = [
    { value: true, label: "Deleted" },
    { value: false, label: "Not Deleted" },
  ];
  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = allVendors?.records?.map(({ _id }) => _id);
      setfilterData({ ...filterData, vendor_ids: updatedArray });
    } else {
      setfilterData({ ...filterData, vendor_ids: [] });
    }
  };
  const isSelected = (data) => {
    if (filterData?.vendor_ids?.length > 0) {
      if (
        filterData?.vendor_ids?.filter((id) => id === data?._id)?.length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  const onSingleSelect = ({ checked, data }) => {
    // if (isLoader) {
    //   return toast.error("Please wait until the previous process is complete.");
    // }
    if (checked) {
      setfilterData({
        ...filterData,
        vendor_ids: [...filterData?.vendor_ids, data?._id],
      });
    } else {
      setfilterData({
        ...filterData,
        vendor_ids: filterData?.vendor_ids?.filter((id) => id !== data?._id),
      });
    }
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
          checked={
            filterData?.vendor_ids?.length === allVendors?.records?.length
          }
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      disableColumnMenu: true, // Add this line to disable the column menu
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={isSelected(params.row.records)}
          onChange={(e) =>
            onSingleSelect({
              checked: e.target.checked,
              data: params.row.records,
            })
          }
          className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
    },
    {
      headerName: "#",
      field: "counter",
      flex: 1,
      width: 60,
      filterable: false,
    },
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      minWidth: 200,
      //   filterable: false,
    },
    { headerName: "Phone", field: "phone", flex: 1, minWidth: 200 },
    {
      headerName: "Company Name",
      field: "company_name",
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Parent Vendor",
      field: "parentName",
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Vendor type",
      field: "vendor_type",
      flex: 1,
      minWidth: 150,
      filterOperators: [
        {
          value: "InNet",
          label: "InNet",
          getApplyFilterFn: (filterItem) => {
            return (params) =>
              String(params.value) === String(filterItem.operator);
          },
        },
        {
          value: "OutNet",
          label: "OutNet",
          getApplyFilterFn: (filterItem) => {
            return (params) =>
              String(params.value) === String(filterItem.operator);
          },
        },
      ],
    },
    { headerName: "State", field: "state", flex: 1, minWidth: 150 },
    { headerName: "City", field: "city", flex: 1, minWidth: 150 },

    { headerName: "HQ Zip code", field: "zip", flex: 1, minWidth: 100 },

    { headerName: "Job Count", field: "job_count", flex: 1, minWidth: 100 },
    { headerName: "Tech(%)", field: "tech_percentage", flex: 1, minWidth: 100 },
  ];
  const locationCol = [
    { headerName: "State", field: "state_id", flex: 1, minWidth: 150 },
    { headerName: "City", field: "city_id", flex: 1, minWidth: 150 },
    { headerName: "Zip code", field: "zip", flex: 1, minWidth: 100 },
    { headerName: "Domains", field: "domains", flex: 1, minWidth: 100 },
  ];
  const getVendors = (filter) => {
    dispatch(
      get_all_geo_vendors({
        page: 1,
        size: 10000,
        filters: filter?.filters,
      })
    );
  };
  const getVendorsLocations = async () => {
    if (!filterData?.industry_id)
      return toast.error("Please select the industry");
    if (!filterData?.vendor_ids.length)
      return toast.error("Please select the vendor");
    setIsLoader(true);
    try {
      const res = await api.post(
        `api/vendors/vendors_locations_by_industry`,
        filterData
      );
      if (res.status === 200) {
        setLocations(res.data);
      }
    } catch (error) {
      console.log("👊 ~ getVendorsLocations ~ error:", error);
    }
    setIsLoader(false);
  };
  useEffect(() => {
    dispatch(get_industry_drd());
    // eslint-disable-next-line
  }, []);

  const handleFullScreen = (data) => {
    if (isFull === data) {
      return setIsFull(null);
    }
    setIsFull(data);
  };
  const handleFilterModelChanges = (filterConditions) => {
    const isAnyValueUndefined = filterConditions.some((condition) => {
      return condition.value === undefined && condition.operator !== "isEmpty";
    });
    if (isAnyValueUndefined) {
      // If any value is undefined, don't apply filtering
      return locations;
    } else {
      // Filter the rows based on the filter conditions
      const filteredRows = locations?.filter((row) => {
        return filterConditions.every((condition) => {
          const { field, operator, value } = condition;
          // Customize this logic based on your filter requirements
          if (operator === "contains") {
            return String(row[field])
              ?.toLowerCase()
              ?.includes(value?.toLowerCase());
          }
          if (operator === "equals") {
            return (
              String(row[field])?.toLowerCase() === String(value)?.toLowerCase()
            );
          }
          if (operator === "startsWith") {
            return String(row[field])
              ?.toLowerCase()
              ?.startsWith(value?.toLowerCase());
          }
          if (operator === "endsWith") {
            return String(row[field])
              ?.toLowerCase()
              ?.endsWith(value.toLowerCase());
          }
          if (operator === "isEmpty") {
            // Check for null, empty string, empty array, and undefined
            const fieldValue = row[field];
            return (
              fieldValue === null ||
              fieldValue === undefined ||
              (Array.isArray(fieldValue) && fieldValue.length === 0) ||
              (typeof fieldValue === "string" && fieldValue.trim() === "")
            );
          }
          if (operator === "isNotEmpty") {
            // Check for non-empty values
            const fieldValue = row[field];
            return !(
              fieldValue === null ||
              fieldValue === undefined ||
              (Array.isArray(fieldValue) && fieldValue.length === 0) ||
              (typeof fieldValue === "string" && fieldValue.trim() === "")
            );
          }
          if (operator === "isAnyOf") {
            return value?.includes(String(row[field]));
          }
          return true;
        });
      });
      return filteredRows;
    }
  };
  const handleFilterModelChange = (e) => {
    setFilterConditions(e?.items);
  };
  const filteredLocations = handleFilterModelChanges(filterConditions);

  function exportLocationDataToExcel() {
    const rows = [];
    const exportDateTime = new Date(); // Get current date and time
    rows.push([
      "Exported on:",
      dayjs(exportDateTime).format("ddd, MMM D, YYYY h:mm A"),
    ]);
    rows.push(["#", "State", "City", "Zip", "Domains"]);

    filteredLocations.forEach((item, index) => {
      const { state_id, city_id, zip, domains } = item;
      const stateName = state_id?.name || "";
      const cityName = city_id?.name || "";

      // Join domains with a space or tab separator
      const domainString = domains ? domains.join("\n") : "";

      rows.push([index + 1, stateName, cityName, zip, domainString]);
    });

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Location Data with Domains");
    const exportRow = worksheet.addRow(rows[0]);
    exportRow.font = { bold: true };

    const headerRow = worksheet.addRow(rows[1]);
    headerRow.font = { bold: true };
    filteredLocations.forEach((item, index) => {
      const { state_id, city_id, zip, domains } = item;
      const stateName = state_id?.name || "";
      const cityName = city_id?.name || "";

      // Join domains with a space or tab separator
      const domainString = domains ? domains.join("\n") : "";

      worksheet.addRow([index + 1, stateName, cityName, zip, domainString]);
    });

    for (let i = 1; i <= 1; i++) {
      worksheet.getColumn(i).alignment = {
        vertical: "middle",
        horizontal: "left",
      };
    }
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 10;
    worksheet.getColumn(5).width = 40; // Adjust the width as needed

    // Write to buffer
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      saveAs(
        blob,
        `location_report(${dayjs(exportDateTime).format("YYYY-M-D")}).xlsx`
      );
    });
  }

  const LineOne = () => {
    return (
      <div className="flex items-center gap-x-2">
        <select
          value={filterData?.industry_id}
          onChange={(e) => {
            setfilterData({
              ...filterData,
              industry_id: e.target.value,
              vendor_ids: [],
            });
            getVendors({
              filters: {
                groupOp: "AND",
                rules: [
                  {
                    field: "active",
                    op: "eq",
                    data: true,
                  },
                  {
                    field: "deleted",
                    op: "eq",
                    data: false,
                  },
                  {
                    field: "industry_ids",
                    op: "eq",
                    data: e.target.value,
                  },
                ],
              },
            });
          }}
          className="border p-1 rounded h-[35px] ml-2 min-w-[250px]"
        >
          <option value="">Select Industry</option>
          {industryDrd.map((option) => (
            <option
              key={option._id}
              value={option._id}
              className="flex justify-between"
            >
              {option?.name}
            </option>
          ))}
        </select>
        <Button
          text={"Get Locations"}
          className="py-1.5 px-3 align-middle mr-2 bg-primary-100 text-white"
          onClick={getVendorsLocations}
        />
        {filteredLocations?.length > 0 ? (
          <Button
            text={"Export"}
            className="py-1.5 px-3 align-middle mr-2 bg-menu text-white"
            onClick={exportLocationDataToExcel}
          />
        ) : null}
      </div>
    );
  };
  return (
    <div>
      {isLoader || isAllRecordLoading ? <DotsLoader /> : null}
      <PageHeader route="Setting > Vendors" LineOne={LineOne} />
      {isFull !== "locations" ? (
        <div className="items-center !m-2 bg-slate-50 !my-2 border rounded p-2">
          <div className="flex w-full justify-between items-center">
            <div className="gap-x-2 flex items-center">
              <span className="text-base font-normal font-mont text-heading">{`Displaying ${
                allVendors?.records?.length ? 1 : 0
              }-${allVendors?.records?.length || 0} of ${
                (allVendors?.totalItems
                  ? allVendors?.totalItems
                  : allVendors?.records?.length) || 0
              } active vendors`}</span>
              {/* <span>|</span> */}
              {filterData.vendor_ids?.length ? (
                <span className="text-sm font-semibold bg-[#fcebc5 font-mont text-heading">{` |  ${
                  filterData.vendor_ids?.length || 0
                } vendors selected`}</span>
              ) : null}
            </div>
            <button
              onClick={() => handleFullScreen("vendors")}
              className="w-8 h-8 hover:bg-slate-200 rounded-full"
            >
              {isFull === "vendors" ? (
                <BiCollapse size={20} />
              ) : (
                <BiExpand size={20} />
              )}
            </button>
          </div>
          <MUIDataTable
            columnDefs={columnDefs}
            //   exportOption={exportOption}
            items={allVendors.records?.map((data, index) => {
              let counter = index + 1;
              let _id = data._id;
              let name =
                data?.first_name +
                " " +
                (data?.last_name ? data?.last_name : "");
              let createdAt = data.created_at
                ? dayjs(data?.created_at).format("ddd, MMM D, YYYY h:mm A")
                : null;
              let {
                parent_id,
                first_name,
                last_name,
                unique_did,
                recipients,
                phone,
                alternate_phone,
                emails,
                username,
                tech_percentage,
                address,
                street,
                zip,
                address_latitude,
                address_longitude,
                active,
                job_count,
                city,
                state,
                industry_ids,
                client_phone_masking,
                company_name,
                vendor_type,
                email_verified,
                email,
                testing,
              } = data;
              return {
                _id,
                id: _id,
                first_name,
                last_name,
                email_verified,
                parentName: allVendors?.records?.find(
                  ({ id }) => id === parent_id
                )?.first_name,
                counter,
                unique_did,
                recipients,
                phone: `${phone ? phone : ""}${
                  alternate_phone ? ", " + alternate_phone : ""
                }`,
                tech_percentage,
                address,
                street,
                zip,
                address_latitude,
                address_longitude,
                records: { ...data },
                name,
                emails,
                email,
                username,
                createdAt,
                active: active ? "Active" : "InActive",
                job_count:
                  job_count >= 0 ? job_count : job_count >= 0 ? job_count : "-",
                city,
                state,
                industry_ids,
                client_phone_masking: client_phone_masking ? 1 : 0,
                company_name:
                  company_name && company_name !== "null" ? company_name : "",
                vendor_type,
                testing: testing ? testing : "Valid",
              };
            })}
            pagination="No"
            searchable="No"
            hideFooter={true}
            displayCount="No"
            toolbar={isFull === "vendors" ? "Yes" : "No"}
            height={isFull === "vendors" ? "70vh" : "30vh"}
          />
        </div>
      ) : null}
      {isFull !== "vendors" ? (
        <div className="bg-white my-3 border rounded">
          <div className="flex w-full justify-between items-center px-2 pt-1">
            <div className="gap-x-2 flex items-center">
              <span className="text-base font-normal font-mont text-heading">{`Displaying ${
                filteredLocations?.length ? 1 : 0
              }-${filteredLocations?.length || 0} of ${
                locations?.length || 0
              } result`}</span>
            </div>
            <button
              onClick={() => handleFullScreen("locations")}
              className="w-8 h-8 hover:bg-slate-200 rounded-full"
            >
              {isFull === "locations" ? (
                <BiCollapse size={20} />
              ) : (
                <BiExpand size={20} />
              )}
            </button>
          </div>
          <MUIDataTable
            columnDefs={locationCol}
            items={filteredLocations?.map((data, index) => {
              const { city_id, state_id, zip, domains } = data;
              return {
                records: { ...data },
                city_id: city_id?.name,
                state_id: state_id?.name,
                zip,
                domains: domains?.length ? domains : "",
              };
            })}
            pagination="No"
            searchable="No"
            hideFooter={true}
            toolbar={isFull === "locations" ? "Yes" : "No"}
            height={isFull === "locations" ? "70vh" : "30vh"}
            displayCount="No"
            onFilterModelChange={handleFilterModelChange}
          />
        </div>
      ) : null}
    </div>
  );
}

export default VendorLocationsByIndustry;
