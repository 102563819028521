import api from "./api";

export const getNationSiteList = async (data) => {
  return await api.get(`/api/nation/national_site`, { params: data });
};
export const createNationSite = async (data) => {
  return await api.post("/api/nation/national_site", data);
};
export const updateNationSite = async (data) => {
  return await api.put(`/api/nation/national_site/${data?.id}`, {
    industry_id: data?.industry_id,
    domain: data?.domain,
  });
};

export const getNationSiteVendorsList = async (data) => {
  return await api.get(`/api/nation/vendor`, { params: data });
};
export const createNationSiteVendors = async (data) => {
  return await api.post("/api/nation/vendor", data);
};
export const updateNationSiteVendors = async (data) => {
  return await api.put(`/api/nation/vendor/${data?.id}`, {
    national_site_id: data?.national_site_id,
    name: data?.name,
  });
};

export const getServiceHours = async (data) => {
  return await api.get(`/api/nation/service_hour`, { params: data });
};
export const createServiceHours = async (data) => {
  return await api.post("/api/nation/service_hour", data);
};
export const updateServiceHours = async (data) => {
  return await api.put(`/api/nation/service_hour/${data?.id}`, {
    hours: data?.hours,
  });
};

export const getRating = async (data) => {
  return await api.get(`/api/nation/rating`, { params: data });
};
export const createRating = async (data) => {
  return await api.post("/api/nation/rating", data);
};
export const updateRating = async (data) => {
  return await api.put(`/api/nation/rating/${data?.id}`, {
    rating: data?.rating,
  });
};

export const getNationVendorList = async (id) => {
  return await api.get(`/api/nation/vendor/${id}`);
};
export const createNationVendor = async (data) => {
  return await api.post("/api/nation/vendor", data);
};
export const updateNationVendor = async (data) => {
  return await api.put("/api/nation/vendor", data);
};
export const getNationSiteZips = async (id) => {
  return await api.get(`api/custom_vendors/nation_site_zips/${id}`);
};
// export const getNationSiteZips = async (id) => {
//   return await api.get(`api/custom_vendors/nation_site_zips/service_zips_by_industry?industry_id=${id}`);
// };
export const addNationSiteZips = async (values) => {
  return await api.post("api/custom_vendors/nation_site_zips", values);
};
export const deleteRating = async (id) => {
  return await api.delete(`/api/nation/rating/${id}`);
};
export const deleteVendor = async (id) => {
  return await api.delete(`/api/nation/vendor/${id}`);
};
export const deleteServiceHours = async (id) => {
  return await api.delete(`/api/nation/service_hour/${id}`);
};
