import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { login as doLogin, logout as doLogout } from "../services/";
import {
  checkToken,
  confirmPassword,
  sendResetEmail,
  vendorLogin,
  sendVendorResetEmail,
  vendorCheckToken,
  vendorConfirmPassword,
} from "../services/authService";
let user = localStorage.getItem("user");
let sslExpiry = localStorage.getItem("sslExpiry");
user = user ? JSON.parse(user) : null;
sslExpiry = sslExpiry ? JSON.parse(sslExpiry) : null;
const initialState = {
  user: user ? user : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  errors: [],
  sslExpiry: sslExpiry ? sslExpiry : [],
  quotingCount: user?.additional_info?.totalPendingQuotingRequests || 0,
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Login user
export const login = createAsyncThunk(
  "auth/login",
  async ({ userData, header }, thunkAPI) => {
    try {
      return await doLogin(userData, header);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Login user
export const vendor_login = createAsyncThunk(
  "auth/vendor_login",
  async ({ userData, header }, thunkAPI) => {
    try {
      return await vendorLogin(userData, header);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Logout User
export const logout = createAsyncThunk("auth/logout", async (thunkAPI) => {
  try {
    return await doLogout();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const send_reset_email = createAsyncThunk(
  "auth/recover",
  async (email, thunkAPI) => {
    try {
      return await sendResetEmail(email);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const check_reset_token = createAsyncThunk(
  "auth/reset",
  async (token, thunkAPI) => {
    try {
      return await checkToken(token);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const reset_password_confirm = createAsyncThunk(
  "auth/confirmPassword",
  async (data, token, thunkAPI) => {
    try {
      return await confirmPassword(data, token);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const vendor_send_reset_email = createAsyncThunk(
  "auth/vendor_recover",
  async (email, thunkAPI) => {
    try {
      return await sendVendorResetEmail(email);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const vendor_check_reset_token = createAsyncThunk(
  "auth/vendor_reset",
  async (token, thunkAPI) => {
    try {
      return await vendorCheckToken(token);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const vendor_reset_password_confirm = createAsyncThunk(
  "auth/vendor_confirmPassword",
  async (data, token, thunkAPI) => {
    try {
      return await vendorConfirmPassword(data, token);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.errors = [];
    },
    logoutUser: (state) => {
      state.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("sslExpiry");
      localStorage.removeItem("expireWarning");
      localStorage.removeItem("iframe");
    },
    hideWarning: (state) => {
      state.sslExpiry = [];
      localStorage.removeItem("sslExpiry");
    },
    setQuotingCount: (state, action) => {
      state.quotingCount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "";
        state.user = {
          ...action.payload?.data?.userDetails,
          expireWarning: true,
        };
        state.sslExpiry = action?.payload?.data?.domainsSSLStatus || [];
        if (action.payload?.status === 200) {
          state.quotingCount =
            action.payload.data.additional_info?.totalPendingQuotingRequests ||
            0;
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...action.payload?.data?.userDetails,
              ...action.payload?.data,
              expireWarning: true,
            })
          );
          localStorage.setItem("iframe", action.payload?.data?.iframe);
          localStorage.setItem("accessToken", action.payload.data.accessToken);
          localStorage.setItem(
            "refreshToken",
            action.payload.data.refreshToken
          );
          localStorage.setItem(
            "sslExpiry",
            JSON.stringify(action?.payload?.data?.domainsSSLStatus || [])
          );
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message =
          !Array.isArray(action.payload) && action.payload === "Unauthorized"
            ? "Invalid username or password "
            : action.payload
            ? action.payload
            : "Unknown Error";
        state.user = null;
        state.errors = Array.isArray(action.payload) && action.payload;
      })
      .addCase(send_reset_email.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(send_reset_email.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;

        state.message = action.payload?.data?.message;
      })
      .addCase(send_reset_email.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.errors = Array.isArray(action.payload) && action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(check_reset_token.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(check_reset_token.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload?.data?.message;
      })
      .addCase(check_reset_token.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.errors = Array.isArray(action.payload) && action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(reset_password_confirm.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(reset_password_confirm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload?.data?.message;
      })
      .addCase(reset_password_confirm.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errors = Array.isArray(action.payload) && action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(vendor_login.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(vendor_login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "";
        if (action.payload?.status === 200) {
          state.user = {
            ...action.payload?.data?.vendor?.vendor_id,
            menu_permissions: ["Vendor Jobs", "Vendor Invoices"],
            role: { name: "Vendor" },
          };
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...action.payload?.data?.vendor?.vendor_id,
              menu_permissions: ["Vendor Jobs", "Vendor Invoices"],
              role: { name: "Vendor" },
            })
          );
          localStorage.setItem("accessToken", action.payload.data.accessToken);
          localStorage.setItem(
            "refreshToken",
            action.payload.data.refreshToken
          );
        }
      })
      .addCase(vendor_login.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message =
          !Array.isArray(action.payload) && action.payload === "Unauthorized"
            ? "Invalid username or password "
            : action.payload
            ? action.payload
            : "Unknown Error";
        state.user = null;
        // toast.error(action.payload==="Unauthorized"?"Invalid username or password ":"Unknown Error w")
        state.errors = Array.isArray(action.payload) && action.payload;
      })
      .addCase(vendor_send_reset_email.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(vendor_send_reset_email.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;

        state.message = action.payload?.data?.message;
      })
      .addCase(vendor_send_reset_email.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.errors = Array.isArray(action.payload) && action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(vendor_check_reset_token.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(vendor_check_reset_token.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload?.data?.message;
      })
      .addCase(vendor_check_reset_token.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.errors = Array.isArray(action.payload) && action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(vendor_reset_password_confirm.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(vendor_reset_password_confirm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload?.data?.message;
      })
      .addCase(vendor_reset_password_confirm.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errors = Array.isArray(action.payload) && action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      });
  },
});

export const { reset, logoutUser, hideWarning, setQuotingCount } =
  authSlice.actions;
export default authSlice.reducer;
