import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/molecules/PageHeader";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";
import api from "../../../services/api";
import AddIPsModal from "./AddIPsModal";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { DotsLoader } from "../../../components";
import Swal from "sweetalert2";
const WhitelistedIPs = () => {
  const [searchText, setSearchText] = useState("");
  const [record, setRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const submitForm = () => {
    setIsEditing(false);
    setEditingRecord(null);
    getIPsList();
  };
  const IPListing = record?.ip?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });

  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false },
    { headerName: "Name", field: "name", flex: 1 },
    { headerName: "IP", field: "ip", flex: 1 },
    { headerName: "Added", field: "added", flex: 1 },
    {
      field: "actions",
      renderCell: (params) =>
        params.row.records ? (
          <div>
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update IP"
            />

            <FaTrashAlt
              onClick={() => deleteRecordHandler(params.row.records._id)}
              className="my_navIcon"
              title="Delete IP"
            />
          </div>
        ) : null,
    },
  ];

  const deleteRecordHandler = async (recordId) => {
    const c = window.confirm(`Are you sure want to delete this IP?`);
    if (!c) return;
    setIsLoading(true);
    try {
      const res = await api.delete(`/api/domains/whitelisted_ip/${recordId}`);
      if (res.status === 200) {
        toast.success("IP deleted successfully");
        getIPsList();
      } else {
        toast.error("IP couldn't be deleted");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("IP couldn't be deleted");
      console.log(err);
    }
  };
  const getIPsList = async () => {
    setIsLoading(true);
    try {
      const res = await api.get("/api/domains/whitelisted_ip");
      if (res.status === 200 || res.status === 201) {
        setRecord(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error || "An error occurred");
      } else {
        toast.error(err?.response?.data || "An error occurred");
      }
    }
  };
  useEffect(() => {
    getIPsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showAlert = async () => {
    const result = await Swal.fire({
      title: "Please Sync all the IPs.",
      text: "",
      icon: "info",
      showCancelButton: false,
      confirmButtonColor: "#ffb700",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sync IPs",
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        confirmButton: "text-lg font-semibold py-2 px-4 rounded",
        cancelButton: "text-lg font-semibold py-2 px-4 rounded",
      },
    });
    if (result.isConfirmed) {
      setIsLoader(true);
      try {
        const res = await api.get(
          "/api/domains/add_whitelisted_ip_on_cloudflare"
        );
        if (res?.status === 200 || res?.status === 201) {
          setIsLoader(false);
          await Swal.fire({
            title: "Confirmed!",
            text: "Your request has been submitted.",
            icon: "success",
          });
        } else {
          setIsLoader(false);
          await Swal.fire({
            title: "Error!",
            text: res?.data?.message || "An error occurred. Please try again.",
            icon: "error",
          });
          getIPsList();
        }
      } catch (error) {
        setIsLoader(false);
        await Swal.fire({
          title: "Error!",
          text:
            error?.response?.data?.message ||
            "An error occurred. Please try again.",
          icon: "error",
        });
        getIPsList();
      }
    }
  };

  useEffect(() => {
    if (record?.show_popup) {
      showAlert();
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {isLoader && <DotsLoader />}
      <PageHeader
        heading="IP Listing"
        onClick={openFormHandler(0)}
        isAllowed={true}
        buttonTitle={`Add IP`}
      />
      {isEditing && (
        <AddIPsModal
          modalTitle="Add IP"
          onCancelForm={cancelFormHandler}
          editingRecord={editingRecord}
          submitForm={submitForm}
        />
      )}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={IPListing?.map((record, index) => {
            let counter = index + 1;
            const { _id, name, ip, is_added } = record;
            return {
              counter,
              records: { ...record },
              _id,
              name,
              ip,
              added: is_added ? "Yes" : "No",
            };
          })}
          totalItems={record?.ip?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
          showCount="No"
        />
      </div>
    </>
  );
};

export default WhitelistedIPs;
