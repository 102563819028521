import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getLinkReport, insertInRobo } from "../services/linkReportServices";

const initialState = {
  isError: false,
  isLoading: false,
  linkReport: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get AllowedIP Data
export const get_link_report = createAsyncThunk(
  "get_link_report",
  async (data, thunkAPI) => {
    try {
      return await getLinkReport(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const insert_in_robo = createAsyncThunk(
  "insert_in_robo",
  async (data, thunkAPI) => {
    try {
      return await insertInRobo(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const linkReportSlice = createSlice({
    name: "siteConversation",
    initialState,
    reducers: {
      linkReportReset: (state) => {
        state.isError = false;
        state.isLoading = false;
        state.linkReport = [];
        state.message = "";
      },
    },
  extraReducers: (builder) => {
    builder
      .addCase(get_link_report.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_link_report.fulfilled, (state, action) => {
        state.isLoading = false;
        state.linkReport = action.payload?.data;
      })
      .addCase(get_link_report.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.linkReport = [];
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(insert_in_robo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(insert_in_robo.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(insert_in_robo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload?action.payload:"Unknown Error")
      });
  },
});

export const { linkReportReset } = linkReportSlice.actions;
export default linkReportSlice.reducer;
