import React, { useEffect, useState } from "react";
import api from "../../services/api";
import SubPermission from "./SubPermission";
import { HiPlus } from "react-icons/hi";
import AddUpdatePermission from "./AddUpdatePermission";
// import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { DotsLoader } from "../../components";
import userPermission from "../../util/userPermission";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
export default function Permissions() {
  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getPermissions = () => {
    setIsLoading(true);
    api
      .get(`/api/permissions`)
      .then((response) => {
        setPermissions(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getPermissions();
  }, []);
  const [permission, setPermission] = useState("");
  const [permission_id, setPermission_id] = useState(null);
  const [subPermissions, setSubPermissions] = useState([]);
  const [addUpdatePermission, showAddUpdatePermission] = useState(false);
  const handleUpdatePermissions = (data) => {
    setPermission(data?.name);
    setSubPermissions(data?.sub_permissions);
    setPermission_id(data?.id);
    showAddUpdatePermission(true);
  };
  const handleAddPermissions = () => {
    setPermission("");
    setSubPermissions([]);
    setPermission_id(null);
    showAddUpdatePermission(true);
  };
  const onCloseUpdatePermissions = (data) => {
    setPermission("");
    setSubPermissions([]);
    setPermission_id(null);
    showAddUpdatePermission(false);
  };

  const deleteRecordHandler = async (record_id) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (!c) return;
    try {
      const res = await api.delete(`/api/permissions/${record_id}`);
      if (res.status === 200) {
        getPermissions();
        toast.success("Permissions removed successfully.");
      } else {
        toast.error(res?.data?.message || "Permissions couldn't be deleted");
      }
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Permissions couldn't be deleted"
      );
    }
  };
  return (
    <div className="p-10">
      {isLoading ? (
        <DotsLoader />
      ) : (
        <div className="flex flex-col gap-2 md:grid md:grid-cols-2 md:gap-12">
          <div>
            <div className="flex flex-col md:flex-row items-center justify-between mb-3 border-b dark:border-white/20">
              <h1 className="text-2xl lg:text-xl font-bold">Permissions</h1>
              {userPermission("Jobs") ? (
                <button
                  onClick={handleAddPermissions}
                  type="button"
                  className="flex items-center py-1.5 !px-3 align-middle !bg-primary-100 border-primary-100 !text-white font-semibold rounded-none"
                >
                  <HiPlus className="text-lg" />
                  <span className="ml-1">Add new permission</span>
                </button>
              ) : null}
            </div>

            {/* Permsission List */}

            <div className="min-h-[334px] p-3 max-h-[calc(100vh-210px)] overflow-y-auto border border-neutral-300 rounded">
              {permissions?.map((item, key) => {
                const { name, sub_permissions } = item;
                const subPermissions = sub_permissions;
                return (
                  <div key={key}>
                    {item?._id ? (
                      item?.sub_permissions?.length ? (
                        <SubPermission
                          children={subPermissions}
                          name={name}
                          id={item._id}
                          item={item}
                          handleUpdatePermissions={handleUpdatePermissions}
                          deleteRecordHandler={deleteRecordHandler}
                        />
                      ) : (
                        <div className="flex items-center justify-between w-full border-">
                          <div className="flex items-center gap-2 py-2">
                            {name}
                          </div>
                          <div className="flex items-center pr-2">
                            <FaEdit
                              key={`${name}-action`}
                              onClick={() =>
                                handleUpdatePermissions({
                                  id: item?._id,
                                  name,
                                  sub_permissions: subPermissions,
                                })
                              }
                              className="h-5 cursor-pointer text-gray-400"
                            />
                            <FaTrashAlt
                              onClick={() => deleteRecordHandler(item?._id)}
                              className="h-4 cursor-pointer text-gray-500 ml-1"
                              title="Delete Permission"
                            />
                          </div>
                          {/* {userPermission("Jobs") ? (
                            <div className="flex items-center pl-1">
                              <FaEdit
                                key={`${name}-action`}
                                onClick={() =>
                                  handleUpdatePermissions({
                                    id: item?._id,
                                    name,
                                    sub_permissions: subPermissions,
                                  })
                                }
                                className="h-5 cursor-pointer text-gray-400"
                              />
                            </div>
                          ) : null} */}
                        </div>
                      )
                    ) : null}
                    {subPermissions?.length > 0 ? (
                      <SubPermission children={subPermissions} deleteRecordHandler={deleteRecordHandler} />
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>

          <div>
            {addUpdatePermission && (
              <AddUpdatePermission
                permission={permission}
                setPermission={setPermission}
                subPermissions={subPermissions}
                setSubPermissions={setSubPermissions}
                showAddUpdatePermission={showAddUpdatePermission}
                getPermissions={getPermissions}
                permission_id={permission_id}
                setIsLoading={setIsLoading}
                onClose={onCloseUpdatePermissions}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
