import api from "./api";

export const apiGetCityByCountryIdStateId = async (data) => {
  const { country_id, state_id, name } = data;
  let url = `/api/location_db/cities/${country_id}`;
  if (state_id) {
    url += `/${state_id}`;
  }
  if (name) {
    url += `/${name}`;
  }
  return await api.get(url);
};
