import React from "react";
import { Button } from "../../../components";
const DomainLogViewModal = (props) => {
  return (
    <div
    className="w-full h-screen z-[100] fixed top-0 left-0 opacity-100 bg-black/[0.75] flex items-center justify-center"
    >
      <div className="relative">
      <div className="absolute top-0 right-0 p-2 z-20 -mt-6 -mr-6">
      <button
        onClick={props.onCancelForm}
        className="bg-white rounded-full text-gray-700 hover:text-red-500 p-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
      <div
      className="z-10 w-auto w-full md:max-w-[1024px] md:min-w-[730px] max-h-[calc(100vh-130px)] overflow-y-auto bg-neutral-100 p-3.5 border-[10px] border-white"
      // ref={modalRef}
      >
        <div className="pt-0 max-w-[994px] h-full px-3.5 m-auto">
          {!props.hideHeader && (
            <header className="bg-primary-100 h-[60px] flex items-center justify-between shadow-lg">
              <h1 className=" text-2xl text-white m-4 font-medium">
                {props.isUpdate
                  ? props.title.replaceAll("Add", "Edit")
                  : props.title}
              </h1>
              <h1 className=" text-2xl text-white m-4 font-medium">
                {`Selected Records: ${props?.data?.length}`}
              </h1>
            </header>
          )}

          <div className="md:p-4">
            
            <div className="table-responsive max-h-[55vh] my-2 bg-white data-records">
              <table className="table table-bordered table-striped">
                <thead className="sticky top-0 bg-white">
                  <tr className="bg-white border-[#ccc]">
                    <th>#</th>
                    <th>domain</th>
                    <th>keyword</th>
                    <th>logic</th>
                    <th>TLD</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody className="max-h-[45vh] mb-2 ">
                  {props.data?.map((obj, index) => (
                    <tr
                      key={index}
                      className="border-b[1px] border-slate-200 p-4 text-black "
                    >
                      <td className="text-black custom-height">{+index + 1}</td>
                      <td className="text-black custom-height">{obj?.domain}</td>
                      <td className="text-black custom-height">{obj?.keyword}</td>
                      <td className="text-black custom-height">{obj?.logic}</td>
                      <td className="text-black custom-height">{obj?.tld}</td>
                      <td className="text-black custom-height">{obj?.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {!props.hideButtons && (
            <div className="!p-4 text-right">
              <Button
                text="Cancel"
                className=""
                onClick={props.onCancelForm}
                variant="btn_cancel"
              />
            </div>
          )}
          {props.CloseOnly && (
            <div className="!p-4 text-right">
              <Button
                text="Cancel"
                className="mx-2"
                onClick={props.onCancelForm}
                variant="btn_cancel"
              />
            </div>
          )}
        </div>
      </div>
    </div>
    </div>
  );
};
export default DomainLogViewModal;
