import React, { useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import AddZipForm from "./AddZipForm";
import AddServiceCostData from "./AddServiceCostData";
import {
  add_bulk_data,
  cost_update,
  get_service_cost_data,
  update_service_cost_data,
} from "../../features/quotePricingSlice";
import { Button, DotsLoader, Loader } from "../../components";
import { MdAdd } from "react-icons/md";
import AddServiceSlot from "./AddServiceSlot";
import { get_job_category } from "../../features/jobCategorySlice";
import Select from "react-select";
import PageHeader from "../../components/molecules/PageHeader";
import { toast } from "react-toastify";
import { get_industry_drd } from "../../features/IndustrySlice";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
const QuotePricing = () => {
  const { isLoading, record, bulk_data } = useSelector(
    (state) => state.quotePricing
  );
  const jobTypes = useSelector((state) => state.jobCategory);
  const industries = useSelector((state) => state.industry);

  const dispatch = useDispatch();
  const [isZip, setIsZip] = React.useState(false);
  const [editZipRecord, setEditZipRecord] = React.useState(null);

  const [isServiceSlot, setIsServiceSlot] = React.useState(false);
  const [isServiceData, setIsServiceData] = React.useState(false);
  const [editServiceData, setEditServiceData] = React.useState(null);
  const [serviceCostId, setServiceCostId] = React.useState();
  const [zipList, setZipList] = React.useState();
  const [jobType, setJobType] = React.useState();
  const [selectedIndustry, setSelectedIndustry] = React.useState();
  
  const getServiceSlots = async () => {
    try {
      const data = await dispatch(get_service_cost_data(jobType?._id));
      if (data?.payload?.status === 200) {
        setServiceCostId(data?.payload?.data[0]?._id);
      }
    } catch (error) {
      console.error(
        "Error ~ file: index.jsx ~ line 37 ~ getServiceSlots ~ error",
        error
      );
    }
  };
  const getjobTypes = async () => {
    try {
      const data = await dispatch(get_job_category({industry_id:selectedIndustry?._id}));
      if (data?.payload?.status === 200) {
        setJobType(data?.payload?.data?.records[0]);
      }
    } catch (error) {
      console.error(
        "Error ~ file: index.jsx ~ line 37 ~ getServiceSlots ~ erro",
        error
      );
    }
  };
  const getIndustry = async () => {
    try {
      const data = await dispatch(get_industry_drd());
      if (data?.payload?.status === 200) {
        setSelectedIndustry(data?.payload?.data[0]);
      }
    } catch (error) {
      console.error(
        "Error ~ file: index.jsx ~ line 37 ~ getServiceSlots ~ erro",
        error
      );
    }
  };
  const getZipList = () => {
    if (record?.filter((data) => data?._id === serviceCostId).length > 0) {
      let selectedIndex = record?.findIndex(
        (data) => data?._id === serviceCostId
      );
      let zip_list = record[selectedIndex]?.zip_list;
      setZipList(zip_list);
    }
  };
  useEffect(() => {
    getZipList();
    // eslint-disable-next-line
  }, [serviceCostId]);

  useEffect(() => {
    if (jobType) {
      getServiceSlots();
    }
    // eslint-disable-next-line
  }, [jobType]);

  useEffect(() => {
    getIndustry();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (jobTypes?.record?.length === 0) {
      getjobTypes();
    } else if (jobTypes?.record?.length > 0) {
      setJobType(jobTypes?.record[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobType?.record]);
  useEffect(() => {
    getjobTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndustry]);

  const openFormHandler = (record) => () => {
    setEditZipRecord(record);
    setIsZip(true);
  };

  const openServiceSlotFormHandler = (record) => () => {
    setEditServiceData(record);
    setIsServiceSlot(true);
  };
  const openServiceDataFormHandler = (record) => () => {
    setEditServiceData(record);
    setIsServiceData(true);
  };
  const cancelFormHandler = () => {
    setEditZipRecord(null);
    setIsZip(false);

    setEditServiceData(null);
    setIsServiceData(false);
    setIsServiceSlot(false);
  };
  const slotIndex = record?.findIndex((slot) => slot?._id === serviceCostId);
  useEffect(() => {
    addBulkData();
    // eslint-disable-next-line
  }, [slotIndex]);

  const addBulkData = () => {
    const location_data = record[slotIndex]?.data?.map((data, index) => {
      let data_id = data?._id;
      let location_data = data?.location_data;

      return {
        data_id,
        location_data,
      };
    });
    dispatch(
      add_bulk_data({
        data: {
          service_cost_id: record[slotIndex]?._id,
          bulk_data: location_data,
        },
      })
    );
  };

  const handleServiceCost = (e, tabs, items, value) => {
    dispatch(
      cost_update({
        service_cost_id: tabs?._id,
        data_id: items?.data_id,
        location_id: value?._id,
        cost: e?.target?.value,
      })
    );
  };

  const updateCost = async () => {
    try {
      const res = await dispatch(update_service_cost_data(bulk_data));
      if (res?.payload?.status === 200) {
        toast.success("Service Cost Slot be updated");
      } else {
        errorMessage({payload:res.payload, action:"Service Cost Slot", msg:'updated'})
      }
    } catch (error) {
      errorMessage({payload:error, action:"Service Cost Slot", msg:'updated'})
    }
  };

  return (
    <div className="container-fluid">
      {
        isLoading && <DotsLoader/>
      }
      {isZip && (
        <AddZipForm
          editZipRecord={editZipRecord}
          modalTitle=""
          onCancelForm={cancelFormHandler}
          zip_list={zipList}
        />
      )}
      {isServiceData && (
        <AddServiceCostData
          editServiceData={editServiceData}
          modalTitle="Add Service Cost Data"
          onCancelForm={cancelFormHandler}
          serviceCostId={serviceCostId}
        />
      )}
      {isServiceSlot && (
        <AddServiceSlot
          editServiceData={editServiceData}
          modalTitle="Add Service Cost Slot"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Quote Pricing"
        heading="Quote Pricing Listing"
        onClick={openServiceSlotFormHandler(0)}
        isAllowed={userPermission("Service Cost")}
      />
      <div className="flex flex-col md:flex-row justify-center mt-3 gap-4">
        {selectedIndustry && (
          <div className="w-full md:w-2/4">
            <label className="font-bold mb-1">Select Industry</label>
            <Select
              options={industries?.industryDrd}
              defaultValue={selectedIndustry}
              getOptionLabel={(options) => options?.name}
              getOptionValue={(options) => options?._id}
              className="w-full"
              placeholder="Select"
              onChange={(e) => setSelectedIndustry(e)}
            />
          </div>
        )}
        {jobType && (
          <div className="w-full md:w-2/4">
            <label className="font-bold mb-1">Select Service</label>
            <Select
              options={jobTypes?.record?.records}
              defaultValue={jobType}
              getOptionLabel={(options) => options?.name}
              getOptionValue={(options) => options?._id}
              className="w-full"
              placeholder="Select"
              onChange={(e) => setJobType(e)}
            />
          </div>
        )}
      </div>
      { jobTypes?.isLoading ? (
        <Loader />
      ) : record?.length > 0 ? (
        <div className="bg-white my-3 border rounded p-4 overflow-y-auto">
          {jobType?.drop_location_required ||
          record[slotIndex]?.data?.length === 0 ? (
            <div
              className="w-12 h-12 rounded-full bg-client-50 flex justify-center items-center fixed bottom-16 right-8"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Add Service Cost Data"
              onClick={openServiceDataFormHandler(0)}
            >
              <MdAdd size={30} className="text-white" />
            </div>
          ) : null}

          <Tabs>
            <TabList className="!flex !flex-wrap !pl-0 react-tabs__tab-list !border-b-[#dee2e6]">
              {record?.map((tab) => {
                return (
                  <Tab
                    key={tab?._id}
                    onClick={() => setServiceCostId(tab?._id)}
                    selectedClassName="!bg-white !border-2 !border-[#5f5f5f] !border-b-0"
                    className="!flex !justify-between !rounded-t !mx-2 !py-4 bg-[#f9f9f9] border border-[#dee2e6] -bottom-px relative py-1.5 px-3 cursor-pointer flex-[0_0_23%] w-full md:max-w-[23%]"
                  >
                    <p className="font mb-0 truncate w-full text-sm">
                      {tab?.payment_type} ({tab?.start_time % 12 || 12}
                      {tab?.start_time < 12 ? " AM" : " PM"} to{" "}
                      {tab?.end_time % 12 || 12}
                      {tab?.end_time < 12 ? " AM" : " PM"})
                    </p>
                    <FaEdit
                      key={tab?._id + "_edit"}
                      onClick={openServiceSlotFormHandler(tab)}
                      className="my_navIcon ml-1 float-right"
                      title="Update Service Slot"
                    />
                  </Tab>
                );
              })}
            </TabList>
            {record?.map((tabs) => {
              const items = tabs?.data?.map((record) => {
                let distance = record?.location_data?.title;
                let all_states = record?.location_data?.locations_price[0];
                let new_york = record?.location_data?.locations_price[1];
                let los_angeles = record?.location_data?.locations_price[2];
                let data_id = record?._id;
                return {
                  distance,
                  all_states,
                  new_york,
                  los_angeles,
                  data_id,
                };
              });

              return (
                <TabPanel key={tabs?._id}>
                  <div className="flex flex-col">
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                          <table className="min-w-full">
                            <thead className="border">
                              <tr>
                                {tabs?.headers?.map((head, key) => (
                                  <th
                                    scope="col"
                                    key={key}
                                    className="text-[15px] font-semibold text-gray-900 p-3 text-left font-mont border"
                                  >
                                    {head?.title}
                                    {head?.is_edit && (
                                      <FaEdit
                                        key={key + "_edit"}
                                        onClick={openFormHandler(head)}
                                        className="my_navIcon ml-1"
                                        title="Update Zip Codes"
                                      />
                                    )}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {items?.map((item, itemKey) => (
                                <tr
                                  key={itemKey}
                                  className="bg-gray-100 border-b"
                                >
                                  {Object.values(item).map((value, key) => {
                                    return (
                                      <>
                                        {key === 4 ? null : (
                                          <td key={key}>
                                            {key === 0 ? (
                                              <label>{value}</label>
                                            ) : (
                                              <input
                                                className="border border-[#767676] p-0.5 rounded-sm"
                                                value={value.cost}
                                                onChange={(e) =>
                                                  handleServiceCost(
                                                    e,
                                                    tabs,
                                                    item,
                                                    value
                                                  )
                                                }
                                                type="number"
                                              />
                                            )}
                                          </td>
                                        )}
                                      </>
                                    );
                                  })}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              );
            })}
          </Tabs>
          <div className="grid grid-cols-1">
            <div className="col-12 p-2 justify-center flex">
              <Button
                text={"Update"}
                onClick={updateCost}
                variant="btn_submit"
              />
            </div>
          </div>
        </div>
      ) : isLoading || jobTypes?.isLoading ? (
        <Loader />
      ) : (
        <p>No Records found</p>
      )}
    </div>
  );
};

export default QuotePricing;
