import React, { useState } from "react";
import { GoSearch } from "react-icons/go";
import { HiOutlineChevronDown } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
const DropdownWithGroupedCheckboxes = ({
  groupedCategories,
  onSelectCategory,
  selectedCategories,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const filteredData = groupedCategories
    ?.map((item) => {
      if (item.name?.toLowerCase()?.includes(searchValue?.toLowerCase())) {
        return {
          industry_id: item.industry_id,
          name: item.name,
          categories: item.categories,
        };
      } else {
        const matchedCategories = item.categories.filter((category) =>
          category.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
        );
        if (matchedCategories.length > 0) {
          return {
            industry_id: item.industry_id,
            name: item.name,
            categories: matchedCategories,
          };
        }
      }
      return null; // Filter out the main object if no match found
    })
    .filter(Boolean);
  const dropdownRef = React.useRef(null);

  const groupChecked = (industry_id) => {
    const selectedIndustry = selectedCategories.find(
      (item) => item.id === industry_id
    );
    const group = groupedCategories.find(
      (data) => data?.industry_id === industry_id
    );
    return group?.categories?.every((item) =>
      selectedIndustry?.categories?.includes(item._id)
    );
  };

  const categoryChecked = ({ industry_id, category_id }) => {
    const industry = selectedCategories.find(
      (item) => item.id === industry_id
    );
    if (industry) {
      return industry?.categories.includes(category_id);
    } else {
      return false;
    }
  };
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setShowOptions(false);
    }
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  return (
    <div>
      <div className="relative" ref={dropdownRef}>
        <button
          onClick={() => setShowOptions(!showOptions)}
          className="block w-full !border !border-[#a9a9a9] bg-white rounded py-1.5 px-2 flex items-center justify-between h-[40px]"
        >
          <span className="flex items-center text-base truncate justify-between w-full text-[#6c757d]">
            Select Categories
            <div className="flex items-center">
              <HiOutlineChevronDown className=" cursor-pointer" size={20} />
            </div>
          </span>
        </button>
        {showOptions && (
          <div className="absolute mt1 m bg-white border-gray-300 w-auto min-w-[330px] right-0 rounded shadow-dropdown z-10">
            <div className="flex flex-row items-center justify-between !py-3 !px-5 bg-[#f8f9fa] h-16">
              <input
                type="checkbox"
                className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
              />
              <div className="relative w-full px-3.7">
                <input
                  className={
                    "appearance-none border rounded w-full !py-2 !pl-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-sm border w-full !pl-15 h-[38px]"
                  }
                  style={{ minHeight: 31 }}
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
                <div className="absolute top-0 right-2 flex items-center h-full pr-3">
                  <GoSearch className="text-[#6c757d]" />
                </div>
              </div>
              <div
                className="flex items-center justify-center rounded-full hover:bg-[#eaecef] !p-1 cursor-pointer"
                onClick={() => setShowOptions(!showOptions)}
              >
                <RxCross2 className="text-[#6c757d]" size={20} />
              </div>
            </div>
            <div className="!pt-3 h-[250px] overflow-y-auto">
              {filteredData?.map((group) => (
                <div
                  key={group.industry_id}
                  className={`${
                    groupChecked(group?.industry_id)
                      ? "text-[#4338CA] bg-[#EEF2FF]"
                      : ""
                  }`}
                >
                  <div className="flex items-center !py-3 !px-5">
                    <input
                      type="checkbox"
                      checked={groupChecked(group?.industry_id)}
                      onChange={(e) =>
                        onSelectCategory({
                          industry_id: e.target.value,
                          type: "industry",
                          isChecked: e.target.checked,
                        })
                      }
                      // className="mr-2"
                      className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
                      value={group.industry_id}
                    />
                    <label
                      className={`text-base font-medium text-[#495057] ${
                        groupChecked(group?.industry_id)
                          ? "text-[#4338CA]"
                          : ""
                      }`}
                    >
                      {group.name}
                    </label>
                  </div>
                  {group.categories.map((category) => (
                    <div
                      key={category._id}
                      className={`${
                        categoryChecked({
                          industry_id: category?.industry_id,
                          category_id: category?._id,
                        })
                          ? "text-[#4338CA] bg-[#EEF2FF]"
                          : ""
                      }`}
                    >
                      <div
                        className={`ml-10 flex items-center !py-1.5 !px-2.5 `}
                      >
                        <input
                          type="checkbox"
                          checked={categoryChecked({
                            industry_id: category?.industry_id,
                            category_id: category?._id,
                          })}
                          onChange={(e) =>
                            onSelectCategory({
                              category_id: e.target.value,
                              industry_id: group.industry_id,
                              type: "category",
                              isChecked: e.target.checked,
                            })
                          }
                          className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
                          value={category._id}
                        />
                        <label
                          className={`text-base text-[#495057] ${
                            categoryChecked({
                              industry_id: category?.industry_id,
                              category_id: category?._id,
                            })
                              ? "text-[#4338CA]"
                              : ""
                          }`}
                        >
                          {category.name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownWithGroupedCheckboxes;
