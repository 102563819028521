import React from "react";

import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../components";

import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import {
  create_geo_vendors,
  get_cities,
  get_zips,
  update_geo_vendors,
} from "../../features/GeoVendorsSlice";

const AddUpdateProvider = ({ editingRecord, onCancelForm, modalTitle }) => {
  let initialValues = {
    name: "",
    phone: "",
    state_id: "",
    city_id: "",
    zip_id: "",
    description: "",
    parent_id: "",
    phone2: "",
  };
  const dispatch = useDispatch();
  const { isLoading, states, cities, zips } = useSelector(
    (state) => state.geoVendors
  );
  const { record } = useSelector((state) => state.geoVendors);
  const [errors, setErrors] = React.useState([]);

  if (editingRecord) {
    const {
      id,
      name,
      phone,
      state_id,
      city_id,
      zip_id,
      description,
      parent_id,
      phone2,
    } = editingRecord;
    initialValues = {
      id,
      name,
      phone,
      phone2,
      state_id: state_id?.id,
      city_id: city_id?.id,
      zip_id: zip_id?.id,
      parent_id: parent_id,
      description,
    };
  }
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (formik.values.name && formik.values.phone) {
        if (/^\d+$/.test(formik.values.phone)) {
          const action = editingRecord ? "updated" : "created";
          const apiFunction = editingRecord
            ? update_geo_vendors
            : create_geo_vendors;
          const successMessage = editingRecord
            ? "Vendor updated successfully"
            : "Vendor created successfully";
          try {
            const res = await dispatch(apiFunction(values));
            if (res?.payload?.status === 200) {
              toast.success(successMessage);
              onCancelForm();
            } else {
              errorMessage({
                payload: res.payload,
                setErrors: setErrors,
                action: "Vendor",
                msg: action,
              });
            }
          } catch (error) {
            errorMessage({
              payload: error,
              setErrors: setErrors,
              action: "Vendor",
              msg: action,
            });
          }
        } else {
          toast.error("Primary Phone must be a numeric value");
        }
      } else {
        toast.error("Name and Primary phone are required fields");
      }
    },
  });
  React.useEffect(() => {
    if (formik.values.state_id) {
      dispatch(get_cities({ state_id: formik.values.state_id }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.state_id]);
  React.useEffect(() => {
    if (formik.values.city_id) {
      dispatch(get_zips(formik.values.city_id));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.city_id]);
  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="w-full grid mt-4 md:grid-cols-2 gap-x-5">
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="parent_id"
              label="Parent Vendor"
              formik={formik}
              options={record}
              valueProp="id"
              labelProp="name"
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="name"
              label="Name"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="phone"
              label="Primary Phone"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="phone2"
              label="Alternate Phone"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="state_id"
              label="State"
              formik={formik}
              options={states}
              valueProp="id"
              labelProp="name"
            />
          </div>
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="city_id"
              label="City"
              formik={formik}
              options={cities}
              valueProp="id"
              labelProp="name"
            />
          </div>
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="zip_id"
              label="Zip Code"
              formik={formik}
              options={zips}
              valueProp="id"
              labelProp="zip"
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="description"
              label="Description"
              formik={formik}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateProvider;
