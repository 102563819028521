import React from "react";

import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  create_card_type,
  update_card_type,
} from "../../features/cardTypesSlice";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";

const CardTypeForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.cardTypes);
  const [errors, setErrors] = React.useState([]);

  let initialValues = {
    name: "",
    status: true,
  };

  if (editingRecord) {
    const { _id, name, status } = editingRecord;
    initialValues = {
      id: _id,
      name,
      status,
    };
  }
  const formik = useFormik({
    initialValues,

    validateOnBlur: true,
    onSubmit: async (values) => {
      if (!editingRecord) {
        try {
          const res = await dispatch(create_card_type(values));
          if (res.payload?.status === 200) {
            toast.success("Card Type created");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Card Type",
              msg: "created",
            });
          }
        } catch (error) {
          toast.error("Card Type couldn't be created");
        }
      } else {
        try {
          const res = await dispatch(update_card_type(values));
          if (res.payload?.status === 200) {
            toast.success("Card Type updated");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Card Type",
              msg: "updated",
            });
          }
        } catch (error) {
          toast.error("Card Type couldn't be updated");
        }
      }
    },
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form>
          <div className="mb-3 mt-4">
            <FormInput
              errors={errors}
              name="name"
              label="Name"
              formik={formik}
            />
          </div>

          <FormSelectInput
            errors={errors}
            name="status"
            label="Status"
            formik={formik}
            options={[
              { label: "Active", value: true },
              { label: "InActive", value: false },
            ]}
            valueProp="value"
            labelProp="label"
          />
        </form>
      </Modal>
    </>
  );
};

export default CardTypeForm;
