import React from "react";
import VehicleInfoSection from "../Common/Sections/VehicleInfoSection";
import VehicleTrailerDetailsSection from "../Common/Sections/VehicleTrailerDetailsSection";
const TowingServiceSection = ({ formik, serviceName }) => {
  return (
    <>
      <VehicleInfoSection
        serviceName={serviceName}
        formik={formik}
        fieldsToShow={[
          "car_year",
          "car_make",
          "car_model",
          "is_neutral",
          "car_color",
          "drivetrain",
          "duty_type",
          "is_tires_ok",
          "car_type",
          "tow_type",
        ]}
      />
      <VehicleTrailerDetailsSection
        formik={formik}
        fieldsToShow={[
          ...(formik.values.extra_fields?.duty_type &&
          formik.values.extra_fields?.duty_type !== "light_duty"
            ? [
                "is_trailer",
                "weight",
                "height",
                "length",
                "width",
                "axles_count",
                "is_dually",
                "loaded_with",
              ]
            : []),
          formik.values?.extra_fields?.tow_type === "secondary"
            ? "payout_section"
            : null,
        ].filter(Boolean)}
      />
    </>
  );
};

export default TowingServiceSection;
