const FormSelectInput = ({
  name,
  label,
  formik,
  options = [],
  valueProp,
  labelProp,
  convertor,
  disabled,
  onChange,
  errors,
  nullOption,
  labelProp2,
  value,
  required,
  onFocus,
  onClick,
  alternatelabelProp,
  className,
  isCustomOption,
}) => {
  return (
    <div className="flex flex-col">
      {label ? (
        <label
          className="font-semibold text-xs text-gray-500 mb-1"
          htmlFor={name}
        >
          {label}
        </label>
      ) : null}

      <select
        defaultValue=""
        id={name}
        className={[
          className ? className : `border rounded-md border-gray-300 p-2`,
          `${
            formik?.values[name] ||
            value ||
            typeof formik?.values[name] === "boolean" ||
            typeof value === "boolean"
              ? "!text-black"
              : "text-gray-400"
          }`,
        ].join(" ")}
        label={label}
        size="small"
        fullWidth
        native
        name={name}
        variant="outlined"
        value={
          value
            ? value
            : value === ""
            ? value
            : value === false
            ? value
            : formik?.values[name]
        }
        onChange={({ target: { value } }) => {
          if (convertor) {
            value = convertor(value);
          }
          if (formik && !onChange) {
            formik?.setFieldValue(name, value);
          }
          if (onChange && formik) {
            onChange(value);
          }
          if (onChange && !formik) {
            onChange(value);
          }
        }}
        onFocus={onFocus}
        onClick={onClick}
        onBlur={formik?.handleBlur}
        disabled={disabled}
        error={formik?.errors[name] && formik?.touched[name] ? true : false}
      >
        {/* {nullOption !== "No" ? <option label=""></option> : null} */}
        {isCustomOption ? (
          <option value="" selected>
            {`${
              options?.length
                ? isCustomOption && typeof isCustomOption !== "boolean"
                  ? isCustomOption
                  : "Select an option"
                : "No option found"
            }`}
          </option>
        ) : null}
        {options.length > 0 &&
          options.map((option) => (
            <option key={option[valueProp]} value={option[valueProp]}>
              {option[labelProp]
                ? `${option[labelProp]} ${
                    labelProp2 ? " | " + option[labelProp2] : ""
                  }`
                : option[valueProp]}
            </option>
          ))}
      </select>
      {formik?.errors[name] && formik?.touched[name] && (
        <small
          style={{
            fontSize: 12,
            marginTop: 0,
            paddingLeft: 16,
            color: "#D32F2F",
          }}
        >
          {formik?.errors[name]}
        </small>
      )}
      {errors !== undefined &&
        errors?.length > 0 &&
        errors?.map((error, index) => {
          return (
            <>
              {error[name] && (
                <small
                  style={{
                    fontSize: 12,
                    marginTop: 0,
                    paddingLeft: 16,
                    color: "#D32F2F",
                    background: "transparent",
                  }}
                  key={index}
                >
                  {error[name]
                    .replaceAll(`"${name}"`, label)
                    .replaceAll(`${name}`, label)}
                </small>
              )}
            </>
          );
        })}

      {errors !== undefined && typeof errors === "object" && (
        <>
          {errors[name] && (
            <small
              style={{
                fontSize: 12,
                marginTop: 0,
                paddingLeft: 16,
                color: "#D32F2F",
                background: "transparent",
              }}
            >
              {errors[name][0]}
            </small>
          )}
        </>
      )}
    </div>
  );
};

export default FormSelectInput;
