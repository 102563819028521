import { DataGridPro } from "@mui/x-data-grid-pro";
import React from "react";
import { useSelector } from "react-redux";
import { Button } from "../../../../components";

const SummaryDetailModal = ({ onCancelForm, data }) => {
  const { isLoading } = useSelector((state) => state.geoVendors);
  const modalRef = React.useRef(null);
  const [searchText, setSearchText] = React.useState("");
  const { record } = useSelector((state) => state.industry);
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      onCancelForm();
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onCancelForm();
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  
  const columnDefs = [
    {
      headerName: "#",
      field: "counter",
      width: 60,
      filterable: false,
    },
    {
      field: "industry_name",
      headerName: "Industry",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Primary Keyword Count",
      field: "primary_keyword_count",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Service Keyword Count",
      field: "service_keyword_count",
      flex: 1,
      minWidth: 100,
    },
  ];
  const sortedData = data
    ?.map((item) => {
      const industryName = record?.records?.find(
        (industry) => industry._id === item?.industry_id
      )?.name;
      return {
        ...item,
        name: industryName || "",
      };
    })
    .sort((a, b) => a?.name?.localeCompare(b?.name));
    const filteredIndustries = sortedData?.filter((e) => {
        return Object.keys(e)?.some(
          (key) =>
            e[key] &&
            e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
        );
      });
  return (
    <>
      <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
        <div className="container-fluid mt-2  text-center">
          <div
            className="inline-block bg-white w-3/4 my-0 mx-auto p-4 bg-red-600 rounded  max-h-[87vh]"
            ref={modalRef}
          >
            <header className="flex items-center justify-center shadow-lg bg-primary-100 text-white h-[50px] mb-3 text-xl">
              <h1 className="text-2xl text-white m-4 font-medium">
                Selected Domains List
              </h1>
            </header>
            <div className="flex flex-row justify-between">
              <input
                placeholder="Search"
                onChange={(e) => setSearchText(e.target.value)}
                className="!w-1/3 mb-2 placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md !py-2 px-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                value={searchText}
              />
              <span className="text-xl">{filteredIndustries?.length} records found</span>
            </div>

            <div className="h-[50vh] mb-2">
              <DataGridPro
                rows={
                    filteredIndustries?.length
                    ? filteredIndustries?.map((record, index) => {
                        const counter = index + 1;
                        const {
                          industry_id,
                          primary_keyword_count,
                          service_keyword_count,
                          name
                        } = record;
                        return {
                          id: industry_id,
                          records: { ...record, id: industry_id },
                          counter,
                          industry_name:name,
                          primary_keyword_count,
                          service_keyword_count,
                        };
                      })
                    : []
                }
                columns={columnDefs}
                loading={isLoading}
              />
            </div>
            <Button
              text="Cancel"
              variant="btn_cancel"
              className="ml-3"
              onClick={onCancelForm}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SummaryDetailModal;
