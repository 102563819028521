import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addImages,
  addLeadTags,
  addNote,
  changeIndustry,
  closeJob,
  deleteLeadTags,
  getAmountTransactions,
  getHeaderSearch,
  getJobs,
  getJobsById,
  getLeadTags,
  getNotes,
  handleTech,
  jobCallLogs,
  jobPaymentCharged,
  jobPaymentMethod,
  jobPaymentRefund,
  notifyTech,
  sendImageForm,
  sendLocationForm,
  setJobStatus,
  updateCost,
  updateInvoiceDetail,
  updateJob,
} from "../services/jobs";

const initialState = {
  isError: false,
  isLoading: false,
  loader: { headerSearch: false, timeLine: false, jobListing: false },
  isSuccess: false,
  record: [],
  jobNotes: [],
  message: "",
  quote: null,
  transactions: [],
  leadTags: [],
  multipleJobs: [],
};

const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Jobs Data
export const get_jobs_data = createAsyncThunk(
  "/api/get_jobs_data",
  async (id, thunkAPI) => {
    try {
      return await getJobs(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Jobs Data
export const get_jobs_data_by_phone = createAsyncThunk(
  "/api/get_jobs_data_by_phone",
  async (id, thunkAPI) => {
    try {
      return await getJobs(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_jobs_by_id = createAsyncThunk(
  "/api/get_jobs_by_id",
  async (id, thunkAPI) => {
    try {
      return await getJobsById(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const update_invoice_details = createAsyncThunk(
  "/api/update_invoice_details",
  async (data, thunkAPI) => {
    try {
      return await updateInvoiceDetail(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const handle_tech = createAsyncThunk(
  "/api/handle_tech",
  async (data, thunkAPI) => {
    try {
      return await handleTech(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const add_lead_tag = createAsyncThunk(
  "/api/add_lead_tag",
  async (data, thunkAPI) => {
    try {
      return await addLeadTags(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const delete_lead_tag = createAsyncThunk(
  "/api/delete_lead_tag",
  async (data, thunkAPI) => {
    try {
      return await deleteLeadTags(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_lead_tag = createAsyncThunk(
  "/api/get_lead_tag",
  async (id, thunkAPI) => {
    try {
      return await getLeadTags(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const update_job = createAsyncThunk(
  "/api/update_job",
  async (data, thunkAPI) => {
    try {
      return await updateJob(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const close_job = createAsyncThunk(
  "/api/close_job",
  async (data, thunkAPI) => {
    try {
      return await closeJob(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const set_job_status = createAsyncThunk(
  "/api/set_job_status",
  async (data, thunkAPI) => {
    try {
      return await setJobStatus(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const change_industry = createAsyncThunk(
  "/api/change_industry",
  async (data, thunkAPI) => {
    try {
      return await changeIndustry(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const job_call_logs = createAsyncThunk(
  "/api/job_call_logs",
  async (data, thunkAPI) => {
    try {
      return await jobCallLogs(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const add_images = createAsyncThunk(
  "/api/add_images",
  async (data, thunkAPI) => {
    try {
      return await addImages(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const update_cost = createAsyncThunk(
  "/api/update_cost",
  async (data, thunkAPI) => {
    try {
      return await updateCost(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Job Payment Method
export const job_payment_card = createAsyncThunk(
  "job_payment_card",
  async (data, thunkAPI) => {
    try {
      return await jobPaymentMethod(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const job_payment_charged = createAsyncThunk(
  "job_payment_charged",
  async (data, thunkAPI) => {
    try {
      return await jobPaymentCharged(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add Note
export const add_note = createAsyncThunk(
  "add_note",
  async ({ id, note }, thunkAPI) => {
    try {
      return await addNote(id, note);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add Note
export const get_notes = createAsyncThunk("get_notes", async (id, thunkAPI) => {
  try {
    return await getNotes(id);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
// Send Image Form
export const send_image_form = createAsyncThunk(
  "send_image_form",
  async (id, thunkAPI) => {
    try {
      return await sendImageForm(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const send_location_form = createAsyncThunk(
  "send_location_form",
  async (id, thunkAPI) => {
    try {
      return await sendLocationForm(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Transactions
export const get_transactions = createAsyncThunk(
  "get_transactions",
  async (data, thunkAPI) => {
    try {
      return await getAmountTransactions(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Payment Refund
export const job_payment_refund = createAsyncThunk(
  "job_payment_refund",
  async (data, thunkAPI) => {
    try {
      return await jobPaymentRefund(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Payment Refund
export const header_search = createAsyncThunk(
  "header_search",
  async (data, thunkAPI) => {
    try {
      return await getHeaderSearch(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const notify_tech = createAsyncThunk(
  "notify_tech",
  async (data, thunkAPI) => {
    try {
      return await notifyTech(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const JobsSlice = createSlice({
  name: "getJobs",
  initialState,
  reducers: {
    jobsReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.jobNotes = [];
      state.message = "";
      state.quote = null;
      state.transactions = [];
      state.leadTags = [];
      state.multipleJobs = [];
    },
    clearNotes: (state) => {
      state.jobNotes = [];
    },
    updateNotes: (state, action) => {
      state.jobNotes.unshift(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_jobs_data.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_jobs_data.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_jobs_data.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_jobs_data_by_phone.pending, (state) => {
        state.isLoading = true;
        state.multipleJobs = [];
      })
      .addCase(get_jobs_data_by_phone.fulfilled, (state, action) => {
        state.isLoading = false;
        state.multipleJobs = action.payload?.data;
      })
      .addCase(get_jobs_data_by_phone.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(handle_tech.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(handle_tech.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(handle_tech.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(update_job.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_job.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_job.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(close_job.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(close_job.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(close_job.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(set_job_status.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(set_job_status.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(set_job_status.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(change_industry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(change_industry.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(change_industry.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(add_images.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_images.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(add_images.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(update_cost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_cost.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(update_cost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(job_payment_card.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(job_payment_card.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(job_payment_card.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_jobs_by_id.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_jobs_by_id.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_jobs_by_id.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(add_note.pending, (state) => {
        state.loader.timeLine = true;
      })
      .addCase(add_note.fulfilled, (state, action) => {
        state.loader.timeLine = false;
        state.jobNotes.unshift(action.payload.data);
      })
      .addCase(add_note.rejected, (state, action) => {
        state.loader.timeLine = false;
      })
      .addCase(get_notes.pending, (state) => {
        state.loader.timeLine = true;
      })
      .addCase(get_notes.fulfilled, (state, action) => {
        state.jobNotes = action.payload.data;
        state.loader.timeLine = false;
      })
      .addCase(get_notes.rejected, (state) => {
        state.loader.timeLine = false;
      })
      .addCase(send_image_form.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(send_image_form.fulfilled, (state, action) => {
        toast.success("Image form sent Successfully");
        state.isLoading = false;
      })
      .addCase(send_image_form.rejected, (state, action) => {
        toast.error(action.payload || "Image form couldn't be sent");
        state.isLoading = false;
      })

      .addCase(send_location_form.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(send_location_form.fulfilled, (state, action) => {
        toast.success("Location form sent Successfully");
        state.isLoading = false;
      })
      .addCase(send_location_form.rejected, (state, action) => {
        toast.error(action.payload || "Location form couldn't be sent");
        state.isLoading = false;
      })
      .addCase(job_payment_charged.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(job_payment_charged.fulfilled, (state, action) => {
        state.jobNotes.unshift(action.payload.data.job_note);
        state.isLoading = false;
      })
      .addCase(job_payment_charged.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_transactions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_transactions.fulfilled, (state, action) => {
        state.transactions = action.payload.data;
        state.isLoading = false;
      })
      .addCase(get_transactions.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(job_payment_refund.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(job_payment_refund.fulfilled, (state, action) => {
        state.jobNotes.unshift(action.payload.data.job_note);
        state.isLoading = false;
      })
      .addCase(job_payment_refund.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(add_lead_tag.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_lead_tag.fulfilled, (state, action) => {
        // state.jobNotes.push(action.payload.data);
        state.leadTags = action.payload.data;
        state.isLoading = false;
      })
      .addCase(add_lead_tag.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_lead_tag.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_lead_tag.fulfilled, (state, action) => {
        // state.jobNotes.push(action.payload.data.job_note);
        state.leadTags = action.payload.data;
        state.isLoading = false;
      })
      .addCase(delete_lead_tag.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_lead_tag.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_lead_tag.fulfilled, (state, action) => {
        state.leadTags = action.payload.data.records;
        state.isLoading = false;
      })
      .addCase(get_lead_tag.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(notify_tech.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(notify_tech.fulfilled, (state, action) => {
        state.jobNotes = [...state.jobNotes, ...action.payload.data.job_notes];
        state.isLoading = false;
      })
      .addCase(notify_tech.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(header_search.pending, (state) => {
        state.loader.headerSearch = true;
        // state.record = [];
      })
      .addCase(header_search.fulfilled, (state, action) => {
        state.loader.headerSearch = false;
        if (action.payload.data?.length > 0) {
          state.record = { records: action.payload?.data };
        }
      })
      .addCase(header_search.rejected, (state, action) => {
        state.loader.headerSearch = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(update_invoice_details.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_invoice_details.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_invoice_details.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(job_call_logs.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(job_call_logs.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(job_call_logs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});
export const { jobsReset, clearNotes, updateNotes } = JobsSlice.actions;
export default JobsSlice.reducer;
