import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  assignVendors,
  deleteLocation,
  deleteSearchLog,
  getAssignedDomain,
  getDistance,
  getDomainDataReport,
  getGeoBulkLocationDetail,
  getGeoLoc,
  getGeoLocationDetail,
  getGeoLocDetail,
  getScrubDomains,
  getScrubReport,
  getSearchLogById,
  getSearchLogDetail,
  getSearchLogsList,
  getValidLocations,
  getVendorByIndustry,
  getVendorsLocationReport,
  saveSearchLog,
  updateSearchLog,
} from "../services/geoLoc";
const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  loc: [],
  locDetail: [],
  validLoc: [],
  locationDetails: [],
  searchLogs: [],
  distance: [],
  scrubDomains:[],
  scrubReport:[],
  domainReport:[],
  searchLogById:[],
  vendorsByIdustry:[],
  vendorLocation:[]
};

export const get_geo_loc = createAsyncThunk(
  "get_geo_loc",
  async (data, thunkAPI) => {
    try {
      return await getGeoLoc(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_geo_location_detail = createAsyncThunk(
  "get_geo_location_detail",
  async (data, thunkAPI) => {
    try {
      return await getGeoLocationDetail(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_geo_bulk_location_detail = createAsyncThunk(
  "get_geo_bulk_location_detail",
  async (data, thunkAPI) => {
    try {
      return await getGeoBulkLocationDetail(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_geo_loc_detail = createAsyncThunk(
  "get_geo_loc_detail",
  async (data, thunkAPI) => {
    try {
      return await getGeoLocDetail(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_valid_locations = createAsyncThunk(
  "get_valid_locations",
  async (data, thunkAPI) => {
    try {
      return await getValidLocations(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_distance = createAsyncThunk(
  "get_distance",
  async (data, thunkAPI) => {
    try {
      return await getDistance(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Search Logs
export const get_search_logs = createAsyncThunk(
  "get_search_logs",
  async (data, thunkAPI) => {
    try {
      return await getSearchLogsList(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Scrub Report
export const get_scrub_report = createAsyncThunk(
  "get_scrub_report",
  async (data, thunkAPI) => {
    try {
      return await getScrubReport(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const save_search_logs = createAsyncThunk(
  "save_search_logs",
  async (data, thunkAPI) => {
    try {
      return await saveSearchLog(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_geolocations_detail = createAsyncThunk(
  "get_geolocations_detail",
  async (data, thunkAPI) => {
    try {
      return await getSearchLogDetail(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const update_search_logs = createAsyncThunk(
  "update_search_logs",
  async (data, thunkAPI) => {
    try {
      return await updateSearchLog(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_scrub_domains = createAsyncThunk(
  "get_scrub_domains",
  async (data, thunkAPI) => {
    try {
      return await getScrubDomains(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_domain_data_report = createAsyncThunk(
  "get_domain_data_report",
  async (data, thunkAPI) => {
    try {
      return await getDomainDataReport(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_vendor_location_report = createAsyncThunk(
  "get_vendor_location_report",
  async (data, thunkAPI) => {
    try {
      return await getVendorsLocationReport(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_vendors_by_industry = createAsyncThunk(
  "get_vendors_by_industry",
  async (id, thunkAPI) => {
    try {
      return await getVendorByIndustry(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const delete_search_log = createAsyncThunk(
  "delete_search_log",
  async (id, thunkAPI) => {
    try {
      return await deleteSearchLog(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_search_log_details_by_log_id = createAsyncThunk(
  "get_search_log_details_by_log_id",
  async (id, thunkAPI) => {
    try {
      return await getSearchLogById(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const assign_vendors = createAsyncThunk(
  "assign_vendors",
  async (data, thunkAPI) => {
    try {
      return await assignVendors(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_assigned_domain = createAsyncThunk(
  "get_assigned_domain",
  async (id, thunkAPI) => {
    try {
      return await getAssignedDomain(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const delete_location = createAsyncThunk(
  "delete_location",
  async (id, thunkAPI) => {
    try {
      return await deleteLocation(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const geoLocSlice = createSlice({
  name: "GeoLocation",
  initialState,
  reducers: {
    resetValidLoc: (state) => {
      state.validLoc = [];
    },
    removeFromValidLoc: (state, action) => {
      state.validLoc = state.validLoc.filter((val) => val !== action.payload);
    },
    geoLocReset:(state)=>{
      state.isError= false;
      state.isLoading= false;
      state.record= [];
      state.message= "";
      state.loc= [];
      state.locDetail= [];
      state.validLoc= [];
      state.locationDetails= [];
      state.searchLogs= [];
      state.distance= [];
      state.scrubDomains=[];
      state.scrubReport=[];
      state.searchLogById=[]
    },
    reset: (state) => {
      state.validLoc = [];
    },
    resetLocDetail: (state) => {
      state.locDetail = [];
    },
    clearAll: (state) => {
      state.locDetail = [];
      state.locationDetails = [];
      state.validLoc = [];
    },
    updatePayload: (state, action) => {
      const result = state.loc.findIndex(
        (record) =>
          record?.payload?.type === action?.payload?.type &&
          record?.payload?.record_id === action?.payload?.id?.toString() &&
          record?.payload?.radiusInMeters === action?.payload?.radiusInMeters
      );
      state.loc[result].payload = {
        ...state.loc[result].payload,
        ...{ location: action?.payload?.location },
      };
    },
    resetLoc: (state) => {
      state.loc = [];
    },
    removeSection: (state, action) => {
      state.loc = [];
    },
    clearScrubDomains: (state, action) => {
      state.scrubDomains = [];
      state.domainReport=[]
    },
    clearScrubReport: (state, action) => {
      state.scrubReport = [];
    },
    deleteObject: (state, action) => {
      if(state.locationDetails.length > 0){
        state.locationDetails.splice(action.payload, 1);
      }
    },
    clearDistance: (state) => {
      state.distance = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_geo_loc.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(get_geo_loc.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.data.status === true) {
          state.loc.push(action.payload.data);
        } else {
          toast.error(action.payload.data.message);
        }
      })
      .addCase(get_geo_loc.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_search_logs.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(get_search_logs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchLogs = action.payload.data;
      })
      .addCase(get_search_logs.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_geo_location_detail.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(get_geo_location_detail.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.data?.length > 0) {
          state.locationDetails.unshift(action.payload.data);
        }
      })
      .addCase(get_geo_location_detail.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_geo_bulk_location_detail.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(get_geo_bulk_location_detail.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_geo_bulk_location_detail.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(save_search_logs.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(save_search_logs.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(save_search_logs.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_search_logs.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(update_search_logs.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_search_logs.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_geo_loc_detail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_geo_loc_detail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.locDetail = action.payload?.data;
      })
      .addCase(get_geo_loc_detail.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_valid_locations.pending, (state) => {
        state.isLoading = true;
        state.validLoc = [];
      })
      .addCase(get_valid_locations.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.data?.length === 0) {
          toast.error("No record match");
        }
        state.validLoc = action.payload?.data;
      })
      .addCase(get_valid_locations.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_distance.pending, (state) => {
        state.isLoading = true;
        state.distance = [];
      })
      .addCase(get_distance.fulfilled, (state, action) => {
        state.isLoading = false;
        state.distance = action.payload?.data;
      })
      .addCase(get_distance.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_search_log.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_search_log.fulfilled, (state, action) => {
        state.searchLogs = state?.searchLogs?.filter(
          ({ id }) => id !== +action?.payload?.data
        );
        state.isLoading = false;
      })
      .addCase(delete_search_log.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_scrub_domains.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(get_scrub_domains.fulfilled, (state, action) => {
        state.isLoading = false;
        state.scrubDomains = action.payload.data;
      })
      .addCase(get_scrub_domains.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_scrub_report.pending, (state) => {
        state.isLoading = true;
        state.distance = [];
      })
      .addCase(get_scrub_report.fulfilled, (state, action) => {
        state.isLoading = false;
        if(action.payload?.data?.length===0){
          toast.error("No Record found")
        }
        state.scrubReport = action.payload?.data;
      })
      .addCase(get_scrub_report.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_geolocations_detail.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(get_geolocations_detail.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.data?.length > 0) {
          state.locationDetails.unshift(action.payload.data);
        }
      })
      .addCase(get_geolocations_detail.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_search_log_details_by_log_id.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(get_search_log_details_by_log_id.fulfilled, (state, action) => {
        state.isLoading = false;
          state.searchLogById=action.payload.data;
      })
      .addCase(get_search_log_details_by_log_id.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(assign_vendors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assign_vendors.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(assign_vendors.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_location.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_location.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_location.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_assigned_domain.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_assigned_domain.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(get_assigned_domain.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_domain_data_report.pending, (state) => {
        state.isLoading = true;
        state.domainReport = [];
      })
      .addCase(get_domain_data_report.fulfilled, (state, action) => {
        state.isLoading = false;
        if(action.payload?.data?.length===0){
          toast.error("No Record found")
        }
        state.domainReport = action.payload?.data;
      })
      .addCase(get_domain_data_report.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_vendors_by_industry.pending, (state) => {
        state.isLoading = true;
        state.vendorsByIdustry = [];
      })
      .addCase(get_vendors_by_industry.fulfilled, (state, action) => {
        state.isLoading = false;
        if(action.payload?.data?.length===0){
          toast.error("No Record found")
        }
        state.vendorsByIdustry = action.payload?.data;
      })
      .addCase(get_vendors_by_industry.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_vendor_location_report.pending, (state) => {
        state.isLoading = true;
        state.vendorLocation = [];
      })
      .addCase(get_vendor_location_report.fulfilled, (state, action) => {
        state.isLoading = false;
        if(action.payload?.data?.length===0){
          toast.error("No Record found")
        }
        state.vendorLocation = action.payload?.data;
      })
      .addCase(get_vendor_location_report.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const {
  reset,
  resetLocDetail,
  updatePayload,
  resetLoc,
  resetValidLoc,
  clearAll,
  removeFromValidLoc,
  deleteObject,
  clearDistance,
  clearScrubDomains,
  clearScrubReport,
  geoLocReset
} = geoLocSlice.actions;
export default geoLocSlice.reducer;
