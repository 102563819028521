import api from "./api";

export const getDbTables = async (dbName) => {
  return await api.get(`api/industries/industry_db/dbTables/${dbName}`);
};

export const getTableRows = async (dbName, tableName) => {
  return await api.get(
    `api/industries/industry_db/tableRows/${dbName}/${tableName}`
  );
};

export const exportTable = async (dbName, tableName) => {
  return await api.get(
    `api/industries/industry_db/export_table/${dbName}/${tableName}`,
    {
      responseType: "blob", // Specify the response type as Blob
    }
  );
};

export const exportSampleTable = async (dbName, tableName) => {
  return await api.get(
    `api/industries/industry_db/export_sample_table/${dbName}/${tableName}`,
    {
      responseType: "blob", // Specify the response type as Blob
    }
  );
};

export const importExcel = async (file, dbName, tableName) => {
  return await api.post(
    `api/industries/industry_db/import_table/${dbName}/${tableName}`,
    file,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
export const updateTableRow = async (dbName, tableName, data) => {
  console.log("👊 ~ updateTableRow ~ data:", data)
  return await api.put(
    `api/industries/industry_db/editTableRows/${dbName}/${tableName}`,
    data
  );
};

export const deleteTableRow = async (dbName, tableName, ids) => {
  return await api.post(
    `api/industries/industry_db/deleteTableRows/${dbName}/${tableName}`,
    { ids }
  );
};

export const addTableRow = async (dbName, tableName, data) => {
  return await api.post(
    `api/industries/industry_db/addTableRows/${dbName}/${tableName}`,
    data
  );
};
export const downloadDatabse = async (dbName) => {
  return await api.get(
    `api/industries/industry_db/export_database_new/${dbName}`
  );
};
export const updateDatabse = async (id) => {
  return await api.post(
    `api/industries/industry_db/curd_operation_for_database/${id}`
  );
};
