import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { get_payment_gateways } from "../../features/paymentGatewaySlice";
import AddUpdateForm from "./AddUpdateForm";
import PageHeader from "../../components/molecules/PageHeader";
import { get_providers } from "../../features/ProviderSlice";
import userPermission from "../../util/userPermission";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
const PaymentGateways = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.paymentGateway);

  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  useEffect(() => {
    dispatch(get_payment_gateways({ page: 1, size: paginationModel.pageSize }));
    dispatch(get_providers());

    // eslint-disable-next-line
  }, []);
  const gateways = record?.records?.filter(
    (gateway) =>
      gateway?.provider?.toLowerCase().includes(searchText.toLowerCase()) ||
      gateway?.title?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Provider", field: "provider", flex: 1 },
    { headerName: "Title", field: "title", flex: 1 },
    { headerName: "API Key", field: "api_key", flex: 1 },
    { headerName: "API Password", field: "api_password", flex: 1 },
    { headerName: "Environment", field: "environment", flex: 1 },
    { headerName: "Auth Feature", field: "has_auth_feature", flex: 1 },
    { headerName: "Status", field: "status", flex: 1 },
    {
      field: "actions",
      renderCell: (params) => (
        <div>
          {userPermission("Update Payment Gateway") && (
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update Payment Gateway"
            />
          )}
        </div>
      ),
      width: 120,
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_payment_gateways({
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Payment Gateway"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Payment Gateway"
        heading="Payment Gateway Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Payment Gateway")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={gateways?.map((record, index) => {
            let counter = index + 1;
            let provider = record.provider_id?.name;
            let title = record.title;
            let api_key = record.api_key;
            let api_password = record.api_password;
            let status = record.active ? "Active" : "Inactive";
            let has_auth_feature = record.has_auth_feature ? "Yes" : "No";
            let environment = record.environment;
            return {
              counter,
              records: record,
              provider,
              title,
              api_key,
              api_password,
              status,
              has_auth_feature,
              environment,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default PaymentGateways;
