import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";
import api from "../../services/api";
import ToggleSwitch from "../../components/molecules/ToggleButton";
import errorMessage from "../../util/errorMessage";
import {
  delete_google_console,
  get_google_console,
} from "../../features/googleConsoleSlice";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import GoogleConsoleForm from "./GoogleConsoleForm";
import userPermission from "../../util/userPermission";

const GoogleConsole = () => {
  const dispatch = useDispatch();
  const { isLoading, searchConsole } = useSelector(
    (state) => state.googleConsole
  );
  const [searchText, setSearchText] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  useEffect(() => {
    dispatch(get_google_console());
    // eslint-disable-next-line
  }, []);
  const googleConsoles = searchConsole?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const handleDeploy = async (ip) => {
    setIsLoader(true);
    try {
      const res = await api.post(
        `https://api.cmsdeployer.com/api/google_seo/deploy_on_server`,
        { host_ip: ip }
      );
      if (res.status === 200) {
        toast.success("Deploy successfully");
        setIsLoader(false);
        dispatch(get_google_console());
      } else {
        errorMessage({
          payload: res?.data?.message,
          action: "Server",
          msg: "deployed",
        });
        setIsLoader(false);
      }
    } catch (error) {
      console.error("🚀 ~ file: index.jsx:48 ~ handleDeploy ~ error:", error);
      errorMessage({
        payload: error?.response.data?.message,
        action: "Server",
        msg: "deployed",
      });
      setIsLoader(false);
    }
  };
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_google_console(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Google Search Console deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "Google Search Console",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          action: "Google Search Console",
          msg: "deleted",
        });
      }
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Host IP", field: "host_ip", flex: 1 },
    { headerName: "Gmail Accounts", field: "gmail_account", flex: 1 },
    { headerName: "Total Domains", field: "total_domain", flex: 1 },
    { headerName: "Client ID", field: "client_id", flex: 1 },
    { headerName: "Client Secret", field: "client_secret", flex: 1 },
    {
      headerName: "Action",
      field: "client_sec",
      flex: 1,
      renderCell: (params) => (
        <>
          <ToggleSwitch
            checked={params?.row?.records?.deploy_status ? true : false}
            unique_by={params?.row?.counter + 1}
            onChange={() => {
              if (params?.row?.records?.deploy_status) {
                return toast.error("This Server is already setup");
              }
              handleDeploy(params.row.host_ip);
            }}
          />
          {!params?.row?.records?.deploy_status ? (
            <div>
              <div className="flex flex-row">
                <FaEdit
                  onClick={openFormHandler(params.row.records)}
                  className="my_navIcon"
                  title="Update AllowedIP"
                />
                <FaTrashAlt
                  onClick={deleteRecordHandler(params.row.records.id)}
                  className="my_navIcon"
                  title="Delete AllowedIP"
                />
              </div>
            </div>
          ) : null}
        </>
      ),
    },
  ];
  return (
    <>
      <PageHeader
        route="Setting > User Roles"
        heading="Google Search Console List"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Allowed IP")}
      />
      {isEditing && (
        <GoogleConsoleForm
          editingRecord={editingRecord}
          modalTitle="Add Host"
          onCancelForm={cancelFormHandler}
        />
      )}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={googleConsoles?.map((record, index) => {
            let counter = index + 1;
            const {
              host_ip,
              client_secret,
              gmail_account,
              total_domain,
              client_id,
            } = record;
            return {
              counter,
              host_ip,
              client_secret,
              gmail_account,
              total_domain,
              client_id,
              records: record,
            };
          })}
          totalItems={searchConsole?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading || isLoader}
        />
      </div>
    </>
  );
};

export default GoogleConsole;
