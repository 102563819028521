import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import { Button, Loader } from "../../components";
const LockedInvoiceModal = ({ onClose, invoiceDetail }) => {
  const [isLoader, setIsLoader] = useState(false);
  const [notify_vendor, setNotify_vendor] = useState(false);
  const handleConfirm = async (id) => {
    setIsLoader(true);
    try {
      const res = await api.post("/api/jobs/lock_invoice", {
        notify_vendor,
        invoice_id: invoiceDetail?._id,
      });
      if (res.status === 200) {
        toast.success("Invoice locked successfully");
        onClose();
      } else {
        toast.error(res?.data?.message || "Invoice couldn't be locked");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error(err?.response?.data?.message || "Invoice couldn't be locked");
      console.log("🚀 ~ lockedInvoice ~ err:", err);
    }
  };
  return (
    <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40 bg-black/60">
      <div className="bg-white p-8 rounded-lg shadow-lg relative min-w-[500px] max-w-[90%]">
        <span className="text-normal font-semibold mb-4 font-pop">
          <input
            type="checkbox"
            className={`form-checkbox h-6 w-6 text-[#052A42] rounded-sm focus:ring-0 cursor-pointer mr-2`}
            onClick={() => setNotify_vendor(!notify_vendor)}
            checked={notify_vendor}
          />
          Would you also like to notify the vendor via email?
        </span>
        <div className="grid grid-cols-1 font-pop font-medium gap-x-2 mt-3">
          <div className="flex justify-betwee p-1">
            <span className="col-span-3">Vendor:</span>
            <span className="text-left ml-3">{`${
              invoiceDetail?.vendor_id?.first_name || ""
            } ${invoiceDetail?.vendor_id?.last_name || ""}`}</span>
          </div>
          {notify_vendor ? (
            <div className="flex justify-betwee p-1">
              <span className="col-span-3">Email:</span>
              <span className="text-left ml-3">
                {invoiceDetail?.vendor_id?.email}
              </span>
            </div>
          ) : null}

          {!invoiceDetail?.vendor_id?.email && notify_vendor ? (
            <small className="text-red-600 -mt-2 mb-3 pl-1">
              Email is not exist for this vendor, Please add it from Vendors.
            </small>
          ) : null}
        </div>
        <div className="flex justify-end !mt-4">
          {isLoader ? (
            <Loader />
          ) : (
            <>
              <Button text="Cancel" variant="btn_cancel" onClick={onClose} />
              <Button
                text="Lock"
                variant="btn_submit"
                onClick={handleConfirm}
                className="ml-2"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LockedInvoiceModal;
